import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components/macro";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
// import { Draggable } from "react-drag-reorder";
import { Snackbar, Alert } from "@mui/material";
import CheckpointListCol from "../table-columns-json/tours/checkpointList";
import StyledColumnOptions from "../../helper/StyledColumnOptions";
// import TourListCol from "../table-columns-json/tours/tourslist";
import { setCheckpointList } from "../../redux/slices/tours/tourTabelColSlice";
import {
  GetTourCheckpointList,
  UpdateCheckpointStatus,
  ViewTourCheckpoint,
  ReorderButton,
} from "../../api";
import {
  getChecked,
  getSnackOpen,
  getSnackClose,
  getTourRow,
  getMsg,
} from "../../redux/slices/mainSlice";
import {
  Checkbox,
  Grid,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper as MuiPaper,
  Table,
  TableBody,
  Pagination,
  TableContainer,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  /* Tooltip, */
  Typography,
  InputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  Box,
  Button,
  Link,
  List,
  ListItemButton,
  /* List,
ListItem, */
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  CircularProgress,
  Card,
  CardContent,
} from "@mui/material";
import { DatePicker } from "@mui/lab";
import {
  FilterList as FilterListIcon,
  SwapVert as SwapVertIcon,
  Search as SearchIcon,
  Cancel as CancelIcon,
  List as ListIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import CheckpointRow from "./CheckpointRow";
import Toaster from "../tasks/Toaster";
import ScheduleCheckpoint from "./ScheduleCheckpoint";
import CustomAlert from "../../components/form-components/CustomAlert";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  //   getMsg,
  getSnackAlert,
  //   getSnackClose,
  //   getSnackOpen,
} from "../../redux/slices/mainSlice";

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;
const Image = styled.img`
  width: 100px;
  margin: 0 auto;
`;
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "140px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);
const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;

const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
function createData(
  name,
  lochint,
  image,
  critical,
  tasks,
  taskschedules,
  timing,
  action
) {
  return {
    name,
    lochint,
    image,
    critical,
    tasks,
    taskschedules,
    timing,
    action,
  };
}
//Create data here
const rows = [
  createData(
    "After hours spa tours",
    "hg",
    "",
    "10",
    0,
    "12/23/2018 10:27",
    "",
    0
  ),
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}
const headCells = [
  /* { id: "sel", numeric: true, disablePadding: false, label: "Sel" },
{ id: "type", numeric: true, disablePadding: false, label: "Type" },
*/ {
    id: "button",
    numeric: true,
    disablePadding: false,
    label: "Button Name",
  },
  {
    id: "lochint",
    numeric: true,
    disablePadding: false,
    label: "Location Hint",
  },
  { id: "image", numeric: true, disablePadding: false, label: "Image" },
  { id: "critical", numeric: true, disablePadding: false, label: "Critical" },
  {
    id: "tasks",
    numeric: true,
    disablePadding: false,
    label: "Tasks",
  },
  /* { id: "active", numeric: true, disablePadding: false, label: "Active" }, */
  {
    id: "taskschedules",
    numeric: true,
    disablePadding: false,
    label: "Task Schedules",
  },
  /* {
id: "sl",
numeric: true,
disablePadding: false,
label: "SL",
}, */
  {
    id: "timing",
    numeric: true,
    disablePadding: false,
    label: "Timing",
  },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];
const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    tableCalRedux,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all data" }}
          />
        </TableCell>
        {CheckpointListCol.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            sx={{ whiteSpace: "nowrap" }}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={tableCalRedux[headCell.id] === true ? "" : "hide-cell"}
          >
            <TableSortLabel
              active={
                headCell.id === "TagDescription"
                  ? orderBy === headCell.id
                  : false
              }
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={
                headCell.id === "TagDescription"
                  ? createSortHandler(headCell.id)
                  : null
              }
              hideSortIcon={headCell.id === "TagDescription" ? false : true}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const {
    numSelected,
    totalRecord,
    setSearchInput,
    handleDeleteOpen,
    handleFirstPage,
    tourCheckpoint,
    tableCalRedux,
    defalutTableCalRedux,
    onReorder,
  } = props;
  const [actions, setActions] = React.useState("");
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();
  const handleChange = (event) => {
    setActions(event.target.value);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // Search Dropdown
  const [anchorForm, setAnchorForm] = React.useState(null);
  const handleClickDropdown = (event) => {
    setAnchorForm(event.currentTarget);
  };
  const handleCloseForm = () => {
    setAnchorForm(null);
  };
  // Columns Dropdown
  const [anchorColumn, setAnchorColumn] = React.useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };
  // show checked only switch toggle
  const [state, setState] = React.useState({
    switchone: true,
  });
  //enter key search
  const enterPress = () => {
    setSearchInput(searchValue);
    handleFirstPage();
  };
  // Change order modal
  const [changeorder, setChangeOrder] = React.useState(false);
  const changeOrderOpen = () => {
    setChangeOrder(true);
  };
  const changeOrderClose = () => {
    handleClose();
    setChangeOrder(false);
  };
  const [modifiedTourCheckpoint, setModifiedTourCheckpoint] = useState([
    ...tourCheckpoint,
  ]);

  const reorderButton = (items) => {
    let payload = {
      Tour: items.map((item) => item.TagID),
    };
    ReorderButton(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          dispatch(getMsg("Button order has been updated"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
          // window.location.reload();
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {});
    setModifiedTourCheckpoint(items);
    onReorder(items);
  };

  const getChangedPos = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = Array.from(modifiedTourCheckpoint);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);
    setModifiedTourCheckpoint(reorderedItems);
    // reorderButton(reorderedItems);
  };

  useEffect(() => {
    setModifiedTourCheckpoint([...tourCheckpoint]);
  }, [tourCheckpoint]);

  const sortOrder = () => {};
  return (
    <Toolbar>
      <div>
        <Paper mt={3}>
          <FormControl mr={2} sx={{ minWidth: 120 }}>
            <InputLabel id="demo-simple-select-label">Actions</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={actions}
              label="Actions"
              MenuProps={{
                PaperProps: {
                  sx: {
                    "& .MuiMenu-list": {
                      boxShadow:
                        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 10%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
                      margin: "0px 3px 5px 3px",
                      padding: "0",
                    },
                  },
                },
              }}
              onChange={handleChange}
            >
              <MenuItem value={"Delete"} onClick={handleDeleteOpen}>
                Delete
              </MenuItem>
            </Select>
          </FormControl>
        </Paper>
      </div>
      <div className="totalRecord">
        <Typography
          color="inherit"
          variant="subtitle1"
          mt={3}
          style={{ width: "max-content", marginLeft: "15px" }}
        >
          {totalRecord <= 1
            ? `${totalRecord} record found`
            : `${totalRecord} records found`}
        </Typography>
      </div>
      <Spacer />
      <div className="searchTool">
        <Box
          mt={3}
          sx={{
            display: "flex",
            flexWrap: "nowrap",
          }}
        >
          <Item sx={{ flexGrow: 1, width: "100%" }}>
            <TextField
              placeholder="Search"
              type="text"
              variant="outlined"
              fullWidth
              // size="small"
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
              InputProps={{
                // sx: {
                //   minWidth: "300px",
                // },
                // startAdornment: (
                //   <InputAdornment position="start">
                //     <SearchIcon />
                //   </InputAdornment>
                // ),

                endAdornment: searchValue && (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      // searchHandleClick();
                      setSearchInput("");
                      setSearchValue("");
                    }}
                  >
                    <CancelIcon />
                  </IconButton>
                ),
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  enterPress();
                }
              }}
            />
          </Item>
          <Item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setSearchInput(searchValue);
                handleFirstPage();
              }}
              sx={{
                borderTopLeftRadius: "0px",
                borderBottomLeftRadius: "0px",
                height: "100%",
                marginLeft: "-3px",
              }}
            >
              <SearchIcon />
              {/* Search */}
            </Button>
          </Item>
        </Box>
      </div>

      <Spacer />
      <div>
        <Paper mt={3}>
          <IconButton
            aria-owns={anchorEl ? "icon-menu" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            variant="contained"
            color="secondary"
          >
            <FilterListIcon />
          </IconButton>
          <StyledFilterMenu
            id="icon-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem onClick={changeOrderOpen}>
              <ListItemIcon>
                <SwapVertIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="Change Order" pl={0} />
            </MenuItem>
            <MenuItem
              aria-label="tour-listing"
              component={NavLink}
              to="/tours/tour-listing"
            >
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="Tour List" pl={0} />
            </MenuItem>
            <MenuItem
              aria-owns={anchorColumn ? "columns-dropdown" : undefined}
              aria-haspopup="true"
              onClick={handleClickColumn}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Columns" pl={0} />
            </MenuItem>
          </StyledFilterMenu>
          <StyledColumnDropdown
            id="columns-dropdown"
            anchorEl={anchorColumn}
            open={Boolean(anchorColumn)}
            onClose={handleCloseColumn}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem>
              <Card>
                <CardContent>
                  <Typography variant="h6" component="h2">
                    {
                      Object.keys(tableCalRedux).filter(
                        (k) => tableCalRedux[k] === true
                      ).length
                    }{" "}
                    out of {Object.keys(tableCalRedux).length} visible
                  </Typography>
                  <StyledColumnOptions
                    calStatus={tableCalRedux}
                    updateCalStatus={(data) => {
                      dispatch(setCheckpointList(data));
                    }}
                    listing={CheckpointListCol}
                  />
                  <Toolbar>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        dispatch(setCheckpointList(defalutTableCalRedux));
                      }}
                    >
                      Reset
                    </Button>
                    <Spacer />
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleCloseColumn}
                    >
                      Cancel
                    </Button>
                  </Toolbar>
                </CardContent>
              </Card>
            </MenuItem>
          </StyledColumnDropdown>
          <Dialog
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "500px",
                },
              },
            }}
            open={changeorder}
            onClose={changeOrderClose}
            aria-labelledby="change-order"
            aria-describedby="change-order"
          >
            <DialogTitle
              id="alert-dialog-title"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingBottom: "0px",
              }}
            >
              <Typography variant="h4" component="div">
                Change Order
              </Typography>
              <DialogActions sx={{ padding: "0px" }}>
                <IconButton
                  sx={{ marginRight: "-10px" }}
                  onClick={() => setChangeOrder(false)}
                >
                  <CancelIcon />
                </IconButton>
              </DialogActions>
            </DialogTitle>
            <DialogContent sx={{ padding: 0 }}>
              <DragDropContext onDragEnd={getChangedPos}>
                <Droppable droppableId="buttonList">
                  {(provided) => (
                    <ul
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={{ listStyleType: "none", padding: 0 }}
                    >
                      {modifiedTourCheckpoint.map((data, index) => (
                        <Draggable
                          key={data.TagID}
                          draggableId={data.TagID.toString()}
                          index={index}
                        >
                          {(provided) => (
                            <li
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <ListItemButton divider key={index}>
                                <ListItemText
                                  key={index}
                                  primary={data.button}
                                />
                              </ListItemButton>
                            </li>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
            </DialogContent>

            <DialogActions sx={{ paddingBottom: "30px", paddingRight: "20px" }}>
              <Button
                onClick={changeOrderClose}
                color="primary"
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  changeOrderClose();
                  reorderButton(modifiedTourCheckpoint);

                  // sortOrder();
                  // reorderButton();
                }}
                color="primary"
                variant="contained"
                sx={{ marginRight: "10px" }}
              >
                Reorder
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </div>
    </Toolbar>
  );
};
function EnhancedTable() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("color");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [totalRecord, setTotalRecord] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [length, setLength] = useState(null);
  const dispatch = useDispatch(); //variable used for dispatching all the actions to the redux store.
  const tableCalRedux = useSelector(
    (state) => state.tourTableCol.checkpointList
  );
  const defalutTableCalRedux = useSelector(
    (state) => state.defaultTourTableCol.checkpointList
  );
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = tourCheckpoint.map((n) => n.TagID);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
    setSelected([]);
  };

  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
    setSelected([]);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    setLength(newSelected.length);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };
  const handleFirstPage = () => {
    setCurrentPage(1);
    setPage(0);
    setSelected([]);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [checkpointId, setCheckpointId] = useState(null);
  const [checkpointName, setCheckpointName] = useState(null);
  const handleActionClick = (event, id, button) => {
    setAnchorEl(event.currentTarget);
    setCheckpointId(id);
    setCheckpointName(button);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
    setCheckpointId(null);
  };
  //Equipment log modal
  const [open, setOpen] = useState(false);
  const clickLogModalOpen = () => {
    setOpen(true);
  };
  const logModalClose = () => {
    setOpen(false);
  };
  //ScheduleCheckpoints modal
  const [scOpen, setScOpen] = useState(false);
  const scheduleCheckpointOpen = () => {
    setScOpen(true);
  };
  const scheduleCheckpointClose = () => {
    setScOpen(false);
  };
  //Date Time Picker
  const [value, setValue] = React.useState(null);
  const [startdate, setStartDate] = React.useState(null);
  const [enddate, setEndDate] = React.useState(null);
  //
  const [state, setState] = React.useState({
    switchtwo: true,
  });
  const handleSwitching = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const { switchtwo } = state;
  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };
  const handleDeleteClose = () => {
    setOpenDelete(false);
    setAnchorEl(null);
    setCheckpointId(null);
  };
  //get tour checkpoints
  /* Destructuring the id from the useParams hook. */
  const { id } = useParams(); //geting url id
  //get view tour checkpoints
  const [viewCheckpoint, setViewCheckpoint] = useState({});
  const [modulearray, setmodulearray] = useState([]);
  const viewTourCheckpoint = () => {
    let payload = {
      tour_id: id,
      tag_id: checkpointId,
    };
    ViewTourCheckpoint(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setViewCheckpoint(res.data);
          setmodulearray(res.data.weekly_value);
        } else {
          setViewCheckpoint({});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [tourCheckpoint, setTourCheckpoint] = useState([]);
  const getTourCheckpoints = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
      checkpoint_name: searchInput,
      sorting: {
        order: order,
        by: orderBy,
      },
    };
    GetTourCheckpointList(id, currentPage, payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setTourCheckpoint(res.data);
          setTotalRecord(res.meta.total);
          setLoading(false);
        } else {
          setTourCheckpoint([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setTotalRecord(0);
      });
  };
  //delete checkpoint method
  const deleteCheckpoint = () => {
    UpdateCheckpointStatus(checkpointId, { status: "D" })
      .then((res) => {
        if (res.statusCode === 200) {
          handleDeleteClose();
          setSelected([]);
          handleDeleteClose();
          getTourCheckpoints();
          setOpenSnack(true);
        } else {
          handleDeleteClose();
          setSelected([]);
          handleDeleteClose();
          getTourCheckpoints();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //useEffect
  useEffect(() => {
    getTourCheckpoints();
  }, [rowsPerPage, currentPage, searchInput, order, orderBy]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(getSnackClose());
    }, 4000);
  }, []);

  const handleReorder = (reorderedList) => {
    setTourCheckpoint(reorderedList);
  };

  return (
    <div>
      <Paper mt={3}>
        <Toaster
          openSnack={openSnack}
          handleCloseSnack={handleCloseSnack}
          message={`A total of ${
            length > 0 ? length : 1
          } record(s) have been deleted`}
        />
        <div>
          {selected.length || checkpointId != null ? (
            <Dialog
              open={openDelete}
              onClose={handleDeleteClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Are you sure you want to delete tour?"}
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleDeleteClose}>Cancel</Button>
                <Button
                  onClick={(handleDeleteClose, deleteCheckpoint)}
                  autoFocus
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          ) : (
            <Dialog
              open={openDelete}
              onClose={handleDeleteClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"You haven't selected any item!"}
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleDeleteClose}>OK</Button>
              </DialogActions>
            </Dialog>
          )}
        </div>
        <EnhancedTableToolbar
          numSelected={selected.length}
          totalRecord={totalRecord}
          setSearchInput={setSearchInput}
          handleDeleteOpen={handleDeleteOpen}
          handleFirstPage={handleFirstPage}
          tourCheckpoint={tourCheckpoint}
          tableCalRedux={tableCalRedux}
          defalutTableCalRedux={defalutTableCalRedux}
          onReorder={handleReorder}
        />
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={tourCheckpoint.length}
              tableCalRedux={tableCalRedux}
            />
            {loading ? (
              <TableCell colSpan={13} align="center">
                <CircularProgress />
              </TableCell>
            ) : (
              <TableBody>
                {stableSort(tourCheckpoint, getComparator(order, orderBy))
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((data) => {
                    const isItemSelected = isSelected(data.TagID);
                    const labelId = `enhanced-table-checkbox-${data.TagID}`;
                    return (
                      <CheckpointRow
                        data={data}
                        isItemSelected={isItemSelected}
                        labelId={labelId}
                        handleClick={handleClick}
                        anchorEl={anchorEl}
                        handleActionClick={handleActionClick}
                        handleActionClose={handleActionClose}
                        handleDeleteOpen={handleDeleteOpen}
                        tourId={id}
                        key={data.TagID}
                        scheduleCheckpointOpen={scheduleCheckpointOpen}
                        checkpointId={checkpointId}
                        viewTourCheckpoint={viewTourCheckpoint}
                        getTourCheckpoints={getTourCheckpoints}
                        tableCalRedux={tableCalRedux}
                      />
                    );
                  })}
                {emptyRows < 0 && (
                  <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                    <TableCell
                      align="center"
                      colSpan={9}
                      sx={{ padding: "20px" }}
                    >
                      <Image src={`/static/img/unsplash/checkpoints.png`} />
                      <Typography
                        variant="h2"
                        component="div"
                        sx={{
                          marginTop: "20px",
                          display: "block",
                          fontWeight: "400",
                        }}
                      >
                        Looks like you need to add some tour buttons first...
                      </Typography>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{
                          marginTop: "20px",
                          display: "block",
                          fontWeight: "400",
                        }}
                      >
                        Learn about <Link href="#">adding tours</Link> from our
                        support site.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {tourCheckpoint.length === 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 6 : 0) * tourCheckpoint.length,
                    }}
                  >
                    <TableCell colSpan={13} align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          }}
        >
          <Pagination
            count={Math.ceil(totalRecord / rowsPerPage)}
            page={currentPage}
            onChange={handleChangePagination}
            color="primary"
            boundaryCount={3}
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <Typography sx={{ marginRight: "10px" }}>
                Rows per page :
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                // label="Age"
                onChange={handleChangeLimit}
              >
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </Box>
          </FormControl>
        </Box>
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      <ScheduleCheckpoint
        scheduleCheckpointOpen={scheduleCheckpointOpen}
        setScOpen={setScOpen}
        scheduleCheckpointClose={scheduleCheckpointClose}
        scOpen={scOpen}
        checkpointName={checkpointName}
        viewCheckpoint={viewCheckpoint}
        setmodulearray={setmodulearray}
        modulearray={modulearray}
        viewTourCheckpoint={viewTourCheckpoint}
        checkpointId={checkpointId}
        getTourCheckpoints={getTourCheckpoints}
      />
      {/* Equipment log popup */}
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "900px",
            },
          },
        }}
        open={open}
        onClose={logModalClose}
        aria-labelledby="equipment-log"
        aria-describedby="equipment-log"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h4" component="div">
            Equipment log for Key GK026
          </Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setOpen(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent sx={{ padding: "0px" }}>
          <DialogContentText id="equipment-log" sx={{ padding: "30px 20px" }}>
            <Grid container spacing={4} mt={2}>
              <Grid item xs={12} md={4}>
                <DatePicker
                  id="start-date"
                  label="Start date"
                  value={startdate}
                  onChange={(newValueStartDate) => {
                    setStartDate(newValueStartDate);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "Start date",
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <DatePicker
                  id="end-date"
                  label="End date"
                  value={enddate}
                  onChange={(newValueEndDate) => {
                    setEndDate(newValueEndDate);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "End date",
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Button
                  color="primary"
                  variant="contained"
                  sx={{ height: "100%" }}
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </DialogContentText>
          <Paper>
            <TableWrapper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Username</TableCell>
                    <TableCell>Checkout Date</TableCell>
                    <TableCell>Checkout By</TableCell>
                    <TableCell>Checkin Date</TableCell>
                    <TableCell>Checkin By</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Test User</TableCell>
                    <TableCell>02/05/2022</TableCell>
                    <TableCell>Test</TableCell>
                    <TableCell>02/03/2022</TableCell>
                    <TableCell>Test</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Test User</TableCell>
                    <TableCell>02/05/2022</TableCell>
                    <TableCell>Test</TableCell>
                    <TableCell>02/03/2022</TableCell>
                    <TableCell>Test</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Test User</TableCell>
                    <TableCell>02/05/2022</TableCell>
                    <TableCell>Test</TableCell>
                    <TableCell>02/03/2022</TableCell>
                    <TableCell>Test</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Typography
                variant="p"
                mt={4}
                sx={{ textAlign: "center", display: "block" }}
              >
                No records found for selected date range
              </Typography>
            </TableWrapper>
          </Paper>
        </DialogContent>
        <DialogActions sx={{ paddingBottom: "30px", paddingRight: "20px" }}>
          <Button onClick={logModalClose} color="primary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
function Checkpoints() {
  const tourData = useSelector((state) => state.tour.tourRow);
  const SnackState = useSelector((state) => state.tour.snackStatus);
  const { tour_name } = tourData;
  const [snackopen, setSnackopen] = useState(true);
  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackopen(false);
  };
  return (
    <React.Fragment>
      {SnackState && (
        <Snackbar
          open={snackopen}
          autoHideDuration={3000}
          onClose={handleSnackClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Alert
            onClose={handleSnackClose}
            severity="success"
            variant="filled"
            sx={{ maxWidth: 600 }}
          >
            Tour button has been updated successfully
          </Alert>
        </Snackbar>
      )}
      <Helmet title="Checkpoints" />
      <Typography variant="h3" gutterBottom display="inline">
        Checkpoints: {tour_name}
      </Typography>

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
export default Checkpoints;
