import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { NavLink } from "react-router-dom";
import { UpdateDepartment } from "../../../api";
import { useDispatch, useSelector } from "react-redux";
import { GetPositionList, DeletePosition } from "../../../api";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../../redux/slices/equipmentSlice";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  FormControl as MuiFormControl,
  Button,
  IconButton,
  TextField,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  MenuItem,
  Menu,
  ListItemText,
  ListItemIcon,
  FormControlLabel,
  Switch,
  TableSortLabel,
  Checkbox,
  Paper,
  Box,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import {
  CancelRounded as CancelRoundedIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;

const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
// Classes Styling
const useStyles = makeStyles((theme) => ({
  borderedBox: {
    border: "1px solid",
    borderBottom: 0,
    borderColor:
      theme.palette.mode === "light" ? "#e0e0e0" : theme.palette.grey[700],
  },
}));
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
function createData(position, securitylevel, count, action) {
  return {
    position,
    securitylevel,
    count,
    action,
  };
}
const rows = [createData("testing", "dfd", "gffg", 0)];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  {
    id: "position",
    numeric: true,
    disablePadding: false,
    label: "Position",
  },
  {
    id: "securitylevel",
    numeric: true,
    disablePadding: false,
    label: "Security Level",
  },
  {
    id: "count",
    numeric: true,
    disablePadding: false,
    label: "Equipment Permission Count",
  },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];
const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            sx={{ whiteSpace: "nowrap" }}
            padding={headCell.disablePadding ? "none" : "normal"}
            // sortDirection={orderBy === headCell.id ? order : false}
          >
            {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.icon}
            </TableSortLabel> */}
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
function EnhancedTable({ id }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("color");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [positionList, setpositionList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [positionId, setPositionId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const dispatch = useDispatch();
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleActionClick = (e, id) => {
    setAnchorEl(e.currentTarget);
    setPositionId(id);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };

  //method for open the delete dialog
  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  /**
   * It closes the delete dialog and resets the anchor element and id
   */
  const handleDeleteClose = () => {
    setOpenDelete(false);
    setAnchorEl(null);
    // setId(null);
  };

  /**
   * It deletes the selected position(s) from the database
   */
  const deletePosition = () => {
    setAnchorEl(null);
    setOpenDelete(false);
    // let value = selected.length > 0 ? selected : id;
    DeletePosition(positionId)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(getMsg("A total of 1 record(s) has been deleted"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          getEquipPositionList();
        } else {
          dispatch(getMsg("Something went wrong, please try again later"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  /**
   * This function is used to get the list of positions in a department
   */
  const getEquipPositionList = () => {
    const payload = {
      department_id: id,
    };
    setLoading(true);
    GetPositionList(1, payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setpositionList(res.data);
          setLoading(false);
        } else {
          setLoading(false);
          setpositionList([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  /* Calling the getEquipPositionList function when the component mounts. */
  useEffect(() => {
    getEquipPositionList();
  }, []);

  return (
    <div>
      <Paper mt={3}>
        <div>
          {selected.length || id > 0 ? (
            <Dialog
              open={openDelete}
              onClose={handleDeleteClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Are you sure you want to delete department?"}
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleDeleteClose}>Cancel</Button>
                <Button onClick={deletePosition} autoFocus>
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          ) : (
            <Dialog
              open={openDelete}
              onClose={handleDeleteClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"You haven't selected any item!"}
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleDeleteClose}>OK</Button>
              </DialogActions>
            </Dialog>
          )}
        </div>
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            {loading ? (
              <TableCell colSpan={13} align="center">
                <CircularProgress />
              </TableCell>
            ) : (
              <TableBody>
                {stableSort(positionList, getComparator(order, orderBy)).map(
                  (row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow hover tabIndex={-1} key={row.id}>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          sx={{ lg: 'whiteSpace: "nowrap"' }}
                        >
                          {row.postion_name}
                        </TableCell>
                        <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                          {row.security_level}
                        </TableCell>
                        <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                          {row.equipment_permission_count}
                        </TableCell>
                        <TableCell align="left">
                          <IconButton
                            aria-label="Settings"
                            size="large"
                            aria-owns={anchorEl ? "icon-menu" : undefined}
                            aria-haspopup="true"
                            onClick={(e) => handleActionClick(e, row.id)}
                            style={{ color: "#1374C5" }}
                          >
                            <SettingsIcon />
                          </IconButton>
                          <StyledMenu
                            id="icon-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleActionClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <MenuItem
                              aria-label="details"
                              component={NavLink}
                              to={`/equipment/edit-position/${positionId}`}
                              size="large"
                            >
                              <ListItemIcon>
                                <EditIcon />
                              </ListItemIcon>
                              <ListItemText primary="Edit" pl={1} />
                            </MenuItem>
                            <MenuItem onClick={handleDeleteOpen}>
                              <ListItemIcon>
                                <DeleteIcon />
                              </ListItemIcon>
                              <ListItemText primary="Delete" pl={1} />
                            </MenuItem>
                          </StyledMenu>
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
                {positionList.length === 0 && (
                  <TableRow
                    style={{ height: (dense ? 6 : 0) * positionList.length }}
                  >
                    <TableCell align="center" colSpan={10}>
                      No records found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
const EditDepartment = ({
  editDepartment,
  editDepartmentClose,
  departmentName,
  setDepartmentName,
  id,
  getEquipDepartmentList,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSubmit = () => {
    let payload = {
      department_name: departmentName,
    };
    if (departmentName !== "") {
      UpdateDepartment(id, payload)
        .then((res) => {
          if (res.statusCode === 200) {
            getEquipDepartmentList();
            editDepartmentClose();
            dispatch(getMsg("Department has been updated successfully"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
          }
        })
        .then((err) => {
          console.log(err);
        });
    }
  };
  return (
    <>
      <Dialog
        open={editDepartment}
        onClocse={editDepartmentClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
        aria-labelledby="add-key-cabinet-title"
        aria-describedby="edit-key-cabinet-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="edit-key-cabinet-title"
        >
          <Typography variant="h4">Edit Department</Typography>
          <DialogActions>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={editDepartmentClose}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <form>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <FormControl mt={6}>
                  <TextField
                    name="dept_name"
                    fullWidth
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Department name"
                    autoComplete="off"
                    value={departmentName}
                    onChange={(e) => setDepartmentName(e.target.value)}
                    placeholder=""
                  />
                </FormControl>
                {departmentName === "" && (
                  <FormHelperText sx={{ color: "#d32f2f", marginLeft: "14px" }}>
                    Please enter department name
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <Button color="primary" variant="outlined">
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  sx={{ marginLeft: "10px" }}
                  onClick={handleSubmit}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </form>
          <Box className={classes.borderedBox} sx={{ marginTop: "30px" }}>
            <Typography
              variant="h5"
              component="div"
              align="center"
              gutterBottom
              sx={{ marginTop: "15px" }}
            >
              Positions in this Department
            </Typography>
            <EnhancedTable id={id} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={editDepartmentClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditDepartment;
