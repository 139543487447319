import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  GetTourBuildingList,
  DeleteTourBuilding,
  AddTourBuildings,
  UpdateTourBuilding,
} from "../../api";
import {
  Avatar,
  Button as MuiButton,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Link,
  Typography,
  Tooltip,
  FormLabel,
  RadioGroup,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Radio,
  Box,
  Fade,
  TextareaAutosize,
  FormHelperText,
  Slider,
  Input as MuiInput,
  Paper,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  Table,
  ListItemText,
  Menu,
  ListItemIcon,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import {
  Settings as SettingsIcon,
  Cancel as CancelIcon,
  Error as ErrorIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Create as CreateIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import Toaster from "../tasks/Toaster";
const FormControlSpacing = styled(MuiFormControl)(spacing);
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
const Input = styled(MuiInput)`
  width: 42px;
`;

const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
function AddNewTourBuilding({
  getTourBuilding,
  editTour,
  editStatus,
  setEditStatus,
  setEditTour,
}) {
  // const [status, setStatus] = useState("");
  // const selectStatus = (event) => {
  //   setStatus(event.target.value);
  // };
  const [openSnack, setOpenSnack] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };
  const { building_id, description, id, name, status } = editTour;
  let value = "";
  if (status === "Active") {
    value = "A";
  }
  if (status === "In-Active") {
    value = "I";
  }
  const initialValues = {
    building_id: building_id ? building_id : "",
    name: name ? name : "",
    description: description ? description : "",
    status: value ? value : "",
  };

  const validationSchema = Yup.object().shape({
    building_id: Yup.number()
      .required("Please enter buildingID")
      .typeError("You must specify a number"),
    name: Yup.string().required("Please enter name"),
    description: Yup.string().required("Please enter description"),
  });

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    setIsDisabled(true);
    try {
      const addUpdate = (res) => {
        if (res.statusCode === 200) {
          resetForm();
          setOpenSnack(true);
          getTourBuilding();
          setEditStatus(false);
          setEditTour({});
          setIsDisabled(false);
        } else {
          getTourBuilding();
          setEditTour({});
          resetForm();
        }
      };
      let payload = {
        building_id: values.building_id,
        name: values.name,
        description: values.description,
        status: values.status ? values.status : "I",
      };
      editStatus === true
        ? UpdateTourBuilding(id, payload)
            .then((res) => {
              addUpdate(res);
            })
            .catch((err) => {
              console.log(err);
              setIsDisabled(false);
            })
        : AddTourBuildings(payload)
            .then((res) => {
              addUpdate(res);
            })
            .catch((err) => {
              console.log(err);
              setIsDisabled(false);
            });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  return (
    <>
      <Toaster
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
        message={
          editStatus === false
            ? "Tour building has been submitted successfully"
            : "Tour building has been updated successfully"
        }
      />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          setFieldValue,
          values,
          status,
        }) => (
          <Card mb={6}>
            <form onSubmit={handleSubmit}>
              <CardContent>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <TextField
                        label="BuildingID*"
                        name="building_id"
                        value={values.building_id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.building_id && errors.building_id
                        )}
                        helperText={touched.building_id && errors.building_id}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="BuildingID"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <TextField
                        id="name"
                        label="Name*"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <TextField
                        id="desc"
                        label="Description*"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.description && errors.description
                        )}
                        helperText={touched.description && errors.description}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <InputLabel
                        shrink
                        id="status"
                        sx={{
                          background: "#fff",
                          paddingLeft: "6px",
                          paddingRight: "6px",
                          marginLeft: "-4px",
                        }}
                      >
                        Status
                      </InputLabel>
                      <Select
                        labelId="status"
                        id="select-status"
                        name="status"
                        value={values.status}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        displayEmpty
                      >
                        <MenuItem value="">Select</MenuItem>
                        <MenuItem value="A">Active</MenuItem>
                        <MenuItem value="I">In-Active</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isDisabled}
                      sx={{ width: "175px", marginLeft: "5px" }}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </form>
          </Card>
        )}
      </Formik>
    </>
  );
}
function TourBuildingList({
  bluildingList,
  loading,
  getTourBuilding,
  setEditTour,
  setEditStatus,
}) {
  const [dense] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [tourBuildingId, setTourBuildingId] = useState(null);
  const [openSnack, setOpenSnack] = useState(false);
  const [buildingData, setBuildingData] = useState({});
  const handleActionClick = (event, id, data) => {
    setAnchorEl(event.currentTarget);
    console.log(id);
    console.log(data);
    setBuildingData(data);
    setTourBuildingId(id);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };
  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };
  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };
  const handleDeleteClose = () => {
    setOpenDelete(false);
    setAnchorEl(null);
    // setTourId(null);
  };
  const deleteTourBuilding = () => {
    handleDeleteClose();
    DeleteTourBuilding(tourBuildingId, { status: "D" })
      .then((res) => {
        if (res.statusCode === 200) {
          getTourBuilding();
          setOpenSnack(true);
          setEditTour({});
        } else {
          setEditTour({});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const editHandle = () => {
    setEditTour(buildingData);
    handleActionClose();
    setEditStatus(true);
  };
  return (
    <div>
      <Toaster
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
        message="A total of 1 record(s) have been deleted"
      />
      <Dialog
        open={openDelete}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete tour building?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleDeleteClose}>Cancel</Button>
          <Button onClick={deleteTourBuilding} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Paper>
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                <TableCell component="th">S.No</TableCell>
                <TableCell component="th">BuildingID</TableCell>
                <TableCell component="th">Name</TableCell>
                <TableCell component="th">Description</TableCell>
                <TableCell component="th">Status</TableCell>
                <TableCell component="th" align="right">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <TableCell colSpan={13} align="center">
                <CircularProgress />
              </TableCell>
            ) : (
              <TableBody>
                {bluildingList.map((data, index) => {
                  return (
                    <TableRow hover key={data.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{data.building_id}</TableCell>
                      <TableCell>{data.name}</TableCell>
                      <TableCell>{data.description}</TableCell>
                      <TableCell>{data.status}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          aria-label="Settings"
                          size="large"
                          aria-owns={anchorEl ? "icon-menu" : undefined}
                          aria-haspopup="true"
                          onClick={(event) =>
                            handleActionClick(event, data.id, data)
                          }
                          style={{ color: "#1374C5" }}
                        >
                          <SettingsIcon />
                        </IconButton>
                        <StyledMenu
                          id="icon-menu"
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleActionClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          <MenuItem onClick={editHandle}>
                            <ListItemIcon>
                              <CreateIcon />
                            </ListItemIcon>
                            <ListItemText primary="Edit" pl={1} />
                          </MenuItem>
                          <MenuItem onClick={handleDeleteOpen}>
                            <ListItemIcon>
                              <DeleteIcon />
                            </ListItemIcon>
                            <ListItemText primary="Delete" pl={1} />
                          </MenuItem>
                        </StyledMenu>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {bluildingList.length === 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 6 : 0) * bluildingList.length,
                    }}
                  >
                    <TableCell colSpan={13} align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
function AddTourBuilding() {
  const [bluildingList, setBluildingList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editTour, setEditTour] = useState({});
  const [editStatus, setEditStatus] = useState(false);
  //get tour building list method
  const getTourBuilding = () => {
    setLoading(true);
    GetTourBuildingList()
      .then((res) => {
        if (res.statusCode === 200) {
          setLoading(false);
          setBluildingList(res.data);
        } else {
          setLoading(false);
          setBluildingList([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  //useEffect method
  useEffect(() => {
    getTourBuilding();
  }, []);
  return (
    <>
      <Helmet title="Add New Tour Building" />
      <Typography variant="h3" gutterBottom display="inline">
        Add New Tour Building
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/tours/tour-listing">
          Tour List
        </Link>
        <Typography>Add New Tour Building</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <AddNewTourBuilding
        getTourBuilding={getTourBuilding}
        editTour={editTour}
        editStatus={editStatus}
        setEditStatus={setEditStatus}
        setEditTour={setEditTour}
      />

      <Typography variant="h3" gutterBottom sx={{ marginTop: "40px" }}>
        Tour Bulding List
      </Typography>
      <Divider my={6} />
      <TourBuildingList
        bluildingList={bluildingList}
        loading={loading}
        getTourBuilding={getTourBuilding}
        setEditTour={setEditTour}
        setEditStatus={setEditStatus}
      />
    </>
  );
}

export default AddTourBuilding;
