import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import axios from "axios";
import Toaster from "../tasks/Toaster";
import useAppSelector from "../../hooks/useAppSelector";
import useAppDispatch from "../../hooks/useAppDispatch";
import { CSVLink } from "react-csv";
import { format } from "date-fns";
import {
  GetTourList,
  UpdateTourStatus,
  UserPropertyList,
  ViewTour,
  ExportCheckpoints,
} from "../../api";
import {
  Checkbox,
  Grid,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper as MuiPaper,
  Table,
  TableBody,
  /* FormGroup, */
  TableContainer,
  InputAdornment,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  InputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  Radio,
  CardContent,
  FormLabel,
  RadioGroup,
  Card,
  Box,
  Button,
  ListItemIcon,
  Pagination,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  FormHelperText,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { DateTimePicker, DatePicker } from "@mui/lab";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  AddBox as AddBoxIcon,
  Visibility as VisibilityIcon,
  Settings as SettingsIcon,
  Create as CreateIcon,
  Search as SearchIcon,
  Cached as CachedIcon,
  CheckBox as CheckBoxIcon,
  LocationOn as LocationOnIcon,
  ExpandMore as ExpandMoreIcon,
  LockOpen as LockOpenIcon,
  Cancel as CancelIcon,
  GpsFixed as GpsFixedIcon,
  CalendarToday as CalendarTodayIcon,
  FileCopy as FileCopyIcon,
  Public as PublicIcon,
  Schedule,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { tableCellClasses } from "@mui/material/TableCell";
import TourRow from "./TourRow";
import CopyTour from "./CopyTour";
import ScheduleTour from "./ScheduleTour";
import { useDispatch, useSelector } from "react-redux"; //Imports related to redux
import TourListCol from "../table-columns-json/tours/tourslist";
import { setTourList } from "../../redux/slices/tours/tourTabelColSlice";
import {
  getChecked,
  getSnackOpen,
  getSnackClose,
  getTourRow,
} from "../../redux/slices/tourSlice"; //import related to redux store.
import BuildVisual from "./BuildVisual";
import StyledColumnOptions from "../../helper/StyledColumnOptions";
/* const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing); */
const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      "& a": {
        color:
          theme.palette.mode === "light" ? "#374151" : theme.palette.grey[300],
        textDecoration: "none",
      },
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "140px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);
const StyledRadio = styled((props) => <RadioGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormGroup-root": {
      flexDirection: "row",
    },
  })
);
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
function createData(
  tourname,
  user,
  tourdesc,
  sched,
  buttons,
  active,
  lastcompleted,
  image,
  action
) {
  return {
    tourname,
    user,
    tourdesc,
    sched,
    buttons,
    active,
    lastcompleted,
    image,
    action,
  };
}
const rows = [
  createData(
    "After hours spa tours",
    "Tour of the spa after closing hours",
    "",
    "10",
    0,
    "12/23/2018 10:27",
    "",
    0
  ),
  createData(
    "Garage storage rooms GH",
    "Tour of the Garage storage rooms GH",
    "",
    "16",
    0,
    "12/23/2018 10:28",
    "",
    0
  ),
  createData(
    "Garage storage",
    "Tour of the Garage storage rooms GH",
    "",
    "16",
    0,
    "12/23/2018 10:28",
    "",
    0
  ),
  createData(
    "Garage storage GH",
    "Tour of the Garage storage rooms GH",
    "",
    "16",
    0,
    "12/23/2018 10:28",
    "",
    0
  ),
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}
const headCells = [
  {
    id: "tour_name",
    numeric: true,
    disablePadding: true,
    label: "Tour Name",
  },
  {
    id: "user_name",
    numeric: true,
    disablePadding: true,
    label: "User Name",
  },
  {
    id: "toudesc",
    numeric: true,
    disablePadding: false,
    label: "Tour Description",
  },
  { id: "sched", numeric: true, disablePadding: false, label: "Sched" },

  {
    id: "button",
    numeric: true,
    disablePadding: false,
    label: "Buttons",
  },
  { id: "active", numeric: true, disablePadding: false, label: "Active" },
  {
    id: "lastcompleted",
    numeric: true,
    disablePadding: false,
    label: "Last Completed",
  },
  {
    id: "image",
    numeric: true,
    disablePadding: false,
    label: "Image",
  },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];
const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    tableCalRedux,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all data" }}
          />
        </TableCell>
        {TourListCol.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            sx={{ whiteSpace: "nowrap" }}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={tableCalRedux[headCell.id] === true ? "" : "hide-cell"}
            title={headCell.title || ""}
          >
            <TableSortLabel
              active={
                headCell.id == "TourName" ? orderBy === headCell.id : false
              }
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={
                headCell.id === "TourName"
                  ? createSortHandler(headCell.id)
                  : null
              }
              hideSortIcon={headCell.id == "TourName" ? false : true}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const {
    numSelected,
    totalRecord,
    setSearchInput,
    handleDeleteOpen,
    exportData,
    handleFirstPage,
    tableCalRedux,
    defalutTableCalRedux,
  } = props;
  const [actions, setActions] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setActions(event.target.value);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // Search Dropdown
  const [anchorForm, setAnchorForm] = useState(null);
  const handleClickDropdown = (event) => {
    setAnchorForm(event.currentTarget);
  };

  const handleCloseForm = () => {
    setAnchorForm(null);
  };
  let headers = [
    { label: "Tour Name", key: "tour_name" },
    { label: "User Name", key: "user_name" },
    { label: "Button", key: "button" },
    { label: "Secondary Name", key: "secondary_name" },
    { label: "Location Hint", key: "location_hint" },
    { label: "Critical", key: "critical" },
    { label: "Task Count", key: "task_count" },
    { label: "Schedule Count", key: "schedule_count" },
    { label: "Time", key: "time" },
  ];

  const [state, setState] = React.useState({
    switchone: true,
  });
  const handleSwitchChange = (event) => {
    setState({ ...state, [event.target.tourname]: event.target.checked });
  };
  const { switchone } = state;
  const [value, setValue] = React.useState(null);
  //Equipment log modal
  const [open, setOpen] = React.useState(false);
  const clickBuildVisual = () => {
    setOpen(true);
  };
  const logModalClose = () => {
    setOpen(false);
  };
  // Columns Dropdown
  const [anchorColumn, setAnchorColumn] = React.useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };
  //enter key search
  const enterPress = () => {
    setSearchInput(searchValue);
    handleFirstPage();
  };
  return (
    <Toolbar>
      <div>
        <Paper mt={3}>
          <FormControl mr={2} sx={{ minWidth: 120 }}>
            <InputLabel id="demo-simple-select-label">Actions</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={actions}
              label="Actions"
              MenuProps={{
                PaperProps: {
                  sx: {
                    "& .MuiMenu-list": {
                      boxShadow:
                        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 10%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
                      margin: "0px 3px 5px 3px",
                      padding: "0",
                    },
                  },
                },
              }}
              onChange={handleChange}
            >
              <MenuItem value={"Delete"} onClick={handleDeleteOpen}>
                Delete
              </MenuItem>
            </Select>
          </FormControl>
        </Paper>
      </div>
      <div className="totalRecord">
        {totalRecord > 0 ? (
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content", marginLeft: "15px" }}
          >
            {totalRecord} records found
          </Typography>
        ) : (
          <Typography
            variant="subtitle1"
            mt={3}
            id="tableTitle"
            style={{ width: "max-content", marginLeft: "15px" }}
          >
            {/* Nutrition */}
            {totalRecord} records found
          </Typography>
        )}
      </div>
      <Spacer />
      <div className="searchTool">
        <Box
          mt={3}
          sx={{
            display: "flex",
            flexWrap: "nowrap",
          }}
        >
          <Item sx={{ flexGrow: 1, width: "100%" }}>
            <TextField
              placeholder="Search"
              type="text"
              variant="outlined"
              fullWidth
              // size="small"
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
              InputProps={{
                // sx: {
                //   minWidth: "300px",
                // },
                // startAdornment: (
                //   <InputAdornment position="start">
                //     <SearchIcon />
                //   </InputAdornment>
                // ),

                endAdornment: searchValue && (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      // searchHandleClick();
                      setSearchInput("");
                      setSearchValue("");
                    }}
                  >
                    <CancelIcon />
                  </IconButton>
                ),
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  enterPress();
                }
              }}
            />
          </Item>
          <Item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setSearchInput(searchValue);
                handleFirstPage();
              }}
              sx={{
                borderTopLeftRadius: "0px",
                borderBottomLeftRadius: "0px",
                height: "100%",
                marginLeft: "-3px",
              }}
            >
              <SearchIcon />
              {/* Search */}
            </Button>
          </Item>
        </Box>
      </div>
      <Spacer />
      <div>
        <Paper mt={3}>
          <IconButton
            aria-owns={anchorEl ? "icon-menu" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            variant="contained"
            color="secondary"
          >
            <FilterListIcon />
          </IconButton>
          <StyledFilterMenu
            id="icon-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem
              aria-label="add-tour"
              component={NavLink}
              to="/tours/add-tour"
            >
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="Add Tour" pl={0} />
            </MenuItem>
            <MenuItem onClick={clickBuildVisual}>
              <ListItemIcon>
                <VisibilityIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="Build Visual" pl={0} />
            </MenuItem>
            {/* <MenuItem
              aria-label="geolocate"
              component={NavLink}
              to="/tours/geolocate"
            >
              <ListItemIcon>
                <PublicIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="Geolocate" pl={0} />
            </MenuItem> */}
            <CSVLink
              data={exportData}
              headers={headers}
              onClick={handleClose}
              filename={`SOVA-Tour-Checkpoints-${format(
                new Date(),
                "MM-dd-yyyy"
              )}`}
            >
              <MenuItem>
                <ListItemIcon>
                  <CheckBoxIcon />
                </ListItemIcon>
                <ListItemText
                  size="large"
                  primary="Export Checkpoints CSV "
                  pl={0}
                />
              </MenuItem>
            </CSVLink>

            <MenuItem
              aria-label="add-tour-building"
              component={NavLink}
              to="/tours/add-tour-building"
            >
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="Add Tour Buildings" pl={0} />
            </MenuItem>
            <MenuItem
              aria-owns={anchorColumn ? "columns-dropdown" : undefined}
              aria-haspopup="true"
              onClick={handleClickColumn}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Columns" pl={0} />
            </MenuItem>
          </StyledFilterMenu>
          <StyledColumnDropdown
            id="columns-dropdown"
            anchorEl={anchorColumn}
            open={Boolean(anchorColumn)}
            onClose={handleCloseColumn}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem>
              <Card>
                <CardContent>
                  <Typography variant="h6" component="h2">
                    {
                      Object.keys(tableCalRedux).filter(
                        (k) => tableCalRedux[k] === true
                      ).length
                    }{" "}
                    out of {Object.keys(tableCalRedux).length} visible
                  </Typography>
                  <StyledColumnOptions
                    calStatus={tableCalRedux}
                    updateCalStatus={(data) => {
                      dispatch(setTourList(data));
                    }}
                    listing={TourListCol}
                  />
                  <Toolbar>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        dispatch(setTourList(defalutTableCalRedux));
                      }}
                    >
                      Reset
                    </Button>
                    <Spacer />
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleCloseColumn}
                    >
                      Cancel
                    </Button>
                  </Toolbar>
                </CardContent>
              </Card>
            </MenuItem>
          </StyledColumnDropdown>
        </Paper>
        {open && (
          <BuildVisual
            logModalClose={logModalClose}
            setOpen={setOpen}
            // logModalClose={logModalClose}
            open={open}
          />
        )}
      </div>
    </Toolbar>
  );
};
function EnhancedTable() {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("color");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [tourList, setTourList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [tourId, setTourId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [length, setLength] = useState(null);
  const [checked, setChecked] = useState(false);
  const [newPages, setNewPages] = useState(null);
  const dispatch = useDispatch(); //variable used for dispatching all the actions to the redux store.
  const tableCalRedux = useSelector((state) => state.tourTableCol.tourList);
  const defalutTableCalRedux = useSelector(
    (state) => state.defaultTourTableCol.tourList
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChecked = (event) => {
    setChecked(event.target.checked);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = tourList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, tourname) => {
    const selectedIndex = selected.indexOf(tourname);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, tourname);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    setLength(newSelected.length);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
    setNewPages(newPage);
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
    setPage(0);
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (tourname) => selected.indexOf(tourname) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [ids, setIds] = useState(null);
  const handleActionClick = (event, id, tourId) => {
    setAnchorEl(event.currentTarget);
    setTourId(id);
    setIds(tourId);
    setLength(1);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };

  //Schedule Modal
  const [schedulemodal, setschedulemodal] = React.useState(false);
  const clickScheduleModalOpen = () => {
    setschedulemodal(true);
  };
  const scheduleModalClose = () => {
    setschedulemodal(false);
  };

  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };
  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
    setAnchorEl(null);
    setTourId(null);
  };

  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
    setSelected([]);
  };

  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
    setSelected([]);
  };

  //Copy Tour Dialog Method
  const [copyTour, setCopyTour] = useState(false);

  const copyTourOpen = () => {
    setCopyTour(true);
  };
  const copyTourClose = () => {
    setCopyTour(false);
  };
  //Get Propert list Method
  const [propertyList, setPropertyList] = useState([]);
  const property = () => {
    UserPropertyList()
      .then((res) => {
        if (res.statusCode === 200) {
          setPropertyList(res.data);
        } else {
          setPropertyList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * This function is used to get the list of tours.
   */
  const getTourList = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
      tour_name: searchInput,
      sorting: {
        order: order,
        by: orderBy,
      },
    };
    GetTourList(currentPage, payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setTourList(res.data);
          setLoading(false);
          setTotalRecord(res.meta.total);
          setCurrentPage(res.meta.current_page);
        } else {
          setLoading(false);
          setTourList([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setTotalRecord(0);
      });
  };
  // single tour data
  let viewTour = tourList.find((obj) => obj.id === tourId);
  //Single view Methode
  const [tourView, setTourView] = useState({});
  const [modulearray, setmodulearray] = useState([]);
  const singleTour = () => {
    ViewTour(tourId)
      .then((res) => {
        if (res.statusCode === 200) {
          setTourView(res.data);
          setmodulearray(res.data.weekly_value);
        } else {
          setTourView({});
          setmodulearray([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //Delete Tour Method
  const deleteTour = () => {
    setAnchorEl(null);
    let url = selected.length > 0 ? selected : tourId;
    UpdateTourStatus(url, { status: "DEL" })
      .then((res) => {
        if (res.statusCode === 200) {
          handleDeleteClose();
          setOpenSnack(true);
          getTourList();
          setSelected([]);
        } else {
          handleDeleteClose();
          setSelected([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //export checkpoints method
  const [exportData, setExportData] = useState([]);
  const exportCheckpoints = () => {
    setLoading(true);
    const payload = {
      tour_name: searchInput,
    };
    ExportCheckpoints(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setExportData(res.data);
        } else {
          setExportData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //useEffect method
  useEffect(() => {
    getTourList();
    exportCheckpoints();
  }, [rowsPerPage, currentPage, searchInput, order, orderBy]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(getSnackClose());
    }, 4000);
  }, []);

  return (
    <div>
      <Paper mt={3}>
        <Toaster
          openSnack={openSnack}
          handleCloseSnack={handleCloseSnack}
          message={`A total of ${
            length > 0 ? length : 1
          } record(s) have been deleted`}
        />
        <div>
          {selected.length || tourId != null ? (
            <Dialog
              open={openDelete}
              onClose={handleDeleteClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Are you sure you want to delete tour?"}
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleDeleteClose}>Cancel</Button>
                <Button onClick={(handleDeleteClose, deleteTour)} autoFocus>
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          ) : (
            <Dialog
              open={openDelete}
              onClose={handleDeleteClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"You haven't selected any item!"}
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleDeleteClose}>OK</Button>
              </DialogActions>
            </Dialog>
          )}
        </div>
        <EnhancedTableToolbar
          numSelected={selected.length}
          totalRecord={totalRecord}
          setSearchInput={setSearchInput}
          handleDeleteOpen={handleDeleteOpen}
          exportData={exportData}
          handleFirstPage={handleFirstPage}
          tableCalRedux={tableCalRedux}
          defalutTableCalRedux={defalutTableCalRedux}
        />
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={tourList.length}
              tableCalRedux={tableCalRedux}
            />
            {loading ? (
              <TableCell colSpan={13} align="center">
                <CircularProgress />
              </TableCell>
            ) : (
              <TableBody>
                {stableSort(tourList, getComparator(order, orderBy)).map(
                  (tour, index) => {
                    const isItemSelected = isSelected(tour.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TourRow
                        tour={tour}
                        isItemSelected={isItemSelected}
                        labelId={labelId}
                        handleClick={handleClick}
                        handleChecked={handleChecked}
                        anchorEl={anchorEl}
                        handleActionClick={handleActionClick}
                        handleActionClose={handleActionClose}
                        handleDeleteOpen={handleDeleteOpen}
                        clickScheduleModalOpen={clickScheduleModalOpen}
                        copyTourOpen={copyTourOpen}
                        property={property}
                        tourId={tourId}
                        ids={ids}
                        key={tour.id}
                        singleTour={singleTour}
                        viewTour={viewTour}
                        getTourList={getTourList}
                        tableCalRedux={tableCalRedux}
                      />
                    );
                  }
                )}
                {tourList.length === 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 6 : 0) * tourList.length,
                    }}
                  >
                    <TableCell colSpan={13} align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          }}
        >
          <Pagination
            count={Math.ceil(totalRecord / rowsPerPage)}
            page={currentPage}
            onChange={handleChangePagination}
            color="primary"
            boundaryCount={3}
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <Typography sx={{ marginRight: "10px" }}>
                Rows per page :
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                // label="Age"
                onChange={handleChangeLimit}
              >
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </Box>
          </FormControl>
        </Box>
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      {/* Schedule tour popup */}
      <ScheduleTour
        schedulemodal={schedulemodal}
        scheduleModalClose={scheduleModalClose}
        setschedulemodal={setschedulemodal}
        tourId={tourId}
        tourView={tourView}
        singleTour={singleTour}
        modulearray={modulearray}
        setmodulearray={setmodulearray}
        getTourList={getTourList}
      />
      <CopyTour
        copyTourOpen={copyTourOpen}
        copyTour={copyTour}
        copyTourClose={copyTourClose}
        setCopyTour={setCopyTour}
        propertyList={propertyList}
        tourId={tourId}
        getTourList={getTourList}
      />
    </div>
  );
}

function TourList() {
  const [snackopen, setSnackopen] = useState(true);
  const loaderStatus = useAppSelector((state) => state.counter.loader);
  const SnackState = useSelector((state) => state.tour.snackStatus);
  const messageStatus = useSelector((state) => state.tour.msg);

  const dispatch = useAppDispatch();
  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackopen(false);
  };
  return (
    <React.Fragment>
      {SnackState && (
        <Snackbar
          open={snackopen}
          autoHideDuration={3000}
          onClose={handleSnackClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Alert
            onClose={handleSnackClose}
            severity="success"
            variant="filled"
            sx={{ maxWidth: 600 }}
          >
            {messageStatus}
          </Alert>
        </Snackbar>
      )}
      <Helmet title="Tour List" />
      <Typography variant="h3" gutterBottom display="inline">
        Tour List
      </Typography>

      {/* <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
          Tables
        </Link>
        <Typography>Users</Typography>
      </Breadcrumbs>

      <Divider my={6} /> */}

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default TourList;
