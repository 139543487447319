import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { subMonths } from "date-fns";
// import CustomDateRange from "../../components/form-components/CustomDateRange";
import GoogleMapReact from "google-map-react";
import {
  Checkbox,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  /* FormGroup, */
  TableContainer,
  InputAdornment,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  /* Tooltip, */
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  Radio,
  CardContent,
  FormLabel,
  RadioGroup,
  Card,
  Box,
  Button,
  /* List,
  ListItem, */
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  Chip,
  List,
  ListItem,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Dialog,
  CircularProgress,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import { DatePicker } from "@mui/lab";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  AddBox as AddBoxIcon,
  ListAlt as ListAltIcon,
  Visibility as VisibilityIcon,
  Settings as SettingsIcon,
  Search as SearchIcon,
  ExpandMore as ExpandMoreIcon,
  Map as MapIcon,
  Cancel as CancelIcon,
  CameraAlt as CameraAltIcon,
} from "@mui/icons-material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { spacing, width } from "@mui/system";
import { TourMissedButtonList } from "../../../api";
import TournameDialog2 from "./TournameDialog2";

const Paper = styled(MuiPaper)(spacing);

const TournameDialog1 = (props) => {
  const {
    tourmissedbuttons,
    setTourMissedButtons,
    tourMissedButtonsClose,
    tourDialog,
    tourIdD1,
  } = props;
  //state for dates in Dialoge popups
  const [sDateMissedButton, setSDateMissedButton] = useState(
    subMonths(new Date(), 1)
  );
  const [eDateMissedButton, setEDateMissedButton] = useState(new Date());
  const [dialogLoader, setDialogLoader] = useState(false); //state for dialog loader
  //states for dialog listing
  const [missedTourButtonList, setMissedTourButtonList] = useState([]); //state for list in get-button-missed (D1) outer dialog tourname
  const [totalTourCount, setTotalTourCount] = useState(null);
  // get -userlist-missed button for: (D2) inner dialog of tourname column
  const [bluetoothButton, setBluetoothButton] = React.useState(false);
  const [tagIdD2, setTagIdD2] = useState(""); //state for  getting the tagId in tourname D2
  const [tagDescriptionD2, setTagDescriptionD2] = useState("");
  //handler for (D1) get button missed outer dialog for tourname
  const getMissedTourButtons = () => {
    let formatedsDate = moment(sDateMissedButton).format("YYYY-MM-DD");
    let formatedeDate = moment(eDateMissedButton).format("YYYY-MM-DD");
    let payload = {
      tourId: tourIdD1, //"9976848884", //tourIDD1,
      start_date: formatedsDate, //"2018-12-04",
      end_date: formatedeDate, // "2022-06-01",
    };
    setDialogLoader(true);
    TourMissedButtonList(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setMissedTourButtonList(res.data);
          setTotalTourCount(res.tour_run_stats_count);
        } else {
          setMissedTourButtonList(res.data);
        }
        setDialogLoader(false);
      })
      .catch((err) => {
        setDialogLoader(false);
        console.log(err);
      });
  };
  // get -userlist-missed button for: (D2) inner dialog of tourname column
  const bluetoothButtonOpen = (tagid, tagdescrip) => {
    setBluetoothButton(true);
    setTagIdD2(tagid);
    setTagDescriptionD2(tagdescrip);
  };
  const bluetoothButtonClose = () => {
    setBluetoothButton(false);
  };

  useEffect(() => {
    getMissedTourButtons();
  }, []);
  // Image Modal
  const [openImage, setOpenImage] = useState(false);

  const handleClickOpen = () => {
    setOpenImage(true);
  };

  const handleClose = () => {
    setOpenImage(false);
  };

  return (
    <>
      {/* get button missed: dialog for Tour name outer dialog*/}
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
        open={tourmissedbuttons}
        onClose={tourMissedButtonsClose}
        aria-labelledby="missed-btn-title"
        aria-describedby="missed-btn-description"
      >
        <DialogTitle
          id="missed-btn-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h4" component="div">
            Missed Buttons: {tourDialog}
          </Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setTourMissedButtons(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          <Grid container spacing={4} mt={2} sx={{ padding: "0 24px" }}>
            <Grid item xs={12} md={4}>
              <DatePicker
                id="start-date"
                value={sDateMissedButton}
                onChange={(newValueStartDate) => {
                  setSDateMissedButton(newValueStartDate);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "Start date",
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DatePicker
                id="end-date"
                l
                value={eDateMissedButton}
                onChange={(newValueEndDate) => {
                  setEDateMissedButton(newValueEndDate);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "End date",
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Button
                color="primary"
                variant="contained"
                sx={{ height: "100%" }}
                onClick={getMissedTourButtons}
              >
                Search
              </Button>
            </Grid>
          </Grid>
          <Paper mt={3}>
            <DialogContentText sx={{ padding: "0 24px" }}>
              Total tours: {totalTourCount ? totalTourCount : 0}
            </DialogContentText>
            <TableContainer className="tableContainer">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Button</TableCell>
                    <TableCell>Image</TableCell>
                    <TableCell>Count</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dialogLoader ? (
                    <TableCell
                      colSpan={9}
                      align="center"
                      sx={{
                        height: "50px",
                        paddingTop: "10px",
                        paddingBottom: "5px",
                        "& .MuiCircularProgress-root": {
                          width: "28px !important",
                          height: "28px !important",
                        },
                      }}
                    >
                      <CircularProgress />
                    </TableCell>
                  ) : (
                    <>
                      {missedTourButtonList.map((item, index) => {
                        return (
                          <TableRow hover key={index}>
                            <TableCell
                              sx={{
                                cursor: "pointer",
                                whiteSpace: "nowrap",
                                color: "#0077B5",
                              }}
                              onClick={() => {
                                bluetoothButtonOpen(
                                  item.TagID,
                                  item.TagDescription
                                );
                              }}
                            >
                              {item.TagDescription}
                            </TableCell>
                            <TableCell>
                              <CameraAltIcon
                                onClick={handleClickOpen}
                                sx={{ cursor: "pointer" }}
                              />
                            </TableCell>
                            <TableCell>{item.tourrun_data_count}</TableCell>
                          </TableRow>
                        );
                      })}
                      {missedTourButtonList.length === 0 ? (
                        <TableRow>
                          <TableCell align="center" colSpan={3}>
                            No data for selected date range
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </DialogContent>
        <DialogActions sx={{ padding: "20px 24px" }}>
          <Button
            color="primary"
            variant="outlined"
            onClick={tourMissedButtonsClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openImage} onClose={handleClose}>
        <DialogTitle>Image</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <img
              src={`/static/img/unsplash/body-part.jpg`}
              style={{ width: "300px", height: "250px" }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {bluetoothButton && (
        <TournameDialog2
          bluetoothButton={bluetoothButton}
          setBluetoothButton={setBluetoothButton}
          bluetoothButtonClose={bluetoothButtonClose}
          tagIdD2={tagIdD2}
          tagDescriptionD2={tagDescriptionD2}
          tourIdD1={tourIdD1}
          sDateMissedButton={sDateMissedButton}
          eDateMissedButton={eDateMissedButton}
        />
      )}
    </>
  );
};

export default TournameDialog1;
