import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  NorEmailListing,
  SendNorMail,
  SendNorPdf,
  SingleRecord,
} from "../../../api";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Typography,
  Button,
  Box,
  IconButton,
  FormControlLabel,
  TableContainer,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Card,
  Paper,
  Switch,
  Checkbox,
  Toolbar,
  TableSortLabel,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
function createData(fname, email) {
  return {
    fname,
    email,
  };
}
const rows = [
  createData("Jesicca", "jasicca@gmail.com"),
  createData("Noori", "noori@gmail.com"),
  createData("Anil", "anil@gmail.com"),
  createData("Tamta", "tamta@gmail.com"),
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "fname",
    numeric: false,
    disablePadding: true,
    label: "Full Name",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
];
function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all records",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar>
      <Typography
        sx={{ flex: "1 1 100%" }}
        color="inherit"
        variant="subtitle1"
        component="div"
      >
        {numSelected} selected
      </Typography>
    </Toolbar>
  );
};
EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
function EnhancedTable({ singleData }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("fname");
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(false);
  const [involvedPersonList, setInvolvedPersonList] = useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = involvedPersonList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, fname) => {
    const selectedIndex = selected.indexOf(fname);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, fname);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  console.log(selected);
  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (fname) => selected.indexOf(fname) !== -1;

  const involvedPersons = () => {
    NorEmailListing(singleData.IncidentID)
      .then((res) => {
        if (res.statusCode === 200) {
          setInvolvedPersonList(res.data);
        } else {
          setInvolvedPersonList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Method for sending email
  // const SendNOREmail = () => {
  //   let payload = {
  //     pids: selected,
  //     incident_id: involvedPersonList.id,
  //   };
  //   SendNorMail(payload)
  // };

  useEffect(() => {
    involvedPersons();
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer className="tableContainer">
          <Table
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            stickyHeader
            aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={involvedPersonList.length}
            />
            <TableBody>
              {stableSort(involvedPersonList, getComparator(order, orderBy))
                //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                          onClick={(event) => handleClick(event, row.id)}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.name}
                      </TableCell>
                      <TableCell>{row.email}</TableCell>
                    </TableRow>
                  );
                })}
              {involvedPersonList.length == 0 && (
                <TableRow
                // style={{
                //   height: (dense ? 6 : 6) * totalRecords,
                // }}
                >
                  <TableCell colSpan={3}>
                    No communication record found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <FormControlLabel
        sx={{ display: "none" }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </Box>
  );
}

const SendNor = ({ sendNor, sendNorClose, singleData }) => {
  const downloadNorPdf = () => {
    const payload = {
      incident_id: singleData.IncidentID,
    };
    SendNorPdf(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          const linkSource = `data:application/pdf;base64,${res.data}`;
          const downloadLink = document.createElement("a");
          const fileName = "SEND_NOR_PDF.pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        } else {
          console.log("Something went wrong!");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <Dialog
        aria-labelledby="sendNor-title"
        aria-describedby="sendNor-description"
        open={sendNor}
        onClose={sendNorClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="sendNor-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Select Notice of report
          </Typography>
          <DialogActions>
            <IconButton onClick={sendNorClose} sx={{ marginRight: "-10px" }}>
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select from the email addresses below to send the Notice of Report,
            or NOR{" "}
          </DialogContentText>
          <Paper mt={6}>
            <Card variant="outlined" sx={{ margin: "15px 0", borderBottom: 0 }}>
              <EnhancedTable singleData={singleData} />
            </Card>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={downloadNorPdf}>
            Download Pdf
          </Button>
          <Button
            color="primary"
            variant="contained"
            sx={{ marginLeft: "10px" }}
            // disabled={selected.length < 1}
          >
            Send Nor
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SendNor;
