import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { handleApiResonseErrors } from "../../helper/helper";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../redux/slices/equipmentSlice";
import { AddEquipDepartment } from "../../api";
import {
  Button as MuiButton,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  FormLabel,
  Select,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Link,
  Typography,
  FormHelperText,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Paper,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { spacing } from "@mui/system";
const FormControlSpacing = styled(MuiFormControl)(spacing);

const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;

// Classes Styling
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));

const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
function AddDepartmentForm() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [equipmentCheck, setEquipmentCheck] = useState([]);
  const [errMsg, setErrMsg] = useState("");
  const [pageLoad, setPageLoad] = useState(false);

  useEffect(() => {}, []);

  /* Creating an object called initialValues. */
  const initialValues = {
    department_name: "",
  };

  /* Creating a validation schema for the form. */
  const validationSchema = Yup.object().shape({
    department_name: Yup.string().required("Please enter department name"),
  });

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      let formData = {
        department_name: values.department_name,
      };
      setPageLoad(true);
      AddEquipDepartment(formData)
        .then((res) => {
          if (res.statusCode === 200) {
            dispatch(getSnackOpen());
            dispatch(getSnackAlert("success"));
            dispatch(getMsg("Department has been submitted successfully"));
            navigate({
              pathname: "/equipment/department-list",
            });
          } else {
            dispatch(getSnackClose());
          }
          setPageLoad(false);
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            setErrors({ department_name: error });
            setErrMsg(error);
          }
          dispatch(getSnackClose());
          setPageLoad(false);
        });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
          values,
          status,
        }) => (
          <Card mb={6}>
            <CardContent>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={6}>
                      <TextField
                        fullWidth
                        id="pname"
                        label="Department name*"
                        name="department_name"
                        value={values.department_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.department_name && errors.department_name
                        )}
                        helperText={
                          touched.department_name && errors.department_name
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="No special characters"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      mt={6}
                    >
                      Save department
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        )}
      </Formik>
      {pageLoad && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
}

function AddDepartment() {
  return (
    <React.Fragment>
      <Helmet title="Add Department" />
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Typography variant="h3" gutterBottom display="inline">
            Add Department
          </Typography>
        </Grid>
      </Grid>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/equipment/department-list">
          List Department
        </Link>
        <Typography>Add Department</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <AddDepartmentForm />
    </React.Fragment>
  );
}

export default AddDepartment;
