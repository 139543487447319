import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import NewReference from "./NewReference";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  DialogActions,
  IconButton,
  Typography,
  Paper,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import {
  CancelRounded as CancelRoundedIcon,
  AddBox as AddBoxIcon,
  Settings as SettingsIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  getSnackOpen,
  getSnackClose,
  getSnackMsg,
  getSnackAlert,
} from "../../../redux/slices/reportSlice";
import Toaster from "../../tasks/Toaster";
import { makeStyles } from "@mui/styles";
import { ReferenceListing, ViewReference, DeleteReference } from "../../../api";
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const References = ({
  referenceOpen,
  referenceClose,
  reference,
  setReference,
  incidentId,
}) => {
  const [refList, setRefList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refInvolvedId, setRefInvolvedId] = useState(null); //state for getting the vehicle id
  const [editRefData, setEditRefData] = useState({});
  const snackbarStatus = useSelector((state) => state.report.snackStatus);
  const snackbarMsg = useSelector((state) => state.report.snackMsg);
  const snackbarAlert = useSelector((state) => state.report.snackAlert);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [refLoader, setRefLoader] = useState(false);
  //method for close the snackbar
  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(getSnackClose());
  };
  // New Reference Modal
  const [newReference, setNewReference] = React.useState(false);
  const newReferenceOpen = () => {
    setNewReference(true);
  };
  const newReferenceClose = () => {
    setNewReference(false);
    setEditRefData({});
  };

  //Method for showing listing for involved vehicle
  const getInvolvedReferenceListing = () => {
    if (incidentId) {
      setLoading(true);
      ReferenceListing(incidentId)
        .then((res) => {
          if (res.statusCode == 200) {
            console.log("++++", res.data);
            setRefList(res.data);
          } else {
            setRefList([]);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  //Method to delete Reference
  const deleteReferenceInvolved = () => {
    referenceDropdownClose();
    setRefLoader(true);
    DeleteReference(refInvolvedId)
      .then((res) => {
        setRefLoader(false);
        if (res.statusCode === 200) {
          dispatch(getSnackMsg("Record has been deleted successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
          getInvolvedReferenceListing();
        } else {
          dispatch(getSnackMsg("Something went wrong, please try again "));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
        setRefLoader(false);
      });
  };

  const getReferenceDetail = () => {
    setRefLoader(true);
    ViewReference(refInvolvedId)
      .then((res) => {
        setRefLoader(false);
        if (res.statusCode === 200) {
          setEditRefData(res.data);
        } else {
          setEditRefData({});
        }
      })
      .catch((err) => {
        console.log(err);
        setRefLoader(false);
      });
  };
  const editReference = () => {
    getReferenceDetail();
    newReferenceOpen();
    referenceDropdownClose();
  };
  useEffect(() => {
    getInvolvedReferenceListing();
  }, []);

  const [referenceDropdown, setReferenceDropdown] = React.useState(null);

  const referenceDropdownClick = (event, refId) => {
    setReferenceDropdown(event.currentTarget);
    setRefInvolvedId(refId);
  };
  const referenceDropdownClose = () => {
    setReferenceDropdown(null);
  };
  return (
    <>
      <Dialog
        aria-labelledby="reference-title"
        aria-describedby="reference-description"
        open={reference}
        onClose={referenceClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "800px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4">References</Typography>
          <DialogActions>
            <IconButton sx={{ marginRight: "-10px" }} onClick={referenceClose}>
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent sx={{ paddingLeft: "0", paddingRight: "0" }}>
          <DialogContentText sx={{ paddingLeft: "24px", paddingRight: "24px" }}>
            <Typography variant="h4" component="div" gutterBottom>
              INSTRUCTIONS:
            </Typography>
            <Typography variant="P" component="div" gutterBottom>
              1. Click the plus icon below
            </Typography>
            <Typography variant="P" component="div" gutterBottom>
              2. In the popup, select the type of record you wish to associate
              with this incident report
            </Typography>
            <Typography variant="P" component="div" gutterBottom>
              3. Enter ID's to associate records for person of interest, lost
              and found, or other incident reports. ID values can be found in
              the POI and lost and found lists under the column "ID". To get the
              ID for another incident report to link with this one, open the
              other incident report for editing and you will see "Ref ID" at the
              top. Add that value in the field.
            </Typography>
          </DialogContentText>
          <Paper mt={3}>
            <IconButton
              onClick={newReferenceOpen}
              sx={{ marginLeft: "24px", marginTop: "20px" }}
            >
              <AddBoxIcon />
            </IconButton>
            <TableContainer className="tableContainer">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Reference Type</TableCell>
                    <TableCell>ID Number</TableCell>
                    <TableCell>Date Added</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                {loading ? (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {refList.map((item) => {
                      return (
                        <TableRow>
                          <TableCell>{item.reference_type}</TableCell>
                          <TableCell>{item.reference_id}</TableCell>
                          <TableCell>{item.created}</TableCell>
                          <TableCell>
                            <IconButton
                              aria-label="reference-dropdown"
                              size="large"
                              aria-owns={
                                referenceDropdown
                                  ? "reference-dropdown"
                                  : undefined
                              }
                              aria-haspopup="true"
                              onClick={(e) => {
                                referenceDropdownClick(e, item.id);
                              }}
                              style={{ color: "#1374C5" }}
                            >
                              <SettingsIcon />
                            </IconButton>
                            <StyledMenu
                              id="reference-dropdown"
                              anchorEl={referenceDropdown}
                              open={Boolean(referenceDropdown)}
                              onClose={referenceDropdownClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <MenuItem onClick={editReference}>
                                <ListItemIcon>
                                  <EditIcon />
                                </ListItemIcon>
                                <ListItemText primary="Edit" pl={1} />
                              </MenuItem>
                              <MenuItem onClick={deleteReferenceInvolved}>
                                <ListItemIcon>
                                  <DeleteIcon />
                                </ListItemIcon>
                                <ListItemText primary="Delete" pl={1} />
                              </MenuItem>
                            </StyledMenu>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {refList.length == 0 && (
                      <TableRow>
                        <TableCell colSpan={4} align="center">
                          No record added
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={referenceClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {newReference && (
        <NewReference
          newReference={newReference}
          setNewReference={setNewReference}
          newReferenceClose={newReferenceClose}
          incidentId={incidentId}
          getInvolvedReferenceListing={getInvolvedReferenceListing}
          editRefData={editRefData}
          refLoader={refLoader}
          setRefLoader={setRefLoader}
        />
      )}
      {snackbarStatus && (
        <Toaster
          openSnack={snackbarStatus}
          handleCloseSnack={handleCloseSnack}
          severity={snackbarAlert}
          message={snackbarMsg}
        />
      )}
      {refLoader && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
};

export default References;
