import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import styled from "styled-components/macro";
import CustomTextField from "../../../components/form-components/CustomTextField";
import CustomSelection from "../../../components/form-components/CustomSelection";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControl as MuiFormControl,
  Accordion as MuiAccordion,
  FormLabel,
  FormControlLabel,
  Tooltip,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  AccordionDetails,
  AccordionSummary,
  InputLabel as MuiInputLabel,
  FormHelperText,
  TextareaAutosize as MuiTextareaAutosize,
  Select,
  MenuItem,
} from "@mui/material";
import {
  CancelRounded as CancelRoundedIcon,
  ExpandMore as ExpandMoreIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  FormatColorFill as FormatColorFillIcon,
  AddBox as AddBoxIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import * as Yup from "yup";
import { SaveInvolvedWitness, DriversList } from "../../../api";
import { DateTimePicker, DatePicker } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import {
  getSnackOpen,
  getSnackClose,
  getSnackMsg,
  getSnackAlert,
} from "../../../redux/slices/reportSlice";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const Image = styled.img`
  max-width: 100%;
  height: 100%;
  display: block;
  border-radius: 5px;
  position: relative;
  z-index: 0;
  object-fit: cover;
`;
const ImageWrapper = styled.div`
  height: 100%;
  &:before {
  }
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
  padding-left: 6px;
  padding-right: 6px;
  margin-left: -4px;
`;
const Accordion = styled(MuiAccordion)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
`;
// Classes Styling
const useStyles = makeStyles((theme) => ({
  accordion: {
    borderTop: 0,
    "& .MuiAccordion-root": {
      "& .MuiAccordionSummary-root": {
        padding: "0 12px",
        opacity: ".5",
      },
      "& .MuiAccordionDetails-root": {
        padding: "8px 12px 12px",
      },
    },
    "& .MuiAccordion-root::before": {
      display: "none",
    },
    "& .Mui-expanded": {
      minHeight: "auto",
      margin: "7px 0 7px",
    },
  },
  textAreaControl: {
    position: "relative",
    "& .MuiFormLabel-root": {
      position: "absolute",
      top: "-24px",
      padding: "0 5px",
      zIndex: "1",
      left: "-5px",
      fontSize: "10px",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
}));
const TextareaAutosize = styled(MuiTextareaAutosize)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 120px !important;
  background: transparent;
  padding: 20px 12px 15px;
  font-size: 13px;
  opacity: 0.8;
  font-weight: 200;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  letter-spacing: normal;
`;
const TextAreaControl = styled((props) => <FormControl {...props} />)(
  ({ theme }) => ({
    "& textarea:focus-visible": {
      outlineColor: "#2f63be",
      border: "0px",
    },
  })
);

const WitnessStatement = ({
  witnessStatementModal,
  witnessStatementModalClose,
  incidentId,
  getInvolvedWitnessListing,
  setPageLoad,
  editWIData,
  witnessActionStatus,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const initialValues = {
    involved_person: editWIData
      ? editWIData.involved_person
        ? editWIData.involved_person
        : ""
      : "",
    incident_description: editWIData
      ? editWIData.incident_description
        ? editWIData.incident_description
        : ""
      : "",
    location_clean: editWIData
      ? editWIData.location_clean
        ? editWIData.location_clean
        : ""
      : "",
    location_clean_description: editWIData
      ? editWIData.location_clean_description
        ? editWIData.location_clean_description
        : ""
      : "",
    location_dry: editWIData
      ? editWIData.location_dry
        ? editWIData.location_dry
        : ""
      : "",
    location_dry_description: editWIData
      ? editWIData.location_dry_description
        ? editWIData.location_dry_description
        : ""
      : "",
    any_puddles: editWIData
      ? editWIData.any_puddles
        ? editWIData.any_puddles
        : ""
      : "",
    any_puddles_description: editWIData
      ? editWIData.any_puddles_description
        ? editWIData.any_puddles_description
        : ""
      : "",
    any_defects: editWIData
      ? editWIData.any_defects
        ? editWIData.any_defects
        : ""
      : "",
    any_defects_description: editWIData
      ? editWIData.any_defects_description
        ? editWIData.any_defects_description
        : ""
      : "",
    lightings: editWIData
      ? editWIData.lightings
        ? editWIData.lightings
        : ""
      : "",
    shoes_type_condition: editWIData
      ? editWIData.shoes_type_condition
        ? editWIData.shoes_type_condition
        : ""
      : "",
    wear_glasses: editWIData
      ? editWIData.wear_glasses
        ? editWIData.wear_glasses
        : ""
      : "",
    any_bundles: editWIData
      ? editWIData.any_bundles
        ? editWIData.any_bundles
        : ""
      : "",
    see_incident: editWIData
      ? editWIData.see_incident
        ? editWIData.see_incident
        : ""
      : "",
    witness_location: editWIData
      ? editWIData.witness_location
        ? editWIData.witness_location
        : ""
      : "",
    comment_attitude: editWIData
      ? editWIData.comment_attitude
        ? editWIData.comment_attitude
        : ""
      : "",
  };
  const validationSchema = Yup.object().shape({
    involved_person: Yup.string().required("Please select involved person"),
    incident_description: Yup.string().required(
      "Please enter incident description"
    ),
    location_clean: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Yes", "No"]),
    location_clean_description: Yup.string().when(
      ["location_clean"],
      (same, schema) => {
        return same === "Yes"
          ? schema
          : schema.required("Please enter the describe environment");
      }
    ),
    location_dry: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Yes", "No"]),
    location_dry_description: Yup.string().when(
      ["location_dry"],
      (same, schema) => {
        return same === "Yes"
          ? schema
          : schema.required("Please enter the walking surfaces");
      }
    ),
    any_puddles: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Yes", "No"]),
    any_defects: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Yes", "No"]),
    lightings: Yup.string().required("Please enter details"),
    shoes_type_condition: Yup.string().required("Please enter"),
    wear_glasses: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Yes", "Not", "No"]),
    any_bundles: Yup.string().required("Please enter details"),
    see_incident: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Yes", "No"]),
    incident_description: Yup.string().required("Please enter details"),
    witness_location: Yup.string().required("Please enter details"),
    comment_attitude: Yup.string().required("Please enter details"),
  });

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setPageLoad(true);
      console.log("++++MY FORM VALUES+++++", values);
      let formData = {
        id: editWIData ? (editWIData.id ? editWIData.id : null) : null,
        incident_id: incidentId,
        involved_person: values.involved_person,
        incident_description: values.incident_description,
        location_clean: values.location_clean,
        location_clean_description: values.location_clean_description,
        location_dry: values.location_dry,
        location_dry_description: values.location_dry_description,
        any_puddles: values.any_puddles,
        any_puddles_description: values.any_puddles_description,
        any_defects: values.any_defects,
        any_defects_description: values.any_defects_description,
        lightings: values.lightings,
        shoes_type_condition: values.shoes_type_condition,
        wear_glasses: values.wear_glasses,
        any_bundles: values.any_bundles,
        see_incident: values.see_incident,
        witness_location: values.witness_location,
        comment_attitude: values.comment_attitude,
      };
      SaveInvolvedWitness(formData)
        .then((res) => {
          setPageLoad(false);
          if (res.statusCode === 200) {
            console.log("+++", res.data);
            getInvolvedWitnessListing();
            dispatch(getSnackMsg(res.message));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            witnessStatementModalClose();
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          } else {
            dispatch(getSnackMsg("Something went wrong, please try again"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          }
        })
        .catch((err) => {
          setPageLoad(false);
          console.log(err);
          dispatch(getSnackMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        });
      // await
      //   timeOut(1500);
      // resetForm();
      // setStatus({ sent: true });
      // setSubmitting(false);
    } catch (error) {
      setPageLoad(false);
      console.log(error);
      // setStatus({ sent: false });
      // setErrors({ submit: error.message });
      // setSubmitting(false);
    }
  };

  const [PIData, setPIData] = useState([]);
  //method for getting year dropdown
  const getPersonDropList = () => {
    setPageLoad(true);
    DriversList(incidentId)
      .then((res) => {
        setPageLoad(false);
        if (res.statusCode == 200) {
          setPIData(res.data);
        }
      })
      .catch((err) => {
        setPageLoad(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getPersonDropList();
  }, []);

  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "900px",
            },
          },
        }}
        aria-labelledby="w-statement-title"
        aria-describedby="w-statement-description"
        open={witnessStatementModal}
        onClose={witnessStatementModalClose}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="w-statement-title"
        >
          <Typography variant="h4">Witness Statement</Typography>
          <DialogActions>
            <IconButton
              onClick={witnessStatementModalClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        {/* New */}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue,
            status,
          }) => (
            <>
              <DialogContent>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={6}>
                    <Grid item xs={12} md={12}>
                      <Typography>
                        This form should be filled out by the witness, or while
                        interviewing the witness directly:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl mt={3}>
                        <InputLabel
                          shrink
                          id="department"
                          sx={{
                            paddingLeft: "6px",
                            paddingRight: "6px",
                            marginLeft: "-4px",
                          }}
                        >
                          Involved Person (select your name) *
                        </InputLabel>
                        <Select
                          labelId="driver"
                          id="driver-select"
                          name="involved_person"
                          disabled={witnessActionStatus}
                          // label="Driver name *"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={values.involved_person}
                          onChange={(e) => {
                            setFieldValue("involved_person", e.target.value);
                          }}
                          onBlur={handleBlur}
                          error={Boolean(
                            touched.involved_person && errors.involved_person
                          )}
                          helperText={
                            touched.involved_person && errors.involved_person
                          }
                          fullWidth
                          displayEmpty
                        >
                          <MenuItem disabled value="">
                            Please select
                          </MenuItem>
                          {PIData.map((data) => {
                            return (
                              <MenuItem value={data.value} key={data.key}>
                                {data.key}
                              </MenuItem>
                            );
                          })}
                          {/* <MenuItem value="unknown">Unknown</MenuItem> */}
                        </Select>
                        <FormHelperText sx={{ color: "#d32f2f" }}>
                          {touched.involved_person && errors.involved_person}
                        </FormHelperText>
                        {/* <CustomSelection
                          name="involved_person"
                          label="Involved person (select your name)*"
                          options={involvedPerson}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        /> */}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <FormControl>
                        <CustomTextField
                          name="incident_description"
                          label="Describe what you saw or heard: *"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled={witnessActionStatus}
                          multiline={true}
                          rows={3}
                          autoComplete="off"
                          placeholder="Please be as specific as possible"
                        />
                        {/* <TextAreaControl
                          className={classes.textAreaControl}
                          mt={4}
                        >
                          <FormControl>
                            <InputLabel id="describe-witness">
                              Describe what you see or heared: *
                            </InputLabel>
                            <CustomTextField
                              name="incident_description"
                              // label="Describe what you see or heared: *"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              multiline={true}
                              rows={4}
                              autoComplete="off"
                              placeholder="Please be as specific as possible"
                            />
                          </FormControl>
                        </TextAreaControl> */}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl>
                        <FormLabel>Area clear of trip hazards? *</FormLabel>
                        <RadioGroup
                          aria-labelledby="trip-hazards"
                          name="location_clean"
                          value={values.location_clean.toString()}
                          onChange={(e) => {
                            setFieldValue(
                              "location_clean",
                              e.currentTarget.value
                            );
                            setFieldValue("location_clean_description", "");
                          }}
                          row
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Yes"
                            value="Yes"
                            disabled={witnessActionStatus}
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="No"
                            value="No"
                            disabled={witnessActionStatus}
                          />
                        </RadioGroup>
                        <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                          {touched.location_clean && errors.location_clean}
                        </FormHelperText>
                      </FormControl>
                      <FormControl
                        mt={3}
                        sx={{
                          display:
                            values.location_clean === "No" ? "block" : "none",
                        }}
                      >
                        <CustomTextField
                          name="location_clean_description"
                          autoComplete="off"
                          placeholder="Describe environment"
                          disabled={witnessActionStatus}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl>
                        <FormLabel>Walking surface was dry? *</FormLabel>
                        <RadioGroup
                          aria-labelledby="trip-hazards"
                          name="location_dry"
                          value={values.location_dry.toString()}
                          onChange={(e) => {
                            setFieldValue(
                              "location_dry",
                              e.currentTarget.value
                            );
                            setFieldValue("location_dry_description", "");
                          }}
                          row
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Yes"
                            value="Yes"
                            disabled={witnessActionStatus}
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="No"
                            value="No"
                            disabled={witnessActionStatus}
                          />
                        </RadioGroup>
                        <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                          {touched.location_dry && errors.location_dry}
                        </FormHelperText>
                      </FormControl>
                      <FormControl
                        mt={3}
                        sx={{
                          display:
                            values.location_dry === "No" ? "block" : "none",
                        }}
                      >
                        <CustomTextField
                          name="location_dry_description"
                          autoComplete="off"
                          placeholder="Describe walking surfaces"
                          disabled={witnessActionStatus}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl>
                        <FormLabel>Any puddles? *</FormLabel>
                        <RadioGroup
                          aria-labelledby="any-puddles"
                          name="any_puddles"
                          value={values.any_puddles.toString()}
                          onChange={(e) => {
                            setFieldValue("any_puddles", e.currentTarget.value);
                            setFieldValue("any_puddles_description", "");
                          }}
                          row
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Yes"
                            value="Yes"
                            disabled={witnessActionStatus}
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="No"
                            value="No"
                            disabled={witnessActionStatus}
                          />
                        </RadioGroup>
                        <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                          {touched.any_puddles && errors.any_puddles}
                        </FormHelperText>
                      </FormControl>
                      <FormControl
                        mt={3}
                        sx={{
                          display:
                            values.any_puddles === "Yes" ? "block" : "none",
                        }}
                      >
                        <CustomTextField
                          name="any_puddles_description"
                          autoComplete="off"
                          placeholder="Wet floor signs posted or not"
                          disabled={witnessActionStatus}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl>
                        <FormLabel>
                          Property defects that may have contributed? *
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="property-defects"
                          name="any_defects"
                          value={values.any_defects.toString()}
                          onChange={(e) => {
                            setFieldValue("any_defects", e.currentTarget.value);
                            setFieldValue("any_defects_description", "");
                          }}
                          row
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Yes"
                            value="Yes"
                            disabled={witnessActionStatus}
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="No"
                            value="No"
                            disabled={witnessActionStatus}
                          />
                        </RadioGroup>
                        <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                          {touched.any_defects && errors.any_defects}
                        </FormHelperText>
                      </FormControl>
                      <FormControl
                        mt={3}
                        sx={{
                          display:
                            values.any_defects === "Yes" ? "block" : "none",
                        }}
                      >
                        <CustomTextField
                          name="any_defects_description"
                          autoComplete="off"
                          placeholder="Provide specifies regarding defects"
                          disabled={witnessActionStatus}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl mt={3}>
                        <CustomTextField
                          name="lightings"
                          label="Describe lighting at time of incident: *"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled={witnessActionStatus}
                          autoComplete="off"
                          placeholder="Daytime, lights on, lights out, dim, etc."
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl mt={3}>
                        <CustomTextField
                          name="shoes_type_condition"
                          label="Describe victims footwear: *"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled={witnessActionStatus}
                          autoComplete="off"
                          placeholder="Heels, dress, athletic, sandles, barefoot etc."
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl>
                        <FormLabel>Do you wear corrective lenses? *</FormLabel>
                        <RadioGroup
                          aria-labelledby="property-defects"
                          name="wear_glasses"
                          value={values.wear_glasses.toString()}
                          onChange={(e) => {
                            setFieldValue(
                              "wear_glasses",
                              e.currentTarget.value
                            );
                          }}
                          row
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Yes"
                            value="Yes"
                            disabled={witnessActionStatus}
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="Not wearing"
                            value="Not"
                            disabled={witnessActionStatus}
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="No"
                            value="No"
                            disabled={witnessActionStatus}
                          />
                        </RadioGroup>
                        <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                          {touched.wear_glasses && errors.wear_glasses}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl mt={3}>
                        <CustomTextField
                          name="any_bundles"
                          label="If providing documentation, list here *"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled={witnessActionStatus}
                          autoComplete="off"
                          placeholder="Photos, documents, sketch drawings, etc."
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl>
                        <FormLabel>Any injuries noticed? *</FormLabel>
                        <RadioGroup
                          aria-labelledby="injuries-noticed"
                          name="see_incident"
                          value={values.see_incident.toString()}
                          onChange={(e) => {
                            setFieldValue(
                              "see_incident",
                              e.currentTarget.value
                            );
                          }}
                          row
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Yes"
                            value="Yes"
                            disabled={witnessActionStatus}
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="No"
                            value="No"
                            disabled={witnessActionStatus}
                          />
                        </RadioGroup>
                        <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                          {touched.see_incident && errors.see_incident}
                        </FormHelperText>
                      </FormControl>
                      <FormControl
                        mt={3}
                        sx={{
                          display:
                            values.see_incident === "Yes" ? "block" : "none",
                        }}
                      >
                        <CustomTextField
                          name="incident_description"
                          // label="Describe what you saw or heard: *"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled={witnessActionStatus}
                          multiline={true}
                          rows={3}
                          autoComplete="off"
                          placeholder="Please clearly describe injuries seen"
                        />
                        {/* <TextAreaControl className={classes.textAreaControl}>
                          <TextareaAutosize
                            name="incident_description"
                            placeholder="Please clearly describe injuries seen"
                          />
                        </TextAreaControl> */}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl>
                        <CustomTextField
                          name="witness_location"
                          label="Who or what caused the incident to occur? *"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled={witnessActionStatus}
                          autoComplete="off"
                          placeholder="Persons, vehicles, etc."
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <FormControl>
                        <CustomTextField
                          name="comment_attitude"
                          label="Anything about the incident appear unusual or
                              suspicious? *"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled={witnessActionStatus}
                          multiline={true}
                          rows={3}
                          autoComplete="off"
                          placeholder="Please share details to assist with the investigation"
                        />
                        {/* <TextAreaControl className={classes.textAreaControl}>
                          <FormControl>
                            <InputLabel id="v-status">
                              Anything about the incident appear unusual or
                              suspicious? *
                            </InputLabel>
                            <TextareaAutosize
                              name="comment_attitude"
                              placeholder="Please share details to assist with the investigation"
                            />
                          </FormControl>
                        </TextAreaControl> */}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <DialogActions sx={{ paddingRight: "24px" }}>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={witnessStatementModalClose}
                    >
                      Close
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      sx={{ marginLeft: "10px" }}
                      disabled={witnessActionStatus}
                    >
                      Save
                    </Button>
                  </DialogActions>
                </form>
              </DialogContent>
            </>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default WitnessStatement;
