import React, { useRef, useState, useReducer, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet-async";
import CustomAlert from "../../components/form-components/CustomAlert";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Formik } from "formik";
import moment from "moment";
import CustomSelect from "../../components/form-components/CustomSelect";
import CustomTextField from "../../components/form-components/CustomTextField";
import CustomCheckbox from "../../components/form-components/CustomCheckbox";
import CustomDateTimePicker from "../../components/form-components/CustomDateTimePicker";
import { updateShowAlert, updateText } from "../../redux/slices/mainSlice";
import TextField from "@mui/material/TextField";
import Disposition from "../../components/modals/lostandfound/Disposition";
import LockItem from "../../components/modals/lostandfound/LockItem";
import GoogleAutoCompleteField from "../../components/form-components/GoogleAutoCompleteField";
import * as Yup from "yup";

import {
  CategoryList,
  EstimatedList,
  SingleRecord,
  EditLostFound,
  CountryList,
  StorageLocationList,
  DepartmentList,
} from "../../api";
import SubItems from "./inner-component/SubItems";
import ActivityLog from "./inner-component/ActivityLog";
import classNames from "classnames";
import { getFileBase64, handleApiResonseErrors } from "../../helper/helper";

import {
  Button as MuiButton,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  FormLabel,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Link,
  Typography,
  Tooltip,
  FormHelperText,
  Box,
  Menu,
  Checkbox,
  FormControlLabel,
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  TextareaAutosize as MuiTextareaAutosize,
  FormGroup,
  RadioGroup,
  Radio,
  Paper,
  CircularProgress,
  Chip,
  Autocomplete,
  IconButton,
  MenuItem,
  ListItemText,
} from "@mui/material";
import {
  Error as ErrorIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  ExpandMore as ExpandMoreIcon,
  Menu as MenuIcon,
  Info as InfoIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import Editlocked from "./inner-component/Editlocked";
import Commlog from "./inner-component/Commlog";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};
  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const Accordion = styled(MuiAccordion)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
`;
const StyledLink = styled(NavLink)`
  text-decoration: none;
  color: inherit;
`;

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
// Classes Styling
const useStyles = makeStyles((theme) => ({
  noPaddingAccordion: {
    "& .MuiAccordion-root": {
      "& .MuiAccordionDetails-root": {
        padding: "8px 0 0 !important",
      },
    },
  },
  accordion: {
    marginTop: 16,
    borderTop: 0,
    "& .MuiAccordion-root": {
      "& .MuiAccordionSummary-root": {
        padding: "0 12px",
        opacity: ".5",
      },
      "& .MuiAccordionDetails-root": {
        padding: "8px 12px 12px",
      },
    },
    "& .MuiAccordion-root::before": {
      display: "none",
    },
    "& .Mui-expanded": {
      minHeight: "auto",
    },
  },
  textAreaControl: {
    position: "relative",
    "& .MuiFormLabel-root": {
      position: "absolute",
      top: "-24px",
      padding: "0 5px",
      zIndex: "1",
      left: "-5px",
      fontSize: "10px",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const TextareaAutosize = styled(MuiTextareaAutosize)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 120px !important;
  background: transparent;
  padding: 20px 12px 15px;
  font-size: 13px;
  opacity: 0.8;
  font-weight: 200;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  letter-spacing: normal;
`;
const TextAreaControl = styled((props) => <FormControl {...props} />)(
  ({ theme }) => ({
    "& textarea:focus-visible": {
      outlineColor: "#2f63be",
      border: "0px",
    },
  })
);
const StyledTooltip = ({ title, children, ...props }) => (
  <Tooltip
    {...props}
    title={title}
    /* arrow */
    componentsProps={{
      tooltip: {
        sx: {
          lineHeight: "1.6",
          fontSize: "10px",
          marginRight: "-15px",
          maxWidth: "235px",
        },
      },
    }}
  >
    {children}
  </Tooltip>
);
const StyledRadio = styled((props) => <RadioGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormGroup-root": {
      flexDirection: "row",
      flexWrap: "nowrap",
    },
    marginTop: "5px",
  })
);
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFormControl = styled((props) => <FormGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormGroup-root": {
      display: "block",
      maxHeight: "250px",
      marginTop: "5px",
      overflowY: "auto",
      "& .MuiFormControlLabel-root": {
        display: "block",
      },
    },
  })
);
const Image = styled.img`
  max-width: initial;
  margin: 20px auto 0;
  width: 100%;
`;

const verify_owners = [
  {
    value: "1",
    key: "Identification verified",
  },
  {
    value: "2",
    key: "Registered guest, item found in same room as guest, stay duration verified",
  },
  {
    value: "3",
    key: "Person was able to describe the item in significant detail without prompting",
  },
  {
    value: "4",
    key: "Lost location matches found location",
  },
  {
    value: "5",
    key: "Owner authorized giving item to person named above",
  },
  {
    value: "6",
    key: "Owner provided correct unlock password for electronic device",
  },
];

const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function AddLostAndFoundForm(props) {
  const params = useParams();
  const classes = useStyles();
  const navigate = useNavigate();

  // Redux
  const showAlert = useSelector((state) => state.main.showAlert);
  const dispatch = useDispatch();

  // Item Information Expansion
  const [iteminfoexpand, setItemInfo] = useState("expand-item-info");
  const handleItemExpansion = (panel) => (event, newExpanded) => {
    setItemInfo(newExpanded ? panel : false);
  };
  // Image Expansion
  const [imageexpand, setImageExpand] = useState("");
  const handleImgExpansion = (panel) => (event, newExpanded) => {
    setImageExpand(newExpanded ? panel : false);
  };
  // Lost Expansion
  const [lostexpand, setLostExpand] = useState("");
  const handleLostExpansion = (panel) => (event, newExpanded) => {
    setLostExpand(newExpanded ? panel : false);
  };
  // Found Expansion
  const [expandfound, setFoundExpand] = useState("");
  const handleFoundExpansion = (panel) => (event, newExpanded) => {
    setFoundExpand(newExpanded ? panel : false);
  };
  // Claimed Expansion
  const [expandclaimed, setClaimedExpand] = useState("");
  const handleClaimedExpansion = (panel) => (event, newExpanded) => {
    setClaimedExpand(newExpanded ? panel : false);
  };
  // Subitems Expansion
  const [expandsubitems, setSubItemsExpand] = useState("");
  const handleSubItemsExpansion = (panel) => (event, newExpanded) => {
    setSubItemsExpand(newExpanded ? panel : false);
  };
  // Activity Log Expansion
  const [expandactivitylog, setActivityLogExpand] = useState("");
  const handleActivityLogExpansion = (panel) => (event, newExpanded) => {
    setActivityLogExpand(newExpanded ? panel : false);
  };

  // Item Category
  const [itemcatglist, setItemCatgList] = useState([]);
  const [subItemCount, setSubItemCount] = useState(0);
  const [urlId, seturlId] = useState(params.id);
  // Estimated Value
  const [itemestlist, setItemEstList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [storageLocation, setStorageLocation] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [recordData, setRecordData] = useState({});
  const [preLoader, setPreLoader] = useState(true);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [showWebCam, setShowWebCam] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDispositionModal, setShowDispositionModal] = useState(false);
  const [showLockItemModal, setShowLockItemModal] = useState(false);
  const [disabledFields, setDisabledFields] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const uploadedFileRef = useRef();

  // edit locked dialog

  const [editLockedDialog, setEditLockedDialog] = useState(false);
  const editLockedDialogOpen = () => {
    setEditLockedDialog(true);
  };

  const editLockedDialogClose = () => {
    setEditLockedDialog(false);
  };

  useEffect(() => {
    setPreLoader(true);
    getCategoryList();
  }, [urlId]);
  const GetSingleRecord = () => {
    SingleRecord(params.id)
      .then((res) => {
        if (res.statusCode == 200) {
          if (res.data.locked_item == 1 || res.data.locked_item == "Y") {
            setDisabledFields(true);
          }
          setRecordData(res.data);
          setSelectedOwner(res.data.verify_right_owner);
          setPreLoader(false);
        } else {
          setPreLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCategoryList = () => {
    CategoryList({})
      .then((res) => {
        if (res.statusCode == 200) {
          setItemCatgList(res.data);
        }
        getEstimatedList();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getEstimatedList = () => {
    EstimatedList({})
      .then((res) => {
        if (res.statusCode == 200) {
          setItemEstList(res.data);
        }
        getCountryList();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCountryList = () => {
    CountryList({})
      .then((res) => {
        if (res.statusCode == 200) {
          setCountryList(res.data);
        }
        getStorageLocationList();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getStorageLocationList = () => {
    StorageLocationList({})
      .then((res) => {
        if (res.statusCode == 200) {
          setStorageLocation(res.data);
        }
        getDepartmentList();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getDepartmentList = () => {
    DepartmentList({})
      .then((res) => {
        if (res.statusCode == 200) {
          setDepartmentList(res.data);
        }
        GetSingleRecord();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const clearSelectedImage = () => {
    setUploadedImage(null);
    uploadedFileRef.current.value = "";
  };
  const handleShowWebCam = () => {
    let changeSetShowWebCam = !showWebCam;
    setShowWebCam(changeSetShowWebCam);
  };
  const handleFileChange = (evt) => {
    let file = evt.target.files[0];
    getFileBase64(file, (result) => {
      setUploadedImage(result);
    });
  };

  const [selectedOwner, setSelectedOwner] = useState(null);

  const validationSchema = Yup.object().shape({
    category: Yup.string().required("Please select category."),
    estimated_value: Yup.string().required("Please select estimated value."),
    description: Yup.string().required(
      "Lost and found description should not be empty."
    ),
    // Lost section
    lost_room_number: Yup.number()
      .nullable()
      .positive("Please enter a valid number.")
      .typeError("Please enter a valid number."),
    lost_zip: Yup.number()
      .nullable()
      .positive("Please enter a valid number.")
      .typeError("Please enter a valid number."),
    lost_phone: Yup.number()
      .nullable()
      .positive("Please enter a valid number.")
      .typeError("Please enter a valid number."),
    lost_email: Yup.string().nullable().email("Please enter a valid email."),

    // found section
    found_zip: Yup.number()
      .nullable()
      .positive("Please enter a valid number.")
      .typeError("Please enter a valid number."),
    found_phone: Yup.number()
      .nullable()
      .positive("Please enter a valid number.")
      .typeError("Please enter a valid number."),
    found_email: Yup.string().nullable().email("Please enter a valid email."),

    // claim section
    claim_zip: Yup.number()
      .nullable()
      .positive("Please enter a valid number.")
      .typeError("Please enter a valid number."),
    claim_phone: Yup.number()
      .nullable()
      .positive("Please enter a valid number.")
      .typeError("Please enter a valid number."),
    claim_email: Yup.string().nullable().email("Please enter a valid email."),
  });

  let initialValues = {
    id: recordData ? (recordData.id ? recordData.id : "") : "",
    disposition_status: recordData ? recordData.disposition_status : "",
    category: recordData
      ? recordData.category
        ? recordData.category
        : ""
      : "",
    estimated_value: recordData
      ? recordData.estimated_value
        ? recordData.estimated_value
        : ""
      : "",
    storage_location: recordData
      ? recordData.storage_location
        ? recordData.storage_location
        : ""
      : "",
    followup_reason: recordData
      ? recordData.followup_reason
        ? recordData.followup_reason
        : ""
      : "",
    description: recordData
      ? recordData.description
        ? recordData.description
        : ""
      : "",
    internal_notes: recordData
      ? recordData.internal_notes
        ? recordData.internal_notes
        : ""
      : "",
    lost_first_name: recordData
      ? recordData.lost_first_name
        ? recordData.lost_first_name
        : ""
      : "",
    lost_last_name: recordData
      ? recordData.lost_last_name
        ? recordData.lost_last_name
        : ""
      : "",
    lost_room_number: recordData
      ? recordData.lost_room_number
        ? recordData.lost_room_number
        : ""
      : "",
    lost_date: recordData
      ? recordData.lost_date
        ? recordData.lost_date
        : ""
      : "",
    lost_arrival_date: recordData
      ? recordData.lost_arrival_date
        ? recordData.lost_arrival_date
        : ""
      : "",
    lost_departure_date: recordData
      ? recordData.lost_departure_date
        ? recordData.lost_departure_date
        : ""
      : "",
    lost_street1: recordData
      ? recordData.lost_street1
        ? recordData.lost_street1
        : ""
      : "",
    lost_street2: recordData
      ? recordData.lost_street2
        ? recordData.lost_street2
        : ""
      : "",
    lost_city: recordData
      ? recordData.lost_city
        ? recordData.lost_city
        : ""
      : "",
    lost_state: recordData
      ? recordData.lost_state
        ? recordData.lost_state
        : ""
      : "",
    lost_zip: recordData
      ? recordData.lost_zip
        ? recordData.lost_zip
        : ""
      : "",
    lost_country: recordData
      ? recordData.lost_country
        ? recordData.lost_country
        : ""
      : "",
    lost_phone: recordData
      ? recordData.lost_phone
        ? recordData.lost_phone
        : ""
      : "",
    lost_email: recordData
      ? recordData.lost_email
        ? recordData.lost_email
        : ""
      : "",
    lost_location: recordData
      ? recordData.lost_location
        ? recordData.lost_location
        : ""
      : "",
    lost_guest_notes: recordData
      ? recordData.lost_guest_notes
        ? recordData.lost_guest_notes
        : ""
      : "",
    lost_company_name: recordData
      ? recordData.lost_company_name
        ? recordData.lost_company_name
        : ""
      : "",
    image: recordData ? (recordData.image ? recordData.image : "") : "",
    found_date: recordData
      ? recordData.found_date
        ? recordData.found_date
        : ""
      : "",
    found_name: recordData
      ? recordData.found_name
        ? recordData.found_name
        : ""
      : "",
    found_by: recordData
      ? recordData.found_by
        ? recordData.found_by
        : ""
      : "",
    found_department: recordData
      ? recordData.found_department
        ? recordData.found_department
        : ""
      : "",
    finder_wants_item: recordData
      ? recordData.finder_wants_item
        ? recordData.finder_wants_item
        : ""
      : "",
    found_address: recordData
      ? recordData.found_address
        ? recordData.found_address
        : ""
      : "",
    found_city: recordData
      ? recordData.found_city
        ? recordData.found_city
        : ""
      : "",
    found_state: recordData
      ? recordData.found_state
        ? recordData.found_state
        : ""
      : "",
    found_zip: recordData
      ? recordData.found_zip
        ? recordData.found_zip
        : ""
      : "",
    found_country: recordData
      ? recordData.found_country
        ? recordData.found_country
        : ""
      : "",
    found_phone: recordData
      ? recordData.found_phone
        ? recordData.found_phone
        : ""
      : "",
    found_email: recordData
      ? recordData.found_email
        ? recordData.found_email
        : ""
      : "",
    found_location: recordData
      ? recordData.found_location
        ? recordData.found_location
        : ""
      : "",
    claim_date: recordData
      ? recordData.claim_date
        ? recordData.claim_date
        : ""
      : "",
    claim_name: recordData
      ? recordData.claim_name
        ? recordData.claim_name
        : ""
      : "",
    claim_street1: recordData
      ? recordData.claim_street1
        ? recordData.claim_street1
        : ""
      : "",
    claim_street2: recordData
      ? recordData.claim_street2
        ? recordData.claim_street2
        : ""
      : "",
    claim_city: recordData
      ? recordData.claim_city
        ? recordData.claim_city
        : ""
      : "",
    claim_state: recordData
      ? recordData.claim_state
        ? recordData.claim_state
        : ""
      : "",
    claim_zip: recordData
      ? recordData.claim_zip
        ? recordData.claim_zip
        : ""
      : "",
    claim_country: recordData
      ? recordData.claim_country
        ? recordData.claim_country
        : ""
      : "",
    claim_phone: recordData
      ? recordData.claim_phone
        ? recordData.claim_phone
        : ""
      : "",
    claim_email: recordData
      ? recordData.claim_email
        ? recordData.claim_email
        : ""
      : "",
    claim_company_name: recordData
      ? recordData.claim_company_name
        ? recordData.claim_company_name
        : ""
      : "",
    shipped: recordData ? (recordData.shipped ? recordData.shipped : "") : "",
    claimed_item_status: recordData
      ? recordData.claimed_item_status
        ? recordData.claimed_item_status
        : ""
      : "",
    shipping_info: recordData
      ? recordData.shipping_info
        ? recordData.shipping_info
        : ""
      : "",
    verified_by: recordData
      ? recordData.verified_by
        ? recordData.verified_by
        : ""
      : "",
    claim_details: recordData
      ? recordData.claim_details
        ? recordData.claim_details
        : ""
      : "",
    followup_required: recordData
      ? recordData.followup_required == "Y"
        ? true
        : false
      : false,
    locked_item: recordData
      ? recordData.locked_item == 1 || recordData.locked_item == "Y"
        ? true
        : false
      : false,
  };

  const someThingWrongAlert = (msg, type) => {
    setLoading(false);
    setAlertMessage(msg);
    setAlertType(type);
    dispatch(updateShowAlert(true));
  };

  useEffect(() => {
    siteVarifyOwner();
  }, [selectedOwner]);

  //useReducer start
  const initialState = { selectedOptions: [] };
  function reducer(state, action) {
    switch (action.type) {
      case "SET_SELECTED_OPTIONS":
        return { selectedOptions: action.payload.options };

      case "REMOVE_OPTION":
        return {
          selectedOptions: state.selectedOptions.filter(
            (option) => option.value !== action.payload.value
          ),
        };
      default:
        throw new Error();
    }
  }
  const [state, dispatch1] = useReducer(reducer, initialState);

  const handleChangeAuto = (event, values) => {
    dispatch1({ type: "SET_SELECTED_OPTIONS", payload: { options: values } });
  };

  const removeOption = (id) => {
    dispatch1({ type: "REMOVE_OPTION", payload: { value: id } });
  };

  const siteVarifyOwner = () => {
    var setResult = [];
    if (selectedOwner) {
      selectedOwner.forEach((obj) => {
        verify_owners.forEach((r) => {
          if (r.value == obj) {
            setResult.push(r);
          }
        });
      });
    }
    dispatch1({
      type: "SET_SELECTED_OPTIONS",
      payload: {
        options: setResult,
      },
    });
  };

  let verifyRightOwner = state.selectedOptions.map((data) => data.value);

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoading(true);
      let formData = {
        id: values.id,
        disposition_status: values.disposition_status,
        category: values.category,
        estimated_value: values.estimated_value,
        storage_location: values.storage_location,
        followup_reason: values.followup_reason,
        description: values.description,
        internal_notes: values.internal_notes,
        lost_first_name: values.lost_first_name,
        lost_last_name: values.lost_last_name,
        lost_room_number: values.lost_room_number,
        lost_date: values.lost_date,
        lost_arrival_date: values.lost_arrival_date,
        lost_departure_date: values.lost_departure_date,
        lost_street1: values.lost_street1,
        lost_street2: values.lost_street2,
        lost_city: values.lost_city,
        lost_state: values.lost_state,
        lost_zip: values.lost_zip,
        lost_country: values.lost_country,
        lost_phone: values.lost_phone,
        lost_email: values.lost_email,
        lost_location: values.lost_location,
        lost_guest_notes: values.lost_guest_notes,
        lost_company_name: values.lost_company_name,
        image: uploadedImage,
        found_date: values.found_date,
        found_name: values.found_name,
        found_by: values.found_by,
        found_department: values.found_department,
        found_address: values.found_address,
        found_city: values.found_city,
        found_state: values.found_state,
        found_zip: values.found_zip,
        found_country: values.found_country,
        found_phone: values.found_phone,
        found_email: values.found_email,
        found_location: values.found_location,
        finder_wants_item: values.finder_wants_item,
        claim_date: values.claim_date,
        claim_name: values.claim_name,
        claim_street1: values.claim_street1,
        claim_street2: values.claim_street2,
        claim_city: values.claim_city,
        claim_state: values.claim_state,
        claim_zip: values.claim_zip,
        claim_country: values.claim_country,
        claim_phone: values.claim_phone,
        claim_email: values.claim_email,
        shipped: values.shipped,
        claimed_item_status: values.claimed_item_status,
        shipping_info: values.shipping_info,
        verified_by: values.verified_by,
        claim_details: values.claim_details,
        verify_right_owner: verifyRightOwner ? verifyRightOwner.join(",") : "",
        followup_required: values.followup_required === true ? "Y" : "N",
        locked_item: values.locked_item === true ? 1 : 0,
      };

      EditLostFound(formData)
        .then((res) => {
          setLoading(false);
          if (res.statusCode === 200) {
            dispatch(updateShowAlert(true));
            dispatch(updateText("Record has been updated successfully"));
            navigate({ pathname: "/lostandfound/list-lost-found" });
          } else {
            someThingWrongAlert(
              "Sorry! something wrong please try again",
              "error"
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            someThingWrongAlert(error, "error");
          }
        });
    } catch (error) {
      setLoading(false);
      someThingWrongAlert("Sorry! something wrong please try again", "error");
    }
  };

  const handelDisposedModal = (status) => {
    setShowDispositionModal(status);
  };
  const handelLockItemModal = (status) => {
    setShowLockItemModal(status);
  };

  return (
    <React.Fragment>
      {preLoader === true ? (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      ) : (
        <div>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              setFieldValue,
              values,
              status,
            }) => (
              <form onSubmit={handleSubmit}>
                <Card mb={6}>
                  <CardContent>
                    <Box className={classes.accordion}>
                      <Grid container spacing={6}>
                        <Grid item xs={12} lg={6} sx={{ padding: "0px" }}>
                          <Accordion
                            mt={0}
                            expanded={iteminfoexpand === "expand-item-info"}
                            onChange={handleItemExpansion("expand-item-info")}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="item-info"
                              id="item-infor"
                            >
                              <Typography>Item Information</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid
                                container
                                spacing={4}
                                sx={{ margin: "0", width: "fit-content" }}
                              >
                                {disabledFields && (
                                  <Grid
                                    item
                                    container
                                    xs={12}
                                    lg={12}
                                    style={{
                                      backgroundColor: "red",
                                      padding: "2px",
                                    }}
                                    alignItems="center"
                                  >
                                    <Grid item xs>
                                      <Typography
                                        variant="subtitle1"
                                        color={"white"}
                                        align="center"
                                      >
                                        Locked
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Tooltip
                                        sx={{ cursor: "pointer" }}
                                        title="Click to show reason for the lock"
                                      >
                                        <InfoIcon
                                          color="info"
                                          onClick={() => {
                                            editLockedDialogOpen();
                                          }}
                                        />
                                      </Tooltip>
                                    </Grid>
                                  </Grid>
                                )}
                                <Grid item xs={12} lg={12}>
                                  <Typography>
                                    {recordData.parent_id === 0
                                      ? "Parent Record "
                                      : "Parent Item = "}
                                    {recordData.parent_id != 0
                                      ? recordData.parent.category
                                      : ""}{" "}
                                    Added:{" "}
                                    {moment(recordData.created).format(
                                      "YYYY-MM-DD"
                                    )}{" "}
                                    by{" "}
                                    {recordData.user
                                      ? recordData.user.UserName
                                      : "-"}
                                  </Typography>

                                  <FormControl mt={3}>
                                    <CustomTextField
                                      disabled={disabledFields}
                                      name="id"
                                      label="Control ID"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      autoComplete="off"
                                      placeholder="System generated"
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                  <FormGroup>
                                    <FormLabel>Disposition</FormLabel>
                                    <RadioGroup
                                      aria-labelledby="disposition_status"
                                      name="disposition_status"
                                      value={values.disposition_status}
                                      row
                                      onChange={(e) =>
                                        setFieldValue(
                                          "disposition_status",
                                          e.currentTarget.value
                                        )
                                      }
                                    >
                                      <FormControlLabel
                                        disabled={disabledFields}
                                        control={<Radio />}
                                        label="Lost"
                                        value={"0"}
                                      />
                                      <FormControlLabel
                                        disabled={disabledFields}
                                        control={<Radio />}
                                        label="Stored"
                                        value={1}
                                      />
                                      <FormControlLabel
                                        disabled={disabledFields}
                                        control={<Radio />}
                                        label="Claimed"
                                        value={2}
                                      />
                                      <FormControlLabel
                                        disabled={disabledFields}
                                        onClick={() =>
                                          handelDisposedModal(true)
                                        }
                                        control={<Radio />}
                                        label="Disposed"
                                        value={3}
                                      />
                                      <FormControlLabel
                                        disabled={disabledFields}
                                        control={<Radio />}
                                        label="Pending"
                                        value={4}
                                      />
                                    </RadioGroup>
                                  </FormGroup>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                  <FormControl disabled={disabledFields} mt={3}>
                                    <CustomSelect
                                      name="category"
                                      fieldLabel="Item category *"
                                      options={itemcatglist}
                                      displayEmpty
                                    />
                                    <FormHelperText>
                                      {errors.category}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                  <FormControl disabled={disabledFields} mt={3}>
                                    <CustomSelect
                                      name="estimated_value"
                                      fieldLabel="Estimated value *"
                                      options={itemestlist}
                                      displayEmpty
                                    />
                                    <FormHelperText>
                                      {errors.estimated_value}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                  <FormControl disabled={disabledFields} mt={3}>
                                    <CustomSelect
                                      name="storage_location"
                                      fieldLabel="Storage Location *"
                                      options={storageLocation}
                                      displayEmpty
                                    />
                                    <FormHelperText>
                                      {errors.storage_location}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "15px",
                                    }}
                                  >
                                    <FormLabel>Followup required</FormLabel>
                                    <Tooltip
                                      title="Highlights row in list, also copies record to followup tab"
                                      sx={{ cursor: "pointer" }}
                                    >
                                      <ErrorIcon color="primary" />
                                    </Tooltip>
                                    <CustomCheckbox
                                      disabled={disabledFields}
                                      name="followup_required"
                                      checked={values.followup_required}
                                      label=""
                                    />
                                  </Box>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "15px",
                                    }}
                                  >
                                    <FormLabel>Lock item</FormLabel>
                                    <Tooltip
                                      title="Prevents status changes. Useful if you need to keep an item past its retention period"
                                      sx={{ cursor: "pointer" }}
                                    >
                                      <ErrorIcon color="primary" />
                                    </Tooltip>
                                    <CustomCheckbox
                                      name="locked_item"
                                      checked={values.locked_item}
                                      label=""
                                      returnOnChange={(evt) => {
                                        if (evt.target.checked) {
                                          handelLockItemModal(true);
                                          setFieldValue("locked_item", false);
                                        }
                                        if (!evt.target.checked) {
                                          setDisabledFields(false);
                                        }
                                      }}
                                    />
                                  </Box>
                                </Grid>
                                {values.followup_required === true && (
                                  <Grid item xs={12} lg={12}>
                                    <FormControl mt={3}>
                                      <CustomTextField
                                        disabled={disabledFields}
                                        name="followup_reason"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        autoComplete="off"
                                        placeholder="Add reason for followup"
                                      />
                                    </FormControl>
                                  </Grid>
                                )}
                                <Grid item xs={12} lg={12}>
                                  <FormControl mt={3}>
                                    <CustomTextField
                                      disabled={disabledFields}
                                      name="description"
                                      label="Description *"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      autoComplete="off"
                                      placeholder="Make model, color, material, size, etc."
                                      multiline={true}
                                      rows={4}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                  <FormControl mt={3}>
                                    <CustomTextField
                                      disabled={disabledFields}
                                      name="internal_notes"
                                      label="Internal Notes "
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      autoComplete="off"
                                      placeholder="Notes about the item, owner, a phone call you had with them, etc."
                                      multiline={true}
                                      rows={4}
                                    />
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            mt={6}
                            expanded={imageexpand === "expand-image"}
                            onChange={handleImgExpansion("expand-image")}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="image-from-group"
                              id="image-from-group"
                            >
                              <Typography>Image</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container spacing={4}>
                                <Grid item xs={12} lg={12}>
                                  <FormControl>
                                    {uploadedImage || values.image ? (
                                      <Image
                                        alt="image name"
                                        src={
                                          uploadedImage
                                            ? uploadedImage
                                            : values.image
                                        }
                                      />
                                    ) : (
                                      <Image
                                        alt="image name"
                                        src="/static/img/lost-found/lost_and_found_pic.jpg"
                                      />
                                    )}

                                    <Box
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        JustifyContent: "space-between",
                                        margin: "10px 0 15px",
                                        gap: "15px",
                                      }}
                                    >
                                      <Button
                                        variant="outlined"
                                        color="primary"
                                        component="span"
                                        onClick={(e) => clearSelectedImage(e)}
                                      >
                                        Clear
                                      </Button>
                                      <Button
                                        variant="outlined"
                                        color="warning"
                                        component="span"
                                        onClick={() => handleShowWebCam()}
                                      >
                                        {showWebCam ? "Close WebCam" : "WebCam"}
                                      </Button>
                                    </Box>
                                    <input
                                      disabled={disabledFields}
                                      accept="image/*"
                                      style={{ display: "none" }}
                                      id="raised-button-file"
                                      multiple
                                      type="file"
                                      ref={uploadedFileRef}
                                      onChange={(e) => handleFileChange(e)}
                                    />
                                    <label htmlFor="raised-button-file">
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        component="span"
                                        disabled={disabledFields}
                                      >
                                        Choose image
                                      </Button>
                                    </label>
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <Accordion
                            mt={0}
                            expanded={lostexpand === "expand-lost"}
                            onChange={handleLostExpansion("expand-lost")}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="lost-form-group"
                              id="lost-form-group"
                            >
                              <Typography>Lost</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container spacing={6}>
                                <Grid item xs={12} lg={6}>
                                  <FormControl>
                                    <CustomTextField
                                      disabled={disabledFields}
                                      name="lost_first_name"
                                      label="First name *"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      autoComplete="off"
                                      placeholder="Mike"
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                  <FormControl>
                                    <CustomTextField
                                      disabled={disabledFields}
                                      name="lost_last_name"
                                      label="Last name *"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      autoComplete="off"
                                      placeholder="Smith"
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                  <FormControl>
                                    <CustomTextField
                                      disabled={disabledFields}
                                      name="lost_room_number"
                                      label="Room number"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      autoComplete="off"
                                      placeholder="1256"
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                  <FormControl>
                                    <CustomDateTimePicker
                                      disabled={disabledFields}
                                      name="lost_date"
                                      label="Lost date"
                                      inputFormat="yyyy-MM-dd HH:mm:ss"
                                      placeholder="yyyy-mm-dd H:i:s"
                                      setValue={(value) => {
                                        let date = value ? moment(value) : "";
                                        let dateValue = date
                                          ? date.format("YYYY-MM-DD HH:mm:ss")
                                          : "";
                                        setFieldValue("lost_date", dateValue);
                                      }}
                                    />
                                    <FormHelperText>
                                      {errors.found_date}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                  <FormControl>
                                    <CustomDateTimePicker
                                      disabled={disabledFields}
                                      name="lost_arrival_date"
                                      label="Arrival date"
                                      inputFormat="yyyy-MM-dd HH:mm:ss"
                                      placeholder="yyyy-mm-dd H:i:s"
                                      setValue={(value) => {
                                        let date = value ? moment(value) : "";
                                        let dateValue = date
                                          ? date.format("YYYY-MM-DD HH:mm:ss")
                                          : "";
                                        setFieldValue(
                                          "lost_arrival_date",
                                          dateValue
                                        );
                                      }}
                                    />
                                    <FormHelperText>
                                      {errors.found_date}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                  <FormControl>
                                    <CustomDateTimePicker
                                      disabled={disabledFields}
                                      name="lost_departure_date"
                                      label="Departure date"
                                      inputFormat="yyyy-MM-dd HH:mm:ss"
                                      placeholder="yyyy-mm-dd H:i:s"
                                      setValue={(value) => {
                                        let date = value ? moment(value) : "";
                                        let dateValue = date
                                          ? date.format("YYYY-MM-DD HH:mm:ss")
                                          : "";
                                        setFieldValue(
                                          "lost_departure_date",
                                          dateValue
                                        );
                                      }}
                                    />
                                    <FormHelperText>
                                      {errors.found_date}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                  <FormLabel>Address</FormLabel>
                                  <FormControl mt={4}>
                                    <GoogleAutoCompleteField
                                      disabled={disabledFields}
                                      name="lost_street1"
                                      label="Street line1"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      autoComplete="off"
                                      placeholder="3214 N University Ave"
                                      getApiResponse={(res) => {
                                        setFieldValue(
                                          "lost_street1",
                                          res.address
                                        );
                                        setFieldValue(
                                          "lost_city",
                                          res.locality
                                        );
                                        setFieldValue(
                                          "lost_state",
                                          res.administrative_area_level_1
                                        );
                                        setFieldValue(
                                          "lost_zip",
                                          res.postal_code
                                        );
                                        setFieldValue(
                                          "lost_country",
                                          res.country
                                        );
                                      }}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                  <FormControl>
                                    <CustomTextField
                                      disabled={disabledFields}
                                      name="lost_street2"
                                      label="Street line2"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      autoComplete="off"
                                      placeholder="Enter suite and apt numbers in field above"
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                  <FormControl>
                                    <CustomTextField
                                      disabled={disabledFields}
                                      name="lost_city"
                                      label="City"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      autoComplete="off"
                                      placeholder="Provo"
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                  <FormControl>
                                    <CustomTextField
                                      disabled={disabledFields}
                                      name="lost_state"
                                      label="State"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      autoComplete="off"
                                      placeholder="Utah"
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                  <FormControl>
                                    <CustomTextField
                                      disabled={disabledFields}
                                      name="lost_zip"
                                      label="Zip code"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      autoComplete="off"
                                      placeholder="84604"
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                  <FormControl>
                                    <CustomTextField
                                      disabled={disabledFields}
                                      name="lost_country"
                                      label="Country"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      autoComplete="off"
                                      placeholder="USA"
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                  <FormControl>
                                    <CustomTextField
                                      disabled={disabledFields}
                                      name="lost_phone"
                                      label="Phone"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      autoComplete="off"
                                      placeholder="XXX-XXX-XXXX"
                                      inputProps={{ maxLength: 20 }}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                  <FormControl>
                                    <CustomTextField
                                      disabled={disabledFields}
                                      name="lost_email"
                                      label="Email"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      autoComplete="off"
                                      placeholder="me@you.com"
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                  <FormControl>
                                    <CustomTextField
                                      disabled={disabledFields}
                                      name="lost_location"
                                      label="Lost location *"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      autoComplete="off"
                                      placeholder="Include specifies"
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                  <FormControl>
                                    <CustomTextField
                                      disabled={disabledFields}
                                      name="lost_guest_notes"
                                      label="Notes on guest"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      autoComplete="off"
                                      placeholder="Anything you wish to not about the guest"
                                    />
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            mt={3}
                            expanded={expandfound === "expand-found"}
                            onChange={handleFoundExpansion("expand-found")}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="found-form-group"
                              id="found-form-group"
                            >
                              <Typography>Found</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container spacing={6}>
                                <Grid item xs={12} lg={6}>
                                  <FormControl>
                                    <CustomDateTimePicker
                                      disabled={disabledFields}
                                      name="found_date"
                                      label="Date"
                                      inputFormat="yyyy-MM-dd HH:mm:ss"
                                      placeholder="yyyy-mm-dd H:i:s"
                                      setValue={(value) => {
                                        let date = value ? moment(value) : "";
                                        let dateValue = date
                                          ? date.format("YYYY-MM-DD HH:mm:ss")
                                          : "";
                                        setFieldValue("found_date", dateValue);
                                      }}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                  <FormControl>
                                    <CustomTextField
                                      disabled={disabledFields}
                                      name="found_name"
                                      label="Finder name"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      autoComplete="off"
                                      placeholder="Person who found the item"
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                  <FormControl>
                                    <FormLabel>Found by</FormLabel>
                                    <RadioGroup
                                      row
                                      aria-labelledby="found_by"
                                      name="found_by"
                                      value={values.found_by}
                                      onChange={(e) =>
                                        setFieldValue(
                                          "found_by",
                                          e.currentTarget.value
                                        )
                                      }
                                    >
                                      <FormControlLabel
                                        disabled={disabledFields}
                                        control={<Radio />}
                                        label="Employee"
                                        value="Employee"
                                      />
                                      <FormControlLabel
                                        disabled={disabledFields}
                                        control={<Radio />}
                                        label="Guest/Other"
                                        value="Guest/Other"
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Grid>
                                {/* For found by employee */}
                                {values.found_by === "Employee" && (
                                  <React.Fragment>
                                    <Grid item xs={12} lg={12}>
                                      <FormControl disabled={disabledFields}>
                                        <CustomSelect
                                          name="found_department"
                                          fieldLabel="Department"
                                          options={departmentList}
                                          displayEmpty
                                          firstOptionTitle="Select department"
                                        />
                                        <FormHelperText>
                                          {errors.found_department}
                                        </FormHelperText>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} lg={12}>
                                      <FormControl>
                                        <FormLabel component="legend">
                                          If not claimed, finder wants item:
                                        </FormLabel>
                                        <StyledRadio>
                                          <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            value={values.finder_wants_item}
                                            name="finder_wants_item"
                                            onChange={(e) =>
                                              setFieldValue(
                                                "finder_wants_item",
                                                e.currentTarget.value
                                              )
                                            }
                                          >
                                            <FormControlLabel
                                              disabled={disabledFields}
                                              value="Yes"
                                              control={<Radio />}
                                              label="Yes"
                                            />
                                            <FormControlLabel
                                              disabled={disabledFields}
                                              value="No"
                                              control={<Radio />}
                                              label="No"
                                            />
                                            <FormControlLabel
                                              disabled={disabledFields}
                                              value="Unknown"
                                              control={<Radio />}
                                              label="Unknown"
                                            />
                                          </RadioGroup>
                                        </StyledRadio>
                                      </FormControl>
                                    </Grid>
                                  </React.Fragment>
                                )}

                                {/* For found by Guest/Other */}
                                {values.found_by === "Guest/Other" && (
                                  <React.Fragment>
                                    <Grid item xs={12} lg={12}>
                                      <FormControl>
                                        <CustomTextField
                                          disabled={disabledFields}
                                          name="found_address"
                                          label="Address"
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          autoComplete="off"
                                          placeholder="1234 E 3rd St"
                                        />
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                      <FormControl>
                                        <CustomTextField
                                          disabled={disabledFields}
                                          name="found_city"
                                          label="City"
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          autoComplete="off"
                                          placeholder="Miami"
                                        />
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} lg={3}>
                                      <FormControl>
                                        <CustomTextField
                                          disabled={disabledFields}
                                          name="found_state"
                                          label="State"
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          autoComplete="off"
                                          placeholder="FL"
                                        />
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} lg={3}>
                                      <FormControl>
                                        <CustomTextField
                                          disabled={disabledFields}
                                          name="found_zip"
                                          label="Zip"
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          autoComplete="off"
                                          placeholder="xxxxx"
                                        />
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                      <FormControl>
                                        <CustomTextField
                                          disabled={disabledFields}
                                          name="found_country"
                                          label="Country"
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          autoComplete="off"
                                          placeholder="USA, Canada etc."
                                        />
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                      <FormControl>
                                        <CustomTextField
                                          disabled={disabledFields}
                                          name="found_phone"
                                          label="Phone"
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          autoComplete="off"
                                          placeholder="xxx-xxx-xxxx"
                                          inputProps={{ maxLength: 20 }}
                                        />
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                      <FormControl>
                                        <CustomTextField
                                          disabled={disabledFields}
                                          name="found_email"
                                          label="Email"
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          autoComplete="off"
                                          placeholder="me@you.com"
                                        />
                                      </FormControl>
                                    </Grid>
                                  </React.Fragment>
                                )}
                                <Grid item xs={12} lg={12}>
                                  <FormControl mt={3}>
                                    <CustomTextField
                                      disabled={disabledFields}
                                      name="found_location"
                                      label="Found location"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      autoComplete="off"
                                    />
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                          {recordData.parent_id === 0 && (
                            <Accordion
                              mt={3}
                              expanded={expandclaimed === "expand-claimed"}
                              onChange={handleClaimedExpansion(
                                "expand-claimed"
                              )}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="claimed-form-group"
                                id="claimed-form-group"
                              >
                                <Typography>Claimed</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Grid container spacing={6}>
                                  <Grid item xs={12} lg={12} align="right">
                                    <Button
                                      color="primary"
                                      variant="outlined"
                                      mt={3}
                                      onClick={() => {
                                        setFieldValue(
                                          "claim_name",
                                          values.lost_first_name +
                                            " " +
                                            values.lost_last_name
                                        );
                                        setFieldValue(
                                          "claim_company_name",
                                          values.lost_company_name
                                        );
                                        setFieldValue(
                                          "claim_street1",
                                          values.lost_street1
                                        );
                                        setFieldValue(
                                          "claim_street2",
                                          values.lost_street2
                                        );
                                        setFieldValue(
                                          "claim_city",
                                          values.lost_city
                                        );
                                        setFieldValue(
                                          "claim_state",
                                          values.lost_state
                                        );
                                        setFieldValue(
                                          "claim_zip",
                                          values.lost_zip
                                        );
                                        setFieldValue(
                                          "claim_country",
                                          values.lost_country
                                        );
                                        setFieldValue(
                                          "claim_phone",
                                          values.lost_phone
                                        );
                                        setFieldValue(
                                          "claim_email",
                                          values.lost_email
                                        );
                                        setFieldValue(
                                          "claim_date",
                                          values.lost_date
                                        );
                                      }}
                                    >
                                      Copy from lost drawer
                                    </Button>
                                  </Grid>
                                  <Grid item xs={12} lg={6}>
                                    <FormControl>
                                      <CustomDateTimePicker
                                        disabled={disabledFields}
                                        name="claim_date"
                                        label="Date"
                                        inputFormat="yyyy-MM-dd HH:mm:ss"
                                        placeholder="yyyy-mm-dd H:i:s"
                                        setValue={(value) => {
                                          let date = value ? moment(value) : "";
                                          let dateValue = date
                                            ? date.format("YYYY-MM-DD HH:mm:ss")
                                            : "";
                                          setFieldValue(
                                            "claim_date",
                                            dateValue
                                          );
                                        }}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} lg={6}>
                                    <FormControl>
                                      <CustomTextField
                                        disabled={disabledFields}
                                        name="claim_name"
                                        label="Claimed name"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        autoComplete="off"
                                        placeholder="Owner or item recipient"
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} lg={12}>
                                    <FormLabel>Address</FormLabel>
                                    <FormControl mt={4}>
                                      <CustomTextField
                                        disabled={disabledFields}
                                        name="claim_street1"
                                        label="Street line 1"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        autoComplete="off"
                                        placeholder=""
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} lg={12}>
                                    <FormControl>
                                      <CustomTextField
                                        disabled={disabledFields}
                                        name="claim_street2"
                                        label="Street line 2"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        autoComplete="off"
                                        placeholder=""
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} lg={6}>
                                    <FormControl>
                                      <CustomTextField
                                        disabled={disabledFields}
                                        name="claim_city"
                                        label="City"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        autoComplete="off"
                                        placeholder=""
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} lg={6}>
                                    <FormControl>
                                      <CustomTextField
                                        disabled={disabledFields}
                                        name="claim_state"
                                        label="State"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        autoComplete="off"
                                        placeholder=""
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} lg={6}>
                                    <FormControl>
                                      <CustomTextField
                                        disabled={disabledFields}
                                        name="claim_zip"
                                        label="Zip"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        autoComplete="off"
                                        placeholder=""
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} lg={6}>
                                    <FormControl>
                                      <FormControl
                                        disabled={disabledFields}
                                        mt={3}
                                      >
                                        <CustomSelect
                                          name="claim_country"
                                          fieldLabel="Country"
                                          options={countryList}
                                          displayEmpty
                                        />
                                      </FormControl>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} lg={6}>
                                    <FormControl>
                                      <CustomTextField
                                        disabled={disabledFields}
                                        name="claim_phone"
                                        label="Phone"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        autoComplete="off"
                                        placeholder=""
                                        inputProps={{ maxLength: 20 }}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} lg={6}>
                                    <FormControl>
                                      <CustomTextField
                                        disabled={disabledFields}
                                        name="claim_email"
                                        label="Email"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        autoComplete="off"
                                        placeholder="me@you.com"
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} lg={12}>
                                    <FormControl>
                                      <FormLabel>Delivered</FormLabel>
                                      <RadioGroup
                                        row
                                        aria-labelledby="delivered"
                                        name="shipped"
                                        value={values.shipped}
                                        onChange={(e) =>
                                          setFieldValue(
                                            "shipped",
                                            e.currentTarget.value
                                          )
                                        }
                                      >
                                        <FormControlLabel
                                          disabled={disabledFields}
                                          control={<Radio />}
                                          label="Yes"
                                          value={0}
                                        />
                                        <FormControlLabel
                                          disabled={disabledFields}
                                          control={<Radio />}
                                          label="Picked up"
                                          value={1}
                                        />
                                      </RadioGroup>
                                      <FormHelperText>
                                        Do claimed items represent all, or only
                                        part of the items allegedly lost by the
                                        claimant?
                                      </FormHelperText>
                                      <RadioGroup
                                        row
                                        aria-labelledby="claimed_item_status"
                                        name="claimed_item_status"
                                        value={values.claimed_item_status}
                                        onChange={(e) =>
                                          setFieldValue(
                                            "claimed_item_status",
                                            e.currentTarget.value
                                          )
                                        }
                                      >
                                        <FormControlLabel
                                          disabled={disabledFields}
                                          control={<Radio />}
                                          label="All"
                                          value="All"
                                        />
                                        <FormControlLabel
                                          disabled={disabledFields}
                                          control={<Radio />}
                                          label="Part"
                                          value="Part"
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} lg={12}>
                                    <FormControl>
                                      <CustomTextField
                                        disabled={disabledFields}
                                        name="shipping_info"
                                        label="Shipping info"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        autoComplete="off"
                                        placeholder="Carrier or tracking number"
                                      />
                                    </FormControl>
                                  </Grid>

                                  <Grid item xs={12} lg={12}>
                                    <FormControl>
                                      <FormHelperText mb={3}>
                                        How did you verify this is the rightful
                                        owner?
                                      </FormHelperText>
                                      <Autocomplete
                                        disabled={disabledFields}
                                        multiple
                                        name="verify_right_owner"
                                        options={verify_owners}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option.key}
                                        value={state.selectedOptions}
                                        renderTags={(values) =>
                                          values.map((value) => (
                                            <Chip
                                              key={value.value}
                                              label={value.key}
                                              onDelete={() => {
                                                removeOption(value.value);
                                              }}
                                            />
                                          ))
                                        }
                                        renderOption={(
                                          props,
                                          option,
                                          { selected }
                                        ) => (
                                          <li {...props} key={option.value}>
                                            <Checkbox
                                              icon={icon}
                                              checkedIcon={checkedIcon}
                                              style={{ marginRight: 8 }}
                                              checked={selected}
                                            />
                                            {option.key}
                                          </li>
                                        )}
                                        style={{ width: "100%" }}
                                        onChange={handleChangeAuto}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder="Search"
                                          />
                                        )}
                                      />
                                    </FormControl>
                                  </Grid>

                                  <Grid item xs={12} lg={12}>
                                    <FormControl>
                                      <CustomTextField
                                        disabled={disabledFields}
                                        name="verified_by"
                                        label="Verified by"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        autoComplete="off"
                                        placeholder="Name of person processing claim"
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} lg={12}>
                                    <FormControl>
                                      <CustomTextField
                                        disabled={disabledFields}
                                        name="claim_details"
                                        label="Claim details"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        autoComplete="off"
                                        placeholder="Add specific details here about how the item claimed"
                                        multiline={true}
                                        rows={4}
                                      />
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              </AccordionDetails>
                            </Accordion>
                          )}
                        </Grid>
                        <Grid item xs={12} lg={12}>
                          <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={disabledFields}
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                </Card>
              </form>
            )}
          </Formik>

          <Box
            mt={3}
            className={classNames(
              classes.accordion,
              classes.noPaddingAccordion
            )}
          >
            {recordData.parent_id === 0 && (
              <Accordion
                expanded={expandsubitems === "expand-subitems"}
                onChange={handleSubItemsExpansion("expand-subitems")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="subitems-table"
                  id="subitems-table"
                >
                  <Typography>Sub Items ({subItemCount})</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={4}>
                    <Grid item xs={12} lg={12} align="right">
                      <Button
                        color="primary"
                        variant="outlined"
                        sx={{ marginRight: "15px" }}
                        component={NavLink}
                        to={"/lostandfound/add-found?parent_id=" + params.id}
                      >
                        Add sub items
                      </Button>
                    </Grid>
                  </Grid>
                  <Box mt={3}>
                    <SubItems
                      id={params.id}
                      setSubItemCount={(count) => setSubItemCount(count)}
                    />
                  </Box>
                </AccordionDetails>
              </Accordion>
            )}
            <Accordion
              mt={3}
              expanded={expandactivitylog === "expand-activitylog"}
              onChange={handleActivityLogExpansion("expand-activitylog")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="activity-activitylog"
                id="activity-activitylog"
              >
                <Typography>Activity Log</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <ActivityLog id={params.id} />
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
          {loading && (
            <Paper className={classes.fixedLoader}>
              <CircularProgress />
            </Paper>
          )}
        </div>
      )}
      {showAlert && (
        <CustomAlert
          message={alertMessage}
          severity={alertType}
          closeAlert={(show) => dispatch(updateShowAlert(show))}
          showAlert={showAlert}
        />
      )}
      {showDispositionModal && (
        <Disposition
          show={showDispositionModal}
          handleModal={(status) => handelDisposedModal(status)}
          fromData={recordData}
          setLoading={(status) => setLoading(status)}
          alertTost={(msg, type) => someThingWrongAlert(msg, type)}
          GetSingleRecord={() => GetSingleRecord()}
          setPreLoader={(status) => setPreLoader(status)}
        />
      )}
      {showLockItemModal && (
        <LockItem
          show={showLockItemModal}
          handleModal={(status) => handelLockItemModal(status)}
          fromData={recordData}
          setLoading={(status) => setLoading(status)}
          alertTost={(msg, type) => someThingWrongAlert(msg, type)}
          GetSingleRecord={() => GetSingleRecord()}
          setPreLoader={(status) => setPreLoader(status)}
        />
      )}
      {editLockedDialog && (
        <Editlocked
          editLockedDialog={editLockedDialog}
          editLockedDialogClose={editLockedDialogClose}
          recordData={recordData}
        />
      )}
    </React.Fragment>
  );
}

function EditLostAndFound() {
  const dispatch = useDispatch();
  const params = useParams();
  const showAlert = useSelector((state) => state.main.showAlert);
  const showAlertText = useSelector((state) => state.main.text);
  // Alert Message
  const [alertMessage, setAlertMessage] = useState(showAlertText);
  const [alertType, setAlertType] = useState("success");
  const showAlertTost = (msg, type) => {
    setAlertMessage(msg);
    setAlertType(type);
    dispatch(updateShowAlert(true));
  };
  // State for anchorEl
  const [anchorEl, setAnchorEl] = useState(null);
  const handleActionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };

  // comm log toggle button model
  const [CommlogDialog, setCommlogDialog] = useState(false);
  const CommlogDialogOpen = () => {
    setCommlogDialog(true);
  };
  const CommlogDialogClose = () => {
    setCommlogDialog(false);
  };

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <Helmet title="Edit Lost and Found" />
          <Typography variant="h3" gutterBottom display="inline">
            Edit Lost and Found
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/lostandfound/list-lost-found">
              List Lost and Found
            </Link>
            <Typography>Edit Lost and Found</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} md={6} textAlign={"right"}>
          <IconButton
            aria-label="Menu"
            size="large"
            aria-owns={anchorEl ? "icon-menu" : undefined}
            aria-haspopup="true"
            onClick={(e) => handleActionClick(e)}
            style={{ color: "#1374C5" }}
          >
            <MenuIcon />
          </IconButton>
          <StyledMenu
            id="icon-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleActionClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem
              onClick={(e) => {
                CommlogDialogOpen();
                setAnchorEl(null);
              }}
            >
              <ListItemText primary={"Comm Log"} pl={1} />
            </MenuItem>
            <StyledLink
              target="_blank"
              to={"/lostandfound/lost-found-details/" + params.id}
            >
              <MenuItem>
                <ListItemText primary="Print Item Detail" pl={1} />
              </MenuItem>
            </StyledLink>
            <StyledLink
              target="_blank"
              to={"/lostandfound/bag-tag/" + params.id}
            >
              <MenuItem>
                <ListItemText primary="Print Bag Tag" pl={1} />
              </MenuItem>
            </StyledLink>
          </StyledMenu>
        </Grid>
      </Grid>
      <Divider my={6} />
      <AddLostAndFoundForm />
      {showAlert && (
        <CustomAlert
          message={alertMessage}
          severity={alertType}
          closeAlert={(show) => dispatch(updateShowAlert(show))}
          showAlert={showAlert}
        />
      )}
      {CommlogDialog && (
        <Commlog
          CommlogDialog={CommlogDialog}
          setCommlogDialog={setCommlogDialog}
          CommlogDialogClose={CommlogDialogClose}
          showAlertTost={(msg, type) => showAlertTost(msg, type)}
        />
      )}
    </React.Fragment>
  );
}

export default EditLostAndFound;
