import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { GetDepartmentList, DeleteDepartment } from "../../api";
import { useDispatch, useSelector } from "react-redux";
import Toaster from "../tasks/Toaster";
import DepartmentListCol from "../table-columns-json/equipment/departmentList";
import { setDepartmentList } from "../../redux/slices/equipment/equipmentTableColSlice";
import StyledColumnOptions from "../../helper/StyledColumnOptions";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../redux/slices/equipmentSlice";
import EditDepartment from "./innerform/EditDepartment";
import {
  Checkbox,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  CardContent,
  Card,
  Box,
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Switch,
  CircularProgress,
  DialogTitle,
  Dialog,
  DialogActions,
  Snackbar,
  Alert,
  Pagination,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  AddBox as AddBoxIcon,
  ListAlt as ListAltIcon,
  Settings as SettingsIcon,
  Search as SearchIcon,
  Edit as EditIcon,
  Cancel as CancelIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";

const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "140px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "department",
    numeric: true,
    disablePadding: false,
    label: "Department Name",
  },
  {
    id: "position_count",
    numeric: true,
    disablePadding: false,
    label: "Position Count",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];
const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    tableCalRedux,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all data" }}
          />
        </TableCell>
        {DepartmentListCol.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sx={{ whiteSpace: "nowrap" }}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={tableCalRedux[headCell.id] === true ? "" : "hide-cell"}
          >
            <TableSortLabel
              active={
                headCell.id === "department_name"
                  ? orderBy === headCell.id
                  : false
              }
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={
                headCell.id === "department_name"
                  ? createSortHandler(headCell.id)
                  : null
              }
              hideSortIcon={headCell.id === "department_name" ? false : true}
            >
              {headCell.label}
              {headCell.icon}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  /* Destructuring the props object. */
  const {
    totalRecord,
    setDataToSearch,
    handleDeleteOpen,
    tableCalRedux,
    defalutTableCalRedux,
  } = props;
  const [actions, setActions] = React.useState("");
  const [positionName, setPositionName] = useState("");
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setActions(event.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * It sets the anchor element to null
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorColumn, setAnchorColumn] = React.useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };

  return (
    <Toolbar>
      <div>
        <Paper mt={3}>
          <FormControl mr={2} sx={{ minWidth: 120 }}>
            <InputLabel id="demo-simple-select-label">Actions</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={actions}
              label="Actions"
              MenuProps={{
                PaperProps: {
                  sx: {
                    "& .MuiMenu-list": {
                      boxShadow:
                        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 10%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
                      margin: "0px 3px 5px 3px",
                      padding: "0",
                    },
                  },
                },
              }}
              onChange={handleChange}
            >
              <MenuItem value={"Delete"} onClick={handleDeleteOpen}>
                Delete
              </MenuItem>
            </Select>
          </FormControl>
        </Paper>
      </div>
      <div>
        {totalRecord > 0 ? (
          <Typography
            sx={{
              marginLeft: { xs: 3, md: 5 },
              fontSize: { xs: 12, md: 15 },
            }}
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content" }}
          >
            {totalRecord} records found
          </Typography>
        ) : (
          <Typography
            sx={{
              marginLeft: { xs: 3, md: 5 },
              fontSize: { xs: 12, md: 15 },
            }}
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content" }}
          >
            {totalRecord} records found
          </Typography>
        )}
      </div>
      <Spacer />
      <div>
        <Box
          mt={3}
          sx={{
            display: "flex",
            flexWrap: "nowrap",
          }}
        >
          <Item sx={{ flexGrow: 1 }}>
            <TextField
              sx={{
                minWidth: { xs: 400 },
                minWidth: { md: 300 },
              }}
              id="search"
              autoComplete="off"
              value={positionName}
              onChange={(e) => setPositionName(e.target.value)}
              placeholder="Search by department name"
              InputProps={{
                // sx: {
                //   minWidth: "300px",
                // },
                endAdornment: positionName && (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setDataToSearch("");
                      setPositionName("");
                    }}
                  >
                    <CancelIcon />
                  </IconButton>
                ),
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  setDataToSearch(positionName);
                }
              }}
            />
          </Item>
          <Item>
            <Button
              variant="contained"
              color="primary"
              sx={{
                borderTopLeftRadius: "0px",
                borderBottomLeftRadius: "0px",
                height: "100%",
                marginRight: { lg: "50px" },
                marginLeft: "-3px",
              }}
              onClick={() => setDataToSearch(positionName)}
            >
              <SearchIcon />
              {/* Search */}
            </Button>
          </Item>
        </Box>
      </div>
      <Spacer />
      <div>
        <Paper mt={3}>
          <IconButton
            aria-owns={anchorEl ? "icon-menu" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            variant="contained"
            color="secondary"
          >
            <FilterListIcon />
          </IconButton>
          <StyledFilterMenu
            id="icon-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem
              aria-label="task-list"
              component={NavLink}
              to="/equipment/add-department"
            >
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="Add Department" pl={0} />
            </MenuItem>
            <MenuItem
              aria-label="add-position"
              component={NavLink}
              to="/equipment/add-position"
            >
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="Add Position" pl={0} />
            </MenuItem>
            <MenuItem
              aria-label="add-position"
              component={NavLink}
              to="/equipment/positions-list"
            >
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="View Position" pl={0} />
            </MenuItem>
            <MenuItem
              aria-owns={anchorColumn ? "columns-dropdown" : undefined}
              aria-haspopup="true"
              onClick={handleClickColumn}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Columns" pl={0} />
            </MenuItem>
          </StyledFilterMenu>
          <StyledColumnDropdown
            id="columns-dropdown"
            anchorEl={anchorColumn}
            open={Boolean(anchorColumn)}
            onClose={handleCloseColumn}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem>
              <Card>
                <CardContent>
                  <Typography variant="h6" component="h2">
                    {
                      Object.keys(tableCalRedux).filter(
                        (k) => tableCalRedux[k] === true
                      ).length
                    }{" "}
                    out of {Object.keys(tableCalRedux).length} visible
                  </Typography>
                  <StyledColumnOptions
                    calStatus={tableCalRedux}
                    updateCalStatus={(data) => {
                      dispatch(setDepartmentList(data));
                    }}
                    listing={DepartmentListCol}
                  />
                  <Toolbar>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        dispatch(setDepartmentList(defalutTableCalRedux));
                      }}
                    >
                      Reset
                    </Button>
                    <Spacer />
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleCloseColumn}
                    >
                      Cancel
                    </Button>
                  </Toolbar>
                </CardContent>
              </Card>
            </MenuItem>
          </StyledColumnDropdown>
        </Paper>
      </div>
    </Toolbar>
  );
};
function EnhancedTable(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("color");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [departmentList, setDepartmentList] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [dataToSearch, setDataToSearch] = useState(null);
  const [id, setId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [length, setLength] = useState(null);
  const [alert, setAlert] = useState("");
  const [msg, setMsg] = useState("");
  const snackbarStatus = useSelector((state) => state.equipment.snackStatus);
  const snackbarMsg = useSelector((state) => state.equipment.msg);
  const snackbarAlert = useSelector((state) => state.equipment.snackAlert);
  const [departmentName, setDepartmentName] = useState("");
  const dispatch = useDispatch();

  const tableCalRedux = useSelector(
    (state) => state.equipmentTableCol.departmentList
  );
  const defalutTableCalRedux = useSelector(
    (state) => state.defaultEquipmentTableCol.departmentList
  );
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = departmentList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    setLength(newSelected.length);
  };

  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
  };
  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
  };
  /**
   * It sets the page number to the new page number and then sets the current page number to the new
   * page number plus one
   * @param event - The event that triggered the callback.
   * @param newPage - The page number that the user clicked on.
   */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  /**
   * It takes the event object, parses the value of the event target to an integer, sets the rows per
   * page to that value, and sets the page to 0
   * @param event - The event that triggered the function.
   */
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const [anchorEl, setAnchorEl] = React.useState(null);

  /**
   * When the user clicks on the action button, the anchor element is set to the current target, the id
   * is set to the id of the current row, and the length is set to 1
   * @param e - the event object
   * @param id - The id of the item that was clicked.
   */
  const handleActionClick = (e, id, department) => {
    setAnchorEl(e.currentTarget);
    setId(id);
    setLength(1);
    setDepartmentName(department);
  };

  /**
   * It sets the anchor element to null
   */
  const handleActionClose = () => {
    setAnchorEl(null);
  };

  /**
   * This function is used to get the list of all the positions.
   */
  const getEquipDepartmentList = () => {
    const payload = {
      limit: rowsPerPage,
      department_name: dataToSearch,
      sorting: {
        order: order,
        by: orderBy,
      },
    };
    setLoading(true);
    GetDepartmentList(currentPage, payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setDepartmentList(res.data);
          setTotalRecord(res.meta.total);
          setCurrentPage(res.meta.current_page);
          setLoading(false);
        } else {
          setLoading(false);
          setDepartmentList([]);
          setTotalRecord(0);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  //method for open the delete dialog
  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  /**
   * It closes the delete dialog and resets the anchor element and id
   */
  const handleDeleteClose = () => {
    setOpenDelete(false);
    setAnchorEl(null);
    setId(null);
  };

  /**
   * If the user clicks away from the snackbar, do nothing. Otherwise, close the snackbar
   * @param reason - The reason for the snackbar being closed.
   * @returns the state of the snackbar.
   */
  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(getSnackClose());
  };

  /**
   * It deletes the selected position(s) from the database
   */
  const deleteDepartment = () => {
    setAnchorEl(null);
    setOpenDelete(false);
    let value = selected.length > 0 ? selected : id;
    DeleteDepartment(value)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(getMsg("Record(s) has been  deleted successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          getEquipDepartmentList();
          setSelected([]);
        } else {
          dispatch(getMsg("Something went wrong, please try again later"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
        }
      })
      .catch((err) => {
        dispatch(getMsg("Something went wrong, please try again later"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
      });
  };
  /* A React Hook that is used to fetch data from the API. */
  useEffect(() => {
    getEquipDepartmentList();
    setTimeout(() => {
      dispatch(getSnackClose());
    }, 4000);
  }, [currentPage, rowsPerPage, dataToSearch, order, orderBy]);
  // Modal Edit Department
  const [editDepartment, setEditDepartment] = useState(false);
  const editDepartmentOpen = () => {
    setEditDepartment(true);
    handleActionClose();
  };
  const editDepartmentClose = () => {
    setEditDepartment(false);
  };
  return (
    <div>
      <Paper mt={3}>
        {snackbarStatus && (
          <Toaster
            openSnack={snackbarStatus}
            handleCloseSnack={handleCloseSnack}
            severity={snackbarAlert}
            message={snackbarMsg}
          />
        )}
        <div>
          {selected.length || id > 0 ? (
            <Dialog
              open={openDelete}
              onClose={handleDeleteClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Are you sure you want to delete department?"}
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleDeleteClose} variant="contained">
                  Cancel
                </Button>
                <Button
                  onClick={deleteDepartment}
                  autoFocus
                  variant="contained"
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          ) : (
            <Dialog
              open={openDelete}
              onClose={handleDeleteClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"You haven't selected any item!"}
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleDeleteClose} variant="contained">
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </div>
        <EnhancedTableToolbar
          numSelected={selected.length}
          totalRecord={totalRecord}
          setDataToSearch={setDataToSearch}
          handleDeleteOpen={handleDeleteOpen}
          tableCalRedux={tableCalRedux}
          defalutTableCalRedux={defalutTableCalRedux}
        />
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={departmentList.length}
              tableCalRedux={tableCalRedux}
            />
            {loading ? (
              <TableCell colSpan={13} align="center">
                <CircularProgress />
              </TableCell>
            ) : (
              <TableBody>
                {stableSort(departmentList, getComparator(order, orderBy))
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow hover key={row.id} selected={isItemSelected}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            onClick={(event) => handleClick(event, row.id)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          align="left"
                          scope="row"
                          sx={{ lg: 'whiteSpace: "nowrap"' }}
                          className={
                            tableCalRedux.id === true ? "" : "hide-cell"
                          }
                        >
                          {row.id}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.department_name === true
                              ? ""
                              : "hide-cell"
                          }
                        >
                          {row.department}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.position_count === true
                              ? ""
                              : "hide-cell"
                          }
                        >
                          {row.count}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={
                            tableCalRedux.action === true ? "" : "hide-cell"
                          }
                        >
                          <IconButton
                            aria-label="Settings"
                            size="large"
                            aria-owns={anchorEl ? "icon-menu" : undefined}
                            aria-haspopup="true"
                            onClick={(e) =>
                              handleActionClick(e, row.id, row.department)
                            }
                            style={{ color: "#1374C5" }}
                          >
                            <SettingsIcon />
                          </IconButton>
                          <StyledMenu
                            id="icon-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleActionClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <MenuItem onClick={editDepartmentOpen}>
                              <ListItemIcon>
                                <EditIcon />
                              </ListItemIcon>
                              <ListItemText primary="Edit" pl={1} />
                            </MenuItem>

                            <MenuItem onClick={handleDeleteOpen}>
                              <ListItemIcon>
                                <DeleteIcon />
                              </ListItemIcon>
                              <ListItemText primary="Delete" pl={1} />
                            </MenuItem>
                          </StyledMenu>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {departmentList.length === 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 6 : 0) * departmentList.length,
                    }}
                  >
                    <TableCell align="center" colSpan={10}>
                      No records found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          }}
        >
          <Pagination
            count={Math.ceil(totalRecord / rowsPerPage)}
            page={currentPage}
            onChange={handleChangePagination}
            color="primary"
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ marginRight: "8px" }}>
                Rows per page:
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                // label="Age"
                onChange={handleChangeLimit}
              >
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </Box>
          </FormControl>
        </Box>
        {/* <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={totalRecord}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
      {editDepartment && (
        <EditDepartment
          editDepartment={editDepartment}
          setEditDepartment={setEditDepartment}
          editDepartmentOpen={editDepartmentOpen}
          editDepartmentClose={editDepartmentClose}
          departmentName={departmentName}
          setDepartmentName={setDepartmentName}
          id={id}
          getEquipDepartmentList={getEquipDepartmentList}
        />
      )}
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </div>
  );
}

function DepartmentList() {
  const [snackopen, setSnackopen] = useState(true);
  const SnackState = useSelector((state) => state.equipment.snackStatus);
  const messageStatus = useSelector((state) => state.equipment.msg);
  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackopen(false);
  };
  return (
    <React.Fragment>
      {SnackState && (
        <Snackbar
          open={snackopen}
          autoHideDuration={3000}
          onClose={handleSnackClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Alert
            onClose={handleSnackClose}
            severity="success"
            variant="filled"
            sx={{ maxWidth: 600 }}
          >
            {messageStatus}
          </Alert>
        </Snackbar>
      )}
      <Helmet title="Departments" />
      <Typography variant="h3" gutterBottom display="inline">
        Departments
      </Typography>

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default DepartmentList;
