import * as Yup from "yup";
import moment from "moment";
import { Formik } from "formik";
import { makeStyles } from "@mui/styles";
import { Helmet } from "react-helmet-async";
import styled from "styled-components/macro";
import { useSelector, useDispatch } from "react-redux";
import ReIssueLogs from "./inner-component/ReIssueLogs";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import CustomAlert from "../../components/form-components/CustomAlert";
import CustomSelect from "../../components/form-components/CustomSelect";
import { updateShowAlert, updateText } from "../../redux/slices/mainSlice";
import { getFileBase64, handleApiResonseErrors } from "../../helper/helper";
import CustomTextField from "../../components/form-components/CustomTextField";
import {
  PositionByDepartment,
  DepartmentList,
  SaveEmployee,
  ViewEmployee,
  UpdateEmployee,
} from "../../api";

import {
  Avatar,
  Divider as MuiDivider,
  Typography,
  FormControl as MuiFormControl,
  Breadcrumbs as MuiBreadcrumbs,
  Link,
  Grid,
  Card,
  CardContent,
  TextField,
  Button,
  Box,
  Paper,
  CircularProgress,
} from "@mui/material";
import { spacing } from "@mui/system";
import { DatePicker } from "@mui/lab";
import CameraFeeding from "../../helper/camera-feeding";
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 20px 0 15px ${(props) => props.theme.spacing(2)};
`;
// Classes Styling
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));

function EditEmployeeForm(props) {
  const [badgeIssueDate, setBadgeIssueDate] = useState();
  const [departmentList, setDepartmentList] = useState([]);
  const [positionList, setPositionList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [recordData, setRecordData] = useState({});
  const [showWebCam, setShowWebCam] = React.useState(false);
  // Alert Message
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");

  const params = useParams();
  const classes = useStyles();
  const uploadedFileRef = useRef();
  const navigate = useNavigate();
  // Redux
  const showAlert = useSelector((state) => state.main.showAlert);
  const dispatch = useDispatch();

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validationSchema = Yup.object().shape({
    employee_id: Yup.number()
      .required("please enter Barcode ID")
      .positive("Please enter a valid Barcode ID.")
      .typeError("Please enter a valid Barcode ID."),
    other_employee: Yup.number()
      .nullable()
      .positive("Please enter a valid Employee ID.")
      .typeError("Please enter a valid Employee ID."),
    fname: Yup.string().required("Please enter first name."),
    lname: Yup.string().required("Please enter last name."),
    department: Yup.number().required("Please select department."),
    position: Yup.number().required("Please select position."),
    phone: Yup.string()
      .nullable()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Phone must be at least 10 characters")
      .max(15, "Phone must be at most 15 characters"),
  });

  let initialValues = {
    employee_id: recordData
      ? recordData.employee_id
        ? recordData.employee_id
        : ""
      : "",
    other_employee: recordData
      ? recordData.other_employee
        ? recordData.other_employee
        : ""
      : "",
    fname: recordData ? (recordData.fname ? recordData.fname : "") : "",
    lname: recordData ? (recordData.lname ? recordData.lname : "") : "",
    department: recordData
      ? recordData.department
        ? recordData.department
        : ""
      : "",
    position: recordData
      ? recordData.position
        ? recordData.position
        : ""
      : "",
    badge_issue_date: recordData
      ? recordData.badge_issue_date
        ? recordData.badge_issue_date
        : ""
      : "",
    phone: recordData ? (recordData.phone ? recordData.phone : "") : "",
    note: recordData ? (recordData.note ? recordData.note : "") : "",
    image: recordData ? (recordData.image ? recordData.image : "") : "",
  };

  console.log(initialValues);
  useEffect(() => {
    getDepartmentList();
    GetSingleRecord();
  }, []);
  function isUrl(str) {
    const pattern =
      /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
    return pattern.test(str);
  }
  const GetSingleRecord = () => {
    ViewEmployee(params.id)
      .then((res) => {
        if (res.statusCode == 200) {
          setRecordData(res.data);
          getPositionList(res.data.department);
          let date = res.data.badge_issue_date
            ? moment(res.data.badge_issue_date).format("MM/DD/YYYY")
            : null;
          setUploadedImage(res.data.image);
          setBadgeIssueDate(date);
          setLoading(false);
          // setUploadedImage(res.data.image);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDepartmentList = () => {
    DepartmentList()
      .then((res) => {
        setDepartmentList(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getPositionList = (value) => {
    setLoading(true);
    PositionByDepartment(value)
      .then((res) => {
        if (res.statusCode === 200) {
          setPositionList(res.data);
        } else {
          setPositionList([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleShowWebCam = () => {
    let changeSetShowWebCam = !showWebCam;
    setShowWebCam(changeSetShowWebCam);
  };

  const handleFileChange = (evt) => {
    let file = evt.target.files[0];
    getFileBase64(file, (result) => {
      setUploadedImage(result);
    });
  };
  const clearSelectedImage = () => {
    setUploadedImage(null);
    uploadedFileRef.current.value = "";
  };

  const messageAlert = (msg, type) => {
    setLoading(false);
    setAlertMessage(msg);
    setAlertType(type);
    dispatch(updateShowAlert(true));
  };

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoading(true);
      let date = badgeIssueDate ? moment(badgeIssueDate) : "";
      date = date ? date.format("YYYY-MM-DD") : "";

      let formData = {
        id: params.id,
        employee_id: values.employee_id,
        other_employee: values.other_employee,
        fname: values.fname,
        lname: values.lname,
        department: values.department,
        position: values.position,
        phone: values.phone,
        note: values.note,
        badge_issue_date: date,
        image: isUrl(uploadedImage) ? null : uploadedImage,
      };
      UpdateEmployee(formData)
        .then((res) => {
          if (res.statusCode === 200) {
            dispatch(updateShowAlert(true));
            dispatch(updateText("Record has been updated successfully"));
            navigate({ pathname: "/logs/employees" });
          } else {
            messageAlert("Sorry! something wrong please try again", "error");
          }
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            messageAlert(error, "error");
          }
        });
    } catch (error) {
      messageAlert("Sorry! something wrong please try again", "error");
    }
  };

  return (
    <>
      <Card mt={6}>
        <CardContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({ errors, values, setFieldValue, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <CustomTextField
                        name="employee_id"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        label="Barcode ID*"
                        autoComplete="off"
                        placeholder="Type or scan existing barcode"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomTextField
                        name="other_employee"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        label="Employee ID"
                        autoComplete="off"
                        placeholder="Global/corporate ID"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomTextField
                        name="fname"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        label="First name*"
                        autoComplete="off"
                        placeholder="John"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomTextField
                        name="lname"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        label="Last name*"
                        autoComplete="off"
                        placeholder="Smith"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomSelect
                        name="department"
                        fieldLabel="Department*"
                        options={departmentList}
                        displayEmpty
                        customCallbackMethod={(evt) => {
                          if (evt.target.value) {
                            setFieldValue("position", "");
                            getPositionList(evt.target.value);
                          }
                        }}
                        firstOptionTitle="Select Department"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomSelect
                        name="position"
                        fieldLabel="Position*"
                        options={positionList}
                        displayEmpty
                        firstOptionTitle={
                          values.department
                            ? "Select Position"
                            : "Select Dept first"
                        }
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <DatePicker
                        name="badge_issue_date"
                        label="Badge issue date*"
                        value={badgeIssueDate}
                        onChange={(newValue) => {
                          setBadgeIssueDate(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <BigAvatar alt="image name" src={uploadedImage} />

                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          JustifyContent: "space-between",
                          margin: "10px 0 15px",
                          gap: "15px",
                        }}
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          component="span"
                          onClick={(e) => clearSelectedImage(e)}
                        >
                          Clear
                        </Button>
                        <Button
                          variant="outlined"
                          color="warning"
                          component="span"
                          onClick={() => handleShowWebCam()}
                        >
                          {showWebCam ? "Close WebCam" : "WebCam"}
                        </Button>
                      </Box>
                      <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id="raised-button-file"
                        multiple
                        type="file"
                        ref={uploadedFileRef}
                        onChange={(e) => handleFileChange(e)}
                      />
                      <label htmlFor="raised-button-file">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                        >
                          Choose image
                        </Button>
                      </label>
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomTextField
                        name="phone"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        label="Phone"
                        autoComplete="off"
                        placeholder="xxx-xxx-xxxx"
                        inputProps={{ maxLength: 20 }}
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomTextField
                        name="note"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        label="User notes"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={12} md={12}>
                    <Button type="submit" color="primary" variant="contained">
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
      {showAlert && (
        <CustomAlert
          message={alertMessage}
          severity={alertType}
          closeAlert={(show) => dispatch(updateShowAlert(show))}
          showAlert={showAlert}
        />
      )}
      {showWebCam && (
        <CameraFeeding
          showWebCam={showWebCam}
          closeWabCam={() => setShowWebCam(false)}
          // value={uploadedImage}
          // onChange={handleFileChange}
          setUploadedImage={setUploadedImage}
          uploadedImage={uploadedImage}
        />
      )}
    </>
  );
}
function EditEmployee() {
  const params = useParams();
  return (
    <React.Fragment>
      <Helmet title="Edit Employee" />
      <Typography variant="h3" gutterBottom display="inline">
        Edit Employee
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/logs/employees">
          Employees
        </Link>
        <Typography>Edit Employee</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <EditEmployeeForm />
      <ReIssueLogs id={params.id} />
    </React.Fragment>
  );
}

export default EditEmployee;
