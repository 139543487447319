import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Formik } from "formik";
import CustomTextField from "../../../components/form-components/CustomTextField";
import {
  GetAddKeyBuildingList,
  DeleteKeyBuilding,
  AddNewKeyBuilding,
} from "../../../api";
import { handleApiResonseErrors } from "../../../helper/helper";
import * as Yup from "yup";
import EditKeyBuilding from "./EditKeyBuilding";
import { useDispatch } from "react-redux";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../../redux/slices/equipmentSlice";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Menu,
  Typography,
  FormControl as MuiFormControl,
  Button,
  IconButton,
  Paper,
  Grid,
  Table,
  TableContainer,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CircularProgress,
} from "@mui/material";
import {
  CancelRounded as CancelRoundedIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));

const AddKeyBuilding = ({
  addKeyBuilding,
  addKeyBuildingClose,
  childToParent,
}) => {
  // Action Dropdown
  const [addKeyBuildingAction, setAddKeyBuildingAction] = React.useState(null);
  const [keyBuildingList, setKeyBuildingList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [buildingId, setBuildingId] = useState(null);
  const [close, setClose] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [singleData, setSingleData] = useState({});
  const dispatch = useDispatch();

  const addKeyBuildingActionClick = (event, id, data) => {
    setAddKeyBuildingAction(event.currentTarget);
    setBuildingId(id);
    setSingleData(data);
  };
  const addKeyBuildingActionClose = () => {
    setAddKeyBuildingAction(null);
  };

  /* The above code is creating a state variable called editKeyCabinet and setting it to false. It is
  also creating a function called editKeyCabinetOpen that sets the state variable to true. */
  const [editKeyBuilding, setEditKeyBuilding] = useState(false);
  const editKeyBuildingOpen = () => {
    setEditKeyBuilding(true);
  };

  /**
   * This function closes the edit key Building modal
   */
  const editKeyBuildingClose = () => {
    setEditKeyBuilding(false);
  };
  //method for open the delete dialog
  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  /**
   * It closes the delete dialog and resets the anchor element and id
   */
  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  /**
   * It closes the dialog box.
   * @param e - The event object
   */
  const closeDialogHandle = (e) => {
    setClose(e.target.value);
  };

  /**
   * This function is used to get the list of key cabinets from the database
   */
  const addKeyBuildingList = () => {
    setLoading(true);
    GetAddKeyBuildingList()
      .then((res) => {
        if (res.statusCode === 200) {
          setKeyBuildingList(res.data);
          childToParent(res.data, "key_building");
          setLoading(false);
        } else {
          setKeyBuildingList([]);
          childToParent(res.data, "key_building");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  /**
   * This function is used to delete a key cabinet
   */
  const deleteKeyBuilding = () => {
    handleDeleteClose();
    DeleteKeyBuilding(buildingId)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(getSnackOpen());
          dispatch(getMsg("Record has been deleted successfully"));
          dispatch(getSnackAlert("success"));
          addKeyBuildingList();
        } else {
          dispatch(getSnackOpen());
          dispatch(getMsg("Something went wrong"));
          dispatch(getSnackAlert("error"));
        }
      })
      .catch((err) => {
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        if (error) {
          setErrMsg(error);
        }
        dispatch(getSnackClose());
      });
  };

  /* The above code is using the useEffect hook to call the addKeyCabinetList function when the component
mounts. */
  useEffect(() => {
    addKeyBuildingList();
  }, []);

  /* Creating a validation schema for the form. */
  const validationSchema = Yup.object().shape({
    bulding_name: Yup.string().required("Please enter key building name"),
    building_addr_1: Yup.string().required(
      "Please enter key building address1"
    ),
    building_addr_2: Yup.string().required(
      "Please enter key building address1"
    ),
    building_city: Yup.string().required("Please enter key building city"),
    building_state: Yup.string().required("Please enter key building state"),
    building_zip: Yup.string().required("Please enter key building zip"),
    admin_first_name: Yup.string().required(
      "Please enter key building admin first name"
    ),
    admin_last_name: Yup.string().required(
      "Please enter key building admin last name"
    ),
    admin_email: Yup.string().required("Please enter key building admin email"),
    admin_phone: Yup.string().required(
      "Please enter key building admin admin phone"
    ),
    admin_fax: Yup.string().required("Please enter key building admin fax"),
  });

  /* Setting the initial values for the form. */
  let initialValues = {
    bulding_name: "",
    building_addr_1: "",
    building_addr_2: "",
    building_city: "",
    building_state: "",
    building_zip: "",
    admin_first_name: "",
    admin_last_name: "",
    admin_email: "",
    admin_phone: "",
    admin_fax: "",
  };

  /**
   * A function that is called when the form is submitted.
   * @param values - An object containing all values of the form.
   */
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      let formData = {
        name: values.bulding_name,
        address1: values.building_addr_1,
        address2: values.building_addr_2,
        city: values.building_city,
        state: values.building_state,
        zip: values.building_zip,
        admin_fname: values.admin_first_name,
        admin_lname: values.admin_last_name,
        admin_email: values.admin_email,
        admin_phone: values.admin_phone,
        admin_fax: values.admin_fax,
      };
      AddNewKeyBuilding(formData)
        .then((res) => {
          if (res.statusCode === 200) {
            dispatch(getSnackOpen());
            dispatch(getMsg("Key building has been submitted successfully"));
            dispatch(getSnackAlert("success"));
            if (close === "close") {
              addKeyBuildingClose();
            }
            addKeyBuildingList();
          } else {
            dispatch(getSnackClose());
          }
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            setErrMsg(error);
          }
          dispatch(getSnackClose());
        });
      resetForm();
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  return (
    <>
      <Dialog
        open={addKeyBuilding}
        onClocse={addKeyBuildingClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "700px",
            },
          },
        }}
        aria-labelledby="add-key-building-title"
        aria-describedby="add-key-building-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="add-key-building-title"
        >
          <Typography variant="h4">Add Key building</Typography>
          <DialogActions>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={addKeyBuildingClose}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              values,
              status,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <CustomTextField
                        name="bulding_name"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key building name"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomTextField
                        name="building_addr_1"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key building address1"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomTextField
                        name="building_addr_2"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key building address2"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomTextField
                        name="building_city"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key building city"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomTextField
                        name="building_state"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key building state"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomTextField
                        name="building_zip"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key building zip"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <CustomTextField
                        name="admin_first_name"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key building admin first name"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomTextField
                        name="admin_last_name"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key building admin last name"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomTextField
                        name="admin_email"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key building admin email"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomTextField
                        name="admin_phone"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key building admin phone"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomTextField
                        name="admin_fax"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key building admin fax"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Button color="primary" variant="outlined" type="submit">
                      Save & add more
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      sx={{ marginLeft: "10px" }}
                      type="submit"
                      value={"close"}
                      onClick={closeDialogHandle}
                    >
                      Save & done
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
          <Paper sx={{ marginTop: "30px" }}>
            <Dialog
              open={openDelete}
              onClose={handleDeleteClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Are you sure you want to delete  key building?"}
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleDeleteClose} variant="contained">
                  Cancel
                </Button>
                <Button
                  onClick={deleteKeyBuilding}
                  autoFocus
                  variant="contained"
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>
            <Typography variant="h4" component="div" gutterBottom>
              Existing Key Building
            </Typography>
            <TableContainer className="tableContainer">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow hover>
                    <TableCell>Name</TableCell>
                    <TableCell>Admin Name</TableCell>
                    <TableCell>Admin Email</TableCell>
                    <TableCell>Admin Phone</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                {loading ? (
                  <TableCell colSpan={13} align="center" padding="none">
                    <CircularProgress size={40} />
                  </TableCell>
                ) : (
                  <TableBody>
                    {keyBuildingList.map((data) => {
                      return (
                        <TableRow hover>
                          <TableCell>{data.name}</TableCell>
                          <TableCell>
                            {data.admin_fname + " " + data.admin_lname}
                          </TableCell>
                          <TableCell>{data.admin_email}</TableCell>
                          <TableCell>{data.admin_phone}</TableCell>
                          <TableCell padding="none">
                            <IconButton
                              aria-label="Settings"
                              size="large"
                              aria-owns={
                                addKeyBuildingAction
                                  ? "add-key-building-action"
                                  : undefined
                              }
                              aria-haspopup="true"
                              onClick={(e) =>
                                addKeyBuildingActionClick(e, data.id, data)
                              }
                              style={{ color: "#1374C5" }}
                            >
                              <SettingsIcon />
                            </IconButton>
                            <StyledMenu
                              id="add-key-building-action"
                              anchorEl={addKeyBuildingAction}
                              open={Boolean(addKeyBuildingAction)}
                              onClose={addKeyBuildingActionClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <MenuItem
                                onClick={() => {
                                  editKeyBuildingOpen();
                                  addKeyBuildingActionClose();
                                }}
                              >
                                <ListItemIcon>
                                  <EditIcon />
                                </ListItemIcon>
                                <ListItemText primary="Edit" pl={1} />
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  handleDeleteOpen();
                                  addKeyBuildingActionClose();
                                }}
                              >
                                <ListItemIcon>
                                  <DeleteIcon />
                                </ListItemIcon>
                                <ListItemText primary="Delete" pl={1} />
                              </MenuItem>
                            </StyledMenu>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {keyBuildingList.length === 0 && (
                      <TableRow>
                        <TableCell align="center" colSpan={10}>
                          No records found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={addKeyBuildingClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {editKeyBuilding && (
        <EditKeyBuilding
          editKeyBuilding={editKeyBuilding}
          editKeyBuildingClose={editKeyBuildingClose}
          setEditKeyBuilding={setEditKeyBuilding}
          editKeyBuildingOpen={editKeyBuildingOpen}
          singleData={singleData}
          addKeyBuildingList={addKeyBuildingList}
          buildingId={buildingId}
        />
      )}
    </>
  );
};

export default AddKeyBuilding;
