import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  IconButton,
  DialogContentText,
  FormControl as MuiFormControl,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
  Grid,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Menu,
  InputLabel,
  TextField,
  Select,
  CircularProgress,
} from "@mui/material";
import {
  CancelRounded as CancelRoundedIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import {
  ActivityAlertList,
  AddActivityAlert,
  DeleteActivityAlert,
} from "../../../api";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  getSnackOpen,
  getSnackClose,
  getSnackMsg,
  getSnackAlert,
} from "../../../redux/slices/reportSlice";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const ActivityAlertDialog = ({ activityAlertOpen, activityAlertClose }) => {
  const [activityAlertList, setActivityAlertList] = useState([]);
  const [deleteID, setDeleteID] = useState("");
  const dispatch = useDispatch();
  const [dialogLoader, setDialogLoader] = useState(false); //state for dialog loader
  const [dense, setDense] = React.useState(true);

  // Action Dropdown
  const [activityAlertsDropdown, setActivityAlerts] = React.useState(null);
  const handleActivityAlerts = (event, id) => {
    setActivityAlerts(event.currentTarget);
    setDeleteID(id);
  };
  const activityAlertsClose = () => {
    setActivityAlerts(null);
  };

  // Method for daily activity log dialog listing
  const getDailyActivityAlertList = () => {
    setDialogLoader(true);
    ActivityAlertList()
      .then((res) => {
        setActivityAlertList(res.data);
        setDialogLoader(false);
        // setUserName(res.data.UserName);
        // setSendTo(res.data.Email);
      })
      .catch((err) => {
        setDialogLoader(false);
        console.log(err);
      });
  };

  const handleActivityDelete = () => {
    DeleteActivityAlert(deleteID)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(getSnackMsg("Activity alert deleted successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          getDailyActivityAlertList();
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getSnackMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        dispatch(getSnackMsg("Something went wrong, please try again"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };

  const initialValues = {
    inputHour: "",
    inputActivityPoints: "",
  };

  const validationSchema = Yup.object().shape({
    inputHour: Yup.string().required("Please select hours"),
    inputActivityPoints: Yup.number()
      .integer()
      .positive("Please enter a valid number")
      .typeError("Please enter a valid number")
      .required("Please enter activity points"),
  });

  const handleSubmit = (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    let payload = {
      hour: values.inputHour,
      activity_points: values.inputActivityPoints,
    };
    AddActivityAlert(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(getSnackMsg("Activity alert has been added successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          getDailyActivityAlertList();
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getSnackMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        dispatch(getSnackMsg("Something went wrong, please try again"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
        // setMsg("Something went wrong, please try again");
        // setAlert("error");
        // setOpenSnack(true);
      });
  };
  useEffect(() => {
    getDailyActivityAlertList();
  }, []);

  let hourArray = [
    "0000",
    "0100",
    "0200",
    "0300",
    "0400",
    "0500",
    "0600",
    "0700",
    "0800",
    "0900",
    "1000",
    "1100",
    "1200",
    "1300",
    "1400",
    "1500",
    "1600",
    "1700",
    "1800",
    "1900",
    "2000",
    "2100",
    "2200",
    "2300",
  ];

  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "700px",
            },
          },
        }}
        aria-labelledby="showchart-title"
        aria-describedby="showchart-description"
        open={activityAlertOpen}
        onClose={activityAlertClose}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="showchart-title"
        >
          <Typography variant="h4">Activity Alerts</Typography>
          <DialogActions>
            <IconButton
              onClick={activityAlertClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            INSTRUCTIONS: Select an hour of the day from the dropdown. Next,
            enter a minimal activity level that you would expect during this
            hour. If activity points are below this level for the selected hour,
            SOVA will send notifications to recipients listed in the
            Communication screen for Activity Alerts. Alerts are sent within the
            next hour. For example, if you set an activity alert of 50 activity
            points for 0400 hours, if activity points are less than 50, persons
            subscribed in the Communication list should receive an email alert
            at approximately 0500 hours.
          </DialogContentText>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              status,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={6}>
                      <TextField
                        id="hour"
                        label="Select hour*"
                        name="inputHour"
                        select
                        value={values.inputHour}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={Boolean(touched.inputHour && errors.inputHour)}
                        helperText={touched.inputHour && errors.inputHour}
                      >
                        <MenuItem value="">Select hour</MenuItem>
                        {hourArray.map((n) => (
                          <MenuItem value={n}>{n}</MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={6}>
                      <TextField
                        id="activity-points"
                        label="Activity points*"
                        name="inputActivityPoints"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={values.inputActivityPoints}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        error={Boolean(
                          touched.inputActivityPoints &&
                            errors.inputActivityPoints
                        )}
                        helperText={
                          touched.inputActivityPoints &&
                          errors.inputActivityPoints
                        }
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button
                      mt={3}
                      color="primary"
                      variant="contained"
                      type="submit"
                    >
                      Save new alerts
                    </Button>
                  </Grid>
                </Grid>
                <DialogContentText
                  sx={{ paddingTop: "15px", paddingBottom: "20px" }}
                >
                  Between the hours of 0000 and{" "}
                  {values.inputHour ? values.inputHour : "0000"}, if activity
                  point values are less than{" "}
                  {values.inputActivityPoints
                    ? values.inputActivityPoints
                    : "40"}
                  , send an alert. Be sure to add recipients in the
                  Communication screen under 'Activity alerts'.
                </DialogContentText>
              </form>
            )}
          </Formik>
          <TableContainer className="tableContainer">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Hour</TableCell>
                  <TableCell>Activity Points</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              {dialogLoader ? (
                <TableCell colSpan={5} align="center">
                  <CircularProgress />
                </TableCell>
              ) : (
                <TableBody>
                  {activityAlertList.map((item) => {
                    return (
                      <TableRow hover key={item.id}>
                        <TableCell>{item.hour}</TableCell>
                        <TableCell>{item.activity_points}</TableCell>
                        <TableCell>{item.active}</TableCell>
                        <TableCell>
                          <IconButton
                            aria-label="activity-alerts"
                            size="large"
                            aria-owns={
                              activityAlertsDropdown
                                ? "activity-alerts"
                                : undefined
                            }
                            aria-haspopup="true"
                            onClick={(e) => {
                              handleActivityAlerts(e, item.id);
                            }}
                            style={{ color: "#1374C5" }}
                          >
                            <SettingsIcon />
                          </IconButton>
                          <StyledMenu
                            id="activity-alerts"
                            anchorEl={activityAlertsDropdown}
                            open={Boolean(activityAlertsDropdown)}
                            onClose={activityAlertsClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            {/* <MenuItem>
                            <ListItemIcon>
                              <EditIcon />
                            </ListItemIcon>
                            <ListItemText primary="Edit" pl={1} />
                          </MenuItem> */}
                            <MenuItem
                              onClick={() => {
                                handleActivityDelete();
                                activityAlertsClose();
                              }}
                            >
                              <ListItemIcon>
                                <DeleteIcon />
                              </ListItemIcon>
                              <ListItemText primary="Delete" pl={1} />
                            </MenuItem>
                          </StyledMenu>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {activityAlertList.length < 1 && (
                    <TableRow
                      style={{ height: (dense ? 6 : 0) * activityAlertList }}
                    >
                      <TableCell colSpan={10} align="center">
                        No record found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions sx={{ paddingRight: "36px" }}>
          <Button
            color="primary"
            variant="outlined"
            onClick={activityAlertClose}
          >
            Close
          </Button>
          {/* <Button color="primary" variant="contained">
            Activity Alerts
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ActivityAlertDialog;
