import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import html2canvas from "html2canvas";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { makeStyles } from "@mui/styles";
import { handleApiResonseErrors } from "../../helper/helper";
import { useSelector, useDispatch } from "react-redux";
import { updateShowAlert, updateText } from "../../redux/slices/mainSlice";
import CustomAlert from "../../components/form-components/CustomAlert";
import { SaveEmergencyProcedures, EmergencyProcedures } from "../../api";

import {
  Box,
  CardContent,
  Paper,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)`
  ${spacing};

  overflow: visible;
`;

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 200px;
  }
`;

const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));

function EditEmergencyProceduresContent(props) {
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [submitCount, setSubmitCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const params = useParams();

  // Alert Message
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");

  // Redux
  const showAlert = useSelector((state) => state.main.showAlert);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const makeSnapShot = () => {
    var newCount = submitCount + 1;
    if (description) {
      html2canvas(document.querySelector(".ql-editor")).then(function (canvas) {
        setImage(canvas.toDataURL("image/png"));
        console.log(canvas.toDataURL("image/png"));
        setSubmitCount(newCount);
      });
    } else {
      setSubmitCount(newCount);
    }
  };

  useEffect(() => {
    getEmergencyData();
  }, []);

  useEffect(() => {
    if (submitCount > 0) {
      submit();
    }
  }, [submitCount]);

  const submit = () => {
    let payload = {
      type: params.type,
      sub_type: params.sub_type,
      description: description,
      image: image,
    };
    setLoading(true);
    SaveEmergencyProcedures(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(updateShowAlert(true));
          dispatch(updateText("Record has been updated successfully"));
          navigate({ pathname: "/logs/emergency-procedures" });
        } else {
          messageAlert("Sorry! something wrong please try again", "error");
        }
        setLoading(false);
      })
      .catch((err) => {
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        if (error) {
          messageAlert(error, "error");
        }
      });
  };

  const getEmergencyData = () => {
    let payload = {
      type: params.type,
      sub_type: params.sub_type,
    };
    EmergencyProcedures(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setDescription(res.data.description);
        }
        setLoading(false);
      })
      .catch((err) => {});
  };

  const messageAlert = (msg, type) => {
    setLoading(false);
    setAlertMessage(msg);
    setAlertType(type);
    dispatch(updateShowAlert(true));
  };

  return (
    <>
      <Card mt={6}>
        <CardContent>
          <Box mt={3}>
            <QuillWrapper>
              <ReactQuill
                theme="snow"
                value={description}
                onChange={setDescription}
                placeholder="Type something.."
              />
            </QuillWrapper>
            <Button
              color="primary"
              variant="contained"
              sx={{ mt: "20px" }}
              onClick={() => makeSnapShot()}
            >
              Submit
            </Button>
          </Box>
        </CardContent>
      </Card>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
      {showAlert && (
        <CustomAlert
          message={alertMessage}
          severity={alertType}
          closeAlert={(show) => dispatch(updateShowAlert(show))}
          showAlert={showAlert}
        />
      )}
    </>
  );
}

function EditEmergencyProcedures() {
  return (
    <React.Fragment>
      <Helmet title="Emergency Procedures" />
      <Typography variant="h3" gutterBottom display="inline">
        Emergency Procedures
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/logs/emergency-procedures">
          Emergency Procedures
        </Link>
        <Typography>Fire Respond</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <EditEmergencyProceduresContent />
    </React.Fragment>
  );
}

export default EditEmergencyProcedures;
