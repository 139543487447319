import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import moment from "moment";
import { subMonths } from "date-fns";
import {
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TextField,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Dialog,
  CircularProgress,
} from "@mui/material";
import { DatePicker } from "@mui/lab";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { TourButtonMissedUser } from "../../../api";
import UsernameDialog2 from "./UsernameDialog2";

const Paper = styled(MuiPaper)(spacing);

const UsernameDialog1 = (props) => {
  const {
    userMissedbuttons,
    setUserMissedButtons,
    userMissedButtonsClose,
    userDialog,
    userIdD3,
  } = props;
  //state for date in missed User button popup
  const [sDateMissedUser, setSDateMissedUser] = useState(
    subMonths(new Date(), 1)
  );
  const [eDateMissedUser, setEDateMissedUser] = useState(new Date());
  const [dialogLoader, setDialogLoader] = useState(false); //state for dialog loader
  const [buttonMissedUser, setButtonMissedUser] = useState([]); //State for list in get-button-missed-user (D3) outer dialog username
  const [totalTourCountUser, setTotalTourCountUser] = useState(null);
  const [tagIdD4, setTagIdD4] = useState(""); //state for  getting the tagId in username D4
  const [tagNameD4, setTagNameD4] = useState("");
  //handler for (D3) get-button-missed-user: outer dialog for username column
  const getButtonsMissedUser = () => {
    let formatedsDate = moment(sDateMissedUser).format("YYYY-MM-DD");
    let formatedeDate = moment(eDateMissedUser).format("YYYY-MM-DD");
    let payload = {
      userId: userIdD3, //"9935162353",
      start_date: formatedsDate, // "2016-08-03",
      end_date: formatedeDate, //"2022-06-01",
    };
    setDialogLoader(true);
    TourButtonMissedUser(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setButtonMissedUser(res.data);
          setTotalTourCountUser(res.tour_run_stats_count);
        } else {
          setButtonMissedUser(res.data);
        }
        setDialogLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setDialogLoader(false);
      });
  };

  // get-user-List-missed-button-U: (D4) for inner dialog of username column
  const [buttonDetail, setButtonDetail] = React.useState(false);
  const buttonDetailOpen = (tagidd4, tagname) => {
    setButtonDetail(true);
    setTagIdD4(tagidd4);
    setTagNameD4(tagname);
  };

  const buttonDetailClose = () => {
    setButtonDetail(false);
  };

  useEffect(() => {
    getButtonsMissedUser();
  }, []);

  return (
    <>
      {/* get button missed user: outer dialog username */}
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
        open={userMissedbuttons}
        onClose={userMissedButtonsClose}
        aria-labelledby="missed-btn-title"
        aria-describedby="missed-btn-description"
      >
        <DialogTitle
          id="missed-btn-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h4" component="div">
            Missed Buttons: {userDialog}
          </Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setUserMissedButtons(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          {/* <Typography variant="h6">Hello</Typography> */}
          <Grid container spacing={4} mt={2} sx={{ padding: "0 24px" }}>
            <Grid item xs={12} md={4}>
              <DatePicker
                id="start-date"
                value={sDateMissedUser}
                onChange={(newValueStartDate) => {
                  setSDateMissedUser(newValueStartDate);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "Start date",
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DatePicker
                id="end-date"
                l
                value={eDateMissedUser}
                onChange={(newValueEndDate) => {
                  setEDateMissedUser(newValueEndDate);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "End date",
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Button
                color="primary"
                variant="contained"
                sx={{ height: "100%" }}
                onClick={getButtonsMissedUser}
              >
                Search
              </Button>
            </Grid>
          </Grid>

          <Paper mt={3}>
            <DialogContentText sx={{ padding: "0 24px" }}>
              Total tours: {totalTourCountUser ? totalTourCountUser : 0}
            </DialogContentText>
            <TableContainer className="tableContainer">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Tour</TableCell>
                    <TableCell>Button</TableCell>
                    <TableCell>Count</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dialogLoader ? (
                    <TableCell
                      colSpan={9}
                      align="center"
                      sx={{
                        height: "50px",
                        paddingTop: "10px",
                        paddingBottom: "5px",
                        "& .MuiCircularProgress-root": {
                          width: "28px !important",
                          height: "28px !important",
                        },
                      }}
                    >
                      <CircularProgress />
                    </TableCell>
                  ) : (
                    <>
                      {buttonMissedUser.map((item, index) => {
                        return (
                          <TableRow hover key={index}>
                            <TableCell>{item.TourName}</TableCell>
                            <TableCell
                              onClick={() => {
                                buttonDetailOpen(
                                  item.TagID,
                                  item.TagDescription
                                );
                              }}
                              sx={{
                                cursor: "pointer",
                                whiteSpace: "nowrap",
                                color: "#0077B5",
                              }}
                            >
                              {item.TagDescription}
                            </TableCell>
                            <TableCell>{item.tourrun_data_count}</TableCell>
                          </TableRow>
                        );
                      })}
                      {buttonMissedUser.length === 0 ? (
                        <TableRow>
                          <TableCell align="center" colSpan={3}>
                            No data for selected date range
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </DialogContent>
        <DialogActions sx={{ padding: "20px 24px" }}>
          <Button
            color="primary"
            variant="outlined"
            onClick={userMissedButtonsClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {buttonDetail && (
        <UsernameDialog2
          buttonDetail={buttonDetail}
          setButtonDetail={setButtonDetail}
          buttonDetailClose={buttonDetailClose}
          tagIdD4={tagIdD4}
          tagNameD4={tagNameD4}
          userIdD3={userIdD3}
          userDialog={userDialog}
          sDateMissedUser={sDateMissedUser}
          eDateMissedUser={eDateMissedUser}
        />
      )}
    </>
  );
};

export default UsernameDialog1;
