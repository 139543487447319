import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { subMonths, format } from "date-fns";
import CustomDateRange from "../../components/form-components/CustomDateRange";
import {
  Checkbox,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  InputAdornment,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  CardContent,
  Card,
  Box,
  Button,
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  DialogTitle,
  DialogActions,
  Dialog,
  CircularProgress,
  Autocomplete,
  Tooltip,
  Pagination,
  Chip,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  Visibility as VisibilityIcon,
  Settings as SettingsIcon,
  ExpandMore as ExpandMoreIcon,
  Map as MapIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { TourPatrolList, DeleteTour, TourPatrolColumns } from "../../api";
import {
  tourPatrolList,
  getTourPatrolChecked,
} from "../../redux/slices/reportSlice";
import { useDispatch, useSelector } from "react-redux";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import SnackToolbar from "../accounts/components/SnackToolbar";
import TourReportResult from "./inner-components/TourReportResult";
import TournameDialog1 from "./inner-components/TournameDialog1";
import UsernameDialog1 from "./inner-components/UsernameDialog1";
import ResultDialog from "./inner-components/ResultDialog";
import TourMap from "./inner-components/TourMap";
/* const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing); */

// Classes Styling
const useStyles = makeStyles((theme) => ({
  searchFilter: {
    position: "relative",
  },
  clearSearch: {
    position: "absolute",
    right: "45px",
  },
  dateRangeDropdown: {
    position: "absolute",
    left: 0,
    top: "100%",
    width: "100%",
    zIndex: 2,
    marginLeft: "0 !important",
    maxHeight: "200px",
    overflowY: "auto",

    "& .MuiBox-root": {
      padding: "15px",
      "& .MuiButton-contained": {
        marginLeft: "15px",
      },
    },
    "& .MuiList-root": {
      padding: 0,
      "& .MuiListItem-root:hover": {
        backgroundColor: "#376fd012",
      },
    },
  },
  DateList: {
    border: "1px solid #0000001c",
    marginTop: "22px",
    padding: 0,
    "& .MuiListItem-root": {
      borderBottom: "1px solid #0000001c",
    },
    "& .MuiListItem-root:last-child": {
      borderBottom: 0,
    },
    "& .MuiListItem-root:hover": {
      backgroundColor: "#376fd012",
    },
  },
  cardHeader: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
// Image Wrapper
const ImageWrapper = styled.div`
  margin-top: 10px;
  height: 100vh;
  width: 100%;
`;
const Image = styled.img`
  width: 100%;
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledForm = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    marginLeft: "75px",
    marginTop: "8px",
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      minWidth: "435px",
      "& form": {
        padding: "15px 20px",
      },
      "& .MuiMenuItem-root": {
        padding: "0",
        display: "block",
        "& .MuiFormControl-root": {
          display: "block",
          width: "100%",
          marginBottom: "20px",
        },
      },
      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "140px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    colArray,
  } = props;
  const [headColumn, setHeadColumn] = useState([]); //My header...
  const checkedValue = useSelector((state) => state.report.tourPatrolChecked);

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  //  Function for preselected options in site.
  const headResult = () => {
    let result = [];
    checkedValue.forEach((obj) => {
      colArray.forEach((result3) => {
        if (result3.key == obj) {
          result.push(result3);
        }
      });
    });
    result.sort((a, b) => {
      return a.sort_id - b.sort_id;
    });
    setHeadColumn(result);
  };

  useEffect(() => {
    headResult();
  }, [checkedValue, colArray]);

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all data" }}
          />
        </TableCell>
        {headColumn.map((headCell) => (
          <TableCell
            key={headCell.sort_id}
            align="left"
            sx={{ whiteSpace: "nowrap" }}
            sortDirection={orderBy === headCell.key ? order : false}
            padding={headCell.key === "TourName" ? "none" : "normal"}
          >
            <TableSortLabel
              active={orderBy === headCell.key}
              direction={orderBy === headCell.key ? order : "asc"}
              onClick={createSortHandler(headCell.key)}
              hideSortIcon={headCell.key === "result" ? true : false}
            >
              {headCell.value}
              {/* {headCell.icon} */}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="left">Action</TableCell>
      </TableRow>
    </TableHead>
  );
};
const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const {
    numSelected,
    totalRecord,
    handleDeleteOpen,
    getTourPatrolList,
    filterRecord,
    dataToFilter,
    setDataToFilter,
    colArray,
    getColumnList,
    getColumnChecked,
    handleFirstPage,
    setCurrentPage,
  } = props;
  const [actions, setActions] = React.useState("");
  const [fadebox, setFadedBox] = React.useState(false);
  const [startDateToFilter, setStartDateToFilter] = useState("");
  const [endDateToFilter, setEndDateToFilter] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [searchTourNameInput, setSearchTourNameInput] = useState("");
  const [searchUserName, setSearchUserName] = useState("");
  const [searchResult, setSearchResult] = useState("");
  const [userSuggestion, setUserSuggestion] = useState([]);
  const [tourSuggestion, setTourSuggestion] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const checkedValue = useSelector((state) => state.report.tourPatrolChecked);

  const dispatch = useDispatch();

  const handleChange = (event) => {
    setActions(event.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // Search Dropdown
  const [anchorForm, setAnchorForm] = React.useState(null);
  const handleClickDropdown = (event) => {
    setAnchorForm(event.currentTarget);
  };

  const handleCloseForm = () => {
    setAnchorForm(null);
  };
  const [value, setValue] = React.useState(null);
  // Select Result
  const [result, setResult] = React.useState("");
  const selectResult = (event) => {
    setResult(event.target.value);
  };

  // Columns Dropdown
  const [anchorColumn, setAnchorColumn] = React.useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };

  const classes = useStyles();
  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };

  const handleSelect = (ranges) => {
    // setFieldValue("dateRange", ranges.selection);
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
  };

  const applySearch = () => {
    const payload = {
      // startTemp: startDateToFilter,
      // endTemp: endDateToFilter,
      start_date: startDateToFilter
        ? moment(startDateToFilter).format("YYYY-MM-DD")
        : "",
      end_date: endDateToFilter
        ? moment(endDateToFilter).format("YYYY-MM-DD")
        : "",
      tour_name: searchTourNameInput,
      user_name: searchInput !== "" ? searchInput : searchUserName,
      result_item: searchResult,
    };
    filterRecord(payload);
  };

  //function for reseting the filter
  const resetFilter = () => {
    setStartDateToFilter("");
    setEndDateToFilter("");
    setSearchTourNameInput("");
    setSearchUserName("");
    setSearchResult("");
    filterRecord({});
  };

  //Handler for checked unchecked
  const handleChecked = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      dispatch(getTourPatrolChecked([...checkedValue, value]));
    } else {
      dispatch(getTourPatrolChecked(checkedValue.filter((e) => e !== value)));
    }
  };

  return (
    <Toolbar>
      <div>
        <Paper mt={3}>
          <FormControl mr={2} sx={{ minWidth: 120 }}>
            <InputLabel id="demo-simple-select-label">Actions</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={actions}
              label="Actions"
              MenuProps={{
                PaperProps: {
                  sx: {
                    "& .MuiMenu-list": {
                      boxShadow:
                        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 10%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
                      margin: "0px 3px 5px 3px",
                      padding: "0",
                    },
                  },
                },
              }}
              onChange={handleChange}
            >
              <MenuItem value={"Delete"} onClick={handleDeleteOpen}>
                Delete
              </MenuItem>
            </Select>
          </FormControl>
        </Paper>
      </div>
      <div className="totalRecord">
        {totalRecord > 0 ? (
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content" }}
          >
            {totalRecord} records found
          </Typography>
        ) : (
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content" }}
          >
            {totalRecord} record found
          </Typography>
        )}
      </div>
      <Spacer />
      <div className="searchTool">
        <Box
          mt={3}
          sx={{
            display: "flex",
            flexWrap: "nowrap",
          }}
        >
          <Item sx={{ flexGrow: 1, width: "100%" }}>
            <TextField
              className={classes.searchFilter}
              id="search"
              type="text"
              placeholder="Search by username, or click down arrow"
              value={searchUserName}
              autoComplete="off"
              inputProps={{}}
              onChange={(e) => {
                setSearchUserName(e.target.value);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  applySearch();
                  setIsFilter(true);
                  setCurrentPage(1);
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {/* {searchInput !== "" ? (
                      <IconButton
                        className={classes.clearSearch}
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setSearchInput("");
                          setDataToFilter(null);
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                    ) : null} */}
                    <IconButton
                      aria-owns={anchorForm ? "search-form" : undefined}
                      aria-haspopup="true"
                      onClick={handleClickDropdown}
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                    <StyledForm
                      id="search-form"
                      anchorEl={anchorForm}
                      open={Boolean(anchorForm)}
                      onClose={handleCloseForm}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <MenuItem sx={{ maxHeight: "380px", overflowY: "auto" }}>
                        <form
                          onKeyDown={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <FormControl mt={3}>
                            <TextField
                              fullWidth
                              id="dateRange"
                              label="Date range"
                              value={
                                startDateToFilter && endDateToFilter
                                  ? moment(startDateToFilter).format(
                                      "MM/DD/YYYY"
                                    ) +
                                    " - " +
                                    moment(endDateToFilter).format("MM/DD/YYYY")
                                  : ""
                              }
                              onClick={() => setFadedBox((prev) => !prev)}
                              // onChange={handleChange}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="Select date range"
                            />
                            {fadebox && (
                              <CustomDateRange
                                handleShow={fadebox}
                                conformation={(status) =>
                                  setFadedBox((prev) => !prev)
                                }
                                setDates={(start, end) => {
                                  setStartDateToFilter(start);
                                  setEndDateToFilter(end);
                                }}
                                selectedStart={startDateToFilter}
                                selectedEnd={endDateToFilter}
                              />
                            )}
                          </FormControl>
                          <FormControl>
                            <Autocomplete
                              freeSolo={true}
                              id="controllable-states-demo"
                              disableClearable
                              // value={searchTourName}
                              // onChange={(event, newValue) => {
                              //   setSearchTourName(newValue);
                              // }}
                              inputValue={searchTourNameInput}
                              onInputChange={(event, newInputValue) => {
                                setSearchTourNameInput(newInputValue);
                                let payload = {
                                  tour_name: newInputValue,
                                  limit: totalRecord,
                                };
                                TourPatrolList(1, { params: payload })
                                  .then((res) => {
                                    if (newInputValue !== "") {
                                      setTourSuggestion(res.data);
                                    } else {
                                      setTourSuggestion([]);
                                    }
                                  })
                                  .catch((err) => {
                                    console.log(err);
                                  });
                              }}
                              options={tourSuggestion.map(
                                (option) => option.tour_name
                              )}
                              // options={tourSuggestion}
                              // sx={{ width: 300 }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Tour name"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  InputProps={{
                                    ...params.InputProps,
                                  }}
                                  placeholder="Perimeter tour"
                                />
                              )}
                            />
                          </FormControl>
                          <FormControl>
                            <Autocomplete
                              freeSolo={true}
                              id="controllable-states-demo"
                              disableClearable
                              // value={searchTourName}
                              // onChange={(event, newValue) => {
                              //   setSearchTourName(newValue);
                              // }}
                              inputValue={searchUserName}
                              onInputChange={(event, newInputValue) => {
                                setSearchUserName(newInputValue);
                                let payload = {
                                  user_name: newInputValue,
                                  limit: totalRecord,
                                };
                                TourPatrolList(1, { params: payload })
                                  .then((res) => {
                                    if (newInputValue !== "") {
                                      setUserSuggestion(res.data);
                                    } else {
                                      setUserSuggestion([]);
                                    }
                                  })
                                  .catch((err) => {
                                    console.log(err);
                                  });
                              }}
                              options={userSuggestion.map(
                                (option) => option.user_name
                              )}
                              // options={tourSuggestion}
                              // sx={{ width: 300 }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Username"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  InputProps={{
                                    ...params.InputProps,
                                  }}
                                  placeholder="John smith"
                                />
                              )}
                            />
                          </FormControl>
                          <FormControl>
                            <InputLabel
                              shrink
                              id="result"
                              sx={{
                                paddingLeft: "6px",
                                paddingRight: "6px",
                                marginLeft: "-4px",
                              }}
                            >
                              Result
                            </InputLabel>
                            <Select
                              labelId="position"
                              id="result"
                              name="result"
                              value={searchResult}
                              onChange={(e) => {
                                setSearchResult(e.target.value);
                              }}
                              fullWidth
                              displayEmpty
                            >
                              <MenuItem value="">Select result</MenuItem>
                              <MenuItem value="80-100">
                                Greater than 80%
                              </MenuItem>
                              <MenuItem value="60-80">
                                Between 60% - 80%
                              </MenuItem>
                              <MenuItem value="60">Less than 60%</MenuItem>
                            </Select>
                          </FormControl>
                          <Box className={classes.cardHeader}>
                            <Button
                              color="primary"
                              variant="contained"
                              ml={3}
                              onClick={(e) => {
                                applySearch({});
                                setIsFilter(true);
                                setCurrentPage(1);
                                handleCloseForm();
                              }}
                            >
                              Filter
                            </Button>
                          </Box>
                        </form>
                      </MenuItem>
                    </StyledForm>
                  </InputAdornment>
                ),
              }}
            />
          </Item>
          <Item>
            {isFilter ? (
              <Button
                color="primary"
                variant="contained"
                onClick={(e) => {
                  resetFilter();
                  setDataToFilter(null);
                  setIsFilter(false);
                  setSearchInput("");
                }}
                sx={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  height: "100%",
                  marginLeft: "-3px",
                }}
              >
                Clear Filter
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                sx={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  height: "100%",
                  marginLeft: "-3px",
                }}
                onClick={(e) => {
                  if (searchUserName !== "") {
                    applySearch({});
                    setIsFilter(true);
                    handleFirstPage();
                    setCurrentPage(1);
                  }
                }}
              >
                Search
              </Button>
            )}
          </Item>
        </Box>
      </div>
      <Spacer />
      <div>
        <Paper mt={3}>
          <IconButton
            aria-owns={anchorEl ? "icon-menu" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            variant="contained"
            color="secondary"
          >
            <FilterListIcon />
          </IconButton>
          <StyledFilterMenu
            id="icon-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem
              aria-owns={anchorColumn ? "columns-dropdown" : undefined}
              aria-haspopup="true"
              onClick={handleClickColumn}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Columns" pl={0} />
            </MenuItem>
          </StyledFilterMenu>
          <StyledColumnDropdown
            id="columns-dropdown"
            anchorEl={anchorColumn}
            open={Boolean(anchorColumn)}
            onClose={handleCloseColumn}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem>
              <Card>
                <CardContent>
                  <Typography variant="h6" component="h2">
                    {checkedValue.length} out of {colArray.length} visible
                  </Typography>
                  <Box>
                    <FormGroup>
                      {colArray.map((col) => {
                        return (
                          <FormControlLabel
                            key={col.sort_id}
                            control={
                              <Checkbox
                                disableRipple
                                checked={checkedValue.includes(col.key)}
                                onChange={(e) => handleChecked(e)}
                                value={col.key}
                              />
                            }
                            label={col.value}
                          />
                        );
                      })}
                    </FormGroup>
                  </Box>
                  <Toolbar>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        getColumnList();
                        getColumnChecked();
                      }}
                    >
                      Reset
                    </Button>
                    <Spacer />
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleCloseColumn}
                    >
                      Cancel
                    </Button>
                  </Toolbar>
                </CardContent>
              </Card>
            </MenuItem>
          </StyledColumnDropdown>
        </Paper>
      </div>
    </Toolbar>
  );
};
function EnhancedTable(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(true);
  //State for listing
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [tourId, setTourId] = useState(null);
  const [start_time, setStart_time] = useState(null);
  const [finish_time, setFinish_time] = useState(null);
  const [tourLId, setTourLId] = useState(null);
  const [dataToFilter, setDataToFilter] = useState(null);
  const [openDelete, setOpenDelete] = useState(false); //state for delete dialog prompt
  const [recordLength, setrecordLength] = useState(null);
  const [snackToolbarOpen, setsnackToolbarOpen] = useState(false);
  const [loading, setLoading] = React.useState(false); //State for loader
  const dispatch = useDispatch();
  const tourList = useSelector((state) => state.report.tourPatrolData);
  //state related to dialogs in listing page
  const [tourIdD1, setTourIdD1] = useState(""); //state for  getting the tourId in tourname D1

  const [userIdD3, setUserIdD3] = useState(""); //state for  getting the userId in username D3
  const [tourIdD5, setTourIdD5] = useState(""); //state for getting the tourId in result D5
  const [tourDialog, setTourDialog] = useState(""); //State for getting tour name for dialog
  const [userDialog, setUserDialog] = useState(""); //State for getting user name for dialog
  const [timeStart, setTimeStart] = useState("");
  const [timeFinish, setTimeFinish] = useState("");
  const [colArray, setColArray] = useState([]); //State for list of colmn list
  const checkedValue = useSelector((state) => state.report.tourPatrolChecked);
  const [tourNameMap, setTourNameMap] = useState("");
  const [userNameMap, setUserNameMap] = useState("");
  const [activityTimeMap, setActivityTimeMap] = useState("");
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = tourList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    setrecordLength(newSelected.length);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    // setPage(newPage);
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setSelected([]);
    // setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleActionClick = (
    event,
    id,
    tourid,
    startTime,
    finishTime,
    tName,
    uName
  ) => {
    setAnchorEl(event.currentTarget);
    setTourLId(id);
    setTourId(tourid);
    setTourNameMap(tName);
    setUserNameMap(uName);
    setActivityTimeMap(startTime);
    setStart_time(moment(startTime).format("YYYY-MM-DD, HH:mm:ss"));
    setFinish_time(moment(finishTime).format("YYYY-MM-DD, HH:mm:ss"));
    setrecordLength(1);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();

  // state and handler for get button missed: (D1) Tour Name outer dialog Modal
  const [tourmissedbuttons, setTourMissedButtons] = React.useState(false);
  const tourMissedButtonsOpen = (tourname, tourid) => {
    setTourMissedButtons(true);
    setTourDialog(tourname);
    setTourIdD1(tourid);
  };

  const tourMissedButtonsClose = () => {
    setTourMissedButtons(false);
  };

  // get-button-missed-User: (D3) state for outer dialog in username column
  const [userMissedbuttons, setUserMissedButtons] = React.useState(false);
  const userMissedButtonsOpen = (username, userid) => {
    setUserMissedButtons(true);
    setUserDialog(username);
    setUserIdD3(userid);
  };

  const userMissedButtonsClose = () => {
    setUserMissedButtons(false);
  };

  //get-tooltip-list: (D5) for dialog of Result Button column
  const [resultmissedbtns, setResultMissedBtns] = React.useState(false);
  const [userIdMap, setUserIdMap] = useState("");
  const [startTimeMap, setStartTimeMap] = useState(null);
  const rMissedButtonsOpen = (username, tourname, tourid, rStart, rFinish) => {
    setResultMissedBtns(true);
    setTourDialog(tourname);
    setUserDialog(username);
    setTourIdD5(tourid);
    setTimeStart(rStart);
    setTimeFinish(rFinish);
  };

  const rMissedButtonsClose = () => {
    setResultMissedBtns(false);
  };
  // View Map Modal
  const [viewmap, setViewMap] = React.useState(false);
  const viewMapOpen = (userId, startTime) => {
    setUserIdMap(userId);
    setStartTimeMap(startTime);
    setViewMap(true);
  };
  const viewMapClose = () => {
    setViewMap(false);
  };

  // Methods for calling the APIs
  const getTourPatrolList = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
      sorting: {
        order: order,
        by: orderBy,
      },
      ...dataToFilter,
    }; //this will be used for sending filtered data
    TourPatrolList(currentPage, payload)
      .then((res) => {
        if (res.statusCode == 200) {
          dispatch(tourPatrolList(res.data));
          setTotalRecord(res.meta.total);
          setCurrentPage(res.meta.current_page);
        } else {
          dispatch(tourPatrolList(res.data));
          setTotalRecord(0);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setTotalRecord(0);
        dispatch(tourPatrolList([]));
      });
  };

  const filterRecord = (data) => {
    setDataToFilter(data);
  };

  //handler for opening the delete dialog box
  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
    setAnchorEl(null);
    setTourLId(null);
  };

  //handlers for snackbar...
  const handleSnackToolbarClick = () => {
    setsnackToolbarOpen(true);
  };
  const handleSnackToolbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setsnackToolbarOpen(false);
  };

  //handler for deleting the tour
  const deleteTourPatrol = () => {
    let delItems = selected.length > 0 ? selected : tourLId;
    DeleteTour(delItems).then((res) => {
      if (res.statusCode == 200) {
        getTourPatrolList();
        handleDeleteClose();
        handleSnackToolbarClick();
        setSelected([]);
      }
    });
  };

  //API call for showing list of columns checkbox items
  const getColumnList = () => {
    TourPatrolColumns()
      .then((res) => {
        setColArray(res.data.all_fields);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getColumnChecked = () => {
    TourPatrolColumns()
      .then((res) => {
        dispatch(getTourPatrolChecked(res.data.default_fields));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //Side-effects
  useEffect(() => {
    getTourPatrolList();
    getColumnList();
  }, [rowsPerPage, currentPage, order, orderBy, dataToFilter]);

  //sideeffect for dispatching actions to redux store
  useEffect(() => {
    if (checkedValue.length == 0) {
      getColumnChecked();
    } else {
      console.log("Redux store array is empty");
    }
  }, []);

  const AnyReactComponent = ({ text }) => <div>{text}</div>;

  const handleFirstPage = () => {
    setCurrentPage(1);
    setSelected([]);
  };

  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
    setSelected([]);
  };

  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
    setSelected([]);
  };

  const points = [
    { id: 1, title: "Round Pond", lat: 51.506, lng: -0.184 },
    { id: 2, title: "The Long Water", lat: 51.508, lng: -0.175 },
    { id: 3, title: "The Serpentine", lat: 51.505, lng: -0.164 },
  ];
  return (
    <div>
      <Paper mt={3}>
        <>
          <SnackToolbar
            handleSnackToolbarClose={handleSnackToolbarClose}
            snackToolbarOpen={snackToolbarOpen}
            recordLength={recordLength}
          />
          {selected.length || tourLId > 0 ? (
            <Dialog
              open={openDelete}
              onClose={handleDeleteClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Are you sure you want to delete tour?"}
              </DialogTitle>
              <DialogActions>
                <Button
                  onClick={handleDeleteClose}
                  color="primary"
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  onClick={(handleDeleteClose, deleteTourPatrol)}
                  autoFocus
                  color="primary"
                  sx={{ ml: "10px" }}
                  variant="contained"
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          ) : (
            <Dialog
              open={openDelete}
              onClose={handleDeleteClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"You haven't selected any item !"}
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleDeleteClose}>OK</Button>
              </DialogActions>
            </Dialog>
          )}
        </>

        <EnhancedTableToolbar
          numSelected={selected.length}
          totalRecord={totalRecord}
          handleDeleteOpen={handleDeleteOpen}
          getTourPatrolList={() => getTourPatrolList()}
          filterRecord={(data) => filterRecord(data)}
          dataToFilter={dataToFilter}
          setDataToFilter={setDataToFilter}
          colArray={colArray}
          getColumnList={getColumnList}
          getColumnChecked={getColumnChecked}
          handleFirstPage={handleFirstPage}
          setCurrentPage={setCurrentPage}
        />
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress />
                  </TableCell>
                  {/* collapse null column to resolve console errors */}
                  {/* <TableCell colSpan={8} /> */}
                </TableRow>
              </TableBody>
            ) : (
              <>
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={tourList.length}
                  colArray={colArray}
                />
                <TableBody>
                  {
                    // stableSort(tourList, getComparator(order, orderBy))
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    tourList.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                              onClick={(event) => handleClick(event, row.id)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              selected={isItemSelected}
                            />
                          </TableCell>
                          {checkedValue.includes("TourName") && (
                            <Tooltip
                              title="Click to show top missed by tour"
                              followCursor
                            >
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                                onClick={() => {
                                  tourMissedButtonsOpen(
                                    row.tour_name,
                                    row.TourID
                                  );
                                }}
                                sx={{
                                  cursor: "pointer",
                                  lg: 'whiteSpace: "nowrap"',
                                  color: "#0077B5",
                                }}
                              >
                                {row.tour_name}
                              </TableCell>
                            </Tooltip>
                          )}
                          {checkedValue.includes("UserName") && (
                            <Tooltip
                              title="Click to show top missed by user"
                              followCursor
                            >
                              <TableCell
                                align="left"
                                sx={{
                                  cursor: "pointer",
                                  whiteSpace: "nowrap",
                                  color: "#0077B5",
                                }}
                                onClick={() => {
                                  userMissedButtonsOpen(
                                    row.user_name,
                                    row.UserID
                                  );
                                }}
                              >
                                {row.user_name}
                              </TableCell>
                            </Tooltip>
                          )}
                          {checkedValue.includes("StartTime") && (
                            <TableCell
                              align="left"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              <Box>
                                <Typography variant="span" component="p">
                                  {row.start_time}
                                </Typography>
                              </Box>
                            </TableCell>
                          )}

                          {checkedValue.includes("result") && (
                            <TableCell
                              align="left"
                              sx={{
                                whiteSpace: "nowrap",
                                "& .MuiChip-label": {
                                  whiteSpace: "nowrap",
                                },
                              }}
                            >
                              <Tooltip
                                title="Click to show top missed for this tour"
                                followCursor
                              >
                                <Chip
                                  onClick={() => {
                                    rMissedButtonsOpen(
                                      row.user_name,
                                      row.tour_name,
                                      row.TourID,
                                      row.start_time,
                                      row.finish_time
                                    );
                                  }}
                                  label={row.result}
                                  sx={{
                                    cursor: "pointer",
                                    backgroundColor:
                                      row.class == "greenclass"
                                        ? "#2E7823"
                                        : row.class == "yellowclass"
                                        ? "#CCC820"
                                        : "#DA3E16",
                                    color: "#000",
                                  }}
                                />
                              </Tooltip>
                              {/* <TourReportResult
                                scanned={row.scanned}
                                total={row.total}
                                result={row.result}
                                rMissedButtonsOpen={rMissedButtonsOpen}
                                row={row}
                              /> */}
                            </TableCell>
                          )}
                          {checkedValue.includes("FinishTime") && (
                            <TableCell align="left">
                              <Box>
                                <Typography variant="span" component="p">
                                  {row.finish_time}
                                </Typography>
                              </Box>
                            </TableCell>
                          )}
                          {checkedValue.includes("total_time") && (
                            <TableCell
                              align="left"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              <Box>
                                <Typography variant="span" component="p">
                                  {row.elapsed}
                                </Typography>
                              </Box>
                            </TableCell>
                          )}
                          {checkedValue.includes("comments") && (
                            <TableCell
                              align="left"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              <Box>
                                <Typography variant="span" component="p">
                                  {row.comments}
                                </Typography>
                              </Box>
                            </TableCell>
                          )}
                          {checkedValue.includes("report_status") && (
                            <TableCell
                              align="left"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              <Box>
                                <Typography variant="span" component="p">
                                  {row.report_status}
                                </Typography>
                              </Box>
                            </TableCell>
                          )}
                          {checkedValue.includes("nist_time") && (
                            <TableCell
                              align="left"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              <Box>
                                <Typography variant="span" component="p">
                                  {row.nist_time}
                                </Typography>
                              </Box>
                            </TableCell>
                          )}
                          {checkedValue.includes("device_time") && (
                            <TableCell
                              align="left"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              <Box>
                                <Typography variant="span" component="p">
                                  {row.device_time}
                                </Typography>
                              </Box>
                            </TableCell>
                          )}
                          <TableCell align="left">
                            <IconButton
                              aria-label="Settings"
                              size="large"
                              aria-owns={anchorEl ? "icon-menu" : undefined}
                              aria-haspopup="true"
                              onClick={(e) =>
                                handleActionClick(
                                  e,
                                  row.id,
                                  row.TourID,
                                  row.start_time,
                                  row.finish_time,
                                  row.tour_name,
                                  row.user_name
                                )
                              }
                              style={{ color: "#1374C5" }}
                            >
                              <SettingsIcon />
                            </IconButton>
                            <StyledMenu
                              id="icon-menu"
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={handleActionClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <MenuItem
                                aria-label="tour-report"
                                component={NavLink}
                                // to="/reports/tour-report"
                                to={`/reports/tour-report/${tourId}/${start_time}/${finish_time}`}
                              >
                                <ListItemIcon>
                                  <VisibilityIcon />
                                </ListItemIcon>
                                <ListItemText primary="View Report" pl={1} />
                              </MenuItem>
                              <MenuItem
                                onClick={() =>
                                  viewMapOpen(row.UserID, row.start_time)
                                }
                              >
                                <ListItemIcon>
                                  <MapIcon />
                                </ListItemIcon>
                                <ListItemText primary="View Map" pl={1} />
                              </MenuItem>
                              <MenuItem
                                onClick={(handleActionClose, handleDeleteOpen)}
                              >
                                <ListItemIcon>
                                  <DeleteIcon />
                                </ListItemIcon>
                                <ListItemText primary="Delete" pl={1} />
                              </MenuItem>
                            </StyledMenu>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  }
                  {totalRecord === 0 && (
                    <TableRow style={{ height: (dense ? 6 : 0) * totalRecord }}>
                      <TableCell colSpan={11} align="center">
                        No records found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          }}
        >
          <Pagination
            count={totalRecord !== 0 ? Math.ceil(totalRecord / rowsPerPage) : 1}
            page={currentPage}
            onChange={handleChangePagination}
            color="primary"
            boundaryCount={3}
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <Typography sx={{ marginRight: "10px" }}>
                Rows per page :
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                // label="Age"
                onChange={handleChangeLimit}
              >
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </Box>
          </FormControl>
        </Box>
        {/* <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={totalRecord}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      {/* get button missed: dialog for Tour name outer dialog*/}
      {tourmissedbuttons && (
        <TournameDialog1
          tourmissedbuttons={tourmissedbuttons}
          setTourMissedButtons={setTourMissedButtons}
          tourMissedButtonsClose={tourMissedButtonsClose}
          tourDialog={tourDialog}
          tourIdD1={tourIdD1}
        />
      )}
      {/* get button missed user: outer dialog username */}
      {userMissedbuttons && (
        <UsernameDialog1
          userMissedbuttons={userMissedbuttons}
          setUserMissedButtons={setUserMissedButtons}
          userMissedButtonsClose={userMissedButtonsClose}
          userDialog={userDialog}
          userIdD3={userIdD3}
        />
      )}
      {/* get tooltip data: Dialog for Result Chip column*/}
      {resultmissedbtns && (
        <ResultDialog
          resultmissedbtns={resultmissedbtns}
          setResultMissedBtns={setResultMissedBtns}
          rMissedButtonsClose={rMissedButtonsClose}
          userDialog={userDialog}
          tourDialog={tourDialog}
          tourIdD5={tourIdD5}
          timeStart={timeStart}
          timeFinish={timeFinish}
        />
      )}
      {/* View Map */}
      {viewmap && (
        <TourMap
          viewmap={viewmap}
          setViewMap={setViewMap}
          viewMapClose={viewMapClose}
          tourNameMap={tourNameMap}
          tourLIdMap={tourLId}
          tourIdMap={tourId}
          userNameMap={userNameMap}
          startTimeMap={startTimeMap}
          userIdMap={userIdMap}
          activityTimeMap={activityTimeMap}
        />
      )}
    </div>
  );
}

function TourPatrol() {
  return (
    <React.Fragment>
      <Helmet title="Tour Patrol" />
      <Typography variant="h3" gutterBottom display="inline">
        Tour Reports
      </Typography>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default TourPatrol;
