import * as Yup from "yup";
import moment from "moment";
import { Formik } from "formik";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import styled from "styled-components/macro";
import { handleApiResonseErrors } from "../../../helper/helper";
import CustomSelect from "../../../components/form-components/CustomSelect";
import CustomTextField from "../../../components/form-components/CustomTextField";
import { SaveSpecialVisitor, ViewSpecialVisitor } from "../../../api";
import { DateTimePicker } from "@mui/lab";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Typography,
  FormControl as MuiFormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Grid,
  FormLabel,
  FormHelperText,
  CircularProgress,
  Paper,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { DatePicker } from "@mui/lab";
import { spacing } from "@mui/system";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;

// Classes Styling
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));

const AddSpecialVisitor = ({
  addSpecialVisitorDialog,
  addSpecialVisitorDialogClose,
  showAlertModal,
  getList,
  id,
}) => {
  const [loading, setLoading] = useState(false);
  const [recordData, setRecordData] = useState(null);
  const classes = useStyles();
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter name"),
    room_area: Yup.string().required("Please enter room/area"),
    visitor_type: Yup.string().required("Please select type"),
    caretaker: Yup.string().required("A radio option is required"),
    phone: Yup.string()
      .max(15, "Phone must be at most 15 characters")
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Please enter a valid number."),
    arrival_date: Yup.date().nullable().required("Please select the date"),
    departure_date: Yup.date()
      .nullable()
      .min(
        Yup.ref("arrival_date"),
        "Departure date has to be more than arrival date"
      )
      .required("Please select the date"),
  });

  let initialValues = {
    name: recordData ? (recordData.name ? recordData.name : "") : "",
    arrival_date: recordData
      ? recordData.arrival_date
        ? recordData.arrival_date
        : null
      : null,
    departure_date: recordData
      ? recordData.departure_date
        ? recordData.departure_date
        : null
      : null,
    room_area: recordData
      ? recordData.room_area
        ? recordData.room_area
        : ""
      : "",
    visitor_type: recordData
      ? recordData.visitor_type
        ? recordData.visitor_type
        : ""
      : "",
    caretaker: recordData
      ? recordData.caretaker
        ? recordData.caretaker
        : "N"
      : "N",
    phone: recordData ? (recordData.phone ? recordData.phone : "") : "",
    special_instructions: recordData
      ? recordData.special_instructions
        ? recordData.special_instructions
        : ""
      : "",
  };

  const type = [
    { key: "Impaired Hearing", value: "Impaired Hearing" },
    { key: "Impaired Mobility", value: "Impaired Mobility" },
    { key: "Impaired Vision", value: "vision" },
    { key: "VIP", value: "VIP" },
    { key: "Other", value: "Other" },
  ];

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoading(true);
      let arrival_date = values.arrival_date ? moment(values.arrival_date) : "";
      arrival_date = arrival_date ? arrival_date.format("YYYY-MM-DD") : "";

      let departure_date = values.departure_date
        ? moment(values.departure_date)
        : "";
      departure_date = departure_date
        ? departure_date.format("YYYY-MM-DD")
        : "";

      let formData = {
        id: id,
        name: values.name,
        arrival_date: arrival_date,
        departure_date: departure_date,
        room_area: values.room_area,
        visitor_type: values.visitor_type,
        caretaker: values.caretaker,
        phone: values.phone,
        special_instructions: values.special_instructions,
      };
      SaveSpecialVisitor(formData)
        .then((res) => {
          if (res.statusCode === 200) {
            addSpecialVisitorDialogClose();
            getList();
            showAlertModal("success", "Record has been submitted successfully");
          } else {
            showAlertModal(
              "success",
              "Sorry! something wrong please try again"
            );
          }
          setLoading(false);
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            showAlertModal("error", error);
          }
        });
    } catch (error) {
      showAlertModal("error", "Sorry! something wrong please try again");
    }
  };

  useEffect(() => {
    if (id) {
      GetRecord();
    }
  }, []);

  const GetRecord = () => {
    setLoading(true);
    ViewSpecialVisitor(id)
      .then((res) => {
        if (res.statusCode === 200) {
          setRecordData(res.data);
        } else {
          showAlertModal("success", "Sorry! something wrong please try again");
        }
        setLoading(false);
      })
      .catch((err) => {
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        if (error) {
          showAlertModal("error", error);
        }
        setLoading(false);
      });
  };

  return (
    <>
      <Dialog
        aria-labelledby="special-visitors-title"
        aria-describedby="special-visitors-desc"
        open={addSpecialVisitorDialog}
        onClose={addSpecialVisitorDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "900px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="special-visitors-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Special Visitor
          </Typography>
          <DialogActions>
            <IconButton
              onClick={addSpecialVisitorDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({ errors, handleSubmit, values, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={6}>
                      <CustomTextField
                        name="name"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Name*"
                        autoComplete="off"
                        placeholder="Name"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={6}>
                      <DateTimePicker
                        id="start-date"
                        label="Arrival Date*"
                        value={values.arrival_date}
                        onChange={(newValue) => {
                          setFieldValue("arrival_date", newValue);
                        }}
                        inputFormat="MM/dd/yyyy HH:mm"
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            name="arrival_date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "mm/dd/yyyy H:i:s",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomSelect
                        name="visitor_type"
                        fieldLabel="Type*"
                        options={type}
                        displayEmpty
                      />
                      <FormHelperText error>
                        {errors.visitor_type}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <DateTimePicker
                        id="start-date"
                        label="Departure Date*"
                        value={values.departure_date}
                        onChange={(newValue) => {
                          setFieldValue("departure_date", newValue);
                        }}
                        inputFormat="MM/dd/yyyy HH:mm"
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            name="departure_date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "mm/dd/yyyy H:i:s",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="room_area"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Room/Area*"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <FormLabel>Has caretaker?*</FormLabel>
                      <RadioGroup
                        aria-labelledby="has-caretaker"
                        name="caretaker"
                        value={values.caretaker}
                        onChange={(e) =>
                          setFieldValue("caretaker", e.currentTarget.value)
                        }
                        row
                      >
                        <FormControlLabel
                          control={<Radio />}
                          name="caretaker"
                          label="Yes"
                          value="Y"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          name="caretaker"
                          label="No"
                          value="N"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="phone"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Phone*"
                        autoComplete="off"
                        placeholder="xxx-xxx-xxxx"
                        inputProps={{ maxLength: 15 }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="special_instructions"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Special instructions"
                        autoComplete="off"
                        placeholder="Add information useful in an evacuation such as special needs"
                        rows={5}
                        multiline={true}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={addSpecialVisitorDialogClose}
                    sx={{ marginRight: "5px" }}
                  >
                    Cancel
                  </Button>
                  <Button color="primary" type="submit" variant="contained">
                    Save
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
};

export default AddSpecialVisitor;
