import React from "react";
import styled from "styled-components/macro";
import CustomTextField from "../../form-components/CustomTextField";
import { Formik } from "formik";
import moment from "moment";
import { handleApiResonseErrors } from "../../../helper/helper";
import { UpdateDispose } from "../../../api";
import * as Yup from "yup";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
  FormControl as MuiFormControl,
  Typography,
  Grid,
  FormHelperText,
  FormControlLabel,
  IconButton,
  RadioGroup,
  Radio,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const Disposition = ({
  show,
  handleModal,
  fromData,
  setLoading,
  alertTost,
  GetSingleRecord,
  setPreLoader,
}) => {
  const validationSchema = Yup.object().shape({
    disposed_type: Yup.string().required(
      "Please choose how the item was disposed of"
    ),
  });

  let initialValues = {
    disposed_value: fromData
      ? fromData.disposed_value
        ? fromData.disposed_value
        : ""
      : "",
    disposed_type: fromData
      ? fromData.disposed_type
        ? fromData.disposed_type
        : ""
      : "",
  };

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoading(true);
      let formData = {
        ids: [fromData.id],
        disposed_value: values.disposed_value,
        disposed_type: values.disposed_type,
      };

      UpdateDispose(formData)
        .then((res) => {
          if (res.statusCode === 200) {
            handleModal(false);
            setPreLoader(true);
            GetSingleRecord();
            alertTost("Record has been updated successfully", "success");
          } else {
            setLoading(false);
            alertTost("Sorry! something wrong please try again", "error");
          }
        })
        .catch((err) => {
          setLoading(false);
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            alertTost(error, "error");
          }
        });
    } catch (error) {
      setLoading(false);
      alertTost("Sorry! something wrong please try again", "error");
    }
  };

  return (
    <>
      <Dialog
        open={show}
        aria-labelledby="lockitem-title"
        aria-describedby="lockitem-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "800px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4" component="div">
            Disposition
          </Typography>
          <DialogActions onClick={() => handleModal(false)}>
            <IconButton sx={{ marginRight: "-10px" }}>
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="lockitem-description">
            Please indicate how the item(s) are to be disposed.
          </DialogContentText>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ errors, handleSubmit, setFieldValue, values }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="disposition"
                        name="disposed_type"
                        value={values.disposed_type}
                        row
                        onChange={(e) =>
                          setFieldValue("disposed_type", e.currentTarget.value)
                        }
                      >
                        <FormControlLabel
                          control={<Radio />}
                          label="Item given to the finder"
                          value="Item given to the finder"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="Item donated"
                          value="Item donated"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="Item thrown away"
                          value="Item thrown away"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="Item destroyed"
                          value="Item destroyed"
                        />
                      </RadioGroup>
                      <FormHelperText>{errors.disposed_type}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <CustomTextField
                        name="disposed_value"
                        fullWidth
                        label="Disposal notes"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                    {fromData && fromData.disposed_user && (
                      <React.Fragment>
                        <FormHelperText>
                          Disposed on :{" "}
                          {moment(fromData.disposed_date).format("YYYY-MM-DD")}
                        </FormHelperText>
                        <FormHelperText>
                          Disposed by : {fromData.disposed_user.UserName}
                        </FormHelperText>
                      </React.Fragment>
                    )}
                  </Grid>
                </Grid>

                <DialogActions>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => handleModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    cariant="contained"
                    sx={{ marginLeft: "10px" }}
                    type="submit"
                  >
                    Save Changes
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Disposition;
