import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import AddVehicleInspection from "./inner-component/AddVehicleInspection";
import AddVehiclesInventory from "./inner-component/AddVehiclesInventory";
import { CircularProgress } from "@mui/material";

import {
  AddVehicleInventory,
  AddVehicleViolation,
  VehicleInventoryList,
  VehicleInspectionList,
  DeleteInventory,
  DeleteInspection,
} from "../../api";

import {
  Grid,
  Typography,
  Paper as MuiPaper,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Select,
  MenuItem,
  Menu,
  Box,
  IconButton,
  ListItemIcon,
  Button,
  ListItemText as MuiListItemText,
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Checkbox,
  TableContainer,
  Tabs,
  Tab,
} from "@mui/material";
import {
  FilterList as FilterListIcon,
  AddBox as AddBoxIcon,
  Settings as SettingsIcon,
  Create as CreateIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { display } from "@mui/system";
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    maxWidth: "120px",
    width: "100%",
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      "& .MuiMenuItem-root": {
        padding: "10px",
        "& .MuiList-root": {
          paddingTop: "0px",
          marginTop: "15px",
          paddingBottom: "0px",
          "& .MuiListItem-root": {
            padding: "0px",
            background: "#7aa8ff0d",
            "& .MuiListItemButton-root": {
              padding: "3px 5px",
            },
          },
        },
      },
      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));
const Paper = styled(MuiPaper)``;
const FormControlSpacing = styled(MuiFormControl)``;
const ListItemText = styled(MuiListItemText)``;
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const InputLabel = styled(MuiInputLabel)`
  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
  padding-left: 6px;
  padding-right: 6px;
  margin-left: -4px;
`;

const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);

function Item(props) {
  const { sx, ...other } = props;
  return <Box sx={{ ...sx }} {...other} />;
}

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const VehicleInspection = () => {
  const [loadingVehicleInventory, setLoadingVehicleInventory] = useState(true);
  const [loadingVehicleInspection, setLoadingVehicleInspection] =
    useState(true);

  const [vehicleInventoryList, setVehicleInventoryList] = useState([]);
  const [vehicleInspectionList, setVehicleInspectionList] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [actions, setActions] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [search, setSearch] = useState("");
  const [anchor, setAnchor] = useState(null);
  const [openDialog, setOpenDialog] = useState(null);
  const [openVehicleInspection, setOpenVehicleInspection] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // const handleMenuClose = () => {
  //   setAnchorEl(null);
  // };

  const vehicleInventory = () => {
    setLoadingVehicleInventory(true); // Start loading
    VehicleInventoryList()
      .then((response) => {
        if (response.statusCode === 200) {
          setVehicleInventoryList(response.data); // Assuming `data` contains the list
          setTotalRecord(response.data.length);
        } else {
          console.log("Failed to fetch vehicle data:", response.message);
        }
      })
      .catch((error) => {
        console.log(
          "Error fetching vehicle data:",
          error.response ? error.response.data : error.message
        );
      })
      .finally(() => {
        setLoadingVehicleInventory(false); // Stop loading
      });
  };
  const vehicleInspection = () => {
    setLoadingVehicleInspection(true); // Start loading
    VehicleInspectionList()
      .then((response) => {
        if (response.statusCode === 200) {
          setVehicleInspectionList(response.data); // Assuming `data` contains the list
          setTotalRecord(response.data.length);
        } else {
          console.log("Failed to fetch vehicle data:", response.message);
        }
      })
      .catch((error) => {
        console.log(
          "Error fetching vehicle data:",
          error.response ? error.response.data : error.message
        );
      })
      .finally(() => {
        setLoadingVehicleInspection(false); // Stop loading
      });
  };
  useEffect(() => {
    vehicleInventory();
    vehicleInspection();
  }, []);

  const [vehicleObject, setVehicleObject] = useState(null);
  const [inspectionObject, setInspectionObject] = useState(null);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenInspection = () => {
    setOpenVehicleInspection(true);
  };
  const handleCloseInspection = () => {
    setOpenVehicleInspection(false);
  };

  const handleChange = (event) => {
    setActions(event.target.value);
  };

  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  const handleFilterClick = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchor(null);
  };

  const [value, setValue] = useState(0);

  const handleVehicleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [checkedItems, setCheckedItems] = useState([]);
  const [checkedItemsInventory, setCheckedItemsInventory] = useState([]);

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const handleActionClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleActionClose = () => {
    setMenuAnchorEl(null);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newCheckedItems = vehicleInspectionList.map((n) => n.id);
      setCheckedItems(newCheckedItems);
      return;
    }
    setCheckedItems([]);
  };
  const handleSelectInventory = (event) => {
    if (event.target.checked) {
      const newCheckedItems = vehicleInventoryList.map((n) => n.id);
      setCheckedItemsInventory(newCheckedItems);
      return;
    }
    setCheckedItemsInventory([]);
  };
  const handleInventoryClick = (event, id) => {
    const selectedIndex = checkedItemsInventory.indexOf(id);
    let newCheckedItems = [];

    if (selectedIndex === -1) {
      newCheckedItems = newCheckedItems.concat(checkedItemsInventory, id);
    } else if (selectedIndex === 0) {
      newCheckedItems = newCheckedItems.concat(checkedItemsInventory.slice(1));
    } else if (selectedIndex === checkedItemsInventory.length - 1) {
      newCheckedItems = newCheckedItems.concat(
        checkedItemsInventory.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newCheckedItems = newCheckedItems.concat(
        checkedItemsInventory.slice(0, selectedIndex),
        checkedItemsInventory.slice(selectedIndex + 1)
      );
    }

    setCheckedItemsInventory(newCheckedItems);
  };

  const handleClick = (event, id) => {
    const selectedIndex = checkedItems.indexOf(id);
    let newCheckedItems = [];

    if (selectedIndex === -1) {
      newCheckedItems = newCheckedItems.concat(checkedItems, id);
    } else if (selectedIndex === 0) {
      newCheckedItems = newCheckedItems.concat(checkedItems.slice(1));
    } else if (selectedIndex === checkedItems.length - 1) {
      newCheckedItems = newCheckedItems.concat(checkedItems.slice(0, -1));
    } else if (selectedIndex > 0) {
      newCheckedItems = newCheckedItems.concat(
        checkedItems.slice(0, selectedIndex),
        checkedItems.slice(selectedIndex + 1)
      );
    }

    setCheckedItems(newCheckedItems);
  };

  const isSelected = (id) => checkedItems.indexOf(id) !== -1;
  const isSelectedInventory = (id) => checkedItemsInventory.indexOf(id) !== -1;
  // delete Inventory

  const handleDeleteClick = (id) => {
    const payload = { id }; // Create the payload with the id
    console.log("Deleting record with Payload:", payload);

    DeleteInventory(payload) // Pass the payload directly to the API function
      .then((response) => {
        console.log(response.message); // Log the success message
        // After successful deletion, refresh the inventory list
        vehicleInventory();
        // window.location.reload();
      })
      .catch((error) => {
        console.error("Error deleting inventory:", error.message);
      });
  };
  // delete Inspection

  const handleDeleteInspection = (id) => {
    const payload = { id }; // Create the payload with the id
    console.log("Deleting record with Payload:", payload);

    DeleteInspection(payload) // Pass the payload directly to the API function
      .then((response) => {
        console.log(response.message); // Log the success message
        // After successful deletion, refresh the inventory list
        vehicleInspection();
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error deleting inventory:", error.message);
      });
  };

  const [selectedVehicleInventory, setSelectedVehicleInventory] =
    useState(null); // For Vehicle Inventory Edit/Delete
  const [selectedVehicleInspection, setSelectedVehicleInspection] =
    useState(null); // For Vehicle Inspection Edit/Delete

  const [anchorElInventory, setAnchorElInventory] = useState(null); // Anchor state for Vehicle Inventory table
  const [anchorElInspection, setAnchorElInspection] = useState(null); // Anchor state for Vehicle Inspection table

  // Handle settings for Vehicle Inventory
  const handleInventoryMenuClick = (event, vehicle) => {
    setAnchorElInventory(event.currentTarget);
    setSelectedVehicleInventory(vehicle); // Set the selected vehicle inventory object for editing or deleting
  };

  // Handle settings for Vehicle Inspection
  const handleInspectionMenuClick = (event, vehicle) => {
    setAnchorElInspection(event.currentTarget);
    setSelectedVehicleInspection(vehicle); // Set the selected vehicle inspection object for editing or deleting
  };

  // Close the dropdown menu for both tables
  const handleMenuClose = () => {
    setAnchorElInventory(null);
    setAnchorElInspection(null);
  };

  return (
    <>
      <React.Fragment>
        <Helmet title="Vehicle Inspection" />
        <Typography variant="h3" gutterBottom display="inline">
          Vehicle Inspection
        </Typography>

        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Paper sx={{ width: "100%" }}>
              <Box mt={6} sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleVehicleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Vehicle Inventory" {...a11yProps(0)} />
                  <Tab label="Vehicle Inspection" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <div>
                  <Paper mt={3} sx={{ display: "flex", justifyContent: "end" }}>
                    <IconButton
                      aria-owns={anchor ? "icon-menu" : undefined}
                      aria-haspopup="true"
                      onClick={handleFilterClick}
                      variant="contained"
                      color="secondary"
                    >
                      <FilterListIcon />
                    </IconButton>
                    <StyledFilterMenu
                      id="icon-menu"
                      anchorEl={anchor}
                      open={Boolean(anchor)}
                      onClose={handleFilterClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          handleOpenDialog();
                          handleFilterClose();
                        }}
                      >
                        <ListItemIcon>
                          <AddBoxIcon />
                        </ListItemIcon>
                        <ListItemText
                          size="large"
                          primary="Add Vehicle Inventory"
                          pl={0}
                        />
                      </MenuItem>
                    </StyledFilterMenu>
                  </Paper>
                </div>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>NFC ID</TableCell>
                        <TableCell>Vehicle Year</TableCell>
                        <TableCell>Vehicle Make</TableCell>
                        <TableCell>Vehicle Model</TableCell>
                        <TableCell>Vehicle Insurance Carrier</TableCell>
                        <TableCell>Vehicle Image</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>

                    {loadingVehicleInventory ? (
                      <TableRow>
                        <TableCell colSpan={11} align="center">
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableBody>
                        {vehicleInventoryList.map((vehicle) => {
                          const isItemSelected = isSelectedInventory(
                            vehicle.id
                          );
                          return (
                            <TableRow
                              key={vehicle.id}
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell>{vehicle.vehicle_uid}</TableCell>
                              <TableCell>{vehicle.vehicle_year}</TableCell>
                              <TableCell>{vehicle.vehicle_make}</TableCell>
                              <TableCell>{vehicle.vehicle_model}</TableCell>
                              <TableCell>
                                {vehicle.vehicle_insurance_carrier}
                              </TableCell>
                              <TableCell>
                                <img
                                  src={vehicle.vehicle_image}
                                  alt="Vehicle"
                                  width="100"
                                />
                              </TableCell>
                              <TableCell>
                                <IconButton
                                  aria-label="Settings"
                                  size="large"
                                  aria-owns={anchorEl ? "icon-menu" : undefined}
                                  aria-haspopup="true"
                                  style={{ color: "#1374C5" }}
                                  onClick={(event) =>
                                    handleInventoryMenuClick(event, vehicle)
                                  } // Pass vehicle object
                                >
                                  <SettingsIcon />
                                </IconButton>
                                <StyledMenu
                                  anchorEl={anchorElInventory}
                                  open={Boolean(anchorElInventory)}
                                  onClose={handleMenuClose}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                >
                                  <MenuItem
                                    onClick={() => {
                                      setVehicleObject(
                                        selectedVehicleInventory
                                      ); // Prefill the selected vehicle inventory object
                                      handleOpenDialog();
                                      handleMenuClose();
                                    }}
                                  >
                                    <ListItemIcon>
                                      <CreateIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Edit" pl={1} />
                                  </MenuItem>

                                  <MenuItem
                                    onClick={() => {
                                      handleDeleteClick(
                                        selectedVehicleInventory.id
                                      ); // Delete the selected vehicle inventory
                                      handleMenuClose();
                                    }}
                                  >
                                    <ListItemIcon>
                                      <DeleteIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Delete" pl={1} />
                                  </MenuItem>
                                </StyledMenu>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        {vehicleInventoryList.length === 0 && (
                          <TableRow>
                            <TableCell colSpan={11} align="center">
                              No records found
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <div>
                  <Paper mt={3} sx={{ display: "flex", justifyContent: "end" }}>
                    <IconButton
                      aria-owns={anchor ? "icon-menu" : undefined}
                      aria-haspopup="true"
                      onClick={handleFilterClick}
                      variant="contained"
                      color="secondary"
                    >
                      <FilterListIcon />
                    </IconButton>
                    <StyledFilterMenu
                      id="icon-menu"
                      anchorEl={anchor}
                      open={Boolean(anchor)}
                      onClose={handleFilterClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          handleOpenInspection();
                          handleFilterClose();
                        }}
                      >
                        <ListItemIcon>
                          <AddBoxIcon />
                        </ListItemIcon>
                        <ListItemText
                          size="large"
                          primary="Add Vehicle Inspection"
                          pl={0}
                        />
                      </MenuItem>
                    </StyledFilterMenu>
                  </Paper>
                </div>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Vehicle UID</TableCell>
                        <TableCell>Vehicle Plate</TableCell>
                        <TableCell>Vehicle Description</TableCell>
                        <TableCell>Inspection date</TableCell>
                        <TableCell>Inspected by</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    {loadingVehicleInspection ? (
                      <TableRow>
                        <TableCell colSpan={11} align="center">
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableBody>
                        {vehicleInspectionList.map((vehicles) => {
                          const isItemSelected = isSelected(vehicles.id);
                          return (
                            <TableRow
                              key={vehicles.id}
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell>{vehicles.vehicle_uid}</TableCell>
                              <TableCell>{vehicles.vehicle_plate}</TableCell>
                              <TableCell>
                                {vehicles.vehicle_description}
                              </TableCell>
                              <TableCell>{vehicles.insurance_expiry}</TableCell>
                              <TableCell>{vehicles.inspected_by}</TableCell>
                              <TableCell>
                                <IconButton
                                  aria-label="Settings"
                                  size="large"
                                  aria-owns={
                                    menuAnchorEl ? "icon-menu" : undefined
                                  }
                                  aria-haspopup="true"
                                  style={{ color: "#1374C5" }}
                                  onClick={(event) =>
                                    handleInspectionMenuClick(event, vehicles)
                                  } // Pass vehicle object
                                >
                                  <SettingsIcon />
                                </IconButton>
                                <StyledMenu
                                  anchorEl={anchorElInspection}
                                  open={Boolean(anchorElInspection)}
                                  onClose={handleMenuClose}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                >
                                  <MenuItem
                                    onClick={() => {
                                      setInspectionObject(
                                        selectedVehicleInspection
                                      ); // Prefill the selected vehicle inspection object
                                      handleOpenInspection();
                                      handleMenuClose();
                                    }}
                                  >
                                    <ListItemIcon>
                                      <CreateIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Edit" pl={1} />
                                  </MenuItem>

                                  <MenuItem
                                    onClick={() => {
                                      handleDeleteInspection(
                                        selectedVehicleInspection.id
                                      ); // Delete the selected vehicle inspection
                                      handleMenuClose();
                                    }}
                                  >
                                    <ListItemIcon>
                                      <DeleteIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Delete" pl={1} />
                                  </MenuItem>
                                </StyledMenu>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        {vehicleInspectionList.length === 0 && (
                          <TableRow>
                            <TableCell colSpan={11} align="center">
                              No records found
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </CustomTabPanel>
            </Paper>
          </Grid>
        </Grid>
      </React.Fragment>
      {AddVehiclesInventory && (
        <AddVehiclesInventory
          openDialog={openDialog}
          handleCloseDialog={handleCloseDialog}
          vehicle={vehicleObject}
        />
      )}
      {AddVehicleInspection && (
        <AddVehicleInspection
          openVehicleInspection={openVehicleInspection}
          handleCloseInspection={handleCloseInspection}
          vehicles={inspectionObject}
        />
      )}
    </>
  );
};

export default VehicleInspection;
