import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import styled from "styled-components/macro";
import CustomTextField from "../../../components/form-components/CustomTextField";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  FormControl as MuiFormControl,
  IconButton,
  Button,
  Paper,
  Typography,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { DAREditComment } from "../../../api";
import Toaster from "../../tasks/Toaster";
import { useDispatch, useSelector } from "react-redux";
import {
  getSnackOpen,
  getSnackClose,
  getSnackMsg,
  getSnackAlert,
} from "../../../redux/slices/reportSlice";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const EditActivityDialog = ({
  editCommentDialog,
  editCommentDialogClose,
  darId,
  darLocation,
  darRemark,
  getDailyActivityList,
}) => {
  const dispatch = useDispatch();
  // const snackbarStatus = useSelector((state) => state.report.snackStatus);
  // const snackbarMsg = useSelector((state) => state.report.snackMsg);
  // const snackbarAlert = useSelector((state) => state.report.snackAlert);
  const [openSnack, setOpenSnack] = useState(false); //state for snackbar
  const [alert, setAlert] = useState("");
  const [msg, setMsg] = useState("");

  //method for close the snackbar
  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(getSnackClose());
    // setOpenSnack(false);
  };
  const initialValues = {
    comments: darRemark ? darRemark : "",
    location: darLocation ? darLocation : "",
  };

  const handleSubmit = (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    let payload = {
      location: values.location,
      comments: values.comments,
    };
    DAREditComment(payload, darId)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(getSnackMsg("DAR comment has been upadated successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          // setMsg(`DAR comment has been upadated successfully`);
          // setAlert("success");
          // setOpenSnack(true);
          editCommentDialogClose();
          getDailyActivityList();
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getSnackMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          editCommentDialogClose();
          // setMsg("Something went wrong, please try again");
          // setAlert("error");
          // setOpenSnack(true);
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        // setMsg("Something went wrong, please try again");
        // setAlert("error");
        // setOpenSnack(true);
        dispatch(getSnackMsg("Something went wrong, please try again"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        editCommentDialogClose();
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };
  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
        aria-labelledby="edit-comment-title"
        aria-describedby="edit-comment-description"
        open={editCommentDialog}
        onClose={editCommentDialogClose}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="edit-comment-title"
        >
          <Typography variant="h4">Edit Activity</Typography>
          <DialogActions>
            <IconButton
              onClick={editCommentDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Paper>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                status,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={6}>
                    <Grid item xs={12} md={12}>
                      <FormControl mt={3}>
                        <CustomTextField
                          name="comments"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Comments"
                          autoComplete="off"
                          multiline={true}
                          rows={4}
                          placeholder="User Log Out"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <FormControl mt={3}>
                        <CustomTextField
                          name="location"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Location"
                          autoComplete="off"
                          multiline={true}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={editCommentDialogClose}
                      >
                        Cancel
                      </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        sx={{ marginLeft: "10px" }}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Paper>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditActivityDialog;
