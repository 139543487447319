import React, { useState } from "react";
import styled from "styled-components/macro";
import LiveIconDilaog from "./inner-components/LiveIconDilaog";
import LivePlusDialog from "./inner-components/LivePlusDialog";
import MessageIconDialog from "./inner-components/MessageIconDialog";
import IconPopup from "./inner-components/IconPopup";
import LiveModuleDilaog from "./inner-components/LiveModuleDilaog";
import LiveModuleModal from "./inner-components/LiveModuleModal";

import {
  Grid,
  Typography,
  IconButton,
  Box,
  Chip,
  ListItem,
  Card,
  List,
  Switch,
  Button,
  Menu,
  MenuItem,
  StyledMenu,
} from "@mui/material";

import {
  FilterAlt as FilterAltIcon,
  Visibility as VisibilityIcon,
  Fullscreen as FullscreenIcon,
  Cached as CachedIcon,
  Groups as GroupsIcon,
  EmailRounded as EmailRoundedIcon,
  PhotoOutlined as PhotoOutlinedIcon,
  AddBoxOutlined as AddBoxOutlinedIcon,
  DirectionsWalk as DirectionsWalkIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
} from "@mui/icons-material";
import { borderTop } from "@mui/system";
const label = { inputProps: { "aria-label": "Switch demo" } };

function LiveModule() {
  const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      maxWidth: "140px",
      width: "100%",
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        "& .MuiMenuItem-root": {
          display: "block",
          "& .MuiList-root": {
            paddingTop: "0px",
            marginTop: "15px",
            paddingBottom: "0px",
            "& .MuiListItem-root": {
              padding: "0px",
              background: "#7aa8ff0d",
              "& .MuiListItemButton-root": {
                padding: "3px 5px",
              },
            },
          },
        },

        "& .Mui-focusVisible": {
          backgroundColor: "transparent",
        },
      },
    },
  }));
  const ImageWrapper = styled.div``;
  const Image = styled.img`
    width: 100%;
  `;
  const [iconPopup, setIconPopup] = React.useState(false);
  const iconPopupOpen = () => {
    setIconPopup(true);
  };
  const iconPopupClose = () => {
    setIconPopup(false);
  };
  const [emailDialog, setEmailDialog] = React.useState(false);
  const emailDialogOpen = () => {
    setEmailDialog(true);
  };
  const emailDialogClose = () => {
    setEmailDialog(false);
  };

  const [livePlusDilaog, setLivePlusDilaog] = React.useState(false);
  const livePlusDilaogOpen = () => {
    setLivePlusDilaog(true);
  };
  const livePlusDilaogClose = () => {
    setLivePlusDilaog(false);
  };
  const [liveIconDilaog, setLiveIconDilaog] = React.useState(false);
  const liveIconDilaogOpen = () => {
    setLiveIconDilaog(true);
  };
  const liveIconDilaogClose = () => {
    setLiveIconDilaog(false);
  };

  const [liveModuleDilaog, setLiveModuleDilaog] = React.useState(false);
  const liveModuleDilaogOpen = () => {
    setLiveModuleDilaog(true);
  };
  const liveModuleDilaogClose = () => {
    setLiveModuleDilaog(false);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleActionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };
  const [liveModal, setLiveModal] = React.useState(false);
  const liveModalOpen = () => {
    setLiveModal(true);
  };
  const liveModalClose = () => {
    setLiveModal(false);
  };

  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={4} md={4}>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Button
              variant="outlined"
              color="error"
              size="medium"
              sx={{
                marginTop: "5px",
              }}
            >
              1
            </Button>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                marginTop: "10px",
                fontWeight: "500",
                marginLeft: "50px",
              }}
            >
              Last Update:
            </Typography>
            <Box
              sx={{
                marginLeft: "auto",
              }}
            >
              <IconButton onClick={liveIconDilaogOpen}>
                <FilterAltIcon />
              </IconButton>
              <Button
                aria-label="Settings"
                size="medium"
                aria-owns={anchorEl ? "icon-menu" : undefined}
                aria-haspopup="true"
                onClick={handleActionClick}
                style={{ color: "#1374C5" }}
                color="success"
                variant="outlined"
                sx={{
                  marginTop: "5px",
                }}
              >
                2
              </Button>
              <StyledMenu
                id="icon-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleActionClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem>
                  <Typography variant="body2" gutterBottom>
                    Inactive Users
                  </Typography>
                </MenuItem>
                <MenuItem>
                  <Typography variant="body2" gutterBottom>
                    Web Developer
                  </Typography>
                </MenuItem>
                <MenuItem>
                  <Typography variant="body2" gutterBottom>
                    Sandeep Kundu
                  </Typography>
                </MenuItem>
              </StyledMenu>
            </Box>
          </Grid>
          <Card
            variant="outlined"
            sx={{
              marginTop: "10px",
            }}
          >
            <List>
              <ListItem
                sx={{
                  display: "block",
                  borderBottom: "1px solid rgb(199 199 199)",
                }}
              >
                <Typography variant="body2" gutterBottom>
                  Web Developer Status: inactive
                  <IconButton onClick={liveModuleDilaogOpen}>
                    <DirectionsWalkIcon fontSize="small" />
                  </IconButton>
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  onClick={liveModalOpen}
                >
                  Marigold Hotel
                </Typography>
                <Typography variant="body2" gutterBottom>
                  01/06/2023 17:32:32(America/Tijuana)
                </Typography>
              </ListItem>
              <ListItem
                sx={{
                  display: "block",
                  borderBottom: "1px solid rgb(199 199 199)",
                }}
              >
                <Typography variant="body2" gutterBottom>
                  Web Developer Status: inactive
                  <IconButton>
                    <DirectionsWalkIcon fontSize="small" />
                  </IconButton>
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Marigold Hotel
                </Typography>
                <Typography variant="body2" gutterBottom>
                  01/06/2023 17:32:32(America/Tijuana)
                </Typography>
              </ListItem>
              <ListItem
                sx={{
                  display: "block",
                  borderBottom: "1px solid rgb(199 199 199)",
                }}
              >
                <Typography variant="body2" gutterBottom>
                  Web Developer Status: inactive
                  <IconButton>
                    <DirectionsWalkIcon fontSize="small" />
                  </IconButton>
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Marigold Hotel
                </Typography>
                <Typography variant="body2" gutterBottom>
                  01/06/2023 17:32:32(America/Tijuana)
                </Typography>
              </ListItem>
              <ListItem
                sx={{
                  display: "block",
                  borderBottom: "1px solid rgb(199 199 199)",
                }}
              >
                <Typography variant="body2" gutterBottom>
                  Web Developer Status: inactive
                  <IconButton>
                    <DirectionsWalkIcon fontSize="small" />
                  </IconButton>
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Marigold Hotel
                </Typography>
                <Typography variant="body2" gutterBottom>
                  01/06/2023 17:32:32(America/Tijuana)
                </Typography>
              </ListItem>
              <ListItem
                sx={{
                  display: "block",
                  borderBottom: "1px solid rgb(199 199 199)",
                }}
              >
                <Typography variant="body2" gutterBottom>
                  Web Developer Status: inactive
                  <IconButton>
                    <DirectionsWalkIcon fontSize="small" />
                  </IconButton>
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Marigold Hotel
                </Typography>
                <Typography variant="body2" gutterBottom>
                  01/06/2023 17:32:32(America/Tijuana)
                </Typography>
              </ListItem>
              <ListItem
                sx={{
                  display: "block",
                  borderBottom: "1px solid rgb(199 199 199)",
                }}
              >
                <Typography variant="body2" gutterBottom>
                  Web Developer Status: inactive
                  <IconButton>
                    <DirectionsWalkIcon fontSize="small" />
                  </IconButton>
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Marigold Hotel
                </Typography>
                <Typography variant="body2" gutterBottom>
                  01/06/2023 17:32:32(America/Tijuana)
                </Typography>
              </ListItem>
              <ListItem
                sx={{
                  display: "block",
                  borderBottom: "1px solid rgb(199 199 199)",
                }}
              >
                <Typography variant="body2" gutterBottom>
                  Web Developer Status: inactive
                  <IconButton>
                    <DirectionsWalkIcon fontSize="small" />
                  </IconButton>
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Marigold Hotel
                </Typography>
                <Typography variant="body2" gutterBottom>
                  01/06/2023 17:32:32(America/Tijuana)
                </Typography>
              </ListItem>
              <ListItem
                sx={{
                  display: "block",
                }}
              >
                <Typography variant="body2" gutterBottom>
                  Web Developer Status: inactive
                  <IconButton>
                    <DirectionsWalkIcon fontSize="small" />
                  </IconButton>
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Marigold Hotel
                </Typography>
                <Typography variant="body2" gutterBottom>
                  01/06/2023 17:32:32(America/Tijuana)
                </Typography>
              </ListItem>
            </List>
          </Card>
        </Grid>

        <Grid item xs={8} md={8}>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Switch {...label} defaultChecked />

            <IconButton sx={{ marginLeft: "auto" }}>
              <FullscreenIcon />
            </IconButton>
            <IconButton>
              <VisibilityIcon />
            </IconButton>
            <IconButton>
              <CachedIcon />
            </IconButton>
            <IconButton>
              <PhotoOutlinedIcon />
            </IconButton>
            <IconButton onClick={emailDialogOpen}>
              <EmailRoundedIcon />
            </IconButton>
            <IconButton>
              <GroupsIcon />
            </IconButton>
            <IconButton onClick={livePlusDilaogOpen}>
              <AddBoxOutlinedIcon color="error" />
            </IconButton>
          </Box>

          <Box
            sx={{
              marginTop: "5px",
            }}
          >
            <IconButton sx={{ float: "right" }} onClick={iconPopupOpen}>
              <ArrowForwardIosIcon fontSize="large" />
            </IconButton>

            <ImageWrapper
              sx={{
                position: "relative",
                display: "block",
              }}
            >
              <Image
                sx={{
                  width: "100%",
                }}
                component="img"
                alt="Guage Chart"
                src="/static/img/unsplash/guage-1.jpg"
              />
            </ImageWrapper>
          </Box>
        </Grid>
      </Grid>
      {liveModal && (
        <LiveModuleModal
          liveModal={liveModal}
          setLiveModal={setLiveModal}
          liveModalOpen={liveModalOpen}
          liveModalClose={liveModalClose}
        />
      )}

      {liveModuleDilaog && (
        <LiveModuleDilaog
          liveModuleDilaog={liveModuleDilaog}
          setLiveModuleDilaog={setLiveModuleDilaog}
          liveModuleDilaogOpen={liveModuleDilaogOpen}
          liveModuleDilaogClose={liveModuleDilaogClose}
        />
      )}
      {liveIconDilaog && (
        <LiveIconDilaog
          liveIconDilaog={liveIconDilaog}
          setLiveIconDilaog={setLiveIconDilaog}
          liveIconDilaogOpen={liveIconDilaogOpen}
          liveIconDilaogClose={liveIconDilaogClose}
        />
      )}
      {livePlusDilaog && (
        <LivePlusDialog
          livePlusDilaog={livePlusDilaog}
          setLivePlusDilaog={setLivePlusDilaog}
          livePlusDilaogOpen={livePlusDilaogOpen}
          livePlusDilaogClose={livePlusDilaogClose}
        />
      )}
      {emailDialog && (
        <MessageIconDialog
          emailDialog={emailDialog}
          setEmailDialog={setEmailDialog}
          emailDialogOpen={emailDialogOpen}
          emailDialogClose={emailDialogClose}
        />
      )}
      {iconPopup && (
        <IconPopup
          iconPopup={iconPopup}
          setIconPopup={setIconPopup}
          iconPopupOpen={iconPopupOpen}
          iconPopupClose={iconPopupClose}
        />
      )}
    </>
  );
}

export default LiveModule;
