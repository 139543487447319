import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import {
  getControllerList,
  userGroupList,
  getControllerActionList,
  updateGroupPermission,
} from "../../../api";
import {
  Typography,
  Grid,
  Select,
  MenuItem,
  FormControl as MuiFormControl,
  InputLabel,
  TableHead,
  TableRow,
  TableCell,
  Table,
  Card,
  Button,
  TableBody,
  Box,
  Checkbox,
} from "@mui/material";

const Permission = () => {
  const FormControlSpacing = styled(MuiFormControl)(spacing);
  const FormControl = styled(FormControlSpacing)`
    width: 100%;
  `;

  const [controllerList, setControllerList] = useState([]);
  const [selectedController, setSelectedController] = useState("");
  const [controllerActions, setControllerActions] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [permissions, setPermissions] = useState({});

  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    try {
      const controllerListResponse = await getControllerList();
      setControllerList(controllerListResponse.data);

      const userGroupListResponse = await userGroupList();
      setUserGroups(userGroupListResponse.data);
    } catch (error) {
      console.error("Error fetching initial data:", error);
    }
  };

  useEffect(() => {
    getControllerList()
      .then((response) => setControllerList(response.data))
      .catch((error) =>
        console.error("Failed to fetch controller list:", error)
      );

    userGroupList()
      .then((response) => setUserGroups(response.data))
      .catch((error) => console.error("Failed to fetch user groups:", error));
  }, []);

  useEffect(() => {
    const newPermissions = {};
    controllerActions.forEach((action) => {
      if (!newPermissions[action.controller]) {
        newPermissions[action.controller] = {};
      }
      newPermissions[action.controller][action.action_name] = action.user_group;
    });
    setPermissions(newPermissions);
  }, [controllerActions]);

  const handleChange = (event) => {
    const newControllerName = event.target.value;
    setSelectedController(newControllerName);
    if (newControllerName === "All") {
      fetchActionsForAllControllers();
    } else {
      fetchActionsForController(newControllerName);
    }
  };

  const fetchActionsForAllControllers = () => {
    Promise.all(
      controllerList.map((controller) =>
        getControllerActionList({ controller_name: controller })
      )
    )
      .then((responses) => {
        const allActions = responses.flatMap((response) => response.data);
        setControllerActions(allActions);
        allActions.forEach((action) =>
          updatePermissionsStateWithApiResponse(action)
        );
      })
      .catch((error) =>
        console.error("Failed to fetch actions for all controllers:", error)
      );
  };

  const fetchActionsForController = (controllerName) => {
    getControllerActionList({ controller_name: controllerName })
      .then((response) => {
        setControllerActions(response.data);
        updatePermissionsStateWithApiResponse(response.data);
      })
      .catch((error) =>
        console.error("Failed to fetch controller actions:", error)
      );
  };

  const updatePermissionsStateWithApiResponse = (actions) => {
    setPermissions((prevPermissions) => {
      const updatedPermissions = { ...prevPermissions };

      actions.forEach((action) => {
        const { controller, action_name, user_group } = action;
        if (!updatedPermissions[controller]) {
          updatedPermissions[controller] = {};
        }
        updatedPermissions[controller][action_name] = user_group;
      });

      return updatedPermissions;
    });
  };

  const handlePermissionChange = (event, controller, actionName, groupId) => {
    const checked = event.target.checked;

    setPermissions((prevPermissions) => {
      const updatedPermissions = { ...prevPermissions };

      updatedPermissions[controller] = updatedPermissions[controller] || {};
      updatedPermissions[controller][actionName] =
        updatedPermissions[controller][actionName] || [];

      if (checked) {
        updatedPermissions[controller][actionName] = [
          ...updatedPermissions[controller][actionName],
          groupId,
        ];
      } else {
        updatedPermissions[controller][actionName] = updatedPermissions[
          controller
        ][actionName].filter((id) => id !== groupId);
      }

      return updatedPermissions;
    });
  };

  const handleUpdate = (action) => {
    const userGroupIds =
      permissions[action.controller]?.[action.action_name] || [];

    updateGroupPermission({
      controller: action.controller,
      action_name: action.action_name,
      user_group_id: userGroupIds,
      what_it_does: action.what_it_does,
    })
      .then((response) => {
        alert("Permissions updated successfully");
      })
      .catch((error) => console.error("Failed to update permissions:", error));
  };

  return (
    <>
      <Typography variant="h3" gutterBottom>
        Edit Group Permission
      </Typography>
      <Grid container spacing={4}>
        <Grid item lg={3} md={3} mt={4}>
          <FormControl>
            <InputLabel id="demo-select-small">Select Controller</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={selectedController}
              onChange={handleChange}
              label="Select Controller"
            >
              {controllerList.map((controller, index) => (
                <MenuItem key={index} value={controller}>
                  {controller}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Card
        sx={{
          marginTop: "25px",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Controller</TableCell>
              <TableCell align="left">Action</TableCell>
              <TableCell align="left">What it Does</TableCell>
              <TableCell align="left">Permission</TableCell>
              <TableCell align="right">Operation</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {controllerActions.map((action, index) => (
              <TableRow key={index}>
                <TableCell>{action.controller}</TableCell>
                <TableCell>{action.action_name}</TableCell>
                <TableCell>{action.what_it_does}</TableCell>
                <TableCell>
                  {userGroups.map((group) => (
                    <Box
                      key={group.id}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Checkbox
                        fontSize="small"
                        checked={
                          // Ensure we're accessing existing state, default to false if not found
                          permissions[action.controller]?.[
                            action.action_name
                          ]?.includes(group.id) || false
                        }
                        onChange={(event) =>
                          handlePermissionChange(
                            event,
                            action.controller,
                            action.action_name,
                            group.id
                          )
                        }
                      />
                      <Typography variant="body2">{group.name}</Typography>
                    </Box>
                  ))}
                </TableCell>

                <TableCell align="right">
                  <Button
                    variant="outlined"
                    onClick={() => handleUpdate(action)}
                  >
                    Update
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    </>
  );
};

export default Permission;
