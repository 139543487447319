import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { Formik } from "formik";
import CustomTextField from "../../components/form-components/CustomTextField";
import { makeStyles } from "@mui/styles";
import AddEquipmentType from "./innerform/AddEquipmentType";
import AddKeyGroup from "./innerform/AddKeyGroup";
import AddKeyCabinet from "./innerform/AddKeyCabinet";
import AddKeyRing from "./innerform/AddKeyRing";
import AddKeyDoor from "./innerform/AddKeyDoor";
import AddKeyLockNumber from "./innerform/AddKeyLockNumber";
import AddKeyBuilding from "./innerform/AddKeyBuilding";
import AddKeyType from "./innerform/AddKeyType";
import { useDispatch, useSelector } from "react-redux";
import Toaster from "../tasks/Toaster";
import EquipmentUserDialog from "./innerform/EquipmentUserDialog";
import {
  GetAddKeyRingList,
  GetAddKeyCabinetList,
  GetAddKeyDoorList,
  GetAddKeyLockNumberList,
  GetAddKeyBuildingList,
  GetAddKeyTypeList,
  UpdateEquipment,
  ViewEquipment,
  ViewEquipDepartmentList,
  GetAddEquipmentType,
  GetEditEquipUser,
  EquipmentTypeTree,
  KeyGroupTree,
  ViewEquipmentUsers,
  UpdatePositionUsers,
} from "../../api";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../redux/slices/equipmentSlice";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Breadcrumbs as MuiBreadcrumbs,
  Box,
  Divider as MuiDivider,
  Link,
  FormControl as MuiFormControl,
  Button as MuiButton,
  Checkbox,
  FormLabel,
  FormGroup,
  Tooltip,
  IconButton,
  FormControlLabel,
  RadioGroup,
  Radio,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Fade,
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Paper,
  MenuItem,
  Select,
  InputLabel as MuiInputLabel,
  CircularProgress,
} from "@mui/material";
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Error as ErrorIcon,
  Edit as EditIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import AddEquipment from "./AddEquipment";
import CustomCheckboxTree from "../../components/form-components/CustomCheckboxTree";

const FormControlSpacing = styled(MuiFormControl)(spacing);
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const Accordion = styled(MuiAccordion)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
`;
// Classes Styling
const useStyles = makeStyles((theme) => ({
  accordion: {
    marginTop: 16,
    borderTop: 0,
    "& .MuiAccordion-root": {
      "& .MuiAccordionSummary-root": {
        padding: "0 12px",
        opacity: ".5",
      },
      "& .MuiAccordionDetails-root": {
        padding: "8px 12px 12px",
      },
    },
    "& .MuiAccordion-root::before": {
      display: "none",
    },
    "& .Mui-expanded": {
      minHeight: "auto",
      margin: "7px 0 7px",
    },
  },
  borderedBox: {
    border: "1px solid",
    borderBottom: 0,
    borderColor:
      theme.palette.mode === "light"
        ? "rgb(224 224 224)"
        : theme.palette.grey[300],
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const StyledFormControl = styled((props) => <FormGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormGroup-root": {
      display: "block",
      maxHeight: "250px",
      marginTop: "5px",
      overflowY: "auto",
      "& .MuiFormControlLabel-root": {
        display: "block",
      },
    },
  })
);
// Users Data
function createUserData(uid, fname, lname, department) {
  return { uid, fname, lname, department };
}

// Positions Data
function createPositionsData(positionDept, positions, positionSecurityLevel) {
  return { positionDept, positions, positionSecurityLevel };
}

function EditEquipmentForm() {
  const { id } = useParams(); //get the equipmentId
  let navigate = useNavigate();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [equipUserModal, setEquipUserModal] = useState(false);

  const equipUserModalOpen = () => {
    setEquipUserModal(true);
  };
  const equipUserModalClose = () => {
    setEquipUserModal(false);
  };
  // Add Equipment Type Modal
  const [openaddequip, setOpenAddEquip] = React.useState(false);
  const addEquipOpen = () => {
    setOpenAddEquip(true);
  };
  const addEquipClose = () => {
    setOpenAddEquip(false);
  };
  // Modal Add Key Group
  const [addKeyGroup, setAddKeyGroup] = useState(false);
  const addKeyGroupOpen = () => {
    setAddKeyGroup(true);
  };
  const addKeyGroupClose = () => {
    setAddKeyGroup(false);
  };
  // Modal Add Key Cabinet
  const [addKeyCabinet, setAddKeyCabinet] = useState(false);
  const addKeyCabinetOpen = () => {
    setAddKeyCabinet(true);
  };
  const addKeyCabinetClose = () => {
    setAddKeyCabinet(false);
  };
  // Modal Add Key Ring
  const [addKeyRing, setAddKeyRing] = useState(false);
  const addKeyRingOpen = () => {
    setAddKeyRing(true);
  };
  const addKeyRingClose = () => {
    setAddKeyRing(false);
  };
  // Modal Add Key Door
  const [addKeyDoor, setAddKeyDoor] = useState(false);
  const addKeyDoorOpen = () => {
    setAddKeyDoor(true);
  };
  const addKeyDoorClose = () => {
    setAddKeyDoor(false);
  };
  // Modal Add Key Lock Number
  const [addKeyLockNumber, setAddKeyLockNumber] = useState(false);
  const addKeyLockNumberOpen = () => {
    setAddKeyLockNumber(true);
  };
  const addKeyLockNumberClose = () => {
    setAddKeyLockNumber(false);
  };
  // Modal Add Key Building
  const [addKeyBuilding, setAddKeyBuilding] = useState(false);
  const addKeyBuildingOpen = () => {
    setAddKeyBuilding(true);
  };
  const addKeyBuildingClose = () => {
    setAddKeyBuilding(false);
  };
  // Modal Add Key Type
  const [addKeyType, setAddKeyType] = useState(false);
  const addKeyTypeOpen = () => {
    setAddKeyType(true);
  };
  const addKeyTypeClose = () => {
    setAddKeyType(false);
  };
  const [loadList, setLoadList] = React.useState("users");
  const handleLoadList = (e) => {
    setLoadList(e.target.value);
    if (e.target.value === "positions") {
      viewEquipDepartmentList();
    }
  };

  const dispatch = useDispatch();
  // Checkbox Tree
  const [equipTypeTree, setEquipTypeTree] = useState([]);
  const [isCheckedValue, setIsCheckedValue] = useState(0);

  const handleCheckedTree = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setIsCheckedValue(value);
    } else {
      setIsCheckedValue(0);
    }
  };
  const getEquipmentTypeTree = () => {
    EquipmentTypeTree()
      .then((res) => {
        if (res.statusCode === 200) {
          setEquipTypeTree(res.data);
        } else {
          setEquipTypeTree([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Key Group Checkbox Tree
  const [equipKeyGroupTree, setEquipKeyGroupTree] = useState([]);
  const [isCheckedKeyGroupValue, setIsCheckedKeyGroupValue] = useState(0);

  const handleCheckedKeyGroupTree = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setIsCheckedKeyGroupValue(value);
    } else {
      setIsCheckedKeyGroupValue(0);
    }
  };
  const getEquipmentKeyGroupTree = () => {
    KeyGroupTree()
      .then((res) => {
        if (res.statusCode === 200) {
          setEquipKeyGroupTree(res.data);
        } else {
          setEquipKeyGroupTree([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [viewEquip, setViewEquip] = useState({});
  const viewEquipment = () => {
    ViewEquipment(id)
      .then((res) => {
        if (res.statusCode === 200) {
          setLoading(false);
          setViewEquip(res.data);
          setIsCheckedValue(res.data.equipment_type_id);
          setIsCheckedKeyGroupValue(res.data.key_group_id);
        } else {
          setViewEquip({});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const {
    disposition,
    equipment_id,
    equipment_name,
    equipment_sl,
    equipment_type_id,
    key_building_id,
    key_cabinet_id,
    key_door_id,
    key_group_id,
    key_lock_number_id,
    key_notes,
    key_ring_id,
    Key_type_id,
  } = viewEquip;

  // let sl = [];
  // for (let i = 1; i <= 10; i++) {
  //   sl.push(i);
  // }
  const sl = Array.from({ length: 10 }, (_, i) => i + 1);

  const addToList = (setList, getList) => {
    getList()
      .then((res) => {
        if (res.statusCode === 200) {
          setList(res.data);
        } else {
          setList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [keyRingList, setKeyRingList] = useState([]);
  const addKeyRingList = () => addToList(setKeyRingList, GetAddKeyRingList);

  const [keyCabinetList, setKeyCabinetList] = useState([]);
  const addKeyCabinetList = () =>
    addToList(setKeyCabinetList, GetAddKeyCabinetList);

  const [keyDoorList, setKeyDoorList] = useState([]);
  const addKeyDoorList = () => addToList(setKeyDoorList, GetAddKeyDoorList);

  const [keyLockNumberList, setKeyLockNumberList] = useState([]);
  const addKeyLockNumberList = () =>
    addToList(setKeyLockNumberList, GetAddKeyLockNumberList);

  const [keyBuildingList, setKeyBuildingList] = useState([]);
  const addKeyBuildingList = () =>
    addToList(setKeyBuildingList, GetAddKeyBuildingList);

  const [keyTypeList, setKeyTypeList] = useState([]);
  const getAddKeyTypeList = () => addToList(setKeyTypeList, GetAddKeyTypeList);

  const childToParent = (val, type) => {
    switch (type) {
      case "key_ring":
        setKeyRingList(val);
        break;
      case "lock_number":
        setKeyLockNumberList(val);
        break;
      case "key_cabinet":
        setKeyCabinetList(val);
        break;
      case "key_door":
        setKeyDoorList(val);
        break;
      case "key_building":
        setKeyBuildingList(val);
        break;
      case "key_type":
        setKeyTypeList(val);
      default:
        break;
    }
  };

  const [viewEquipDepList, setViewEquipDepList] = useState([]);
  const viewEquipDepartmentList = () => {
    ViewEquipDepartmentList({ sl: equipment_sl, equip_key: key_ring_id })
      .then((res) => {
        if (res.statusCode === 200) {
          setViewEquipDepList(res.data);
        } else {
          setViewEquipDepList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [editEquipUser, setEditEquipUser] = useState([]);
  const getEditEquipmentUser = () => {
    let payload = {
      security_level: equipment_sl,
      id: id,
    };
    GetEditEquipUser({ params: payload })
      .then((res) => {
        if (res.statusCode === 200) {
          setEditEquipUser(res.data);
        } else {
          setEditEquipUser([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * This function is used to get the equipment type from the database
   */
  const [equipType, setEquipType] = useState([]);
  const getEquipmentType = () => {
    GetAddEquipmentType()
      .then((res) => {
        if (res.statusCode === 200) {
          setEquipType(res.data);
        } else {
          setEquipType([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const UserCheckbox = (props) => {
    const { value, id, row } = props;
    const [active, setActive] = useState(value == "Y" ? true : false);

    return (
      <Checkbox
        color="primary"
        checked={active}
        onClick={() => {
          setActive(!active);
          handleChangeEquipUser(row);
        }}
      />
    );
  };

  useEffect(() => {
    viewEquipment();
    addKeyRingList();
    addKeyCabinetList();
    addKeyDoorList();
    addKeyLockNumberList();
    addKeyBuildingList();
    getAddKeyTypeList();
    getEquipmentType();
    getEquipmentTypeTree();
    getEquipmentKeyGroupTree();
    setLoading(true);
  }, []);

  useEffect(() => {
    getEditEquipmentUser();
  }, [equipment_sl]);

  const initialValues = {
    equipment_id: equipment_id ? equipment_id : "",
    equipment_name: equipment_name ? equipment_name : "",
    key_ring: key_ring_id ? key_ring_id : "",
    key_cabinet: key_cabinet_id ? key_cabinet_id : "",
    security_level: equipment_sl ? equipment_sl : "",
    key_door: key_door_id ? key_door_id : "",
    key_lock_number: key_lock_number_id ? key_lock_number_id : "",
    key_building: key_building_id ? key_building_id : "",
    key_type: Key_type_id ? Key_type_id : "",
    key_notes: key_notes ? key_notes : "",
    equipment_disposition: disposition ? disposition : "in_service",
  };
  const validationSchema = Yup.object().shape({
    equipment_id: Yup.string().required("Please enter equipment id"),
    equipment_name: Yup.string().required("Please enter equipment name"),
  });
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoading(true);
      let formData = {
        id: id,
        equipment_id: values.equipment_id,
        equipment_name: values.equipment_name,
        equipment_type_id: isCheckedValue,
        key_group_id: isCheckedKeyGroupValue,
        key_ring_id: values.key_ring,
        key_door_id: values.key_door,
        key_building_id: values.key_building,
        key_lock_number_id: values.key_lock_number,
        key_type_id: values.key_type,
        key_cabinet_id: values.key_cabinet,
        key_notes: values.key_notes,
        disposition: values.equipment_disposition,
        equipment_sl: values.security_level,
      };
      UpdateEquipment(formData).then((res) => {
        if (res.statusCode === 200) {
          dispatch(getSnackOpen());
          dispatch(getMsg("Equipment has been updated successfully"));
          dispatch(getSnackAlert("success"));
          setLoading(false);
          navigate({
            pathname: "/equipment/equipment-list",
          });
        } else {
          dispatch(getSnackClose());
        }
      });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  // const dispatch = useDispatch();
  const snackbarStatus = useSelector((state) => state.equipment.snackStatus);
  const snackbarMsg = useSelector((state) => state.equipment.msg);
  const snackbarAlert = useSelector((state) => state.equipment.snackAlert);

  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(getSnackClose());
  };

  let equipTypeTree1 = [
    { id: "key_id", name: "Keys", parent_id: 0, children_recursive: [] },
    ...equipTypeTree,
  ];

  const [checkedEquipUser, setCheckedEquipUser] = useState(false);
  const [equipUserData, setEquipUserData] = useState({});
  const handleChangeEquipUser = (data) => {
    if (data.check == "Y" && loadList == "users") {
      ViewEquipmentUsers({
        user_id: data.id,
        check: "N",
        equipment_id: id,
      })
        .then((res) => {
          if (res.statusCode === 200) {
            console.log(res.data);
            getEditEquipmentUser();
          } else {
            console.log("error");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (data.check == "Y" && loadList == "positions") {
      UpdatePositionUsers({
        pos_id: data.pos_id,
        check: "N",
        equip_key: key_ring_id,
      })
        .then((res) => {
          if (res.statusCode === 200) {
            console.log(res.data);
            viewEquipDepartmentList();
          } else {
            console.log("error");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (isCheckedValue == "key_id" && data.check == "N") {
      equipUserModalOpen();
    }
    setEquipUserData(data);
  };
  return (
    <>
      {snackbarStatus && (
        <Toaster
          openSnack={snackbarStatus}
          handleCloseSnack={handleCloseSnack}
          severity={snackbarAlert}
          message={snackbarMsg}
        />
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          setFieldValue,
          values,
          status,
        }) => (
          <Card>
            <CardContent>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={4}>
                      <CustomTextField
                        name="equipment_id"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Equipment ID"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomTextField
                        name="equipment_name"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Equipment name"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <StyledFormControl>
                        <FormLabel component="legend">
                          Equipment type
                          {
                            <sup
                              style={{
                                verticalAlign: "top",
                              }}
                            >
                              *
                            </sup>
                          }
                          <Tooltip
                            title="Click to add or edit equipment types"
                            placement="right-start"
                          >
                            <IconButton color="warning" onClick={addEquipOpen}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </FormLabel>
                        <CustomCheckboxTree
                          handleChecked={(evt) => handleCheckedTree(evt)}
                          equipType={equipTypeTree1}
                          isChecked={isCheckedValue}
                        />
                        {openaddequip && (
                          <AddEquipmentType
                            openaddequip={openaddequip}
                            addEquipOpen={addEquipOpen}
                            addEquipClose={addEquipClose}
                            setOpenAddEquip={setOpenAddEquip}
                          />
                        )}
                      </StyledFormControl>
                    </FormControl>

                    <Grid
                      container
                      spacing={6}
                      style={{
                        display: isCheckedValue === "key_id" ? "block" : "none",
                      }}
                    >
                      <Grid item xs={12} md={12}>
                        <FormControl mt={3}>
                          <StyledFormControl>
                            <FormLabel component="legend">
                              Key Group
                              {
                                <sup
                                  style={{
                                    verticalAlign: "top",
                                  }}
                                >
                                  *
                                </sup>
                              }
                              <Tooltip
                                title="Click to add or edit key groups"
                                placement="right-start"
                              >
                                <IconButton
                                  color="warning"
                                  onClick={addKeyGroupOpen}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                            </FormLabel>
                            <FormGroup>
                              <CustomCheckboxTree
                                handleChecked={(evt) =>
                                  handleCheckedKeyGroupTree(evt)
                                }
                                equipType={equipKeyGroupTree}
                                isChecked={isCheckedKeyGroupValue}
                              />
                            </FormGroup>
                          </StyledFormControl>
                        </FormControl>
                        {addKeyGroup && (
                          <AddKeyGroup
                            addKeyGroup={addKeyGroup}
                            addKeyGroupClose={addKeyGroupClose}
                            addKeyGroupOpen={addKeyGroupOpen}
                            setAddKeyGroup={setAddKeyGroup}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Grid container spacing={4}>
                          <Grid item xs={12} md={6}>
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "nowrap",
                                alignItems: "center",
                              }}
                            >
                              <Item sx={{ flexGrow: 1, width: "100%" }}>
                                <FormControl>
                                  <InputLabel
                                    shrink
                                    required
                                    id="key-ring"
                                    sx={{
                                      background: "#fff",
                                      paddingLeft: "6px",
                                      paddingRight: "6px",
                                      marginLeft: "-4px",
                                    }}
                                  >
                                    Key ring
                                  </InputLabel>
                                  <Select
                                    labelId="key-ring"
                                    id="key-ring"
                                    name="key_ring"
                                    value={values.key_ring}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    displayEmpty
                                  >
                                    <MenuItem value="">
                                      Select key ring
                                    </MenuItem>
                                    {keyRingList.map((data) => {
                                      return (
                                        <MenuItem value={data.id} key={data.id}>
                                          {data.ring_name}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </Item>
                              <Item>
                                <Tooltip
                                  title="Click to add or edit key rings"
                                  placement="right-start"
                                >
                                  <IconButton
                                    color="warning"
                                    onClick={addKeyRingOpen}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                                {addKeyRing && (
                                  <AddKeyRing
                                    addKeyRing={addKeyRing}
                                    setAddKeyRing={setAddKeyRing}
                                    addKeyRingOpen={addKeyRingOpen}
                                    addKeyRingClose={addKeyRingClose}
                                    childToParent={childToParent}
                                  />
                                )}
                              </Item>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "nowrap",
                                alignItems: "center",
                              }}
                            >
                              <Item sx={{ flexGrow: 1, width: "100%" }}>
                                <FormControl>
                                  <InputLabel
                                    shrink
                                    required
                                    id="key-cabinet"
                                    sx={{
                                      background: "#fff",
                                      paddingLeft: "6px",
                                      paddingRight: "6px",
                                      marginLeft: "-4px",
                                    }}
                                  >
                                    Key cabinet
                                  </InputLabel>
                                  <Select
                                    labelId="key-cabinet"
                                    id="key-cabinet"
                                    name="key_cabinet"
                                    value={values.key_cabinet}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    displayEmpty
                                  >
                                    <MenuItem value="">
                                      Select key cabinet
                                    </MenuItem>
                                    {keyCabinetList.map((data) => {
                                      return (
                                        <MenuItem value={data.id} key={data.id}>
                                          {data.name}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </Item>
                              <Item>
                                <Tooltip
                                  title="Click to add or edit key cabinets"
                                  placement="right-start"
                                >
                                  <IconButton
                                    color="warning"
                                    onClick={addKeyCabinetOpen}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                                {addKeyCabinet && (
                                  <AddKeyCabinet
                                    addKeyCabinet={addKeyCabinet}
                                    setAddKeyCabinet={setAddKeyCabinet}
                                    addKeyCabinetOpen={addKeyCabinetOpen}
                                    addKeyCabinetClose={addKeyCabinetClose}
                                    childToParent={childToParent}
                                  />
                                )}
                              </Item>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <FormControl>
                          <InputLabel
                            shrink
                            required
                            id="security-level"
                            sx={{
                              background: "#fff",
                              paddingLeft: "6px",
                              paddingRight: "6px",
                              marginLeft: "-4px",
                            }}
                          >
                            Key security level
                          </InputLabel>
                          <Select
                            labelId="security-level"
                            id="security-level"
                            name="security_level"
                            value={values.security_level}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            displayEmpty
                          >
                            <MenuItem value="">Select security level</MenuItem>
                            {sl.map((data) => {
                              return (
                                <MenuItem value={data} key={data}>
                                  {data}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <Box
                          className={classes.accordion}
                          /*  style={{
                            display:
                              ketSecurityLevelSelect === 2 ? "block" : "none",
                          }} */
                        >
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="option-key-details"
                              id="option-key-det"
                            >
                              <Typography>Optional Key Details</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container spacing={6}>
                                <Grid item xs={12} md={6}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexWrap: "nowrap",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Item sx={{ flexGrow: 1, width: "100%" }}>
                                      <FormControl>
                                        <InputLabel
                                          shrink
                                          required
                                          id="key-door"
                                          sx={{
                                            background: "#fff",
                                            paddingLeft: "6px",
                                            paddingRight: "6px",
                                            marginLeft: "-4px",
                                          }}
                                        >
                                          Key door
                                        </InputLabel>
                                        <Select
                                          labelId="disposition"
                                          id="key-door"
                                          name="key_door"
                                          value={values.key_door}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          fullWidth
                                          displayEmpty
                                        >
                                          <MenuItem value="">
                                            Select key door
                                          </MenuItem>
                                          {keyDoorList.map((data) => {
                                            return (
                                              <MenuItem
                                                value={data.id}
                                                key={data.id}
                                              >
                                                {data.name}
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                      </FormControl>
                                    </Item>
                                    <Item>
                                      <Tooltip
                                        title="Click to add or edit key doors"
                                        placement="right-start"
                                      >
                                        <IconButton
                                          color="warning"
                                          onClick={addKeyDoorOpen}
                                        >
                                          <EditIcon />
                                        </IconButton>
                                      </Tooltip>
                                      {addKeyDoor && (
                                        <AddKeyDoor
                                          addKeyDoor={addKeyDoor}
                                          addKeyDoorOpen={addKeyDoorOpen}
                                          addKeyDoorClose={addKeyDoorClose}
                                          setAddKeyDoor={setAddKeyDoor}
                                          childToParent={childToParent}
                                        />
                                      )}
                                    </Item>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexWrap: "nowrap",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Item sx={{ flexGrow: 1, width: "100%" }}>
                                      <FormControl>
                                        <InputLabel
                                          shrink
                                          required
                                          id="key-lock"
                                          sx={{
                                            background: "#fff",
                                            paddingLeft: "6px",
                                            paddingRight: "6px",
                                            marginLeft: "-4px",
                                          }}
                                        >
                                          Key lock number
                                        </InputLabel>
                                        <Select
                                          labelId="disposition"
                                          id="key-lock"
                                          name="key_lock_number"
                                          value={values.key_lock_number}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          fullWidth
                                          displayEmpty
                                        >
                                          <MenuItem value="">
                                            Select key lock no.
                                          </MenuItem>

                                          {keyLockNumberList.map((data) => {
                                            return (
                                              <MenuItem
                                                value={data.id}
                                                key={data.id}
                                              >
                                                {data.name}
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                      </FormControl>
                                    </Item>
                                    <Item>
                                      <Tooltip
                                        title="Click to add or edit key doors"
                                        placement="right-start"
                                      >
                                        <IconButton
                                          color="warning"
                                          onClick={addKeyLockNumberOpen}
                                        >
                                          <EditIcon />
                                        </IconButton>
                                      </Tooltip>
                                      {addKeyLockNumber && (
                                        <AddKeyLockNumber
                                          addKeyLockNumber={addKeyLockNumber}
                                          addKeyLockNumberClose={
                                            addKeyLockNumberClose
                                          }
                                          setAddKeyLockNumber={
                                            setAddKeyLockNumber
                                          }
                                          addKeyLockNumberOpen={
                                            addKeyLockNumberOpen
                                          }
                                          childToParent={childToParent}
                                        />
                                      )}
                                    </Item>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexWrap: "nowrap",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Item sx={{ flexGrow: 1, width: "100%" }}>
                                      <FormControl>
                                        <InputLabel
                                          shrink
                                          required
                                          id="key-building"
                                          sx={{
                                            background: "#fff",
                                            paddingLeft: "6px",
                                            paddingRight: "6px",
                                            marginLeft: "-4px",
                                          }}
                                        >
                                          Key building
                                        </InputLabel>
                                        <Select
                                          labelId="disposition"
                                          id="key-building"
                                          name="key_building"
                                          value={values.key_building}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          fullWidth
                                          displayEmpty
                                        >
                                          <MenuItem value="">
                                            Select key building
                                          </MenuItem>
                                          {keyBuildingList.map((data) => {
                                            return (
                                              <MenuItem
                                                value={data.id}
                                                key={data.id}
                                              >
                                                {data.name}
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                      </FormControl>
                                    </Item>
                                    <Item>
                                      <Tooltip
                                        title="Click to add or edit key doors"
                                        placement="right-start"
                                      >
                                        <IconButton
                                          color="warning"
                                          onClick={addKeyBuildingOpen}
                                        >
                                          <EditIcon />
                                        </IconButton>
                                      </Tooltip>
                                      {addKeyBuilding && (
                                        <AddKeyBuilding
                                          addKeyBuilding={addKeyBuilding}
                                          setAddKeyBuilding={setAddKeyBuilding}
                                          addKeyBuildingClose={
                                            addKeyBuildingClose
                                          }
                                          addKeyBuildingOpen={
                                            addKeyBuildingOpen
                                          }
                                          childToParent={childToParent}
                                        />
                                      )}
                                    </Item>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexWrap: "nowrap",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Item sx={{ flexGrow: 1, width: "100%" }}>
                                      <FormControl>
                                        <InputLabel
                                          shrink
                                          required
                                          id="key-type"
                                          sx={{
                                            background: "#fff",
                                            paddingLeft: "6px",
                                            paddingRight: "6px",
                                            marginLeft: "-4px",
                                          }}
                                        >
                                          Key type
                                        </InputLabel>
                                        <Select
                                          labelId="disposition"
                                          id="key-type"
                                          name="key_type"
                                          value={values.key_type}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          fullWidth
                                          displayEmpty
                                        >
                                          <MenuItem value="">
                                            Select key type
                                          </MenuItem>
                                          {keyTypeList.map((data) => {
                                            return (
                                              <MenuItem
                                                value={data.id}
                                                key={data.id}
                                              >
                                                {data.type_name}
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                      </FormControl>
                                    </Item>
                                    <Item>
                                      <Tooltip
                                        title="Click to add or edit key doors"
                                        placement="right-start"
                                      >
                                        <IconButton
                                          color="warning"
                                          onClick={addKeyTypeOpen}
                                        >
                                          <EditIcon />
                                        </IconButton>
                                      </Tooltip>
                                      {addKeyType && (
                                        <AddKeyType
                                          addKeyType={addKeyType}
                                          addKeyTypeClose={addKeyTypeClose}
                                          addKeyTypeOpen={addKeyTypeOpen}
                                          setAddKeyType={setAddKeyType}
                                          childToParent={childToParent}
                                        />
                                      )}
                                    </Item>
                                  </Box>
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <FormControl>
                          <TextField
                            id="key-notes"
                            label="Key notes"
                            name="key_notes"
                            value={values.key_notes}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder=""
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={1}>
                      <FormLabel component="legend">
                        Equipment Disposition
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="equipment-dispotion"
                        name="equipment_disposition"
                        value={values.equipment_disposition}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <FormControlLabel
                          value="in_service"
                          control={<Radio />}
                          label="In service"
                        />
                        <FormControlLabel
                          value="lost"
                          control={<Radio />}
                          label="Lost"
                        />
                        <FormControlLabel
                          value="expired"
                          control={<Radio />}
                          label="Expired"
                        />
                        <FormControlLabel
                          value="out_for_repair"
                          control={<Radio />}
                          label="Out for repair"
                        />
                      </RadioGroup>
                    </FormControl>
                    <Typography mt={6}>
                      Grant permissions to checkout this eqipment to individual
                      users or entire positions by using the list below. Need to
                      edit users first?
                      {
                        <Link
                          component={NavLink}
                          to="/equipment/equipment-user-list"
                          sx={{ marginLeft: "5px" }}
                        >
                          Click here.
                        </Link>
                      }
                    </Typography>
                    <FormControl mt={6}>
                      <FormLabel component="legend">
                        Load the list below with:
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="load-the-list"
                        value={loadList}
                        name="load_the_list"
                        onChange={handleLoadList}
                      >
                        <FormControlLabel
                          value="users"
                          control={<Radio />}
                          label="Users"
                        />
                        <FormControlLabel
                          value="positions"
                          control={<Radio />}
                          label="Positions"
                        />
                      </RadioGroup>
                    </FormControl>
                    <Box
                      className={classes.borderedBox}
                      mt={6}
                      style={{
                        display: loadList === "users" ? "block" : "none",
                      }}
                    >
                      <Typography
                        variant="h5"
                        component="div"
                        align="center"
                        gutterBottom
                        mt={5}
                      >
                        Users
                      </Typography>
                      <TableContainer className="tableContainer">
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              <TableCell padding="checkbox">
                                {/* <Checkbox color="primary" /> */}
                              </TableCell>
                              <TableCell>User ID</TableCell>
                              <TableCell>First Name</TableCell>
                              <TableCell>Last Name</TableCell>
                              <TableCell>Department</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {editEquipUser.map((row) => {
                              return (
                                <TableRow key={row.id} hover>
                                  <TableCell padding="checkbox">
                                    {/* <Checkbox
                                      color="primary"
                                      checked={row.check == "Y" ? true : false}
                                      onClick={(e) =>
                                        handleChangeEquipUser(e, row)
                                      }
                                    /> */}
                                    <UserCheckbox
                                      value={row.check}
                                      id={row.id}
                                      row={row}
                                    />
                                  </TableCell>
                                  <TableCell>{row.user_id}</TableCell>
                                  <TableCell>{row.fname}</TableCell>
                                  <TableCell>{row.lname}</TableCell>
                                  <TableCell>{row.department_name}</TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                    <Box
                      className={classes.borderedBox}
                      mt={6}
                      style={{
                        display: loadList === "positions" ? "block" : "none",
                      }}
                    >
                      <Box
                        mt={5}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "0 15px",
                        }}
                      >
                        <Typography variant="h5" component="div" gutterBottom>
                          Positions
                        </Typography>
                        <Link
                          component={NavLink}
                          to="/equipment/positions-list"
                        >
                          Edit positions
                          {
                            <EditIcon
                              fontSize="small"
                              sx={{
                                marginLeft: "5px",
                                verticalAlign: "middle",
                              }}
                            />
                          }
                        </Link>
                      </Box>

                      <TableContainer mt={3} className="tableContainer">
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              <TableCell padding="checkbox">
                                {/* <Checkbox color="primary" /> */}
                              </TableCell>
                              <TableCell>Department</TableCell>
                              <TableCell>Positions</TableCell>
                              <TableCell>Security Level</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {viewEquipDepList.map((row) => (
                              <TableRow key={row.pos_id} hover>
                                <TableCell>
                                  <Checkbox
                                    color="primary"
                                    padding="checkbox"
                                    checked={row.check == "Y" ? true : false}
                                    onClick={() => handleChangeEquipUser(row)}
                                  />
                                </TableCell>
                                <TableCell>{row.Department}</TableCell>
                                <TableCell>{row.Position}</TableCell>
                                <TableCell>{row.Security}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    component="span"
                    mt={6}
                    sx={{ width: "175px" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    mt={6}
                    sx={{ width: "175px", marginLeft: "5px" }}
                  >
                    Save
                  </Button>
                </Grid>
              </form>
            </CardContent>
          </Card>
        )}
      </Formik>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
      {equipUserModal && (
        <EquipmentUserDialog
          equipUserModal={equipUserModal}
          setEquipUserModal={setEquipUserModal}
          equipUserModalOpen={equipUserModalOpen}
          equipUserModalClose={equipUserModalClose}
          equipUserData={equipUserData}
          id={id}
          loadList={loadList}
          key_ring_id={key_ring_id}
          getEditEquipmentUser={getEditEquipmentUser}
          viewEquipDepartmentList={viewEquipDepartmentList}
        />
      )}
    </>
  );
}
function EditEquipment() {
  const { id } = useParams();
  return (
    <React.Fragment>
      <Helmet title="Edit Equipment" />
      <Grid container spacing={6}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h3" gutterBottom display="inline">
            Edit Equipment
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/equipment/equipment-list">
              Equipment
            </Link>
            <Typography>Edit Equipment</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} sm={6} textAlign="right" alignSelf="end">
          <Button
            color="primary"
            variant="outlined"
            component={NavLink}
            to={`/equipment/view-equipment-detail/${id}`}
          >
            View Equipment Detail
          </Button>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12} md={12}>
          <EditEquipmentForm />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default EditEquipment;
