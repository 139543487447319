import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@mui/styles";
import styled from "styled-components/macro";

import { getFileBase64 } from "../../../helper/helper";

import {
  Grid,
  Typography,
  Button,
  Tab,
  Box,
  Avatar as MuiAvatar,
} from "@mui/material";
import CameraFeeding from "../../../helper/camera-feeding";
import ImageCropper from "../../../components/form-components/ImageCropper";

const Avatar = styled(MuiAvatar)`
  width: 90px;
  height: 90px;
  object-fit: cover;
`;

const BigAvatar = styled(Avatar)`
  width: 180px;
  height: 180px;
  object-fit: cover;
  margin: 30px auto 0;
`;

// Classes Styling
const useStyles = makeStyles((theme) => ({
  accordion: {
    marginTop: 16,
    borderTop: 0,
    "& .MuiAccordion-root": {
      "& .MuiAccordionSummary-root": {
        padding: "0 12px",
        opacity: ".5",
      },
      "& .MuiAccordionDetails-root": {
        padding: "8px 12px 12px",
      },
    },
    "& .MuiAccordion-root::before": {
      display: "none",
    },
    "& .Mui-expanded": {
      minHeight: "auto",
      margin: "7px 0 7px",
    },
  },
  VerticalTab: {
    "& .MuiTabs-flexContainer": {
      display: "block",
      maxHeight: "300px",
      overflowY: "auto",
      overflowX: "hidden",
      paddingRight: "3px",

      "& .MuiTab-root": {
        display: "block",
        width: "105px",
        height: "105px",
        marginLeft: "auto",
        marginBottom: "8px",
        border: "1px solid",
        borderRadius: "10px",
        borderColor:
          theme.palette.mode === "light" ? "#e0e0e0" : theme.palette.grey[700],
      },
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .MuiTabs-flexContainer::-webkit-scrollbar": {
      width: 3,
      height: 3,
    },
    "& .MuiTabs-flexContainer::-webkit-scrollbar-thumb": {
      backgroundColor: "#e0e0e0",
    },
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));

const AddPoiImage = ({ title, filePath, uploadedImage, setUploadedImage }) => {
  const uploadedFileRef = useRef();
  const [showWebCam, setShowWebCam] = useState(false);

  const [imageCropperDialog, setImageCropperDialog] = useState(false);

  const imageCropperOpen = () => {
    setImageCropperDialog(true);
  };
  const imageCropperClose = () => {
    setImageCropperDialog(false);
  };

  const childToParent = (childData) => {
    setUploadedImage(childData);
  };

  const clearSelectedImage = () => {
    setUploadedImage(null);
    uploadedFileRef.current.value = "";
  };

  const handleShowWebCam = () => {
    let changeSetShowWebCam = !showWebCam;
    setShowWebCam(changeSetShowWebCam);
  };

  const handleFileChange = (evt) => {
    let file = evt.target.files[0];
    getFileBase64(file, (result) => {
      setUploadedImage(result);
      imageCropperOpen();
    });
  };

  useEffect(() => {}, []);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} md={9}>
          <Box>
            <Typography
              variant="h4"
              component="div"
              textAlign="center"
              gutterBottom
            >
              {title}
            </Typography>
            <BigAvatar
              alt="Remy Sharp"
              src={uploadedImage ? uploadedImage : filePath}
            />
          </Box>

          <Box
            style={{
              display: "flex",
              alignItems: "center",
              JustifyContent: "space-between",
              margin: "10px 0 15px",
              gap: "15px",
            }}
          >
            {/* <Button
              variant="outlined"
              color="primary"
              component="span"
              onClick={(e) => clearSelectedImage(e)}
              className="customChooseImage"
            >
              Clear
            </Button> */}
            {/* <Button
              variant="outlined"
              color="warning"
              component="span"
              onClick={() => handleShowWebCam()}
              className="customChooseImage"
            >
              {showWebCam ? "Close WebCam" : "WebCam"}
            </Button> */}
            {/* {showWebCam && (
              <Button
                variant="outlined"
                color="primary"
                component="span"
                onClick={() => takeImageToWebCam()}
              >
                Capture
              </Button>
            )} */}
          </Box>
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="raised-button-file"
            multiple
            type="file"
            ref={uploadedFileRef}
            onChange={(e) => handleFileChange(e)}
          />
          <label htmlFor="raised-button-file">
            <Button
              sx={{ marginLeft: { lg: "110px", sm: "100px", xs: "95px" } }}
              // sx={{ marginLeft: "110px" }}
              variant="contained"
              color="primary"
              component="span"
              className="customChooseImage"
            >
              Choose image
            </Button>
          </label>

          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
              marginTop: "15px",
            }}
          >
            <Button color="primary" variant="outlined">
              Clear
            </Button>
            <Button
              color="primary"
              variant="contained"
              sx={{ marginLeft: "10px", marginRight: "10px" }}
            >
              WebCam
            </Button>
            <Button color="primary" variant="outlined">
              Capture
            </Button>
          </Box>
          <Box sx={{ marginTop: "15px" }}>
            <input
              name="logoImage"
              accept="image/*"
              style={{ display: "none" }}
              id="raised-button-file"
              multiple
              type="file"
            />
            <label htmlFor="raised-button-file">
              <Button
                variant="contained"
                color="primary"
                component="span"
                mt={3}
              >
                Choose Image
              </Button>
            </label>
          </Box> */}
        </Grid>
      </Grid>
      {showWebCam && (
        <CameraFeeding
          showWebCam={showWebCam}
          closeWabCam={() => setShowWebCam(false)}
          // value={uploadedImage}
          // onChange={handleFileChange}
          setUploadedImage={setUploadedImage}
          uploadedImage={uploadedImage}
        />
      )}
      {title == "Front Face (Autocrop)" &&
        uploadedImage &&
        imageCropperDialog && (
          <ImageCropper
            imageCropperDialog={imageCropperDialog}
            setImageCropperDialog={setImageCropperDialog}
            imageCropperOpen={imageCropperOpen}
            imageCropperClose={imageCropperClose}
            childToParent={childToParent}
            imageData={uploadedImage}
            dialogHead="Crop Face"
          />
        )}
      {uploadedImage && imageCropperDialog && (
        <ImageCropper
          imageCropperDialog={imageCropperDialog}
          setImageCropperDialog={setImageCropperDialog}
          imageCropperOpen={imageCropperOpen}
          imageCropperClose={imageCropperClose}
          childToParent={childToParent}
          imageData={uploadedImage}
          // dialogHead="Crop Face"
        />
      )}
    </>
  );
};

export default AddPoiImage;
