import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import ActivityAlertDialog from "./ActivityAlertDialog";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import BarChart from "./BarChart";
import { BarChartData } from "../../../api";

const ShowChart = ({ showChartOpen, showChartClose }) => {
  const [chartData, setChartData] = useState([]);
  const [drillDownChartData, setDrillDownChartData] = useState([]);
  // Modal Activity Alerts
  const [activityAlerts, setActivityAlerts] = useState(false);
  const activityAlertOpen = () => {
    setActivityAlerts(true);
  };
  const activityAlertClose = () => {
    setActivityAlerts(false);
  };

  useEffect(() => {
    BarChartData()
      .then((res) => {
        setChartData(res.data.finalArr);
        setDrillDownChartData(res.data.drillArr);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "700px",
            },
          },
        }}
        aria-labelledby="showchart-title"
        aria-describedby="showchart-description"
        open={showChartOpen}
        onClose={showChartClose}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="showchart-title"
        >
          <Typography variant="h4">Daily Activity by Hour</Typography>
          <DialogActions>
            <IconButton onClick={showChartClose} sx={{ marginRight: "-10px" }}>
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <BarChart
            chartData={chartData}
            drillDownChartData={drillDownChartData}
          />
          {/* <Typography>Chart goes here</Typography> */}
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={showChartClose}>
            Close
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={activityAlertOpen}
          >
            Activity Alerts
          </Button>
        </DialogActions>
      </Dialog>
      {activityAlerts && (
        <ActivityAlertDialog
          activityAlerts={activityAlerts}
          setActivityAlerts={setActivityAlerts}
          activityAlertOpen={activityAlertOpen}
          activityAlertClose={activityAlertClose}
        />
      )}
    </>
  );
};

export default ShowChart;
