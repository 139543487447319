import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { Formik, useFormikContext } from "formik";
import CustomTextField from "../../components/form-components/CustomTextField";
import CustomSelect from "../../components/form-components/CustomSelect";
import CustomSelection from "../../components/form-components/CustomSelection";
import { makeStyles } from "@mui/styles";
import CustomDateTimePicker from "../../components/form-components/CustomDateTimePicker";
import PropTypes from "prop-types";
import References from "./inner-components/References";
import AddLocationOnMap from "./inner-components/AddLocationOnMap";
import AddPersonInvolved from "./inner-components/AddPersonInvolved";
import VehicleInvolved from "./inner-components/VehicleInvolved";
import WitnessStatement from "./inner-components/WitnessStatement";
import MediaUpload from "./inner-components/MediaUpload";
import IncidentMediaList from "./inner-components/IncidentMediaList";
import AddUseOfForce from "./inner-components/AddUseOfForce";
import ReactQuill from "react-quill";

import {
  Grid,
  Typography,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Link,
  TextField,
  Button,
  Checkbox,
  Radio,
  RadioGroup,
  FormControl as MuiFormControl,
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  FormGroup,
  FormLabel,
  FormHelperText,
  FormControlLabel,
  ToggleButtonGroup,
  ToggleButton,
  Tabs,
  InputAdornment,
  Tab,
  Box,
  Table,
  TableContainer,
  IconButton,
  TableCell,
  ButtonGroup,
  TableHead,
  TableRow,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TableBody,
  Menu,
  Paper,
  Tooltip,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  CardActions,
  TableFooter,
} from "@mui/material";
import {
  Undo as UndoIcon,
  Redo as RedoIcon,
  FormatBold as FormatBoldIcon,
  FormatUnderlined as FormatUnderlinedIcon,
  LocalPrintshop as LocalPrintshopIcon,
  Restore as RestoreIocn,
  FullscreenExit as FullscreenExitIcon,
  Code as CodeIcon,
  AddBox as AddIcon,
  Person as PersonIcon,
  Delete as DeleteIcon,
  Settings as SettingsIcon,
  Edit as EditIcon,
  Error as ErrorIcon,
  ExpandMore as ExpandMoreIcon,
  FilterList as FilterListIcon,
  ControlPointDuplicate as ControlPointDuplicateIcon,
  Build as BuildIcon,
} from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";
import { DateTimePicker, TimePicker } from "@mui/lab";
import { spacing } from "@mui/system";
import * as Yup from "yup";
import {
  IncidentTypeListDropdown,
  SaveIncidentReport,
  InvolvedPersonListing,
  AddIncident,
  DeleteInvolvedPerson,
  InvolvedVehicleListing,
  GetInvolvedVehicleData,
  GetInvolvedPersonData,
  DeleteInvolvedVehicle,
  InvolvedWitnessListing,
  GetInvolvedWitnessData,
  DeleteInvolvedWitness,
  GetIncidentReportData,
  GetMediaList,
  IncidentUserList,
  GetInvolvedFinanceData,
  DeleteInvolvedFinance,
  FinancialListing,
} from "../../api";
import { DatePicker } from "@mui/lab";
import CheckboxWrapper from "../accounts/components/Checkbox";
import Toaster from "../tasks/Toaster";
import { useDispatch, useSelector } from "react-redux";
import {
  getSnackOpen,
  getSnackClose,
  getSnackMsg,
  getSnackAlert,
} from "../../redux/slices/reportSlice";
import MUIRichTextEditor from "mui-rte";
import SignatureCanvas from "react-signature-canvas";
import CommunicationLog from "./inner-components/CommunicationLog";
import AddAppendedNarrative from "./inner-components/AddAppendedNarrative";
import InvestigativeReport from "./inner-components/InvestigativeReport";
import AddEditFinancialReport from "./inner-components/AddEditFinancialReport";
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
// ThumbnailTableWrapper
const ThumbnailTableWrapper = styled.div`
  height: 100px;
  width: 100px;
`;
// Image
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 200px;
  }
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledTooltip = ({ title, children, ...props }) => (
  <Tooltip
    {...props}
    title={title}
    placement="top-end"
    /* arrow */
    componentsProps={{
      tooltip: {
        sx: {
          lineHeight: "1.6",
          fontSize: "10px",
          marginRight: "-15px",
          maxWidth: "270px",
          padding: 0,
          background: "transparent",
        },
      },
    }}
  >
    {children}
  </Tooltip>
);
const Accordion = styled(MuiAccordion)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
`;
// Classes Styling
const useStyles = makeStyles((theme) => ({
  accordion: {
    borderTop: 0,
    "& .MuiAccordion-root": {
      "& .MuiAccordionSummary-root": {
        padding: "0 12px",
        opacity: ".5",
      },
      "& .MuiAccordionDetails-root": {
        padding: "8px 12px 12px",
      },
    },
    "& .MuiAccordion-root::before": {
      display: "none",
    },
    "& .Mui-expanded": {
      minHeight: "auto",
      margin: "7px 0 7px",
    },
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
  textEditor: {
    position: "relative",
    border: "1px solid",
    borderColor:
      theme.palette.mode === "light" ? "rgb(215 215 215)" : "#56606f",
    borderRadius: "4px",
    "& #mui-rte-container": {
      marginTop: 0,
    },
    "& .MUIRichTextEditor-editorContainer-10": {
      position: "absolute",
      padding: "15px",
      display: "block",
    },
    "& #mui-rte-editor": {
      height: "180px",
      overflowY: "auto",
    },
    "& #mui-rte-editor-container": {
      height: "170px",
      overflowY: "auto",
      borderTop: "1px solid",
      borderColor:
        theme.palette.mode === "light" ? "rgb(215 215 215)" : "#56606f",
    },
    "& #mui-rte-toolbar": {
      padding: "5px 15px",
    },
    "& .DraftEditor-editorContainer": {
      padding: "15px",
    },
  },
}));
const AndormentFormControl = styled((props) => <FormControl {...props} />)(
  ({ theme }) => ({
    position: "relative",
    marginTop: "30px",
    "& .MuiBox-root": {
      backgroundColor: theme.palette.mode === "light" ? "#ffffff" : "#233044",
      padding: "0 0px 0 5px",
      left: 10,
      position: "absolute",
      top: "-15px",
      zIndex: "1",
      "& .MuiFormLabel-root": {
        fontSize: "0.75em",
      },
      "& .MuiIconButton-root": {
        marginLeft: "5px",
        padding: "4px",
      },
    },
    "& .MuiAutocomplete-inputRoot": {
      paddingTop: "24px",
    },
  })
);
const Divider = styled(MuiDivider)(spacing);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function incidentTab(index) {
  return {
    id: `incident-tab-${index}`,
    "aria-controls": `incident-tabpanel-${index}`,
  };
}
function EditIncidentReportForm(props) {
  const [narrativebtn, setNarrativeBtn] = React.useState("undobtn");
  const snackbarStatus = useSelector((state) => state.report.snackStatus);
  const snackbarMsg = useSelector((state) => state.report.snackMsg);
  const snackbarAlert = useSelector((state) => state.report.snackAlert);
  const dispatch = useDispatch();
  const [PIListing, setPIListing] = useState([]);
  const [VIListing, setVIListing] = useState([]);
  const [WIListing, setWIListing] = useState([]);
  const [mediaListing, setMediaListing] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageLoad, setPageLoad] = useState(false);
  const navigate = useNavigate();
  const [involvedPerId, setInvolvedPerId] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [tabStatus, setTabStatus] = useState("");
  const [vehicleInvolvedId, setVehicleInvolvedId] = useState(null); //state for getting the vehicle id
  const [vehicleData, setVehicleData] = useState({});
  const [vehicleActionStatus, setVehicleActionStatus] = useState(false);
  const [personActionStatus, setPersonActionStatus] = useState(false);
  const [editPIData, setEditPIData] = useState({});
  const [editWIData, setEditWIData] = useState({});
  const [involvedWitnessID, setInvolvedWitnessID] = useState(null);
  const [witnessActionStatus, setWitnessActionStatus] = useState(false);
  const [editableData, setEditableData] = useState({});
  const [financeActionstatus, setFinanceActionstatus] = useState(false);
  const [financeViewActionstatus, setFinanceViewActionstatus] = useState(false);
  const [editFIData, setEditFIData] = useState({});
  const [FIListing, setFIListing] = useState([]);
  const [narrative, setNarrative] = useState("");
  const [financialInvolvedID, setFinancialInvolvedID] = useState(null);
  const { id } = useParams(); //Getting the edit user Id
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);

  const [financialReportDialog, setFinancialReportDialog] = useState(false);
  const financialReportDialogOpen = () => {
    setFinancialReportDialog(true);
  };
  const financialReportDialogClose = () => {
    setFinancialReportDialog(false);
    setEditFIData({});
  };

  const [financialActionDropdown, setFinancialActionDropdown] =
    React.useState(null);
  const financialActionDropdownClick = (event, finId) => {
    setFinancialActionDropdown(event.currentTarget);
    setFinancialInvolvedID(finId);
  };
  const financialActionDropdownClose = () => {
    setFinancialActionDropdown(null);
  };

  //Methods related to Financial information
  //Method for showing listing for financial info
  const getInvolvedFinanceListing = () => {
    if (incidentId) {
      setLoading(true);
      FinancialListing(incidentId)
        .then((res) => {
          if (res.statusCode == 200) {
            console.log("++++", res.data);
            setFIListing(res.data);
          } else {
            setFIListing([]);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };
  //Method for getting the financial info for edit
  const getFinanceInvolved = () => {
    setPageLoad(true);
    GetInvolvedFinanceData(financialInvolvedID)
      .then((res) => {
        setPageLoad(false);
        if (res.statusCode === 200) {
          setEditFIData(res.data);
        }
      })
      .catch((err) => {
        setPageLoad(false);
        console.log(err);
      });
  };

  //Function for deleteing involved vehicle
  const DeleteFinanceInvolved = () => {
    setPageLoad(true);
    DeleteInvolvedFinance(financialInvolvedID)
      .then((res) => {
        if (res.statusCode == 200) {
          setPageLoad(false);
          dispatch(getSnackMsg("Record has been deleted successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          getInvolvedFinanceListing();
          // handleDeleteClose();
        } else {
          setPageLoad(false);
          // handleDeleteClose();
          dispatch(getSnackMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
        }
      })
      .catch((err) => {
        setPageLoad(false);
        console.log(err);
      });
  };
  //Handler for editing finance involved
  const editFinance = () => {
    getFinanceInvolved();
    financialActionDropdownClose();
    financialReportDialogOpen();
    setFinanceActionstatus(false);
  };

  //Handler for view finance involved
  const viewFinance = () => {
    getFinanceInvolved();
    financialActionDropdownClose();
    financialReportDialogOpen();
    setFinanceActionstatus(true);
    setFinanceViewActionstatus(true);
  };

  let totalCost = FIListing.map((it) => parseFloat(it.cost)).reduce(
    (it, acc) => it + acc,
    0
  );
  //method for close the snackbar
  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(getSnackClose());
  };
  const handleNarrativeBtn = (event, newNarrativeBtn) => {
    setNarrativeBtn(newNarrativeBtn);
  };
  const [customValidation, setCustomValidation] = useState({
    incidentClass: "",
    irStatus: "",
    startDateTime: "",
    endDateTime: "",
    reportedDateTime: "",
    incidentLocation: "",
    summaryNar: "",
  });
  const [incidentId, setIncidentId] = useState(id);
  /* Tabs */
  const [tabvalue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  // Reference Modal
  const [reference, setReference] = React.useState(false);
  const referenceOpen = () => {
    setReference(true);
  };
  const referenceClose = () => {
    setReference(false);
  };

  //Ref for signature pad
  const sigCanvas = useRef({});
  const [signImage, setSignImage] = useState(null);
  const clearPad = () => {
    sigCanvas.current.clear();
    setSignImage(null);
  };
  const savePad = () => {
    setSignImage(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
  };
  //Functions for text editors
  const [editorTextNarrative, setEditorTextNarrative] = useState("");
  const onEditorNarrativeChange = (e) => {
    setEditorTextNarrative(e.getCurrentContent().getPlainText());
  };
  const [editorTextMedical, setEditorTextMedical] = useState("");
  const onEditorMedicalChange = (e) => {
    setEditorTextMedical(e.getCurrentContent().getPlainText());
  };
  const [editorTextInjury, setEditorTextInjury] = useState("");
  const onEditorInjuryChange = (e) => {
    setEditorTextInjury(e.getCurrentContent().getPlainText());
  };
  const [editorTextPatient, setEditorTextPatient] = useState("");
  const onEditorPatientChange = (e) => {
    setEditorTextPatient(e.getCurrentContent().getPlainText());
  };
  const initialValues = {
    IncidentReportName: editableData
      ? editableData.IncidentReportName
        ? editableData.IncidentReportName
        : ""
      : "",
    report_template: editableData
      ? editableData.ir_type
        ? editableData.ir_type
        : "general_incident_report"
      : "general_incident_report",
    incident_class: editableData
      ? editableData.IncidentTypeID
        ? editableData.IncidentTypeID
        : ""
      : "",
    ir_status: editableData
      ? editableData.ir_status
        ? editableData.ir_status
        : "Open"
      : "Open",
    start_datetime: editableData
      ? editableData.start_time
        ? editableData.start_time
        : new Date()
      : new Date(),
    end_datetime: editableData
      ? editableData.end_time
        ? editableData.end_time
        : new Date()
      : new Date(),
    reported_datetime: editableData
      ? editableData.IncidentTimeReported
        ? editableData.IncidentTimeReported
        : new Date()
      : new Date(),
    show_map: false,
    incident_location: editableData
      ? editableData.IncidentLocation
        ? editableData.IncidentLocation
        : ""
      : "",
    summary_narrative: editableData
      ? editableData.SummaryDescription
        ? editableData.SummaryDescription
        : ""
      : "",
    narrative: editableData
      ? editableData.Description
        ? editableData.Description
        : ""
      : "",
    //  narrative: "Hello",
    alleged_incident_contribution: editableData
      ? editableData.ConditionsHazards
        ? editableData.ConditionsHazards
        : ""
      : "",
    // incident_contribution: "",
    security_camera_preserved: editableData
      ? editableData.security_camera_image
        ? editableData.security_camera_image
        : "N/A"
      : "N/A",
    police_contacted: editableData
      ? editableData.PoliceContact
        ? editableData.PoliceContact
        : "No"
      : "No",
    officer_name: editableData
      ? editableData.OfficerName
        ? editableData.OfficerName
        : ""
      : "",
    officer_email: editableData
      ? editableData.OfficerEmail
        ? editableData.OfficerEmail
        : ""
      : "",
    badge_number: editableData
      ? editableData.OfficerBadge
        ? editableData.OfficerBadge
        : ""
      : "",
    preport_number: editableData
      ? editableData.PoliceReportNo
        ? editableData.PoliceReportNo
        : ""
      : "",
    fire_dept_called: editableData
      ? editableData.fire_department
        ? editableData.fire_department
        : "No"
      : "No",
    engine_number: editableData
      ? editableData.fire_department_engine
        ? editableData.fire_department_engine
        : ""
      : "",
    fire_dept_arr_time: editableData
      ? editableData.fire_dept_arrival_time
        ? editableData.fire_dept_arrival_time
        : null
      : null,
    fire_dept_departure_time: editableData
      ? editableData.fire_dept_departure_time
        ? editableData.fire_dept_departure_time
        : null
      : null,
    fire_dept_captain_name: editableData
      ? editableData.captain_name
        ? editableData.captain_name
        : ""
      : "",
    ambulance_called: editableData
      ? editableData.VicHosp
        ? editableData.VicHosp
        : "No"
      : "No",
    ambulance_info: editableData
      ? editableData.VicHospAName
        ? editableData.VicHospAName
        : ""
      : "",
    anyone_else_contacted: editableData
      ? editableData.OtherNameContacted
        ? editableData.OtherNameContacted
        : "No"
      : "No",
    anyone_else_contacted_info: editableData
      ? editableData.OtherNameContactedList
        ? editableData.OtherNameContactedList
        : ""
      : "",
    anyone_hospitalized: editableData
      ? editableData.HospName
        ? editableData.HospName
        : "No"
      : "No",
    hospital_name: editableData
      ? editableData.HospNameList
        ? editableData.HospNameList
        : ""
      : "",
    doctor_name: editableData
      ? editableData.hospital_doc_name
        ? editableData.hospital_doc_name
        : ""
      : "",
    claims_adjuster_contacted: editableData
      ? editableData.ClaimsAdjContacted
        ? editableData.ClaimsAdjContacted
        : "No"
      : "No",
    claim_adjuster_info: editableData
      ? editableData.ClaimsAdjContactedName
        ? editableData.ClaimsAdjContactedName
        : ""
      : "",
    // Initial states for 2nd category
    last_meal_status: editableData
      ? editableData.meal_location
        ? editableData.meal_location
        : ""
      : "",
    symptoms: editableData
      ? editableData.symptoms
        ? editableData.symptoms
        : ""
      : "",
    reported_by: editableData
      ? editableData.reported_by
        ? editableData.reported_by
        : ""
      : "",
    sick_person_relationship: editableData
      ? editableData.relation_w_sick_person
        ? editableData.relation_w_sick_person
        : ""
      : "",
    provide_doc_ins: editableData
      ? editableData.previous_instructions
        ? editableData.previous_instructions
        : ""
      : "",
    physician_contacted: editableData
      ? editableData.physician_contacted
        ? editableData.physician_contacted
        : ""
      : "",
    physician_name: editableData
      ? editableData.physician_name
        ? editableData.physician_name
        : ""
      : "",
    chef_contacted: editableData
      ? editableData.chef_contacted
        ? editableData.chef_contacted
        : "No"
      : "No",
    chef_name: editableData
      ? editableData.chef_contacted_name
        ? editableData.chef_contacted_name
        : ""
      : "",
    follow_required: editableData
      ? editableData.followup_required
        ? editableData.followup_required
        : "No"
      : "No",
    guest_called_back_min: editableData
      ? editableData.callback_30_mins
        ? editableData.callback_30_mins
        : "No"
      : "No",
    guest_called_min_date: editableData
      ? editableData.callback_30_mins_datetime
        ? editableData.callback_30_mins_datetime
        : null
      : null,
    person_spoken_with_min: editableData
      ? editableData.callback_30_mins_person
        ? editableData.callback_30_mins_person
        : ""
      : "",
    guest_called_back_days: editableData
      ? editableData.callback_2_days
        ? editableData.callback_2_days
        : "No"
      : "No",
    guest_called_days_date: editableData
      ? editableData.callback_2_days_datetime
        ? editableData.callback_2_days_datetime
        : null
      : null,
    person_spoken_with_days: editableData
      ? editableData.callback_2_days_person
        ? editableData.callback_2_days_person
        : ""
      : "",
    check_food_items: editableData
      ? editableData.food_item_ques == "1"
        ? true
        : false
      : false,
    comment_about_food_item: editableData
      ? editableData.fitem_comment_item
        ? editableData.fitem_comment_item
        : ""
      : "",
    investigate_chef_servers: editableData
      ? editableData.investigation_with_cooks == "1"
        ? true
        : false
      : false,
    comment_about_investigation: editableData
      ? editableData.investigation_comment_item
        ? editableData.investigation_comment_item
        : ""
      : "",
    inspect_hygiene: editableData
      ? editableData.review_sanitation == "1"
        ? true
        : false
      : false,
    comment_about_hygiene: editableData
      ? editableData.sanitation_comment_item
        ? editableData.sanitation_comment_item
        : ""
      : "",
    //Initial states for 3rd category
    facility_name: editableData
      ? editableData.aed_faculty_name
        ? editableData.aed_faculty_name
        : ""
      : "",
    address_line_1: editableData
      ? editableData.aed_address_1
        ? editableData.aed_address_1
        : ""
      : "",
    address_line_2: editableData
      ? editableData.aed_address_2
        ? editableData.aed_address_2
        : ""
      : "",
    add_city: editableData
      ? editableData.aed_city
        ? editableData.aed_city
        : ""
      : "",
    add_state: editableData
      ? editableData.aed_state
        ? editableData.aed_state
        : ""
      : "",
    add_zip: editableData
      ? editableData.aed_zip
        ? editableData.aed_zip
        : ""
      : "",
    date_of_incident: editableData
      ? editableData.aed_incident_date
        ? editableData.aed_incident_date
        : null
      : null,
    estimated_time_of_incident: editableData
      ? editableData.aed_incident_time
        ? editableData.aed_incident_time
        : null
      : null,
    estimated_time_of_911: editableData
      ? editableData.aed_911_time
        ? editableData.aed_911_time
        : null
      : null,
    patient_fname: editableData
      ? editableData.aed_patient_fname
        ? editableData.aed_patient_fname
        : ""
      : "",
    patient_mname: editableData
      ? editableData.aed_patient_mname
        ? editableData.aed_patient_mname
        : ""
      : "",
    patient_lname: editableData
      ? editableData.aed_patient_lname
        ? editableData.aed_patient_lname
        : ""
      : "",
    patient_gender: editableData
      ? editableData.aed_p_gender
        ? editableData.aed_p_gender
        : ""
      : "",
    patient_age: editableData
      ? editableData.aed_p_age
        ? editableData.aed_p_age
        : ""
      : "",
    patient_unresponsive: editableData
      ? editableData.aed_p_collapse
        ? editableData.aed_p_collapse
        : ""
      : "",
    events_prior_collapse: editableData
      ? editableData.aed_collapse_event
        ? editableData.aed_collapse_event
        : []
      : [],
    patient_collapse_witness: editableData
      ? editableData.aed_person_see_collapse
        ? editableData.aed_person_see_collapse
        : ""
      : "",
    patient_collapse_witness_name: editableData
      ? editableData.collapse_present_person_name
        ? editableData.collapse_present_person_name
        : ""
      : "",
    aed_employee: editableData
      ? editableData.aed_person_aed_employee
        ? editableData.aed_person_aed_employee
        : ""
      : "",
    circulation_check: editableData
      ? editableData.aed_circulation
        ? editableData.aed_circulation
        : ""
      : "",
    pulse_check: editableData
      ? editableData.aed_pulse_checked
        ? editableData.aed_pulse_checked
        : ""
      : "",
    have_pulse: editableData
      ? editableData.aed_pulse_person
        ? editableData.aed_pulse_person
        : ""
      : "",
    cpr_given: editableData
      ? editableData.cpr_prior_911_ems_arrived
        ? editableData.cpr_prior_911_ems_arrived
        : ""
      : "",
    estimated_time_cpr: editableData
      ? editableData.cpr_started_time
        ? editableData.cpr_started_time
        : null
      : null,
    cpr_started_prior_aed: editableData
      ? editableData.cpr_aed_employee
        ? editableData.cpr_aed_employee
        : ""
      : "",
    who_started_cpr: editableData
      ? editableData.who_started_cpr
        ? editableData.who_started_cpr
        : ""
      : "",
    cpr_person_name: editableData
      ? editableData.name_cpr_started
        ? editableData.name_cpr_started
        : ""
      : "",
    patient_side_prior_ems: editableData
      ? editableData.aed_prior_to_ems
        ? editableData.aed_prior_to_ems
        : ""
      : "",
    reason_ems_arrival: editableData
      ? editableData.why_no_prior
        ? editableData.why_no_prior
        : ""
      : "",
    est_time_cpr_aed_arrive_patient: editableData
      ? editableData.aed_brought_time
        ? editableData.aed_brought_time
        : null
      : null,
    aed_manufacturer: editableData
      ? editableData.aed_manufacturer
        ? editableData.aed_manufacturer
        : ""
      : "",
    aed_model_number: editableData
      ? editableData.aed_model_number
        ? editableData.aed_model_number
        : ""
      : "",
    aed_serial_number: editableData
      ? editableData.aed_serial_number
        ? editableData.aed_serial_number
        : ""
      : "",
    aed_storage_location: editableData
      ? editableData.aed_storage_location
        ? editableData.aed_storage_location
        : ""
      : "",
    aed_pads_placed: editableData
      ? editableData.aed_pads_used_patient
        ? editableData.aed_pads_used_patient
        : ""
      : "",
    aed_pads_served_by: editableData
      ? editableData.who_applied_pads
        ? editableData.who_applied_pads
        : ""
      : "",
    facility_aed_turned_on: editableData
      ? editableData.aed_turned_on
        ? editableData.aed_turned_on
        : ""
      : "",
    est_time_aed_turned_on: editableData
      ? editableData.aed_turnon_time
        ? editableData.aed_turnon_time
        : null
      : null,
    aed_ever_shocked_patient: editableData
      ? editableData.aed_shock_patient
        ? editableData.aed_shock_patient
        : ""
      : "",
    est_time_first_shock: editableData
      ? editableData.aed_shock_first_time
        ? editableData.aed_shock_first_time
        : null
      : null,
    number_of_shocks: editableData
      ? editableData.number_shock_given
        ? editableData.number_shock_given
        : ""
      : "",
    aed_fname: editableData
      ? editableData.person_operating_aed_fname
        ? editableData.person_operating_aed_fname
        : ""
      : "",
    aed_mname: editableData
      ? editableData.person_operating_aed_mname
        ? editableData.person_operating_aed_mname
        : ""
      : "",
    aed_lname: editableData
      ? editableData.person_operating_aed_lname
        ? editableData.person_operating_aed_lname
        : ""
      : "",
    is_trained_aed: editableData
      ? editableData.aed_person_trained_emp
        ? editableData.aed_person_trained_emp
        : ""
      : "",
    med_training_level: editableData
      ? editableData.medical_training_level
        ? editableData.medical_training_level
        : ""
      : "",
    aed_mechanical_failure: editableData
      ? editableData.aed_mechanical_difficulty
        ? editableData.aed_mechanical_difficulty
        : ""
      : "",
    unexpected_injuries_aed: editableData
      ? editableData.unexpected_event_injury
        ? editableData.unexpected_event_injury
        : ""
      : "",
    pulse_restored: editableData
      ? editableData.pulse_restored
        ? editableData.pulse_restored
        : ""
      : "",
    time_pulse_restored: editableData
      ? editableData.time_pulse_restored
        ? editableData.time_pulse_restored
        : null
      : null,
    breathing_restored: editableData
      ? editableData.breath_restored
        ? editableData.breath_restored
        : ""
      : "",
    time_breathing_restored: editableData
      ? editableData.time_breath_restored
        ? editableData.time_breath_restored
        : null
      : null,
    responsiveness_restored: editableData
      ? editableData.responsive_restored
        ? editableData.responsive_restored
        : ""
      : "",
    time_responsiveness_restored: editableData
      ? editableData.time_responsive_restored
        ? editableData.time_responsive_restored
        : null
      : null,
    sign_of_circulation: editableData
      ? editableData.circulation_sign
        ? editableData.circulation_sign
        : ""
      : "",
    time_sign_of_circulation: editableData
      ? editableData.time_circulation_sign
        ? editableData.time_circulation_sign
        : null
      : null,
    patient_trans_hospital: editableData
      ? editableData.patient_transported_hosp
        ? editableData.patient_transported_hosp
        : ""
      : "",
    mode_of_trans_hospital: editableData
      ? editableData.how_patient_transported_hosp
        ? editableData.how_patient_transported_hosp
        : ""
      : "",
    hopital_name: editableData
      ? editableData.hospital_name
        ? editableData.hospital_name
        : ""
      : "",
    attending_physician: editableData
      ? editableData.attending_physician
        ? editableData.attending_physician
        : ""
      : "",
    aed_remains: editableData
      ? editableData.aed_remain_facility
        ? editableData.aed_remain_facility
        : ""
      : "",
    aed_remains_reason: editableData
      ? editableData.aed_remain_facility_reason
        ? editableData.aed_remain_facility_reason
        : ""
      : "",
    memory_device_remains: editableData
      ? editableData.memory_facility
        ? editableData.memory_facility
        : ""
      : "",
    memory_device_remains_reason: editableData
      ? editableData.memory_facility_reason
        ? editableData.memory_facility_reason
        : ""
      : "",
    aed_removed: editableData
      ? editableData.aed_removed
        ? editableData.aed_removed
        : ""
      : "",
    report_completed_by_fname: editableData
      ? editableData.report_by_fname
        ? editableData.report_by_fname
        : ""
      : "",
    report_completed_by_lname: editableData
      ? editableData.report_by_lname
        ? editableData.report_by_lname
        : ""
      : "",
    report_title: editableData
      ? editableData.reporter_title
        ? editableData.reporter_title
        : ""
      : "",
    //Initial state for last section
    anyone_injured: editableData
      ? editableData.anyone_injured
        ? editableData.anyone_injured
        : "No"
      : "No",
    supplier_detail: editableData
      ? editableData.manufacturer_supplier
        ? editableData.manufacturer_supplier
        : ""
      : "",
    destroy_sign: editableData
      ? editableData.do_not_destroy
        ? editableData.do_not_destroy
        : ""
      : "",
    alcohol_involved: editableData
      ? editableData.drugs_involved
        ? editableData.drugs_involved
        : ""
      : "",
    witness_incident: editableData
      ? editableData.incident_witnessed
        ? editableData.incident_witnessed
        : ""
      : "",
    witness_person: editableData
      ? editableData.witness_name
        ? editableData.witness_name
        : ""
      : "",
    witness_address: editableData
      ? editableData.witness_address
        ? editableData.witness_address
        : ""
      : "",
    witness_city: editableData
      ? editableData.witness_city
        ? editableData.witness_city
        : ""
      : "",
    witness_state: editableData
      ? editableData.witness_state
        ? editableData.witness_state
        : ""
      : "",
    witness_zip: editableData
      ? editableData.witness_zip
        ? editableData.witness_zip
        : ""
      : "",
    witness_phone: editableData
      ? editableData.witness_phone
        ? editableData.witness_phone
        : ""
      : "",
    weather_conditions: editableData
      ? editableData.weather_condition
        ? editableData.weather_condition
        : ""
      : "",
    lighting_conditions: editableData
      ? editableData.lighting_condition
        ? editableData.lighting_condition
        : ""
      : "",
    private_notes: editableData
      ? editableData.private_notes
        ? editableData.private_notes
        : ""
      : "",
    icc_classification: editableData
      ? editableData.icc_value
        ? editableData.icc_value
        : ""
      : "",
    reporting_officer: editableData
      ? editableData.UserID
        ? editableData.UserID
        : 0
      : "",
    other_name: editableData
      ? editableData.other_name
        ? editableData.other_name
        : ""
      : "",
  };

  // Validat5ion schema

  const validationSchema = Yup.object().shape({
    report_template: Yup.string().required("Please select report template"),
    incident_class: Yup.string().required("Please select incident class"),
    ir_status: Yup.string().required("Please select ir status"),
    start_datetime: Yup.string().required("Please select start date/time"),
    end_datetime: Yup.string().required("Please select end date/time"),
    reported_datetime: Yup.string().required(
      "Please select reported date/time"
    ),
    incident_location: Yup.string().required("Please enter the venue"),
    summary_narrative: Yup.string().required(
      "Please enter the summary narrative"
    ),
    officer_name: Yup.string().when(["police_contacted"], (same, schema) => {
      return same === "No"
        ? schema
        : schema.required("Please enter the officer name");
    }),
    officer_email: Yup.string()
      .email("Please enter a valid email address")
      .when(["police_contacted"], (same, schema) => {
        return same === "No"
          ? schema
          : schema.required("Please enter email address");
      }),
    badge_number: Yup.string().when(["police_contacted"], (same, schema) => {
      return same === "No"
        ? schema
        : schema.required("Please enter the badge number");
    }),
    preport_number: Yup.string().when(["police_contacted"], (same, schema) => {
      return same === "No"
        ? schema
        : schema.required("Please enter the report number");
    }),
    fire_dept_arr_time: Yup.string()
      .nullable()
      .when(["fire_dept_called"], (same, schema) => {
        return same === "No"
          ? schema
          : schema.required("Please select arrival date/time");
      }),

    fire_dept_departure_time: Yup.string()
      .nullable()
      .when(["fire_dept_called"], (same, schema) => {
        return same === "No"
          ? schema
          : schema.required("Please select departure date/time");
      }),
    fire_dept_captain_name: Yup.string().when(
      ["fire_dept_called"],
      (same, schema) => {
        return same === "No"
          ? schema
          : schema.required("Please enter the captain name");
      }
    ),
    icc_classification: Yup.string().required("Please select the ICC"),
    address_line_1: Yup.string().when(["report_template"], (same, schema) => {
      return same === "general_incident_report" || same === "food_illness"
        ? schema
        : schema.required("Please enter the address");
    }),
    address_line_2: Yup.string().when(["report_template"], (same, schema) => {
      return same === "general_incident_report" || same === "food_illness"
        ? schema
        : schema.required("Please enter the address");
    }),

    add_city: Yup.string().when(["report_template"], (same, schema) => {
      return same === "general_incident_report" || same === "food_illness"
        ? schema
        : schema.required("Please enter the city");
    }),

    add_state: Yup.string().when(["report_template"], (same, schema) => {
      return same === "general_incident_report" || same === "food_illness"
        ? schema
        : schema.required("Please enter the state");
    }),

    add_zip: Yup.string().when(["report_template"], (same, schema) => {
      return same === "general_incident_report" || same === "food_illness"
        ? schema
        : schema.required("Please enter the zip code");
    }),

    date_of_incident: Yup.string()
      .nullable()
      .when(["report_template"], (same, schema) => {
        return same === "general_incident_report" || same === "food_illness"
          ? schema
          : schema.required("Please select the date of incident");
      }),
    //alleged_incident_contribution: Yup.string().required("Please enter"),
  });

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setPageLoad(true);
      console.log("++++++MY FORM VALUES +++++", values);
      let formatedStartTime = values.start_datetime
        ? moment(values.start_datetime).format("YYYY-MM-DD HH:mm:ss")
        : "";
      let formatedEndTime = values.end_datetime
        ? moment(values.end_datetime).format("YYYY-MM-DD HH:mm:ss")
        : "";
      let formatedReportedTime = values.reported_datetime
        ? moment(values.reported_datetime).format("YYYY-MM-DD HH:mm:ss")
        : "";
      let formatedFireArrTime = values.fire_dept_arr_time
        ? moment(values.fire_dept_arr_time).format("YYYY-MM-DD HH:mm:ss")
        : "";
      let formatedFireDptTime = values.fire_dept_departure_time
        ? moment(values.fire_dept_departure_time).format("YYYY-MM-DD HH:mm:ss")
        : "";
      let formatedCbMin = values.guest_called_min_date
        ? moment(values.guest_called_min_date).format("YYYY-MM-DD HH:mm:ss")
        : "";
      let formatedCbDays = values.guest_called_days_date
        ? moment(values.guest_called_days_date).format("YYYY-MM-DD HH:mm:ss")
        : "";
      let formatedDOIncident = values.date_of_incident
        ? moment(values.date_of_incident).format("YYYY-MM-DD")
        : "";
      let formatedTOIncident = values.estimated_time_of_incident
        ? moment(values.estimated_time_of_incident).format("HH:mm:ss")
        : "";
      let formatedTime911 = values.estimated_time_of_911
        ? moment(values.estimated_time_of_911).format("HH:mm:ss")
        : "";
      let formatedCrpTime = values.estimated_time_cpr
        ? moment(values.estimated_time_cpr).format("HH:mm:ss")
        : "";
      let formatedCrpAedTime = values.est_time_cpr_aed_arrive_patient
        ? moment(values.est_time_cpr_aed_arrive_patient).format("HH:mm:ss")
        : "";
      let formatedAedOnTime = values.est_time_aed_turned_on
        ? moment(values.est_time_aed_turned_on).format("HH:mm:ss")
        : "";
      let formatedShockTime = values.est_time_first_shock
        ? moment(values.est_time_first_shock).format("HH:mm:ss")
        : "";
      let formatedPulseTime = values.time_pulse_restored
        ? moment(values.time_pulse_restored).format("HH:mm:ss")
        : "";
      let formatedBreathTime = values.time_breathing_restored
        ? moment(values.time_breathing_restored).format("HH:mm:ss")
        : "";
      let formatedResponsiveTime = values.time_responsiveness_restored
        ? moment(values.time_responsiveness_restored).format("HH:mm:ss")
        : "";
      let formatedCirculationTime = values.time_sign_of_circulation
        ? moment(values.time_sign_of_circulation).format("HH:mm:ss")
        : "";
      let formData = {
        // id: id,
        IncidentID: id,
        IncidentReportName: values.IncidentReportName,
        ir_type: values.report_template,
        IncidentTypeID: values.incident_class,
        ir_status: values.ir_status,
        start_time: formatedStartTime,
        end_time: formatedEndTime,
        IncidentTimeReported: formatedReportedTime,
        IncidentLocation: values.incident_location,
        SummaryDescription: values.summary_narrative,
        narrative: narrative,
        ConditionsHazards: values.alleged_incident_contribution,
        security_camera_image: values.security_camera_preserved,
        PoliceContact: values.police_contacted,
        OfficerName: values.officer_name,
        OfficerEmail: values.officer_email,
        OfficerBadge: values.badge_number,
        PoliceReportNo: values.preport_number,
        fire_department: values.fire_dept_called,
        fire_department_engine: values.engine_number,
        fire_dept_arrival_time: formatedFireArrTime,
        fire_dept_departure_time: formatedFireDptTime,
        captain_name: values.fire_dept_captain_name,
        VicHosp: values.ambulance_called,
        VicHospAName: values.ambulance_info,
        OtherNameContacted: values.anyone_else_contacted,
        OtherNameContactedList: values.anyone_else_contacted_info,
        HospName: values.anyone_hospitalized,
        HospNameList: values.hospital_name,
        hospital_doc_name: values.doctor_name,
        ClaimsAdjContacted: values.claims_adjuster_contacted,
        ClaimsAdjContactedName: values.claim_adjuster_info,
        meal_location: values.last_meal_status,
        symptoms: values.symptoms,
        reported_by: values.reported_by,
        relation_w_sick_person: values.sick_person_relationship,
        previous_instructions: values.provide_doc_ins,
        physician_contacted: values.physician_contacted,
        physician_name: values.physician_name,
        chef_contacted: values.chef_contacted,
        chef_contacted_name: values.chef_name,
        followup_required: values.follow_required,
        callback_30_mins: values.guest_called_back_min,
        callback_30_mins_datetime: formatedCbMin,
        callback_30_mins_person: values.person_spoken_with_min,
        callback_2_days: values.guest_called_back_days,
        callback_2_days_datetime: formatedCbDays,
        callback_2_days_person: values.person_spoken_with_days,
        food_item_ques: values.check_food_items ? 1 : 0,
        fitem_comment_item: values.comment_about_food_item,
        investigation_with_cooks: values.investigate_chef_servers,
        investigation_comment_item: values.comment_about_investigation,
        review_sanitation: values.inspect_hygiene,
        sanitation_comment_item: values.comment_about_hygiene,
        aed_faculty_name: values.facility_name,
        aed_address_1: values.address_line_1,
        aed_address_2: values.address_line_2,
        aed_city: values.add_city,
        aed_state: values.add_state,
        aed_zip: values.add_zip,
        aed_incident_date: formatedDOIncident,
        aed_incident_time: formatedTOIncident,
        aed_911_time: formatedTime911,
        aed_patient_fname: values.patient_fname,
        aed_patient_mname: values.patient_mname,
        aed_patient_lname: values.patient_lname,
        aed_p_gender: values.patient_gender,
        aed_p_age: values.patient_age,
        aed_p_collapse: values.patient_unresponsive,
        aed_collapse_event: values.events_prior_collapse.toString(),
        aed_person_see_collapse: values.patient_collapse_witness,
        collapse_present_person_name: values.patient_collapse_witness_name,
        aed_person_aed_employee: values.aed_employee,
        aed_circulation: values.circulation_check,
        aed_pulse_checked: values.pulse_check,
        aed_pulse_person: values.have_pulse,
        cpr_prior_911_ems_arrived: values.cpr_given,
        cpr_started_time: formatedCrpTime,
        cpr_aed_employee: values.cpr_started_prior_aed,
        who_started_cpr: values.who_started_cpr,
        name_cpr_started: values.cpr_person_name,
        aed_prior_to_ems: values.patient_side_prior_ems,
        why_no_prior: values.reason_ems_arrival,
        aed_brought_time: formatedCrpAedTime,
        aed_manufacturer: values.aed_manufacturer,
        aed_model_number: values.aed_model_number,
        aed_serial_number: values.aed_serial_number,
        aed_storage_location: values.aed_storage_location,
        aed_pads_used_patient: values.aed_pads_placed,
        who_applied_pads: values.aed_pads_served_by,
        aed_turned_on: values.facility_aed_turned_on,
        aed_turnon_time: formatedAedOnTime,
        aed_shock_patient: values.aed_ever_shocked_patient,
        aed_shock_first_time: formatedShockTime,
        number_shock_given: values.number_of_shocks,
        person_operating_aed_fname: values.aed_fname,
        person_operating_aed_mname: values.aed_mname,
        person_operating_aed_lname: values.aed_lname,
        aed_person_trained_emp: values.is_trained_aed,
        medical_training_level: values.med_training_level,
        aed_mechanical_difficulty: values.aed_mechanical_failure,
        narrative_medical: editorTextMedical,
        unexpected_event_injury: values.unexpected_injuries_aed,
        narrative_injury: editorTextInjury,
        pulse_restored: values.pulse_restored,
        time_pulse_restored: formatedPulseTime,
        breath_restored: values.breathing_restored,
        time_breath_restored: formatedBreathTime,
        responsive_restored: values.responsiveness_restored,
        time_responsive_restored: formatedResponsiveTime,
        circulation_sign: values.sign_of_circulation,
        time_circulation_sign: formatedCirculationTime,
        patient_transported_hosp: values.patient_trans_hospital,
        how_patient_transported_hosp: values.mode_of_trans_hospital,
        hospital_name: values.hopital_name,
        attending_physician: values.attending_physician,
        narrtive_patient: editorTextPatient,
        aed_remain_facility: values.aed_remains,
        aed_remain_facility_reason: values.aed_remains_reason,
        memory_facility: values.memory_device_remains,
        memory_facility_reason: values.memory_device_remains_reason,
        aed_removed: values.aed_removed,
        report_by_fname: values.report_completed_by_fname,
        report_by_lname: values.report_completed_by_lname,
        reporter_title: values.report_title,
        signature: signImage,
        anyone_injured: values.anyone_injured,
        manufacturer_supplier: values.supplier_detail,
        do_not_destroy: values.destroy_sign,
        drugs_involved: values.alcohol_involved,
        incident_witnessed: values.witness_incident,
        witness_name: values.witness_person,
        witness_address: values.witness_address,
        witness_city: values.witness_city,
        witness_state: values.witness_state,
        witness_zip: values.witness_zip,
        witness_phone: values.witness_phone,
        weather_condition: values.weather_conditions,
        lighting_condition: values.lighting_conditions,
        private_notes: values.private_notes,
        icc_value: values.icc_classification,
        UserID: values.reporting_officer,
        other_name: values.other_name,
        GPSLong: lng,
        GPSLat: lat,
      };
      AddIncident(formData)
        .then((res) => {
          if (res.statusCode === 200) {
            navigate({ pathname: "/reports/incidents" });
            // getInvolvedPersonListing();
            dispatch(getSnackMsg("Incident has been submitted successfully"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            // personInvolvedClose();
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          } else {
            dispatch(getSnackMsg("Something went wrong, please try again"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            // // personInvolvedClose();
            // setTimeout(() => {
            //   dispatchR(getSnackClose());
            // }, 3000);
          }
          setPageLoad(false);
        })
        .catch((err) => {
          console.log(err);
          dispatch(getSnackMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setPageLoad(false);
          // // personInvolvedClose();
          // setTimeout(() => {
          //   dispatchR(getSnackClose());
          // }, 3000);
          // let errors = err.data.errors;
          // let error = handleApiResonseErrors(errors);
          // if (error) {
          //   dispatch(getSnackMsg(error));
          //   dispatch(getSnackAlert("error"));
          //   dispatch(getSnackOpen());
          //   setTimeout(() => {
          //     dispatch(getSnackClose());
          //   }, 3000);
          // }
        });
      // alert(values);
      // alert("Hi Im submitting");
      // await timeOut(1500);
      // resetForm();
      // setStatus({ sent: true });
      // setSubmitting(false);
    } catch (error) {
      console.log(error);
      // setStatus({ sent: false });
      // setErrors({ submit: error.message });
      // setSubmitting(false);
    }
  };

  const incidentType = [
    { key: "Open Investigation Pending", value: "Open" },
    { key: "Open Under Investigation", value: "Open-Investigation" },
    { key: "Open Claim Pending", value: "Open-Claim-Pending" },
    { key: "Open Claim Active", value: "Open-Claims" },
    { key: "Closed Claim Complete", value: "Closed-Claims" },
    { key: "Closed Unfounded", value: "Closed-Item Found" },
    { key: "Closed", value: "Closed" },
  ];

  const reporttemplateitems = [
    { key: "General Incident Report", value: "general_incident_report" },
    { key: "Alleged Food Illness", value: "food_illness" },
    { key: "AED Deployment", value: "aed_deployment" },
  ];

  const eventcollapse = [
    { key: "Difficulty Breathing", value: "Difficulty Breathing" },
    { key: "Electric Shock", value: "Electric Shock" },
    { key: "Drowning", value: "Drowning" },
    { key: "Chest Pain", value: "Chest Pain" },
    { key: "Injury", value: "Injury" },
    { key: "No signs or symptoms", value: "No signs or symptoms" },
    { key: "Unknown", value: "Unknown" },
  ];
  const weatherconditions = [
    { key: "Fair", value: "Fair" },
    { key: "Rain", value: "Rain" },
    { key: "Fog", value: "Fog" },
    { key: "Ice", value: "Ice" },
    { key: "Snow", value: "Snow" },
    { key: "Other", value: "Other" },
  ];
  const lightingconditions = [
    { key: "Daytime", value: "Daytime" },
    { key: "Dusk", value: "Dusk" },
    { key: "Lights Working", value: "Lights Working" },
    { key: "Lights Out", value: "Lights Out" },
    { key: "Others", value: "Others" },
  ];
  const iccclassification = [
    { key: "Standard", value: "Standard" },
    { key: "Elevated", value: "Elevated" },
    { key: "Sensitive", value: "Sensitive" },
  ];

  // state for edit force of use
  const [addUseOfForce, setAddUseOfForce] = useState(false);
  const addUseOfForceOpen = () => {
    setAddUseOfForce(true);
  };
  const addUseOfForceClose = () => {
    setAddUseOfForce(false);
  };

  // Involved Person Action Dropdown
  const [ipAnchorEl, setIpAnchorEl] = useState(null);
  const handleIPActionClick = (event, perId) => {
    setIpAnchorEl(event.currentTarget);
    setInvolvedPerId(perId);
  };
  const handleIPActionClose = () => {
    setIpAnchorEl(null);
  };
  // Media Action Dropdown
  const [mediaAction, setMediaAction] = React.useState(null);
  const mediaActionClick = (event) => {
    setMediaAction(event.currentTarget);
  };
  const mediaActionClose = () => {
    setMediaAction(null);
  };
  // Vehicle Action Dropdown
  const [vehicleAction, setVehicleAction] = React.useState(null);
  const vehicleActionClick = (event, id) => {
    console.log("++++ID++++", id);
    setVehicleAction(event.currentTarget);
    setVehicleInvolvedId(id);
  };
  const vehicleActionClose = () => {
    setVehicleAction(null);
  };
  // Witness Statement Action Dropdown
  const [witnessStatement, setWitnessStatement] = React.useState(null);
  const witnessActionClick = (event, id) => {
    setWitnessStatement(event.currentTarget);
    setInvolvedWitnessID(id);
  };
  const witnessActionClose = () => {
    setWitnessStatement(null);
  };

  const classes = useStyles();
  // Add location on map
  const [addLocationOnMap, setAddLocationOnMap] = useState(false);
  const addLocationOnMapOpen = () => {
    setAddLocationOnMap(true);
  };
  const addLocationOnMapClose = () => {
    setAddLocationOnMap(false);
  };

  const saveIncident = (val) => {
    setPageLoad(true);
    let payload = {
      start_time: val.start_datetime
        ? moment(val.start_datetime).format("YYYY-MM-DD hh:mm:ss")
        : "",
      end_time: val.end_datetime
        ? moment(val.end_datetime).format("YYYY-MM-DD hh:mm:ss")
        : "",
      IncidentTypeID: val.incident_class,
      ir_type: val.report_template,
      SummaryDescription: val.summary_narrative,
      IncidentLocation: val.incident_location,
      ir_status: val.ir_status,
    };
    SaveIncidentReport(payload)
      .then((res) => {
        setPageLoad(false);
        if (res.statusCode == 200) {
          setIncidentId(res.data.id);
          dispatch(getSnackMsg("Incident has been added successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
          if (tabStatus === "personClick") {
            setPersonInvolved(true);
          } else if (tabStatus === "vehicleClick") {
            vehicleInvolvedOpen();
          } else if (tabStatus === "referenceClick") {
            referenceOpen();
          } else if (tabStatus === "witnessClick") {
            witnessStatementModalOpen();
          } else if (tabStatus == "uofClick") {
            addUseOfForceOpen();
          }
        } else {
          dispatch(getSnackMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        setPageLoad(false);
        console.log(err);
        dispatch(getSnackMsg("Something went wrong, please try again"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };
  // Add Person Involved

  const saveNewIncident = (v) => {
    if (v.incident_class === "") {
      setCustomValidation((prevState) => ({
        ...prevState,
        incidentClass: "Incident class required",
      }));
    } else if (v.ir_status === "") {
      setCustomValidation((prevState) => ({
        ...prevState,
        irStatus: "IR status required",
      }));
    } else if (v.start_datetime === "") {
      setCustomValidation((prevState) => ({
        ...prevState,
        startDateTime: "Start date/time is required",
      }));
    } else if (v.end_datetime === "") {
      setCustomValidation((prevState) => ({
        ...prevState,
        endDateTime: "End date/time is required",
      }));
    } else if (v.reported_datetime === "") {
      setCustomValidation((prevState) => ({
        ...prevState,
        reportedDateTime: "Report date/time is Required",
      }));
    } else if (v.incident_location === "") {
      setCustomValidation((prevState) => ({
        ...prevState,
        incidentLocation: "Incident location is Required",
      }));
    } else if (v.summary_narrative === "") {
      setCustomValidation((prevState) => ({
        ...prevState,
        summaryNar: "Summary narrative is Required",
      }));
    } else if (v.summary_narrative.length < 5) {
      setCustomValidation((prevState) => ({
        ...prevState,
        summaryNar: "Summary narrative must be at least 5 characters",
      }));
    } else {
      saveIncident(v);
    }
  };
  const [deleteType, setDeleteType] = useState("");
  const handleDeleteClose = () => {
    setOpenDelete(false);
  };
  const handleDeleteOpen = (val) => {
    setOpenDelete(true);
    setDeleteType(val);
  };
  //handler for opening involved person model
  const [personInvolved, setPersonInvolved] = useState(false);
  const personInvolvedOpen = () => {
    setPersonInvolved(true);
  };
  const personInvolvedClose = () => {
    setPersonInvolved(false);
    setEditPIData({});
  };
  // Vehicle Involved
  const [vehicleInvolved, setVehicleInvolved] = useState(false);
  const vehicleInvolvedOpen = () => {
    setVehicleInvolved(true);
  };
  const vehicleInvolvedClose = () => {
    setVehicleInvolved(false);
    setVehicleData({});
  };
  // Witness Statement Modal
  const [witnessStatementModal, setWitnessStatementModal] = useState(false);
  const witnessStatementModalOpen = () => {
    setWitnessStatementModal(true);
  };
  const witnessStatementModalClose = () => {
    setWitnessStatementModal(false);
    setEditWIData({});
  };
  // Media Upload Modal
  const [mediaUpload, setMediaUpload] = useState(false);
  const mediaUploadOpen = () => {
    setMediaUpload(true);
  };
  const mediaUploadClose = () => {
    setMediaUpload(false);
  };
  const [incidentDropdown, setIncidentDropdown] = useState([]);
  //API call for getting SMS subscribed Plan details
  const getIncidentTypeList = () => {
    setPageLoad(true);
    IncidentTypeListDropdown()
      .then((res) => {
        setPageLoad(false);
        if (res.statusCode == 200) {
          setIncidentDropdown(res.data);
        } else {
          setIncidentDropdown([]);
        }
      })
      .catch((err) => {
        setPageLoad(false);
        console.log(err);
      });
  };

  //Method for showing listing for involved vehicle
  const getInvolvedVehicleListing = () => {
    if (id) {
      setLoading(true);
      InvolvedVehicleListing(id)
        .then((res) => {
          if (res.statusCode == 200) {
            // console.log("++++", res.data);
            setVIListing(res.data);
          } else {
            setVIListing([]);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  //function for getting data for involved vehicle
  const getVehicleInvolved = () => {
    setPageLoad(true);
    GetInvolvedVehicleData(vehicleInvolvedId)
      .then((res) => {
        setPageLoad(false);
        if (res.statusCode === 200) {
          setVehicleData(res.data);
        }
      })
      .catch((err) => {
        setPageLoad(false);
        console.log(err);
      });
  };

  //Function for deleteing involved vehicle
  const DeleteVehicleInvolved = () => {
    setPageLoad(true);
    DeleteInvolvedVehicle(vehicleInvolvedId).then((res) => {
      if (res.statusCode == 200) {
        setPageLoad(false);
        dispatch(getSnackMsg("Record has been deleted successfully"));
        dispatch(getSnackAlert("success"));
        dispatch(getSnackOpen());
        getInvolvedVehicleListing();
        handleDeleteClose();
      } else {
        setPageLoad(false);
        handleDeleteClose();
        dispatch(getSnackMsg("Something went wrong, please try again"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
      }
    });
  };

  //Handler for editing vehicle involved
  const editVehicle = () => {
    getVehicleInvolved();
    vehicleActionClose();
    vehicleInvolvedOpen();
    setVehicleActionStatus(false);
  };

  //Handler for view vehicle involved
  const viewVehicle = () => {
    getVehicleInvolved();
    vehicleActionClose();
    vehicleInvolvedOpen();
    setVehicleActionStatus(true);
  };

  // Methods related to PERSON INVOLVED
  //Method for showing listing for involved person
  const getInvolvedPersonListing = () => {
    if (id) {
      setLoading(true);
      InvolvedPersonListing(id)
        .then((res) => {
          if (res.statusCode == 200) {
            // console.log("++++", res.data);
            setPIListing(res.data);
          } else {
            setPIListing([]);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  //Method for showing listing for involved person
  const getMediaListing = () => {
    if (id) {
      setLoading(true);
      let payload = {
        IncidentID: id,
      };
      GetMediaList(payload)
        .then((res) => {
          if (res.statusCode == 200) {
            setMediaListing(res.data);
          } else {
            setMediaListing([]);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  //Method for getting data for involved person
  const getPersonInvolved = () => {
    setPageLoad(true);
    GetInvolvedPersonData(involvedPerId)
      .then((res) => {
        setPageLoad(false);
        if (res.statusCode === 200) {
          setEditPIData(res.data);
        }
      })
      .catch((err) => {
        setPageLoad(false);
        console.log(err);
      });
  };

  //Function for deleteing involved person
  const DeletePersonInvolved = () => {
    setPageLoad(true);
    DeleteInvolvedPerson(involvedPerId).then((res) => {
      if (res.statusCode == 200) {
        setPageLoad(false);
        dispatch(getSnackMsg("Record has been deleted successfully"));
        dispatch(getSnackAlert("success"));
        dispatch(getSnackOpen());
        getInvolvedPersonListing();
        handleDeleteClose();
      } else {
        setPageLoad(false);
        handleDeleteClose();
        dispatch(getSnackMsg("Something went wrong, please try again"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
      }
    });
  };

  //Handler for edit involved person
  const editInvolvedPerson = () => {
    getPersonInvolved();
    handleIPActionClose();
    personInvolvedOpen();
    setPersonActionStatus(false);
  };

  //Handler for view Person involved
  const viewInvolvedPerson = () => {
    getPersonInvolved();
    handleIPActionClose();
    personInvolvedOpen();
    setPersonActionStatus(true);
  };

  //Method for showing listing for involved Witness
  const getInvolvedWitnessListing = () => {
    if (id) {
      setLoading(true);
      InvolvedWitnessListing(id)
        .then((res) => {
          if (res.statusCode == 200) {
            setWIListing(res.data);
          } else {
            setWIListing([]);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const getWitnessInvolved = () => {
    setPageLoad(true);
    GetInvolvedWitnessData(involvedWitnessID)
      .then((res) => {
        setPageLoad(false);
        if (res.statusCode === 200) {
          setEditWIData(res.data);
        }
      })
      .catch((err) => {
        setPageLoad(false);
        console.log(err);
      });
  };

  //Function for deleteing involved person
  const DeleteWitnessInvolved = () => {
    setPageLoad(true);
    DeleteInvolvedWitness(involvedWitnessID).then((res) => {
      if (res.statusCode == 200) {
        setPageLoad(false);
        dispatch(getSnackMsg("Record has been deleted successfully"));
        dispatch(getSnackAlert("success"));
        dispatch(getSnackOpen());
        getInvolvedWitnessListing();
        handleDeleteClose();
      } else {
        setPageLoad(false);
        handleDeleteClose();
        dispatch(getSnackMsg("Something went wrong, please try again"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
      }
    });
  };

  //Handler for editing witness involved
  const editWitness = () => {
    getWitnessInvolved();
    witnessActionClose();
    witnessStatementModalOpen();
    setWitnessActionStatus(false);
  };

  //Handler for view vehicle involved
  const viewWitness = () => {
    getWitnessInvolved();
    witnessActionClose();
    witnessStatementModalOpen();
    setWitnessActionStatus(true);
  };

  // Investigative Report Modal
  const [investigativeReport, setInvestigativeReport] = useState(false);
  const investigativeReportOpen = () => {
    setInvestigativeReport(true);
  };
  const investigativeReportClose = () => {
    setInvestigativeReport(false);
  };

  const [reportingOfficer, setReportingOfficer] = useState([]);
  const ReportingUser = () => {
    IncidentUserList()
      .then((res) => {
        if (res.statusCode === 200) {
          setReportingOfficer(res.data);
        } else {
          setReportingOfficer([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //Method to get edit incident info
  const getEditIncidentData = () => {
    setPageLoad(true);
    GetIncidentReportData(id)
      .then((res) => {
        if (res.statusCode == 200) {
          setEditableData(res.data);
          setNarrative(res.data.Description);
          setLat(parseFloat(res.data.GPSLong));
          setLng(parseFloat(res.data.GPSLat));
        }
        setPageLoad(false);
      })
      .catch((err) => {
        setPageLoad(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getIncidentTypeList();
    getEditIncidentData();
    getInvolvedPersonListing();
    getInvolvedVehicleListing();
    getInvolvedWitnessListing();
    getMediaListing();
    ReportingUser();
    getInvolvedFinanceListing();
  }, []);

  const deleteFunctions = {
    personInvolved: DeletePersonInvolved,
    vehicleInvolved: DeleteVehicleInvolved,
    wittnessInvolved: DeleteWitnessInvolved,
  };

  const onClickHandler = deleteFunctions[deleteType] || null;
  return (
    <>
      <Dialog
        open={openDelete}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleDeleteClose} variant="contained">
            Cancel
          </Button>
          <Button onClick={onClickHandler} variant="contained" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          status,
          setFieldValue,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              <Card>
                <CardContent>
                  <Box>
                    <Grid container spacing={6}>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        lg={4}
                        // order={{ xs: 3, md: 1 }}
                      >
                        <FormControl mt={3}>
                          <CustomTextField
                            name="IncidentReportName"
                            label="IR number"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            // defaultValue="05-19-2022 -346"
                            // required
                            autoComplete="off"
                            placeholder="160 character max"
                          />
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        lg={4}
                        alignSelf="center"
                        // order={{ xs: 2, md: 2 }}
                      >
                        <FormControl mt={3}>
                          <FormLabel>
                            Ref ID:{" "}
                            {editableData
                              ? editableData.IncidentID
                                ? editableData.IncidentID
                                : null
                              : null}
                          </FormLabel>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        lg={4}
                        alignSelf="center"
                        // order={{ xs: 1, md: 3 }}
                      >
                        {editableData.status == "N" ? (
                          <Tooltip
                            title={`Web report is incomplete`}
                            followCursor
                          >
                            <Typography
                              sx={{ color: "#000000", marginBottom: 0 }}
                              variant="h3"
                              component="div"
                              gutterBottom
                            >
                              NEW
                            </Typography>
                          </Tooltip>
                        ) : editableData.status == "S" ? (
                          <Tooltip
                            title={`Report has been submitted and cannot be edited`}
                            followCursor
                          >
                            <Typography
                              sx={{ color: "red", marginBottom: 0 }}
                              variant="h3"
                              component="div"
                              gutterBottom
                            >
                              SUBMITTED
                            </Typography>
                          </Tooltip>
                        ) : editableData.status == "R" ? (
                          <Tooltip
                            title={`Report can be edited by the author to correct deficiencies`}
                            followCursor
                          >
                            <Typography
                              sx={{ color: "#FFFF00", marginBottom: 0 }}
                              variant="h3"
                              component="div"
                              gutterBottom
                            >
                              RETURNED
                            </Typography>
                          </Tooltip>
                        ) : editableData.status == "A" ? (
                          <Tooltip
                            title={`Report is finalized and cannot be edited`}
                            followCursor
                          >
                            <Typography
                              sx={{ color: "lightgreen", marginBottom: 0 }}
                              variant="h3"
                              component="div"
                              gutterBottom
                            >
                              ACCEPTED
                            </Typography>
                          </Tooltip>
                        ) : editableData.status == "E" ? (
                          <Tooltip
                            title={`Mobile report is incomplete`}
                            followCursor
                          >
                            <Typography
                              sx={{ color: "gray", marginBottom: 0 }}
                              variant="h3"
                              component="div"
                              gutterBottom
                            >
                              DRAFT
                            </Typography>
                          </Tooltip>
                        ) : null}
                        {/* {editableData ? editableData.status ? editableData.status == "N" ? (
                          <Typography
                          sx={{ color: "red", marginBottom: 0 }}
                          variant="h3"
                          component="div"
                          gutterBottom
                        >
                          NEW
                        </Typography>
                        ) : editableData.status == "S" ? (
                            <Typography
                          sx={{ color: "red", marginBottom: 0 }}
                          variant="h3"
                          component="div"
                          gutterBottom
                        >
                          Sumbitted
                        </Typography>
                        ) : editableData.status == "R" ? (
                          <Typography
                          sx={{ color: "red", marginBottom: 0 }}
                          variant="h3"
                          component="div"
                          gutterBottom
                        >
                          Recieved
                        </Typography>
                        ) : editableData.status == "A" ?  (
                          <Typography
                          sx={{ color: "red", marginBottom: 0 }}
                          variant="h3"
                          component="div"
                          gutterBottom
                        >
                          Accepted
                        </Typography>
                        ) : editableData.status == "E" ? (
                        <Typography
                          sx={{ color: "red", marginBottom: 0 }}
                          variant="h3"
                          component="div"
                          gutterBottom
                        >
                          Web
                        </Typography>     
                        ) : null : null} */}
                        {/* // <Typography
                          sx={{ color: "red", marginBottom: 0 }}
                          variant="h3"
                          component="div"
                          gutterBottom
                        >
                          SUBMITTED
                        </Typography> */}
                      </Grid>
                      <Grid item xs={12} lg={4} alignSelf="start">
                        <FormControl mt={3}>
                          <CustomSelection
                            name="report_template"
                            label="Report Template *"
                            options={reporttemplateitems}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={4} alignSelf="start">
                        <FormControl mt={3}>
                          <CustomSelection
                            name="incident_class"
                            label="Incident Class *"
                            options={incidentDropdown}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          {values.incident_class === "" && (
                            <FormHelperText sx={{ color: "red" }}>
                              {customValidation.incidentClass}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={4} alignSelf="start">
                        <FormControl mt={3}>
                          <CustomSelection
                            name="ir_status"
                            label="IR Status *"
                            options={incidentType}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <FormControl>
                          <DateTimePicker
                            id="start_datetime"
                            label="Start Date/Time *"
                            value={values.start_datetime}
                            onChange={(newValue) => {
                              setFieldValue("start_datetime", newValue);
                            }}
                            inputFormat="MM/dd/yyyy HH:mm"
                            renderInput={(params) => (
                              <CustomTextField
                                {...params}
                                name="start_datetime"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                autoComplete="off"
                              />
                            )}
                          />
                          {values.start_datetime === "" && (
                            <FormHelperText sx={{ color: "red" }}>
                              {customValidation.startDateTime}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <FormControl>
                          <DateTimePicker
                            id="end_datetime"
                            label="End Date/Time *"
                            value={values.end_datetime}
                            onChange={(newValue) => {
                              setFieldValue("end_datetime", newValue);
                            }}
                            inputFormat="MM/dd/yyyy HH:mm"
                            renderInput={(params) => (
                              <CustomTextField
                                {...params}
                                name="end_datetime"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                autoComplete="off"
                              />
                            )}
                          />
                          {values.end_datetime === "" && (
                            <FormHelperText sx={{ color: "red" }}>
                              {customValidation.endDateTime}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <FormControl>
                          <DateTimePicker
                            id="reported_datetime"
                            label="Reported Date/Time *"
                            value={values.reported_datetime}
                            onChange={(newValue) => {
                              setFieldValue("reported_datetime", newValue);
                            }}
                            inputFormat="MM/dd/yyyy HH:mm"
                            renderInput={(params) => (
                              <CustomTextField
                                {...params}
                                name="reported_datetime"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                autoComplete="off"
                              />
                            )}
                          />
                          {values.reported_datetime === "" && (
                            <FormHelperText sx={{ color: "red" }}>
                              {customValidation.reportedDateTime}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={4} alignSelf="end">
                        <FormControl>
                          {/* <CheckboxWrapper
                            name="show_map"
                            checked={values.show_map}
                            // legend="Terms Of Service"
                            label="Show map"
                          /> */}
                          <FormControlLabel
                            control={
                              <Checkbox
                                disableRipple
                                name="show_map"
                                onChange={addLocationOnMapOpen}
                              />
                            }
                            label="Show map"
                          />
                          <CustomTextField
                            name="incident_location"
                            label="Venue *"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            // onChange={() => {
                            //   setCustomValidation((prevState) => ({
                            //     ...prevState,
                            //     incidentLocation: "",
                            //   }));
                            // }}
                            autoComplete="off"
                            placeholder=""
                          />
                          {values.incident_location === "" && (
                            <FormHelperText sx={{ color: "red" }}>
                              {customValidation.incidentLocation}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={8} alignSelf="end">
                        <FormControl>
                          <CustomTextField
                            name="summary_narrative"
                            label="Summary Narrative *"
                            inputProps={{ maxLength: 160 }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            autoComplete="off"
                            placeholder="160 character max"
                          />
                          {values.summary_narrative === "" && (
                            <FormHelperText sx={{ color: "red" }}>
                              {customValidation.summaryNar}
                            </FormHelperText>
                          )}
                          {values.summary_narrative.length < 5 && (
                            <FormHelperText sx={{ color: "red" }}>
                              {customValidation.summaryNar}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                  {/* Common upper box for all dropdown */}
                  <Box>
                    <Grid container spacing={6} alignItems="end">
                      <Grid item xs={12} lg={12}>
                        {/* <FormControl mt={3}>
                          <FormLabel>Narrative</FormLabel>
                          {values.report_template === "food_illness" && (
                            <FormHelperText sx={{ marginLeft: 0 }}>
                              Describe meals in detail, including, any alcoholic
                              beverages consumed and also note any food
                              allergies
                            </FormHelperText>
                          )}
                          <Box
                            className={classes.textEditor}
                            sx={{ marginTop: "15px" }}
                          >
                            <MUIRichTextEditor
                              label={
                                <Typography sx={{ padding: "15px" }}>
                                  Type something here...
                                </Typography>
                              }
                              // defaultValue={values.narrative}
                              inlineToolbar={true}
                              onChange={onEditorNarrativeChange}
                            />
                          </Box>
                        </FormControl> */}
                        <FormControl>
                          <FormLabel>Narrative</FormLabel>
                          <QuillWrapper>
                            <ReactQuill
                              theme="snow"
                              value={narrative}
                              onChange={setNarrative}
                              placeholder="Type something.."
                            />
                          </QuillWrapper>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <FormControl>
                          <CustomTextField
                            name="alleged_incident_contribution"
                            label="Conditions or hazards which may have contributed to the incident"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            autoComplete="off"
                            placeholder="Pool lighting, weight surface, etc."
                          />
                        </FormControl>
                      </Grid>
                      {/* Grid for all tabbed section for adding involved person, media, Vehicle, witness,  etc */}
                      <Grid item xs={12} lg={12}>
                        <Box sx={{ width: "100%" }}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                              value={tabvalue}
                              onChange={handleTabChange}
                              aria-label="incident tabs"
                            >
                              <Tab
                                label="Involved person"
                                {...incidentTab(0)}
                              />
                              <Tab label="Media" {...incidentTab(1)} />
                              <Tab label="Vehicles" {...incidentTab(2)} />
                              <Tab
                                label="Witness Statements"
                                {...incidentTab(3)}
                              />
                            </Tabs>
                          </Box>
                          <TabPanel value={tabvalue} index={0}>
                            <Paper>
                              <ButtonGroup
                                aria-label="button group"
                                sx={{
                                  "& .MuiButton-root": {
                                    padding: "5px 8px",
                                    margin: "10px 0 15px",
                                  },
                                }}
                              >
                                <Button
                                  onClick={() => {
                                    setTabStatus("personClick");
                                    setPersonActionStatus(false);
                                    if (incidentId === "") {
                                      saveNewIncident(values);
                                    } else {
                                      personInvolvedOpen();
                                    }
                                  }}
                                >
                                  <AddIcon />
                                </Button>
                                <Button>
                                  <PersonIcon /> 0
                                </Button>
                              </ButtonGroup>
                              <TableContainer
                                className="tableContainer"
                                component={Paper}
                                sx={{
                                  margin: "15px auto auto",
                                  "&::-webkit-scrollbar": {
                                    width: 7,
                                    height: 7,
                                  },
                                  "&::-webkit-scrollbar-track": {
                                    background: "#bed2f5",
                                  },
                                  "&::-webkit-scrollbar-thumb": {
                                    background: "#92a6c9",
                                  },
                                }}
                              >
                                <Table stickyHeader aria-label="sticky table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Name</TableCell>
                                      <TableCell>Involvement</TableCell>
                                      <TableCell>Injured</TableCell>
                                      <TableCell>Registered</TableCell>
                                      <TableCell>Mobile Phone</TableCell>
                                      <TableCell>Email</TableCell>
                                      <TableCell>Action</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  {loading ? (
                                    <TableBody>
                                      <TableRow>
                                        <TableCell colSpan={10} align="center">
                                          <CircularProgress />
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  ) : (
                                    <TableBody>
                                      {PIListing.map((item) => {
                                        return (
                                          <TableRow hover>
                                            <TableCell
                                              sx={{ whiteSpace: "nowrap" }}
                                            >
                                              {item.name}
                                            </TableCell>
                                            <TableCell
                                              sx={{ whiteSpace: "nowrap" }}
                                            >
                                              {item.involvement_type}
                                            </TableCell>
                                            <TableCell
                                              sx={{ whiteSpace: "nowrap" }}
                                            >
                                              {item.injured}
                                            </TableCell>
                                            <TableCell
                                              sx={{ whiteSpace: "nowrap" }}
                                            >
                                              {item.guest_registered}
                                            </TableCell>
                                            <TableCell
                                              sx={{ whiteSpace: "nowrap" }}
                                            >
                                              {item.mobile_phone}
                                            </TableCell>
                                            <TableCell
                                              sx={{ whiteSpace: "nowrap" }}
                                            >
                                              {item.email}
                                            </TableCell>
                                            <TableCell>
                                              <IconButton
                                                aria-label="Settings"
                                                size="large"
                                                aria-owns={
                                                  ipAnchorEl
                                                    ? "involved-person"
                                                    : undefined
                                                }
                                                aria-haspopup="true"
                                                onClick={(event) =>
                                                  handleIPActionClick(
                                                    event,
                                                    item.id
                                                  )
                                                }
                                                style={{ color: "#1374C5" }}
                                              >
                                                <SettingsIcon />
                                              </IconButton>
                                              <StyledMenu
                                                id="involved-person"
                                                anchorEl={ipAnchorEl}
                                                open={Boolean(ipAnchorEl)}
                                                onClose={handleIPActionClose}
                                                anchorOrigin={{
                                                  vertical: "bottom",
                                                  horizontal: "right",
                                                }}
                                                transformOrigin={{
                                                  vertical: "top",
                                                  horizontal: "right",
                                                }}
                                              >
                                                <MenuItem
                                                  onClick={viewInvolvedPerson}
                                                >
                                                  <ListItemIcon>
                                                    <VisibilityIcon />
                                                  </ListItemIcon>
                                                  <ListItemText
                                                    primary="View"
                                                    pl={1}
                                                  />
                                                </MenuItem>
                                                <MenuItem
                                                  onClick={editInvolvedPerson}
                                                >
                                                  <ListItemIcon>
                                                    <EditIcon />
                                                  </ListItemIcon>
                                                  <ListItemText
                                                    primary="Edit"
                                                    pl={1}
                                                  />
                                                </MenuItem>

                                                <MenuItem
                                                  onClick={() => {
                                                    handleIPActionClose();
                                                    handleDeleteOpen(
                                                      "personInvolved"
                                                    );
                                                  }}
                                                >
                                                  <ListItemIcon>
                                                    <DeleteIcon />
                                                  </ListItemIcon>
                                                  <ListItemText
                                                    primary="Delete"
                                                    pl={1}
                                                  />
                                                </MenuItem>
                                              </StyledMenu>
                                            </TableCell>
                                          </TableRow>
                                        );
                                      })}
                                      {PIListing.length == 0 && (
                                        <TableRow>
                                          <TableCell colSpan={7} align="center">
                                            No record added
                                          </TableCell>
                                        </TableRow>
                                      )}
                                    </TableBody>
                                  )}
                                </Table>
                              </TableContainer>
                            </Paper>
                          </TabPanel>
                          <TabPanel value={tabvalue} index={1}>
                            <Paper>
                              <ButtonGroup
                                aria-label="button group"
                                sx={{
                                  "& .MuiButton-root": {
                                    padding: "5px 8px",
                                    margin: "10px 0 15px",
                                  },
                                }}
                              >
                                <Button onClick={mediaUploadOpen}>
                                  <AddIcon />
                                </Button>
                              </ButtonGroup>
                              <IncidentMediaList
                                mediaListing={mediaListing}
                                loading={loading}
                                getMediaListing={() => getMediaListing()}
                                setPageLoad={(status) => setPageLoad(status)}
                              />
                            </Paper>
                          </TabPanel>
                          <TabPanel value={tabvalue} index={2}>
                            <Paper>
                              <ButtonGroup
                                aria-label="button group"
                                sx={{
                                  "& .MuiButton-root": {
                                    padding: "5px 8px",
                                    margin: "10px 0 15px",
                                  },
                                }}
                              >
                                <Button
                                  onClick={() => {
                                    setTabStatus("vehicleClick");
                                    setVehicleActionStatus(false);
                                    if (incidentId === "") {
                                      saveNewIncident(values);
                                    } else {
                                      vehicleInvolvedOpen();
                                    }
                                  }}
                                >
                                  <AddIcon />
                                </Button>
                              </ButtonGroup>
                              <TableContainer
                                className="tableContainer"
                                component={Paper}
                                sx={{
                                  margin: "15px auto auto",
                                  "&::-webkit-scrollbar": {
                                    width: 7,
                                    height: 7,
                                  },
                                  "&::-webkit-scrollbar-track": {
                                    background: "#bed2f5",
                                  },
                                  "&::-webkit-scrollbar-thumb": {
                                    background: "#92a6c9",
                                  },
                                }}
                              >
                                <Table stickyHeader aria-label="sticky table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Make</TableCell>
                                      <TableCell>Model</TableCell>
                                      <TableCell>Year</TableCell>
                                      <TableCell>Color</TableCell>
                                      <TableCell>Licence Number</TableCell>
                                      <TableCell>Driven By</TableCell>
                                      <TableCell>Reason</TableCell>
                                      <TableCell>Passengers</TableCell>
                                      <TableCell>Action</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  {loading ? (
                                    <TableBody>
                                      <TableRow>
                                        <TableCell colSpan={10} align="center">
                                          <CircularProgress />
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  ) : (
                                    <TableBody>
                                      {VIListing.map((item) => {
                                        return (
                                          <TableRow hover>
                                            <TableCell
                                              sx={{
                                                whiteSpace: "nowrap",
                                              }}
                                            >
                                              {item.make}
                                            </TableCell>
                                            <TableCell
                                              sx={{
                                                whiteSpace: "nowrap",
                                              }}
                                            >
                                              {item.model}
                                            </TableCell>
                                            <TableCell
                                              sx={{
                                                whiteSpace: "nowrap",
                                              }}
                                            >
                                              {item.year}
                                            </TableCell>
                                            <TableCell
                                              sx={{
                                                whiteSpace: "nowrap",
                                              }}
                                            >
                                              {item.color}
                                            </TableCell>
                                            <TableCell
                                              sx={{
                                                whiteSpace: "nowrap",
                                              }}
                                            >
                                              {item.plate}
                                            </TableCell>
                                            <TableCell
                                              sx={{
                                                whiteSpace: "nowrap",
                                              }}
                                            >
                                              {item.driver_name}
                                            </TableCell>
                                            <TableCell
                                              sx={{ minWidth: "300px" }}
                                            >
                                              {item.reason}
                                            </TableCell>
                                            <TableCell
                                              sx={{
                                                whiteSpace: "nowrap",
                                              }}
                                            >
                                              {item.passengers}
                                            </TableCell>
                                            <TableCell>
                                              <IconButton
                                                aria-label="Settings"
                                                size="large"
                                                aria-owns={
                                                  vehicleAction
                                                    ? "vehicles-action"
                                                    : undefined
                                                }
                                                aria-haspopup="true"
                                                onClick={(e) => {
                                                  vehicleActionClick(
                                                    e,
                                                    item.id
                                                  );
                                                }}
                                                style={{
                                                  color: "#1374C5",
                                                }}
                                              >
                                                <SettingsIcon />
                                              </IconButton>
                                              <StyledMenu
                                                id="vehicles-action"
                                                anchorEl={vehicleAction}
                                                open={Boolean(vehicleAction)}
                                                onClose={vehicleActionClose}
                                                anchorOrigin={{
                                                  vertical: "bottom",
                                                  horizontal: "right",
                                                }}
                                                transformOrigin={{
                                                  vertical: "top",
                                                  horizontal: "right",
                                                }}
                                              >
                                                <MenuItem onClick={viewVehicle}>
                                                  <ListItemIcon>
                                                    <VisibilityIcon />
                                                  </ListItemIcon>
                                                  <ListItemText
                                                    primary="View"
                                                    pl={1}
                                                  />
                                                </MenuItem>
                                                <MenuItem onClick={editVehicle}>
                                                  <ListItemIcon>
                                                    <EditIcon />
                                                  </ListItemIcon>
                                                  <ListItemText
                                                    primary="Edit"
                                                    pl={1}
                                                  />
                                                </MenuItem>

                                                <MenuItem
                                                  onClick={() => {
                                                    vehicleActionClose();
                                                    handleDeleteOpen(
                                                      "vehicleInvolved"
                                                    );
                                                  }}
                                                >
                                                  <ListItemIcon>
                                                    <DeleteIcon />
                                                  </ListItemIcon>
                                                  <ListItemText
                                                    primary="Delete"
                                                    pl={1}
                                                  />
                                                </MenuItem>
                                              </StyledMenu>
                                            </TableCell>
                                          </TableRow>
                                        );
                                      })}
                                      {VIListing.length == 0 && (
                                        <TableRow
                                        // style={{
                                        //   height:
                                        //     (dense ? 6 : 0) *
                                        //     PIListing.length,
                                        // }}
                                        >
                                          <TableCell colSpan={9} align="center">
                                            No record added
                                          </TableCell>
                                        </TableRow>
                                      )}
                                    </TableBody>
                                  )}
                                </Table>
                              </TableContainer>
                            </Paper>
                          </TabPanel>
                          <TabPanel value={tabvalue} index={3}>
                            <Paper>
                              <ButtonGroup
                                aria-label="button group"
                                sx={{
                                  "& .MuiButton-root": {
                                    padding: "5px 8px",
                                    margin: "10px 0 15px",
                                  },
                                }}
                              >
                                <Button
                                  onClick={() => {
                                    setTabStatus("witnessClick");
                                    setWitnessActionStatus(false);
                                    if (incidentId === "") {
                                      saveNewIncident(values);
                                    } else {
                                      witnessStatementModalOpen();
                                    }
                                  }}
                                  //onClick={witnessStatementModalOpen}
                                >
                                  <AddIcon />
                                </Button>
                              </ButtonGroup>
                              <TableContainer
                                className="tableContainer"
                                component={Paper}
                                sx={{
                                  margin: "15px auto auto",
                                  "&::-webkit-scrollbar": {
                                    width: 7,
                                    height: 7,
                                  },
                                  "&::-webkit-scrollbar-track": {
                                    background: "#bed2f5",
                                  },
                                  "&::-webkit-scrollbar-thumb": {
                                    background: "#92a6c9",
                                  },
                                }}
                              >
                                <Table stickyHeader aria-label="sticky table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Witness Name</TableCell>
                                      <TableCell>Viewed Incident</TableCell>
                                      <TableCell>Phone</TableCell>
                                      <TableCell>Action</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  {loading ? (
                                    <TableBody>
                                      <TableRow>
                                        <TableCell colSpan={4} align="center">
                                          <CircularProgress />
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  ) : (
                                    <TableBody>
                                      {WIListing.map((item) => {
                                        return (
                                          <TableRow hover>
                                            <TableCell>
                                              {item.involvePerson.name}
                                            </TableCell>
                                            <TableCell>
                                              {item.see_incident}
                                            </TableCell>
                                            <TableCell>
                                              {item.involvePerson.mobile_phone}
                                            </TableCell>
                                            <TableCell>
                                              <IconButton
                                                aria-label="Settings"
                                                size="large"
                                                aria-owns={
                                                  witnessStatement
                                                    ? "witness-statement"
                                                    : undefined
                                                }
                                                aria-haspopup="true"
                                                onClick={(e) => {
                                                  witnessActionClick(
                                                    e,
                                                    item.id
                                                  );
                                                }}
                                                style={{
                                                  color: "#1374C5",
                                                }}
                                              >
                                                <SettingsIcon />
                                              </IconButton>
                                              <StyledMenu
                                                id="witness-statement"
                                                anchorEl={witnessStatement}
                                                open={Boolean(witnessStatement)}
                                                onClose={witnessActionClose}
                                                anchorOrigin={{
                                                  vertical: "bottom",
                                                  horizontal: "right",
                                                }}
                                                transformOrigin={{
                                                  vertical: "top",
                                                  horizontal: "right",
                                                }}
                                              >
                                                <MenuItem onClick={viewWitness}>
                                                  <ListItemIcon>
                                                    <VisibilityIcon />
                                                  </ListItemIcon>
                                                  <ListItemText
                                                    primary="View"
                                                    pl={1}
                                                  />
                                                </MenuItem>
                                                <MenuItem onClick={editWitness}>
                                                  <ListItemIcon>
                                                    <EditIcon />
                                                  </ListItemIcon>
                                                  <ListItemText
                                                    primary="Edit"
                                                    pl={1}
                                                  />
                                                </MenuItem>

                                                <MenuItem
                                                  onClick={() => {
                                                    witnessActionClose();
                                                    handleDeleteOpen(
                                                      "wittnessInvolved"
                                                    );
                                                  }}
                                                >
                                                  <ListItemIcon>
                                                    <DeleteIcon />
                                                  </ListItemIcon>
                                                  <ListItemText
                                                    primary="Delete"
                                                    pl={1}
                                                  />
                                                </MenuItem>
                                              </StyledMenu>
                                            </TableCell>
                                          </TableRow>
                                        );
                                      })}
                                      {WIListing.length == 0 && (
                                        <TableRow>
                                          <TableCell colSpan={4} align="center">
                                            No record added
                                          </TableCell>
                                        </TableRow>
                                      )}
                                    </TableBody>
                                  )}
                                </Table>
                              </TableContainer>
                            </Paper>
                          </TabPanel>
                        </Box>
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <FormControl>
                          <FormLabel>
                            Security camera images preserved?
                          </FormLabel>
                          <RadioGroup
                            name="security_camera_preserved"
                            aria-labelledby="preserved-security"
                            row
                            value={values.security_camera_preserved.toString()}
                            onChange={(e) =>
                              setFieldValue(
                                "security_camera_preserved",
                                e.currentTarget.value
                              )
                            }
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Yes"
                              value="Yes"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="No"
                              value="No"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="N/A"
                              value="N/A"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <FormControl>
                          <FormLabel>Police contacted?</FormLabel>
                          <RadioGroup
                            aria-labelledby="police-contacted"
                            name="police_contacted"
                            row
                            value={values.police_contacted.toString()}
                            onChange={(e) => {
                              setFieldValue(
                                "police_contacted",
                                e.currentTarget.value
                              );
                              setFieldValue("officer_name", "");
                              setFieldValue("officer_email", "");
                              setFieldValue("badge_number", "");
                              setFieldValue("preport_number", "");
                            }}
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Yes"
                              value="Yes"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="No"
                              value="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        lg={12}
                        sx={{
                          display:
                            values.police_contacted === "Yes"
                              ? "block"
                              : "none",
                        }}
                      >
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6} lg={3}>
                            <FormControl>
                              <CustomTextField
                                name="officer_name"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                label="Officer name *"
                                autoComplete="off"
                                placeholder="Officer name"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <FormControl>
                              <CustomTextField
                                name="officer_email"
                                fullWidth
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                label="Officer email *"
                                autoComplete="off"
                                placeholder="Officer email"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <FormControl>
                              <CustomTextField
                                name="badge_number"
                                fullWidth
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{ maxLength: 20 }}
                                label="Badge number *"
                                autoComplete="off"
                                placeholder="Badge number"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <FormControl>
                              <CustomTextField
                                name="preport_number"
                                fullWidth
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{ maxLength: 30 }}
                                label="Police report number *"
                                autoComplete="off"
                                placeholder="Police report number"
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <FormControl>
                          <FormLabel>Fire department called?</FormLabel>
                          <RadioGroup
                            aria-labelledby="fire-dept-called"
                            name="fire_dept_called"
                            value={values.fire_dept_called.toString()}
                            onChange={(e) => {
                              setFieldValue(
                                "fire_dept_called",
                                e.currentTarget.value
                              );
                              setFieldValue("engine_number", "");
                              setFieldValue("fire_dept_arr_time", null);
                              setFieldValue("fire_dept_departure_time", null);
                              setFieldValue("fire_dept_captain_name", "");
                            }}
                            row
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Yes"
                              value="Yes"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="No"
                              value="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        lg={12}
                        sx={{
                          display:
                            values.fire_dept_called === "Yes"
                              ? "block"
                              : "none",
                        }}
                      >
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6} lg={3}>
                            <FormControl>
                              <CustomTextField
                                name="engine_number"
                                fullWidth
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                label="Engine number"
                                autoComplete="off"
                                placeholder="Engine number"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <FormControl>
                              <DateTimePicker
                                id="f-arrival-time"
                                label="Fire dept arrival time *"
                                value={values.fire_dept_arr_time}
                                onChange={(newValue) => {
                                  setFieldValue("fire_dept_arr_time", newValue);
                                }}
                                inputFormat="MM/dd/yyyy HH:mm"
                                renderInput={(params) => (
                                  <CustomTextField
                                    {...params}
                                    name="fire_dept_arr_time"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    autoComplete="off"
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <FormControl>
                              <DateTimePicker
                                id="d-arrival-time"
                                label="Fire dept departure time *"
                                value={values.fire_dept_departure_time}
                                onChange={(newValue) => {
                                  setFieldValue(
                                    "fire_dept_departure_time",
                                    newValue
                                  );
                                }}
                                renderInput={(params) => (
                                  <CustomTextField
                                    {...params}
                                    name="fire_dept_departure_time"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    autoComplete="off"
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <FormControl>
                              <CustomTextField
                                name="fire_dept_captain_name"
                                fullWidth
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                label="Captain name *"
                                autoComplete="off"
                                placeholder="First and last name"
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <FormControl>
                          <FormLabel>Ambulance called?</FormLabel>
                          <RadioGroup
                            aria-labelledby="ambulance-called"
                            name="ambulance_called"
                            value={values.ambulance_called}
                            row
                            onChange={(e) => {
                              setFieldValue(
                                "ambulance_called",
                                e.currentTarget.value
                              );
                              setFieldValue("ambulance_info", "");
                            }}
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Yes"
                              value="Yes"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="No"
                              value="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display:
                            values.ambulance_called === "Yes"
                              ? "block"
                              : "none",
                        }}
                      >
                        <FormControl>
                          <CustomTextField
                            name="ambulance_info"
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            //label="Company or other info"
                            autoComplete="off"
                            placeholder="Company or other info"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <FormControl>
                          <FormLabel>Anyone else contacted?</FormLabel>
                          <RadioGroup
                            aria-labelledby="anyone-else-contacted"
                            name="anyone_else_contacted"
                            row
                            value={values.anyone_else_contacted}
                            onChange={(e) => {
                              setFieldValue(
                                "anyone_else_contacted",
                                e.currentTarget.value
                              );
                              setFieldValue("anyone_else_contacted_info", "");
                            }}
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Yes"
                              value="Yes"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="No"
                              value="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display:
                            values.anyone_else_contacted === "Yes"
                              ? "block"
                              : "none",
                        }}
                      >
                        <FormControl>
                          <CustomTextField
                            name="anyone_else_contacted_info"
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            // label="Company or other info"
                            autoComplete="off"
                            placeholder="List name or other info"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <FormControl>
                          <FormLabel>Anyone hospitalized?</FormLabel>
                          <RadioGroup
                            aria-labelledby="anyone-hospitalized"
                            name="anyone_hospitalized"
                            value={values.anyone_hospitalized}
                            onChange={(e) => {
                              setFieldValue(
                                "anyone_hospitalized",
                                e.currentTarget.value
                              );
                              setFieldValue("hospital_name", "");
                              setFieldValue("doctor_name", "");
                            }}
                            row
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Yes"
                              value="Yes"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="No"
                              value="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sx={{
                          display:
                            values.anyone_hospitalized === "Yes"
                              ? "block"
                              : "none",
                        }}
                      >
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6}>
                            <FormControl>
                              <CustomTextField
                                name="hospital_name"
                                fullWidth
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                // label="Company or other info"
                                autoComplete="off"
                                placeholder="Hospital name"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl>
                              <CustomTextField
                                name="doctor_name"
                                fullWidth
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                // label="Company or other info"
                                autoComplete="off"
                                placeholder="Doctor name (if know)"
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <FormControl>
                          <FormLabel>Claims adjuster contacted?</FormLabel>
                          <RadioGroup
                            aria-labelledby="claims-adjuster-contacted"
                            name="claims_adjuster_contacted"
                            value={values.claims_adjuster_contacted}
                            onChange={(e) => {
                              setFieldValue(
                                "claims_adjuster_contacted",
                                e.currentTarget.value
                              );
                              setFieldValue("claim_adjuster_info", "");
                            }}
                            row
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Yes"
                              value="Yes"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="No"
                              value="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display:
                            values.claims_adjuster_contacted === "Yes"
                              ? "block"
                              : "none",
                        }}
                      >
                        <FormControl>
                          <CustomTextField
                            name="claim_adjuster_info"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            // label="Company or other info"
                            autoComplete="off"
                            placeholder="Claim details, date contacted, etc."
                          />
                        </FormControl>
                      </Grid>

                      {/* Second category */}
                      {values.report_template === "food_illness" && (
                        <Grid item xs={12} lg={12}>
                          <Grid container spacing={6}>
                            <Grid item xs={12} lg={6}>
                              <FormControl>
                                <CustomTextField
                                  name="last_meal_status"
                                  label="Location/times of last two meals"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="Restaurant name if known"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <FormControl>
                                <CustomTextField
                                  name="symptoms"
                                  label="Symptoms"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="Cramping, nausea, vomiting, diarrhea, fever, chills, etc."
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <FormControl>
                                <CustomTextField
                                  name="reported_by"
                                  label="Reported by"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="Person reporting this to officer"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <FormControl>
                                <CustomTextField
                                  name="sick_person_relationship"
                                  label="Relationship to sick person"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="off"
                                  placeholder="Staff, family memeber, friend, etc."
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                              <FormControl>
                                <FormLabel sx={{ marginBottom: "10px" }}>
                                  If guest saw a physician and had positive
                                  results on a test for foodborn illness,
                                  Immediately contact the health department.
                                  Include their instructions here.
                                </FormLabel>
                                <CustomTextField
                                  name="provide_doc_ins"
                                  autoComplete="off"
                                  placeholder="Provide doctor insructions"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                              <FormControl>
                                <FormLabel>Physician contacted?</FormLabel>
                                <RadioGroup
                                  aria-labelledby="physician-contacted"
                                  name="physician_contacted"
                                  value={values.physician_contacted.toString()}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "physician_contacted",
                                      e.currentTarget.value
                                    );
                                    setFieldValue("physician_name", "");
                                  }}
                                  row
                                >
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Yes"
                                    value="Yes"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="No"
                                    value="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display:
                                  values.physician_contacted === "Yes"
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <FormControl>
                                <CustomTextField
                                  name="physician_name"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  // label="Company or other info"
                                  autoComplete="off"
                                  placeholder="List physician name"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                              <FormControl>
                                <FormLabel>Excutive chef contacted?</FormLabel>
                                <RadioGroup
                                  aria-labelledby="chef-contacted"
                                  name="chef_contacted"
                                  value={values.chef_contacted.toString()}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "chef_contacted",
                                      e.currentTarget.value
                                    );
                                    setFieldValue("chef_name", "");
                                  }}
                                  row
                                >
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Yes"
                                    value="Yes"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="No"
                                    value="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display:
                                  values.chef_contacted === "Yes"
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <FormControl>
                                <CustomTextField
                                  name="chef_name"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  // label="Company or other info"
                                  autoComplete="off"
                                  placeholder="Chef name"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                              <FormControl>
                                <FormLabel>Followup required</FormLabel>
                                <RadioGroup
                                  aria-labelledby="follow-required"
                                  name="follow_required"
                                  value={values.follow_required.toString()}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "follow_required",
                                      e.currentTarget.value
                                    )
                                  }
                                  row
                                >
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Yes"
                                    value="Yes"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="No"
                                    value="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                              <FormControl>
                                <FormLabel>
                                  Guest was called back within 30 minutes of
                                  report by chef
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="follow-required"
                                  name="guest_called_back_min"
                                  value={values.guest_called_back_min.toString()}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "guest_called_back_min",
                                      e.currentTarget.value
                                    );
                                    setFieldValue(
                                      "guest_called_min_date",
                                      null
                                    );
                                    setFieldValue("person_spoken_with_min", "");
                                  }}
                                  row
                                >
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Yes"
                                    value="Yes"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="No"
                                    value="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display:
                                  values.guest_called_back_min === "Yes"
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                  <FormControl>
                                    <DateTimePicker
                                      id="guest_called_min_date"
                                      value={values.guest_called_min_date}
                                      onChange={(newValue) => {
                                        setFieldValue(
                                          "guest_called_min_date",
                                          newValue
                                        );
                                      }}
                                      inputFormat="MM/dd/yyyy HH:mm"
                                      renderInput={(params) => (
                                        <CustomTextField
                                          {...params}
                                          name="guest_called_min_date"
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          autoComplete="off"
                                          // inputProps={{
                                          //   ...params.inputProps,
                                          //   placeholder: "mm/dd/yyyy H:i:s",
                                          // }}
                                        />
                                      )}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <FormControl>
                                    <CustomTextField
                                      name="person_spoken_with_min"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      // label="Company or other info"
                                      autoComplete="off"
                                      placeholder="Name of person spoke with"
                                    />
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                              <FormControl>
                                <FormLabel>
                                  Guest was called back within 2 days of report
                                  by chef
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="follow-required"
                                  name="guest_called_back_days"
                                  value={values.guest_called_back_days.toString()}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "guest_called_back_days",
                                      e.currentTarget.value
                                    );
                                    setFieldValue(
                                      "guest_called_days_date",
                                      null
                                    );
                                    setFieldValue(
                                      "person_spoken_with_days",
                                      ""
                                    );
                                  }}
                                  row
                                >
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Yes"
                                    value="Yes"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="No"
                                    value="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display:
                                  values.guest_called_back_days === "Yes"
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                  <FormControl>
                                    <DateTimePicker
                                      id="g-date"
                                      value={values.guest_called_days_date}
                                      onChange={(newValue) => {
                                        setFieldValue(
                                          "guest_called_days_date",
                                          newValue
                                        );
                                      }}
                                      inputFormat="MM/dd/yyyy HH:mm"
                                      renderInput={(params) => (
                                        <CustomTextField
                                          {...params}
                                          name="guest_called_days_date"
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          autoComplete="off"
                                          // inputProps={{
                                          //   ...params.inputProps,
                                          //   placeholder: "mm/dd/yyyy H:i:s",
                                          // }}
                                        />
                                      )}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <FormControl>
                                    <CustomTextField
                                      name="person_spoken_with_days"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      // label="Company or other info"
                                      autoComplete="off"
                                      placeholder="Name of person spoke with"
                                    />
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                              <FormControl>
                                <CheckboxWrapper
                                  name="check_food_items"
                                  checked={values.check_food_items}
                                  disableRipple
                                  label="Save and check any food items in question"
                                  returnOnChange={(evt) => {
                                    if (evt.target.checked) {
                                      setFieldValue(
                                        "comment_about_food_item",
                                        ""
                                      );
                                    }
                                  }}
                                />
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              sx={{
                                display:
                                  values.check_food_items === true
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <FormControl>
                                <CustomTextField
                                  name="comment_about_food_item"
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  // label="Company or other info"
                                  autoComplete="off"
                                  placeholder="Add comments about this item here"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                              <FormControl>
                                <CheckboxWrapper
                                  name="investigate_chef_servers"
                                  checked={values.investigate_chef_servers}
                                  disableRipple
                                  label="Investigate with cooks, server or bartenders"
                                  returnOnChange={(evt) => {
                                    if (evt.target.checked) {
                                      setFieldValue(
                                        "comment_about_investigation",
                                        ""
                                      );
                                    }
                                  }}
                                />
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              sx={{
                                display:
                                  values.investigate_chef_servers === true
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <FormControl>
                                <CustomTextField
                                  name="comment_about_investigation"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  // label="Company or other info"
                                  autoComplete="off"
                                  placeholder="Add comments about this item here"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                              <FormControl>
                                <CheckboxWrapper
                                  name="inspect_hygiene"
                                  checked={values.inspect_hygiene}
                                  disableRipple
                                  label="Review sanitation and hygiene with kitchen staff involved"
                                  returnOnChange={(evt) => {
                                    if (evt.target.checked) {
                                      setFieldValue(
                                        "comment_about_hygiene",
                                        ""
                                      );
                                    }
                                  }}
                                />
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              sx={{
                                display:
                                  values.inspect_hygiene === true
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <FormControl>
                                <CustomTextField
                                  name="comment_about_hygiene"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  // label="Company or other info"
                                  autoComplete="off"
                                  placeholder="Add comments about this item here"
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}

                      {/* Third Category */}
                      {values.report_template === "aed_deployment" && (
                        <Grid item xs={12}>
                          <Box>
                            <Grid container spacing={6}>
                              <Grid item xs={12} md={12}>
                                <FormControl>
                                  <CustomTextField
                                    name="facility_name"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{ maxLength: 50 }}
                                    label="Facility name"
                                    autoComplete="off"
                                    placeholder=""
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <FormLabel>Incident location *</FormLabel>
                                <Grid container spacing={3}>
                                  <Grid item xs={12} md={6}>
                                    <FormControl mt={3}>
                                      <CustomTextField
                                        name="address_line_1"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        inputProps={{ maxLength: 50 }}
                                        autoComplete="off"
                                        placeholder="Address 1"
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <FormControl mt={3}>
                                      <CustomTextField
                                        name="address_line_2"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        inputProps={{ maxLength: 50 }}
                                        autoComplete="off"
                                        placeholder="Address 2"
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={4}>
                                    <FormControl>
                                      <CustomTextField
                                        name="add_city"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        inputProps={{ maxLength: 50 }}
                                        autoComplete="off"
                                        placeholder="City"
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={4}>
                                    <FormControl>
                                      <CustomTextField
                                        name="add_state"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        inputProps={{ maxLength: 30 }}
                                        autoComplete="off"
                                        placeholder="State"
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={4}>
                                    <FormControl>
                                      <CustomTextField
                                        name="add_zip"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        inputProps={{ maxLength: 20 }}
                                        autoComplete="off"
                                        placeholder="ZIP"
                                      />
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <FormControl>
                                  <DatePicker
                                    id="start-date"
                                    label="Date of incident"
                                    value={values.date_of_incident}
                                    onChange={(newValue) => {
                                      setFieldValue(
                                        "date_of_incident",
                                        newValue
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <CustomTextField
                                        {...params}
                                        name="date_of_incident"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        inputProps={{
                                          ...params.inputProps,
                                          placeholder: "",
                                        }}
                                      />
                                    )}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <TimePicker
                                  label="Estimated time of incident"
                                  id="estimate-time-incident"
                                  value={values.estimated_time_of_incident}
                                  onChange={(newValue) => {
                                    setFieldValue(
                                      "estimated_time_of_incident",
                                      newValue
                                    );
                                  }}
                                  inputFormat="HH:mm"
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      inputProps={{
                                        ...params.inputProps,
                                        placeholder: "",
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <TimePicker
                                  label="Estimated time that 911 call was placed"
                                  id="estimate-time"
                                  value={values.estimated_time_of_911}
                                  onChange={(newValue) => {
                                    setFieldValue(
                                      "estimated_time_of_911",
                                      newValue
                                    );
                                  }}
                                  inputFormat="HH:mm"
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      inputProps={{
                                        ...params.inputProps,
                                        placeholder: "",
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <FormLabel>Patient name</FormLabel>
                                <Grid container spacing={3}>
                                  <Grid item xs={12} md={4}>
                                    <FormControl mt={3}>
                                      <CustomTextField
                                        name="patient_fname"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        autoComplete="off"
                                        placeholder="First name"
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={4}>
                                    <FormControl mt={3}>
                                      <CustomTextField
                                        name="patient_mname"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        autoComplete="off"
                                        placeholder="Middle name"
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={4}>
                                    <FormControl mt={3}>
                                      <CustomTextField
                                        name="patient_lname"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        autoComplete="off"
                                        placeholder="Last name"
                                      />
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <FormControl>
                                  <FormLabel>Patient gender</FormLabel>
                                  <RadioGroup
                                    aria-labelledby="p-gender"
                                    name="patient_gender"
                                    value={values.patient_gender.toString()}
                                    onChange={(e) =>
                                      setFieldValue(
                                        "patient_gender",
                                        e.currentTarget.value
                                      )
                                    }
                                    row
                                  >
                                    <FormControlLabel
                                      control={<Radio />}
                                      label="Male"
                                      value="male"
                                    />
                                    <FormControlLabel
                                      control={<Radio />}
                                      label="Female"
                                      value="female"
                                    />
                                    <FormControlLabel
                                      control={<Radio />}
                                      label="Unknown"
                                      value="unknown"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <FormControl>
                                  <CustomTextField
                                    name="patient_age"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{ maxLength: 3 }}
                                    label="Estimated age of patient"
                                    autoComplete="off"
                                    placeholder="Age"
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <FormControl>
                                  <FormLabel>
                                    Did the patient collapse (become
                                    unresponsive, i.e. no breathing, no
                                    coughing, no movement)?
                                  </FormLabel>
                                  <RadioGroup
                                    aria-labelledby="patient-state"
                                    name="patient_unresponsive"
                                    value={values.patient_unresponsive.toString()}
                                    onChange={(e) =>
                                      setFieldValue(
                                        "patient_unresponsive",
                                        e.currentTarget.value
                                      )
                                    }
                                    row
                                  >
                                    <FormControlLabel
                                      control={<Radio />}
                                      label="Yes"
                                      value="Yes"
                                    />
                                    <FormControlLabel
                                      control={<Radio />}
                                      label="No"
                                      value="No"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <FormControl>
                                  <FormLabel>
                                    If yes, what were the events immediately
                                    prior to the collapse? (check all that
                                    apply)
                                  </FormLabel>
                                  <FormGroup row>
                                    {eventcollapse.map((item) => (
                                      <FormControlLabel
                                        key={item.key}
                                        control={
                                          <Checkbox
                                            disableRipple
                                            // name="events_prior_collapse"
                                            value={item.value}
                                            onChange={(e) => {
                                              const { value, checked } =
                                                e.target;
                                              if (checked) {
                                                setFieldValue(
                                                  "events_prior_collapse",
                                                  [
                                                    ...values.events_prior_collapse,
                                                    value,
                                                  ]
                                                );
                                              } else {
                                                setFieldValue(
                                                  "events_prior_collapse",
                                                  values.events_prior_collapse.filter(
                                                    (e) => e !== value
                                                  )
                                                );
                                              }
                                              // console.log(
                                              //   "+++",
                                              //   values.events_prior_collapse
                                              // );
                                            }}
                                            checked={values.events_prior_collapse.includes(
                                              item.value
                                            )}
                                          />
                                        }
                                        label={item.key}
                                      />
                                    ))}
                                  </FormGroup>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <FormControl>
                                  <FormLabel>
                                    Was someone present to see that collapse?
                                  </FormLabel>
                                  <RadioGroup
                                    aria-labelledby="patient_collapse_witness"
                                    name="patient_collapse_witness"
                                    value={values.patient_collapse_witness.toString()}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "patient_collapse_witness",
                                        e.currentTarget.value
                                      );
                                      setFieldValue(
                                        "patient_collapse_witness_name",
                                        ""
                                      );
                                    }}
                                    row
                                  >
                                    <FormControlLabel
                                      control={<Radio />}
                                      label="Yes"
                                      value="Yes"
                                    />
                                    <FormControlLabel
                                      control={<Radio />}
                                      label="No"
                                      value="No"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display:
                                    values.patient_collapse_witness === "Yes"
                                      ? "block"
                                      : "none",
                                }}
                              >
                                <FormControl>
                                  <CustomTextField
                                    name="patient_collapse_witness_name"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    // label="Company or other info"
                                    autoComplete="off"
                                    placeholder="Enter persons name"
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <FormControl>
                                  <FormLabel>
                                    If yes, was that person a trained AED
                                    employee?
                                  </FormLabel>
                                  <RadioGroup
                                    aria-labelledby="aed-employee"
                                    name="aed_employee"
                                    value={values.aed_employee.toString()}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "aed_employee",
                                        e.currentTarget.value
                                      );
                                    }}
                                    row
                                  >
                                    <FormControlLabel
                                      control={<Radio />}
                                      label="Yes"
                                      value="Yes"
                                    />
                                    <FormControlLabel
                                      control={<Radio />}
                                      label="No"
                                      value="No"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <FormControl>
                                  <FormLabel>
                                    After the collapse, at the time of Patient
                                    Assessment and just prior to the Facility
                                    AED pads being applied, were there signs of
                                    circulation (breathing, coughing, movement)?
                                  </FormLabel>
                                  <RadioGroup
                                    aria-labelledby="patient-assessment"
                                    name="circulation_check"
                                    value={values.circulation_check.toString()}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "circulation_check",
                                        e.currentTarget.value
                                      );
                                    }}
                                    row
                                  >
                                    <FormControlLabel
                                      control={<Radio />}
                                      label="Yes"
                                      value="Yes"
                                    />
                                    <FormControlLabel
                                      control={<Radio />}
                                      label="No"
                                      value="No"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <FormControl>
                                  <FormLabel>Was pulse checked?</FormLabel>
                                  <RadioGroup
                                    aria-labelledby="pulse-check"
                                    name="pulse_check"
                                    value={values.pulse_check.toString()}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "pulse_check",
                                        e.currentTarget.value
                                      );
                                    }}
                                    row
                                  >
                                    <FormControlLabel
                                      control={<Radio />}
                                      label="Yes"
                                      value="Yes"
                                    />
                                    <FormControlLabel
                                      control={<Radio />}
                                      label="No"
                                      value="No"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <FormControl>
                                  <FormLabel>
                                    If yes, did the person have a pulse?
                                  </FormLabel>
                                  <RadioGroup
                                    aria-labelledby="have-pulse"
                                    name="have_pulse"
                                    value={values.have_pulse.toString()}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "have_pulse",
                                        e.currentTarget.value
                                      );
                                    }}
                                    row
                                  >
                                    <FormControlLabel
                                      control={<Radio />}
                                      label="Yes"
                                      value="Yes"
                                    />
                                    <FormControlLabel
                                      control={<Radio />}
                                      label="No"
                                      value="No"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <FormControl>
                                  <FormLabel>
                                    Was CPR given prior to 911 EMS arrival?
                                  </FormLabel>
                                  <RadioGroup
                                    aria-labelledby="ems-arrival"
                                    name="cpr_given"
                                    value={values.cpr_given.toString()}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "cpr_given",
                                        e.currentTarget.value
                                      );
                                    }}
                                    row
                                  >
                                    <FormControlLabel
                                      control={<Radio />}
                                      label="Yes"
                                      value="Yes"
                                    />
                                    <FormControlLabel
                                      control={<Radio />}
                                      label="No"
                                      value="No"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <TimePicker
                                  label="Estimated time CPR started"
                                  id="estimated_time_cpr"
                                  value={values.estimated_time_cpr}
                                  onChange={(newValue) => {
                                    setFieldValue(
                                      "estimated_time_cpr",
                                      newValue
                                    );
                                  }}
                                  inputFormat="HH:mm"
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      inputProps={{
                                        ...params.inputProps,
                                        placeholder: "",
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <FormControl>
                                  <FormLabel>
                                    Was CPR started prior to the arrival of a
                                    trained AED Employee?
                                  </FormLabel>
                                  <RadioGroup
                                    aria-labelledby="cpr-started"
                                    name="cpr_started_prior_aed"
                                    value={values.cpr_started_prior_aed.toString()}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "cpr_started_prior_aed",
                                        e.currentTarget.value
                                      );
                                    }}
                                    row
                                  >
                                    <FormControlLabel
                                      control={<Radio />}
                                      label="Yes"
                                      value="Yes"
                                    />
                                    <FormControlLabel
                                      control={<Radio />}
                                      label="No"
                                      value="No"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <FormControl>
                                  <FormLabel>Who started CPR?</FormLabel>
                                  <RadioGroup
                                    aria-labelledby="cpr-starter"
                                    name="who_started_cpr"
                                    value={values.who_started_cpr.toString()}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "who_started_cpr",
                                        e.currentTarget.value
                                      );
                                    }}
                                    row
                                  >
                                    <FormControlLabel
                                      control={<Radio />}
                                      label="Bystander"
                                      value="Bystander"
                                    />
                                    <FormControlLabel
                                      control={<Radio />}
                                      label="Trained AED employee"
                                      value="Trained AED employee"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <FormControl mt={3}>
                                  <CustomTextField
                                    name="cpr_person_name"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    label="Name of the person who started CPR"
                                    autoComplete="off"
                                    //placeholder="Last name"
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <FormControl>
                                  <FormLabel>
                                    Was a facility AED brought to the patient's
                                    side prior to 911 EMS arrival?
                                  </FormLabel>
                                  <RadioGroup
                                    aria-labelledby="ems-arrival"
                                    name="patient_side_prior_ems"
                                    value={values.patient_side_prior_ems.toString()}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "patient_side_prior_ems",
                                        e.currentTarget.value
                                      );
                                    }}
                                    row
                                  >
                                    <FormControlLabel
                                      control={<Radio />}
                                      label="Yes"
                                      value="Yes"
                                    />
                                    <FormControlLabel
                                      control={<Radio />}
                                      label="No"
                                      value="No"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <FormControl mt={3}>
                                  <CustomTextField
                                    name="reason_ems_arrival"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    label="If No, briefly describe why?"
                                    autoComplete="off"
                                    //placeholder="Last name"
                                  />
                                </FormControl>
                              </Grid>
                              {/* one more block */}
                              <Grid
                                item
                                xs={12}
                                md={12}
                                sx={{
                                  display:
                                    values.patient_side_prior_ems === "" ||
                                    values.patient_side_prior_ems === "Yes"
                                      ? "block"
                                      : "none",
                                }}
                              >
                                <Grid container spacing={6}>
                                  <Grid item xs={12} md={12}>
                                    <TimePicker
                                      label="If Yes, estimated time the Facility AED was brought to the patient's side"
                                      id="est_time_cpr_aed_arrive_patient"
                                      value={
                                        values.est_time_cpr_aed_arrive_patient
                                      }
                                      onChange={(newValue) => {
                                        setFieldValue(
                                          "est_time_cpr_aed_arrive_patient",
                                          newValue
                                        );
                                      }}
                                      inputFormat="HHmm"
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          fullWidth
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          inputProps={{
                                            ...params.inputProps,
                                            placeholder: "",
                                          }}
                                        />
                                      )}
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <FormControl mt={3}>
                                      <CustomTextField
                                        name="aed_manufacturer"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        label="AED manufacturer"
                                        autoComplete="off"
                                        //placeholder="Last name"
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <FormControl mt={3}>
                                      <CustomTextField
                                        name="aed_model_number"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        label="AED model number"
                                        autoComplete="off"
                                        //placeholder="Last name"
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <FormControl mt={3}>
                                      <CustomTextField
                                        name="aed_serial_number"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        label="AED serial number"
                                        autoComplete="off"
                                        //placeholder="Last name"
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <FormControl mt={3}>
                                      <CustomTextField
                                        name="aed_storage_location"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        label="AED storage location"
                                        autoComplete="off"
                                        //placeholder="Last name"
                                      />
                                    </FormControl>
                                  </Grid>

                                  <Grid item xs={12} md={12}>
                                    <FormControl>
                                      <FormLabel>
                                        Were the facility AED Pads placed on the
                                        patient?
                                      </FormLabel>
                                      <RadioGroup
                                        aria-labelledby="aed-pads"
                                        name="aed_pads_placed"
                                        value={values.aed_pads_placed.toString()}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "aed_pads_placed",
                                            e.currentTarget.value
                                          );
                                        }}
                                        row
                                      >
                                        <FormControlLabel
                                          control={<Radio />}
                                          label="Yes"
                                          value="Yes"
                                        />
                                        <FormControlLabel
                                          control={<Radio />}
                                          label="No"
                                          value="No"
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={12}>
                                    <FormControl>
                                      <FormLabel>
                                        If Yes, Was the person who put the AED
                                        Pads on the patient a:
                                      </FormLabel>
                                      <RadioGroup
                                        aria-labelledby="aed-pads-c"
                                        name="aed_pads_served_by"
                                        value={values.aed_pads_served_by.toString()}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "aed_pads_served_by",
                                            e.currentTarget.value
                                          );
                                        }}
                                        row
                                      >
                                        <FormControlLabel
                                          control={<Radio />}
                                          label="Trained employee"
                                          value="Trained employee"
                                        />
                                        <FormControlLabel
                                          control={<Radio />}
                                          label="Untrained employee"
                                          value="Untrained employee"
                                        />
                                        <FormControlLabel
                                          control={<Radio />}
                                          label="Bystander"
                                          value="Bystander"
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={12}>
                                    <FormControl>
                                      <FormLabel>
                                        Was the facility AED turned on?
                                      </FormLabel>
                                      <RadioGroup
                                        aria-labelledby="facility-turned-on"
                                        name="facility_aed_turned_on"
                                        value={values.facility_aed_turned_on.toString()}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "facility_aed_turned_on",
                                            e.currentTarget.value
                                          );
                                        }}
                                        row
                                      >
                                        <FormControlLabel
                                          control={<Radio />}
                                          label="Yes"
                                          value="Yes"
                                        />
                                        <FormControlLabel
                                          control={<Radio />}
                                          label="No"
                                          value="No"
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={12}>
                                    <TimePicker
                                      label="If Yes, estimated time the Facility AED was turned on"
                                      id="estimate-time-cpr"
                                      value={values.est_time_aed_turned_on}
                                      onChange={(newValue) => {
                                        setFieldValue(
                                          "est_time_aed_turned_on",
                                          newValue
                                        );
                                      }}
                                      inputFormat="HH:mm"
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          fullWidth
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          inputProps={{
                                            ...params.inputProps,
                                            placeholder: "",
                                          }}
                                        />
                                      )}
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={12}>
                                    <FormControl>
                                      <FormLabel>
                                        Did the Facility AED ever shock the
                                        patient?
                                      </FormLabel>
                                      <RadioGroup
                                        aria-labelledby="f-aed"
                                        name="aed_ever_shocked_patient"
                                        value={values.aed_ever_shocked_patient.toString()}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "aed_ever_shocked_patient",
                                            e.currentTarget.value
                                          );
                                        }}
                                        row
                                      >
                                        <FormControlLabel
                                          control={<Radio />}
                                          label="Yes"
                                          value="Yes"
                                        />
                                        <FormControlLabel
                                          control={<Radio />}
                                          label="No"
                                          value="No"
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={12}>
                                    <TimePicker
                                      label="If Yes, estimated time of first shock by Facility AED"
                                      id="shock-time-cpr"
                                      value={values.est_time_first_shock}
                                      onChange={(newValue) => {
                                        setFieldValue(
                                          "est_time_first_shock",
                                          newValue
                                        );
                                      }}
                                      inputFormat="HHmm"
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          fullWidth
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          inputProps={{
                                            ...params.inputProps,
                                            placeholder: "",
                                          }}
                                        />
                                      )}
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={12}>
                                    <FormControl mt={3}>
                                      <CustomTextField
                                        name="number_of_shocks"
                                        label="If shocks were given, how many shocks were delivered prior to the EMS ambulance arrival?"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        inputProps={{ maxLength: 2 }}
                                        autoComplete="off"
                                        //placeholder="Last name"
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={12}>
                                    <FormLabel>
                                      Name of person operating the Facility AED
                                    </FormLabel>
                                    <Grid container spacing={6}>
                                      <Grid item xs={12} md={4}>
                                        <CustomTextField
                                          name="aed_fname"
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          autoComplete="off"
                                          placeholder="First name"
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={4}>
                                        <CustomTextField
                                          name="aed_mname"
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          autoComplete="off"
                                          placeholder="Middle name"
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={4}>
                                        <CustomTextField
                                          name="aed_lname"
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          autoComplete="off"
                                          placeholder="Last name"
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={12} md={12}>
                                    <FormControl>
                                      <FormLabel>
                                        Is this person a trained AED employee?
                                      </FormLabel>
                                      <RadioGroup
                                        aria-labelledby="trained-aed"
                                        name="is_trained_aed"
                                        value={values.is_trained_aed.toString()}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "is_trained_aed",
                                            e.currentTarget.value
                                          );
                                        }}
                                        row
                                      >
                                        <FormControlLabel
                                          control={<Radio />}
                                          label="Yes"
                                          value="Yes"
                                        />
                                        <FormControlLabel
                                          control={<Radio />}
                                          label="No"
                                          value="No"
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={12}>
                                    <FormControl>
                                      <FormLabel>
                                        Highest level of medical training of
                                        person administering the Facility AED:
                                      </FormLabel>
                                      <RadioGroup
                                        aria-labelledby="administer-person"
                                        name="med_training_level"
                                        value={values.med_training_level.toString()}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "med_training_level",
                                            e.currentTarget.value
                                          );
                                        }}
                                        row
                                      >
                                        <FormControlLabel
                                          control={<Radio />}
                                          label="Public AED trained"
                                          value="Public AED trained"
                                        />
                                        <FormControlLabel
                                          control={<Radio />}
                                          label="Nurse/Physician"
                                          value="Nurse/Physician"
                                        />
                                        <FormControlLabel
                                          control={<Radio />}
                                          label="First responder AED trained"
                                          value="First responder AED trained"
                                        />
                                        <FormControlLabel
                                          control={<Radio />}
                                          label="Other health care provider"
                                          value="Other health care provider"
                                        />
                                        <FormControlLabel
                                          control={<Radio />}
                                          label="EMT-B"
                                          value="EMT-B"
                                        />
                                        <FormControlLabel
                                          control={<Radio />}
                                          label="No known training"
                                          value="No known training"
                                        />
                                        <FormControlLabel
                                          control={<Radio />}
                                          label="CRT/EMP-P"
                                          value="CRT/EMP-P"
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={12}>
                                    <FormControl>
                                      <FormLabel>
                                        Was there any mechanincal difficulty or
                                        failure associated with the use of the
                                        facility AED?
                                      </FormLabel>
                                      <RadioGroup
                                        aria-labelledby="failure-associated"
                                        name="aed_mechanical_failure"
                                        value={values.aed_mechanical_failure.toString()}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "aed_mechanical_failure",
                                            e.currentTarget.value
                                          );
                                        }}
                                        row
                                      >
                                        <FormControlLabel
                                          control={<Radio />}
                                          label="Yes"
                                          value="Yes"
                                        />
                                        <FormControlLabel
                                          control={<Radio />}
                                          label="No"
                                          value="No"
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} lg={12}>
                                    <FormControl>
                                      <FormLabel>Narrative</FormLabel>
                                      <FormHelperText sx={{ marginLeft: 0 }}>
                                        If Yes, Briefly explain and discuss with
                                        your medical oversight provider as other
                                        forms may be required
                                      </FormHelperText>
                                      <Box
                                        className={classes.textEditor}
                                        sx={{ marginTop: "15px" }}
                                      >
                                        <MUIRichTextEditor
                                          label={
                                            <Typography
                                              sx={{ padding: "15px" }}
                                            >
                                              Type something here...
                                            </Typography>
                                          }
                                          // defaultValue={values.narrative}
                                          inlineToolbar={true}
                                          onChange={onEditorMedicalChange}
                                        />
                                      </Box>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={12}>
                                    <FormControl>
                                      <FormLabel>
                                        Were there any expected events or
                                        injuries that occured during the use of
                                        the Facility AED?
                                      </FormLabel>
                                      <RadioGroup
                                        aria-labelledby="expected-injuried"
                                        name="unexpected_injuries_aed"
                                        value={values.unexpected_injuries_aed.toString()}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "unexpected_injuries_aed",
                                            e.currentTarget.value
                                          );
                                        }}
                                        row
                                      >
                                        <FormControlLabel
                                          control={<Radio />}
                                          label="Yes"
                                          value="Yes"
                                        />
                                        <FormControlLabel
                                          control={<Radio />}
                                          label="No"
                                          value="No"
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} lg={12}>
                                    <FormControl>
                                      {/* <FormLabel>Narrative</FormLabel> */}
                                      <FormHelperText sx={{ marginLeft: 0 }}>
                                        If Yes, Briefly explain
                                      </FormHelperText>
                                      <Box
                                        className={classes.textEditor}
                                        sx={{ marginTop: "15px" }}
                                      >
                                        <MUIRichTextEditor
                                          label={
                                            <Typography
                                              sx={{ padding: "15px" }}
                                            >
                                              Type something here...
                                            </Typography>
                                          }
                                          // defaultValue={values.narrative}
                                          onChange={onEditorInjuryChange}
                                          inlineToolbar={true}
                                        />
                                      </Box>
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              </Grid>
                              {/* one more block */}
                              <Grid item xs={12} md={12}>
                                <FormLabel>
                                  Indicate the patient's status at the time of
                                  911 EMS arrival
                                </FormLabel>
                                <Grid container spacing={6}>
                                  <Grid item xs={12} md={6}>
                                    <FormControl>
                                      <FormLabel>Pulse restored</FormLabel>
                                      <RadioGroup
                                        aria-labelledby="pulse-restored"
                                        name="pulse_restored"
                                        value={values.pulse_restored.toString()}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "pulse_restored",
                                            e.currentTarget.value
                                          );
                                        }}
                                        row
                                      >
                                        <FormControlLabel
                                          control={<Radio />}
                                          label="Yes"
                                          value="Yes"
                                        />
                                        <FormControlLabel
                                          control={<Radio />}
                                          label="No"
                                          value="No"
                                        />
                                        <FormControlLabel
                                          control={<Radio />}
                                          label="Don't know"
                                          value="Don't know"
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <FormControl>
                                      <TimePicker
                                        label="If Yes, time pulse restored"
                                        id="pulse-restored"
                                        value={values.time_pulse_restored}
                                        onChange={(newValue) => {
                                          setFieldValue(
                                            "time_pulse_restored",
                                            newValue
                                          );
                                        }}
                                        inputFormat="HHmm"
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            fullWidth
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            inputProps={{
                                              ...params.inputProps,
                                              placeholder: "",
                                            }}
                                          />
                                        )}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <FormControl>
                                      <FormLabel>Breathing restored</FormLabel>
                                      <RadioGroup
                                        aria-labelledby="breathing-restored"
                                        name="breathing_restored"
                                        value={values.breathing_restored.toString()}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "breathing_restored",
                                            e.currentTarget.value
                                          );
                                        }}
                                        row
                                      >
                                        <FormControlLabel
                                          control={<Radio />}
                                          label="Yes"
                                          value="Yes"
                                        />
                                        <FormControlLabel
                                          control={<Radio />}
                                          label="No"
                                          value="No"
                                        />
                                        <FormControlLabel
                                          control={<Radio />}
                                          label="Don't know"
                                          value="Don't know"
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <FormControl>
                                      <TimePicker
                                        label="If Yes, time breathing restored"
                                        id="time-breathing-restored"
                                        value={values.time_breathing_restored}
                                        onChange={(newValue) => {
                                          setFieldValue(
                                            "time_breathing_restored",
                                            newValue
                                          );
                                        }}
                                        inputFormat="HHmm"
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            fullWidth
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            inputProps={{
                                              ...params.inputProps,
                                              placeholder: "",
                                            }}
                                          />
                                        )}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <FormControl>
                                      <FormLabel>
                                        Responsiveness restored
                                      </FormLabel>
                                      <RadioGroup
                                        aria-labelledby="responsiveness-restored"
                                        name="responsiveness_restored"
                                        value={values.responsiveness_restored.toString()}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "responsiveness_restored",
                                            e.currentTarget.value
                                          );
                                        }}
                                        row
                                      >
                                        <FormControlLabel
                                          control={<Radio />}
                                          label="Yes"
                                          value="Yes"
                                        />
                                        <FormControlLabel
                                          control={<Radio />}
                                          label="No"
                                          value="No"
                                        />
                                        <FormControlLabel
                                          control={<Radio />}
                                          label="Don't know"
                                          value="Don't know"
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <FormControl>
                                      <TimePicker
                                        label="If Yes, time patient was responsive"
                                        id="pulse-restored"
                                        value={
                                          values.time_responsiveness_restored
                                        }
                                        onChange={(newValue) => {
                                          setFieldValue(
                                            "time_responsiveness_restored",
                                            newValue
                                          );
                                        }}
                                        inputFormat="HHmm"
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            fullWidth
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            inputProps={{
                                              ...params.inputProps,
                                              placeholder: "",
                                            }}
                                          />
                                        )}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <FormControl>
                                      <FormLabel>
                                        Signs of circulation
                                      </FormLabel>
                                      <RadioGroup
                                        aria-labelledby="circulations-signs"
                                        name="sign_of_circulation"
                                        value={values.sign_of_circulation.toString()}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "sign_of_circulation",
                                            e.currentTarget.value
                                          );
                                        }}
                                        row
                                      >
                                        <FormControlLabel
                                          control={<Radio />}
                                          label="Yes"
                                          value="Yes"
                                        />
                                        <FormControlLabel
                                          control={<Radio />}
                                          label="No"
                                          value="No"
                                        />
                                        <FormControlLabel
                                          control={<Radio />}
                                          label="Don't know"
                                          value="Don't know"
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <FormControl>
                                      <TimePicker
                                        label="If Yes, time circulation returned"
                                        id="time_sign_of_circulation"
                                        value={values.time_sign_of_circulation}
                                        onChange={(newValue) => {
                                          setFieldValue(
                                            "time_sign_of_circulation",
                                            newValue
                                          );
                                        }}
                                        inputFormat="HHmm"
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            fullWidth
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            inputProps={{
                                              ...params.inputProps,
                                              placeholder: "",
                                            }}
                                          />
                                        )}
                                      />
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <FormControl>
                                  <FormLabel>
                                    Was the patient transported to the hospital?
                                  </FormLabel>
                                  <RadioGroup
                                    aria-labelledby="patient-transportation"
                                    name="patient_trans_hospital"
                                    value={values.patient_trans_hospital.toString()}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "patient_trans_hospital",
                                        e.currentTarget.value
                                      );
                                    }}
                                    row
                                  >
                                    <FormControlLabel
                                      control={<Radio />}
                                      label="Yes"
                                      value="Yes"
                                    />
                                    <FormControlLabel
                                      control={<Radio />}
                                      label="No"
                                      value="No"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <FormControl>
                                  <FormLabel>
                                    If Yes, How was the patient transported to
                                    the hospital?
                                  </FormLabel>
                                  <RadioGroup
                                    aria-labelledby="patient-transportation"
                                    name="mode_of_trans_hospital"
                                    value={values.mode_of_trans_hospital.toString()}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "mode_of_trans_hospital",
                                        e.currentTarget.value
                                      );
                                    }}
                                    row
                                  >
                                    <FormControlLabel
                                      control={<Radio />}
                                      label="EMS Ambulance"
                                      value="EMS Ambulance"
                                    />
                                    <FormControlLabel
                                      control={<Radio />}
                                      label="Private Vehicle"
                                      value="Private Vehicle"
                                    />
                                    <FormControlLabel
                                      control={<Radio />}
                                      label="Other"
                                      value="Other"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <FormControl mt={3}>
                                  <CustomTextField
                                    name="hopital_name"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    label="Name of hospital"
                                    autoComplete="off"
                                    //placeholder="Last name"
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <FormControl mt={3}>
                                  <CustomTextField
                                    name="attending_physician"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    label="Attending physician (if known)"
                                    autoComplete="off"
                                    //placeholder="Last name"
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} lg={12}>
                                <FormControl>
                                  <FormLabel>
                                    Followup on patient status after hospital
                                    admmitance (if known)
                                  </FormLabel>
                                  <Box
                                    className={classes.textEditor}
                                    sx={{ marginTop: "15px" }}
                                  >
                                    <MUIRichTextEditor
                                      label={
                                        <Typography sx={{ padding: "15px" }}>
                                          Type something here...
                                        </Typography>
                                      }
                                      // defaultValue={values.narrative}
                                      inlineToolbar={true}
                                      onChange={onEditorPatientChange}
                                    />
                                  </Box>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <FormControl>
                                  <FormLabel>
                                    Did the AED remain at the facility?
                                  </FormLabel>
                                  <RadioGroup
                                    aria-labelledby="aed-fcility"
                                    name="aed_remains"
                                    value={values.aed_remains.toString()}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "aed_remains",
                                        e.currentTarget.value
                                      );
                                    }}
                                    row
                                  >
                                    <FormControlLabel
                                      control={<Radio />}
                                      label="Yes"
                                      value="Yes"
                                    />
                                    <FormControlLabel
                                      control={<Radio />}
                                      label="No"
                                      value="No"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <FormControl>
                                  <CustomTextField
                                    name="aed_remains_reason"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    label="If No, briefly describe the reason"
                                    autoComplete="off"
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <FormControl>
                                  <FormLabel>
                                    Did the memory device remain at the
                                    facility?
                                  </FormLabel>
                                  <RadioGroup
                                    aria-labelledby="memory-device"
                                    name="memory_device_remains"
                                    value={values.memory_device_remains.toString()}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "memory_device_remains",
                                        e.currentTarget.value
                                      );
                                    }}
                                    row
                                  >
                                    <FormControlLabel
                                      control={<Radio />}
                                      label="Yes"
                                      value="Yes"
                                    />
                                    <FormControlLabel
                                      control={<Radio />}
                                      label="No"
                                      value="No"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <FormControl>
                                  <CustomTextField
                                    name="memory_device_remains_reason"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    label="If No, briefly describe the reason"
                                    autoComplete="off"
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <FormControl>
                                  <FormLabel>
                                    Was the AED removed from the service?
                                  </FormLabel>
                                  <RadioGroup
                                    aria-labelledby="memory-device"
                                    name="aed_removed"
                                    value={values.aed_removed.toString()}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "aed_removed",
                                        e.currentTarget.value
                                      );
                                    }}
                                    row
                                  >
                                    <FormControlLabel
                                      control={<Radio />}
                                      label="Yes"
                                      value="Yes"
                                    />
                                    <FormControlLabel
                                      control={<Radio />}
                                      label="No"
                                      value="No"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <FormLabel>Reported completed by</FormLabel>
                                <Grid container spacing={6}>
                                  <Grid item xs={12} md={6}>
                                    <FormControl>
                                      <CustomTextField
                                        name="report_completed_by_fname"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        placeholder="First name"
                                        autoComplete="off"
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <FormControl>
                                      <CustomTextField
                                        name="report_completed_by_lname"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        placeholder="Last name"
                                        autoComplete="off"
                                      />
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <FormControl>
                                  <CustomTextField
                                    name="report_title"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    placeholder="Title"
                                    autoComplete="off"
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <FormControl>
                                  <FormLabel>Signature</FormLabel>
                                  <Card
                                    variant="outlined"
                                    className="signatureCanvas"
                                  >
                                    <CardContent>
                                      <SignatureCanvas ref={sigCanvas} />
                                    </CardContent>
                                    <CardActions>
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={savePad}
                                      >
                                        Save
                                      </Button>
                                      <Button
                                        color="primary"
                                        variant="outlined"
                                        onClick={clearPad}
                                      >
                                        Clear
                                      </Button>
                                    </CardActions>
                                  </Card>
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      )}

                      {/* End section box from anyone injured to reporting officer */}
                      <Grid item xs={12} lg={12}>
                        <FormControl>
                          <FormLabel>Anyone injured?</FormLabel>
                          <RadioGroup
                            aria-labelledby="anyone-injured2"
                            name="anyone_injured"
                            value={values.anyone_injured}
                            onChange={(e) => {
                              setFieldValue(
                                "anyone_injured",
                                e.currentTarget.value
                              );
                              setFieldValue("supplier_detail", "");
                              setFieldValue("destroy_sign", "");
                              setFieldValue("alcohol_involved", "");
                              setFieldValue("witness_incident", "");
                              setFieldValue("witness_person", "");
                              setFieldValue("witness_address", "");
                              setFieldValue("witness_city", "");
                              setFieldValue("witness_state", "");
                              setFieldValue("witness_zip", "");
                              setFieldValue("witness_phone", "");
                            }}
                            row
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Yes"
                              value="Yes"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="No"
                              value="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        lg={12}
                        sx={{
                          display:
                            values.anyone_injured === "Yes" ? "block" : "none",
                        }}
                      >
                        <FormControl>
                          <CustomTextField
                            name="supplier_detail"
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label="List manufacturer (if any)"
                            autoComplete="off"
                            placeholder="Name and address of manufacturer or supplier of product or machine involved in incident"
                          />
                        </FormControl>
                        <Box sx={{ marginTop: "20px" }}>
                          <Grid container spacing={6}>
                            <Grid item xs={12} lg={12}>
                              <FormControl>
                                <FormLabel>
                                  Has the product or machine been stored with a
                                  "Do Not Destroy" Sign?
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="destroy-sign"
                                  name="destroy_sign"
                                  value={values.destroy_sign}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "destroy_sign",
                                      e.currentTarget.value
                                    )
                                  }
                                  row
                                >
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Yes"
                                    value="Yes"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="No"
                                    value="No"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="N/A"
                                    value="N/A"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                              <FormControl>
                                <FormLabel>
                                  Alcohol or drugs involved?
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="alcohol-involved"
                                  name="alcohol_involved"
                                  value={values.alcohol_involved}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "alcohol_involved",
                                      e.currentTarget.value
                                    )
                                  }
                                  row
                                >
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Yes"
                                    value="Yes"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="No"
                                    value="No"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="N/A"
                                    value="N/A"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                              <FormControl>
                                <FormLabel>Did you witness incident?</FormLabel>
                                <RadioGroup
                                  aria-labelledby="witness-incident"
                                  name="witness_incident"
                                  value={values.witness_incident}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "witness_incident",
                                      e.currentTarget.value
                                    );
                                    setFieldValue("witness_person", "");
                                    setFieldValue("witness_address", "");
                                    setFieldValue("witness_city", "");
                                    setFieldValue("witness_state", "");
                                    setFieldValue("witness_zip", "");
                                    setFieldValue("witness_phone", "");
                                  }}
                                  row
                                >
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Yes"
                                    value="Yes"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="No"
                                    value="No"
                                  />
                                </RadioGroup>
                                <FormHelperText
                                  error={true}
                                  sx={{
                                    display:
                                      values.witness_incident === "Yes"
                                        ? "block"
                                        : "none",
                                    marginLeft: 0,
                                  }}
                                >
                                  Please add your self as a witness in the
                                  involved persons section and complete a
                                  witness statement.
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              lg={12}
                              sx={{
                                display:
                                  values.witness_incident === "No"
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={6} lg={4}>
                                  <FormControl>
                                    <CustomTextField
                                      name="witness_person"
                                      fullWidth
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      label="Name of the person who informed you of the incident"
                                      autoComplete="off"
                                      placeholder="Mike Smith"
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <FormControl>
                                    <CustomTextField
                                      name="witness_address"
                                      fullWidth
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      label="Address"
                                      autoComplete="off"
                                      placeholder="1234 First Ave"
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <FormControl>
                                    <CustomTextField
                                      name="witness_city"
                                      fullWidth
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      label="City"
                                      autoComplete="off"
                                      placeholder="Miami"
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <FormControl mt={3}>
                                    <CustomTextField
                                      name="witness_state"
                                      fullWidth
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      label="State"
                                      autoComplete="off"
                                      placeholder="FL"
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <FormControl mt={3}>
                                    <CustomTextField
                                      name="witness_zip"
                                      fullWidth
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      label="Zip"
                                      autoComplete="off"
                                      placeholder="xxxxx"
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <FormControl mt={3}>
                                    <CustomTextField
                                      name="witness_phone"
                                      fullWidth
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      label="Phone"
                                      autoComplete="off"
                                      placeholder="xxx-xxx-xxxx"
                                    />
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <FormControl mt={3}>
                          <CustomSelection
                            name="weather_conditions"
                            label="Weather conditions"
                            options={weatherconditions}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <FormControl mt={3}>
                          <CustomSelection
                            name="lighting_conditions"
                            label="Lighting conditions"
                            options={lightingconditions}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <FormControl>
                          <CustomTextField
                            name="private_notes"
                            label="Private notes"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            autoComplete="off"
                            placeholder="Notes added here do not appear on the printed report"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <FormControl>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <FormLabel>References</FormLabel>
                            <Tooltip title="Connect other IRs or POI records to this IR">
                              <ErrorIcon color="primary" />
                            </Tooltip>
                          </Box>
                          <Button
                            sx={{ width: "fit-content", marginTop: "5px" }}
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              setTabStatus("referenceClick");
                              if (incidentId === "") {
                                saveNewIncident(values);
                              } else {
                                referenceOpen();
                              }
                            }}
                          >
                            <AddIcon /> Add references
                          </Button>
                        </FormControl>
                        {reference && (
                          <References
                            reference={reference}
                            setReference={setReference}
                            referenceOpen={referenceOpen}
                            referenceClose={referenceClose}
                            incidentId={incidentId}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <Box className={classes.accordion}>
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="financial-information-title"
                              id="financial-information"
                            >
                              <Typography>Financial Information</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <IconButton
                                onClick={() => {
                                  setFinanceActionstatus(false);
                                  setTabStatus("financeClick");
                                  if (incidentId === "") {
                                    saveNewIncident(values);
                                  } else {
                                    financialReportDialogOpen();
                                  }
                                }}
                              >
                                <AddIcon />
                              </IconButton>
                              <TableContainer className="tableContainer">
                                <Table stickyHeader aria-label="sticky table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>
                                        Category of Loss/Recovery
                                      </TableCell>
                                      <TableCell>Description</TableCell>
                                      <TableCell>Total</TableCell>
                                      <TableCell>Action</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  {loading ? (
                                    <TableBody>
                                      <TableRow>
                                        <TableCell colSpan={4} align="center">
                                          <CircularProgress />
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  ) : (
                                    <TableBody>
                                      {FIListing.map((item) => {
                                        return (
                                          <TableRow>
                                            <TableCell
                                              sx={{ whiteSpace: "nowrap" }}
                                            >
                                              {item.category_of_loss}
                                            </TableCell>
                                            <TableCell
                                              sx={{
                                                whiteSpace: "nowrap",
                                                minWidth: "300px",
                                              }}
                                            >
                                              {item.description}
                                            </TableCell>
                                            <TableCell>{item.cost}</TableCell>
                                            <TableCell>
                                              <IconButton
                                                aria-label="Settings"
                                                size="large"
                                                aria-owns={
                                                  financialActionDropdown
                                                    ? "financial-menu"
                                                    : undefined
                                                }
                                                aria-haspopup="true"
                                                onClick={(event) =>
                                                  financialActionDropdownClick(
                                                    event,
                                                    item.id
                                                  )
                                                }
                                                style={{ color: "#1374C5" }}
                                              >
                                                <SettingsIcon />
                                              </IconButton>
                                              <StyledMenu
                                                id="financial-menu"
                                                anchorEl={
                                                  financialActionDropdown
                                                }
                                                open={Boolean(
                                                  financialActionDropdown
                                                )}
                                                onClose={
                                                  financialActionDropdownClose
                                                }
                                                anchorOrigin={{
                                                  vertical: "bottom",
                                                  horizontal: "right",
                                                }}
                                                transformOrigin={{
                                                  vertical: "top",
                                                  horizontal: "right",
                                                }}
                                              >
                                                <MenuItem onClick={viewFinance}>
                                                  <ListItemIcon>
                                                    <VisibilityIcon />
                                                  </ListItemIcon>
                                                  <ListItemText
                                                    primary="View"
                                                    pl={1}
                                                  />
                                                </MenuItem>
                                                <MenuItem onClick={editFinance}>
                                                  <ListItemIcon>
                                                    <EditIcon />
                                                  </ListItemIcon>
                                                  <ListItemText
                                                    primary="Edit"
                                                    pl={1}
                                                  />
                                                </MenuItem>
                                                <MenuItem
                                                  onClick={() => {
                                                    financialActionDropdownClose();
                                                    DeleteFinanceInvolved();
                                                  }}
                                                >
                                                  <ListItemIcon>
                                                    <DeleteIcon />
                                                  </ListItemIcon>
                                                  <ListItemText
                                                    primary="Delete"
                                                    pl={1}
                                                  />
                                                </MenuItem>
                                              </StyledMenu>
                                            </TableCell>
                                          </TableRow>
                                        );
                                      })}
                                      {FIListing.length == 0 && (
                                        <TableRow>
                                          <TableCell colSpan={7} align="center">
                                            No record added
                                          </TableCell>
                                        </TableRow>
                                      )}
                                    </TableBody>
                                  )}

                                  <TableFooter>
                                    <TableRow>
                                      <TableCell colSpan="2"></TableCell>
                                      <TableCell>
                                        Total: {totalCost || ""} USD
                                      </TableCell>
                                      <TableCell></TableCell>
                                    </TableRow>
                                  </TableFooter>
                                </Table>
                              </TableContainer>
                            </AccordionDetails>
                          </Accordion>
                        </Box>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <AndormentFormControl>
                          <FormControl mt={3}>
                            <Box>
                              <FormLabel>
                                Incident confidentiality classification (ICC) *
                              </FormLabel>
                              <StyledTooltip
                                sx={{
                                  "& .MuiTooltip-tooltip": {
                                    padding: "0px",
                                  },
                                }}
                                title={
                                  <Card>
                                    <CardContent>
                                      <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                      >
                                        ICC Classifications
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        color="text.secondary"
                                      >
                                        <strong>Standard</strong> - No
                                        restrictions on visibility, sharing, or
                                        notifications.
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        color="text.secondary"
                                      >
                                        <strong>Elevated</strong> - IR's do not
                                        appear in shift reports unless fetched
                                        prior to ICC change. Can be emailed by
                                        admins only.
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        color="text.secondary"
                                      >
                                        <strong>Sensitive</strong> - Highest
                                        level. IR's do not appear in shift
                                        reports. Only visible to admins in the
                                        IR list. Cannot be emailed. No
                                        notifications send when saved, even if
                                        marked critical. PDF's can be downloaded
                                        by admins only.
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                }
                                placement="top-end"
                              >
                                <IconButton
                                  color="primary"
                                  sx={{
                                    padding: "0px",
                                    marginRight: "-2.5px",
                                  }}
                                >
                                  <ErrorIcon />
                                </IconButton>
                              </StyledTooltip>
                            </Box>

                            <CustomSelection
                              name="icc_classification"
                              options={iccclassification}
                            />
                          </FormControl>
                        </AndormentFormControl>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <FormControl mt={3}>
                          <CustomSelection
                            name="reporting_officer"
                            label="Reporting officer"
                            options={reportingOfficer}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </FormControl>
                      </Grid>
                      {values.reporting_officer == "0" && (
                        <Grid item xs={12} lg={4}>
                          <FormControl mt={3}>
                            <CustomTextField
                              name="other_name"
                              fullWidth
                              value={values.other_name}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              label="Officer Name"
                              autoComplete="off"
                              placeholder="Bobe Smith"
                            />
                          </FormControl>
                        </Grid>
                      )}
                      <Grid item xs={12} md={6}>
                        <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                          sx={{ margin: "15px 0 0" }}
                        >
                          Save incident report
                        </Button>
                      </Grid>

                      <Grid item xs={12} md={6} textAlign="right">
                        <Button
                          type="button"
                          color="primary"
                          variant="outlined"
                          onClick={investigativeReportOpen}
                        >
                          Add investigative report
                        </Button>
                        <Button
                          sx={{ marginLeft: "10px" }}
                          type="button"
                          color="primary"
                          variant="outlined"
                          onClick={() => {
                            setTabStatus("uofClick");
                            if (incidentId === "") {
                              saveNewIncident(values);
                            } else {
                              addUseOfForceOpen();
                            }
                          }}
                        >
                          Edit use of force
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            </form>
          </>
        )}
      </Formik>

      {/* Modal for Edit use of Force */}
      {addUseOfForce && (
        <AddUseOfForce
          addUseOfForce={addUseOfForce}
          setAddUseOfForce={setAddUseOfForce}
          addUseOfForceOpen={addUseOfForceOpen}
          addUseOfForceClose={addUseOfForceClose}
          incidentId={incidentId}
          setPageLoad={setPageLoad}
        />
      )}

      {addLocationOnMap && (
        <AddLocationOnMap
          addLocationOnMap={addLocationOnMap}
          setAddLocationOnMap={setAddLocationOnMap}
          addLocationOnMapOpen={addLocationOnMapOpen}
          addLocationOnMapClose={addLocationOnMapClose}
          lat={lat}
          setLat={setLat}
          lng={lng}
          setLng={setLng}
        />
      )}
      {personInvolved && (
        <AddPersonInvolved
          personInvolved={personInvolved}
          setPersonInvolved={setPersonInvolved}
          personInvolvedOpen={personInvolvedOpen}
          personInvolvedClose={personInvolvedClose}
          incidentId={incidentId}
          getInvolvedPersonListing={getInvolvedPersonListing}
          pageLoad={pageLoad}
          setPageLoad={setPageLoad}
          editPIData={editPIData}
          personActionStatus={personActionStatus}
          involvedPerId={involvedPerId}
        />
      )}
      {vehicleInvolved && (
        <VehicleInvolved
          vehicleInvolved={vehicleInvolved}
          setVehicleInvolved={setVehicleInvolved}
          vehicleInvolvedOpen={vehicleInvolvedOpen}
          vehicleInvolvedClose={vehicleInvolvedClose}
          incidentId={incidentId}
          getInvolvedVehicleListing={getInvolvedVehicleListing}
          vehicleData={vehicleData}
          vehicleActionStatus={vehicleActionStatus}
        />
      )}
      {witnessStatementModal && (
        <WitnessStatement
          witnessStatementModal={witnessStatementModal}
          setWitnessStatementModal={setWitnessStatementModal}
          witnessStatementModalOpen={witnessStatementModalOpen}
          witnessStatementModalClose={witnessStatementModalClose}
          incidentId={incidentId}
          getInvolvedWitnessListing={getInvolvedWitnessListing}
          setPageLoad={setPageLoad}
          editWIData={editWIData}
        />
      )}
      {mediaUpload && (
        <MediaUpload
          mediaUpload={mediaUpload}
          setMediaUpload={setMediaUpload}
          mediaUploadOpen={mediaUploadOpen}
          mediaUploadClose={mediaUploadClose}
          incidentId={incidentId}
          getMediaListing={() => getMediaListing()}
        />
      )}
      {investigativeReport && (
        <InvestigativeReport
          investigativeReport={investigativeReport}
          setInvestigativeReport={setInvestigativeReport}
          investigativeReportOpen={investigativeReportOpen}
          investigativeReportClose={investigativeReportClose}
          editableData={editableData}
        />
      )}
      {snackbarStatus && (
        <Toaster
          openSnack={snackbarStatus}
          handleCloseSnack={handleCloseSnack}
          severity={snackbarAlert}
          message={snackbarMsg}
        />
      )}

      {financialReportDialog && (
        <AddEditFinancialReport
          financialReportDialog={financialReportDialog}
          setFinancialReportDialog={setFinancialReportDialog}
          financialReportDialogOpen={financialReportDialogOpen}
          financialReportDialogClose={financialReportDialogClose}
          incidentId={incidentId}
          getInvolvedFinanceListing={getInvolvedFinanceListing}
          setPageLoad={setPageLoad}
          editFIData={editFIData}
          getFinanceInvolved={getFinanceInvolved}
          financeActionstatus={financeActionstatus}
          financeViewActionstatus={financeViewActionstatus}
        />
      )}
      {pageLoad && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
}
function EditIncidentReport() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleActionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };
  // Add Appended Narrative Modal
  const [addAppendedNarrative, setAddAppendedNarrative] = useState(false);
  const addAppendedNarrativeOpen = () => {
    setAddAppendedNarrative(true);
  };
  const addAppendedNarrativeClose = () => {
    setAddAppendedNarrative(false);
  };
  // CommunicationLog Modal
  const [communicationLog, setCommunicationLog] = useState(false);
  const communicationLogOpen = () => {
    setCommunicationLog(true);
  };
  const communicationLogClose = () => {
    setCommunicationLog(false);
  };
  return (
    <React.Fragment>
      <Helmet title="Edit Incident Report" />
      <Grid container>
        <Grid item xs={9} md={10}>
          <Typography variant="h3" gutterBottom display="inline">
            Edit Incident Report
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/reports/incidents">
              Incident Report
            </Link>
            <Typography>Edit Incident Report</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={3} md={2} textAlign="right">
          <IconButton
            aria-owns={anchorEl ? "icon-menu" : undefined}
            aria-haspopup="true"
            onClick={handleActionClick}
            variant="contained"
            color="secondary"
          >
            <FilterListIcon />
          </IconButton>
          <StyledFilterMenu
            id="icon-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleActionClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem
              onClick={() => {
                addAppendedNarrativeOpen();
              }}
            >
              <ListItemIcon>
                <ControlPointDuplicateIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="Append Narrative" pl={0} />
            </MenuItem>
            <MenuItem onClick={communicationLogOpen}>
              <ListItemIcon>
                <BuildIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="Comm Log" pl={0} />
            </MenuItem>
          </StyledFilterMenu>
        </Grid>
      </Grid>
      {/* <Typography variant="h3" gutterBottom display="inline">
        Edit Incident Report
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/reports/incidents">
          Incident Report
        </Link>
        <Typography>Add Incident Report</Typography>
      </Breadcrumbs>*/}

      {communicationLog && (
        <CommunicationLog
          communicationLog={communicationLog}
          setCommunicationLog={setCommunicationLog}
          communicationLogClose={communicationLogClose}
          communicationLogOpen={communicationLogOpen}
        />
      )}
      {addAppendedNarrative && (
        <AddAppendedNarrative
          addAppendedNarrative={addAppendedNarrative}
          setAddAppendedNarrative={setAddAppendedNarrative}
          addAppendedNarrativeOpen={addAppendedNarrativeOpen}
          addAppendedNarrativeClose={addAppendedNarrativeClose}
        />
      )}

      <Divider my={6} />
      <EditIncidentReportForm />
    </React.Fragment>
  );
}

export default EditIncidentReport;
