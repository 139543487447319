import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { handleApiResonseErrors } from "../../helper/helper";
import moment from "moment";
import DialogBox from "../../components/form-components/DialogBox";
import CustomAlert from "../../components/form-components/CustomAlert";
import CustomDateRange from "../../components/form-components/CustomDateRange";
import { CSVLink } from "react-csv";
import { format } from "date-fns";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import ActivityLogsCol from "../table-columns-json/lostfound/ActivityLogs";
import { useSelector, useDispatch } from "react-redux";
import StyledColumnOptions from "../../helper/StyledColumnOptions";
import { setActivityLogs } from "../../redux/slices/lostfound/lostfoundTableCalSlice";
import {
  LostFoundActivityLogs,
  RemoveActivityLogs,
  CategoryList,
} from "../../api";

import {
  Checkbox,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  InputAdornment,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  CardContent,
  Card,
  Box,
  Button,
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  CircularProgress,
} from "@mui/material";
import {
  FilterList as FilterListIcon,
  Settings as SettingsIcon,
  ExpandMore as ExpandMoreIcon,
  InsertDriveFile as InsertDriveFileIcon,
  PictureAsPdf as PictureAsPdfIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledForm = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    marginLeft: "75px",
    marginTop: "8px",
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      minWidth: "435px",
      "& form": {
        padding: "15px 20px",
      },
      "& .MuiMenuItem-root": {
        padding: "0",
        display: "block",
        "& .MuiFormControl-root": {
          display: "block",
          width: "100%",
          marginBottom: "20px",
        },
      },
      "& .MuiMenuItem-root:hover": {
        background: "transparent",
      },
      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));
const useStyles = makeStyles((theme) => ({
  dateRangeDropdown: {
    position: "absolute",
    left: 0,
    top: "100%",
    width: "100%",
    zIndex: 2,
    marginLeft: "0 !important",
    maxHeight: "200px",
    overflowY: "auto",

    "& .MuiBox-root": {
      padding: "15px",
      "& .MuiButton-contained": {
        marginLeft: "15px",
      },
    },
    "& .MuiList-root": {
      padding: 0,
      "& .MuiListItem-root:hover": {
        backgroundColor: "#376fd012",
      },
    },
  },
  cardHeader: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "140px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    tableCalRedux,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all data" }}
          />
        </TableCell>
        {ActivityLogsCol.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            sx={{
              whiteSpace: "nowrap",
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={tableCalRedux[headCell.id] === true ? "" : "hide-cell"}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {headCell.icon}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
const EnhancedTableToolbar = (props) => {
  const {
    numSelected,
    selectedRecord,
    alertMessage,
    alertType,
    showAlert,
    getLogsList,
    resetSelected,
    tableData,
    catglist,
    filterRecord,
    showAlertModal,
    totalRecordinDb,
    tableCalRedux,
  } = props;
  const [actions, setActions] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const LoggedInUserInfo = useSelector((state) => state.login.user);
  const CurrentLocationInfo = useSelector((state) => state.login.Location);

  const dispatch = useDispatch();
  const defaultTableCalRedux = useSelector(
    (state) => state.defaultLostfoundTableCal.activityLogs
  );

  const handleChange = (event) => {
    setActions(event.target.value);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // Search Dropdown
  const [anchorForm, setAnchorForm] = useState(null);
  const handleClickDropdown = (event) => {
    setAnchorForm(event.currentTarget);
  };

  const handleCloseForm = () => {
    setAnchorForm(null);
  };
  const [value, setValue] = useState(null);
  // Columns Dropdown
  const [anchorColumn, setAnchorColumn] = useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };
  const classes = useStyles();
  // Faded Item
  const [fadebox, setFadedBox] = useState(false);
  const [startDateToFilter, setStartDateToFilter] = useState("");
  const [endDateToFilter, setEndDateToFilter] = useState("");
  const [searchLostfoundId, setSearchLostfoundId] = useState("");
  const [searchUserName, setSearchUserName] = useState("");
  // Select Category
  const [category, setCategory] = useState("");
  const selectCategory = (event) => {
    setCategory(event.target.value);
  };

  const removeRecords = () => {
    if (numSelected > 0) {
      setModalMessage("Are you sure you want to delete?");
    } else {
      setModalMessage("You haven't selected any item!");
    }
    setShowDeleteModal(true);
  };
  const handelDeleteAction = (status) => {
    if (status === true && selectedRecord.length > 0) {
      RemoveActivityLogs({ ids: selectedRecord })
        .then((res) => {
          setShowDeleteModal(false);
          if (res.statusCode == 200) {
            alertMessage(
              "A total of " + numSelected + "record(s) have been deleted."
            );
            alertType("success");
            showAlert(true);
            getLogsList();
            resetSelected();
          } else {
            alertMessage(res.message);
            alertType("error");
            showAlert(true);
          }
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            alertMessage(error);
            alertType("error");
            showAlert(true);
          }
        });
    } else {
      setShowDeleteModal(false);
    }
  };

  let exportCSVheaders = [
    { label: "ID", key: "id" },
    { label: "Activity", key: "activity" },
    { label: "Category", key: "category" },
    { label: "Description", key: "description" },
    { label: "User", key: "user_name" },
    { label: "Datetime", key: "created" },
  ];

  const generatePdf = () => {
    const doc = new jsPDF();
    // autoTable(doc, { html: "#my-table" });

    const newDatePDF = moment(new Date()).format("MM/DD/YYYY");
    const newTimePDF = moment(new Date()).format("HH:mm:ss");
    var img = new Image();
    img.src = "/static/img/brands/logo.png";
    doc.addImage(img, "png", 10, 10, 45, 20);

    doc.setFontSize(18);
    doc.setFont("helvetica", "bold");
    doc.text(100, 40, "Lost and Found Logs", "center");

    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text(100, 48, CurrentLocationInfo.LocationName, "center");

    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text(`Created By : `, 145, 60);

    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text(LoggedInUserInfo.alias_name, 167, 60);

    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text(`Generated : `, 145, 65);

    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text(`${newDatePDF} ${newTimePDF}`, 165, 65);

    const expPdfData = tableData.map((t) => {
      return [
        t.id,
        t.activity,
        t.category,
        t.description,
        t.user_name,
        t.created,
      ];
    });
    autoTable(doc, {
      margin: { top: 80 },
      head: [["ID", "Activity", "Category", "Description", "User", "Datetime"]],
      body: expPdfData,
      showFoot: "everyPage",
      didDrawPage: function (data) {
        if (data.pageCount >= 1) {
          data.settings.margin.top = 10;
        }
        // Footer
        var str = "Page " + doc.internal.getNumberOfPages();

        doc.setFontSize(10);
        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        doc.text(str, 110, pageHeight - 3);
        doc.setLineWidth(0.1);
        doc.line(10, pageHeight - 8, 200, pageHeight - 8, "F");
      },
    });
    let fileName =
      "SOVA-lostfound-activity-logs-" +
      moment(new Date()).format("YYYY-MM-DD") +
      ".pdf";
    doc.save(fileName);
  };

  const applySearch = () => {
    if (searchLostfoundId && isNaN(searchLostfoundId)) {
      showAlertModal("error", "Please enter a number only");
      setSearchLostfoundId("");
    } else {
      const payload = {
        startTemp: startDateToFilter,
        endTemp: endDateToFilter,

        to_date: startDateToFilter
          ? moment(startDateToFilter).format("YYYY-MM-DD")
          : "",
        from_date: endDateToFilter
          ? moment(endDateToFilter).format("YYYY-MM-DD")
          : "",
        lostfound_id: searchLostfoundId,
        user_name: searchUserName,
        category: category,
      };
      filterRecord(payload);
    }
  };

  const resetFilter = () => {
    setStartDateToFilter("");
    setEndDateToFilter("");
    setSearchLostfoundId("");
    setSearchUserName("");
    setCategory("");
    filterRecord({});
  };

  return (
    <Toolbar>
      <div>
        <Paper mt={3}>
          <DialogBox
            handleShow={showDeleteModal}
            message={modalMessage}
            conformation={(status) => handelDeleteAction(status)}
          />
          <FormControl mr={2} sx={{ minWidth: 120 }}>
            <InputLabel id="demo-simple-select-label">Actions</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={actions}
              label="Actions"
              MenuProps={{
                PaperProps: {
                  sx: {
                    "& .MuiMenu-list": {
                      boxShadow:
                        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 10%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
                      margin: "0px 3px 5px 3px",
                      padding: "0",
                    },
                  },
                },
              }}
              onChange={handleChange}
            >
              <MenuItem value={"Delete"} onClick={() => removeRecords()}>
                Delete
              </MenuItem>
            </Select>
          </FormControl>
        </Paper>
      </div>
      <div>
        <Typography
          color="inherit"
          variant="subtitle1"
          mt={3}
          style={{ width: "max-content", marginLeft: "15px" }}
        >
          {totalRecordinDb} records found
        </Typography>
      </div>
      <Spacer />
      <div>
        <Box
          mt={3}
          sx={{
            display: "flex",
            flexWrap: "nowrap",
          }}
        >
          <Item sx={{ flexGrow: 1, width: "100%" }}>
            <TextField
              id="search"
              type="text"
              value={searchLostfoundId}
              placeholder="Search by ID, or click arrow"
              inputProps={{
                sx: {
                  minWidth: "300px",
                },
              }}
              onChange={(e) => {
                setSearchLostfoundId(e.target.value);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  applySearch();
                  setIsFilter(true);
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-owns={anchorForm ? "search-form" : undefined}
                      aria-haspopup="true"
                      onClick={handleClickDropdown}
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                    <StyledForm
                      id="search-form"
                      anchorEl={anchorForm}
                      open={Boolean(anchorForm)}
                      onClose={handleCloseForm}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <MenuItem sx={{ maxHeight: "380px", overflowY: "auto" }}>
                        <form>
                          <FormControl mt={3}>
                            <TextField
                              fullWidth
                              label="Date range"
                              placeholder="Select date range"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onClick={() => setFadedBox((prev) => !prev)}
                              value={
                                startDateToFilter && endDateToFilter
                                  ? moment(startDateToFilter).format(
                                      "YYYY-MM-DD"
                                    ) +
                                    " - " +
                                    moment(endDateToFilter).format("YYYY-MM-DD")
                                  : ""
                              }
                            />
                            {fadebox && (
                              <CustomDateRange
                                handleShow={fadebox}
                                conformation={(status) =>
                                  setFadedBox((prev) => !prev)
                                }
                                setDates={(start, end) => {
                                  setStartDateToFilter(start);
                                  setEndDateToFilter(end);
                                }}
                                selectedStart={startDateToFilter}
                                selectedEnd={endDateToFilter}
                              />
                            )}
                          </FormControl>
                          <FormControl mt={3}>
                            <TextField
                              fullWidth
                              id="user"
                              label="User"
                              value={searchUserName}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="John Smith"
                              onChange={(e) => {
                                setSearchUserName(e.target.value);
                              }}
                            />
                          </FormControl>
                          <FormControl>
                            <InputLabel
                              shrink
                              id="category"
                              name="catg"
                              sx={{
                                paddingLeft: "6px",
                                paddingRight: "6px",
                                marginLeft: "-4px",
                              }}
                            >
                              Category
                            </InputLabel>
                            <Select
                              labelId="position"
                              id="category"
                              value={category}
                              onChange={selectCategory}
                              fullWidth
                              displayEmpty
                            >
                              {catglist.map((item, index) => {
                                return (
                                  <MenuItem key={index} value={item.value}>
                                    {item.key}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                          <Box className={classes.cardHeader}>
                            <Button
                              color="primary"
                              variant="contained"
                              ml={3}
                              onClick={(e) => {
                                applySearch({});
                                setIsFilter(true);
                                handleCloseForm();
                              }}
                            >
                              Filter
                            </Button>
                          </Box>
                        </form>
                      </MenuItem>
                    </StyledForm>
                  </InputAdornment>
                ),
              }}
            />
          </Item>
          <Item>
            {isFilter ? (
              <Button
                color="primary"
                variant="contained"
                onClick={(e) => {
                  setIsFilter(false);
                  resetFilter();
                }}
                sx={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  height: "100%",
                  marginRight: { lg: "50px" },
                  marginLeft: "-3px",
                  width: "100%",
                }}
              >
                Clear Filter
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                sx={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  height: "100%",
                  marginRight: { lg: "50px" },
                  marginLeft: "-3px",
                }}
                onClick={(e) => {
                  applySearch();
                  setIsFilter(true);
                }}
              >
                Search
              </Button>
            )}
          </Item>
        </Box>
      </div>
      <Spacer />
      <div>
        <Paper mt={3}>
          <IconButton
            aria-owns={anchorEl ? "icon-menu" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            variant="contained"
            color="secondary"
          >
            <FilterListIcon />
          </IconButton>
          <StyledFilterMenu
            id="icon-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem>
              <ListItemIcon>
                <InsertDriveFileIcon />
              </ListItemIcon>
              <CSVLink
                data={tableData}
                style={{ textDecoration: "none", color: "inherit" }}
                headers={exportCSVheaders}
                onClick={handleClose}
                filename={`SOVA-lostfound-activity-logs-${format(
                  new Date(),
                  "yyyy-MM-dd"
                )}`}
              >
                <ListItemText size="large" primary="Export to CSV" pl={0} />
              </CSVLink>
            </MenuItem>
            <MenuItem>
              <ListItemIcon>
                <PictureAsPdfIcon />
              </ListItemIcon>
              <ListItemText
                size="large"
                primary="Export to PDF"
                pl={0}
                onClick={() => generatePdf()}
              />
            </MenuItem>
            <MenuItem
              aria-owns={anchorColumn ? "columns-dropdown" : undefined}
              aria-haspopup="true"
              onClick={handleClickColumn}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Columns" pl={0} />
            </MenuItem>
          </StyledFilterMenu>
          <StyledColumnDropdown
            id="columns-dropdown"
            anchorEl={anchorColumn}
            open={Boolean(anchorColumn)}
            onClose={handleCloseColumn}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem>
              <Card>
                <CardContent>
                  <Typography variant="h6" component="h2">
                    {
                      Object.keys(tableCalRedux).filter(
                        (k) => tableCalRedux[k] === true
                      ).length
                    }{" "}
                    out of {Object.keys(tableCalRedux).length} visible
                  </Typography>
                  <StyledColumnOptions
                    calStatus={tableCalRedux}
                    updateCalStatus={(data) => {
                      dispatch(setActivityLogs(data));
                    }}
                    listing={ActivityLogsCol}
                  />
                  <Toolbar>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        dispatch(setActivityLogs(defaultTableCalRedux));
                      }}
                    >
                      Reset
                    </Button>
                    <Spacer />
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleCloseColumn}
                    >
                      Cancel
                    </Button>
                  </Toolbar>
                </CardContent>
              </Card>
            </MenuItem>
          </StyledColumnDropdown>
        </Paper>
      </div>
    </Toolbar>
  );
};
function EnhancedTable(props) {
  const { alertMessage, alertType, showAlert, showAlertModal } = props;

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [dataToFilter, setDataToFilter] = useState({});

  const [lists, setLists] = useState([]); //state for storing list items
  const [itemcatglist, setItemCatgList] = useState([]);
  const [exportCSVLists, setExportCSVLists] = useState([]); //state for storing list items
  const [totalRecordinDb, setTotalRecordinDb] = useState(0);

  const [loading, setLoading] = useState(false); //State for loader

  // for listing
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const tableCalRedux = useSelector(
    (state) => state.lostfoundTableCal.activityLogs
  );

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = lists.map((n) => n.id);
      setSelected(newSelecteds);
    } else {
      setSelected([]);
    }
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  useEffect(() => {
    getLogsList();
    getCategoryList();
  }, [rowsPerPage, currentPage, order, orderBy, dataToFilter]);

  const getLogsList = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
      sorting: {
        order: order,
        by: orderBy,
      },
      ...dataToFilter,
    };
    LostFoundActivityLogs(currentPage + 1, payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setLists(res.data);
          setTotalRecordinDb(res.meta.total);
          makeDataToExport(res.data);
        } else {
          setLists([]);
          setTotalRecordinDb(0);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        if (error) {
          // someThingWrongAlert(error);
        }
      });
  };

  const getCategoryList = () => {
    CategoryList({})
      .then((res) => {
        if (res.statusCode == 200) {
          setItemCatgList(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const filterRecord = (data) => {
    setDataToFilter(data);
  };

  const makeDataToExport = (data) => {
    const expData = data.map((t) => {
      return {
        id: t.lostfound_id,
        activity: t.activity,
        category: t.lsot_and_found ? t.lsot_and_found.category : "-",
        description: t.lsot_and_found ? t.lsot_and_found.description : "-",
        user_name: t.user ? t.user.UserName : "-",
        created: moment(t.created).format("YYYY-MM-DD HH:mm:ss"),
      };
    });
    setExportCSVLists(expData);
  };

  return (
    <div>
      <Paper mt={3}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          selectedRecord={selected}
          alertMessage={alertMessage}
          alertType={alertType}
          showAlert={showAlert}
          getLogsList={() => getLogsList()}
          resetSelected={() => setSelected([])}
          tableData={exportCSVLists}
          catglist={itemcatglist}
          filterRecord={(data) => filterRecord(data)}
          dataToFilter={dataToFilter}
          showAlertModal={(type, message) => showAlertModal(type, message)}
          totalRecordinDb={totalRecordinDb}
          tableCalRedux={tableCalRedux}
        />
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={lists.length}
              tableCalRedux={tableCalRedux}
            />
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    <CircularProgress />
                  </TableCell>
                  {/* collapse null column to resolve console errors */}
                  <TableCell colSpan={8} />
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {lists.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell
                        padding="checkbox"
                        sx={{
                          paddingTop: "15px",
                          paddingBottom: "15px",
                        }}
                      >
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        align="center"
                        scope="row"
                        padding="none"
                        sx={{
                          whiteSpace: "nowrap",
                          paddingTop: "15px",
                          paddingBottom: "15px",
                        }}
                        className={tableCalRedux.id === true ? "" : "hide-cell"}
                      >
                        {row.lostfound_id}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          whiteSpace: "nowrap",
                          paddingTop: "15px",
                          paddingBottom: "15px",
                        }}
                        className={
                          tableCalRedux.activity === true ? "" : "hide-cell"
                        }
                      >
                        {row.activity}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          whiteSpace: "nowrap",
                          paddingTop: "15px",
                          paddingBottom: "15px",
                        }}
                        className={
                          tableCalRedux.category === true ? "" : "hide-cell"
                        }
                      >
                        {row.lsot_and_found ? row.lsot_and_found.category : "-"}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          // whiteSpace: "nowrap",
                          paddingTop: "15px",
                          paddingBottom: "15px",
                          minWidth: "400px",
                        }}
                        className={
                          tableCalRedux.description === true ? "" : "hide-cell"
                        }
                      >
                        {row.lsot_and_found
                          ? row.lsot_and_found.description
                          : "-"}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={
                          tableCalRedux.UserName === true ? "" : "hide-cell"
                        }
                      >
                        {row.user ? row.user.UserName : "-"}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          whiteSpace: "nowrap",
                          paddingTop: "15px",
                          paddingBottom: "15px",
                        }}
                        className={
                          tableCalRedux.created === true ? "" : "hide-cell"
                        }
                      >
                        <Box>
                          <Typography variant="span" component="p">
                            {moment(row.created).format("YYYY-MM-DD HH:mm:ss")}
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}

                {lists.length < 1 && (
                  <TableRow style={{ height: (dense ? 6 : 0) * lists.length }}>
                    <TableCell colSpan={8} align="center">
                      No record found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 40, 50]}
          component="div"
          count={totalRecordinDb}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </div>
  );
}

function LostFoundLogs() {
  // Alert Message
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [showAlert, setShowAlert] = useState(false);

  const showAlertModal = (type, message) => {
    setAlertMessage(message);
    setAlertType(type);
    setShowAlert(true);
  };

  return (
    <React.Fragment>
      <Helmet title="Lost and Found Logs" />
      <Typography variant="h3" gutterBottom display="inline">
        Lost and Found Logs
      </Typography>

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable
            alertMessage={(value) => setAlertMessage(value)}
            alertType={(value) => setAlertType(value)}
            showAlert={(value) => setShowAlert(value)}
            showAlertModal={(type, message) => showAlertModal(type, message)}
          />
        </Grid>
      </Grid>
      {showAlert && (
        <CustomAlert
          message={alertMessage}
          severity={alertType}
          closeAlert={(show) => setShowAlert(show)}
          showAlert={showAlert}
        />
      )}
    </React.Fragment>
  );
}

export default LostFoundLogs;
