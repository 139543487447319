import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

import { useSelector, useDispatch } from "react-redux";
import { updateShowAlert } from "../../redux/slices/mainSlice";
import CustomAlert from "../../components/form-components/CustomAlert";
import { LostAndfFound } from "../../api";
import LostAndFoundStatus from "./inner-component/LostAndFoundStatus";
import ListingAction from "./inner-component/listing-action";
import EnhancedTableToolbar from "./inner-component/update-action";
import { handleApiResonseErrors } from "../../helper/helper";
import SingleActivityLogs from "../../components/modals/lostandfound/SingleActivityLogs";
import RevisionsList from "../../components/modals/lostandfound/RevisionsList";
import CommunicationLogModal from "../../components/modals/lostandfound/CommunicationLogModal";
import SubItmes from "../../components/modals/lostandfound/SubItmes";
import ShipmentRequest from "../../components/modals/lostandfound/ShipmentRequest";
import AddComLogModal from "../../components/modals/lostandfound/AddComLogModal";
import PrintShippingLabel from "../../components/modals/lostandfound/PrintShippingLabel";
import LostFoundListingCol from "../table-columns-json/lostfound/LostFoundListing";

import {
  Checkbox,
  Grid,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  FormControl as MuiFormControl,
  Box,
  Button,
  FormControlLabel,
  Switch,
  Tooltip,
  ToggleButtonGroup,
  ToggleButton,
  CircularProgress,
} from "@mui/material";
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  Cached as CachedIcon,
  Lock as LockIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
// Classes Styling
const useStyles = makeStyles((theme) => ({
  alphabets: {
    marginTop: "15px",
    "& .MuiList-root": {
      display: "flex",
      overflowX: "auto",
      background: "#7aa8ff0d",
      padding: "0px",
      "& .MuiListItemButton-root": {
        paddingLeft: "13px",
        paddingRight: "13px",
        textAlign: "center",
      },
    },
    "& .MuiList-root::-webkit-scrollbar": {
      width: "5px",
      height: "5px",
    },
    "& .MuiList-root::-webkit-scrollbar-track": {
      backgroundColor: "#bed2f561",
    },
    "& .MuiList-root::-webkit-scrollbar-thumb": {
      backgroundColor: "#92a6c96e",
    },
  },
  shipmentRequest: {
    "& .MuiFormControl-root": {
      minWidth: "auto",
      width: "100%",
    },
  },
  filterDataByTabs: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "15px",
    "& .MuiToggleButtonGroup-root": {
      justifyContent: "space-between",
      width: "100%",
      overflowX: "auto",
      "& .MuiButtonBase-root": {
        borderRadius: "0",
        width: "100%",
        whiteSpace: "nowrap",
      },
    },
    "& .MuiList-root::-webkit-scrollbar": {
      width: "5px",
      height: "5px",
    },
    "& .MuiList-root::-webkit-scrollbar-track": {
      backgroundColor: "#bed2f561",
    },
    "& .MuiList-root::-webkit-scrollbar-thumb": {
      backgroundColor: "#92a6c96e",
    },
  },
  btnDropdown: {
    maxWidth: "65px",
    width: "100%",
  },
  statusButton: {
    width: "20px",
    height: "20px",
    display: "block",
    lineHeight: "20px",
    textAlign: "center",
    borderRadius: "6px",
    color: "#fff",
    fontSize: "12px",
    fontWeight: "bolder",
  },
  cardHeader: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  filterByRadioControls: {
    position: "absolute",
    top: "100%",
    right: "0px",
    boxShadow: "0px 3px 5px 3px #00000014 !important",
    minWidth: "320px",
    zIndex: "1",
    padding: "15px",
  },
  itemInSingleRow: {
    display: "flex",
    gap: "15px",
    alignItems: "center",
  },
  createMergedGroup: {
    border: "1px solid",
    borderColor:
      theme.palette.mode === "light" ? "#c4c4c4" : theme.palette.grey[300],
    padding: 0,
    marginTop: "15px",
  },
  CustomActionsButton: {
    borderColor:
      theme.palette.mode === "light"
        ? "rgb(223 223 223)"
        : theme.palette.grey[300],
    color: theme.palette.mode === "light" ? "#7f8797" : theme.palette.grey[300],
    padding: "13px 20px",
  },
}));

const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    tableCalRedux,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all data" }}
          />
        </TableCell>
        {LostFoundListingCol.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            sx={{ whiteSpace: "nowrap" }}
            sortDirection={orderBy === headCell.id ? order : false}
            className={tableCalRedux[headCell.id] === true ? "" : "hide-cell"}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {headCell.icon}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

function EnhancedTable(props) {
  // Redux
  const showAlert = useSelector((state) => state.main.showAlert);
  const showAlertText = useSelector((state) => state.main.text);
  const tableCalRedux = useSelector(
    (state) => state.lostfoundTableCal.lostFoundListing
  );
  const dispatch = useDispatch();

  const [order, setOrder] = useState("DESC");
  const [orderBy, setOrderBy] = useState("id");
  const [selected, setSelected] = useState([]);
  const [dense, setDense] = useState(true);
  const [dataToFilter, setDataToFilter] = useState({
    checkbox_desc: true,
  });
  const [alphabetsFilter, setAlphabetsFilter] = useState({
    checkbox_desc: true,
  });
  const [selectedIdDS, setSelectedIdDS] = useState(0);

  // for listing
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecordinDb, setTotalRecordinDb] = useState(0);

  // View Activity Log Table modal
  const [activitylogtable, activityLogTable] = useState(false);
  const [showSubItemModal, setShowSubItemModal] = useState(false);
  const [showRevisionsModal, setShowRevisionsModal] = useState(false);
  const [showComLogModal, setShowComLogModal] = useState(false);
  const [showAddComLog, setShowAddComLog] = useState(false);
  const [showShippingLabel, setShowShippingLabel] = useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState("");
  const [selectedRecordData, setSelectedRecordData] = useState([]);

  const [lists, setLists] = useState([]); //state for storing list items
  const [shipmentrequest, setShipmentRequest] = useState(false);

  // Alert Message
  const [alertMessage, setAlertMessage] = useState(showAlertText);
  const [alertType, setAlertType] = useState("success");
  const [loading, setLoading] = useState(false); //State for loader

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = lists.map((n) => n.id);
      setSelected(newSelecteds);
      setSelectedRecordData(lists);
      const newSelectedIdDS = lists.map((n) => n.disposition_status);
      if (
        newSelectedIdDS.includes(0) ||
        newSelectedIdDS.includes(2) ||
        newSelectedIdDS.includes(3) ||
        newSelectedIdDS.includes(4)
      ) {
        let newSelected = selectedIdDS + 1;
        setSelectedIdDS(newSelected);
      }
    } else {
      setSelected([]);
      setSelectedIdDS(0);
      setSelectedRecordData([]);
    }
  };

  const handleClick = (event, id, row) => {
    if (selected.some((elementId) => elementId == id)) {
      setSelected((prev) => prev.filter((rowId) => rowId !== id));
      setSelectedRecordData((prev) =>
        prev.filter((rowData) => rowData.id != row.id)
      );
    } else {
      setSelected([...selected, id]);
      setSelectedRecordData([...selectedRecordData, row]);
    }

    // if (selectedIndex === -1) {
    //   newSelected = newSelected.concat(selected, id);
    // } else if (selectedIndex === 0) {
    //   newSelected = newSelected.concat(selected.slice(1));
    // } else if (selectedIndex === selected.length - 1) {
    //   newSelected = newSelected.concat(selected.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //   newSelected = newSelected.concat(
    //     selected.slice(0, selectedIndex),
    //     selected.slice(selectedIndex + 1)
    //   );
    // }
    // setSelected(newSelected);
  };

  const handleSelecteDS = (event, DS) => {
    let notValidToupdate = [0, 2, 3, 4];
    if (event.target.checked) {
      if (notValidToupdate.includes(DS)) {
        let newSelected = selectedIdDS + 1;
        setSelectedIdDS(newSelected);
      }
    } else {
      if (notValidToupdate.includes(DS)) {
        let newSelected = selectedIdDS - 1;
        setSelectedIdDS(newSelected);
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const classes = useStyles();
  //Tabs
  const [tabsbutton, setTabButton] = useState("showall");
  const changeTab = (event, newTab) => {
    setCurrentPage(0);
    setTabButton(newTab);
    setSelected([]);
  };
  //Tabs
  const [alphabetesbutton, setAlphabetsButton] = useState("");
  const changeAlphabetsTab = (event, newTab) => {
    if (newTab == "refresh") {
      newTab = "";
    }
    setAlphabetsButton(newTab);
    filterByAlphabets(alphabetsFilter, newTab);
  };
  // Faded Item
  const [fadebox, setFadedBox] = useState(true);

  useEffect(() => {
    getLostFoundList();
    setTimeout(() => {
      dispatch(updateShowAlert(false));
    }, 3000);
  }, [rowsPerPage, currentPage, order, orderBy, dataToFilter, tabsbutton]);

  const getLostFoundList = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
      disposition_status: tabsbutton ? parseInt(tabsbutton) : "",
      followup_required: tabsbutton == "Y" ? tabsbutton : "",
      lost_guest: tabsbutton == "G" ? "Y" : "",
      sorting: {
        order: order,
        by: orderBy,
      },
      ...dataToFilter,
    };
    LostAndfFound(currentPage + 1, payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setLists(res.data);
          setTotalRecordinDb(res.meta.total);
        } else {
          setLists([]);
          setTotalRecordinDb(0);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        if (error) {
          someThingWrongAlert(error);
        }
      });
  };

  const filterRecord = (data) => {
    setDataToFilter(data);
  };

  const someThingWrongAlert = (msg) => {
    setAlertMessage(msg);
    setAlertType("error");
    dispatch(updateShowAlert(true));
    setTimeout(() => {
      dispatch(updateShowAlert(false));
    }, 3000);
  };

  const updateAlphabetsFilter = (obj) => {
    let newObj = {
      ...alphabetsFilter,
      ...obj,
    };
    setAlphabetsFilter(newObj);
    if (alphabetesbutton) {
      filterByAlphabets(newObj, alphabetesbutton);
    }
  };

  const filterByAlphabets = (obj, value) => {
    let filterObj = {};
    if (obj.checkbox_category && obj.checkbox_category === true) {
      filterObj.category = value;
      filterObj.checkbox_category = true;
    } else {
      filterObj.category = "";
      filterObj.checkbox_category = false;
    }
    if (obj.checkbox_desc && obj.checkbox_desc === true) {
      filterObj.description = value;
      filterObj.checkbox_desc = true;
    } else {
      filterObj.description = "";
      filterObj.checkbox_desc = false;
    }
    if (obj.checkbox_lost_last_name && obj.checkbox_lost_last_name === true) {
      filterObj.lost_last_name = value;
      filterObj.checkbox_lost_last_name = true;
    } else {
      filterObj.lost_last_name = "";
      filterObj.checkbox_lost_last_name = false;
    }
    if (obj.checkbox_found_location && obj.checkbox_found_location === true) {
      filterObj.found_location = value;
      filterObj.checkbox_found_location = true;
    } else {
      filterObj.found_location = "";
      filterObj.checkbox_found_location = false;
    }
    if (obj.checkbox_store_location && obj.checkbox_store_location === true) {
      filterObj.storage_location = value;
      filterObj.checkbox_store_location = true;
    } else {
      filterObj.storage_location = "";
      filterObj.checkbox_store_location = false;
    }
    filterObj.searchByAlphabet = true;
    setDataToFilter(filterObj);
  };

  const showAlertTost = (msg, type) => {
    setAlertMessage(msg);
    setAlertType(type);
    dispatch(updateShowAlert(true));
  };

  return (
    <div>
      <Paper mt={3}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          selectedRecords={selected}
          filterRecord={(data) => filterRecord(data)}
          dataToFilter={dataToFilter}
          totalRecordinDb={totalRecordinDb}
          updateAlphabetsFilter={(obj) => updateAlphabetsFilter(obj)}
          setAlphabetsButton={(value) => setAlphabetsButton(value)}
          activityLogTable={(value) => activityLogTable(value)}
          setShowSubItemModal={(value) => setShowSubItemModal(value)}
          setRecordId={(value) => setSelectedRecordId(value)}
          selectedRecordData={selectedRecordData}
          selectedRecordId={selectedRecordId}
          reloadList={() => getLostFoundList()}
          handelListLoader={(value) => setLoading(value)}
          resetSelected={() => setSelected([])}
          selectedIdDS={selectedIdDS}
        />
        {fadebox && (
          <Box
            className={classNames(classes.alphabets, classes.filterDataByTabs)}
          >
            <ToggleButtonGroup
              color="primary"
              value={alphabetesbutton}
              exclusive
              onChange={changeAlphabetsTab}
            >
              <ToggleButton value="a">A</ToggleButton>
              <ToggleButton value="b">B</ToggleButton>
              <ToggleButton value="c">C</ToggleButton>
              <ToggleButton value="d">D</ToggleButton>
              <ToggleButton value="e">E</ToggleButton>
              <ToggleButton value="f">F</ToggleButton>
              <ToggleButton value="g">G</ToggleButton>
              <ToggleButton value="h">H</ToggleButton>
              <ToggleButton value="i">I</ToggleButton>
              <ToggleButton value="j">J</ToggleButton>
              <ToggleButton value="k">K</ToggleButton>
              <ToggleButton value="l">L</ToggleButton>
              <ToggleButton value="m">M</ToggleButton>
              <ToggleButton value="n">N</ToggleButton>
              <ToggleButton value="o">O</ToggleButton>
              <ToggleButton value="p">P</ToggleButton>
              <ToggleButton value="q">Q</ToggleButton>
              <ToggleButton value="r">R</ToggleButton>
              <ToggleButton value="s">S</ToggleButton>
              <ToggleButton value="t">T</ToggleButton>
              <ToggleButton value="u">U</ToggleButton>
              <ToggleButton value="v">V</ToggleButton>
              <ToggleButton value="w">W</ToggleButton>
              <ToggleButton value="x">X</ToggleButton>
              <ToggleButton value="y">Y</ToggleButton>
              <ToggleButton value="z">Z</ToggleButton>
              <ToggleButton value="refresh">
                <CachedIcon fontSize="small" />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        )}
        <Box className={classes.filterDataByTabs}>
          <ToggleButtonGroup
            color="primary"
            value={tabsbutton}
            exclusive
            onChange={changeTab}
          >
            <ToggleButton value="showall">
              <Tooltip
                title="Lost, stored and claimed items"
                placement="top"
                arrow
              >
                <span>Show All</span>
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="1">
              <Tooltip title="Items stored in inverntory" placement="top" arrow>
                <span>Stored</span>
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="0">
              <Tooltip title="Lost inquiries" placement="top" arrow>
                <span>Lost</span>
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="2">
              <Tooltip title="Picked up or shipped items" placement="top" arrow>
                <span>Claimed</span>
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="3">
              <Tooltip title="Donated or discarded items" placement="top" arrow>
                <span>Disposed</span>
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="4">
              <Tooltip title="Items ready to ship" placement="top" arrow>
                <span>Pending</span>
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="Y">
              <Tooltip title="Flagged items" placement="top" arrow>
                <span>Followup</span>
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="G">
              <Tooltip title="Inquiry from SOVA website" placement="top" arrow>
                <span>Guest</span>
              </Tooltip>
            </ToggleButton>

            <Button
              className={classes.btnDropdown}
              value="other"
              color="primary"
              variant="contained"
              onClick={() => setFadedBox((prev) => !prev)}
            >
              {fadebox ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </Button>
          </ToggleButtonGroup>
        </Box>
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={lists.length}
              tableCalRedux={tableCalRedux}
            />
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={11} align="center">
                    <CircularProgress />
                  </TableCell>
                  {/* collapse null column to resolve console errors */}
                  <TableCell colSpan={10} />
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {lists.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => {
                        handleClick(event, row.id, row);
                        handleSelecteDS(event, row.disposition_status);
                      }}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      sx={{
                        backgroundColor:
                          row.followup_required === "Y" ? "#dedede" : "inherit",
                      }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        align="left"
                        sx={{ lg: 'whiteSpace: "nowrap"' }}
                        className={
                          tableCalRedux.disposition_status === true
                            ? ""
                            : "hide-cell"
                        }
                      >
                        <LostAndFoundStatus dispositionStatus={row} />
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          whiteSpace: "nowrap",
                        }}
                        className={tableCalRedux.id === true ? "" : "hide-cell"}
                      >
                        <div>{row.id}</div>
                        <div>
                          {(row.locked_item == 1 || row.locked_item == "Y") && (
                            <LockIcon fontSize="small" color="error" />
                          )}
                        </div>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ whiteSpace: "nowrap" }}
                        className={
                          tableCalRedux.lost_date === true ? "" : "hide-cell"
                        }
                      >
                        {row.lost_date}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ whiteSpace: "nowrap" }}
                        className={
                          tableCalRedux.found_date === true ? "" : "hide-cell"
                        }
                      >
                        {row.found_date}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={
                          tableCalRedux.category === true ? "" : "hide-cell"
                        }
                      >
                        {row.category}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ minWidth: "400px" }}
                        className={
                          tableCalRedux.description === true ? "" : "hide-cell"
                        }
                      >
                        {row.description}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          whiteSpace: "nowrap",
                        }}
                        className={
                          tableCalRedux.found_name === true ? "" : "hide-cell"
                        }
                      >
                        {row.found_name}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ whiteSpace: "nowrap" }}
                        className={
                          tableCalRedux.found_location === true
                            ? ""
                            : "hide-cell"
                        }
                      >
                        {row.found_location}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ whiteSpace: "nowrap" }}
                        className={
                          tableCalRedux.storage_location === true
                            ? ""
                            : "hide-cell"
                        }
                      >
                        {row.storage_location}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={
                          tableCalRedux.action === true ? "" : "hide-cell"
                        }
                      >
                        <ListingAction
                          data={row}
                          setRecordId={(value) => setSelectedRecordId(value)}
                          activityLogTable={(value) => activityLogTable(value)}
                          setShowRevisionsModal={(value) =>
                            setShowRevisionsModal(value)
                          }
                          setShowSubItemModal={(value) =>
                            setShowSubItemModal(value)
                          }
                          setShipmentRequest={(status) =>
                            setShipmentRequest(status)
                          }
                          setShowComLogModal={(status) =>
                            setShowComLogModal(status)
                          }
                          setShowShippingLabel={(status) =>
                            setShowShippingLabel(status)
                          }
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}

                {lists.length < 1 && (
                  <TableRow style={{ height: (dense ? 6 : 0) * lists.length }}>
                    <TableCell colSpan={10} align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 40, 50]}
          component="div"
          count={totalRecordinDb}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      {/* View Activity Log */}
      {activitylogtable && (
        <SingleActivityLogs
          activitylogtable={activitylogtable}
          handleActivityModal={(status) => activityLogTable(status)}
          id={selectedRecordId}
        />
      )}
      {/* Sub Items */}
      {showSubItemModal && (
        <SubItmes
          showSubItemModal={showSubItemModal}
          handleModal={(status) => setShowSubItemModal(status)}
          id={selectedRecordId}
        />
      )}
      {showRevisionsModal && (
        <RevisionsList
          show={showRevisionsModal}
          handleModal={(status) => setShowRevisionsModal(status)}
          id={selectedRecordId}
        />
      )}
      {showComLogModal && (
        <CommunicationLogModal
          show={showComLogModal}
          handleModal={(status) => setShowComLogModal(status)}
          id={selectedRecordId}
          setShowAddComLog={(status) => setShowAddComLog(status)}
          log_type="lostfound"
        />
      )}
      {showAddComLog && (
        <AddComLogModal
          show={showAddComLog}
          handleModal={(status) => setShowAddComLog(status)}
          id={selectedRecordId}
          showAlertTost={(msg, type) => showAlertTost(msg, type)}
          log_type="lostfound"
        />
      )}
      {showShippingLabel && (
        <PrintShippingLabel
          show={showShippingLabel}
          handleModal={(status) => setShowShippingLabel(status)}
          id={selectedRecordId}
          showAlertTost={(msg, type) => showAlertTost(msg, type)}
        />
      )}
      {showAlert && (
        <CustomAlert
          message={alertMessage}
          severity={alertType}
          closeAlert={(show) => dispatch(updateShowAlert(show))}
          showAlert={showAlert}
        />
      )}
      {shipmentrequest && (
        <ShipmentRequest
          showModal={shipmentrequest}
          handleModal={(status) => setShipmentRequest(status)}
          showAlertTost={(msg, type) => showAlertTost(msg, type)}
          reloadList={() => getLostFoundList()}
          handleActionsClose={false}
          resetSelected={() => setSelected([])}
          selectedRecordId={selectedRecordId}
        />
      )}
    </div>
  );
}

function ListLostAndFound() {
  return (
    <React.Fragment>
      <Helmet title="Lost and Found" />
      <Typography variant="h3" gutterBottom display="inline">
        Lost and Found
      </Typography>

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ListLostAndFound;
