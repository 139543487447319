import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { UploadMediaFile } from "../../../api";
import { handleApiResonseErrors } from "../../../helper/helper";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../redux/slices/mainSlice";
import {
  FormControl as MuiFormControl,
  Select,
  MenuItem,
  TextField,
  Button,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Card,
  CircularProgress,
  Paper,
} from "@mui/material";
import {
  NotInterested as NotInterestedIcon,
  CloudUpload as CloudUploadIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
// ThumbnailTableWrapper
const ThumbnailTableWrapper = styled.div`
  height: 100px;
  width: 100px;
`;
// Image
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
const Input = styled("input")({
  display: "none",
});

// Classes Styling
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));

const MediaUploadFiles = ({
  uploadeMedia,
  setUploadeMedia,
  incidentId,
  getMediaListing,
}) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const selectMediaType = (event, objIndex) => {
    let value = event.target.value;
    uploadeMedia[objIndex].media_id = value;
    setUploadeMedia(uploadeMedia);
  };

  const changeName = (event, objIndex) => {
    let value = event.target.value;
    uploadeMedia[objIndex].media_name = value;
    setUploadeMedia(uploadeMedia);
  };

  const changeDesc = (event, objIndex) => {
    let value = event.target.value;
    uploadeMedia[objIndex].media_description = value;
    setUploadeMedia(uploadeMedia);
  };

  const saveMedia = (fileData, objIndex) => {
    if (!fileData.media_name || !fileData.media_description) {
      alertMessage("Please provide media name and description", "error");
      return;
    }
    setLoading(true);
    let formData = {
      incident_id: incidentId,
      media_id: fileData.media_id,
      media_name: fileData.media_name,
      media_description: fileData.media_description,
      file: fileData.file,
    };
    UploadMediaFile(formData)
      .then((res) => {
        if (res.statusCode === 200) {
          alertMessage(res.message, "success");
          uploadeMedia[objIndex].isUploaded = true;
          setUploadeMedia(uploadeMedia);
          getMediaListing();
        } else {
          alertMessage("Sorry! something wrong please try again", "error");
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        if (error) {
          setLoading(false);
          alertMessage(error);
        }
      });
  };

  const dispatch = useDispatch();
  const alertMessage = (msg, type) => {
    dispatch(getMsg(msg));
    dispatch(getSnackAlert(type));
    dispatch(getSnackOpen());
    setTimeout(() => {
      dispatch(getSnackClose());
    }, 3000);
  };

  const removeMedia = (value) => {
    setUploadeMedia(uploadeMedia.filter((data, index) => index !== value));
  };
  return (
    <>
      <Card variant="outlined" sx={{ borderBottom: 0, marginTop: "15px" }}>
        <TableContainer className="tableContainer">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Media</TableCell>
                <TableCell>Media Type</TableCell>
                <TableCell>Media Name</TableCell>
                <TableCell colSpan="3">Media Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {uploadeMedia.length > 0 &&
                uploadeMedia.map((item, index) => {
                  return (
                    <TableRow hover key={index}>
                      <TableCell>
                        <ThumbnailTableWrapper>
                          <Image
                            sx={{ width: "100%" }}
                            component="img"
                            alt="Guage Chart"
                            src={
                              item.media_id === 1
                                ? item.file
                                : item.media_id === 2
                                ? "/static/img/reports/File-Video-icon.png"
                                : item.media_id === 3
                                ? "/static/img/reports/File-Audio-icon.png"
                                : item.media_id === 4
                                ? "/static/img/reports/Adobe-PDF-Document-icon.png"
                                : item.media_id === 5
                                ? "/static/img/reports/Document-Microsoft-Word-icon.png"
                                : ""
                            }
                          />
                        </ThumbnailTableWrapper>
                      </TableCell>
                      <TableCell sx={{ minWidth: "200px" }}>
                        <FormControl>
                          <Select
                            labelId="media-type"
                            id="media-type"
                            value={item.media_id}
                            onChange={(evt) => selectMediaType(evt, index)}
                            fullWidth
                            displayEmpty
                          >
                            <MenuItem value={1}>Image</MenuItem>
                            <MenuItem value={2}>Video</MenuItem>
                            <MenuItem value={3}>Audio</MenuItem>
                            <MenuItem value={4}>PDF</MenuItem>
                            <MenuItem value={5}>DOC</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell sx={{ minWidth: "200px" }}>
                        <FormControl>
                          <TextField
                            fullWidth
                            onChange={(evt) => changeName(evt, index)}
                            placeholder=""
                          />
                        </FormControl>
                      </TableCell>
                      <TableCell sx={{ minWidth: "200px" }}>
                        <FormControl>
                          <TextField
                            fullWidth
                            onChange={(evt) => changeDesc(evt, index)}
                            placeholder=""
                          />
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        {item.isUploaded === false ? (
                          <Box sx={{ display: "flex" }}>
                            <Button
                              color="primary"
                              variant="contained"
                              sx={{ marginRight: "10px" }}
                              onClick={() => saveMedia(item, index)}
                            >
                              <CloudUploadIcon sx={{ marginRight: "5px" }} />
                              Upload
                            </Button>
                            <Button
                              color="error"
                              variant="outlined"
                              onClick={() => removeMedia(index)}
                            >
                              <NotInterestedIcon sx={{ marginRight: "5px" }} />
                              Cancel
                            </Button>
                          </Box>
                        ) : (
                          <Box>Upload Complete</Box>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
};

export default MediaUploadFiles;
