import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Button,
  TextField,
  Box,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import { AddItemCategoryList } from "../../../api";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../redux/slices/mainSlice";
import { useDispatch } from "react-redux";

// Classes Styles
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));

const ItemCategories = ({
  itemCategoriesDialog,
  itemCategoriesDialogClose,
  getCategoryList,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [fieldValue, setFieldValue] = useState({
    key: "",
    value: {
      weight: "",
      type: "",
    },
  });

  const [fields, setFields] = useState([]);

  const handleChange = (name, value) => {
    const field = {
      key: fieldValue.key,
      value: {
        weight: fieldValue.value.weight,
        type: fieldValue.value.type,
      },
    };
    if (name === "key") {
      field[name] = value;
    } else {
      field.value[name] = value;
    }
    setFieldValue(field);
  };

  const handleEdit = (name, value, index) => {
    const data = [...fields];
    if (name === "key") {
      data[index][name] = value;
      setFields(data);
    } else {
      data[index].value[name] = value;
      setFields(data);
    }
  };

  const addItemCategoryList = () => {
    setLoading(true);

    const payload = {
      description: fields,
    };
    AddItemCategoryList(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          dispatch(getMsg("Records Updated Successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          getCategoryList();
          setLoading(false);
          itemCategoriesDialogClose();
        } else {
          dispatch(getMsg("Something Went Wrong!"));
          dispatch(getSnackAlert("erorr"));
          dispatch(getSnackOpen());
          setFields(res.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const getItemCategoryList = () => {
    setLoading(true);
    AddItemCategoryList(fields)
      .then((res) => {
        if (res.statusCode == 200) {
          setFields(res.data);
        } else {
          setFields(res.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getItemCategoryList();
  }, []);

  const handleDelete = (index) => {
    setFields(fields.filter((item, itemIndex) => itemIndex !== index));
  };

  return (
    <>
      <Dialog
        aria-labelledby="lift-out-title-modal"
        aria-describedby="lift-out-modal"
        open={itemCategoriesDialog}
        onClose={itemCategoriesDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            background: "#E8E8E8",
          }}
          id="lift-out-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Item Categories
          </Typography>
          <DialogActions>
            <IconButton
              onClick={itemCategoriesDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ padding: "20px" }}>
            Categories for your lost and found items are shown below. Default
            categories for Cash, Cell Phone, Documents, Driver License/ID,
            Jewelry, Laptop and Passport do not appear below but are already
            added to your portal so please do not add those. After entering a
            category name in a blank row below, set an average weight for the
            item then choose the weight scale. Use whole numbers only when
            setting the average weight.
          </Typography>
          <TableContainer
            className="tableContainer"
            component={Paper}
            sx={{
              margin: "15px auto auto",
              "&::-webkit-scrollbar": {
                width: 7,
                height: 7,
              },
              "&::-webkit-scrollbar-track": {
                background: "#bed2f5",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#92a6c9",
              },
            }}
          >
            <Table
              stickyHeader
              aria-labelledby="tableTitle"
              size={true ? "small" : "medium"}
              aria-label="sticky table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">Category</TableCell>
                  <TableCell align="left">Avg. Weight</TableCell>
                  <TableCell align="left">Scale</TableCell>
                  <TableCell align="left">Remove</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fields.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell align="left" sx={{ borderBottom: "none" }}>
                      <TextField
                        name="key"
                        required
                        id={`outlined-required-${index}`}
                        value={item?.key}
                        variant="outlined"
                        placeholder="Enter Category"
                        onChange={(event) => {
                          handleEdit(
                            event.target.name,
                            event.target.value,
                            index
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell align="left" sx={{ borderBottom: "none" }}>
                      <TextField
                        name="weight"
                        required
                        id={`outlined-weight-${index}`}
                        value={item?.value?.weight}
                        variant="outlined"
                        placeholder="Enter Weight"
                        type="number"
                        onChange={(event) => {
                          handleEdit(
                            event.target.name,
                            event.target.value,
                            index
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell align="left" sx={{ borderBottom: "none" }}>
                      <TextField
                        select
                        variant="outlined"
                        name="type"
                        sx={{ width: "90px" }}
                        value={
                          item?.value?.type !== "" && item?.value?.type !== null
                            ? item?.value?.type
                            : "select"
                        }
                        onChange={(event) => {
                          handleEdit(
                            event.target.name,
                            event.target.value,
                            index
                          );
                        }}
                      >
                        <MenuItem value="select" disabled>
                          Select
                        </MenuItem>
                        <MenuItem value="g">g</MenuItem>
                        <MenuItem value="kg">kg</MenuItem>
                        <MenuItem value="lb">lbs</MenuItem>
                        <MenuItem value="oz">oz</MenuItem>
                      </TextField>
                    </TableCell>
                    <TableCell align="left" sx={{ borderBottom: "none" }}>
                      {index == fields.length - 1 ? (
                        <AddCircleIcon
                          sx={{ cursor: "pointer" }}
                          color="primary"
                          onClick={() => {
                            setFields([...fields, fieldValue]);
                            setFieldValue({
                              key: "",
                              value: {
                                weight: "",
                                type: "",
                              },
                            });
                          }}
                        />
                      ) : (
                        <DoDisturbOnIcon
                          sx={{ cursor: "pointer" }}
                          color="error"
                          onClick={() => {
                            handleDelete(index);
                          }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
                {/* <TableRow>
                  <TableCell align="left" sx={{ borderBottom: "none" }}>
                    <TextField
                      name="key"
                      required
                      id={`outlined-required`}
                      placeholder="Enter Category"
                      variant="outlined"
                      value={fieldValue?.key}
                      onChange={(event) => {
                        handleChange(event.target.name, event.target.value);
                      }}
                    />
                  </TableCell>
                  <TableCell align="left" sx={{ borderBottom: "none" }}>
                    <TextField
                      name="weight"
                      required
                      id={`outlined-weight`}
                      placeholder="Enter Weight"
                      variant="outlined"
                      type="number"
                      value={fieldValue?.value?.weight}
                      onChange={(event) => {
                        handleChange(event.target.name, event.target.value);
                      }}
                    />
                  </TableCell>
                  <TableCell align="left" sx={{ borderBottom: "none" }}>
                    <TextField
                      select
                      name="type"
                      variant="outlined"
                      sx={{ width: "90px" }}
                      value={
                        fieldValue?.value?.type !== ""
                          ? fieldValue?.value?.type
                          : "select"
                      }
                      onChange={(event) => {
                        handleChange(event.target.name, event.target.value);
                      }}
                    >
                      <MenuItem value="select" disabled>
                        Select
                      </MenuItem>
                      <MenuItem value="g">g</MenuItem>
                      <MenuItem value="kg">kg</MenuItem>
                      <MenuItem value="lb">lbs</MenuItem>
                      <MenuItem value="oz">oz</MenuItem>
                    </TextField>
                  </TableCell>
                  <TableCell align="left" sx={{ borderBottom: "none" }}>
                    <AddCircleIcon
                      sx={{ cursor: "pointer" }}
                      color="primary"
                      onClick={() => {
                        setFields([...fields, fieldValue]);
                        setFieldValue({
                          key: "",
                          value: {
                            weight: "",
                            type: "",
                          },
                        });
                      }}
                    />
                  </TableCell>
                </TableRow> */}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions sx={{ pr: "28px" }}>
          <Button
            color="warning"
            variant="outlined"
            onClick={() => {
              addItemCategoryList();
            }}
          >
            Submit
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={itemCategoriesDialogClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
};

export default ItemCategories;
