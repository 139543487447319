import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Formik } from "formik";
import CustomTextField from "../../../components/form-components/CustomTextField";
import CustomSelect from "../../../components/form-components/CustomSelect";
import {
  GetAddEquipmentType,
  AddEquipType,
  DeleteEquipmentType,
  EquipmentTypeTree,
} from "../../../api";
import { handleApiResonseErrors } from "../../../helper/helper";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../../redux/slices/equipmentSlice";
import {
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
  Button,
  Dialog,
  DialogContentText,
  DialogContent,
  DialogActions,
  DialogTitle,
  Grid,
  Select,
  MenuItem,
  FormHelperText,
  InputLabel as MuiInputLabel,
  Box,
} from "@mui/material";
import { spacing } from "@mui/system";
import EditEquipmentType from "./EditEquipmentType";
import CustomCheckboxTree from "../../../components/form-components/CustomCheckboxTree";
import { id } from "date-fns/locale";
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const AddEquipmentType = ({
  addEquipOpen,
  addEquipClose,
  openaddequip,
  setOpenAddEquip,
  getEquipmentTypeList,
}) => {
  const securitylevel = [
    { key: "option one", value: "option one" },
    { key: "option two", value: "option two" },
    { key: "option three", value: "option three" },
    { key: "option four", value: "option four" },
    { key: "option five", value: "option five" },
  ];

  //get all task checkbox
  const [modulearray, setmodulearray] = useState([]);
  const [isChecked, setIsChecked] = useState(null);
  const [equipType, setEquipType] = useState([]);
  const [errMsg, setErrMsg] = useState("");
  const [close, setClose] = useState("");
  const [name, setName] = useState("");
  const [sL, setSL] = useState(null);
  const dispatch = useDispatch();
  const handleChecked = (e, name, securityLevel) => {
    const { value, checked } = e.target;
    setIsChecked(value);
    setName(name);
    setSL(securityLevel);
    if (checked) {
      setmodulearray([...modulearray, value]);
    } else {
      setmodulearray(modulearray.filter((e) => e !== value));
    }
  };

  let sLevel = [];
  for (let i = 1; i <= 10; i++) {
    sLevel.push(i);
  }

  const handleSecurityLevel = (e) => {
    console.log(e.target.value);
  };
  const closeDialogHandle = (e) => {
    setClose(e.target.value);
  };

  // Checkbox Tree
  const [equipTypeTree, setEquipTypeTree] = useState([]);
  const [isCheckedValue, setIsCheckedValue] = useState(0);

  //editEquipmentType
  const [open, setOpen] = useState(false);
  const editOpen = () => {
    setOpen(true);
  };
  const editClose = () => {
    setOpen(false);
  };

  const handleCheckedTree = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setIsCheckedValue(value);
    } else {
      setIsCheckedValue(0);
    }
  };
  const getEquipmentTypeTree = () => {
    EquipmentTypeTree()
      .then((res) => {
        if (res.statusCode === 200) {
          setEquipTypeTree(res.data);
        } else {
          setEquipTypeTree([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * This function is used to get the equipment type from the database
   */
  const getEquipmentType = () => {
    GetAddEquipmentType()
      .then((res) => {
        if (res.statusCode === 200) {
          setEquipType(res.data);
        } else {
          setEquipType([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteEquipmentType = () => {
    DeleteEquipmentType(isCheckedValue)
      .then((res) => {
        if (res.statusCode === 200) {
          getEquipmentTypeTree();
          getEquipmentTypeList();
          dispatch(getSnackOpen());
          dispatch(getMsg("Record has been deleted successfully"));
          dispatch(getSnackAlert("success"));
          getEquipmentType();
        } else {
          dispatch(getSnackOpen());
          dispatch(getMsg(res.message));
          dispatch(getSnackAlert("error"));
          // dispatch(getSnackClose());
        }
      })
      .catch((err) => {
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        if (error) {
          setErrMsg(error);
        }
        dispatch(getSnackClose());
      });
  };

  /* This is a react hook that is used to run a function when the component is mounted. */
  useEffect(() => {
    getEquipmentType();
    getEquipmentTypeTree();
  }, []);

  const validationSchema = Yup.object().shape({
    equip_type_name: Yup.string().required("Please enter equipment type name"),
    security_level: Yup.string().required("Please select security level"),
  });
  const initialValues = {
    security_level: "",
    equip_type_name: "",
  };
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      let formData = {
        parent_id: isCheckedValue ? isCheckedValue : 0,
        name: values.equip_type_name,
        security_level: values.security_level,
      };
      AddEquipType(formData)
        .then((res) => {
          if (res.statusCode === 200) {
            dispatch(getSnackOpen());
            getEquipmentTypeTree();
            getEquipmentTypeList();
            dispatch(getMsg("Equipment type has been submitted successfully"));
            dispatch(getSnackAlert("success"));
            if (close === "close") {
              addEquipClose();
            }
            getEquipmentType();
          } else {
            dispatch(getSnackClose());
          }
        })
        .catch((err) => {
          // console.log(err.data.errors);
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            setErrMsg(error);
          }
          dispatch(getSnackClose());
        });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  const [checked, setChecked] = React.useState([true, false]);

  const handleChange1 = (event) => {
    setChecked([event.target.checked, event.target.checked]);
  };

  const handleChange2 = (event) => {
    setChecked([event.target.checked, checked[1]]);
  };

  const handleChange3 = (event) => {
    setChecked([checked[0], event.target.checked]);
  };

  return (
    <div>
      <Dialog
        aria-labelledby="add-equipment-title"
        aria-describedby="add-equipment-description"
        open={openaddequip}
        onClose={addEquipClose}
        sx={{
          "& .MuiFormControl-root": {
            display: "block",
          },
        }}
      >
        <DialogTitle id="add-equipment-title">Add Equipment Type</DialogTitle>
        <DialogContent>
          <DialogContentText id="add-equipment-description">
            Add a new top level equipment type by entering a name in the field
            below, then select a Security Level (SL) and save. SL is a number
            between 1 and 10 with higher numbers representing more restrictive
            equipment types. Add a child equipment type by first selecting the
            parent type from the list below. To edit or delete an existing
            equipment type, select it, then click the edit or delete button that
            appears at the bottom of this popup.
          </DialogContentText>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <FormControl mt={4}>
                <CustomCheckboxTree
                  handleChecked={(evt) => handleCheckedTree(evt)}
                  equipType={equipTypeTree}
                  isChecked={isCheckedValue}
                />
              </FormControl>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  touched,
                  values,
                  status,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid item xs={12} mt={6}>
                      <FormControl>
                        <CustomTextField
                          name="equip_type_name"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Equipment type name*"
                          autoComplete="off"
                          placeholder="No special characters"
                        />
                        <FormHelperText
                          sx={{ color: "#d32f2f", marginLeft: "14px" }}
                        >
                          {errMsg}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} mt={6}>
                      <FormControl>
                        <InputLabel
                          shrink
                          id="security_level"
                          sx={{
                            paddingLeft: "6px",
                            paddingRight: "6px",
                            marginLeft: "-4px",
                          }}
                        >
                          Security level*
                        </InputLabel>
                        <Select
                          labelId="security_level"
                          id="security_level"
                          name="security_level"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={values.security_level}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(
                            touched.security_level && errors.security_level
                          )}
                          helperText={
                            touched.security_level && errors.security_level
                          }
                          fullWidth
                          displayEmpty
                        >
                          <MenuItem disabled value="">
                            Select security level
                          </MenuItem>
                          {sLevel.map((data) => {
                            return (
                              <MenuItem value={data} key={data}>
                                {data}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText
                          sx={{ color: "#d32f2f", marginLeft: "14px" }}
                        >
                          {touched.security_level && errors.security_level}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} mt={6}>
                      <Button color="primary" variant="outlined" type="submit">
                        Save & Add More
                      </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        value={"close"}
                        sx={{ marginLeft: "10px" }}
                        type="submit"
                        onClick={closeDialogHandle}
                      >
                        Save & Done
                      </Button>
                    </Grid>
                  </form>
                )}
              </Formik>
            </Grid>
          </Grid>
          {isCheckedValue > 0 && (
            <DialogActions>
              <Button color="primary" variant="contained" onClick={editOpen}>
                Edit
              </Button>
              <Button
                color="primary"
                variant="outlined"
                onClick={deleteEquipmentType}
              >
                Delete
              </Button>
            </DialogActions>
          )}
        </DialogContent>
      </Dialog>
      {open && (
        <EditEquipmentType
          editClose={editClose}
          editOpen={editOpen}
          open={open}
          setOpen={setOpen}
          getEquipmentTypeTree={getEquipmentTypeTree}
          isCheckedValue={isCheckedValue}
        />
      )}
    </div>
  );
};

export default AddEquipmentType;
