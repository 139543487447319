import React from "react";
import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
//Classes Styling
const useStyles = makeStyles((theme) => ({
  BulletList: {
    listStyle: "disc",
    "& .MuiListItem-root": {
      padding: 0,
      display: "block",
    },
  },
}));
const Image = styled.img`
  max-width: 100%;
  height: 100%;
  display: block;
  border-radius: 5px;
  position: relative;
  z-index: 0;
  object-fit: cover;
`;
const ImageWrapper = styled.div`
  height: 100%;
  &:before {
  }
`;
const ItemQuickView = ({
  itemQuickView,
  itemQuickViewClose,
  itemDataToView,
}) => {
  const classes = useStyles();
  return (
    <>
      <Dialog
        open={itemQuickView}
        onClocse={itemQuickViewClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "800px",
            },
          },
        }}
        aria-labelledby="item-quick-view-title"
        aria-describedby="item-quick-view-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="item-quick-view-title"
        >
          <Typography variant="h4">Control ID: {itemDataToView.id}</Typography>
          <DialogActions>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={itemQuickViewClose}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6} lg={5} xl={4}>
              <ImageWrapper className={classes.ImageWrapper}>
                <Image
                  alt="SaaS Dashboard | SOVA"
                  src={
                    itemDataToView.image
                      ? itemDataToView.image
                      : `/static/img/lost-found/lost_and_found_pic.jpg`
                  }
                />
              </ImageWrapper>
            </Grid>
            <Grid item xs={12} md={6} lg={7} xl={8}>
              <Typography variant="h5" component="div" gutterBottom>
                Item Details:
              </Typography>
              <List className={classes.BulletList}>
                <ListItem>
                  <ListItemText
                    primary={"Found Date: " + itemDataToView.found_date}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={"Category: " + itemDataToView.category}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={"Description: " + itemDataToView.description}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={"Found By: " + itemDataToView.found_name}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={"Found Location: " + itemDataToView.found_location}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={"Storage: " + itemDataToView.storage_location}
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ padding: "15px 20px" }}>
          <Link
            to={"/lostandfound/edit-lost-found/" + itemDataToView.id}
            style={{ textDecoration: "none" }}
          >
            <Button color="primary" variant="contained">
              Edit
            </Button>
          </Link>
          <Link
            target="_blank"
            to={"/lostandfound/lost-found-details/" + itemDataToView.id}
            style={{ textDecoration: "none" }}
          >
            <Button
              color="primary"
              variant="contained"
              sx={{ marginLeft: "5px" }}
            >
              Print PDF
            </Button>
          </Link>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ItemQuickView;
