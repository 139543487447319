import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import FireTab from "./inner-component/EmergencyProceduresTab/FireTab";
import Medical from "./inner-component/EmergencyProceduresTab/Medical";
import Weather from "./inner-component/EmergencyProceduresTab/Weather";
import Security from "./inner-component/EmergencyProceduresTab/Security";
import Engineering from "./inner-component/EmergencyProceduresTab/Engineering";
import { useSelector, useDispatch } from "react-redux";
import CustomAlert from "../../components/form-components/CustomAlert";
import { updateShowAlert } from "../../redux/slices/mainSlice";
import {
  Typography,
  Tabs,
  Tab,
  Box,
  Paper as MuiPaper,
  Divider as MuiDivider,
  CircularProgress,
} from "@mui/material";

import { spacing } from "@mui/system";
const Divider = styled(MuiDivider)(spacing);
const Paper = styled(MuiPaper)(spacing);
// Classes Styling
const useStyles = makeStyles((theme) => ({
  tabs: {
    "& .MuiTabs-scroller": {
      borderBottom: "1px solid #0000000f",
    },
  },
  fixedLoader: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function emergencyProceduresTabs(index) {
  return {
    id: `incident-tab-${index}`,
    "aria-controls": `incident-tabpanel-${index}`,
  };
}
function EmergencyProceduresContent(props) {
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  // Redux
  const showAlert = useSelector((state) => state.main.showAlert);
  const showAlertText = useSelector((state) => state.main.text);

  // Alert Message
  const [alertMessage, setAlertMessage] = useState(showAlertText);
  const [alertType, setAlertType] = useState("success");

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(updateShowAlert(false));
    }, 3000);
  }, []);

  return (
    <>
      <Paper mt={6}>
        <Box className={classes.tabs}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="emergency-procedures"
            variant="scrollable"
          >
            <Tab label="Fire" {...emergencyProceduresTabs(0)} />
            <Tab label="Medical" {...emergencyProceduresTabs(1)} />
            <Tab label="Security" {...emergencyProceduresTabs(2)} />
            <Tab label="Engineering" {...emergencyProceduresTabs(3)} />
            <Tab label="Weather" {...emergencyProceduresTabs(4)} />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <FireTab setLoading={(status) => setLoading(status)} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Medical setLoading={(status) => setLoading(status)} />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <Security setLoading={(status) => setLoading(status)} />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <Engineering setLoading={(status) => setLoading(status)} />
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          <Weather setLoading={(status) => setLoading(status)} />
        </TabPanel>
      </Paper>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
      {showAlert && (
        <CustomAlert
          message={alertMessage}
          severity={alertType}
          closeAlert={(show) => dispatch(updateShowAlert(show))}
          showAlert={showAlert}
        />
      )}
    </>
  );
}

function EmergencyProcedures() {
  return (
    <React.Fragment>
      <Helmet title="Emergency Procedures" />
      <Typography variant="h3" gutterBottom display="inline">
        Emergency Procedures
      </Typography>
      <EmergencyProceduresContent />
    </React.Fragment>
  );
}

export default EmergencyProcedures;
