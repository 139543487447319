import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";
import { Typography, Paper as MuiPaper, Link, Grid } from "@mui/material";
import { spacing } from "@mui/system";
import { AddCircleOutlineOutlined as AddCircleOutlineOutlinedIcon } from "@mui/icons-material";
import { EmergencyProcedures } from "../../../../api";

const Paper = styled(MuiPaper)(spacing);
const Image = styled.img`
  width: 100%;
`;

function Security({ setLoading }) {
  const [respond, setRespond] = useState(null);
  const [contact, setContact] = useState(null);
  const [report, setReport] = useState(null);

  useEffect(() => {
    setLoading(true);
    getRespond();
  }, []);

  const getRespond = () => {
    let payload = {
      type: "Security",
      sub_type: "Respond",
    };
    EmergencyProcedures(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setRespond(res.data);
        }
        getContact();
      })
      .catch((err) => {});
  };

  const getContact = () => {
    let payload = {
      type: "Security",
      sub_type: "Contact",
    };
    EmergencyProcedures(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setContact(res.data);
        }
        getReport();
      })
      .catch((err) => {});
  };

  const getReport = () => {
    let payload = {
      type: "Security",
      sub_type: "Report",
    };
    EmergencyProcedures(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setReport(res.data);
        }
        setLoading(false);
      })
      .catch((err) => {});
  };

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <Typography
            variant="h4"
            component="div"
            gutterBottom
            sx={{ mb: "30px", mt: "15px" }}
          >
            Respond
          </Typography>
          <Paper className="addEmergency-product" style={{ minHeight: 250 }}>
            {respond?.image_name ? (
              <Image src={respond.image_name} />
            ) : (
              <Image src={`/static/img/lost-found/lost_and_found_pic.jpg`} />
            )}
            <Link
              className="overlay-link"
              component={NavLink}
              to="/logs/edit-emergency-procedures/Security/Respond"
            >
              {respond?.description ? "Click to edit" : "Click to add"}
              <AddCircleOutlineOutlinedIcon />
            </Link>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <Typography
            variant="h4"
            component="div"
            gutterBottom
            sx={{ mb: "30px", mt: "15px" }}
          >
            Contact
          </Typography>
          <Paper className="addEmergency-product" style={{ minHeight: 250 }}>
            {contact?.image_name ? (
              <Image src={contact.image_name} />
            ) : (
              <Image src={`/static/img/lost-found/lost_and_found_pic.jpg`} />
            )}
            <Link
              className="overlay-link"
              component={NavLink}
              to="/logs/edit-emergency-procedures/Security/Contact"
            >
              {contact?.description ? "Click to edit" : "Click to add"}
              <AddCircleOutlineOutlinedIcon />
            </Link>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <Typography
            variant="h4"
            component="div"
            gutterBottom
            sx={{ mb: "30px", mt: "15px" }}
          >
            Report
          </Typography>
          <Paper className="addEmergency-product" style={{ minHeight: 250 }}>
            {report?.image_name ? (
              <Image src={report.image_name} />
            ) : (
              <Image src={`/static/img/lost-found/lost_and_found_pic.jpg`} />
            )}
            <Link
              className="overlay-link"
              component={NavLink}
              to="/logs/edit-emergency-procedures/Security/Report"
            >
              {report?.description ? "Click to edit" : "Click to add"}
              <AddCircleOutlineOutlinedIcon />
            </Link>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Security;
