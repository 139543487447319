import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {
  GetEquipmentUserList,
  DeleteEquipmentUser,
  EquipmentUserPdf,
  GetAllDepartment,
  PositionByDepartment,
  ImportEquipmentUser,
} from "../../api";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../redux/slices/mainSlice";
import { format } from "date-fns";
import EquipmentUserListCol from "../table-columns-json/equipment/equipmentUserList";
import { setEquipmentUserList } from "../../redux/slices/equipment/equipmentTableColSlice";
import StyledColumnOptions from "../../helper/StyledColumnOptions";
import {
  Checkbox,
  Grid,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  InputAdornment,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  InputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  Radio,
  CardContent,
  FormLabel,
  RadioGroup,
  Card,
  Box,
  Button,
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  CircularProgress,
  Snackbar,
  Alert,
  Pagination,
  Tooltip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  AddBox as AddBoxIcon,
  Settings as SettingsIcon,
  Create as CreateIcon,
  PictureAsPdf as PictureAsPdfIcon,
  Cached as CachedIcon,
  Portrait as PortraitIcon,
  ExpandMore as ExpandMoreIcon,
  SettingsInputComposite as SettingsInputCompositeIcon,
} from "@mui/icons-material";
import { DatePicker } from "@mui/lab";
import { spacing } from "@mui/system";
import Toggle from "./Toggle";
import Toaster from "../tasks/Toaster";
import EquipmentUserLog from "./innerform/EquipmentUserLog";

const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "140px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);

const StyledForm = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    marginLeft: "75px",
    marginTop: "8px",
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      minWidth: "435px",
      "& form": {
        padding: "15px 20px",
      },
      "& .MuiMenuItem-root": {
        padding: "0",
        display: "block",
        "& .MuiFormControl-root": {
          display: "block",
          width: "100%",
          marginBottom: "20px",
        },
      },
    },
    "& .Mui-focusVisible": {
      backgroundColor: "transparent",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
function createData(
  barcode,
  fname,
  lname,
  dept,
  position,
  sl,
  updated,
  lastseen,
  active,
  out,
  pcount,
  action
) {
  return {
    barcode,
    fname,
    lname,
    dept,
    position,
    sl,
    updated,
    lastseen,
    active,
    out,
    pcount,
    action,
  };
}
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}
const headCells = [
  { id: "barcode", numeric: true, disablePadding: true, label: "Barcode ID" },
  {
    id: "fname",
    numeric: true,
    disablePadding: false,
    label: "First Name",
  },
  { id: "lname", numeric: true, disablePadding: false, label: "Last Name" },
  { id: "dept", numeric: true, disablePadding: false, label: "Department" },
  { id: "position", numeric: true, disablePadding: false, label: "Position" },
  { id: "sl", numeric: true, disablePadding: false, label: "SL" },
  { id: "updated", numeric: true, disablePadding: false, label: "Updated" },
  { id: "lastseen", numeric: true, disablePadding: false, label: "Last Seen" },
  { id: "active", numeric: true, disablePadding: false, label: "Active" },
  {
    id: "out",
    numeric: true,
    disablePadding: false,
    label: "Out",
  },
  {
    id: "pcount",
    numeric: true,
    disablePadding: false,
    label: "Pcount",
  },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];
const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    tableCalRedux,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all data" }}
          />
        </TableCell>
        {EquipmentUserListCol.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            sx={{ whiteSpace: "nowrap" }}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={tableCalRedux[headCell.id] === true ? "" : "hide-cell"}
          >
            <TableSortLabel
              active={headCell.id === "fname" ? orderBy === headCell.id : false}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={
                headCell.id === "last_seen" ||
                headCell.id === "active" ||
                headCell.id === "out" ||
                headCell.id === "pcount" ||
                headCell.id === "action"
                  ? null
                  : createSortHandler(headCell.id)
              }
              hideSortIcon={
                headCell.id === "last_seen" ||
                headCell.id === "active" ||
                headCell.id === "out" ||
                headCell.id === "pcount" ||
                headCell.id === "action"
                  ? true
                  : false
              }
            >
              <Tooltip title={headCell.tooltipMsg}>
                <div>{headCell.label}</div>
              </Tooltip>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const {
    totalRecord,
    setDataToFilter,
    handleDeleteOpen,
    handleFirstPage,
    tableCalRedux,
    defalutTableCalRedux,
    rowsPerPage,
    order,
    orderBy,
    dataToFilter,
    getEquipmentUserList,
  } = props;
  const [actions, setActions] = React.useState("");
  const [barcodeId, setBarcodeId] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [name, setName] = useState("");
  const [department, setDepartment] = useState("");
  const [position, setPosition] = useState("");
  const [registeredDate, setRegisteredDate] = useState(null);
  const [showDeletedUser, setShowDeletedUser] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const [departmentList, setDepartmentList] = useState([]);
  const [positionList, setPositionList] = useState([]);
  const handleDepartment = () => {
    GetAllDepartment()
      .then((res) => {
        setDepartmentList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangeDepartment = (value) => {
    PositionByDepartment(value)
      .then((res) => {
        if (res.statusCode === 200) {
          setPositionList(res.data);
        } else {
          setPositionList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //method for filter data
  const applySearch = () => {
    const payload = {
      emp_id: barcodeId,
      // employee_id: employeeId,
      name: name,
      department: departmentList.find((item) => item.id == department)
        ?.department_name,
      position: position,
      createdAt:
        registeredDate !== null ? format(registeredDate, "yyyy-MM-dd") : null,
      visibility: showDeletedUser,
    };
    setDataToFilter(payload);
  };

  //function for reseting the filter
  const resetFilter = () => {
    setBarcodeId("");
    setEmployeeId("");
    setName("");
    setDepartment("");
    setPosition("");
    setShowDeletedUser("");
    setRegisteredDate(null);
  };

  const handleChange = (event) => {
    setActions(event.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // Search Dropdown
  const [anchorForm, setAnchorForm] = React.useState(null);
  const handleClickDropdown = (event) => {
    setAnchorForm(event.currentTarget);
  };
  const handleCloseForm = () => {
    setAnchorForm(null);
  };

  // Import User List Dialog
  const [importUserDialog, setImportUserDialog] = useState(false);
  const handleImportUser = (event) => {
    setImportUserDialog(event.currentTarget);
    setAnchorEl(null);
  };
  const handleCloseImportDialog = () => {
    setImportUserDialog(false);
  };
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleDownloadSampleCSV = () => {
    const link = document.createElement("a");
    link.href = `${process.env.PUBLIC_URL}/static/Import_User_List_Sample.csv`;
    link.download = "sample_user_list.csv";
    link.click();
  };

  const handleImport = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      try {
        setLoading(true);
        const res = await ImportEquipmentUser(formData);
        if (res.statusCode === 200) {
          dispatch(getMsg("Equipment user list imported successfully!"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
          handleCloseImportDialog();
          getEquipmentUserList();
        }
      } catch (error) {
        console.error(error?.data?.message);
        dispatch(getMsg(error?.data?.message || "Opps Something went wrong!"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      } finally {
        setLoading(false);
      }
    }
  };

  // Columns Dropdown
  const [anchorColumn, setAnchorColumn] = React.useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };

  const onButtonClick = () => {
    setLoading(true);
    const payload = {
      limit: rowsPerPage,
      sorting: {
        order: order,
        by: orderBy,
      },
      ...dataToFilter,
    };
    EquipmentUserPdf(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          const linkSource = `data:application/pdf;base64,${res.data}`;
          const downloadLink = document.createElement("a");
          const fileName = "SOVA-Equipment-User.pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          setLoading(false);
        } else {
          console.log("something went wrong");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    handleDepartment();
  }, []);

  return (
    <Toolbar>
      <div>
        <Paper mt={3}>
          <FormControl mr={2} sx={{ minWidth: 120 }}>
            <InputLabel id="demo-simple-select-label">Actions</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={actions}
              label="Actions"
              MenuProps={{
                PaperProps: {
                  sx: {
                    "& .MuiMenu-list": {
                      boxShadow:
                        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 10%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
                      margin: "0px 3px 5px 3px",
                      padding: "0",
                    },
                  },
                },
              }}
              onChange={handleChange}
            >
              <MenuItem value={"delete"} onClick={handleDeleteOpen}>
                Delete
              </MenuItem>
            </Select>
          </FormControl>
        </Paper>
      </div>
      <div className="totalRecord">
        {totalRecord > 0 ? (
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content", marginLeft: "15px" }}
          >
            {totalRecord} records found
          </Typography>
        ) : (
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content", marginLeft: "15px" }}
          >
            {totalRecord} records found
          </Typography>
        )}
      </div>
      <Spacer />
      <div className="searchTool">
        <Box
          mt={3}
          sx={{
            display: "flex",
            flexWrap: "nowrap",
          }}
        >
          <Item sx={{ flexGrow: 1, width: "100%" }}>
            <TextField
              id="search"
              placeholder="Search by name or clickdown arrow"
              value={name}
              onChange={(e) => setName(e.target.value)}
              inputProps={{}}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  applySearch({});
                  setIsFilter(true);
                  handleFirstPage();
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-owns={anchorForm ? "search-form" : undefined}
                      aria-haspopup="true"
                      onClick={handleClickDropdown}
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                    {anchorForm && (
                      <StyledForm
                        id="search-form"
                        anchorEl={anchorForm}
                        open={Boolean(anchorForm)}
                        onClose={handleCloseForm}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <MenuItem
                          sx={{ maxHeight: "380px", overflowY: "auto" }}
                        >
                          <form
                            onKeyDown={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <FormControl mt={3}>
                              <TextField
                                fullWidth
                                id="barcode-id"
                                label="Barcode ID"
                                value={barcodeId}
                                onChange={(e) => setBarcodeId(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="ID for scanning"
                              />
                            </FormControl>
                            <FormControl>
                              <TextField
                                fullWidth
                                id="name"
                                label="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="Mike Smith"
                              />
                            </FormControl>

                            <FormControl mt={6}>
                              <InputLabel
                                shrink
                                id="department"
                                sx={{
                                  background: "whitesmoke",
                                  paddingLeft: "6px",
                                  paddingRight: "6px",
                                  marginLeft: "-4px",
                                }}
                              >
                                Department*
                              </InputLabel>
                              <Select
                                labelId="department"
                                id="department-select"
                                name="department"
                                label="Department*"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                value={department}
                                onChange={(e) => {
                                  setDepartment(e.target.value);
                                  handleChangeDepartment(e.target.value);
                                }}
                                fullWidth
                                displayEmpty
                              >
                                <MenuItem disabled value="">
                                  Select department
                                </MenuItem>
                                {departmentList.map((data) => {
                                  return (
                                    <MenuItem value={data.id} key={data.id}>
                                      {data.department_name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>

                            <FormControl mt={6}>
                              <InputLabel
                                shrink
                                id="position"
                                sx={{
                                  background: "whitesmoke",
                                  paddingLeft: "6px",
                                  paddingRight: "6px",
                                  marginLeft: "-4px",
                                }}
                              >
                                Position*
                              </InputLabel>
                              <Select
                                labelId="position"
                                id="position-select"
                                name="position"
                                label="position*"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                value={position}
                                onChange={(e) => {
                                  setPosition(e.target.value);
                                }}
                                fullWidth
                                displayEmpty
                              >
                                <MenuItem disabled value="">
                                  Select position
                                </MenuItem>
                                {positionList.map((data) => {
                                  return (
                                    <MenuItem
                                      value={data.position_name}
                                      key={data.id}
                                    >
                                      {data.position_name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                            <FormControl>
                              <DatePicker
                                id="date-registered"
                                label="Date registered"
                                value={registeredDate}
                                onChange={(newValue) => {
                                  setRegisteredDate(newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{
                                      ...params.inputProps,
                                      placeholder: "Click to select",
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel component="legend">
                                Show deleted users?
                              </FormLabel>
                              <RadioGroup
                                aria-labelledby="equipment-dispotion"
                                defaultValue="In service"
                                name="radio-buttons-group"
                                value={showDeletedUser}
                                onChange={(e) =>
                                  setShowDeletedUser(e.target.value)
                                }
                              >
                                <FormControlLabel
                                  value="D"
                                  control={<Radio />}
                                  label="Yes"
                                />
                                <FormControlLabel
                                  value="A"
                                  control={<Radio />}
                                  label="No"
                                />
                              </RadioGroup>
                            </FormControl>
                            <FormControl>
                              <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                mt={6}
                                sx={{ width: "175px", marginRight: "5px" }}
                                onClick={() => {
                                  applySearch({});
                                  handleCloseForm();
                                  setIsFilter(true);
                                  handleFirstPage();
                                }}
                              >
                                Filter
                              </Button>
                            </FormControl>
                          </form>
                        </MenuItem>
                      </StyledForm>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Item>
          <Item>
            {isFilter ? (
              <Button
                variant="contained"
                color="primary"
                sx={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  height: "100%",
                  width: "100%",
                  marginLeft: "-3px",
                }}
                onClick={() => {
                  resetFilter();
                  setDataToFilter(null);
                  setIsFilter(false);
                }}
              >
                Clear
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                sx={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  height: "100%",
                  width: "100%",
                  marginLeft: "-3px",
                }}
                onClick={() => {
                  if (name !== "") {
                    applySearch({});
                    setIsFilter(true);
                    handleFirstPage();
                  }
                }}
              >
                Search
              </Button>
            )}
          </Item>
        </Box>
      </div>
      <Spacer />
      <div>
        <Paper mt={3}>
          <IconButton
            aria-owns={anchorEl ? "icon-menu" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            variant="contained"
            color="secondary"
          >
            <FilterListIcon />
          </IconButton>
          <StyledFilterMenu
            id="icon-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem
              aria-label="add-equipment-user"
              component={NavLink}
              to="/equipment/add-equipment-user"
            >
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="Add User" pl={0} />
            </MenuItem>
            <MenuItem onClick={onButtonClick}>
              <ListItemIcon>
                <PictureAsPdfIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="Download PDF" pl={0} />
            </MenuItem>
            <MenuItem
              aria-label="equipment-list"
              component={NavLink}
              to="/equipment/equipment-list"
            >
              <ListItemIcon>
                <PortraitIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="List Equipment" pl={0} />
            </MenuItem>
            <MenuItem
              aria-label="process-equipment"
              component={NavLink}
              to="/equipment/process-equipment"
            >
              <ListItemIcon>
                <CachedIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="Process Equipment" pl={0} />
            </MenuItem>
            <MenuItem
              aria-label="equipment-logs"
              component={NavLink}
              to="/equipment/equipment-logs"
            >
              <ListItemIcon>
                <SettingsInputCompositeIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="Equipment Logs" pl={0} />
            </MenuItem>
            <MenuItem aria-haspopup="true" onClick={handleImportUser}>
              <ListItemIcon>
                <PortraitIcon />
              </ListItemIcon>
              <ListItemText primary="Import User List" pl={0} />
            </MenuItem>
            <MenuItem
              aria-owns={anchorColumn ? "columns-dropdown" : undefined}
              aria-haspopup="true"
              onClick={handleClickColumn}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Columns" pl={0} />
            </MenuItem>
          </StyledFilterMenu>
          <StyledColumnDropdown
            id="columns-dropdown"
            anchorEl={anchorColumn}
            open={Boolean(anchorColumn)}
            onClose={handleCloseColumn}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem>
              <Card>
                <CardContent>
                  <Typography variant="h6" component="h2">
                    {
                      Object.keys(tableCalRedux).filter(
                        (k) => tableCalRedux[k] === true
                      ).length
                    }{" "}
                    out of {Object.keys(tableCalRedux).length} visible
                  </Typography>
                  <StyledColumnOptions
                    calStatus={tableCalRedux}
                    updateCalStatus={(data) => {
                      dispatch(setEquipmentUserList(data));
                    }}
                    listing={EquipmentUserListCol}
                  />
                  <Toolbar>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        dispatch(setEquipmentUserList(defalutTableCalRedux));
                      }}
                    >
                      Reset
                    </Button>
                    <Spacer />
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleCloseColumn}
                    >
                      Cancel
                    </Button>
                  </Toolbar>
                </CardContent>
              </Card>
            </MenuItem>
          </StyledColumnDropdown>
        </Paper>
        {importUserDialog && (
          <Dialog
            open={importUserDialog}
            onClose={handleCloseImportDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Import User List</DialogTitle>
            <DialogContent dividers>
              <Typography variant="subtitle1" gutterBottom>
                To import the equipment user list, please use the provided CSV
                format. You can download the sample CSV below:
              </Typography>
              <Button
                variant="outlined"
                onClick={handleDownloadSampleCSV}
                // startIcon={<UploadFile />}
              >
                Download Sample CSV
              </Button>

              <Typography variant="subtitle1" style={{ marginTop: "20px" }}>
                After filling the CSV, upload it below to import the user list:
              </Typography>
              <input
                accept=".csv"
                id="file-upload"
                type="file"
                onChange={handleFileChange}
                style={{ marginTop: "10px" }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseImportDialog} variant="contained">
                Cancel
              </Button>
              <Button
                onClick={handleImport}
                variant="contained"
                disabled={!selectedFile}
              >
                Import
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {loading && (
          <Paper className={classes.fixedLoader}>
            <CircularProgress />
          </Paper>
        )}
      </div>
    </Toolbar>
  );
};
function EnhancedTable() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("color");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [equipmentUser, setEquipmentUser] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [dataToFilter, setDataToFilter] = useState(null);
  const [id, setId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [length, setLength] = useState(null);
  const [alert, setAlert] = useState("");
  const [msg, setMsg] = useState("");
  const dispatch = useDispatch();

  const tableCalRedux = useSelector(
    (state) => state.equipmentTableCol.equipmentUserList
  );
  const defalutTableCalRedux = useSelector(
    (state) => state.defaultEquipmentTableCol.equipmentUserList
  );
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = equipmentUser.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    setLength(newSelected.length);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
    setPage(0);
    setSelected([]);
  };

  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
  };
  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (fname) => selected.indexOf(fname) !== -1;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleActionClick = (e, id) => {
    setAnchorEl(e.currentTarget);
    setId(id);
    setLength(1);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
    setId([]);
  };
  //Equipment log modal
  const [open, setOpen] = React.useState(false);
  const clickLogModalOpen = () => {
    setOpen(true);
    setAnchorEl(null);
  };
  const logModalClose = () => {
    setOpen(false);
  };

  /**
   * This function is used to get the list of equipment users.
   */
  const getEquipmentUserList = () => {
    setLoading(true);
    const payload = {
      limit: rowsPerPage,
      sorting: {
        order: order,
        by: orderBy,
      },
      ...dataToFilter,
    };
    GetEquipmentUserList(currentPage, payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setEquipmentUser(res.data);
          setTotalRecord(res.meta.total);
          setCurrentPage(res.meta.current_page);
          setLoading(false);
        } else {
          setLoading(false);
          setEquipmentUser(res.data);
          setTotalRecord(0);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setTotalRecord(0);
      });
  };

  /**
   * When the delete button is clicked, the delete modal is opened
   */
  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  /**
   * It closes the delete dialog and resets the anchor element and id
   */
  const handleDeleteClose = () => {
    setOpenDelete(false);
    setAnchorEl(null);
    setId(null);
  };

  /**
   * If the user clicks away from the snackbar, the snackbar will not close. Otherwise, the snackbar will
   * close
   * @param reason - The reason for the close event.
   * @returns The function handleCloseSnack is being returned.
   */
  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  /**
   * It deletes the selected equipment user(s) from the database
   */
  const deleteEquipmentUser = () => {
    setAnchorEl(null);
    setOpenDelete(false);
    let value = selected.length > 0 ? selected : id;
    DeleteEquipmentUser(value)
      .then((res) => {
        if (res.statusCode === 200) {
          setMsg(
            `A total of ${length > 0 ? length : 1} record(s) have been deleted`
          );
          setAlert("success");
          setOpenSnack(true);
          getEquipmentUserList();
        } else {
          setMsg("Some went wrong");
          setAlert("error");
          setOpenSnack(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //useEffect method
  useEffect(() => {
    getEquipmentUserList();
    setTimeout(() => {
      dispatch(getSnackClose());
    }, 4000);
  }, [rowsPerPage, currentPage, dataToFilter, order, orderBy]);

  return (
    <div>
      <Paper mt={3}>
        <Toaster
          openSnack={openSnack}
          handleCloseSnack={handleCloseSnack}
          severity={alert}
          message={msg}
        />
        <div>
          {selected.length || id > 0 ? (
            <Dialog
              open={openDelete}
              onClose={handleDeleteClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {" Are you sure you want to delete the Equipment user(s)?"}
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleDeleteClose} variant="contained">
                  Cancel
                </Button>
                <Button
                  onClick={deleteEquipmentUser}
                  autoFocus
                  variant="contained"
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          ) : (
            <Dialog
              open={openDelete}
              onClose={handleDeleteClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"You haven't selected any item!"}
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleDeleteClose} variant="contained">
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </div>
        <EnhancedTableToolbar
          numSelected={selected.length}
          totalRecord={totalRecord}
          setDataToFilter={setDataToFilter}
          handleDeleteOpen={handleDeleteOpen}
          handleFirstPage={handleFirstPage}
          tableCalRedux={tableCalRedux}
          defalutTableCalRedux={defalutTableCalRedux}
          rowsPerPage={rowsPerPage}
          order={order}
          orderBy={orderBy}
          dataToFilter={dataToFilter}
          getEquipmentUserList={getEquipmentUserList}
        />
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={equipmentUser.length}
              tableCalRedux={tableCalRedux}
            />
            {loading ? (
              <TableCell colSpan={13} align="center">
                <CircularProgress />
              </TableCell>
            ) : (
              <TableBody>
                {stableSort(equipmentUser, getComparator(order, orderBy)).map(
                  (row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${row.id}`;

                    return (
                      <TableRow hover key={row.id} selected={isItemSelected}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            onClick={(event) => handleClick(event, row.id)}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          align="left"
                          className={
                            tableCalRedux.barcode_id === true ? "" : "hide-cell"
                          }
                        >
                          {row.employee_id}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={
                            tableCalRedux.fname === true ? "" : "hide-cell"
                          }
                        >
                          {row.fname}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.last_name === true ? "" : "hide-cell"
                          }
                        >
                          {row.lname}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.department === true ? "" : "hide-cell"
                          }
                        >
                          {row.department}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.position === true ? "" : "hide-cell"
                          }
                        >
                          {row.position}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.sl === true ? "" : "hide-cell"
                          }
                        >
                          {row.security_level}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.updated === true ? "" : "hide-cell"
                          }
                        >
                          {row.createdAt}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.last_seen === true ? "" : "hide-cell"
                          }
                        >
                          {row.LastUpdatedOn}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={
                            tableCalRedux.active === true ? "" : "hide-cell"
                          }
                        >
                          <FormGroup>
                            <Toggle value={row.active} id={row.id} />
                          </FormGroup>
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.out === true ? "" : "hide-cell"
                          }
                        >
                          {row.equipment_checkout_count}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={
                            tableCalRedux.pcount === true ? "" : "hide-cell"
                          }
                        >
                          {row.p_count > 0 ? row.p_count + "*" : row.p_count}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={
                            tableCalRedux.action === true ? "" : "hide-cell"
                          }
                        >
                          <IconButton
                            aria-label="Settings"
                            size="large"
                            aria-owns={anchorEl ? "icon-menu" : undefined}
                            aria-haspopup="true"
                            onClick={(e) => handleActionClick(e, row.id)}
                            style={{ color: "#1374C5" }}
                          >
                            <SettingsIcon />
                          </IconButton>
                          <StyledMenu
                            id="icon-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleActionClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <MenuItem
                              aria-label="details"
                              component={NavLink}
                              to={`/equipment/edit-equipment-user/${id}`}
                              size="large"
                            >
                              <ListItemIcon>
                                <CreateIcon />
                              </ListItemIcon>
                              <ListItemText primary="Edit" pl={1} />
                            </MenuItem>
                            <MenuItem onClick={handleDeleteOpen}>
                              <ListItemIcon>
                                <DeleteIcon />
                              </ListItemIcon>
                              <ListItemText primary="Delete" pl={1} />
                            </MenuItem>
                            <MenuItem onClick={clickLogModalOpen}>
                              <ListItemIcon>
                                <SettingsIcon />
                              </ListItemIcon>
                              <ListItemText primary="User Log" pl={1} />
                            </MenuItem>
                          </StyledMenu>
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
                {equipmentUser.length === 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 6 : 0) * equipmentUser.length,
                    }}
                  >
                    <TableCell colSpan={11} align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          }}
        >
          <Pagination
            count={Math.ceil(totalRecord / rowsPerPage)}
            page={currentPage}
            boundaryCount={2}
            onChange={handleChangePagination}
            color="primary"
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ marginRight: "8px" }}>
                Rows per page:
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                onChange={handleChangeLimit}
              >
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </Box>
          </FormControl>
        </Box>
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      {/* Equipment log popup */}
      {open && (
        <EquipmentUserLog
          logModalClose={logModalClose}
          open={open}
          setOpen={setOpen}
          id={id}
        />
      )}
    </div>
  );
}

function EquipmentUserList() {
  const [snackopen, setSnackopen] = useState(true);
  const SnackState = useSelector((state) => state.equipment.snackStatus);
  const messageStatus = useSelector((state) => state.equipment.msg);
  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackopen(false);
  };
  return (
    <React.Fragment>
      {SnackState && (
        <Snackbar
          open={snackopen}
          autoHideDuration={3000}
          onClose={handleSnackClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Alert
            onClose={handleSnackClose}
            severity="success"
            variant="filled"
            sx={{ maxWidth: 600 }}
          >
            {messageStatus}
          </Alert>
        </Snackbar>
      )}
      <Helmet title="Equipment User List" />
      <Typography variant="h3" gutterBottom display="inline">
        Equipment User List
      </Typography>

      {/* <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
          Tables
        </Link>
        <Typography>Users</Typography>
      </Breadcrumbs>

      <Divider my={6} /> */}

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default EquipmentUserList;
