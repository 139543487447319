import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  Button,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  FormControlLabel,
  Checkbox,
  Card,
  TextField,
  Grid,
  formLabelClasses,
} from "@mui/material";
import {
  Cancel as CancelIcon,
  AddCircle as AddCircleIcon,
} from "@mui/icons-material";

const RecipientsDialog = ({
  recipientsDilaog,
  SetrecipientsDilaog,
  recipientsDilaogOpen,
  recipientsDilaogClose,
}) => {
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <>
      {" "}
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "350px",
            },
          },
        }}
        open={recipientsDilaogOpen}
        onClose={recipientsDilaogClose}
        aria-labelledby="viewmap-title"
        aria-describedby="viewmap-description"
      >
        <DialogTitle
          id="viewmap-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h6" ml={4}>
            Select recipients for notification
          </Typography>

          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => recipientsDilaogClose(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Grid
            conatiner
            spacing={6}
            sx={{
              display: "flex",
            }}
          >
            <Grid
              item
              xs={6}
              sx={{
                textAlign: "center",
              }}
            >
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="Select all"
              />
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                textAlign: "center",
              }}
            >
              <Typography variant="body2" gutterBottom mt={2}>
                (0)Seleted
              </Typography>
            </Grid>
          </Grid>
          <Card
            variant="outlined"
            sx={{
              width: "300px",
              height: "350px",
            }}
          >
            <FormControlLabel
              sx={{
                marginLeft: 7,
                marginTop: 3,
              }}
              control={<Checkbox defaultChecked />}
              label="ayush11ramola@gmail.com"
            />
            <FormControlLabel
              sx={{
                marginLeft: 7,
              }}
              control={<Checkbox defaultChecked />}
              label="Security test"
            />
            <IconButton>
              <AddCircleIcon fontSize="small" />
            </IconButton>
            <FormControlLabel
              sx={{
                marginLeft: 7,
              }}
              control={<Checkbox defaultChecked />}
              label="housekeeping1"
            />
            <IconButton>
              <AddCircleIcon fontSize="small" />
            </IconButton>
          </Card>
        </DialogContent>
        <DialogActions
          sx={{
            padding: "15px 15px",
            gap: 20,
            marginRight: 10,
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={recipientsDilaogClose}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={recipientsDilaogClose}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RecipientsDialog;
