import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
  DialogActions,
  Typography,
  Button,
  InputLabel as MuiInputLabel,
  Select,
  FormControl as MuiFormControl,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  MenuItem,
  TableBody,
  Card,
  Paper,
} from "@mui/material";
import { IncidentIccStatus, IncidentIccStatusList } from "../../../api";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../../redux/slices/mainSlice";
import { useDispatch } from "react-redux";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const ChangeIcc = ({ changeIcc, changeIccClose, singleData }) => {
  // Select Standard
  const [standard, setStandard] = React.useState("Standard");
  const selectStandard = (event) => {
    setStandard(event.target.value);
  };
  const dispatch = useDispatch();
  const handleUpdate = () => {
    let payload = {
      incidentId: singleData.IncidentID,
      iccStatus: standard,
    };
    IncidentIccStatus(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(getMsg("Record updated successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
          iccList();
        } else {
        }
      })
      .catch((err) => {
        dispatch(getMsg("Something went wrong!"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };

  const [statusList, setStatusList] = useState([]);
  const iccList = () => {
    IncidentIccStatusList(singleData.IncidentID)
      .then((res) => {
        if (res.statusCode === 200) {
          setStatusList(res.data);
        } else {
          setStatusList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    iccList();
  }, []);

  return (
    <>
      <Dialog
        aria-labelledby="changeIcc-title"
        aria-describedby="changeIcc-description"
        open={changeIcc}
        onClose={changeIccClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "550px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="changeIcc-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Change ICC
          </Typography>
          <DialogActions>
            <IconButton onClick={changeIccClose} sx={{ marginRight: "-10px" }}>
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select a new Incident Confidential Classification (ICC) using the
            dropdown below.
          </DialogContentText>
          <Paper sx={{ marginTop: "15px" }}>
            <Typography>
              <strong>Standard-</strong> No restrictions on visibility, sharing
              or notifications.
            </Typography>
            <Typography>
              <strong>Elevated-</strong> IR's do not appear in shift report
              unless fetched prior to ICC change. Can be emailed by admins only.
            </Typography>
            <Typography>
              <strong>Sensitive-</strong> Highest level. IR's do not appear in
              shift reports. Only visible to admins in the IR list. Cannot be
              emailed. No notifications sent when saved, even if marked
              critical. PDF's can be downloaded by admins only.
            </Typography>
          </Paper>
          <FormControl mt={6}>
            <InputLabel
              shrink
              id="standard"
              sx={{
                paddingLeft: "6px",
                paddingRight: "6px",
                marginLeft: "-4px",
              }}
            >
              Standard
            </InputLabel>
            <Select
              labelId="department"
              id="standard"
              value={standard}
              label="Standard"
              onChange={selectStandard}
              fullWidth
              displayEmpty
            >
              <MenuItem value="Standard">Standard</MenuItem>
              <MenuItem value="Elevated">Elevated</MenuItem>
              <MenuItem value="Sensitive">Sensitive</MenuItem>
            </Select>
          </FormControl>
          <DialogActions sx={{ paddingRight: 0 }}>
            <Button color="primary" variant="outlined" onClick={changeIccClose}>
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              sx={{ marginLeft: "10px" }}
              onClick={handleUpdate}
            >
              Update
            </Button>
          </DialogActions>
          <Typography variant="h5" component="div" gutterBottom>
            ICC Stauts Timeline
          </Typography>
          <Card mt={6} variant="outlined" sx={{ borderBottom: 0 }}>
            <TableContainer className="tableContainer">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Username</TableCell>
                    <TableCell>Datetime</TableCell>
                    <TableCell>Status Prior</TableCell>
                    <TableCell>Status After</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {statusList.map((it) => {
                    return (
                      <TableRow key={it.id}>
                        <TableCell>{it.username}</TableCell>
                        <TableCell>{it.created}</TableCell>
                        <TableCell>{it.status_prior}</TableCell>
                        <TableCell>{it.status}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ChangeIcc;
