import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {
  Checkbox,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  /* FormGroup, */
  TableContainer,
  InputAdornment,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  /* Tooltip, */
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  Radio,
  CardContent,
  FormLabel,
  RadioGroup,
  Card,
  Box,
  Button,
  /* List,
  ListItem, */
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  Chip,
  List,
  ListItem,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Dialog,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { DatePicker } from "@mui/lab";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  AddBox as AddBoxIcon,
  Error as ErrorIcon,
  Visibility as VisibilityIcon,
  Settings as SettingsIcon,
  Search as SearchIcon,
  ExpandMore as ExpandMoreIcon,
  Map as MapIcon,
  Cancel as CancelIcon,
  PictureAsPdf as PictureAsPdfIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import PerformDialog1 from "./inner-components/PerformDialog1";
import TourActivityDialog from "./inner-components/TourActivityDialog";
import TourMissedDialog from "./inner-components/TourMissedDialog";
import TourCompleteDialog from "./inner-components/TourCompleteDialog";
import { UserPerformanceList, UserPerformanceColumns } from "../../api";
import CustomDateRange from "../../components/form-components/CustomDateRange";
import { useDispatch, useSelector } from "react-redux";
import {
  userPerformanceList,
  getPerformanceChecked,
} from "../../redux/slices/reportSlice";
import moment from "moment";
import { subDays } from "date-fns";
import autoTable from "jspdf-autotable";
import jsPDF from "jspdf";
import UserPermisionChip from "./inner-components/UserPermisionChip";

/* const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing); */

// Classes Styling
const useStyles = makeStyles((theme) => ({
  dateRangeDropdown: {
    position: "absolute",
    left: 0,
    top: "100%",
    width: "100%",
    zIndex: 2,
    marginLeft: "0 !important",
    maxHeight: "200px",
    overflowY: "auto",

    "& .MuiBox-root": {
      padding: "15px",
      "& .MuiButton-contained": {
        marginLeft: "15px",
      },
    },
    "& .MuiList-root": {
      padding: 0,
      "& .MuiListItem-root:hover": {
        backgroundColor: "#376fd012",
      },
    },
  },
  DateList: {
    border: "1px solid #0000001c",
    marginTop: "22px",
    padding: 0,
    "& .MuiListItem-root": {
      borderBottom: "1px solid #0000001c",
    },
    "& .MuiListItem-root:last-child": {
      borderBottom: 0,
    },
    "& .MuiListItem-root:hover": {
      backgroundColor: "#376fd012",
    },
  },
  ErrorImage: {
    width: "250px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "15px",
    marginBottom: "15px",
  },
}));

const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: max-content;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
// Image Wrapper
const ImageWrapper = styled.div`
  margin-top: 10px;
`;
// const Image = styled.img`
//   width: 100%;
// `;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledForm = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    marginLeft: "75px",
    marginTop: "8px",
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      minWidth: "435px",
      "& form": {
        padding: "15px 20px",
      },
      "& .MuiMenuItem-root": {
        padding: "0",
        display: "block",
        "& .MuiFormControl-root": {
          display: "block",
          width: "100%",
          marginBottom: "20px",
        },
      },
      "& .MuiMenuItem-root:hover": {
        background: "transparent",
      },
      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "140px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
function createData(
  user,
  tourhours,
  ntthours,
  nttcount,
  tours,
  btnscanned,
  buttonmissed,
  hit
  //action
) {
  return {
    user,
    tourhours,
    ntthours,
    nttcount,
    tours,
    btnscanned,
    buttonmissed,
    hit,
    //action,
  };
}
const rows = [
  createData("Darin Redadmin", "1.28", "1857.22", "75", 0, "98", "42", "70"),
  createData("Sanjee Nawaz", "1.45", "1857.65", "75", 0, "98", "42", "70"),
  createData("Ib test 1", "1.28", "1857.22", "75", 0, "98", "42", "70"),
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  {
    id: "user_name",
    numeric: true,
    disablePadding: false,
    label: "User",
    tooltipMsg: "User who logged hours during date range",
  },
  {
    id: "tourhours",
    numeric: true,
    disablePadding: false,
    label: "Tour Hours",
    tooltipMsg: "Hours spent conducting tours",
  },
  {
    id: "ntthours",
    numeric: true,
    disablePadding: false,
    label: "NTT Hours",
    tooltipMsg: "Hours conducting Non-Tour Tasks (NTTs)",
  },
  {
    id: "nttcount",
    numeric: true,
    disablePadding: false,
    label: "NTT Count",
    tooltipMsg: "Count of Non-Tour Tasks completed",
  },
  {
    id: "tours",
    numeric: true,
    disablePadding: false,
    label: "Tours",
    tooltipMsg: "Tour completed/tour started",
  },
  {
    id: "btnscanned",
    numeric: true,
    disablePadding: false,
    label: "Buttons Scanned",
    tooltipMsg: "Count of buttons scanned during date range",
  },
  {
    id: "buttonmissed",
    numeric: true,
    disablePadding: false,
    label: "Button Missed",
    tooltipMsg: "Count of buttons missed during date range",
  },
  {
    id: "hit",
    numeric: true,
    disablePadding: false,
    label: "Hit %",
    tooltipMsg: "Percentage of buttons scanned vs. missed",
  },
  // { id: "action", numeric: true, disablePadding: false, label: "Action" },
];
const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    colArray,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const [headColumn, setHeadColumn] = useState([]); //My header...
  const checkedValue = useSelector((state) => state.report.performanceChecked);

  //  Function for preselected options in site.
  const headResult = () => {
    let result = [];
    checkedValue.forEach((obj) => {
      colArray.forEach((result3) => {
        if (result3.key == obj) {
          result.push(result3);
        }
      });
    });
    result.sort((a, b) => {
      return a.sort_id - b.sort_id;
    });
    setHeadColumn(result);
  };

  useEffect(() => {
    headResult();
  }, [checkedValue, colArray]);
  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all data" }}
          />
        </TableCell> */}
        {headColumn.map((headCell) => (
          <TableCell
            key={headCell.sort_id}
            align="left" //{headCell.numeric ? "left" : "left"}
            sx={{ whiteSpace: "nowrap" }}
            //padding={headCell.id === "user_name" ? "none" : "normal"}
            // sortDirection={orderBy === headCell.tname ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.key}
              direction={orderBy === headCell.key ? order : "asc"}
              onClick={createSortHandler(headCell.key)}
            >
              <Tooltip title={headCell.tooltipMsg}>
                <div>{headCell.value}</div>
              </Tooltip>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const {
    numSelected,
    setDataToFilter,
    totalRecord,
    filterRecord,
    startDateToFilter,
    setStartDateToFilter,
    endDateToFilter,
    setEndDateToFilter,
    handleFirstPage,
    generatePdf,
    colArray,
    getColumnList,
    getColumnChecked,
  } = props;
  const [actions, setActions] = React.useState("");
  const [fadebox, setFadedBox] = React.useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const checkedValue = useSelector((state) => state.report.performanceChecked);

  const dispatch = useDispatch();
  const handleChange = (event) => {
    setActions(event.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // Search Dropdown
  const [anchorForm, setAnchorForm] = React.useState(null);
  const handleClickDropdown = (event) => {
    setAnchorForm(event.currentTarget);
  };

  const handleCloseForm = () => {
    setAnchorForm(null);
  };
  const [value, setValue] = React.useState(null);
  // Select Result
  const [result, setResult] = React.useState("");
  const selectResult = (event) => {
    setResult(event.target.value);
  };

  // Columns Dropdown
  const [anchorColumn, setAnchorColumn] = React.useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };
  //Scan Visitor ID modal
  const [open, visitorModal] = React.useState(false);
  // const scanVisitorModalOpen = () => {
  //   visitorModal(true);
  // };
  // const scanVisitorModalClose = () => {
  //   visitorModal(false);
  // };
  const classes = useStyles();

  //Method for search filter
  const applySearch = () => {
    const payload = {
      start_date: startDateToFilter
        ? moment(startDateToFilter).format("YYYY-MM-DD")
        : "",
      end_date: endDateToFilter
        ? moment(endDateToFilter).format("YYYY-MM-DD")
        : "",
    };
    filterRecord(payload);
  };

  //function for reseting the filter
  const resetFilter = () => {
    setStartDateToFilter(subDays(new Date(), 7));
    setEndDateToFilter(new Date());
    filterRecord({});
  };

  //Handler for checked unchecked
  const handleChecked = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      dispatch(getPerformanceChecked([...checkedValue, value]));
    } else {
      dispatch(getPerformanceChecked(checkedValue.filter((e) => e !== value)));
    }
  };
  return (
    <Toolbar>
      {/* <div>
        <Paper mt={3}>
          <FormControl mr={2} sx={{ minWidth: 120 }}>
            <InputLabel id="demo-simple-select-label">Actions</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={actions}
              label="Actions"
              MenuProps={{
                PaperProps: {
                  sx: {
                    "& .MuiMenu-list": {
                      boxShadow:
                        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 10%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
                      margin: "0px 3px 5px 3px",
                      padding: "0",
                    },
                  },
                },
              }}
              onChange={handleChange}
            >
              <MenuItem value={"Delete"}>Delete</MenuItem>
            </Select>
          </FormControl>
        </Paper>
      </div> */}
      <div>
        {totalRecord > 0 ? (
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content" }}
          >
            {totalRecord} records found
          </Typography>
        ) : (
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content" }}
          >
            {totalRecord} record found
          </Typography>
        )}
      </div>
      <Spacer />
      <div className="searchTool">
        <Box
          mt={3}
          sx={{
            display: "flex",
            flexWrap: "nowrap",
          }}
        >
          <Item sx={{ flexGrow: 1, width: "100%" }}>
            <FormControl>
              <TextField
                fullWidth
                id="dateRange"
                // label="Date range"
                value={
                  startDateToFilter && endDateToFilter
                    ? moment(startDateToFilter).format("MM/DD/YYYY") +
                      " - " +
                      moment(endDateToFilter).format("MM/DD/YYYY")
                    : ""
                }
                onClick={() => setFadedBox((prev) => !prev)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    applySearch();
                    setIsFilter(true);
                    handleFirstPage();
                  }
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Select date range"
              />
              {fadebox && (
                <CustomDateRange
                  handleShow={fadebox}
                  conformation={(status) => setFadedBox((prev) => !prev)}
                  setDates={(start, end) => {
                    setStartDateToFilter(start);
                    setEndDateToFilter(end);
                    setIsFilter(false);
                  }}
                  selectedStart={startDateToFilter}
                  selectedEnd={endDateToFilter}
                />
              )}
            </FormControl>
          </Item>
          <Item>
            {isFilter ? (
              <Button
                color="primary"
                variant="contained"
                onClick={(e) => {
                  resetFilter();
                  setDataToFilter(null);
                  setIsFilter(false);
                }}
                sx={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  height: "100%",
                  marginLeft: "-3px",
                }}
              >
                Clear
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                sx={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  height: "100%",
                  marginLeft: "-3px",
                }}
                onClick={(e) => {
                  applySearch({});
                  setIsFilter(true);
                  handleFirstPage();
                }}
              >
                Search
              </Button>
            )}
          </Item>
        </Box>
      </div>
      <Spacer />
      <div>
        <Paper mt={3}>
          <IconButton
            aria-owns={anchorEl ? "icon-menu" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            variant="contained"
            color="secondary"
          >
            <FilterListIcon />
          </IconButton>
          <StyledFilterMenu
            id="icon-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem onClick={generatePdf}>
              <ListItemIcon>
                <PictureAsPdfIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="Download PDF" pl={0} />
            </MenuItem>
            <MenuItem
              aria-owns={anchorColumn ? "columns-dropdown" : undefined}
              aria-haspopup="true"
              onClick={handleClickColumn}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Columns" pl={0} />
            </MenuItem>
          </StyledFilterMenu>
          <StyledColumnDropdown
            id="columns-dropdown"
            anchorEl={anchorColumn}
            open={Boolean(anchorColumn)}
            onClose={handleCloseColumn}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem>
              <Card>
                <CardContent>
                  <Typography variant="h6" component="h2">
                    {checkedValue.length} out of {colArray.length} visible
                  </Typography>
                  <Box>
                    <FormGroup>
                      {colArray.map((col) => {
                        return (
                          <FormControlLabel
                            key={col.sort_id}
                            control={
                              <Checkbox
                                disableRipple
                                checked={checkedValue.includes(col.key)}
                                onChange={(e) => handleChecked(e)}
                                value={col.key}
                              />
                            }
                            label={col.value}
                          />
                        );
                      })}
                    </FormGroup>
                  </Box>
                  <Toolbar>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        getColumnList();
                        getColumnChecked();
                      }}
                    >
                      Reset
                    </Button>
                    <Spacer />
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleCloseColumn}
                    >
                      Cancel
                    </Button>
                  </Toolbar>
                </CardContent>
              </Card>
            </MenuItem>
          </StyledColumnDropdown>
        </Paper>
      </div>
    </Toolbar>
  );
};
function EnhancedTable(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [selected, setSelected] = React.useState([]);
  // const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecord, setTotalRecord] = useState(0);
  const [loading, setLoading] = React.useState(false); //State for loader
  const [startDateToFilter, setStartDateToFilter] = useState(
    subDays(new Date(), 7)
  );
  const [endDateToFilter, setEndDateToFilter] = useState(new Date());
  const [dataToFilter, setDataToFilter] = useState(null);
  const dispatch = useDispatch();
  const performanceList = useSelector(
    (state) => state.report.userPerformanceData
  );
  const [userIdActivity, setUserIdActivity] = useState("");
  const [userNameActivity, setUserNameActivity] = useState("");
  const [thValue, setThValue] = useState("");
  const [nttValue, setNttValue] = useState("");
  const [missedValue, setMissedValue] = useState("");
  const LoggedInUserInfo = useSelector((state) => state.login.user);
  const [colArray, setColArray] = useState([]); //State for list of colmn list
  const checkedValue = useSelector((state) => state.report.performanceChecked);
  const selectedLocation = useSelector((state) => state.login.Location);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = performanceList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    // setPage(newPage);
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // const emptyRows =
  //   rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleActionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();
  // Missed Buttons Modal
  const [missedbutton, setMissedButtons] = React.useState(false);
  const missedButtonOpen = (userId, userName, missed) => {
    setUserIdActivity(userId);
    setUserNameActivity(userName);
    setMissedValue(missed);
    setMissedButtons(true);
  };
  const missedButtonClose = () => {
    setMissedButtons(false);
  };
  // Task Completed Modal
  const [taskcompleted, setTaskCompleted] = React.useState(false);
  const taskCompletedOpen = (userId, userName, nttCount) => {
    setUserIdActivity(userId);
    setUserNameActivity(userName);
    setNttValue(nttCount);
    setTaskCompleted(true);
  };
  const taskCompletedClose = () => {
    setTaskCompleted(false);
  };
  // User Activity Modal
  const [useractivity, setUserActivity] = React.useState(false);
  const userActivityOpen = (userId, userName) => {
    setUserIdActivity(userId);
    setUserNameActivity(userName);
    setUserActivity(true);
  };
  const userActivityClose = () => {
    setUserActivity(false);
  };
  // Tour Activity Modal
  const [touractivity, setTourActivity] = React.useState(false);
  const tourActivityOpen = (userId, userName, thour) => {
    setUserIdActivity(userId);
    setUserNameActivity(userName);
    setThValue(thour);
    setTourActivity(true);
  };
  const tourActivityClose = () => {
    setTourActivity(false);
  };
  const handleFirstPage = () => {
    setCurrentPage(0);
    setSelected([]);
  };
  const filterRecord = (data) => {
    setDataToFilter(data);
  };

  //Method for generating PDF
  const generatePdf = () => {
    const doc = new jsPDF();
    const newRange =
      moment(startDateToFilter).format("MM/DD/YYYY") +
      " to " +
      moment(endDateToFilter).format("MM/DD/YYYY");
    const newDatePDF = moment(new Date()).format("MM/DD/YYYY");
    const newTimePDF = moment(new Date()).format("HH:mm:ss");
    var img = new Image();
    img.src = "/static/img/brands/logo.png";
    doc.addImage(img, "png", 10, 10, 45, 20);

    doc.setFontSize(11.7);
    doc.setFont("helvetica", "bold");
    doc.text(100, 45, "User Performance Report", "center");

    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text(100, 55, `${selectedLocation.LocationName}`, "center");

    // doc.text(100, 20, 'Hello World', 'center');

    doc.setFontSize(8);
    doc.setFont("helvetica", "bold");
    doc.text("Created By : ", 15, 65);
    // doc.text(`Tour Name :        ${reportData.tour_name}`, 15, 50);

    doc.setFontSize(8);
    doc.setFont("helvetica", "normal");
    doc.text(LoggedInUserInfo.alias_name, 33, 65);

    doc.setFontSize(8);
    doc.setFont("helvetica", "bold");
    doc.text("Created Date : ", 15, 70);
    // doc.text(`Tour By :             ${reportData.user_name}`, 15, 55);

    doc.setFontSize(8);
    doc.setFont("helvetica", "normal");
    doc.text(newDatePDF, 36, 70);

    doc.setFontSize(8);
    doc.setFont("helvetica", "bold");
    doc.text("Created Time : ", 15, 75);
    // doc.text(`Tour Status :        ${reportData.report_status}`, 15, 60);

    doc.setFontSize(8);
    doc.setFont("helvetica", "normal");
    doc.text(newTimePDF, 36, 75);

    doc.setFontSize(8);
    doc.setFont("helvetica", "bold");
    doc.text("Report date : ", 15, 80);
    // doc.text(`Tour Start :          ${reportData.start_time}`, 15, 65);

    doc.setFontSize(8);
    doc.setFont("helvetica", "normal");
    doc.text(newRange, 34, 80);

    const expPdfData = performanceList.map((item) => {
      return [
        item.user_name,
        item.tour_hours,
        item.ntt_hours,
        item.ntt_count,
        item.tours,
        item.button_scanned,
        item.button_missed,
        item.hit,
      ];
    });
    autoTable(doc, {
      // styles: { marginTop: "400px" },
      margin: { top: 90 },
      head: [
        [
          "Users",
          "Tour Hours",
          "NTT Hours",
          "NTT Count",
          "Tours",
          "Buttons Scanned",
          "Buttons Missed",
          "Hit %",
        ],
      ],
      body: expPdfData,
      showFoot: "everyPage",
      // showHead: "firstPage",
      // Footer
      didDrawPage: function (data) {
        // Footer
        var str = "Page " + doc.internal.getNumberOfPages();

        doc.setFontSize(10);
        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        doc.text(str, 110, pageHeight - 3);
        doc.setLineWidth(0.1);
        doc.line(10, pageHeight - 8, 200, pageHeight - 8, "F");
      },
    });
    let fileName =
      "SOVA-USER-PERFORMANCE-" +
      moment(new Date()).format("YYYY-MM-DD") +
      ".pdf"; // TODOS: location name will be added later
    doc.save(fileName);
  };

  const getUserPerformanceList = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
      sorting: {
        order: order,
        by: orderBy,
      },
      start_date: startDateToFilter
        ? moment(startDateToFilter).format("YYYY-MM-DD")
        : "",
      end_date: endDateToFilter
        ? moment(endDateToFilter).format("YYYY-MM-DD")
        : "",
      ...dataToFilter,
    }; //this will be used for sending filtered data
    console.log("im payload: ", payload);
    UserPerformanceList(currentPage + 1, payload)
      .then((res) => {
        if (res.statusCode == 200) {
          dispatch(userPerformanceList(res.data));
          setTotalRecord(res.data.length);
        } else {
          dispatch(userPerformanceList(res.data));
          setTotalRecord(0);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setTotalRecord(0);
        dispatch(userPerformanceList([]));
      });
  };

  //API call for showing list of column checkboxes Items
  const getColumnList = () => {
    UserPerformanceColumns()
      .then((res) => {
        setColArray(res.data.all_fields);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getColumnChecked = () => {
    UserPerformanceColumns()
      .then((res) => {
        dispatch(getPerformanceChecked(res.data.default_fields));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getUserPerformanceList();
    getColumnList();

    return () => {
      dispatch(userPerformanceList([]));
    };
  }, [rowsPerPage, currentPage, order, orderBy, dataToFilter]);

  //sideeffect for dispatching actions to redux store
  useEffect(() => {
    if (checkedValue.length == 0) {
      getColumnChecked();
    } else {
      console.log("Redux store array is empty");
    }
  }, []);

  return (
    <div>
      <Paper mt={3}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          totalRecord={totalRecord}
          dataToFilter={dataToFilter}
          setDataToFilter={setDataToFilter}
          filterRecord={(data) => filterRecord(data)}
          startDateToFilter={startDateToFilter}
          setStartDateToFilter={setStartDateToFilter}
          endDateToFilter={endDateToFilter}
          setEndDateToFilter={setEndDateToFilter}
          handleFirstPage={handleFirstPage}
          generatePdf={generatePdf}
          colArray={colArray}
          getColumnList={getColumnList}
          getColumnChecked={getColumnChecked}
        />
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    <CircularProgress />
                  </TableCell>
                  {/* collapse null column to resolve console errors */}
                  {/* <TableCell colSpan={8} /> */}
                </TableRow>
              </TableBody>
            ) : (
              <>
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={performanceList.length}
                  colArray={colArray}
                />
                <TableBody>
                  {stableSort(performanceList, getComparator(order, orderBy))
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                              onClick={(event) => handleClick(event, row.id)}
                              role="checkbox"
                            />
                          </TableCell> */}
                          {checkedValue.includes("user_name") && (
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              //padding="none"
                              // sx={{ whiteSpace: "nowrap" }}
                              sx={{
                                cursor: "pointer",
                                whiteSpace: "nowrap",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                                color: "#0077B5",
                              }}
                              onClick={() => {
                                userActivityOpen(row.user_id, row.user_name);
                              }}
                            >
                              {row.user_name}
                            </TableCell>
                          )}
                          {checkedValue.includes("tour_hours") && (
                            <TableCell
                              align="left"
                              // sx={{ whiteSpace: "nowrap" }}
                              sx={{
                                cursor: row.tour_hours !== 0 ? "pointer" : null,
                                whiteSpace: "nowrap",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                                color: row.tour_hours !== 0 ? "#0077B5" : null,
                              }}
                              onClick={() => {
                                tourActivityOpen(
                                  row.user_id,
                                  row.user_name,
                                  row.tour_hours
                                );
                              }}
                            >
                              {row.tour_hours}
                            </TableCell>
                          )}
                          {checkedValue.includes("ntt_hours") && (
                            <TableCell
                              align="left"
                              sx={{
                                whiteSpace: "nowrap",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                              }}
                            >
                              {row.ntt_hours}
                            </TableCell>
                          )}
                          {checkedValue.includes("ntt_count") && (
                            <TableCell
                              align="left"
                              // sx={{ whiteSpace: "nowrap" }}
                              sx={{
                                cursor: row.ntt_count !== 0 ? "pointer" : null,
                                whiteSpace: "nowrap",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                                color: row.ntt_count !== 0 ? "#0077B5" : null,
                              }}
                              onClick={() => {
                                taskCompletedOpen(
                                  row.user_id,
                                  row.user_name,
                                  row.ntt_count
                                );
                              }}
                            >
                              {row.ntt_count}
                            </TableCell>
                          )}
                          {checkedValue.includes("tours") && (
                            <TableCell
                              align="left"
                              sx={{
                                whiteSpace: "nowrap",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                              }}
                            >
                              <Chip
                                label={row.tours}
                                sx={{
                                  cursor: "pointer",
                                  backgroundColor:
                                    row.class == "greenclass"
                                      ? "#2E7823"
                                      : row.class == "yellowclass"
                                      ? "#CCC820"
                                      : "#DA3E16",
                                  color: "#000",
                                }}
                              />
                              {/* <UserPermisionChip
                                scanned={row.complete_hour}
                                total={row.tour_count}
                                result={row.tours}
                              /> */}
                            </TableCell>
                          )}
                          {checkedValue.includes("button_scanned") && (
                            <TableCell
                              align="left"
                              sx={{
                                whiteSpace: "nowrap",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                              }}
                            >
                              {row.button_scanned}
                            </TableCell>
                          )}
                          {checkedValue.includes("button_missed") && (
                            <TableCell
                              align="left"
                              // sx={{ whiteSpace: "nowrap" }}
                              sx={{
                                cursor:
                                  row.button_missed !== 0 ? "pointer" : null,
                                whiteSpace: "nowrap",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                                color:
                                  row.button_missed !== 0 ? "#0077B5" : null,
                              }}
                              onClick={() => {
                                missedButtonOpen(
                                  row.user_id,
                                  row.user_name,
                                  row.button_missed
                                );
                              }}
                            >
                              {row.button_missed}
                            </TableCell>
                          )}
                          {checkedValue.includes("hit") && (
                            <TableCell
                              align="left"
                              sx={{
                                whiteSpace: "nowrap",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                              }}
                            >
                              {row.hit}
                            </TableCell>
                          )}
                          {checkedValue.includes("complete_hour") && (
                            <TableCell
                              align="left"
                              sx={{
                                whiteSpace: "nowrap",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                              }}
                            >
                              {row.complete_hour}
                            </TableCell>
                          )}
                          {checkedValue.includes("tour_count") && (
                            <TableCell
                              align="left"
                              sx={{
                                whiteSpace: "nowrap",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                              }}
                            >
                              {row.tour_count}
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                  {totalRecord === 0 && (
                    <TableRow style={{ height: (dense ? 6 : 0) * totalRecord }}>
                      <TableCell colSpan={9} align="center">
                        No records found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={totalRecord}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      {/* User Activity Modal for username column */}
      {useractivity && (
        <PerformDialog1
          useractivity={useractivity}
          setUserActivity={setUserActivity}
          userActivityClose={userActivityClose}
          userIdActivity={userIdActivity}
          userNameActivity={userNameActivity}
          startDateToFilterA={startDateToFilter}
          endDateToFilterA={endDateToFilter}
        />
      )}

      {/* Tour Activity Modal on Tour hour column */}
      {touractivity && thValue !== 0 && (
        <TourActivityDialog
          touractivity={touractivity}
          setTourActivity={setTourActivity}
          tourActivityClose={tourActivityClose}
          userIdActivity={userIdActivity}
          userNameActivity={userNameActivity}
          startDateToFilterA={startDateToFilter}
          endDateToFilterA={endDateToFilter}
        />
      )}

      {/* Missed Buttons Modal on Button missed column */}
      {missedbutton && missedValue !== 0 && (
        <TourMissedDialog
          missedbutton={missedbutton}
          setMissedButtons={setMissedButtons}
          missedButtonClose={missedButtonClose}
          userIdActivity={userIdActivity}
          userNameActivity={userNameActivity}
          startDateToFilterA={startDateToFilter}
          endDateToFilterA={endDateToFilter}
        />
      )}

      {/* Task Completed Modal on NTT count columns*/}
      {taskcompleted && nttValue !== 0 && (
        <TourCompleteDialog
          taskcompleted={taskcompleted}
          setTaskCompleted={setTaskCompleted}
          taskCompletedClose={taskCompletedClose}
          userIdActivity={userIdActivity}
          userNameActivity={userNameActivity}
          startDateToFilterA={startDateToFilter}
          endDateToFilterA={endDateToFilter}
        />
      )}
    </div>
  );
}

function Performance() {
  return (
    <React.Fragment>
      <Helmet title="User Performance" />
      <Typography variant="h3" gutterBottom display="inline">
        User Performance
      </Typography>

      {/* <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
          Tables
        </Link>
        <Typography>Users</Typography>
      </Breadcrumbs>

      <Divider my={6} /> */}

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Performance;
