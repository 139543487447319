import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Formik } from "formik";
import CustomTextField from "../../../components/form-components/CustomTextField";
import { GetAddKeyDoorList, DeleteKeyDoor, AddNewKeyDoor } from "../../../api";
import { handleApiResonseErrors } from "../../../helper/helper";
import * as Yup from "yup";
import EditKeyDoor from "./EditKeyDoor";
import { useDispatch } from "react-redux";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../../redux/slices/equipmentSlice";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Menu,
  Typography,
  FormControl as MuiFormControl,
  Button,
  IconButton,
  Paper,
  Grid,
  Table,
  TableContainer,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import {
  CancelRounded as CancelRoundedIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const AddKeyDoor = ({ addKeyDoor, addKeyDoorClose, childToParent }) => {
  // Action Dropdown
  const [addKeyDoorAction, setAddKeyDoorAction] = React.useState(null);
  const [keyDoorList, setKeyDoorList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [doorId, setDoorId] = useState(null);
  const [close, setClose] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [singleData, setSingleData] = useState({});
  const dispatch = useDispatch();

  const addKeyDoorActionClick = (event, id, data) => {
    setAddKeyDoorAction(event.currentTarget);
    setDoorId(id);
    setSingleData(data);
  };
  const addKeyDoorActionClose = () => {
    setAddKeyDoorAction(null);
  };

  /* The above code is creating a state variable called editKeyCabinet and setting it to false. It is
  also creating a function called editKeyCabinetOpen that sets the state variable to true. */
  const [editKeyDoor, setEditKeyDoor] = useState(false);
  const editKeyDoorOpen = () => {
    setEditKeyDoor(true);
  };

  /**
   * This function closes the edit key Door modal
   */
  const editKeyDoorClose = () => {
    setEditKeyDoor(false);
  };

  //method for open the delete dialog
  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  /**
   * It closes the delete dialog and resets the anchor element and id
   */
  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  /**
   * It closes the dialog box.
   * @param e - The event object
   */
  const closeDialogHandle = (e) => {
    setClose(e.target.value);
  };

  /**
   * This function is used to get the list of key cabinets from the database
   */
  const addKeyDoorList = () => {
    setLoading(true);
    GetAddKeyDoorList()
      .then((res) => {
        if (res.statusCode === 200) {
          setKeyDoorList(res.data);
          childToParent(res.data, "key_door");
          setLoading(false);
        } else {
          setKeyDoorList([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  /**
   * This function is used to delete a key cabinet
   */
  const deleteKeyDoor = () => {
    handleDeleteClose();
    DeleteKeyDoor(doorId)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(getSnackOpen());
          dispatch(getMsg("Record has been deleted successfully"));
          dispatch(getSnackAlert("success"));
          addKeyDoorList();
        } else {
          dispatch(getSnackOpen());
          dispatch(getMsg("Something went wrong"));
          dispatch(getSnackAlert("error"));
        }
      })
      .catch((err) => {
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        if (error) {
          setErrMsg(error);
        }
        dispatch(getSnackClose());
      });
  };

  /* The above code is using the useEffect hook to call the addKeyCabinetList function when the component
mounts. */
  useEffect(() => {
    addKeyDoorList();
  }, []);

  /* Creating a validation schema for the form. */
  const validationSchema = Yup.object().shape({
    door_name: Yup.string().required("Please enter door name"),
    door_building: Yup.string().required("Please enter door building"),
    door_floor: Yup.string().required("Please enter door floor"),
    door_room: Yup.string().required("Please enter door room"),
    door_room_number: Yup.number()
      .typeError("Door room number must be a number")
      .required("Please enter door room number"),
    door_notes: Yup.string().required("Please enter door notes"),
    door_cylinder_pos: Yup.string().required("Please enter door cylinder pos"),
    door_cylinder: Yup.string().required("Please enter door cylinder"),
    door_lock_number: Yup.number()
      .typeError("Door lock number must be a number")
      .required("Please enter door lock number"),
    door_length: Yup.number()
      .typeError("Door length must be a number")
      .required("Please enter door length"),
  });

  /* Setting the initial values for the form. */
  let initialValues = {
    door_name: "",
    door_building: "",
    door_floor: "",
    door_room: "",
    door_room_number: "",
    door_notes: "",
    door_cylinder_pos: "",
    door_cylinder: "",
    door_lock_number: "",
    door_length: "",
  };

  /**
   * A function that is called when the form is submitted.
   * @param values - An object containing all values of the form.
   */
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      let formData = {
        name: values.door_name,
        building: values.door_building,
        floor: values.door_floor,
        room: values.door_room,
        room_number: values.door_room_number,
        notes: values.door_notes,
        cylinder_pos: values.door_cylinder_pos,
        cylinder: values.door_cylinder,
        lock_number: values.door_lock_number,
        length: values.door_length,
      };
      AddNewKeyDoor(formData)
        .then((res) => {
          if (res.statusCode === 200) {
            dispatch(getSnackOpen());
            dispatch(getMsg("Key door has been submitted successfully"));
            dispatch(getSnackAlert("success"));
            if (close === "close") {
              addKeyDoorClose();
            }
            addKeyDoorList();
          } else {
            dispatch(getSnackClose());
          }
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            setErrMsg(error);
          }
          dispatch(getSnackClose());
        });
      resetForm();
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  return (
    <>
      <Dialog
        open={addKeyDoor}
        onClocse={addKeyDoorClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "700px",
            },
          },
        }}
        aria-labelledby="add-key-door-title"
        aria-describedby="add-key-door-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="add-key-door-title"
        >
          <Typography variant="h4">Add Key door</Typography>
          <DialogActions>
            <IconButton sx={{ marginRight: "-10px" }} onClick={addKeyDoorClose}>
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              values,
              status,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <CustomTextField
                        name="door_name"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key door name*"
                        autoComplete="off"
                        placeholder=""
                      />
                      <FormHelperText sx={{ color: "#d32f2f" }}>
                        {errMsg}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <CustomTextField
                        name="door_building"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key door building*"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="door_floor"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key door floor*"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="door_room"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key door room*"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CustomTextField
                      name="door_room_number"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Key door room number*"
                      autoComplete="off"
                      placeholder=""
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="door_notes"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key door notes*"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="door_cylinder_pos"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key door cylinder pos*"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="door_cylinder"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key door cylinder*"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="door_lock_number"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key door lock number*"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="door_length"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key door length*"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button color="primary" variant="outlined" type="submit">
                      Save & add more
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      sx={{ marginLeft: "10px" }}
                      type="submit"
                      value={"close"}
                      onClick={closeDialogHandle}
                    >
                      Save & done
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
          <Paper sx={{ marginTop: "30px" }}>
            <Dialog
              open={openDelete}
              onClose={handleDeleteClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Are you sure you want to delete key door?"}
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleDeleteClose} variant="contained">
                  Cancel
                </Button>
                <Button onClick={deleteKeyDoor} autoFocus variant="contained">
                  OK
                </Button>
              </DialogActions>
            </Dialog>
            <Typography variant="h4" component="div" gutterBottom>
              Existing Key Door
            </Typography>
            <TableContainer className="tableContainer">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow hover>
                    <TableCell>Name</TableCell>
                    <TableCell>Building</TableCell>
                    <TableCell>Floor</TableCell>
                    <TableCell>Room Number</TableCell>
                    <TableCell>Notes</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                {loading ? (
                  <TableCell colSpan={13} align="center">
                    <CircularProgress />
                  </TableCell>
                ) : (
                  <TableBody>
                    {keyDoorList.map((data) => {
                      return (
                        <TableRow>
                          <TableCell>{data.name}</TableCell>
                          <TableCell>{data.building}</TableCell>
                          <TableCell>{data.floor}</TableCell>
                          <TableCell>{data.room_number}</TableCell>
                          <TableCell>{data.notes}</TableCell>
                          <TableCell padding="none">
                            <IconButton
                              aria-label="Settings"
                              size="large"
                              aria-owns={
                                addKeyDoorAction
                                  ? "add-key-door-action"
                                  : undefined
                              }
                              aria-haspopup="true"
                              onClick={(e) =>
                                addKeyDoorActionClick(e, data.id, data)
                              }
                              style={{ color: "#1374C5" }}
                            >
                              <SettingsIcon />
                            </IconButton>
                            <StyledMenu
                              id="add-key-door-action"
                              anchorEl={addKeyDoorAction}
                              open={Boolean(addKeyDoorAction)}
                              onClose={addKeyDoorActionClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <MenuItem
                                onClick={() => {
                                  editKeyDoorOpen();
                                  addKeyDoorActionClose();
                                }}
                              >
                                <ListItemIcon>
                                  <EditIcon />
                                </ListItemIcon>
                                <ListItemText primary="Edit" pl={1} />
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  handleDeleteOpen();
                                  addKeyDoorActionClose();
                                }}
                              >
                                <ListItemIcon>
                                  <DeleteIcon />
                                </ListItemIcon>
                                <ListItemText primary="Delete" pl={1} />
                              </MenuItem>
                            </StyledMenu>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {keyDoorList.length === 0 && (
                      <TableRow>
                        <TableCell align="center" colSpan={10}>
                          No records found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={addKeyDoorClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {editKeyDoor && (
        <EditKeyDoor
          editKeyDoor={editKeyDoor}
          editKeyDoorClose={editKeyDoorClose}
          setEditKeyDoor={setEditKeyDoor}
          editKeyDoorOpen={editKeyDoorOpen}
          singleData={singleData}
          addKeyDoorList={addKeyDoorList}
          doorId={doorId}
        />
      )}
    </>
  );
};

export default AddKeyDoor;
