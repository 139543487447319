import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { makeStyles } from "@mui/styles";
import styled from "styled-components/macro";
import CustomSelect from "../../form-components/CustomSelect";
import CustomTextField from "../../form-components/CustomTextField";
import { handleApiResonseErrors } from "../../../helper/helper";
import { SingleRecord, SendShipmentRequest } from "../../../api";
import * as Yup from "yup";

import {
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Button,
  FormControl as MuiFormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
  Paper,
  Grid,
  FormLabel,
  FormHelperText,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Cancel as CancelIcon } from "@mui/icons-material";
const ShipmentRequest = ({
  showModal,
  handleModal,
  selectedRecordId,
  showAlertTost,
  reloadList,
  handleActionsClose,
  resetSelected,
}) => {
  const [recordData, setRecordData] = useState({});
  const [loading, setLoading] = useState(true); //State for loader
  const [afterSubmitLoading, setAfterSubmitLoading] = useState(false); //State for loader
  const [disabledFields, setDisabledFields] = useState(true);

  const FormControlSpacing = styled(MuiFormControl)(spacing);
  const FormControl = styled(FormControlSpacing)`
    width: 100%;
  `;
  const useStyles = makeStyles((theme) => ({
    alphabets: {
      marginTop: "15px",
      "& .MuiList-root": {
        display: "flex",
        overflowX: "auto",
        background: "#7aa8ff0d",
        padding: "0px",
        "& .MuiListItemButton-root": {
          paddingLeft: "13px",
          paddingRight: "13px",
          textAlign: "center",
        },
      },
      "& .MuiList-root::-webkit-scrollbar": {
        width: "5px",
        height: "5px",
      },
      "& .MuiList-root::-webkit-scrollbar-track": {
        backgroundColor: "#bed2f561",
      },
      "& .MuiList-root::-webkit-scrollbar-thumb": {
        backgroundColor: "#92a6c96e",
      },
    },
    shipmentRequest: {
      "& .MuiFormControl-root": {
        minWidth: "auto",
        width: "100%",
      },
    },
    filterDataByTabs: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "15px",
      "& .MuiToggleButtonGroup-root": {
        justifyContent: "space-between",
        width: "100%",
        overflowX: "auto",
        "& .MuiButtonBase-root": {
          borderRadius: "0",
          width: "100%",
          whiteSpace: "nowrap",
        },
      },
      "& .MuiList-root::-webkit-scrollbar": {
        width: "5px",
        height: "5px",
      },
      "& .MuiList-root::-webkit-scrollbar-track": {
        backgroundColor: "#bed2f561",
      },
      "& .MuiList-root::-webkit-scrollbar-thumb": {
        backgroundColor: "#92a6c96e",
      },
    },
    btnDropdown: {
      maxWidth: "65px",
      width: "100%",
    },
    statusButton: {
      width: "20px",
      height: "20px",
      display: "block",
      lineHeight: "20px",
      textAlign: "center",
      borderRadius: "6px",
      color: "#fff",
      fontSize: "12px",
      fontWeight: "bolder",
    },
    cardHeader: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    filterByRadioControls: {
      position: "absolute",
      top: "100%",
      right: "0px",
      boxShadow: "0px 3px 5px 3px #00000014 !important",
      minWidth: "320px",
      zIndex: "1",
      padding: "15px",
    },
    itemInSingleRow: {
      display: "flex",
      gap: "15px",
      alignItems: "center",
    },
    createMergedGroup: {
      border: "1px solid",
      borderColor:
        theme.palette.mode === "light" ? "#c4c4c4" : theme.palette.grey[300],
      padding: 0,
      marginTop: "15px",
    },
    CustomActionsButton: {
      borderColor:
        theme.palette.mode === "light"
          ? "rgb(223 223 223)"
          : theme.palette.grey[300],
      color:
        theme.palette.mode === "light" ? "#7f8797" : theme.palette.grey[300],
      padding: "13px 20px",
    },
    fixedLoader: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "#00000059",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: "99999",
    },
  }));

  const StyledRadio = styled((props) => <RadioGroup {...props} />)(
    ({ theme }) => ({
      "& .MuiFormGroup-root": {
        flexDirection: "row",
        flexWrap: "nowrap",
      },
      marginTop: "5px",
    })
  );
  const classes = useStyles();

  const lengthUnit = [
    {
      value: "in",
      key: "inch",
    },
    {
      value: "cm",
      key: "cm",
    },
  ];

  const massUnit = [
    {
      value: "g",
      key: "g",
    },
    {
      value: "kg",
      key: "kg",
    },
    {
      value: "lb",
      key: "lbs",
    },
    {
      value: "oz",
      key: "oz",
    },
  ];

  useEffect(() => {
    GetSingleRecord();
  }, []);

  const GetSingleRecord = () => {
    SingleRecord(selectedRecordId)
      .then((res) => {
        if (res.statusCode === 200) {
          setRecordData(res.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validationSchema = Yup.object().shape({
    lost_first_name: Yup.string().required("This field is required."),
    lost_last_name: Yup.string().required("This field is required."),
    lost_email: Yup.string()
      .nullable()
      .when(["send_shipping_noti"], (notificationType, schema) => {
        return notificationType === "1"
          ? schema
          : schema.required(
              "Email is required please edit the record to add email"
            );
      }),
    send_shipping_noti: Yup.string().required("This field is required."),
    length_unit: Yup.string().required("This field is required."),
    mass_unit: Yup.string()
      .nullable()
      .when(["item_weight"], (itemWeight, schema) => {
        return itemWeight ? schema.required("This field is required") : schema;
      }),
    item_h: Yup.number()
      .positive("Please enter a valid number.")
      .typeError("Please enter a valid number.")
      .required("This field is required."),
    lost_phone: Yup.number()
      .nullable()
      .when(["send_shipping_noti"], (notificationType, schema) => {
        return notificationType === "0"
          ? schema
          : schema.required(
              "Phone is required please edit the record to add phone"
            );
      }),
    item_l: Yup.number()
      .nullable()
      .positive("Please enter a valid number.")
      .typeError("Please enter a valid number.")
      .required("This field is required."),
    item_w: Yup.number()
      .nullable()
      .positive("Please enter a valid number.")
      .typeError("Please enter a valid number.")
      .required("This field is required."),
    item_weight: Yup.number()
      .nullable()
      .positive("Please enter a valid number.")
      .typeError("Please enter a valid number.")
      .required("This field is required."),
  });

  let initialValues = {
    lost_first_name: recordData
      ? recordData.lost_first_name
        ? recordData.lost_first_name
        : ""
      : "",
    lost_last_name: recordData
      ? recordData.lost_last_name
        ? recordData.lost_last_name
        : ""
      : "",
    lost_email: recordData
      ? recordData.lost_email
        ? recordData.lost_email
        : ""
      : "",
    lost_phone: recordData
      ? recordData.lost_phone
        ? recordData.lost_phone
        : ""
      : "",
    send_shipping_noti: "0",
    item_l: recordData ? (recordData.item_l ? recordData.item_l : "") : "",
    item_w: recordData ? (recordData.item_w ? recordData.item_w : "") : "",
    item_h: recordData ? (recordData.item_h ? recordData.item_h : "") : "",
    length_unit: recordData
      ? recordData.length_unit
        ? recordData.length_unit
        : ""
      : "",
    item_weight: recordData
      ? recordData.item_weight
        ? recordData.item_weight
        : ""
      : "",
    mass_unit: recordData
      ? recordData.mass_unit
        ? recordData.mass_unit
        : ""
      : "",
  };

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      let payload = {
        id: selectedRecordId,
        send_shipping_noti: values.send_shipping_noti,
        item_l: values.item_l,
        item_w: values.item_w,
        item_h: values.item_h,
        length_unit: values.length_unit,
        item_weight: values.item_weight,
        mass_unit: values.mass_unit,
      };
      setAfterSubmitLoading(true);
      SendShipmentRequest(payload)
        .then((res) => {
          if (res.statusCode === 200) {
            handleModal(false);
            showAlertTost(res.message, "success");
            reloadList();
            resetSelected();
            if (handleActionsClose) {
              handleActionsClose();
            }
          } else {
            showAlertTost(res.message, "error");
          }
          setAfterSubmitLoading(false);
        })
        .catch((err) => {
          setAfterSubmitLoading(false);
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            showAlertTost(error, "error");
          }
        });
    } catch (error) {}
  };

  return (
    <Dialog
      open={showModal}
      aria-labelledby="subitems-title"
      aria-describedby="subitems-description"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            maxWidth: "600px",
          },
        },
      }}
    >
      <DialogTitle
        id="subitems-dialog-title"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: "0px",
        }}
      >
        <Typography variant="h4" component="div">
          Send Shipment Request
        </Typography>
        <DialogActions sx={{ padding: "0px" }}>
          <IconButton
            sx={{ marginRight: "-10px" }}
            onClick={() => handleModal(false)}
          >
            <CancelIcon />
          </IconButton>
        </DialogActions>
      </DialogTitle>

      {loading ? (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      ) : (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please complete the information below to send an email or SMS to the
            person listed with details on how they can purchase shipping to have
            the lost item retured to them. All fields are required. You must
            edit the record first if need to change name, email or phone below.
            It is your responsibility to verify ownership of the item.
          </DialogContentText>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              setFieldValue,
              values,
              status,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3} mt={4}>
                  <Grid item xs={12} lg={6}>
                    <FormControl mt={6}>
                      <CustomTextField
                        disabled={disabledFields}
                        name="lost_first_name"
                        label="First Name *"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormControl mt={6}>
                      <CustomTextField
                        disabled={disabledFields}
                        name="lost_last_name"
                        label="Last Name *"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormControl mt={3}>
                      <CustomTextField
                        disabled={disabledFields}
                        name="lost_email"
                        label="Email *"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormControl mt={3}>
                      <CustomTextField
                        disabled={disabledFields}
                        name="lost_phone"
                        label="Phone"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormControl mt={3}>
                      <FormLabel component="legend">
                        Send notification by
                      </FormLabel>
                      <StyledRadio>
                        <RadioGroup
                          aria-labelledby="send_shipping_noti"
                          name="send_shipping_noti"
                          value={values.send_shipping_noti}
                          onChange={(e) =>
                            setFieldValue(
                              "send_shipping_noti",
                              e.currentTarget.value
                            )
                          }
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Email"
                            value="0"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="SMS"
                            value="1"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="Both"
                            value="2"
                          />
                        </RadioGroup>
                      </StyledRadio>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12} lg={12}>
                        <FormControl>
                          <FormLabel>Box dimensions</FormLabel>
                          <FormHelperText>
                            Set height to one for envelopes
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} md={6} lg={3}>
                        <FormControl mt={3}>
                          <CustomTextField
                            name="item_l"
                            label="Length *"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            autoComplete="off"
                            placeholder=""
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} md={6} lg={3}>
                        <FormControl mt={3}>
                          <CustomTextField
                            name="item_w"
                            label="Width *"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            autoComplete="off"
                            placeholder=""
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={6} md={6} lg={3}>
                        <FormControl mt={3}>
                          <CustomTextField
                            name="item_h"
                            label="Height *"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            autoComplete="off"
                            placeholder=""
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} md={6} lg={3}>
                        <FormControl mt={3}>
                          <CustomSelect
                            name="length_unit"
                            fieldLabel="Size unit *"
                            options={lengthUnit}
                            displayEmpty
                          />
                          <FormHelperText>{errors.length_unit}</FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={12} lg={12}>
                        <FormControl>
                          <FormLabel>
                            Approx.shipping weight (including packaging)
                          </FormLabel>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} md={6} lg={6}>
                        <FormControl mt={3}>
                          <CustomTextField
                            name="item_weight"
                            label="Weight *"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            autoComplete="off"
                            placeholder=""
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} md={6} lg={6}>
                        <FormControl mt={3}>
                          <CustomSelect
                            name="mass_unit"
                            fieldLabel="Mass unit *"
                            options={massUnit}
                            displayEmpty
                          />
                          <FormHelperText>{errors.mass_unit}</FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => handleModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{ marginLeft: "10px" }}
                    type="submit"
                  >
                    Save Changes
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      )}
      {afterSubmitLoading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </Dialog>
  );
};

export default ShipmentRequest;
