import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import moment, { weekdays } from "moment";
import format from "date-fns/format";
import {
  NavLink,
  useParams,
  useNavigate,
  createSearchParams,
} from "react-router-dom";
import { Helmet } from "react-helmet-async";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import Toaster from "./Toaster";
import { makeStyles } from "@mui/styles";
import { UpdateScheduleTask } from "../../api";
import {
  Avatar,
  Button as MuiButton,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Link,
  Typography,
  Tooltip,
  FormLabel,
  RadioGroup,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Radio,
  Box,
  Fade,
  TextareaAutosize as MuiTextareaAutosize,
  FormHelperText,
  Slider,
  Input as MuiInput,
  Paper,
  Switch,
  TableContainer,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Autocomplete,
  Tabs,
  Tab,
  Menu,
} from "@mui/material";
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Error as ErrorIcon,
  Edit as EditIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  ChangeHistory as ChangeHistoryIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  Add as AddIcon,
  Settings as SettingsIcon,
  Create as CreateIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { event } from "jquery";
const Button = styled(MuiButton)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const LavelTable = styled((props) => <TableContainer {...props} />)(
  ({ theme }) => ({
    "& .MuiBox-root": {
      display: "flex",
      alignItems: "center",
      "& .MuiFormLabel-root": {
        whiteSpace: "nowrap",
        paddingRight: "10px",
        minWidth: "60px",
      },
    },
    "& .MuiTableCell-root": {
      padding: "5px 10px 5px 0px",
      border: "0px",
    },
  })
);
const TextAreaControl = styled((props) => <FormControl {...props} />)(
  ({ theme }) => ({
    "& textarea:focus-visible": {
      outlineColor: "#2f63be",
      border: "0px",
    },
  })
);
const TextareaAutosize = styled(MuiTextareaAutosize)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 120px !important;
  background: transparent;
  padding: 20px 12px 15px;
  font-size: 13px;
  opacity: 0.8;
  font-weight: 200;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  letter-spacing: normal;
`;
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
const StyledBox = styled((props) => <Paper {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    "& .MuiBox-root": {
      padding: "0px",
    },
    "& .Mui-selected": {
      backgroundColor: "#7aa8ff0d",
    },
    "& .MuiList-root::-webkit-scrollbar": {
      /* display: "none", */
    },
  },
}));
// Classes Styling
const useStyles = makeStyles((theme) => ({
  textAreaControl: {
    position: "relative",
    "& .MuiFormLabel-root": {
      position: "absolute",
      top: "-24px",
      padding: "0 5px",
      zIndex: "1",
      left: "-5px",
      fontSize: "10px",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
}));
//Auto Select
const customNotification = [
  { title: "Not selected" },
  { title: "Option one" },
  { title: "Option two" },
  { title: "Option three" },
  { title: "Option four" },
  { title: "Option five" },
];
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
const Input = styled(MuiInput)`
  width: 42px;
`;

const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;

const StyledInputSlider = styled((props) => <Paper {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      "& .MuiInput-root::before": {
        borderBottom: "0px",
      },
      "& .MuiInput-root": {
        "& .MuiInput-input": {
          paddingBottom: "0px",
        },
      },
    },
  })
);
const StyledContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
  "& .MuiBox-root": {
    backgroundColor: "#7aa8ff0d",
    padding: "15px",
    marginTop: "10px",
    maxHeight: "542px",
    overflowY: "auto",
    "& .MuiPaper-root": {
      backgroundColor: "transparent",
      borderBottom: "0px",
      marginBottom: "12px",
      "& .MuiTypography-subtitle2": {
        marginRight: "5px",
      },
      "& .MuiSvgIcon-root": {
        margin: "0px 6px",
        width: "12px",
        height: "12px",
        verticalAlign: "middle",
      },
    },
  },
}));
// Switch outside
const CustomSwitchControl = styled((props) => <FormGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormControlLabel-root": {
      position: "relative",
      marginLeft: "0px",
      marginRight: "0px",
      paddingRight: "60px",
      paddingTop: "8px",
      paddingBottom: "8px",
      "& .MuiBox-root": {
        position: "absolute",
        right: "0px",
        top: "0px",
        display: "flex",
        alignItems: "center",
        flexWrap: "nowrap",
      },
    },
  })
);
//Switch value inside
//For yes or No
const CustomSwitchYesNo = styled(Switch)(({ theme }) => ({
  padding: 8,
  width: 75,
  position: "absolute",
  right: "-8px",
  "& .MuiButtonBase-root:hover": {
    backgroundColor: "transparent",
  },
  "& .Mui-checked": {
    transform: "translateX(42px)",
    padding: "8.5px 0px",
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    backgroundColor: "#000000",

    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "53%",
      transform: "translateY(-53%)",
      width: 35,
      height: 16,
      fontSize: "10px",
      color: "#fff",
    },
    "&:before": {
      content: '"YES"',
      left: 15,
    },
    "&:after": {
      content: '"NO"',
      right: "-5px",
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 26,
    height: 26,
    margin: "-3.3px 0px 0 -1px",
    boxShadow: "0px 1px 3px 1px #0000001a",
  },
}));
//For on or off
const CustomSwitchOffOn = styled(Switch)(({ theme }) => ({
  padding: 8,
  width: 75,
  position: "absolute",
  right: "-8px",
  "& .MuiButtonBase-root:hover": {
    backgroundColor: "transparent",
  },
  "& .Mui-checked": {
    transform: "translateX(42px)",
    padding: "8.5px 0px",
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    backgroundColor: "#000000",

    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "53%",
      transform: "translateY(-53%)",
      width: 35,
      height: 16,
      fontSize: "10px",
      color: "#fff",
    },
    "&:before": {
      content: '"ON"',
      left: 15,
    },
    "&:after": {
      content: '"OFF"',
      right: "-2px",
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 26,
    height: 26,
    margin: "-3.3px 0px 0 -1px",
    boxShadow: "0px 1px 3px 1px #0000001a",
  },
}));
const StyledRadio = styled((props) => <RadioGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormGroup-root": {
      flexDirection: "row",
      flexWrap: "nowrap",
    },
  })
);

const Schedule = (props) => {
  const [dayNames, setDayNames] = useState([]);
  const [mytime, setMytime] = useState("XXXX");
  const {
    edittask,
    taskModalClose,
    setEditTask,
    handleRadioChange,
    dvalues,
    month,
    handleMonthChange,
    TimePicker,
    timevalue,
    setTimeValue,
    singleScheduleTask,
    scheduleList,
    modulearray,
    setmodulearray,
    // message,
    // setMessage,
  } = props;
  const {
    assign_id,
    task_id,
    schedule_time,
    schedule_type,
    schedule_type_value,
    message,
    viewScheduleTask,
    weekly_value,
  } = singleScheduleTask;
  console.log("My weekly value", message);
  let time = "" + schedule_time;
  let hour = time.slice(0, 2);
  let minute = time.slice(2, 4);
  let d = "2020-01-01" + " " + hour + ":" + minute;
  console.log(d);
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  let months = [];
  for (let index = 1; index <= 31; index++) {
    months.push(index);
  }
  console.log("time======>", schedule_time);
  const week = [
    {
      id: 1,
      name: "Sunday",
      data: "1",
    },
    {
      id: 2,
      name: "Monday",
      data: "2",
    },
    {
      id: 3,
      name: "Tuesday",
      data: "3",
    },
    {
      id: 4,
      name: "Wednesday",
      data: "4",
    },
    {
      id: 5,
      name: "Thursday",
      data: "5",
    },
    {
      id: 6,
      name: "Friday",
      data: "6",
    },
    {
      id: 7,
      name: "Saturday",
      data: "7",
    },
  ];

  const initialValues = {
    schedule_radio: schedule_type ? schedule_type : "",
    schedule_time: d ? d : "",
    month: schedule_type_value ? schedule_type_value : "1",
  };
  const validationSchema = Yup.object().shape({});
  // const [modulearray, setmodulearray] = useState([]);
  const [scheduleTime, setScheduleTime] = useState(null);

  const handleChecked = (e, name) => {
    const { value, checked } = e.target;
    if (checked) {
      setmodulearray([...modulearray, value]);
      setDayNames([...dayNames, name]);
    } else {
      setmodulearray(modulearray.filter((e) => e !== value));
      setDayNames(dayNames.filter((e) => e !== name));
    }
  };
  var resultDay = [];
  dayNames.forEach((obj) => {
    week.forEach((res) => {
      if (res.name == obj) {
        resultDay.push(res);
      }
    });
  });
  resultDay.sort((a, b) => {
    return a.data - b.data;
  });
  let date = new Date(scheduleTime);
  let hour1 = date.getHours();
  if (hour1 < 10) {
    hour1 = "0" + hour1;
  }

  let minute1 = date.getMinutes();
  if (minute1 < 10) {
    minute1 = "0" + minute1;
  }
  let time1 = "" + hour1 + minute1;
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      let payload = {
        schedule_type: values.schedule_radio,
        schedule_time: time1 ? time1 : d,
        weekly_day: modulearray,
        monthly_day: values.month,
      };
      UpdateScheduleTask(assign_id, payload)
        .then((response) => {
          if (response.statusCode === 200) {
            scheduleList();
            setOpenSnack(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      resetForm();
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  // const headResult = () => {
  //   let result = [];
  //   modulearray.forEach((obj) => {
  //     week.forEach((result3) => {
  //       if (result3.key == obj) {
  //         result.push(result3);
  //       }
  //     });
  //   });
  //   result.sort((a, b) => {
  //     return a.sort_id - b.sort_id;
  //   });
  //   console.log("=========", result);
  // };

  return (
    <>
      <Toaster
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
        message="Schedule Task has been successfully updated "
      />
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
            },
          },
        }}
        open={edittask}
        onClose={taskModalClose}
        aria-labelledby="equipment-log"
        aria-describedby="equipment-log"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h4" component="div">
            Edit Schedules
          </Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setEditTask(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent sx={{ padding: "0px" }}>
          <DialogContentText sx={{ padding: "10px 25px 25px" }}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={handleSubmit}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                setFieldValue,
                values,
                status,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Typography variant="p" component="div">
                    Select the schedule when this task should appear as a popup
                    to your users. You can choose a daily, weekly or monthly
                    task schedule, and multiple schedules are permitted. For
                    example, if you wish to add a schedule to unlock the sales
                    office Monday through Friday only, then you would set 5
                    daily schedules, one for each day of the week.
                    Alternatively, if you need to add or edit schedule for a
                    task assigned to a tour checkpoint, you can do that under
                    the Tours menu.
                  </Typography>
                  <FormControl mt={6}>
                    <FormLabel component="legend">Recurring:</FormLabel>
                    <StyledRadio>
                      <RadioGroup
                        aria-labelledby="recurring"
                        // name="recurring"
                        name="schedule_radio"
                        value={values.schedule_radio.toString()}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <FormControlLabel
                          value="Daily"
                          control={<Radio />}
                          label="Daily"
                        />
                        <FormControlLabel
                          value="Weekly"
                          control={<Radio />}
                          label="Weekly"
                        />
                        <FormControlLabel
                          value="Monthly"
                          control={<Radio />}
                          label="Monthly"
                        />
                      </RadioGroup>
                    </StyledRadio>
                  </FormControl>
                  <FormControl mt={3}></FormControl>
                  <FormControl
                    mt={3}
                    style={{
                      display:
                        values.schedule_radio === "Weekly" ? "block" : "none",
                    }}
                  >
                    <FormGroup>
                      {week.map((mod, index) => {
                        return (
                          <FormControlLabel
                            key={mod.id}
                            control={
                              <Checkbox
                                disableRipple
                                name={mod.name}
                                value={mod.data}
                                checked={modulearray.includes(mod.data)}
                                onChange={(e) => {
                                  handleChecked(e, mod.name);
                                }}
                              />
                            }
                            label={mod.name}
                          />
                        );
                      })}
                    </FormGroup>
                  </FormControl>
                  <FormControl
                    mt={3}
                    style={{
                      display:
                        values.schedule_radio === "Monthly" ? "block" : "none",
                    }}
                  >
                    <Select
                      labelId="month"
                      id="month"
                      name="month"
                      value={values.month}
                      onChange={handleChange}
                      displayEmpty
                    >
                      {months.map((day) => {
                        return <MenuItem value={day}>{day}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                  <FormControl mt={6}>
                    <TimePicker
                      label="Schedule time for task"
                      name="schedule_time"
                      value={scheduleTime ? scheduleTime : values.schedule_time}
                      onChange={(newValue) => {
                        setScheduleTime(newValue);
                        let dnd = moment(newValue).format("HHmm");
                        setMytime(dnd);
                      }}
                      onBlur={handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </FormControl>
                  <FormHelperText
                    style={{
                      display:
                        values.schedule_radio === "Daily" ? "block" : "none",
                    }}
                  >
                    Everyday at {mytime} hours
                  </FormHelperText>
                  <FormHelperText
                    style={{
                      display:
                        values.schedule_radio === "Weekly" ? "block" : "none",
                    }}
                  >
                    Every {resultDay.map((data) => data.name).join(",")} at{" "}
                    {mytime} hours
                  </FormHelperText>
                  <FormHelperText
                    style={{
                      display:
                        values.schedule_radio === "Monthly" ? "block" : "none",
                    }}
                  >
                    {`Every ${values.month}${
                      values.month === 1
                        ? "st"
                        : values.month === 2
                        ? "nd"
                        : values.month === 3
                        ? "rd"
                        : "th"
                    } day of month at ${mytime} hours`}
                  </FormHelperText>
                  <DialogActions
                    sx={{
                      paddingTop: "30px",
                      paddingBottom: "30px",
                      paddingRight: "20px",
                    }}
                  >
                    <Button
                      onClick={taskModalClose}
                      color="primary"
                      variant="contained"
                      type="submit"
                    >
                      Save
                    </Button>
                  </DialogActions>
                </form>
              )}
            </Formik>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Schedule;
