import React, { useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate, createSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import useAppSelector from "../../hooks/useAppSelector";
import useAppDispatch from "../../hooks/useAppDispatch";
import { AddTasks } from "../../api";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { TimePicker } from "@mui/lab";
import TourSchedule from "./TourSchedule";
import StructuredDialog from "../dashboards/Task/StructuredDialog";
import {
  getChecked,
  getSnackOpen,
  getSnackClose,
  getMsg,
} from "../../redux/slices/taskSlice";
import {
  Avatar,
  Button as MuiButton,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Select,
  Menu,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Link,
  Typography,
  Tooltip,
  FormLabel,
  RadioGroup,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Radio,
  Box,
  Fade,
  TextareaAutosize as MuiTextareaAutosize,
  FormHelperText,
  Slider,
  Input as MuiInput,
  Paper,
  Switch,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Tabs,
  Tab,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Autocomplete,
  CircularProgress,
  fabClasses,
} from "@mui/material";
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Error as ErrorIcon,
  Edit as EditIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  ChangeHistory as ChangeHistoryIcon,
  Add as AddIcon,
  Settings as SettingsIcon,
  Create as CreateIcon,
  Delete as DeleteIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { event } from "jquery";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "none",
  boxShadow:
    "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
  p: 4,
};

const FormControlSpacing = styled(MuiFormControl)(spacing);
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
//Auto Select
const customNotification = [
  { title: "Not selected" },
  { title: "Option one" },
  { title: "Option two" },
  { title: "Option three" },
  { title: "Option four" },
  { title: "Option five" },
];
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
const StyledBox = styled((props) => <Paper {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    "& .MuiBox-root": {
      padding: "0px",
    },
    "& .Mui-selected": {
      backgroundColor: "#7aa8ff0d",
    },
    "& .MuiList-root::-webkit-scrollbar": {
      /* display: "none", */
    },
  },
}));
const Input = styled(MuiInput)`
  width: 42px;
`;

const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
// Classes Styling
const useStyles = makeStyles((theme) => ({
  textAreaControl: {
    position: "relative",
    "& .MuiFormLabel-root": {
      position: "absolute",
      top: "-24px",
      padding: "0 5px",
      zIndex: "1",
      left: "-5px",
      fontSize: "10px",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));

const TextareaAutosize = styled(MuiTextareaAutosize)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 120px !important;
  background: transparent;
  padding: 20px 12px 15px;
  font-size: 13px;
  opacity: 0.8;
  font-weight: 200;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  letter-spacing: normal;
`;
const TextAreaControl = styled((props) => <FormControl {...props} />)(
  ({ theme }) => ({
    "& textarea:focus-visible": {
      outlineColor: "#2f63be",
      border: "0px",
    },
  })
);
const StyledInputSlider = styled((props) => <Paper {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      "& .MuiInput-root::before": {
        borderBottom: "0px",
      },
      "& .MuiInput-root": {
        "& .MuiInput-input": {
          paddingBottom: "0px",
        },
      },
    },
  })
);
const StyledContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
  "& .MuiBox-root": {
    backgroundColor: "#7aa8ff0d",
    padding: "15px",
    marginTop: "10px",
    maxHeight: "542px",
    overflowY: "auto",
    "& .MuiPaper-root": {
      backgroundColor: "transparent",
      borderBottom: "0px",
      marginBottom: "12px",
      "& .MuiTypography-subtitle2": {
        marginRight: "5px",
      },
      "& .MuiSvgIcon-root": {
        margin: "0px 6px",
        width: "12px",
        height: "12px",
        verticalAlign: "middle",
      },
    },
  },
}));
// Switch outside
const CustomSwitchControl = styled((props) => <FormGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormControlLabel-root": {
      position: "relative",
      marginLeft: "0px",
      marginRight: "0px",
      paddingRight: "60px",
      paddingTop: "8px",
      paddingBottom: "8px",
      "& .MuiBox-root": {
        position: "absolute",
        right: "0px",
        top: "0px",
        display: "flex",
        alignItems: "center",
        flexWrap: "nowrap",
      },
    },
  })
);
//Switch value inside
//For yes or No
const CustomSwitchYesNo = styled(Switch)(({ theme }) => ({
  padding: 8,
  width: 75,
  position: "absolute",
  right: "-8px",
  "& .MuiButtonBase-root:hover": {
    backgroundColor: "transparent",
  },
  "& .Mui-checked": {
    transform: "translateX(42px)",
    padding: "8.5px 0px",
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    backgroundColor: "#000000",

    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "53%",
      transform: "translateY(-53%)",
      width: 35,
      height: 16,
      fontSize: "10px",
      color: "#fff",
    },
    "&:before": {
      content: '"YES"',
      left: 15,
    },
    "&:after": {
      content: '"NO"',
      right: "-5px",
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 26,
    height: 26,
    margin: "-3.3px 0px 0 -1px",
    boxShadow: "0px 1px 3px 1px #0000001a",
  },
}));
//For on or off
const CustomSwitchOffOn = styled(Switch)(({ theme }) => ({
  padding: 8,
  width: 75,
  position: "absolute",
  right: "-8px",
  "& .MuiButtonBase-root:hover": {
    backgroundColor: "transparent",
  },
  "& .Mui-checked": {
    transform: "translateX(42px)",
    padding: "8.5px 0px",
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    backgroundColor: "#000000",

    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "53%",
      transform: "translateY(-53%)",
      width: 35,
      height: 16,
      fontSize: "10px",
      color: "#fff",
    },
    "&:before": {
      content: '"ON"',
      left: 15,
    },
    "&:after": {
      content: '"OFF"',
      right: "-2px",
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 26,
    height: 26,
    margin: "-3.3px 0px 0 -1px",
    boxShadow: "0px 1px 3px 1px #0000001a",
  },
}));
const StyledRadio = styled((props) => <RadioGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormGroup-root": {
      flexDirection: "row",
      flexWrap: "nowrap",
    },
  })
);
const LavelTable = styled((props) => <TableContainer {...props} />)(
  ({ theme }) => ({
    "& .MuiBox-root": {
      display: "flex",
      alignItems: "center",
      "& .MuiFormLabel-root": {
        whiteSpace: "nowrap",
        paddingRight: "10px",
        minWidth: "60px",
      },
    },
    "& .MuiTableCell-root": {
      padding: "5px 10px 5px 0px",
      border: "0px",
    },
  })
);

const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
function ColumnLeft() {
  const count = useAppSelector((state) => state.counter.loader);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  //redirect
  let navigate = useNavigate();
  //Activity Points
  const [activityvalue, setValue] = React.useState(0);
  const [taskType, setTaskType] = useState("No");
  const [hideLevel, setHideLevel] = useState(false);

  const [structuredCommentsOpen, setStructuredCommentsOpen] = useState(false);
  const [structuredLocationOpen, setStructuredLocationOpen] = useState(false);

  const handleStructuredCommentsOpen = () => setStructuredCommentsOpen(true);
  const handleStructuredCommentsClose = () => setStructuredCommentsOpen(false);

  const handleStructuredLocationOpen = () => setStructuredLocationOpen(true);
  const handleStructuredLocationClose = () => setStructuredLocationOpen(false);

  //Hide level 3 method
  const handleHideLevel = (event) => {
    setHideLevel(event.target.checked);
  };
  const handleActivityPoints = (event, newValue) => {
    setValue(newValue);
  };
  const handleInputChange = (event) => {
    setValue(event.target.value === "" ? "" : Number(event.target.value));
  };
  const handleBlur = () => {
    if (activityvalue < 0) {
      setValue(0);
    } else if (activityvalue > 100) {
      setValue(100);
    }
  };
  const handleChangeTask = (event) => {
    setTaskType(event.target.value);
  };
  //Sentiment Score
  const [sentimentscore, setscoreValue] = React.useState(0);
  const handleScores = (event, newValue) => {
    setscoreValue(newValue);
  };
  const handleScoreChange = (event) => {
    setscoreValue(event.target.value === "" ? "" : Number(event.target.value));
  };
  const handleScoreBlur = () => {
    if (sentimentscore < 0) {
      setscoreValue(0);
    } else if (sentimentscore > 100) {
      setscoreValue(100);
    }
  };
  const initialValues = {
    task_name: "",
    task_type: "TT",
    critical: "non-critical",
    cohm: 0,
    comment: 0,
    team: 0,
    send_shift_report: 0,
    web_notify: 0,
    ir_required: 0,
    collect_sentiemnt: "N",
    passdown_req: "N",
    unavailable_started: "N",
    task_comment: "",
    tag: "",
    task_sentiments: 1,
    level_one: "",
    level_two: "",
    level_one: "",
    level_field_one: "",
    level_field_two: "",
    level_field_three: "",
    structured_comment: "",
    structured_location: "",
  };
  const validationSchema = Yup.object().shape({
    task_name: Yup.string().required("Please enter task name"),
    level_field_one: Yup.string().email("Invalid email format"),
    level_field_two: Yup.string().email("Invalid email format"),
    level_field_three: Yup.string().email("Invalid email format"),
  });

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoading(true);
      const commentValues = values.comment_level
        ? values.comment_level.join(",")
        : "";

      let payload = {
        TaskDescription: values.task_name,
        assignable: taskType,
        critical_task: values.critical,
        contact_ohm: values.cohm,
        comment_required: values.comment,
        notify_email: values.team,
        send_shift_report: values.send_shift_report,
        web_notify: values.web_notify,
        ir_required: values.ir_required,
        sentiment_assign: values.collect_sentiemnt,
        task_comment: values.task_comment,
        tag: values.tag,
        activity_point: activityvalue ? activityvalue : 3,
        sentimentIcon: values.task_sentiments,
        sentimentScore: sentimentscore,
        lvl1_label: values.level_one,
        lvl2_label: values.level_two,
        lvl3_label: values.level_three,
        Notify_lvl1_email: values.level_field_one,
        Notify_lvl2_email: values.level_field_two,
        Notify_lvl3_email: values.level_field_three,
        passdown_req: values.passdown_req,
        unavailable_started: values.unavailable_started,
        Comment_required_lvl: commentValues,
        structured_comment: values.structured_comment,
        structured_location: values.structured_location,
      };
      AddTasks(payload)
        .then((res) => {
          if (res.statusCode === 200) {
            dispatch(getSnackOpen());
            dispatch(getMsg("Task has been submitted successfully"));
            navigate({
              pathname: "/tasks/task-list",
            });
            setLoading(fabClasses);
          } else {
            dispatch(getSnackClose());
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(getSnackClose());
          setLoading(false);
        });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  const [value, setPanel] = React.useState(0);
  const handlePanel = (event, newTab) => {
    setPanel(newTab);
  };
  //
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleActionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };
  //Edit task modal
  const [edittask, setTask] = React.useState(false);
  const taskModalOpen = () => {
    setTask(true);
  };
  const taskModalClose = () => {
    setTask(false);
  };
  //Edit task button modal
  const [taskbutton, setTaskButton] = React.useState(false);
  const taskButtonOpen = () => {
    setTaskButton(true);
  };
  const taskButtonClose = () => {
    setTaskButton(false);
  };
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  // Add Schedule
  const [addschedule, addScheduleModal] = useState(false);
  const [levelType, setLevelType] = useState("");

  const addScheduleOpen = () => {
    addScheduleModal(true);
  };
  const addScheduleClose = () => {
    addScheduleModal(false);
  };
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        setFieldValue,
        values,
        status,
      }) => (
        <Card mb={6}>
          {loading && (
            <Paper className={classes.fixedLoader}>
              <CircularProgress />
            </Paper>
          )}
          <form onSubmit={handleSubmit}>
            <CardContent>
              <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                  <FormControl mt={3}>
                    <TextField
                      id="task-name"
                      name="task_name"
                      label="Task name*"
                      value={values.task_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.task_name && errors.task_name)}
                      helperText={touched.task_name && errors.task_name}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Short concise name, no special characters"
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <FormLabel component="legend">Task type</FormLabel>
                    <StyledRadio>
                      <RadioGroup
                        aria-labelledby="task-type"
                        // defaultValue={values.task_type}
                        name="task_type"
                        value={taskType}
                        onChange={handleChangeTask}
                        // onBlur={handleBlur}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Tour task"
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="Non-tour task"
                        />
                      </RadioGroup>
                    </StyledRadio>
                  </FormControl>
                  <FormControl
                    mt={3}
                    style={{ display: taskType === "Yes" ? "block" : "none" }}
                  >
                    <FormLabel component="legend">Set custom levels</FormLabel>
                    <Grid container spacing={3} sx={{ marginTop: "0" }}>
                      <Grid item xs={12} lg={4} xl={3}>
                        <TextField
                          id="level-one"
                          name="level_one"
                          label="Level 1"
                          value={values.level_one}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.level_one && errors.level_one)}
                          helperText={touched.level_one && errors.level_one}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          placeholder="Yes"
                        />
                      </Grid>
                      <Grid item xs={12} lg={4} xl={3}>
                        <TextField
                          id="level-two"
                          name="level_two"
                          label="Level 2"
                          value={values.level_two}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.level_two && errors.level_two)}
                          helperText={touched.level_two && errors.level_two}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          placeholder="No"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        lg={4}
                        xl={3}
                        style={{
                          visibility: hideLevel === true ? "hidden" : "inherit",
                        }}
                      >
                        <TextField
                          id="level-three"
                          name="level_three"
                          label="Level 3"
                          value={values.level_three}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(
                            touched.level_three && errors.level_three
                          )}
                          helperText={touched.level_three && errors.level_three}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          placeholder="N/A"
                        />
                      </Grid>
                      <Grid item xs={12} lg={12} xl={3}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="hide_level"
                              checked={hideLevel}
                              onChange={handleHideLevel}
                              value={hideLevel === true ? 0 : 1}
                            />
                          }
                          label="Hide level 3"
                        />
                      </Grid>
                      <Grid item xs={12} lg={12} xl={12}>
                        <LavelTable>
                          <TableContainer className="tableContainer">
                            <Table stickyHeader aria-label="sticky table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>
                                    Send email notifications for levels
                                  </TableCell>
                                  <TableCell>Comments</TableCell>
                                  <TableCell>Schedule</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell>
                                    <Box>
                                      <Item>
                                        <FormLabel>Level 1</FormLabel>
                                      </Item>
                                      <Item>
                                        <TextField
                                          id="lavel-field"
                                          name="level_field_one"
                                          value={values.level_field_one}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          error={Boolean(
                                            touched.level_field_one &&
                                              errors.level_field_one
                                          )}
                                          helperText={
                                            touched.level_field_one &&
                                            errors.level_field_one
                                          }
                                          placeholder="me@you.com"
                                        />
                                      </Item>
                                    </Box>
                                  </TableCell>
                                  <TableCell>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="comment_level"
                                          value="1"
                                          onChange={handleChange}
                                        />
                                      }
                                      label=""
                                    />
                                  </TableCell>
                                  <TableCell>
                                    {isValidEmail(values.level_field_one) && (
                                      <IconButton
                                        onClick={() => {
                                          addScheduleOpen();
                                          setLevelType("level1");
                                        }}
                                      >
                                        <AccessTimeIcon />
                                      </IconButton>
                                    )}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <Box>
                                      <Item>
                                        <FormLabel>Level 2</FormLabel>
                                      </Item>
                                      <Item>
                                        <TextField
                                          id="lavel-field"
                                          name="level_field_two"
                                          value={values.level_field_two}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          error={Boolean(
                                            touched.level_field_two &&
                                              errors.level_field_two
                                          )}
                                          helperText={
                                            touched.level_field_two &&
                                            errors.level_field_two
                                          }
                                          placeholder="me@you.com"
                                        />
                                      </Item>
                                    </Box>
                                  </TableCell>
                                  <TableCell>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="comment_level"
                                          value="2"
                                          onChange={handleChange}
                                        />
                                      }
                                      label=""
                                    />
                                  </TableCell>
                                  <TableCell>
                                    {isValidEmail(values.level_field_two) && (
                                      <IconButton
                                        onClick={() => {
                                          addScheduleOpen();
                                          setLevelType("level2");
                                        }}
                                      >
                                        <AccessTimeIcon />
                                      </IconButton>
                                    )}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <Box>
                                      <Item>
                                        <FormLabel>Level 3</FormLabel>
                                      </Item>
                                      <Item>
                                        <TextField
                                          id="lavel-field"
                                          name="level_field_three"
                                          value={values.level_field_three}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          error={Boolean(
                                            touched.level_field_three &&
                                              errors.level_field_three
                                          )}
                                          helperText={
                                            touched.level_field_three &&
                                            errors.level_field_three
                                          }
                                          placeholder="me@you.com"
                                        />
                                      </Item>
                                    </Box>
                                  </TableCell>
                                  <TableCell>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="comment_level"
                                          value="3"
                                          onChange={handleChange}
                                        />
                                      }
                                      label=""
                                    />
                                  </TableCell>
                                  <TableCell>
                                    {isValidEmail(values.level_field_three) && (
                                      <IconButton
                                        onClick={() => {
                                          addScheduleOpen();
                                          setLevelType("level3");
                                        }}
                                      >
                                        <AccessTimeIcon />
                                      </IconButton>
                                    )}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </LavelTable>
                      </Grid>
                    </Grid>
                  </FormControl>
                  <FormControl
                    mt={3}
                    style={{ display: taskType === "No" ? "block" : "none" }}
                  >
                    <CustomSwitchControl>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <CustomSwitchYesNo
                              name="critical"
                              value={values.critical}
                              checked={values.critical === "critical"}
                              onChange={(event, checked) => {
                                setFieldValue(
                                  "critical",
                                  checked ? "critical" : "non-critical"
                                );
                              }}
                            />
                          }
                          label="Critical"
                        />
                        <FormControlLabel
                          control={
                            <CustomSwitchYesNo
                              name="cohm"
                              value={values.cohm}
                              checked={values.cohm === 1}
                              onChange={(event, checked) => {
                                setFieldValue("cohm", checked ? 1 : 0);
                              }}
                            />
                          }
                          label="Contact occupational health manager"
                        />
                        <FormControlLabel
                          control={
                            <CustomSwitchYesNo
                              name="comment"
                              value={values.comment}
                              checked={values.comment === 1}
                              onChange={(event, checked) => {
                                setFieldValue("comment", checked ? 1 : 0);
                              }}
                            />
                          }
                          label="Comments required"
                        />
                        <FormControlLabel
                          control={
                            <CustomSwitchOffOn
                              name="team"
                              value={values.team}
                              checked={values.team === 1}
                              onChange={(event, checked) => {
                                setFieldValue("team", checked ? 1 : 0);
                              }}
                            />
                          }
                          label="Team notify"
                        />
                        <FormControlLabel
                          control={
                            <CustomSwitchYesNo
                              name="send_shift_report"
                              value={values.send_shift_report}
                              checked={values.send_shift_report === 1}
                              onChange={(event, checked) => {
                                setFieldValue(
                                  "send_shift_report",
                                  checked ? 1 : 0
                                );
                              }}
                            />
                          }
                          label="Send shift report"
                        />
                        <FormControlLabel
                          control={
                            <CustomSwitchOffOn
                              name="web_notify"
                              value={values.web_notify}
                              checked={values.web_notify === 1}
                              onChange={(event, checked) => {
                                setFieldValue("web_notify", checked ? 1 : 0);
                              }}
                            />
                          }
                          label="Web notify"
                        />
                        <FormControlLabel
                          control={
                            <CustomSwitchYesNo
                              name="ir_required"
                              value={values.ir_required}
                              checked={values.ir_required === 1}
                              onChange={(event, checked) => {
                                setFieldValue("ir_required", checked ? 1 : 0);
                              }}
                            />
                          }
                          label="IR required"
                        />
                        <FormControlLabel
                          control={
                            <CustomSwitchYesNo
                              name="collect_sentiemnt"
                              value={values.collect_sentiemnt}
                              checked={values.collect_sentiemnt === "Y"}
                              onChange={(event, checked) => {
                                setFieldValue(
                                  "collect_sentiemnt",
                                  checked ? "Y" : "N"
                                );
                              }}
                            />
                          }
                          label="Collect sentiment"
                        />
                        <FormControlLabel
                          control={
                            <CustomSwitchYesNo
                              name="passdown_req"
                              value={values.passdown_req}
                              checked={values.passdown_req === "Y"}
                              onChange={(event, checked) => {
                                setFieldValue(
                                  "passdown_req",
                                  checked ? "Y" : "N"
                                );
                              }}
                            />
                          }
                          label="Passdown required"
                        />
                        <FormControlLabel
                          control={
                            <CustomSwitchYesNo
                              name="unavailable_started"
                              value={values.unavailable_started}
                              checked={values.unavailable_started === "Y"}
                              onChange={(event, checked) => {
                                setFieldValue(
                                  "unavailable_started",
                                  checked ? "Y" : "N"
                                );
                              }}
                            />
                          }
                          label="Unavailable when started"
                        />
                      </FormGroup>
                    </CustomSwitchControl>
                    <FormControl mt={6}>
                      <TextField
                        id="task-comment"
                        label="Task comment"
                        name="task_comment"
                        value={values.task_comment}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Standard comment to populate via dispatch"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <FormLabel>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "3px",
                          }}
                        >
                          Activity points
                          {
                            <StyledInputSlider>
                              <Paper>
                                <Input
                                  value={
                                    activityvalue === 0 ? 3 : activityvalue
                                  }
                                  sx={{ marginLeft: "5px" }}
                                  size="small"
                                  name="activity_point"
                                  onChange={handleInputChange}
                                  onBlur={handleBlur}
                                  inputProps={{
                                    step: 1,
                                    min: 0,
                                    max: 100,
                                    "aria-labelledby": "input-slider",
                                  }}
                                />
                              </Paper>
                            </StyledInputSlider>
                          }
                        </Box>
                      </FormLabel>
                      <Slider
                        size="medium"
                        value={activityvalue === 0 ? 3 : activityvalue}
                        onChange={handleActivityPoints}
                        aria-labelledby="input-slider"
                        sx={{ marginLeft: "6px", marginTop: "10px" }}
                        min={0}
                        max={10}
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <FormLabel component="legend">Task sentiment</FormLabel>
                      <StyledRadio>
                        <RadioGroup
                          aria-labelledby="task-sentiment"
                          name="task_sentiments"
                          defaultValue={values.task_sentiments}
                          value={values.task_sentiments}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Positive"
                            value="1"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="Warning"
                            value="2"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="Caution"
                            value="3"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="Danger"
                            value="4"
                          />
                        </RadioGroup>
                      </StyledRadio>
                    </FormControl>
                    <FormControl mt={6}>
                      <FormLabel>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "3px",
                          }}
                        >
                          Sentiment score
                          {
                            <StyledInputSlider>
                              <Paper>
                                <Input
                                  value={sentimentscore}
                                  sx={{ marginLeft: "5px" }}
                                  size="small"
                                  onChange={handleScoreChange}
                                  onBlur={handleScoreBlur}
                                  inputProps={{
                                    step: 1,
                                    min: 0,
                                    max: 100,
                                    "aria-labelledby": "input-slider",
                                  }}
                                />
                              </Paper>
                            </StyledInputSlider>
                          }
                        </Box>
                      </FormLabel>
                      <Slider
                        size="medium"
                        value={
                          typeof sentimentscore === "number"
                            ? sentimentscore
                            : 0
                        }
                        onChange={handleScores}
                        aria-labelledby="input-slider"
                        sx={{ marginLeft: "6px", marginTop: "10px" }}
                      />
                    </FormControl>
                  </FormControl>
                  <FormControl
                    mt={3}
                    style={{ display: taskType === "Yes" ? "block" : "none" }}
                  >
                    <CustomSwitchControl>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <CustomSwitchYesNo
                              name="unavailable_started"
                              value={values.unavailable_started}
                              checked={values.unavailable_started === "Y"}
                              onChange={(event, checked) => {
                                setFieldValue(
                                  "unavailable_started",
                                  checked ? "Y" : "N"
                                );
                              }}
                            />
                          }
                          label="Unavailable when started"
                        />
                      </FormGroup>
                    </CustomSwitchControl>
                  </FormControl>
                  <FormControl mt={6}>
                    <TextField
                      id="tag"
                      label="Tag:"
                      name="tag"
                      value={values.tag}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormControl>
                  {taskType === "No" && (
                    <Paper
                      style={{
                        display: "flex",
                        gap: "10px",
                        marginTop: "30px",
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        color={"primary"}
                        sx={{ cursor: "pointer" }}
                        onClick={handleStructuredCommentsOpen}
                      >
                        Add Structured Comments
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        color={"primary"}
                        sx={{ cursor: "pointer" }}
                        onClick={handleStructuredLocationOpen}
                      >
                        Add Structured Location
                      </Typography>
                    </Paper>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl mt={2}>
                    <FormLabel>Information</FormLabel>
                    <StyledContainer>
                      <Box
                        style={{
                          display: taskType === "Yes" ? "block" : "none",
                        }}
                      >
                        <Paper variant="p" component="div">
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            component="span"
                          >
                            Task Type:
                          </Typography>
                          <Typography variant="span" gutterBottom>
                            If this is a new task being added, you can make a
                            selection here. This cannot be changed after you
                            save and return to edit the task. A Tour Task is one
                            that can be assigned to a tour checkpoint, such as
                            "Floors are dry". A Non-Tour Task is one that is not
                            directly associated with a tour such as "Vehicle
                            patrol".
                          </Typography>
                        </Paper>
                        <Paper variant="p" component="div">
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            component="span"
                          >
                            Custom Button Levels:
                          </Typography>
                          <Typography variant="span" gutterBottom>
                            <strong>(tour tasks only)</strong> By default,
                            mobile users will see options Yes, No, and N/A as
                            buttons when completing this task. For example,
                            let's assume the task is to check the temperature of
                            a server room. You could add captions like 'Normal',
                            'Above Normal' or 'Too Hot' then add email addresses
                            to notify others of selections made, and a schedule
                            when these notices should be sent.
                          </Typography>
                        </Paper>
                      </Box>
                      <Box
                        style={{
                          display: taskType === "No" ? "block" : "none",
                        }}
                      >
                        <Paper variant="p" component="div">
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            component="span"
                          >
                            Critical:
                          </Typography>
                          <Typography variant="span" gutterBottom>
                            Setting this toggle to YES can potentially sent
                            notification alerts by email or SMS when the
                            critical task is recorded by your team. Visit
                            Settings
                            <ArrowForwardIosIcon />
                            Communication to learn how to set this up.
                          </Typography>
                        </Paper>
                        <Paper variant="p" component="div">
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            component="span"
                          >
                            Contact Occupational Health Manager:
                          </Typography>
                          <Typography variant="span" gutterBottom>
                            When set to ON, triggers emails and SMS messages to
                            be sent to your onsite nurse or health care provider
                            when these tasks are recorded. Visit Settings
                            <ArrowForwardIosIcon />
                            Communication to learn how to set this up.
                          </Typography>
                        </Paper>
                        <Paper variant="p" component="div">
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            component="span"
                          >
                            Comments Required:
                          </Typography>
                          <Typography variant="span" gutterBottom>
                            Setting this toggle to YES requires your team to
                            enter a comment prior to completing the task.
                          </Typography>
                        </Paper>
                        <Paper variant="p" component="div">
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            component="span"
                          >
                            Team Notify:
                          </Typography>
                          <Typography variant="span" gutterBottom>
                            Setting toggle to ON allows your team to send
                            notifications to a group of recipients that you
                            configure. You will find an option for "Team Notify"
                            in the drop-down menu at Settings
                            <ArrowForwardIosIcon />
                            Communication. Add your recipients there to receive
                            email or text messages when this task type is
                            recorded.
                          </Typography>
                        </Paper>
                        <Paper variant="p" component="div">
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            component="span"
                          >
                            Send Shift Report:
                          </Typography>
                          <Typography variant="span" gutterBottom>
                            Setting toggle to YES will cause this task to roll
                            up in totals that are shown in the shift report.
                            Most tasks should be marked YES. An example of a
                            task that you may not wish to have counted on the
                            shift report is a task like "Meal Break", unless
                            it's important to know how many people took meal
                            breaks in a given shift.
                          </Typography>
                        </Paper>
                        <Paper variant="p" component="div">
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            component="span"
                          >
                            Web Notify:
                          </Typography>
                          <Typography variant="span" gutterBottom>
                            Setting toggle to ON will show a notification to all
                            users who are currently logged into SOVA from a
                            desktop console. These notifications remain, and
                            must be clicked to close.
                          </Typography>
                        </Paper>
                        <Paper variant="p" component="div">
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            component="span"
                          >
                            IR Required:
                          </Typography>
                          <Typography variant="span" gutterBottom>
                            Setting toggle to YES will prompt mobile users to
                            optionally create a new incident report when
                            completing the task.
                          </Typography>
                        </Paper>
                        <Paper variant="p" component="div">
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            component="span"
                          >
                            Collect Sentiment:
                          </Typography>
                          <Typography variant="span" gutterBottom>
                            Setting toggle to YES will show a popup to mobile
                            users when they are completing the task. The popup
                            allows them to choose whether the task interaction
                            was a positive or negative one. You would usually
                            set this to YES for tasks where the security officer
                            will encounter and interact with your customers.
                          </Typography>
                        </Paper>
                        <Paper variant="p" component="div">
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            component="span"
                          >
                            Passdown Required:
                          </Typography>
                          <Typography variant="span" gutterBottom>
                            Setting toggle to YES saves task to the passdown
                            report automatically.
                          </Typography>
                        </Paper>
                        <Paper variant="p" component="div">
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            component="span"
                          >
                            Task Comment:
                          </Typography>
                          <Typography variant="span" gutterBottom>
                            Adding a canned text comment will allow it to be
                            selected from live dispatch to help improve data
                            consistency between dispatchers. Consider adding
                            text like &#123; add name here &#125; to focus users
                            to change certain parts of the comment while leaving
                            the rest unchanged.
                          </Typography>
                        </Paper>
                        <Paper variant="p" component="div">
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            component="span"
                          >
                            Activity Points:
                          </Typography>
                          <Typography variant="span" gutterBottom>
                            Set an activity point value for this task using the
                            slider. Tasks are given 3 activity points by
                            default. A good rule of thumb is to assign 1
                            activity point for each 5 minutes of activity. So if
                            a task takes 10 minutes on average to complete, you
                            would assign 2 activity points.
                          </Typography>
                        </Paper>
                        <Paper variant="p" component="div">
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            component="span"
                          >
                            Task Sentiment:
                          </Typography>
                          <Typography variant="span" gutterBottom>
                            Select the general sentiment for this task. Your
                            selection here will dictate the color of the icon
                            that appears in the DAR report so admins can quickly
                            scan that report to focus only on the items that
                            would generally be considered negative.
                          </Typography>
                        </Paper>
                        <Paper variant="p" component="div">
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            component="span"
                          >
                            Sentiment Score:
                          </Typography>
                          <Typography variant="span" gutterBottom>
                            Select a number using the slider that represents the
                            'Sentiment Score' for this task. Very positive tasks
                            should be ranked 80-100, very negative tasks 0-20.
                            Neutral tasks should be ranked at 50. The average
                            sentiment score for all tasks completed in a given
                            day is displayed in the dashboard calendar providing
                            admins a quick overview into whether the day was
                            positive or negative.
                          </Typography>
                        </Paper>
                        <Paper variant="p" component="div">
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            component="span"
                          >
                            Tag:
                          </Typography>
                          <Typography variant="span" gutterBottom>
                            Aggregate tasks that are used at certain sites. This
                            is an optional field. Possible values could be the
                            name of your clients business, or an ID number that
                            represents one of your clients.
                          </Typography>
                        </Paper>
                      </Box>
                    </StyledContainer>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={6}
                    sx={{ width: "175px" }}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </form>
          {/* Add Schedule Dialog */}
          <TourSchedule
            addschedule={addschedule}
            addScheduleClose={addScheduleClose}
            addScheduleModal={addScheduleModal}
            TimePicker={TimePicker}
            levelType={levelType}
            // viewSchedule={viewSchedule}
            // result={result}
          />
          <Dialog
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "900px",
                },
              },
            }}
            open={taskbutton}
            onClose={taskButtonClose}
            aria-labelledby="equipment-log"
            aria-describedby="equipment-log"
          >
            <DialogTitle
              id="alert-dialog-title"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingBottom: "0px",
              }}
            >
              <Typography variant="h4" component="div">
                Edit Task Button
              </Typography>
              <DialogActions sx={{ padding: "0px" }}>
                <IconButton
                  sx={{ marginRight: "-10px" }}
                  onClick={() => setTaskButton(false)}
                >
                  <CancelIcon />
                </IconButton>
              </DialogActions>
            </DialogTitle>
            <DialogContent sx={{ padding: "0px" }}>
              <DialogContentText sx={{ padding: "10px 25px 25px" }}>
                <Grid container spacing={4}>
                  <Grid item xs={12} lg={6}>
                    <FormControl mt={6}>
                      <TextField
                        id="id"
                        name="id"
                        label="ID"
                        disabled
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="2760132329"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <TextField
                        id="btn-name"
                        name="btn-name"
                        label="Button name"
                        disabled
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Men's locker room"
                      />
                    </FormControl>
                    <FormControl mt={3}>
                      <TextAreaControl
                        mt={3}
                        className={classes.textAreaControl}
                      >
                        <FormControl mt={1}>
                          <InputLabel id="v-status">Location hint</InputLabel>
                          <TextareaAutosize placeholder="Near lockers" />
                        </FormControl>
                      </TextAreaControl>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormControl mt={5}>
                      <FormLabel>Location hint image</FormLabel>
                      <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id="raised-button-file"
                        multiple
                        type="file"
                      />
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} lg={4}>
                          <label htmlFor="raised-button-file">
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                              mt={3}
                            >
                              Choose image
                            </Button>
                          </label>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                          <label>
                            <Button
                              variant="contained"
                              color="warning"
                              component="span"
                              mt={3}
                            >
                              Place Arrows
                            </Button>
                          </label>
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions
              sx={{
                paddingTop: "30px",
                paddingBottom: "30px",
                paddingRight: "20px",
              }}
            >
              <Button
                onClick={taskButtonClose}
                color="warning"
                variant="contained"
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
          <StructuredDialog
            open={structuredCommentsOpen}
            onClose={handleStructuredCommentsClose}
            title="Structured Comments"
            description={`Provide a basic sentence containing relevant data that should appear in the activity reports. Enclose portions that you want your users to change in {{double curly brackets}}. Here’s an example:

            Fire panel {{Indicate if it was checked::was checked, was not checked}} and the system readings were {{Provide fire panel readings::normal, trouble, bypass}}

            Inside the brackets, the portion at the left of the double :: colon will be the caption your user will see, and the portion to the right will be the hint that will appear in the field where they will provide their response. Here you could provide an example of what information they should provide.`}
            onSave={(structuredComment) => {
              setFieldValue("structured_comment", structuredComment);
              handleStructuredCommentsClose();
            }}
            currentValue={values.structured_comment}
          />

          {/* Structured Location Dialog */}
          <StructuredDialog
            open={structuredLocationOpen}
            onClose={handleStructuredLocationClose}
            title="Structured Location"
            description="Add captions and hints below to help your team provide necessary information in the location field for this NTT."
            onSave={(structuredLocation) => {
              setFieldValue("structured_location", structuredLocation);
              handleStructuredLocationClose();
            }}
            currentValue={values.structured_location}
          />
        </Card>
      )}
    </Formik>
  );
}

function AddTask() {
  return (
    <React.Fragment>
      <Helmet title="Add Task" />
      <Typography variant="h3" gutterBottom display="inline">
        Add Task
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/tasks/task-list">
          Tasks
        </Link>
        <Typography>Add Task</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12} md={12}>
          <ColumnLeft />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default AddTask;
