import React, { useState } from "react";
import { FormControlLabel, Switch } from "@mui/material";
import { EquipmentActiveInactive } from "../../api";

const EquipmentListToggle = (props) => {
  const { value, id } = props;
  const [active, setActive] = useState(value === "A" ? true : false);
  const handleActive = (status, id) => {
    console.log(status, id);
    let payload = {
      status: status === true ? "I" : "A",
    };
    EquipmentActiveInactive(id, payload)
      .then((res) => {
        console.log("success");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={active}
            onClick={() => {
              setActive(!active);
              handleActive(active, id);
            }}
          />
        }
        label=""
      />
    </>
  );
};

export default EquipmentListToggle;
