import React from "react";
import styled from "styled-components/macro";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  Button,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl as MuiFormControl,
  Box,
  FormControlLabel,
  Checkbox,
  Card,
  TextField,
  Grid,
  formLabelClasses,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Cancel as CancelIcon } from "@mui/icons-material";

const IconPopup = ({
  iconPopup,
  setIconPopup,
  iconPopupOpen,
  iconPopupClose,
}) => {
  const FormControlSpacing = styled(MuiFormControl)(spacing);

  const FormControl = styled(FormControlSpacing)`
    width: 100%;
  `;
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "400px",
            },
          },
        }}
        open={iconPopup}
        onClose={iconPopupClose}
        aria-labelledby="viewmap-title"
        aria-describedby="viewmap-description"
      >
        <DialogTitle
          id="viewmap-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{
                marginRight: "-10px",
              }}
              onClick={() => setIconPopup(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={6}>
            <Grid item xs={12} mt={4}>
              <FormControl>
                <InputLabel id="demo-select-small">
                  Click to select location
                </InputLabel>
                <Select
                  labelId="department"
                  id="demo-select-small"
                  label="Click to select location"
                  value={age}
                  onChange={handleChange}
                  placeholder="Select template(optional)"
                >
                  <MenuItem value="disables value">
                    Select template(optional)
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl>
                <InputLabel id="demo-select-small" shrink>
                  Activity
                </InputLabel>
                <Select
                  labelId="department"
                  id="demo-select-small"
                  label="Click to select"
                  name="Activity"
                  value={age}
                  onChange={handleChange}
                  placeholder="Click to select"
                >
                  <MenuItem value="">Click to select</MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl>
                <InputLabel id="demo-select-small" shrink>
                  Offer
                </InputLabel>
                <Select
                  labelId="department"
                  id="demo-select-small"
                  label="Offer"
                  name="Activity"
                  value={age}
                  onChange={handleChange}
                  placeholder="Click to select"
                >
                  <MenuItem value="">Click to select</MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-multiline-static"
                label="Location"
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Room,area"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-multiline-static"
                label="Location"
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Room,area"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{ width: "350px" }}
                id="outlined-multiline-static"
                label="Comments"
                multiline
                rows={3}
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Comment about the task"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            padding: "20px 24px",
          }}
        >
          <FormControlLabel
            sx={{ marginRight: "5px" }}
            label="Start task upon saving"
            control={<Checkbox defaultChecked />}
          />
          <Button variant="outlined" color="primary" onClick={iconPopupClose}>
            Clear
          </Button>
          <Button variant="contained" color="primary" onClick={iconPopupClose}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default IconPopup;
