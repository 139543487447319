import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { format } from "date-fns";
import { makeStyles } from "@mui/styles";
import {
  GetEquipmentList,
  EquipmentActiveInactive,
  DeleteEquipment,
  EquipmentListPdf,
} from "../../api";
import Toaster from "../tasks/Toaster";
import { useDispatch, useSelector } from "react-redux";
import { getSnackClose } from "../../redux/slices/equipmentSlice";
import EquipmentListCol from "../table-columns-json/equipment/equipmentList";
import { setEquipmentList } from "../../redux/slices/equipment/equipmentTableColSlice";
import StyledColumnOptions from "../../helper/StyledColumnOptions";
import {
  Checkbox,
  Grid,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  InputAdornment,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  InputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  Radio,
  CardContent,
  FormLabel,
  RadioGroup,
  Card,
  Box,
  Button,
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  CircularProgress,
  Autocomplete,
  Pagination,
  Tooltip,
} from "@mui/material";
import { DateTimePicker, DatePicker } from "@mui/lab";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  AddBox as AddBoxIcon,
  Settings as SettingsIcon,
  Create as CreateIcon,
  PictureAsPdf as PictureAsPdfIcon,
  Cached as CachedIcon,
  Portrait as PortraitIcon,
  ExpandMore as ExpandMoreIcon,
  SettingsInputComposite as SettingsInputCompositeIcon,
} from "@mui/icons-material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import BlockIcon from "@mui/icons-material/Block";
import BuildIcon from "@mui/icons-material/Build";
import { spacing } from "@mui/system";
import EquipmentListToggle from "./EquipmentListToggle";
import EquipmentLogPopup from "./innerform/EquipmentLogPopup";

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledSwitchControl = styled((props) => <FormGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormControlLabel-root": {
      position: "relative",
      marginLeft: "0px",
      marginRight: "0px",
      paddingRight: "60px",
      paddingTop: "8px",
      paddingBottom: "8px",
      "& .MuiSwitch-root": {
        position: "absolute",
        right: "-10px",
        top: "0px",
      },
    },
  })
);
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "140px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);
const StyledForm = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    marginLeft: "75px",
    marginTop: "8px",
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      minWidth: "435px",
      "& form": {
        padding: "15px 20px",
      },
      "& .MuiMenuItem-root": {
        padding: "0",
        display: "block",
        "& .MuiFormControl-root": {
          display: "block",
          width: "100%",
          marginBottom: "20px",
        },
      },
      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}
const headCells = [
  { id: "sel", numeric: true, disablePadding: true, label: "Sel" },
  { id: "type", numeric: true, disablePadding: false, label: "Type" },
  {
    id: "equipment_name",
    numeric: true,
    disablePadding: false,
    label: "Name",
  },
  { id: "equipid", numeric: true, disablePadding: false, label: "Equip ID" },
  { id: "keygroup", numeric: true, disablePadding: false, label: "Key Group" },
  { id: "ringid", numeric: true, disablePadding: false, label: "Ring ID" },
  {
    id: "currentlocation",
    numeric: true,
    disablePadding: false,
    label: "Current Location",
  },
  { id: "active", numeric: true, disablePadding: false, label: "Active" },
  {
    id: "lastprocessed",
    numeric: true,
    disablePadding: false,
    label: "Last Processed",
  },
  {
    id: "sl",
    numeric: true,
    disablePadding: false,
    label: "SL",
  },
  {
    id: "disposition,",
    numeric: true,
    disablePadding: false,
    label: "Disposition",
  },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];
const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    tableCalRedux,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Tooltip title="Check one or more for bulk edits">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all data" }}
            />
          </Tooltip>
        </TableCell>
        <TableCell></TableCell>
        {EquipmentListCol.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            sx={{ whiteSpace: "nowrap" }}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={tableCalRedux[headCell.id] === true ? "" : "hide-cell"}
          >
            <TableSortLabel
              active={
                headCell.id === "equipment_name"
                  ? orderBy === headCell.id
                  : false
              }
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={
                headCell.id == "sel" ||
                headCell.id == "active" ||
                headCell.id == "last_processed" ||
                headCell.id == "action"
                  ? null
                  : createSortHandler(headCell.id)
              }
              hideSortIcon={
                headCell.id == "sel" ||
                headCell.id == "active" ||
                headCell.id == "last_processed" ||
                headCell.id == "action"
                  ? true
                  : false
              }
            >
              <Tooltip title={headCell.tooltipMsg}>
                <div>{headCell.label}</div>
              </Tooltip>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const {
    numSelected,
    totalRecord,
    setDataToFilter,
    handleDeleteOpen,
    handleFirstPage,
    tableCalRedux,
    defalutTableCalRedux,
    rowsPerPage,
    order,
    orderBy,
    dataToFilter,
  } = props;
  const [actions, setActions] = React.useState("");
  const [equipmentId, setEquipmentId] = useState("");
  const [equipmentName, setEquipmentName] = useState("");
  const [equipmentType, setEquipmentType] = useState("");
  const [ringId, setRingId] = useState("");
  const [keyGroupName, setKeyGroupName] = useState("");
  const [lastProcessedDate, setLastProcessedDate] = useState(null);
  const [equipmentDisposition, setEquipmentDisposition] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const [allEquipment, setAllEquipment] = useState([]);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  // show checked only switch toggle
  const [state, setState] = React.useState({
    switchone: false,
  });
  const handleSwitchChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const { switchone } = state;
  //method for filter data
  const applySearch = () => {
    const payload = {
      equipment_id: equipmentId,
      equipment_name: equipmentName,
      type_name: equipmentType,
      key_ring_id: ringId,
      disposition: equipmentDisposition,
      name: keyGroupName,
      checked_out: switchone ? "on" : null,
      modified:
        lastProcessedDate !== null
          ? format(lastProcessedDate, "yyyy-MM-dd")
          : null,
      search_value: searchValue,
    };
    setDataToFilter(payload);
  };

  //function for reseting the filter
  const resetFilter = () => {
    setEquipmentId("");
    setEquipmentName("");
    setEquipmentType("");
    setRingId("");
    setEquipmentDisposition("");
    setKeyGroupName("");
    setLastProcessedDate(null);
    setState({ switchone: false });
  };
  const handleChange = (event) => {
    setActions(event.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // Search Dropdown
  const [anchorForm, setAnchorForm] = React.useState(null);
  const handleClickDropdown = (event) => {
    setAnchorForm(event.currentTarget);
  };

  const handleCloseForm = () => {
    setAnchorForm(null);
  };

  const [value, setValue] = React.useState(null);
  // Columns Dropdown
  const [anchorColumn, setAnchorColumn] = React.useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };

  const onButtonClick = () => {
    const payload = {
      limit: rowsPerPage,
      sorting: {
        order: order,
        by: orderBy,
      },
      ...dataToFilter,
    };
    setLoading(true);
    EquipmentListPdf(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          const linkSource = `data:application/pdf;base64,${res.data}`;
          const downloadLink = document.createElement("a");
          const fileName = "SOVA-Eqipment-List.pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          setLoading(false);
        } else {
          console.log("something went wrong");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  return (
    <Toolbar>
      <div>
        <Paper mt={3}>
          <FormControl mr={2} sx={{ minWidth: 120 }}>
            <InputLabel id="demo-simple-select-label">Actions</InputLabel>
            <Select
              className="equipment_action_list"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={actions}
              label="Actions"
              MenuProps={{
                PaperProps: {
                  sx: {
                    "& .MuiMenu-list": {
                      boxShadow:
                        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 10%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
                      margin: "0px 3px 5px 3px",
                      padding: "0",
                    },
                  },
                },
              }}
              onChange={handleChange}
            >
              <MenuItem value={"Delete"} onClick={handleDeleteOpen}>
                Delete
              </MenuItem>
            </Select>
          </FormControl>
        </Paper>
      </div>
      <div className="totalRecord">
        {totalRecord > 0 ? (
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content", marginLeft: "15px" }}
          >
            {totalRecord} records found
          </Typography>
        ) : (
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content", marginLeft: "15px" }}
          >
            {totalRecord} records found
          </Typography>
        )}
      </div>
      <Spacer />
      <div className="searchTool">
        <Box
          mt={3}
          sx={{
            display: "flex",
            flexWrap: "nowrap",
          }}
        >
          <Item sx={{ flexGrow: 1, width: "100%" }}>
            <TextField
              id="search"
              placeholder="Search by name, equip ID or click arrow"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  applySearch({});
                  setIsFilter(true);
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-owns={anchorForm ? "search-form" : undefined}
                      aria-haspopup="true"
                      onClick={handleClickDropdown}
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                    {anchorForm && (
                      <StyledForm
                        id="search-form"
                        anchorEl={anchorForm}
                        open={Boolean(anchorForm)}
                        onClose={handleCloseForm}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <MenuItem
                          sx={{ maxHeight: "380px", overflowY: "auto" }}
                        >
                          <form
                            onKeyDown={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <FormControl mt={3}>
                              <TextField
                                fullWidth
                                id="equip-id"
                                label="Equipment ID"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="Barcode ID (non keys)"
                                value={equipmentId}
                                onChange={(e) => {
                                  setEquipmentId(e.target.value);
                                }}
                              />
                            </FormControl>
                            <FormControl>
                              <Autocomplete
                                freeSolo={true}
                                id="free-solo-2-demo"
                                disableClearable
                                inputValue={equipmentName}
                                onInputChange={(event, newInputValue) => {
                                  setEquipmentName(newInputValue);
                                  let d = {
                                    equipment_name: equipmentName,
                                    limit: totalRecord,
                                  };
                                  GetEquipmentList(1, { params: d })
                                    .then((res) => {
                                      setAllEquipment(res.data);
                                    })
                                    .catch((err) => {
                                      console.log(err);
                                    });
                                }}
                                options={allEquipment.map(
                                  (option) => option.equipment_name
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Equipment name"
                                    InputProps={{
                                      ...params.InputProps,
                                    }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    placeholder="KEY 001"
                                  />
                                )}
                              />
                            </FormControl>
                            <FormControl>
                              <TextField
                                fullWidth
                                id="equip-type"
                                label="Equipment type"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                value={equipmentType}
                                onChange={(e) => {
                                  setEquipmentType(e.target.value);
                                }}
                                placeholder="Keys, Radios, etc."
                              />
                            </FormControl>
                            <FormControl>
                              <TextField
                                fullWidth
                                id="ring-id"
                                label="Ring ID"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                value={ringId}
                                onChange={(e) => {
                                  setRingId(e.target.value);
                                }}
                                placeholder="Barcode ID (keys only)"
                              />
                            </FormControl>
                            <FormControl>
                              <Autocomplete
                                freeSolo={true}
                                id="free-solo-2-demo"
                                inputValue={keyGroupName}
                                onInputChange={(event, newInputValue) => {
                                  setKeyGroupName(newInputValue);
                                  let d = {
                                    name: keyGroupName,
                                    limit: totalRecord,
                                  };
                                  GetEquipmentList(1, { params: d })
                                    .then((res) => {
                                      setAllEquipment(res.data);
                                    })
                                    .catch((err) => {
                                      console.log(err);
                                    });
                                }}
                                options={allEquipment}
                                getOptionLabel={(option) => option.key_group_id}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Key group name"
                                    InputProps={{
                                      ...params.InputProps,
                                    }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    placeholder="Engineering"
                                  />
                                )}
                              />
                            </FormControl>
                            <FormControl>
                              <DatePicker
                                id="last-processed"
                                label="Last processed"
                                value={lastProcessedDate}
                                onChange={(newValue) => {
                                  setLastProcessedDate(newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{
                                      ...params.inputProps,
                                      placeholder: "Click to select",
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                            <FormControl>
                              <StyledSwitchControl>
                                <FormGroup>
                                  <FormControlLabel
                                    label="Show checked out only"
                                    control={
                                      <Switch
                                        checked={switchone}
                                        onChange={handleSwitchChange}
                                        name="switchone"
                                      />
                                    }
                                  />
                                </FormGroup>
                              </StyledSwitchControl>
                            </FormControl>
                            <FormControl>
                              <FormLabel component="legend">
                                Equipment disposition
                              </FormLabel>
                              <RadioGroup
                                aria-labelledby="equipment-dispotion"
                                defaultValue="In service"
                                name="radio-buttons-group"
                                value={equipmentDisposition}
                                onChange={(e) => {
                                  setEquipmentDisposition(e.target.value);
                                }}
                              >
                                <FormControlLabel
                                  value="in_service"
                                  control={<Radio />}
                                  label="In service"
                                />
                                <FormControlLabel
                                  value="lost"
                                  control={<Radio />}
                                  label="Lost"
                                />
                                <FormControlLabel
                                  value="expired"
                                  control={<Radio />}
                                  label="Expired"
                                />
                                <FormControlLabel
                                  value="out_for_repair"
                                  control={<Radio />}
                                  label="Out for repair"
                                />
                              </RadioGroup>
                            </FormControl>
                            <FormControl>
                              <Button
                                variant="contained"
                                color="primary"
                                mt={6}
                                sx={{ width: "175px", marginRight: "5px" }}
                                onClick={() => {
                                  applySearch({});
                                  handleCloseForm();
                                  setIsFilter(true);
                                }}
                              >
                                Filter
                              </Button>
                            </FormControl>
                          </form>
                        </MenuItem>
                      </StyledForm>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Item>
          <Item>
            {isFilter ? (
              <Button
                variant="contained"
                color="primary"
                mt={6}
                sx={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  height: "100%",
                  marginLeft: "-3px",
                }}
                onClick={() => {
                  resetFilter();
                  setDataToFilter(null);
                  setIsFilter(false);
                  setSearchValue("");
                  handleFirstPage();
                }}
              >
                Clear
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                sx={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  height: "100%",
                  marginLeft: "-3px",
                }}
                onClick={() => {
                  if (searchValue !== "") {
                    applySearch({});
                    setIsFilter(true);
                    handleFirstPage();
                  }
                }}
              >
                Search
              </Button>
            )}
          </Item>
        </Box>
      </div>
      <Spacer />
      <div>
        <Paper mt={3}>
          <IconButton
            aria-owns={anchorEl ? "icon-menu" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            variant="contained"
            color="secondary"
          >
            <FilterListIcon />
          </IconButton>
          <StyledFilterMenu
            id="icon-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem
              aria-label="add-equipment"
              component={NavLink}
              to="/equipment/add-equipment"
            >
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="Add Equipment" pl={0} />
            </MenuItem>
            <MenuItem onClick={onButtonClick}>
              <ListItemIcon>
                <PictureAsPdfIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="Download PDF" pl={0} />
            </MenuItem>
            <MenuItem
              aria-label="equipment-user-list"
              component={NavLink}
              to="/equipment/equipment-user-list"
            >
              <ListItemIcon>
                <PortraitIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="Equipment Users" pl={0} />
            </MenuItem>
            <MenuItem
              aria-label="process-equipment"
              component={NavLink}
              to="/equipment/process-equipment"
            >
              <ListItemIcon>
                <CachedIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="Process Equipment" pl={0} />
            </MenuItem>
            <MenuItem
              aria-label="equipment-logs"
              component={NavLink}
              to="/equipment/equipment-logs"
            >
              <ListItemIcon>
                <SettingsInputCompositeIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="Equipment Logs" pl={0} />
            </MenuItem>
            <MenuItem
              aria-owns={anchorColumn ? "columns-dropdown" : undefined}
              aria-haspopup="true"
              onClick={handleClickColumn}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Columns" pl={0} />
            </MenuItem>
          </StyledFilterMenu>
          <StyledColumnDropdown
            id="columns-dropdown"
            anchorEl={anchorColumn}
            open={Boolean(anchorColumn)}
            onClose={handleCloseColumn}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem>
              <Card>
                <CardContent>
                  <Typography variant="h6" component="h2">
                    {
                      Object.keys(tableCalRedux).filter(
                        (k) => tableCalRedux[k] === true
                      ).length
                    }{" "}
                    out of {Object.keys(tableCalRedux).length} visible
                  </Typography>
                  <StyledColumnOptions
                    calStatus={tableCalRedux}
                    updateCalStatus={(data) => {
                      dispatch(setEquipmentList(data));
                    }}
                    listing={EquipmentListCol}
                  />
                  <Toolbar>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        dispatch(setEquipmentList(defalutTableCalRedux));
                      }}
                    >
                      Reset
                    </Button>
                    <Spacer />
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleCloseColumn}
                    >
                      Cancel
                    </Button>
                  </Toolbar>
                </CardContent>
              </Card>
            </MenuItem>
          </StyledColumnDropdown>
        </Paper>
        {loading && (
          <Paper className={classes.fixedLoader}>
            <CircularProgress />
          </Paper>
        )}
      </div>
    </Toolbar>
  );
};
function EnhancedTable() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("equipment_name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [equipment, setEquipment] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [dataToFilter, setDataToFilter] = useState(null);
  const [id, setId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [length, setLength] = useState(null);

  const tableCalRedux = useSelector(
    (state) => state.equipmentTableCol.equipmentList
  );
  const defalutTableCalRedux = useSelector(
    (state) => state.defaultEquipmentTableCol.equipmentList
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = equipment.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    setLength(newSelected.length);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
    setPage(0);
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
  };
  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [equipType, setEquipType] = useState("");
  const [equipName, setEquipName] = useState("");
  const handleActionClick = (e, id, type, name) => {
    setAnchorEl(e.currentTarget);
    setId(id);
    setLength(1);
    setEquipType(type);
    setEquipName(name);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
    setId(null);
  };

  //Equipment log modal
  const [open, setOpen] = React.useState(false);
  const clickLogModalOpen = () => {
    setOpen(true);
    setAnchorEl(null);
  };
  const logModalClose = () => {
    setOpen(false);
  };

  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
    setAnchorEl(null);
    setId(null);
  };

  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  //method for delete equipment
  const deleteEquipment = () => {
    let value = selected.length > 0 ? selected : id;
    setAnchorEl(null);
    setOpenDelete(false);
    DeleteEquipment(value)
      .then((res) => {
        if (res.statusCode === 200) {
          equipmentList();
          setSelected([]);
          setOpenSnack(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //method for equipment list
  const equipmentList = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
      sorting: {
        order: order,
        by: orderBy,
      },
      ...dataToFilter,
    };
    GetEquipmentList(currentPage, payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setEquipment(res.data);
          setTotalRecord(res.meta.total);
          setCurrentPage(res.meta.current_page);
          setLoading(false);
        } else {
          setLoading(false);
          setEquipment([]);
          setTotalRecord(0);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setTotalRecord(0);
        setEquipment([]);
      });
  };

  //useEffect method
  useEffect(() => {
    equipmentList();
  }, [rowsPerPage, currentPage, dataToFilter, order, orderBy]);

  // State to track if the box should be visible
  const [isBoxVisible, setIsBoxVisible] = useState(false);

  // Handle checkbox change
  const handleCheckboxChange = (event) => {
    setIsBoxVisible(event.target.checked);
  };

  const [selRows, setSelRows] = useState([]);

  // Function to handle "Sel" checkbox selection
  const toggleSelRow = (event, id) => {
    const selectedIndex = selRows.indexOf(id);
    let newSelRows = [];

    if (selectedIndex === -1) {
      newSelRows = newSelRows.concat(selRows, id);
    } else if (selectedIndex === 0) {
      newSelRows = newSelRows.concat(selRows.slice(1));
    } else if (selectedIndex === selRows.length - 1) {
      newSelRows = newSelRows.concat(selRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelRows = newSelRows.concat(
        selRows.slice(0, selectedIndex),
        selRows.slice(selectedIndex + 1)
      );
    }

    setSelRows(newSelRows);
  };

  // Helper function to check if an item is selected
  const isSelRow = (id) => selRows.indexOf(id) !== -1;

  return (
    <div>
      <Paper mt={3}>
        <Toaster
          openSnack={openSnack}
          handleCloseSnack={handleCloseSnack}
          message={`A total of ${
            length > 0 ? length : 1
          } record(s) have been deleted`}
        />
        <div>
          {selected.length || id > 0 ? (
            <Dialog
              open={openDelete}
              onClose={handleDeleteClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Are you sure you want to delete the Equipment(s)?"}
              </DialogTitle>
              <DialogActions>
                <Button
                  onClick={handleDeleteClose}
                  variant="contained"
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  onClick={deleteEquipment}
                  autoFocus
                  variant="contained"
                  color="primary"
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          ) : (
            <Dialog
              open={openDelete}
              onClose={handleDeleteClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"You haven't selected any item!"}
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleDeleteClose}>OK</Button>
              </DialogActions>
            </Dialog>
          )}
        </div>
        <EnhancedTableToolbar
          numSelected={selected.length}
          totalRecord={totalRecord}
          setDataToFilter={setDataToFilter}
          handleDeleteOpen={handleDeleteOpen}
          handleFirstPage={handleFirstPage}
          tableCalRedux={tableCalRedux}
          defalutTableCalRedux={defalutTableCalRedux}
          dataToFilter={dataToFilter}
          rowsPerPage={rowsPerPage}
          order={order}
          orderBy={orderBy}
        />
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={equipment.length}
              tableCalRedux={tableCalRedux}
            />
            {loading ? (
              <TableCell colSpan={13} align="center">
                <CircularProgress />
              </TableCell>
            ) : (
              <TableBody>
                {stableSort(equipment, getComparator(order, orderBy)).map(
                  (row) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${row.id}`;

                    return (
                      <TableRow hover key={row.id} selected={isItemSelected}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            onClick={(event) => handleClick(event, row.id)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                          />
                        </TableCell>
                        <TableCell padding="checkbox">
                          {selRows.length !== 0 && (
                            <Box
                              className="checkbox_popup_model"
                              sx={{
                                position: "absolute",
                                top: "235px",
                                zIndex: 999,
                                backgroundColor: "#fde8c3",
                                padding: "7px 15px !important",
                                minWidth: "926px",
                                left: "306px",
                              }}
                            >
                              <Grid container spacing={2}>
                                <Grid
                                  item
                                  xs={6}
                                  md={3}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "4px",
                                  }}
                                >
                                  <Typography
                                    variant="h6"
                                    className="updated_heading"
                                  >
                                    Updated:
                                  </Typography>
                                  <Typography
                                    variant="h6"
                                    className="updated_heading"
                                  >
                                    {selRows.length} row(s) selected
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  md={9}
                                  sx={{ textAlign: "right" }}
                                >
                                  <Button
                                    variant="text"
                                    className="updated_heading"
                                  >
                                    Check-in Equipment
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          )}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          sx={{ lg: 'whiteSpace: "nowrap"' }}
                          className={
                            tableCalRedux.sel === true ? "" : "hide-cell"
                          }
                        >
                          {/* {row.sel} */}
                          <Checkbox
                            checked={isSelRow(row.id)}
                            disabled={row.current_location === "Checked In"}
                            inputProps={{ "aria-labelledby": labelId }}
                            onClick={(event) => toggleSelRow(event, row.id)}
                            role="checkbox"
                            aria-checked={isSelRow(row.id)}
                            tabIndex={-1}
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          className={
                            tableCalRedux.type === true ? "" : "hide-cell"
                          }
                        >
                          {/* {row.equipment_type} */}
                          {row
                            ? row.equipment_type
                              ? row.equipment_type
                              : "Key"
                            : ""}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={
                            tableCalRedux.equipment_name === true
                              ? ""
                              : "hide-cell"
                          }
                        >
                          {row.equipment_name}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.equipment_id === true
                              ? ""
                              : "hide-cell"
                          }
                        >
                          {row.equipment_id}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.key_group_id === true
                              ? ""
                              : "hide-cell"
                          }
                        >
                          {row.key_group_id}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.sel === true ? "" : "hide-cell"
                          }
                        >
                          {row.key_ring_id}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.current_location === true
                              ? ""
                              : "hide-cell"
                          }
                        >
                          {row.current_location}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={
                            tableCalRedux.active === true ? "" : "hide-cell"
                          }
                        >
                          <FormGroup>
                            <EquipmentListToggle
                              value={row.status}
                              id={row.id}
                            />
                          </FormGroup>
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.last_processed === true
                              ? ""
                              : "hide-cell"
                          }
                        >
                          {row.modified}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={
                            tableCalRedux.equipment_sl === true
                              ? ""
                              : "hide-cell"
                          }
                        >
                          {row.equipment_sl}
                        </TableCell>
                        {row.disposition === "in_service" ? (
                          <TableCell
                            title="In Service"
                            align="left"
                            className={
                              tableCalRedux.disposition === true
                                ? ""
                                : "hide-cell"
                            }
                          >
                            <CheckBoxIcon color="success" />
                          </TableCell>
                        ) : row.disposition === "lost" ? (
                          <TableCell
                            title="Lost"
                            align="left"
                            className={
                              tableCalRedux.disposition === true
                                ? ""
                                : "hide-cell"
                            }
                          >
                            <HighlightOffIcon />
                          </TableCell>
                        ) : row.disposition === "expired" ? (
                          <TableCell
                            title="Expired"
                            align="left"
                            className={
                              tableCalRedux.disposition === true
                                ? ""
                                : "hide-cell"
                            }
                          >
                            <BlockIcon />
                          </TableCell>
                        ) : row.disposition === "out_for_repair" ? (
                          <TableCell
                            title="Out for repair"
                            align="left"
                            className={
                              tableCalRedux.disposition === true
                                ? ""
                                : "hide-cell"
                            }
                          >
                            <BuildIcon />
                          </TableCell>
                        ) : null}
                        <TableCell
                          align="left"
                          className={
                            tableCalRedux.action === true ? "" : "hide-cell"
                          }
                        >
                          <IconButton
                            aria-label="Settings"
                            size="large"
                            aria-owns={anchorEl ? "icon-menu" : undefined}
                            aria-haspopup="true"
                            onClick={(e) =>
                              handleActionClick(
                                e,
                                row.id,
                                row.equipment_type,
                                row.equipment_name
                              )
                            }
                            style={{ color: "#1374C5" }}
                          >
                            <SettingsIcon />
                          </IconButton>
                          <StyledMenu
                            id="icon-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleActionClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <MenuItem
                              component={NavLink}
                              to={`/equipment/edit-equipment/${id}`}
                            >
                              <ListItemIcon>
                                <CreateIcon />
                              </ListItemIcon>
                              <ListItemText primary="Edit" pl={1} />
                            </MenuItem>
                            <MenuItem onClick={handleDeleteOpen}>
                              <ListItemIcon>
                                <DeleteIcon />
                              </ListItemIcon>
                              <ListItemText primary="Delete" pl={1} />
                            </MenuItem>
                            <MenuItem onClick={clickLogModalOpen}>
                              <ListItemIcon>
                                <SettingsIcon />
                              </ListItemIcon>
                              <ListItemText primary="Equipment Log" pl={1} />
                            </MenuItem>
                          </StyledMenu>
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
                {equipment.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={15} align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          }}
        >
          <Pagination
            count={
              Math.ceil(totalRecord / rowsPerPage) > 0
                ? Math.ceil(totalRecord / rowsPerPage)
                : 1
            }
            page={currentPage}
            boundaryCount={2}
            onChange={handleChangePagination}
            color="primary"
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ marginRight: "8px" }}>
                Rows per page:
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                onChange={handleChangeLimit}
              >
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </Box>
          </FormControl>
        </Box>
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      {/* Equipment log popup */}
      {open && (
        <EquipmentLogPopup
          setOpen={setOpen}
          open={open}
          logModalClose={logModalClose}
          id={id}
          equipType={equipType}
          equipName={equipName}
        />
      )}
    </div>
  );
}

function EquipmentList() {
  const dispatch = useDispatch();
  const snackbarStatus = useSelector((state) => state.equipment.snackStatus);
  const snackbarMsg = useSelector((state) => state.equipment.msg);
  const snackbarAlert = useSelector((state) => state.equipment.snackAlert);

  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(getSnackClose());
  };
  return (
    <React.Fragment>
      {snackbarStatus && (
        <Toaster
          openSnack={snackbarStatus}
          handleCloseSnack={handleCloseSnack}
          severity={snackbarAlert}
          message={snackbarMsg}
        />
      )}
      <Helmet title="Equipment List" />
      <Typography variant="h3" gutterBottom display="inline">
        Equipment List
      </Typography>

      {/* <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
          Tables
        </Link>
        <Typography>Users</Typography>
      </Breadcrumbs>

      <Divider my={6} /> */}

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default EquipmentList;
