import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components/macro";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { renderToString } from "react-dom/server";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { ViewTourReport } from "../../api";
import {
  Checkbox,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  /* FormGroup, */
  TableContainer,
  InputAdornment,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  /* Tooltip, */
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  Radio,
  CardContent,
  FormLabel,
  RadioGroup,
  Card,
  Box,
  Button,
  /* List,
  ListItem, */
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  List,
  ListItem,
  Link,
  Breadcrumbs,
  Divider as MuiDivider,
} from "@mui/material";
import { DatePicker } from "@mui/lab";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  AddBox as AddBoxIcon,
  ListAlt as ListAltIcon,
  Visibility as VisibilityIcon,
  Settings as SettingsIcon,
  Search as SearchIcon,
  ExpandMore as ExpandMoreIcon,
  Map as MapIcon,
  Cancel as CancelIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import autoTable from "jspdf-autotable";
import jsPDF from "jspdf";
import moment from "moment";
import { useSelector } from "react-redux";

/* const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing); */

// Classes Styling
const useStyles = makeStyles((theme) => ({
  dateRangeDropdown: {
    position: "absolute",
    left: 0,
    top: "100%",
    width: "100%",
    zIndex: 2,
    marginLeft: "0 !important",
    maxHeight: "200px",
    overflowY: "auto",

    "& .MuiBox-root": {
      padding: "15px",
      "& .MuiButton-contained": {
        marginLeft: "15px",
      },
    },
    "& .MuiList-root": {
      padding: 0,
      "& .MuiListItem-root:hover": {
        backgroundColor: "#376fd012",
      },
    },
  },
  DateList: {
    border: "1px solid #0000001c",
    marginTop: "22px",
    padding: 0,
    "& .MuiListItem-root": {
      borderBottom: "1px solid #0000001c",
    },
    "& .MuiListItem-root:last-child": {
      borderBottom: 0,
    },
    "& .MuiListItem-root:hover": {
      backgroundColor: "#376fd012",
    },
  },
}));
const Divider = styled(MuiDivider)(spacing);
const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledForm = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    marginLeft: "75px",
    marginTop: "8px",
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      minWidth: "435px",
      "& form": {
        padding: "15px 20px",
      },
      "& .MuiMenuItem-root": {
        padding: "0",
        display: "block",
        "& .MuiFormControl-root": {
          display: "block",
          width: "100%",
          marginBottom: "20px",
        },
      },
      "& .MuiMenuItem-root:hover": {
        background: "transparent",
      },
      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "140px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

const headCells = [
  {
    id: "scantime",
    numeric: true,
    disablePadding: false,
    label: "Scan Time",
  },
  {
    id: "location",
    numeric: true,
    disablePadding: false,
    label: "Location",
  },
  {
    id: "comment",
    numeric: true,
    disablePadding: false,
    label: "Comment",
  },
];
const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            sx={{ whiteSpace: "nowrap" }}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.tname ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.tname}
              direction={orderBy === headCell.tname ? order : "asc"}
              onClick={createSortHandler(headCell.tname)}
              hideSortIcon={true}
            >
              {headCell.label}
              {headCell.icon}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

function EnhancedTable(props) {
  const { childFunc } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("color");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { tourId, start_date, end_date } = useParams(); //Getting the Tour Id
  const [reportData, setReportData] = useState({});
  const [pdfData, setPdfData] = useState([]);
  const selectedLocation = useSelector((state) => state.login.Location);

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleActionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();

  const [startdate, setStartDate] = React.useState(null);
  const [enddate, setEndDate] = React.useState(null);

  const generatePdf = () => {
    const generatedDate = moment().format("MM/DD/YYYY HH:mm:ss");
    const userFirstName = JSON.parse(localStorage.getItem("user"));
    const userName = userFirstName.username;
    const doc = new jsPDF();
    var img = new Image();
    img.src = "/static/img/brands/logo.png";
    doc.addImage(img, "png", 10, 10, 45, 20);

    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text(100, 25, "Tour Report", "center");

    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text(100, 35, `${selectedLocation.LocationName}`, "center");

    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text("Tour Name : ", 15, 50);
    // doc.text(`Tour Name :        ${reportData.tour_name}`, 15, 50);

    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text(`${reportData.tour_name}`, 40, 50);

    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text("Tour By : ", 15, 55);
    // doc.text(`Tour By :             ${reportData.user_name}`, 15, 55);

    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text(`${reportData.user_name}`, 40, 55);

    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text("Tour Status : ", 15, 60);
    // doc.text(`Tour Status :        ${reportData.report_status}`, 15, 60);

    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text(`${reportData.report_status}`, 40, 60);

    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text("Tour Start : ", 15, 65);
    // doc.text(`Tour Start :          ${reportData.start_time}`, 15, 65);

    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text(`${reportData.start_time}`, 40, 65);

    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text("Tour Finish : ", 15, 70);
    // doc.text(`Tour Finish :        ${reportData.finish_time}`, 15, 70);

    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text(`${reportData.finish_time}`, 40, 70);

    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text("NIST Time : ", 15, 75);
    // doc.text(`NIST Time :        ${reportData.nist_time}`, 15, 75);

    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text(`${reportData.nist_time} hours`, 40, 75);

    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text("Device Time : ", 15, 80);
    // doc.text(`Device Time :      ${reportData.device_time}`, 15, 80);

    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text(`${reportData.device_time} hours`, 40, 80);

    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text(`Report By : `, 120, 50);

    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text(`${userName}`, 145, 50);

    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text(`Generated : `, 120, 55);

    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text(`${generatedDate}`, 145, 55);
    const expPdfData = pdfData.map((t) => {
      return [t.ScanTime.split(" ")[1], t.TagDescription, t.TourCommentText];
    });
    autoTable(doc, {
      // styles: { marginTop: "400px" },
      margin: { top: 90 },
      head: [["Scantime", "Location", "Comment"]],
      body: expPdfData,
      showFoot: "everyPage",
      // showHead: "firstPage",
      // Footer
      didDrawPage: function (data) {
        // Footer
        var str = "Page " + doc.internal.getNumberOfPages();

        doc.setFontSize(10);
        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        doc.text(str, 110, pageHeight - 3);
        doc.setLineWidth(0.1);
        doc.line(10, pageHeight - 8, 200, pageHeight - 8, "F");
      },
    });
    let fileName =
      "SOVA-Tour-Report-" + moment(new Date()).format("YYYY-MM-DD") + ".pdf";
    doc.save(fileName);
  };

  useEffect(() => {
    let payload = {
      tourId: tourId,
      start_time: start_date,
      finish_time: end_date,
    };
    ViewTourReport(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setReportData(res.data);
          const newArray = Object.keys(res)
            .filter((key) => !isNaN(key))
            .map((key) => res[key]);
          setPdfData(newArray);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    childFunc.current = generatePdf;
  }, [reportData, pdfData]);

  return (
    <div>
      <Paper mt={3}>
        <Box id="mybox" sx={{ padding: "20px 15px" }}>
          <Typography component="h3" variant="span" gutterBottom>
            Marigold Hotel
          </Typography>
          <Paper mt={3}>
            <Typography component="p" variant="span" gutterBottom>
              <strong>Tour Name:</strong> {reportData.tour_name}
            </Typography>
            <Typography component="p" variant="span" gutterBottom>
              <strong>Tour By:</strong> {reportData.user_name}
            </Typography>
            <Typography component="p" variant="span" gutterBottom>
              <strong>Tour Status:</strong> {reportData.report_status}
            </Typography>
            <Typography component="p" variant="span" gutterBottom>
              <strong>Tour Start:</strong> {reportData.start_time}
            </Typography>
            <Typography component="p" variant="span" gutterBottom>
              <strong>Tour Finish:</strong> {reportData.finish_time}
            </Typography>
            <Typography component="p" variant="span" gutterBottom>
              <strong>NIST Time:</strong> {reportData.nist_time} Hours
            </Typography>
            <Typography component="p" variant="span" gutterBottom>
              <strong>Device Time:</strong> {reportData.device_time} Hours
            </Typography>
          </Paper>
        </Box>
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              rowCount={pdfData.length}
            />
            <TableBody>
              {pdfData.map((row, index) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    <TableCell
                      component="th"
                      scope="row"
                      /* padding="none" */
                      sx={{ lg: 'whiteSpace: "nowrap"' }}
                    >
                      {row.ScanTime.split(" ")[1]}
                    </TableCell>
                    <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                      {row.TagDescription}
                    </TableCell>
                    <TableCell align="left" sx={{ width: "450px" }}>
                      {row.TourCommentText}
                    </TableCell>
                  </TableRow>
                );
              })}
              {pdfData.length < 1 && (
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    No records found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </div>
  );
}

function TourReport() {
  const childFunc = useRef(null);
  return (
    <>
      <Helmet title="Tour Report" />
      <Grid container spacing={4} justifyContent="space-between">
        <Grid item xs={12} sm={6}>
          <Typography id="myreport" variant="h3" gutterBottom display="inline">
            Tour Report
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} textAlign="right">
          <Button
            color="primary"
            variant="outlined"
            onClick={() => childFunc.current()}
          >
            Download Report
          </Button>
        </Grid>
      </Grid>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/reports/tour-patrol">
          Tour Patrol
        </Link>
        <Typography>Reports</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable childFunc={childFunc} />
        </Grid>
      </Grid>
    </>
  );
}

export default TourReport;
