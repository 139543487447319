import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { GetBagTag } from "../../api";
import { Paper, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

function BagTagPdf() {
  const [loading, setLoading] = React.useState(true); //State for loader
  const [fileBase64, setFileBase64] = React.useState(""); //State for loader

  const params = useParams();
  // Classes Styling
  const useStyles = makeStyles((theme) => ({
    fixedLoader: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "#00000059",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: "99999",
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    getBagTag();
  }, []);

  const getBagTag = () => {
    GetBagTag(params.id)
      .then((res) => {
        if (res.statusCode == 200) {
          setFileBase64(res.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      {loading === true ? (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      ) : (
        <embed
          src={"data:application/pdf;base64, " + fileBase64}
          width="100%"
          style={{ height: "100vh" }}
        />
      )}
    </React.Fragment>
  );
}

export default BagTagPdf;
