import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Formik } from "formik";
import CustomTextField from "../../../components/form-components/CustomTextField";
import {
  GetAddKeyLockNumberList,
  DeleteKeyLockNumber,
  AddNewKeyLockNumber,
} from "../../../api";
import { handleApiResonseErrors } from "../../../helper/helper";
import * as Yup from "yup";
import EditKeyLockNumber from "./EditKeyLockNumber";
import { useDispatch } from "react-redux";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../../redux/slices/equipmentSlice";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  DialogActions,
  IconButton,
  Button,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  FormControl as MuiFormControl,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
  CircularProgress,
} from "@mui/material";
import {
  CancelRounded as CancelRoundedIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const AddKeyLockNumber = ({
  addKeyLockNumber,
  addKeyLockNumberClose,
  childToParent,
}) => {
  // Action Dropdown
  const [addKeyLockAction, setAddKeyLockAction] = React.useState(null);
  const [keyLockNumberList, setKeyLockNumberList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [lockNumberId, setLockNumberId] = useState(null);
  const [close, setClose] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [singleData, setSingleData] = useState({});
  const dispatch = useDispatch();

  const addKeyLockActionClick = (event, id, data) => {
    setAddKeyLockAction(event.currentTarget);
    setLockNumberId(id);
    setSingleData(data);
  };
  const addKeyLockActionClose = () => {
    setAddKeyLockAction(null);
  };

  /* The above code is creating a state variable called editKeyCabinet and setting it to false. It is
  also creating a function called editKeyCabinetOpen that sets the state variable to true. */
  const [editKeyLockNumber, setEditKeyLockNumber] = useState(false);
  const editKeyLockNumberOpen = () => {
    setEditKeyLockNumber(true);
  };

  /**
   * This function closes the edit key LockNumber modal
   */
  const editKeyLockNumberClose = () => {
    setEditKeyLockNumber(false);
  };
  //method for open the delete dialog
  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  /**
   * It closes the delete dialog and resets the anchor element and id
   */
  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  /**
   * It closes the dialog box.
   * @param e - The event object
   */
  const closeDialogHandle = (e) => {
    setClose(e.target.value);
  };

  /**
   * This function is used to get the list of key cabinets from the database
   */
  const addKeyLockNumberList = () => {
    setLoading(true);
    GetAddKeyLockNumberList()
      .then((res) => {
        if (res.statusCode === 200) {
          setKeyLockNumberList(res.data);
          childToParent(res.data, "lock_number");
          setLoading(false);
        } else {
          setKeyLockNumberList([]);
          childToParent(res.data, "lock_number");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  /**
   * This function is used to delete a key cabinet
   */
  const deleteKeyLockNumber = () => {
    handleDeleteClose();
    DeleteKeyLockNumber(lockNumberId)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(getSnackOpen());
          dispatch(getMsg("Record has been deleted successfully"));
          dispatch(getSnackAlert("success"));
          addKeyLockNumberList();
        } else {
          dispatch(getSnackOpen());
          dispatch(getMsg("Something went wrong"));
          dispatch(getSnackAlert("error"));
        }
      })
      .catch((err) => {
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        if (error) {
          setErrMsg(error);
        }
        dispatch(getSnackClose());
      });
  };

  /* The above code is using the useEffect hook to call the addKeyCabinetList function when the component
mounts. */
  useEffect(() => {
    addKeyLockNumberList();
  }, []);

  /* Creating a validation schema for the form. */
  const validationSchema = Yup.object().shape({
    key_lock_name: Yup.string().required("Please enter key lock number"),
  });

  /* Setting the initial values for the form. */
  let initialValues = {
    key_lock_name: "",
  };

  /**
   * A function that is called when the form is submitted.
   * @param values - An object containing all values of the form.
   */
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      let formData = {
        name: values.key_lock_name,
      };
      AddNewKeyLockNumber(formData)
        .then((res) => {
          if (res.statusCode === 200) {
            dispatch(getSnackOpen());
            dispatch(getMsg("Key lock number has been submitted successfully"));
            dispatch(getSnackAlert("success"));
            if (close === "close") {
              addKeyLockNumberClose();
            }
            addKeyLockNumberList();
          } else {
            dispatch(getSnackClose());
          }
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            setErrMsg(error);
          }
          dispatch(getSnackClose());
        });
      resetForm();
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  return (
    <>
      <Dialog
        open={addKeyLockNumber}
        onClocse={addKeyLockNumberClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
            },
          },
        }}
        aria-labelledby="add-key-lock-number-title"
        aria-describedby="add-key-lock-number-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="add-key-lock-number-title"
        >
          <Typography variant="h4">Add Key lock number</Typography>
          <DialogActions>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={addKeyLockNumberClose}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              values,
              status,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={12}>
                    <FormControl mt={3}>
                      <CustomTextField
                        name="key_lock_name"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key lock number*"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Button color="primary" variant="outlined" type="submit">
                      Save & add more
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      sx={{ marginLeft: "10px" }}
                      type="submit"
                      value={"close"}
                      onClick={closeDialogHandle}
                    >
                      Save & done
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
          <Paper sx={{ marginTop: "30px" }}>
            <Dialog
              open={openDelete}
              onClose={handleDeleteClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Are you sure you want to delete key lock number?"}
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleDeleteClose} variant="contained">
                  Cancel
                </Button>
                <Button
                  onClick={deleteKeyLockNumber}
                  autoFocus
                  variant="contained"
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>
            <Typography variant="h4" component="div" gutterBottom>
              Existing Key Lock Number
            </Typography>
            <TableContainer className="tableContainer">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                {loading ? (
                  <TableCell colSpan={13} align="center" padding="none">
                    <CircularProgress size={40} />
                  </TableCell>
                ) : (
                  <TableBody>
                    {keyLockNumberList.map((data) => {
                      return (
                        <TableRow hover>
                          <TableCell>{data.name}</TableCell>
                          <TableCell padding="none" align="right">
                            <IconButton
                              aria-label="Settings"
                              size="large"
                              aria-owns={
                                addKeyLockAction
                                  ? "add-key-lock-action"
                                  : undefined
                              }
                              aria-haspopup="true"
                              onClick={(e) =>
                                addKeyLockActionClick(e, data.id, data)
                              }
                              style={{ color: "#1374C5" }}
                            >
                              <SettingsIcon />
                            </IconButton>
                            <StyledMenu
                              id="add-key-lock-action"
                              anchorEl={addKeyLockAction}
                              open={Boolean(addKeyLockAction)}
                              onClose={addKeyLockActionClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <MenuItem
                                onClick={() => {
                                  editKeyLockNumberOpen();
                                  addKeyLockActionClose();
                                }}
                              >
                                <ListItemIcon>
                                  <EditIcon />
                                </ListItemIcon>
                                <ListItemText primary="Edit" pl={1} />
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  handleDeleteOpen();
                                  addKeyLockActionClose();
                                }}
                              >
                                <ListItemIcon>
                                  <DeleteIcon />
                                </ListItemIcon>
                                <ListItemText primary="Delete" pl={1} />
                              </MenuItem>
                            </StyledMenu>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {keyLockNumberList.length === 0 && (
                      <TableRow>
                        <TableCell align="center" colSpan={10}>
                          No records found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={addKeyLockNumberClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {editKeyLockNumber && (
        <EditKeyLockNumber
          editKeyLockNumber={editKeyLockNumber}
          editKeyLockNumberClose={editKeyLockNumberClose}
          setEditKeyLockNumber={setEditKeyLockNumber}
          editKeyLockNumberOpen={editKeyLockNumberOpen}
          singleData={singleData}
          addKeyLockNumberList={addKeyLockNumberList}
          lockNumberId={lockNumberId}
        />
      )}
    </>
  );
};

export default AddKeyLockNumber;
