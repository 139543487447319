import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { MatchLostFoundList } from "../../api";
import MatchLostfoundAction from "./inner-component/MatchLostfoundAction";
import RuleSet from "../../components/modals/lostandfound/RuleSet";
import CustomAlert from "../../components/form-components/CustomAlert";
import { makeStyles } from "@mui/styles";
import MatchLostFoundListCol from "../table-columns-json/lostfound/MatchLostFound";
import {
  Grid,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  Box,
  FormControlLabel,
  Switch,
  CircularProgress,
  ListItemIcon,
  TableSortLabel,
  Button,
  IconButton,
} from "@mui/material";
import {
  MergeType as MergeTypeIcon,
  CancelRounded as CancelRoundedIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
const Paper = styled(MuiPaper)(spacing);

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const { numSelected, mergedRecordId, setMergedRecordId } = props;

  return (
    <>
      {mergedRecordId && (
        <Box
          sx={{
            backgroundColor: "#c3fdc5",
            padding: "7px 15px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="subtitle"
            sx={{ color: "green", fontWeight: "500" }}
          >
            The selected records have been successfully merged. You can verify
            by opening the remaining record by{" "}
            <Link
              variant="text"
              to={"/lostandfound/edit-lost-found/" + mergedRecordId}
              style={{
                textDecoration: "none",
              }}
            >
              clicking here
            </Link>
          </Typography>
          <IconButton onClick={() => setMergedRecordId(null)}>
            <CancelRoundedIcon
              sx={{ color: "green", backgroundColor: "#c3fdc5" }}
            />
          </IconButton>
        </Box>
      )}
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          {numSelected > 0 ? (
            <Typography
              color="inherit"
              variant="subtitle1"
              mt={3}
              style={{ width: "max-content", marginLeft: "15px" }}
            >
              {numSelected} records found
            </Typography>
          ) : (
            <Typography variant="h6" mt={3} id="tableTitle">
              {/* Nutrition */}
            </Typography>
          )}
        </Box>
        <Box>
          <Typography
            color="inherit"
            // variant="subtitle1"
            mt={3}
            style={{
              width: "max-content",
              marginRight: "15px",
            }}
          >
            Showing records having matched point value greater than 2
          </Typography>
        </Box>
      </Toolbar>
    </>
  );
};
function EnhancedTable(props) {
  const [dense, setDense] = React.useState(true);

  const [lists, setLists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showRuleSet, setShowRuleSet] = useState(false);

  // Alert Message
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("success");
  const [showAlert, setShowAlert] = React.useState(false);

  // states for sorting
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");

  // state for mergeRecordId
  const [mergedRecordId, setMergedRecordId] = useState(null);

  // Classes Styling
  const useStyles = makeStyles((theme) => ({
    fixedLoader: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "#00000059",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: "99999",
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    getList();
  }, []);

  const getList = () => {
    setLoading(true);
    let payload = {
      location_id: 9,
    };
    MatchLostFoundList(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setLists(res.data);
        } else {
          setLists([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const showAlertPopup = (msg, type) => {
    setAlertMessage(msg);
    setAlertType(type);
    setShowAlert(true);
  };

  // functions for handling sorting
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <div>
      <Paper mt={3}>
        <EnhancedTableToolbar
          numSelected={lists.length}
          mergedRecordId={mergedRecordId}
          setMergedRecordId={setMergedRecordId}
        />
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                {MatchLostFoundListCol.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? "left" : "left"}
                    sx={{ whiteSpace: "nowrap" }}
                    padding={headCell.disablePadding ? "none" : "normal"}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={
                        headCell.id == "action"
                          ? null
                          : () => handleRequestSort(headCell.id)
                      }
                      hideSortIcon={headCell.id == "action" ? true : false}
                    >
                      {headCell.label}
                      {headCell.id == "match" ? (
                        <ListItemIcon
                          sx={{ minWidth: "fit-content" }}
                          onClick={() => setShowRuleSet(true)}
                        >
                          <MergeTypeIcon />
                        </ListItemIcon>
                      ) : (
                        ""
                      )}
                      {headCell.icon}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    <CircularProgress />
                  </TableCell>
                  {/* collapse null column to resolve console errors */}
                  <TableCell colSpan={5} />
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {stableSort(lists, getComparator(order, orderBy)).map(
                  (row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow hover key={row.recordKey}>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          sx={{ lg: 'whiteSpace: "nowrap"' }}
                        >
                          <Link
                            to={"/lostandfound/edit-lost-found/" + row.lost_id}
                            style={{ textDecoration: "none" }}
                          >
                            {row.lost_id}
                          </Link>
                        </TableCell>
                        <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                          <Link
                            to={"/lostandfound/edit-lost-found/" + row.found_id}
                            style={{ textDecoration: "none" }}
                          >
                            {row.found_id}
                          </Link>
                        </TableCell>
                        <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                          {row.point}
                        </TableCell>
                        <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                          {row.match}
                        </TableCell>
                        <TableCell align="left">
                          <MatchLostfoundAction
                            data={row}
                            reloadList={() => getList()}
                            showAlertPopup={(msg, type) =>
                              showAlertPopup(msg, type)
                            }
                            setShowLoader={(status) => setShowLoader(status)}
                            setMergedRecordId={setMergedRecordId}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
                {lists.length < 1 && (
                  <TableRow style={{ height: (dense ? 6 : 0) * lists.length }}>
                    <TableCell colSpan={5} align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
      {showRuleSet && (
        <RuleSet
          show={showRuleSet}
          handleModal={(status) => setShowRuleSet(status)}
        />
      )}
      {showAlert && (
        <CustomAlert
          message={alertMessage}
          severity={alertType}
          closeAlert={(show) => setShowAlert(show)}
          showAlert={showAlert}
        />
      )}
      {showLoader && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </div>
  );
}

function MatchLostFound() {
  return (
    <React.Fragment>
      <Helmet title="Lost and Found Matching" />
      <Typography variant="h3" gutterBottom display="inline">
        Lost and Found Matching
      </Typography>

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default MatchLostFound;
