import * as Yup from "yup";
import moment from "moment";
import { Formik } from "formik";
import { makeStyles } from "@mui/styles";
import { Helmet } from "react-helmet-async";
import styled from "styled-components/macro";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import CustomAlert from "../../components/form-components/CustomAlert";
import CustomSelect from "../../components/form-components/CustomSelect";
import { updateShowAlert, updateText } from "../../redux/slices/mainSlice";
import { getFileBase64, handleApiResonseErrors } from "../../helper/helper";
import CustomTextField from "../../components/form-components/CustomTextField";
import { PositionByDepartment, DepartmentList, SaveEmployee } from "../../api";
import { DateTimePicker } from "@mui/lab";
import {
  Avatar,
  Divider as MuiDivider,
  Typography,
  FormControl as MuiFormControl,
  Breadcrumbs as MuiBreadcrumbs,
  Link,
  Grid,
  Card,
  CardContent,
  Button,
  Box,
  Paper,
  CircularProgress,
} from "@mui/material";
import { spacing } from "@mui/system";
import { DatePicker } from "@mui/lab";
import CameraFeeding from "../../helper/camera-feeding";
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 20px 0 15px ${(props) => props.theme.spacing(2)};
`;

// Classes Styling
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));

function AddEmployeeForm(props) {
  const [departmentList, setDepartmentList] = useState([]);
  const [positionList, setPositionList] = useState([]);
  const [preLoader, setPreLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  // Alert Message
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");

  const classes = useStyles();
  const uploadedFileRef = useRef();
  const navigate = useNavigate();
  const [showWebCam, setShowWebCam] = React.useState(false);
  // Redux
  const showAlert = useSelector((state) => state.main.showAlert);
  const dispatch = useDispatch();

  const Image = styled.img`
    max-width: initial;
    margin: 20px auto 0;
    width: 100%;
  `;
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validationSchema = Yup.object().shape({
    employee_id: Yup.number()
      .required("Please enter Barcode ID")
      .positive("Please enter a valid Barcode ID.")
      .typeError("Please enter a valid Barcode ID."),
    other_employee: Yup.number()
      .nullable()
      .positive("Please enter a valid Employee ID.")
      .typeError("Please enter a valid Employee ID."),
    fname: Yup.string().required("Please enter first name."),
    lname: Yup.string().required("Please enter last name."),
    department: Yup.number().required("Please select department."),
    position: Yup.number().required("Please select position."),
    phone: Yup.string()
      .nullable()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Phone must be at least 10 characters")
      .max(15, "Phone must be at most 15 characters"),
    badge_issue_date: Yup.string()
      .nullable()
      .required("Please select the date"),
  });

  let initialValues = {
    employee_id: "",
    other_employee: "",
    fname: "",
    lname: "",
    department: "",
    position: "",
    badge_issue_date: null,
    phone: "",
    note: "",
  };

  useEffect(() => {
    getDepartmentList();
  }, []);

  const getDepartmentList = () => {
    DepartmentList()
      .then((res) => {
        setDepartmentList(res.data);
        setPreLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setPreLoader(false);
      });
  };

  const getPositionList = (value) => {
    setLoading(true);
    PositionByDepartment(value)
      .then((res) => {
        if (res.statusCode === 200) {
          setPositionList(res.data);
        } else {
          setPositionList([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleShowWebCam = () => {
    let changeSetShowWebCam = !showWebCam;
    setShowWebCam(changeSetShowWebCam);
  };
  const handleFileChange = (evt) => {
    let file = evt.target.files[0];
    getFileBase64(file, (result) => {
      setUploadedImage(result);
    });
  };

  const clearSelectedImage = () => {
    setUploadedImage(null);
    uploadedFileRef.current.value = "";
  };
  const messageAlert = (msg, type) => {
    setLoading(false);
    setAlertMessage(msg);
    setAlertType(type);
    dispatch(updateShowAlert(true));
  };

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoading(true);
      let date = values.badge_issue_date
        ? moment(values.badge_issue_date).format("YYYY-MM-DD")
        : "";

      let formData = {
        employee_id: values.employee_id,
        other_employee: values.other_employee,
        fname: values.fname,
        lname: values.lname,
        department: values.department,
        position: values.position,
        phone: values.phone,
        note: values.note,
        badge_issue_date: date,
        image: uploadedImage,
      };
      SaveEmployee(formData)
        .then((res) => {
          if (res.statusCode === 200) {
            dispatch(updateShowAlert(true));
            dispatch(updateText("Record has been submitted successfully"));
            navigate({ pathname: "/logs/employees" });
          } else {
            messageAlert("Sorry! something wrong please try again", "error");
          }
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            messageAlert(error, "error");
          }
        });
    } catch (error) {
      messageAlert("Sorry! something wrong please try again", "error");
    }
  };

  return (
    <>
      <Card mt={6}>
        <CardContent>
          {preLoader === true ? (
            <Paper className={classes.fixedLoader}>
              <CircularProgress />
            </Paper>
          ) : (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, values, setFieldValue, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={6}>
                    <Grid item xs={12} md={6}>
                      <FormControl mt={3}>
                        <CustomTextField
                          name="employee_id"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          label="Barcode ID*"
                          autoComplete="off"
                          placeholder="Type or scan existing barcode"
                        />
                      </FormControl>
                      <FormControl mt={6}>
                        <CustomTextField
                          name="other_employee"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          label="Employee ID"
                          autoComplete="off"
                          placeholder="Global/corporate ID"
                        />
                      </FormControl>
                      <FormControl mt={6}>
                        <CustomTextField
                          name="fname"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          label="First name*"
                          autoComplete="off"
                          placeholder="John"
                        />
                      </FormControl>
                      <FormControl mt={6}>
                        <CustomTextField
                          name="lname"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          label="Last name*"
                          autoComplete="off"
                          placeholder="Smith"
                        />
                      </FormControl>
                      <FormControl mt={6}>
                        <CustomSelect
                          name="department"
                          fieldLabel="Department*"
                          options={departmentList}
                          displayEmpty
                          customCallbackMethod={(evt) => {
                            if (evt.target.value) {
                              setFieldValue("position", "");
                              getPositionList(evt.target.value);
                            }
                          }}
                          firstOptionTitle="Select Department"
                        />
                      </FormControl>
                      <FormControl mt={6}>
                        <CustomSelect
                          name="position"
                          fieldLabel="Position*"
                          options={positionList}
                          displayEmpty
                          firstOptionTitle={
                            values.department
                              ? "Select Position"
                              : "Select Dept first"
                          }
                        />
                      </FormControl>
                      <FormControl mt={6}>
                        <DateTimePicker
                          id="date-range"
                          label="Badge issue date*"
                          value={values.badge_issue_date}
                          onChange={(newValue) => {
                            setFieldValue("badge_issue_date", newValue);
                          }}
                          inputFormat="MM/dd/yyyy HH:mm"
                          renderInput={(params) => (
                            <CustomTextField
                              {...params}
                              name="badge_issue_date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "mm/dd/yyyy H:i:s",
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl>
                        {uploadedImage ? (
                          <BigAvatar alt="image name" src={uploadedImage} />
                        ) : (
                          <BigAvatar
                            alt="image name"
                            src="/static/img/avatars/profile-upload.png"
                          />
                        )}

                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            JustifyContent: "space-between",
                            margin: "10px 0 15px",
                            gap: "15px",
                          }}
                        >
                          <Button
                            variant="outlined"
                            color="primary"
                            component="span"
                            onClick={(e) => clearSelectedImage(e)}
                          >
                            Clear
                          </Button>
                          <Button
                            variant="outlined"
                            color="warning"
                            component="span"
                            onClick={() => handleShowWebCam()}
                          >
                            {showWebCam ? "Close WebCam" : "WebCam"}
                          </Button>
                        </Box>
                        <input
                          accept="image/*"
                          style={{ display: "none" }}
                          id="raised-button-file"
                          multiple
                          type="file"
                          ref={uploadedFileRef}
                          onChange={(e) => handleFileChange(e)}
                        />
                        <label htmlFor="raised-button-file">
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                          >
                            Choose image
                          </Button>
                        </label>
                      </FormControl>
                      <FormControl mt={6}>
                        <CustomTextField
                          name="phone"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          label="Phone"
                          autoComplete="off"
                          placeholder="xxx-xxx-xxxx"
                          inputProps={{ maxLength: 20 }}
                        />
                      </FormControl>
                      <FormControl mt={6}>
                        <CustomTextField
                          name="note"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          label="User notes"
                          autoComplete="off"
                          placeholder=""
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={12} md={12}>
                      <Button type="submit" color="primary" variant="contained">
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          )}
        </CardContent>
      </Card>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
      {showAlert && (
        <CustomAlert
          message={alertMessage}
          severity={alertType}
          closeAlert={(show) => dispatch(updateShowAlert(show))}
          showAlert={showAlert}
        />
      )}
      {showWebCam && (
        <CameraFeeding
          showWebCam={showWebCam}
          closeWabCam={() => setShowWebCam(false)}
          // value={uploadedImage}
          // onChange={handleFileChange}
          setUploadedImage={setUploadedImage}
          uploadedImage={uploadedImage}
        />
      )}
    </>
  );
}
function AddEmployee() {
  return (
    <React.Fragment>
      <Helmet title="Add Employee" />
      <Typography variant="h3" gutterBottom display="inline">
        Add Employee
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/logs/employees">
          Employees
        </Link>
        <Typography>Add Employee</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <AddEmployeeForm />
    </React.Fragment>
  );
}

export default AddEmployee;
