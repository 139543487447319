import React, { useState, useEffect, useReducer } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
// import ClassificationTest from "./inner-components/ClassificationDoc";
import EmailComment from "./inner-components/EmailComment";
import ChangeSubmitStatus from "./inner-components/ChangeSubmitStatus";
import PeekModal from "./inner-components/PeekModal";
import AddAppendedNarrative from "./inner-components/AddAppendedNarrative";
import SendNor from "./inner-components/SendNor";
import { updateShowAlert } from "../../redux/slices/mainSlice";
import AddComLogModal from "../../components/modals/lostandfound/AddComLogModal";
import CommunicationLogModal from "../../components/modals/lostandfound/CommunicationLogModal";
import RequireReview from "./inner-components/RequireReview";
import SendIrToRepondingPoliceOfficer from "./inner-components/SendIrToRepondingPoliceOfficer";
import IncidentMediaStatus from "./inner-components/IncidentMediaStatus";
import ChangeIcc from "./inner-components/ChangeIcc";
import TaskList from "./task-list/TaskList";
import IrTask from "./inner-components/IrTask";
import { CSVLink } from "react-csv";
import { format } from "date-fns";
import IncidentListCol from "../table-columns-json/reports/IncidentCol";
import { setIncidentList } from "../../redux/slices/report/reportTableColSlice";
import StyledColumnOptions from "../../helper/StyledColumnOptions";
import {
  Checkbox,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  /* FormGroup, */
  TableContainer,
  InputAdornment,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  Radio,
  CardContent,
  FormLabel,
  RadioGroup,
  Card,
  Box,
  Button,
  /* List,
  ListItem, */
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  Chip,
  List,
  ListItem,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Dialog,
  Tooltip,
  CardHeader,
  Pagination,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { DatePicker } from "@mui/lab";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  AddBox as AddBoxIcon,
  Error as ErrorIcon,
  InsertDriveFile as InsertDriveFileIcon,
  Visibility as VisibilityIcon,
  RateReview as RateReviewIcon,
  Streetview as StreetviewIcon,
  ExitToApp as ExitToAppIcon,
  Assignment as AssignmentIcon,
  TrendingUp as TrendingUpIcon,
  Edit as EditIcon,
  Settings as SettingsIcon,
  Search as SearchIcon,
  ExpandMore as ExpandMoreIcon,
  Send as SendIcon,
  Cancel as CancelIcon,
  PictureAsPdf as PictureAsPdfIcon,
  Launch as LaunchIcon,
  Star as StarIcon,
  CameraAlt as CameraAltIcon,
  AddToPhotos as AddToPhotosIcon,
  MobileFriendly as MobileFriendlyIcon,
  Directions as DirectionsIcon,
  Warning as WarningIcon,
  StarRate as StarRateIcon,
  ListAlt as ListAltIcon,
} from "@mui/icons-material";
import { ArrowRightCircle } from "react-feather";
import { spacing } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import {
  incidentsList,
  getSnackClose,
  getSnackOpen,
  getSnackMsg,
  getSnackAlert,
  changeDisabledStatus,
} from "../../redux/slices/reportSlice";
import {
  IncidentsReportList,
  DeleteIncident,
  ReportUpdateStatus,
  IncidentTypeListDropdown,
  DownloadReportIncident,
} from "../../api";
import CustomDateRange from "../../components/form-components/CustomDateRange";
import moment from "moment";
import Toaster from "../tasks/Toaster";
// import IncidentCol from "../table-columns-json/reports/IncidentCol";
/* const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing); */

// Classes Styling
const useStyles = makeStyles((theme) => ({
  dateRangeDropdown: {
    position: "absolute",
    left: 0,
    top: "100%",
    width: "100%",
    zIndex: 2,
    marginLeft: "0 !important",
    maxHeight: "200px",
    overflowY: "auto",

    "& .MuiBox-root": {
      padding: "15px",
      "& .MuiButton-contained": {
        marginLeft: "15px",
      },
    },
    "& .MuiList-root": {
      padding: 0,
      "& .MuiListItem-root:hover": {
        backgroundColor: "#376fd012",
      },
    },
  },
  DateList: {
    border: "1px solid #0000001c",
    marginTop: "22px",
    padding: 0,
    "& .MuiListItem-root": {
      borderBottom: "1px solid #0000001c",
    },
    "& .MuiListItem-root:last-child": {
      borderBottom: 0,
    },
    "& .MuiListItem-root:hover": {
      backgroundColor: "#376fd012",
    },
  },
  ErrorImage: {
    width: "250px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "15px",
    marginBottom: "15px",
  },
  fixedLoader: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));

const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
  padding-left: 6px;
  padding-right: 6px;
  margin-left: -4px;
`;
// Image Wrapper
const ImageWrapper = styled.div`
  margin-top: 10px;
`;
const Image = styled.img`
  width: 100%;
`;
const StyledTooltip = ({ title, children, ...props }) => (
  <Tooltip
    {...props}
    arrow
    title={title}
    placement="bottom-end"
    /* arrow */
    componentsProps={{
      tooltip: {
        sx: {
          padding: 0,
          background: "transparent",
          "& .MuiCardContent-root": {
            paddingTop: 0,
          },
          "& .MuiTooltip-arrow": {
            color: "rgb(210 210 210)",
          },
        },
      },
    }}
  >
    {children}
  </Tooltip>
);
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledForm = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    marginLeft: "75px",
    marginTop: "8px",
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      maxWidth: "435px",
      width: "100%",
      "& form": {
        padding: "15px 20px",
      },
      "& .MuiMenuItem-root": {
        padding: "0",
        display: "block",
        "& .MuiFormControl-root": {
          display: "block",
          width: "100%",
          marginBottom: "20px",
        },
      },
      "& .MuiMenuItem-root:hover": {
        background: "transparent",
      },
      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "140px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
function createData(
  number,
  classification,
  datetime,
  disposition,
  location,
  status,
  author,
  modified,
  action
) {
  return {
    number,
    classification,
    datetime,
    disposition,
    location,
    status,
    author,
    modified,
    action,
  };
}
const rows = [
  createData(
    "22-1133-06-12-2022 (VO)",
    0,
    0,
    "Open investigation pending",
    "Regency guest room",
    0,
    "Michael Hoffman",
    0,
    0
  ),
  createData(
    "23-1133-06-12-2022 (VO)",
    0,
    0,
    "Open investigation pending two",
    "Regency guest room",
    0,
    "Anees Hashmi",
    0,
    0
  ),
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  {
    id: "IncidentReportName",
    numeric: true,
    disablePadding: true,
    label: "Number",
  },
  {
    id: "Classification",
    numeric: true,
    disablePadding: false,
    label: "Classification",
  },
  {
    id: "datetime",
    numeric: true,
    disablePadding: false,
    label: "Datetime",
  },
  {
    id: "disposition",
    numeric: true,
    disablePadding: false,
    label: "Disposition",
  },
  {
    id: "location",
    numeric: true,
    disablePadding: false,
    label: "Location",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "author",
    numeric: true,
    disablePadding: false,
    label: "Author",
  },
  {
    id: "modified",
    numeric: true,
    disablePadding: false,
    label: "Modified",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
];
const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    tableCalRedux,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all data" }}
          />
        </TableCell>
        {IncidentListCol.map((headCell) => (
          <>
            {headCell.id === "status" ? (
              <StyledTooltip
                title={
                  <Card>
                    <CardHeader title="Approval Status of IR" />
                    <CardContent>
                      <List>
                        <ListItem>
                          <ListItemIcon>
                            <MobileFriendlyIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText
                            primary="Mobile phone = new report created via
                    mobile"
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <LaunchIcon color="disabled" />
                          </ListItemIcon>
                          <ListItemText
                            primary="Gray square with arrow = new
                    report created via web"
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <ArrowRightCircle color="green" />
                          </ListItemIcon>
                          <ListItemText
                            primary="Green circle with
                    right arrow = completed report submitted for review"
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <WarningIcon color="warning" />
                          </ListItemIcon>
                          <ListItemText
                            primary="Orange triangle = report
                    returned to author for edits"
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <StarRateIcon color="warning" />
                          </ListItemIcon>
                          <ListItemText
                            primary="Yellow star = report
                    accepted"
                          />
                        </ListItem>
                      </List>
                    </CardContent>
                  </Card>
                }
                placement="left"
              >
                <TableCell
                  key={headCell.id}
                  align="left"
                  sx={{ whiteSpace: "nowrap" }}
                  // padding={headCell.disablePadding ? "none" : "normal"}
                  sortDirection={orderBy === headCell.id ? order : false}
                  // className={
                  //   tableCalRedux[headCell.id] === true ? "" : "hide-cell"
                  // }
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={
                      headCell.id == "status" || headCell.id == "Action"
                        ? null
                        : createSortHandler(headCell.id)
                    }
                    hideSortIcon={
                      headCell.id == "status" || headCell.id == "Action"
                        ? true
                        : false
                    }
                  >
                    {headCell.label}
                    {headCell.label === "Status" ? (
                      <ErrorIcon fontSize="small" color="primary" />
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              </StyledTooltip>
            ) : (
              <TableCell
                key={headCell.id}
                align="left"
                sx={{ whiteSpace: "nowrap" }}
                //padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
                // className={
                //   tableCalRedux[headCell.id] === true ? "" : "hide-cell"
                // }
                className={
                  tableCalRedux[headCell.id] === true ? "" : "hide-cell"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={
                    headCell.id == "status" || headCell.id == "Action"
                      ? null
                      : createSortHandler(headCell.id)
                  }
                  hideSortIcon={
                    headCell.id == "status" || headCell.id == "Action"
                      ? true
                      : false
                  }
                >
                  {headCell.label}
                  {headCell.label === "Status" ? (
                    <ErrorIcon fontSize="small" />
                  ) : null}
                  {headCell.icon}
                </TableSortLabel>
              </TableCell>
            )}
          </>
        ))}
      </TableRow>
    </TableHead>
  );
};
const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const {
    numSelected,
    totalRecord,
    filterRecord,
    setDataToFilter,
    setCurrentPage,
    incidentList,
    tableCalRedux,
    defalutTableCalRedux,
    handleDeleteOpen,
    setMessage,
    setIsDelete,
    statusCount,
    setStatusCount,
    isFilter,
    setIsFilter,
    rowsPerPage,
    order,
    orderBy,
    dataToFilter,
  } = props;
  const [actions, setActions] = useState("");
  //States for filter
  const [startDateToFilter, setStartDateToFilter] = useState("");
  const [endDateToFilter, setEndDateToFilter] = useState("");
  const [fadebox, setFadedBox] = useState(false);
  const [quaterValue, setQuaterValue] = useState("");
  const [locationValue, setLocationValue] = useState("");
  const [narrativeValue, setNarrativeValue] = useState("");
  const [involvedPersonValue, setInvolvedPersonValue] = useState("");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  // Incident Status
  const [incidentStatus, setIncidentStatus] = useState("");
  const selectIncidentStatus = (event) => {
    setIncidentStatus(event.target.value);
  };
  const [officerValue, setOfficerValue] = useState("");
  // Police Contacted
  const [policeContacted, setPoliceContacted] = useState("");
  const selectPoliceContacted = (event) => {
    setPoliceContacted(event.target.value);
  };
  // Ambulance Called
  const [ambulanceCalled, setAmbulanceCalled] = useState("");
  const selectAmbulanceCalled = (event) => {
    setAmbulanceCalled(event.target.value);
  };
  // Fire Department Called
  const [fireDepartmentCalled, setFireDepartmentCalled] = useState("");
  const selectFireDepartmentCalled = (event) => {
    setFireDepartmentCalled(event.target.value);
  };
  // Anyone Hospitalized
  const [anyoneHospitalized, setAnyoneHospitalized] = useState("");
  const selectAnyoneHospitalized = (event) => {
    setAnyoneHospitalized(event.target.value);
  };
  // Claims Adjuster Contacted
  const [adjusterContacted, setAdjusterContacted] = useState("");
  const selectAdjusterContacted = (event) => {
    setAdjusterContacted(event.target.value);
  };
  // Has Media Attached
  const [hasMediaAttached, setHasMediaAttached] = useState("");
  const selectHasMediaAttached = (event) => {
    setHasMediaAttached(event.target.value);
  };
  const [vehicleValue, setVehicleValue] = useState("");
  const [rowValue, setRowValue] = useState("");
  const [submitStatusValue, setSubmitStatusValue] = useState([]);
  //Handler for checked unchecked
  const handleChecked = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSubmitStatusValue([...submitStatusValue, value]);
    } else {
      setSubmitStatusValue(submitStatusValue.filter((e) => e !== value));
    }
  };
  const [searchInput, setSearchInput] = useState("");

  // const [irSelected, setIrSelected] = useState([]);

  // const handleIrSelect = (e) => {
  //   const { value, checked } = e.target;
  //   console.log("++++", value);
  //   if (checked) {
  //     setIrSelected([...irSelected, value]);
  //   } else {
  //     setIrSelected(irSelected.filter((e) => e !== value));
  //   }
  // };

  const initialState = { selectedOptions: [] };
  function reducer(state, action) {
    switch (action.type) {
      case "SET_SELECTED_OPTIONS":
        return { selectedOptions: action.payload.options };

      case "REMOVE_OPTION":
        return {
          selectedOptions: state.selectedOptions.filter(
            (option) => option.IncidentTypeID !== action.payload.IncidentTypeID
          ),
        };
      default:
        throw new Error();
    }
  }
  const [state, dispatch1] = useReducer(reducer, initialState);
  const handleChangeAuto = (event, values) => {
    console.log("++++", values);
    dispatch1({ type: "SET_SELECTED_OPTIONS", payload: { options: values } });
  };

  const removeOption = (id) => {
    dispatch1({ type: "REMOVE_OPTION", payload: { IncidentTypeID: id } });
  };
  console.log("State---------", state.selectedOptions);

  const handleChange = (event) => {
    setActions(event.target.value);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [incidentCSV, setincidentCSV] = useState([]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    let payload = {
      limit: totalRecord,
    };
    IncidentsReportList(1, payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setincidentCSV(res.data);
        } else {
          setincidentCSV([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [ir, setIr] = useState([]);
  useEffect(() => {
    let payload = {
      limit: totalRecord,
    };
    IncidentTypeListDropdown()
      .then((res) => {
        if (res.statusCode == 200) {
          setIr(res.data);
        } else {
          setIr([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [type, setType] = useState([]);
  const handleClose = () => {
    setAnchorEl(null);
  };
  // Search Dropdown
  const [anchorForm, setAnchorForm] = useState(null);
  const handleClickDropdown = (event) => {
    setAnchorForm(event.currentTarget);
  };

  const handleCloseForm = () => {
    setAnchorForm(null);
  };
  const [value, setValue] = useState(null);
  // Select Result
  const [result, setResult] = useState("");
  const selectResult = (event) => {
    setResult(event.target.value);
  };

  // Columns Dropdown
  const [anchorColumn, setAnchorColumn] = useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };
  //Scan Visitor ID modal
  const [open, visitorModal] = useState(false);
  // const scanVisitorModalOpen = () => {
  //   visitorModal(true);
  // };
  // const scanVisitorModalClose = () => {
  //   visitorModal(false);
  // };
  // const [fadebox, setFadedBox] = useState(false);
  const classes = useStyles();

  // Person involved
  const [personInvolved, setPersonInvolved] = useState("");
  const selectPersonInvolved = (event) => {
    setPersonInvolved(event.target.value);
  };

  //Method for search filter
  const applySearch = () => {
    const payload = {
      start_incident_date: startDateToFilter
        ? moment(startDateToFilter).format("YYYY-MM-DD")
        : "",
      end_incident_date: endDateToFilter
        ? moment(endDateToFilter).format("YYYY-MM-DD")
        : "",
      quarter: quaterValue,
      location: locationValue,
      Description: narrativeValue,
      name: involvedPersonValue,
      type: type ? type.map((data) => data.value) : null,
      ir_status: incidentStatus,
      status: submitStatusValue,
      reporting_officer: officerValue,
      VicHosp: policeContacted,
      ambulance_called: ambulanceCalled,
      fire_department: fireDepartmentCalled,
      anyone_hospitalized: anyoneHospitalized,
      claims_adj_contacted: adjusterContacted,
      has_media_attached: hasMediaAttached,
      license_plate: vehicleValue,
      limit: rowValue,
      search: searchInput,
    };
    filterRecord(payload);
  };

  //function for reseting the filter
  const resetFilter = () => {
    setStartDateToFilter("");
    setEndDateToFilter("");
    setQuaterValue("");
    setLocationValue("");
    setNarrativeValue("");
    setInvolvedPersonValue("");
    //type([]);
    setIncidentStatus("");
    setSubmitStatusValue([]);
    setOfficerValue("");
    setPoliceContacted("");
    setAmbulanceCalled("");
    setFireDepartmentCalled("");
    setAnyoneHospitalized("");
    setAdjusterContacted("");
    setHasMediaAttached("");
    setVehicleValue("");
    setRowValue("");
    setSearchInput("");
    filterRecord({});
  };

  const incidentType = [
    { label: "Open Investigation Pending", value: "Open" },
    { label: "Open Under Investigation", value: "Open-Investigation" },
    { label: "Open Claim Pending", value: "Open-Claim-Pending" },
    { label: "Open Claim Active", value: "Open-Claims" },
    { label: "Closed Claim Complete", value: "Closed-Claims" },
    { label: "Closed Unfounded", value: "Closed-Item Found" },
    { label: "Closed", value: "Closed" },
  ];
  const checkArray = [
    { key: "New", value: "N" },
    { key: "Submitted", value: "S" },
    { key: "Returned", value: "R" },
    { key: "Accepted", value: "A" },
  ];

  //Array for Ir classification section auto-complete dropdown

  const optionArray = [
    {
      IncidentTypeID: 1,
      full_type: "Hyatt Grand Central New York",
    },
    {
      IncidentTypeID: 2,
      full_type: "Hyatt Olive 8",
    },
    {
      IncidentTypeID: 3,
      full_type: "Hyatt Regency Baltimore",
    },
    {
      IncidentTypeID: 4,
      full_type: "Hyatt Regency Boston",
    },
    {
      IncidentTypeID: 5,
      full_type: "Hyatt Regency Calgary",
    },
    {
      IncidentTypeID: 6,
      full_type: "Hyatt Regency Chicago",
    },
    {
      IncidentTypeID: 7,
      full_type: "Hyatt Regency Chicago Hskpg",
    },
    {
      IncidentTypeID: 8,
      full_type: "Hyatt Regency Clearwater Beach Resort and Spa",
    },
    {
      IncidentTypeID: 9,
      full_type: "Hyatt Regency Coconut Point hskpg",
    },
    {
      IncidentTypeID: 10,
      full_type: "Hyatt Regency Coconut Point Resort and Spa",
    },
  ];
  // IR Tasks
  const [irTasks, setIrTasks] = useState(false);
  const irTasksOpen = () => {
    setIrTasks(true);
  };
  const irTasksClose = () => {
    setIrTasks(false);
  };

  let headers = [
    { label: "Number", key: "Number" },
    { label: "Classification", key: "Classification" },
    { label: "Datetime", key: "Datetime" },
    { label: "Disposition", key: "ir_status" },
    { label: "Location", key: "IncidentLocation" },
    { label: "Status", key: "status" },
    { label: "Author", key: "user.UserName" },
    { label: "Modified", key: "LastModified" },
  ];

  const onButtonClick = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
      sorting: {
        order: order,
        by: orderBy,
      },
      ...dataToFilter,
      statusCount: statusCount,
    };
    DownloadReportIncident(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          const linkSource = `data:application/pdf;base64,${res.data}`;
          const downloadLink = document.createElement("a");
          const fileName = "SOVA-INCIDENT-SUMMARY.pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          setLoading(false);
        } else {
          console.log("something went wrong");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  return (
    <Toolbar>
      <div>
        <Paper mt={3}>
          <FormControl mr={2} sx={{ minWidth: 120 }}>
            <InputLabel id="demo-simple-select-label">Actions</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={actions}
              label="Actions"
              MenuProps={{
                PaperProps: {
                  sx: {
                    "& .MuiMenu-list": {
                      boxShadow:
                        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 10%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
                      margin: "0px 3px 5px 3px",
                      padding: "0",
                    },
                  },
                },
              }}
              onChange={handleChange}
            >
              <MenuItem
                value={"Delete"}
                onClick={() => {
                  handleDeleteOpen();
                  setMessage("Are you sure you want to delete incident?");
                  setIsDelete(true);
                }}
              >
                Delete
              </MenuItem>
            </Select>
          </FormControl>
        </Paper>
      </div>
      <div className="totalRecord">
        {totalRecord > 0 ? (
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content" }}
          >
            {totalRecord} records found
          </Typography>
        ) : (
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content" }}
          >
            {totalRecord} record found
          </Typography>
        )}
      </div>
      <Spacer />
      <div className="searchTool">
        <Box
          mt={3}
          sx={{
            display: "flex",
            flexWrap: "nowrap",
          }}
        >
          <Item sx={{ flexGrow: 1, width: "100%" }}>
            <TextField
              id="search"
              type="text"
              placeholder="Search by Involved Person or location. Click arrow for other filters"
              autoComplete="off"
              value={searchInput}
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  applySearch();
                  setIsFilter(true);
                  setCurrentPage(1);
                }
              }}
              inputProps={{}}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-owns={anchorForm ? "search-form" : undefined}
                      aria-haspopup="true"
                      onClick={handleClickDropdown}
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                    <StyledForm
                      id="search-form"
                      anchorEl={anchorForm}
                      open={Boolean(anchorForm)}
                      onClose={handleCloseForm}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <MenuItem sx={{ maxHeight: "380px", overflowY: "auto" }}>
                        {/* <FilterIncidents /> */}
                        <form>
                          <FormControl mt={3}>
                            <TextField
                              fullWidth
                              id="dateRange"
                              label="Date range"
                              autoComplete="off"
                              value={
                                startDateToFilter && endDateToFilter
                                  ? moment(startDateToFilter).format(
                                      "MM/DD/YYYY"
                                    ) +
                                    " - " +
                                    moment(endDateToFilter).format("MM/DD/YYYY")
                                  : ""
                              }
                              onClick={() => setFadedBox((prev) => !prev)}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="Select date range"
                            />
                            {fadebox && (
                              <CustomDateRange
                                handleShow={fadebox}
                                conformation={(status) =>
                                  setFadedBox((prev) => !prev)
                                }
                                setDates={(start, end) => {
                                  setStartDateToFilter(start);
                                  setEndDateToFilter(end);
                                }}
                                selectedStart={startDateToFilter}
                                selectedEnd={endDateToFilter}
                              />
                            )}
                          </FormControl>
                          <FormControl>
                            <FormLabel>Quarter</FormLabel>
                            <RadioGroup
                              aria-labelledby="quarter"
                              name="quarter"
                              value={quaterValue}
                              onChange={(e) => {
                                setQuaterValue(e.target.value);
                              }}
                              // defaultValue="Q1"
                              row
                            >
                              <FormControlLabel
                                control={<Radio />}
                                label="Q1"
                                value="Q1"
                                name="quarter_q1"
                              />
                              <FormControlLabel
                                control={<Radio />}
                                label="Q2"
                                value="Q2"
                                name="quarter_q2"
                              />
                              <FormControlLabel
                                control={<Radio />}
                                label="Q3"
                                value="Q3"
                                name="quarter_q3"
                              />
                              <FormControlLabel
                                control={<Radio />}
                                label="Q4"
                                value="Q4"
                                name="quarter_q4"
                              />
                            </RadioGroup>
                          </FormControl>
                          <FormControl>
                            <TextField
                              fullWidth
                              id="location"
                              label="Location"
                              autoComplete="off"
                              value={locationValue}
                              onChange={(e) => {
                                setLocationValue(e.target.value);
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="Parking lot"
                            />
                          </FormControl>
                          <FormControl>
                            <TextField
                              fullWidth
                              id="narrative"
                              label="Narrative"
                              autoComplete="off"
                              value={narrativeValue}
                              onChange={(e) => {
                                setNarrativeValue(e.target.value);
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="Word or phrase to search"
                            />
                          </FormControl>
                          <FormControl>
                            <TextField
                              fullWidth
                              id="involved-person"
                              label="Name of involved person"
                              autoComplete="off"
                              value={involvedPersonValue}
                              onChange={(e) => {
                                setInvolvedPersonValue(e.target.value);
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="John smith"
                            />
                          </FormControl>
                          <FormControl>
                            <Autocomplete
                              multiple
                              id="treatment-rendered"
                              fullWidth
                              options={ir}
                              value={type}
                              onChange={(e, v) => {
                                setType(v);
                              }}
                              disableCloseOnSelect
                              getOptionLabel={(option) => option.key}
                              isOptionEqualToValue={(option, value) =>
                                option.value === value.value
                              }
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.key}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="IR classification"
                                  variant="outlined"
                                  placeholder="IR classification"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              )}
                            />
                            {/* Existing with checkbox error */}
                            {/* <Autocomplete
                              multiple
                              options={optionArray}
                              limitTags={1}
                              disableCloseOnSelect
                              getOptionLabel={(option) => option.full_type}
                              value={state.selectedOptions}
                              renderTags={(values) =>
                                values.map((value) => (
                                  <Chip
                                    key={value.IncidentTypeID}
                                    label={value.full_type}
                                    onDelete={() => {
                                      removeOption(value.IncidentTypeID);
                                    }}
                                  />
                                ))
                              }
                              getOptionSelected={(option, value) =>
                                option.IncidentTypeID === value.IncidentTypeID
                              }
                              renderOption={(props, option, { selected }) => (
                                <li {...props} key={option.IncidentTypeID}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    // style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.full_type}
                                  {"ddd" + selected}
                                </li>
                              )}
                              // style={{ width: "100%" }}
                              onChange={handleChangeAuto}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="IR classification"
                                  variant="outlined"
                                  placeholder="IR classification"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              )}
                            /> */}
                          </FormControl>
                          <FormControl>
                            <InputLabel
                              shrink
                              id="person-involved"
                              sx={{
                                paddingLeft: "6px",
                                paddingRight: "6px",
                                marginLeft: "-4px",
                              }}
                            >
                              Incident Status
                            </InputLabel>
                            <Select
                              labelId="incident-status"
                              id="person-involved"
                              value={incidentStatus}
                              // label="Name of involved person"
                              onChange={selectIncidentStatus}
                              fullWidth
                              displayEmpty
                            >
                              <MenuItem value="">Click to select</MenuItem>
                              {incidentType.map((item) => (
                                <MenuItem value={item.value} key={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl>
                            <FormLabel>Submit status</FormLabel>
                            <FormGroup row>
                              {checkArray.map((item) => (
                                <FormControlLabel
                                  key={item.key}
                                  control={
                                    <Checkbox
                                      disableRipple
                                      name="status_new"
                                      value={item.value}
                                      onChange={(e) => handleChecked(e)}
                                      checked={submitStatusValue.includes(
                                        item.value
                                      )}
                                    />
                                  }
                                  label={item.key}
                                />
                              ))}
                            </FormGroup>
                          </FormControl>
                          <FormControl>
                            <TextField
                              fullWidth
                              required
                              id="reporting-officer"
                              label="Reporting officer"
                              autoComplete="off"
                              value={officerValue}
                              onChange={(e) => {
                                setOfficerValue(e.target.value);
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="Type to see suggestions"
                            />
                          </FormControl>
                          <FormControl>
                            <InputLabel
                              shrink
                              id="police-contacted"
                              sx={{
                                paddingLeft: "6px",
                                paddingRight: "6px",
                                marginLeft: "-4px",
                              }}
                            >
                              Police contacted?
                            </InputLabel>
                            <Select
                              labelId="incident-status"
                              id="police-contacted"
                              value={policeContacted}
                              label="Police contacted"
                              onChange={selectPoliceContacted}
                              fullWidth
                              displayEmpty
                            >
                              <MenuItem value="">Click to select</MenuItem>
                              <MenuItem value="Yes">Yes</MenuItem>
                              <MenuItem value="No">No</MenuItem>
                            </Select>
                          </FormControl>
                          <FormControl>
                            <InputLabel
                              shrink
                              id="ambulance-called"
                              sx={{
                                paddingLeft: "6px",
                                paddingRight: "6px",
                                marginLeft: "-4px",
                              }}
                            >
                              Ambulance called?
                            </InputLabel>
                            <Select
                              labelId="incident-status"
                              id="ambulance-called"
                              value={ambulanceCalled}
                              label="Ambulance called"
                              onChange={selectAmbulanceCalled}
                              fullWidth
                              displayEmpty
                            >
                              <MenuItem value="">Click to select</MenuItem>
                              <MenuItem value="Yes">Yes</MenuItem>
                              <MenuItem value="No">No</MenuItem>
                            </Select>
                          </FormControl>
                          <FormControl>
                            <InputLabel
                              shrink
                              id="fire-dept-called"
                              sx={{
                                paddingLeft: "6px",
                                paddingRight: "6px",
                                marginLeft: "-4px",
                              }}
                            >
                              Fire department called?
                            </InputLabel>
                            <Select
                              labelId="incident-status"
                              id="ambulance-called"
                              value={fireDepartmentCalled}
                              label="Fire department called?"
                              onChange={selectFireDepartmentCalled}
                              fullWidth
                              displayEmpty
                            >
                              <MenuItem value="">Click to select</MenuItem>
                              <MenuItem value="Yes">Yes</MenuItem>
                              <MenuItem value="No">No</MenuItem>
                            </Select>
                          </FormControl>
                          <FormControl>
                            <InputLabel
                              shrink
                              id="anyone-hospitalized"
                              sx={{
                                paddingLeft: "6px",
                                paddingRight: "6px",
                                marginLeft: "-4px",
                              }}
                            >
                              Anyone hospitalized?
                            </InputLabel>
                            <Select
                              labelId="incident-status"
                              id="anyone-hospitalized"
                              value={anyoneHospitalized}
                              label="Anyone hospitalized?"
                              onChange={selectAnyoneHospitalized}
                              fullWidth
                              displayEmpty
                            >
                              <MenuItem value="">Click to select</MenuItem>
                              <MenuItem value="Yes">Yes</MenuItem>
                              <MenuItem value="No">No</MenuItem>
                            </Select>
                          </FormControl>
                          <FormControl>
                            <InputLabel
                              shrink
                              id="claim-adjuster-contracted"
                              sx={{
                                paddingLeft: "6px",
                                paddingRight: "6px",
                                marginLeft: "-4px",
                              }}
                            >
                              Claims adjuster contacted?
                            </InputLabel>
                            <Select
                              labelId="incident-status"
                              id="claim-adjuster-contracted"
                              value={adjusterContacted}
                              label="Claims Adjuster Contacted?"
                              onChange={selectAdjusterContacted}
                              fullWidth
                              displayEmpty
                            >
                              <MenuItem value="">Click to select</MenuItem>
                              <MenuItem value="Yes">Yes</MenuItem>
                              <MenuItem value="No">No</MenuItem>
                            </Select>
                          </FormControl>
                          <FormControl>
                            <InputLabel
                              shrink
                              id="has-media-attached"
                              sx={{
                                paddingLeft: "6px",
                                paddingRight: "6px",
                                marginLeft: "-4px",
                              }}
                            >
                              Has media attached?
                            </InputLabel>
                            <Select
                              labelId="incident-status"
                              id="has-media-attached"
                              value={hasMediaAttached}
                              label="Has Media Attached?"
                              onChange={selectHasMediaAttached}
                              fullWidth
                              displayEmpty
                            >
                              <MenuItem value="">Click to select</MenuItem>
                              <MenuItem value="Yes">Yes</MenuItem>
                              <MenuItem value="No">No</MenuItem>
                            </Select>
                          </FormControl>
                          <FormControl>
                            <TextField
                              fullWidth
                              required
                              id="vehicle-license-plate-number"
                              label="Vehicle license plate number"
                              autoComplete="off"
                              value={vehicleValue}
                              onChange={(e) => {
                                setVehicleValue(e.target.value);
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="4 char require for partial search"
                            />
                          </FormControl>
                          <FormControl>
                            <FormLabel>Record per page</FormLabel>
                            <RadioGroup
                              aria-labelledby="recordperpage"
                              name="recordperpage"
                              value={rowValue}
                              onChange={(e) => {
                                setRowValue(e.target.value);
                              }}
                              row
                              defaultValue="10 per page"
                            >
                              <FormControlLabel
                                control={<Radio />}
                                label="10 per page"
                                value="10"
                                name="record_10_per_page"
                              />
                              <FormControlLabel
                                control={<Radio />}
                                label="20 per page"
                                value="20"
                                name="record_20_per_page"
                              />
                              <FormControlLabel
                                control={<Radio />}
                                label="50 per page"
                                value="50"
                                name="record_50_per_page"
                              />
                            </RadioGroup>
                          </FormControl>
                          <FormControl>
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                              mt={6}
                              onClick={(e) => {
                                applySearch();
                                setIsFilter(true);
                                handleCloseForm();
                                setCurrentPage(1);
                              }}
                            >
                              Filter
                            </Button>
                          </FormControl>
                        </form>
                      </MenuItem>
                    </StyledForm>
                  </InputAdornment>
                ),
              }}
            />
          </Item>
          <Item>
            {isFilter ? (
              <Button
                color="primary"
                variant="contained"
                onClick={(e) => {
                  resetFilter();
                  setDataToFilter(null);
                  setIsFilter(false);
                  setSearchInput("");
                  setStatusCount("");
                }}
                sx={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  height: "100%",
                  width: "100px",
                  marginRight: { lg: "50px" },
                  marginLeft: "-3px",
                }}
              >
                Clear Filter
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                sx={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  height: "100%",
                  width: "100px",
                  marginRight: { lg: "50px" },
                  marginLeft: "-3px",
                }}
                onClick={(e) => {
                  if (searchInput !== "") {
                    applySearch({});
                    setIsFilter(true);
                    setCurrentPage(1);
                  }
                }}
              >
                Search
              </Button>
            )}
          </Item>
        </Box>
      </div>
      <Spacer />
      <div>
        <Paper mt={3}>
          <IconButton
            aria-owns={anchorEl ? "icon-menu" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            variant="contained"
            color="secondary"
          >
            <FilterListIcon />
          </IconButton>
          <StyledFilterMenu
            id="icon-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem
              aria-label="add-incident-report"
              component={NavLink}
              to="/reports/add-incident-report"
            >
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="Add Incident Report" pl={0} />
            </MenuItem>
            <MenuItem onClick={irTasksOpen}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="IR Tasks" pl={0} />
            </MenuItem>
            <MenuItem onClick={onButtonClick}>
              <ListItemIcon>
                <PictureAsPdfIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="Download Pdf" pl={0} />
            </MenuItem>
            <CSVLink
              data={incidentCSV}
              headers={headers}
              onClick={handleClose}
              filename={`SOVA-Tour-Checkpoints-${format(
                new Date(),
                "MM-dd-yyyy"
              )}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <MenuItem>
                <ListItemIcon>
                  <InsertDriveFileIcon />
                </ListItemIcon>
                <ListItemText size="large" primary="Export CSV" pl={0} />
              </MenuItem>
            </CSVLink>

            <MenuItem
              aria-owns={anchorColumn ? "columns-dropdown" : undefined}
              aria-haspopup="true"
              onClick={handleClickColumn}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Columns" pl={0} />
            </MenuItem>
          </StyledFilterMenu>
          <StyledColumnDropdown
            id="columns-dropdown"
            anchorEl={anchorColumn}
            open={Boolean(anchorColumn)}
            onClose={handleCloseColumn}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem>
              <Card>
                <CardContent>
                  <Typography variant="h6" component="h2">
                    {
                      Object.keys(tableCalRedux).filter(
                        (k) => tableCalRedux[k] === true
                      ).length
                    }{" "}
                    out of {Object.keys(tableCalRedux).length} visible
                  </Typography>
                  <StyledColumnOptions
                    calStatus={tableCalRedux}
                    updateCalStatus={(data) => {
                      dispatch(setIncidentList(data));
                    }}
                    listing={IncidentListCol}
                  />
                  <Toolbar>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        dispatch(setIncidentList(defalutTableCalRedux));
                      }}
                    >
                      Reset
                    </Button>
                    <Spacer />
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleCloseColumn}
                    >
                      Cancel
                    </Button>
                  </Toolbar>
                </CardContent>
              </Card>
            </MenuItem>
          </StyledColumnDropdown>
        </Paper>
      </div>
      {irTasks && (
        <IrTask
          irTasks={irTasks}
          setIrTasks={setIrTasks}
          irTasksOpen={irTasksOpen}
          irTasksClose={irTasksClose}
        />
      )}
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </Toolbar>
  );
};
function EnhancedTable(props) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Modified");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [loading, setLoading] = useState(false); //State for loader
  const [loader, setLoader] = useState(false);
  const [dataToFilter, setDataToFilter] = useState(null);
  const snackbarStatus = useSelector((state) => state.report.snackStatus);
  const snackbarMsg = useSelector((state) => state.report.snackMsg);
  const snackbarAlert = useSelector((state) => state.report.snackAlert);
  const incidentList = useSelector((state) => state.report.incidentsData);
  const [openDelete, setOpenDelete] = useState(false);
  const [message, setMessage] = useState("");
  const [isDelete, setIsDelete] = useState(false);
  const [statusCount, setStatusCount] = useState("");
  const [isFilter, setIsFilter] = useState(false);

  const tableCalRedux = useSelector(
    (state) => state.reportTableCol.incidentList
  );
  const defalutTableCalRedux = useSelector(
    (state) => state.defaultReportTableCol.incidentList
  );
  // Alert Message
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");

  //method for close the snackbar
  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(getSnackClose());
  };

  const dispatch = useDispatch();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = incidentList.map((n) => n.IncidentID);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, author) => {
    const selectedIndex = selected.indexOf(author);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, author);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (author) => selected.indexOf(author) !== -1;

  // const emptyRows =
  //   rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const [anchorEl, setAnchorEl] = useState(null);
  const [singleData, setSingleData] = useState({});
  const handleActionClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    setSingleData(data);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
    setSingleData({});
  };
  const classes = useStyles();
  // User Name Missed Buttons Modal
  const [unmissedbuttons, setUserMissedButtons] = useState(false);
  const userMissedButtonsOpen = () => {
    setUserMissedButtons(true);
  };
  const userMissedButtonsClose = () => {
    setUserMissedButtons(false);
  };

  const [startdate, setStartDate] = useState(null);

  // Modal Classification
  const [modalClassification, setModalClassification] = useState(false);
  const modalClassificationOpen = () => {
    setModalClassification(true);
  };
  const modalClassificationClose = () => {
    setModalClassification(false);
  };
  // Modal Email Comment
  const [emailComment, setEmailComment] = useState(false);
  const emailCommentOpen = () => {
    setEmailComment(true);
  };
  const emailCommentClose = () => {
    setEmailComment(false);
  };
  // Modal Change Status
  const [changeSubmitStatus, setChangeSubmitStatus] = useState(false);
  const changeSubmitStatusOpen = () => {
    setChangeSubmitStatus(true);
  };
  const changeSubmitStatusClose = () => {
    setChangeSubmitStatus(false);
  };

  // Peek Modal
  const [peekModal, setPeekModal] = useState(false);
  const peekModalOpen = () => {
    setPeekModal(true);
  };
  const peekModalClose = () => {
    setPeekModal(false);
  };

  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
    setSelected([]);
  };

  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
    setSelected([]);
  };

  const filterRecord = (data) => {
    setDataToFilter(data);
  };

  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
    setAnchorEl(null);
    setMessage("");
    setIsDelete(false);
  };

  const [totalSubmitted, setTotalSubmitted] = useState(null);
  // Methods for calling the APIs
  const getIncidentReportList = (statusCount) => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
      sorting: {
        order: order,
        by: orderBy,
      },
      ...dataToFilter,
      statusCount: statusCount,
    }; //this will be used for sending filtered data
    IncidentsReportList(currentPage, payload)
      .then((res) => {
        if (res.statusCode == 200) {
          dispatch(incidentsList(res.data));
          setTotalRecord(res.meta.total);
          setCurrentPage(res.meta.current_page);
          setTotalSubmitted(res.count);
        } else {
          dispatch(incidentsList(res.data));
          setTotalRecord(0);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setTotalRecord(0);
        dispatch(incidentsList([]));
      });
  };

  const deleteIncident = () => {
    handleDeleteClose();
    let url = selected.length > 0 ? selected : singleData.IncidentID;
    DeleteIncident(url)
      .then((res) => {
        if (res.statusCode === 200) {
          getIncidentReportList();
          dispatch(getSnackMsg("Incident has been deleted"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
          setSelected([]);
        } else {
          dispatch(getSnackMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
        }
      })
      .catch((err) => {
        console.log("err");
        dispatch(getSnackMsg("Something went wrong, please try again"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
      });
  };

  const handleSubmit = () => {
    handleDeleteClose();
    ReportUpdateStatus(singleData.IncidentID, { status_prior: "N" })
      .then((res) => {
        if (res.statusCode === 200) {
          getIncidentReportList();
          dispatch(getSnackMsg("Incident report submitted successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getSnackMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
        }
      })
      .catch((err) => {
        dispatch(getSnackMsg("Something went wrong, please try again"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
      });
  };
  const searchClick = (event) => {
    setIsFilter(true);
    setStatusCount("S");
    // getIncidentReportList("S"); // pass the desired statusCount value here
  };
  useEffect(() => {
    getIncidentReportList(statusCount);
    // getColumnList();
  }, [rowsPerPage, currentPage, order, orderBy, dataToFilter, statusCount]);

  // Add Appended Narrative Modal
  const [addAppendedNarrative, setAddAppendedNarrative] = useState(false);
  const addAppendedNarrativeOpen = () => {
    setAddAppendedNarrative(true);
  };
  const addAppendedNarrativeClose = () => {
    setAddAppendedNarrative(false);
  };
  // Send NOR Modal
  const [sendNor, setSendNor] = useState(false);
  const sendNorOpen = () => {
    setSendNor(true);
  };
  const sendNorClose = () => {
    setSendNor(false);
  };
  // View Communication Log Modal
  const [showAddComLog, setShowAddComLog] = useState(false);
  const [viewCommunicationLog, setViewCommunicationLog] = useState(false);
  const viewCommunicationLogOpen = () => {
    setViewCommunicationLog(true);
  };
  const viewCommunicationClose = () => {
    setViewCommunicationLog(false);
  };
  // Require review Modal
  const [requireReview, setRequireReview] = useState(false);
  const requireReviewOpen = () => {
    setRequireReview(true);
  };
  const requireReviewClose = () => {
    setRequireReview(false);
  };
  // Send to responding police officer Modal
  const [sendResponseToPoliceOfficer, setSendResponseToPoliceOfficer] =
    useState(false);
  const sendResponseToPoliceOfficerOpen = () => {
    setSendResponseToPoliceOfficer(true);
  };
  const sendResponseToPoliceOfficerClose = () => {
    setSendResponseToPoliceOfficer(false);
  };
  // Change ICC Modal
  const [changeIcc, setChangeIcc] = useState(false);
  const changeIccOpen = () => {
    setChangeIcc(true);
  };
  const changeIccClose = () => {
    setChangeIcc(false);
  };
  // Task List Modal
  const [taskList, setTaskList] = useState(false);
  const taskListOpen = () => {
    setTaskList(true);
  };
  const taskListClose = () => {
    setTaskList(false);
  };

  const showAlertTost = (msg, type) => {
    dispatch(getSnackMsg(msg));
    dispatch(getSnackAlert(type));
    dispatch(getSnackOpen());
    setTimeout(() => {
      dispatch(getSnackClose());
    }, 3000);
  };

  return (
    <div>
      {/* <Dialog
        open={openDelete}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
        <DialogActions>
          <Button onClick={handleDeleteClose} variant="contained">
            Cancel
          </Button>
          <Button
            onClick={isDelete ? deleteIncident : handleSubmit}
            autoFocus
            variant="contained"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog> */}
      {selected.length || singleData.IncidentID > 0 ? (
        <Dialog
          open={openDelete}
          onClose={handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
          <DialogActions>
            <Button onClick={handleDeleteClose} variant="contained">
              Cancel
            </Button>
            <Button
              onClick={isDelete ? deleteIncident : handleSubmit}
              autoFocus
              variant="contained"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog
          open={openDelete}
          onClose={handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"You haven't selected any item!"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleDeleteClose}>OK</Button>
          </DialogActions>
        </Dialog>
      )}
      <Paper mt={3}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          totalRecord={totalRecord}
          filterRecord={(data) => filterRecord(data)}
          setDataToFilter={setDataToFilter}
          setCurrentPage={setCurrentPage}
          incidentList={incidentList}
          tableCalRedux={tableCalRedux}
          defalutTableCalRedux={defalutTableCalRedux}
          handleDeleteOpen={handleDeleteOpen}
          setMessage={setMessage}
          setIsDelete={setIsDelete}
          statusCount={statusCount}
          setStatusCount={setStatusCount}
          isFilter={isFilter}
          setIsFilter={setIsFilter}
          rowsPerPage={rowsPerPage}
          order={order}
          orderBy={orderBy}
          dataToFilter={dataToFilter}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            padding: "10px 12px 0",
          }}
        >
          <Typography>Submitted reports to be reviewed:</Typography>
          <Tooltip
            title="Click box to right to filter by reports that need review"
            placement="bottom"
          >
            <ErrorIcon />
          </Tooltip>
          <Chip label={totalSubmitted} color="success" onClick={searchClick} />
        </Box>
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <>
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={incidentList.length}
                  tableCalRedux={tableCalRedux}
                />
                <TableBody>
                  {stableSort(incidentList, getComparator(order, orderBy))
                    //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.IncidentID);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.IncidentID}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                              onClick={(event) =>
                                handleClick(event, row.IncidentID)
                              }
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              selected={isItemSelected}
                            />
                          </TableCell>
                          <Tooltip
                            title={`RefID : ${row.IncidentID}`}
                            followCursor
                          >
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              sx={{ whiteSpace: "nowrap" }}
                              className={
                                tableCalRedux.Number === true ? "" : "hide-cell"
                              }
                            >
                              {row.Number}
                            </TableCell>
                          </Tooltip>
                          <TableCell
                            align="left"
                            sx={{ whiteSpace: "nowrap" }}
                            className={
                              tableCalRedux.Classification === true
                                ? ""
                                : "hide-cell"
                            }
                          >
                            <Tooltip
                              title={`${row.SummaryDescription}`}
                              followCursor
                            >
                              <Typography variant="span" component="p">
                                {row.Classification}(0)
                              </Typography>
                            </Tooltip>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <IncidentMediaStatus data={row} />
                            </Box>
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ whiteSpace: "nowrap" }}
                            className={
                              tableCalRedux.Datetime === true ? "" : "hide-cell"
                            }
                          >
                            <Box>
                              <Typography variant="span" component="p">
                                {row.Datetime}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ whiteSpace: "nowrap" }}
                            className={
                              tableCalRedux.ir_status === true
                                ? ""
                                : "hide-cell"
                            }
                          >
                            {row.ir_status}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ whiteSpace: "nowrap" }}
                            className={
                              tableCalRedux.IncidentLocation === true
                                ? ""
                                : "hide-cell"
                            }
                          >
                            {row.IncidentLocation}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ whiteSpace: "nowrap" }}
                            className={
                              tableCalRedux.status === true ? "" : "hide-cell"
                            }
                          >
                            <StyledTooltip
                              title={
                                <Card>
                                  <CardHeader
                                    title={
                                      row.status == "N"
                                        ? "New"
                                        : row.status == "S"
                                        ? "Submitted"
                                        : row.status == "R"
                                        ? "Return"
                                        : row.status == "A"
                                        ? "Accepted"
                                        : row.status == "E"
                                        ? "Expired"
                                        : ""
                                    }
                                  />
                                  <CardContent>
                                    <Button
                                      onClick={() => {
                                        changeSubmitStatusOpen();
                                        setSingleData(row);
                                      }}
                                    >
                                      Change
                                    </Button>
                                  </CardContent>
                                </Card>
                              }
                            >
                              {row.status === "N" ? (
                                <IconButton>
                                  <LaunchIcon
                                    className="disabled"
                                    color="disabled"
                                  />
                                </IconButton>
                              ) : row.status === "S" ? (
                                <IconButton color="success">
                                  <ArrowRightCircle />
                                </IconButton>
                              ) : row.status === "R" ? (
                                <IconButton color="warning">
                                  <WarningIcon />
                                </IconButton>
                              ) : row.status === "A" ? (
                                <IconButton color="warning">
                                  <StarIcon />
                                </IconButton>
                              ) : row.status === "E" ? (
                                <IconButton>
                                  <MobileFriendlyIcon color="primary" />
                                </IconButton>
                              ) : (
                                <Typography>n/a</Typography>
                              )}
                            </StyledTooltip>
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              whiteSpace: "nowrap",
                              cursor: "pointer",
                              lg: 'whiteSpace: "nowrap"',
                              color: "#0077B5",
                            }}
                            onClick={() => {
                              emailCommentOpen();
                              setSingleData(row);
                            }}
                            className={
                              tableCalRedux.UserName === true ? "" : "hide-cell"
                            }
                          >
                            {row?.user?.UserName || ""}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ whiteSpace: "nowrap" }}
                            className={
                              tableCalRedux.LastModified === true
                                ? ""
                                : "hide-cell"
                            }
                          >
                            <Box>
                              <Typography variant="span" component="p">
                                {row.LastModified}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell
                            align="left"
                            className={
                              tableCalRedux.Action === true ? "" : "hide-cell"
                            }
                          >
                            <IconButton
                              aria-label="Settings"
                              size="large"
                              aria-owns={anchorEl ? "icon-menu" : undefined}
                              aria-haspopup="true"
                              onClick={(e) => handleActionClick(e, row)}
                              style={{ color: "#1374C5" }}
                            >
                              <SettingsIcon />
                            </IconButton>
                            <StyledMenu
                              id="icon-menu"
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={handleActionClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <MenuItem
                                component={NavLink}
                                to={`/reports/view-incident-report/${singleData.IncidentID}`}
                                onClick={() => {
                                  dispatch(changeDisabledStatus(true));
                                }}
                                // to="/reports/view-incident-report"
                              >
                                <ListItemIcon>
                                  <VisibilityIcon />
                                </ListItemIcon>
                                <ListItemText primary="View" pl={1} />
                              </MenuItem>
                              <MenuItem onClick={peekModalOpen}>
                                <ListItemIcon>
                                  <TrendingUpIcon />
                                </ListItemIcon>
                                <ListItemText primary="Peek" pl={1} />
                              </MenuItem>
                              <MenuItem
                                component={NavLink}
                                to={`/reports/edit-incident-report/${singleData.IncidentID}`}
                                // to="/reports/edit-incident-report"
                              >
                                <ListItemIcon>
                                  <EditIcon />
                                </ListItemIcon>
                                <ListItemText primary="Edit" pl={1} />
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  handleDeleteOpen();
                                  setMessage(
                                    "Are you sure? You will not be able to edit the incident report after submitting"
                                  );
                                }}
                                sx={{
                                  display:
                                    singleData.status == "S" ? "none" : "flex",
                                }}
                              >
                                <ListItemIcon>
                                  <SendIcon />
                                </ListItemIcon>
                                <ListItemText primary="Submit" pl={1} />
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  handleDeleteOpen();
                                  setMessage(
                                    "Are you sure you want to delete incident?"
                                  );
                                  setIsDelete(true);
                                }}
                                disabled={
                                  singleData.status == "S" ? true : false
                                }
                              >
                                <ListItemIcon>
                                  <DeleteIcon />
                                </ListItemIcon>
                                <ListItemText primary="Delete" pl={1} />
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  addAppendedNarrativeOpen();
                                  // handleActionClose();
                                }}
                              >
                                <ListItemIcon>
                                  <AddToPhotosIcon />
                                </ListItemIcon>
                                <ListItemText primary="Append" pl={1} />
                              </MenuItem>
                              <MenuItem onClick={sendNorOpen}>
                                <ListItemIcon>
                                  <SendIcon />
                                </ListItemIcon>
                                <ListItemText primary="Send NOR" pl={1} />
                              </MenuItem>
                              <MenuItem onClick={viewCommunicationLogOpen}>
                                <ListItemIcon>
                                  <SettingsIcon />
                                </ListItemIcon>
                                <ListItemText primary="View Comm Log" pl={1} />
                              </MenuItem>
                              <MenuItem onClick={requireReviewOpen}>
                                <ListItemIcon>
                                  <RateReviewIcon />
                                </ListItemIcon>
                                <ListItemText primary="Require Review" pl={1} />
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  sendResponseToPoliceOfficerOpen();
                                  // handleActionClose();
                                }}
                              >
                                <ListItemIcon>
                                  <StreetviewIcon />
                                </ListItemIcon>
                                <ListItemText
                                  primary="Release to Officer"
                                  pl={1}
                                />
                              </MenuItem>
                              <MenuItem onClick={changeIccOpen}>
                                <ListItemIcon>
                                  <ExitToAppIcon />
                                </ListItemIcon>
                                <ListItemText primary="Change ICC" pl={1} />
                              </MenuItem>
                              <MenuItem onClick={taskListOpen}>
                                <ListItemIcon>
                                  <AssignmentIcon />
                                </ListItemIcon>
                                <ListItemText primary="Task List" pl={1} />
                              </MenuItem>
                            </StyledMenu>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {totalRecord === 0 && (
                    <TableRow style={{ height: (dense ? 6 : 0) * totalRecord }}>
                      <TableCell colSpan={11} align="center">
                        No records found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          }}
        >
          <Pagination
            count={totalRecord !== 0 ? Math.ceil(totalRecord / rowsPerPage) : 1}
            page={currentPage}
            onChange={handleChangePagination}
            color="primary"
            boundaryCount={3}
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <Typography sx={{ marginRight: "10px" }}>
                Rows per page :
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                // label="Age"
                onChange={handleChangeLimit}
              >
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </Box>
          </FormControl>
        </Box>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
      {/* {modalClassification && (
        <ClassificationTest
          modalClassificationOpen={modalClassificationOpen}
          modalClassificationClose={modalClassificationClose}
          modalClassification={modalClassification}
          setModalClassification={setModalClassification}
        />
      )} */}
      {emailComment && (
        <EmailComment
          emailCommentOpen={emailCommentOpen}
          emailCommentClose={emailCommentClose}
          emailComment={emailComment}
          setEmailComment={setEmailComment}
          singleData={singleData}
        />
      )}
      {addAppendedNarrative && (
        <AddAppendedNarrative
          addAppendedNarrative={addAppendedNarrative}
          setAddAppendedNarrative={setAddAppendedNarrative}
          addAppendedNarrativeOpen={addAppendedNarrativeOpen}
          addAppendedNarrativeClose={addAppendedNarrativeClose}
          singleData={singleData}
        />
      )}
      {sendNor && (
        <SendNor
          sendNor={sendNor}
          setSendNor={setSendNor}
          sendNorOpen={sendNorOpen}
          sendNorClose={sendNorClose}
          singleData={singleData}
        />
      )}
      {taskList && (
        <TaskList
          taskList={taskList}
          setTaskList={setTaskList}
          taskListOpen={taskListOpen}
          taskListClose={taskListClose}
          showAlertTost={(msg, type) => showAlertTost(msg, type)}
          setLoader={(status) => setLoader(status)}
          module_id={singleData.IncidentID}
          taskTitle={singleData.Classification + " " + singleData.Datetime}
          moduleType="IR"
        />
      )}
      {viewCommunicationLog && (
        <CommunicationLogModal
          show={viewCommunicationLog}
          handleModal={(status) => setViewCommunicationLog(status)}
          id={singleData.IncidentID}
          setShowAddComLog={(status) => setShowAddComLog(status)}
          log_type="ir"
        />
      )}
      {showAddComLog && (
        <AddComLogModal
          show={showAddComLog}
          handleModal={(status) => setShowAddComLog(status)}
          id={singleData.IncidentID}
          showAlertTost={(msg, type) => showAlertTost(msg, type)}
          log_type="ir"
        />
      )}
      {requireReview && (
        <RequireReview
          requireReview={requireReview}
          setRequireReview={setRequireReview}
          requireReviewOpen={requireReviewOpen}
          requireReviewClose={requireReviewClose}
          singleData={singleData}
        />
      )}
      {sendResponseToPoliceOfficer && (
        <SendIrToRepondingPoliceOfficer
          sendResponseToPoliceOfficer={sendResponseToPoliceOfficer}
          setSendResponseToPoliceOfficer={setSendResponseToPoliceOfficer}
          sendResponseToPoliceOfficerOpen={sendResponseToPoliceOfficerOpen}
          sendResponseToPoliceOfficerClose={sendResponseToPoliceOfficerClose}
          singleData={singleData}
        />
      )}
      {changeIcc && (
        <ChangeIcc
          changeIcc={changeIcc}
          setChangeIcc={setChangeIcc}
          changeIccOpen={changeIccOpen}
          changeIccClose={changeIccClose}
          singleData={singleData}
        />
      )}
      {changeSubmitStatus && (
        <ChangeSubmitStatus
          changeSubmitStatusOpen={changeSubmitStatusOpen}
          changeSubmitStatusClose={changeSubmitStatusClose}
          changeSubmitStatus={changeSubmitStatus}
          setChangeSubmitStatus={setChangeSubmitStatus}
          singleData={singleData}
        />
      )}
      {peekModal && (
        <PeekModal
          peekModal={peekModal}
          peekModalClose={peekModalClose}
          peekModalOpen={peekModalOpen}
          setPeekModal={setPeekModal}
          singleData={singleData}
        />
      )}
      {/* snackbar for adding incident */}
      {snackbarStatus && (
        <Toaster
          openSnack={snackbarStatus}
          handleCloseSnack={handleCloseSnack}
          severity={snackbarAlert}
          message={snackbarMsg}
        />
      )}
      {loader && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </div>
  );
}

function Incidents() {
  return (
    <React.Fragment>
      <Helmet title="Incidents Reports" />
      <Typography variant="h3" gutterBottom display="inline">
        Incidents Reports
      </Typography>

      {/* <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
          Tables
        </Link>
        <Typography>Users</Typography>
      </Breadcrumbs>

      <Divider my={6} /> */}

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Incidents;
