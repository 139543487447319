import React, { useState, useRef } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PropTypes from "prop-types";
import EquipmentCheckIn from "./innerform/EquipmentCheckIn";
import {
  ViewProcessEquipment,
  CheckEquipments,
  SearchEquipmentUser,
  DeleteAssignedEquipemnt,
  UpdateEquipUser,
  EquipmentCheckin,
  BulkCheckIn,
  UpdateUserImage,
} from "../../api";
import { getFileBase64, handleApiResonseErrors } from "../../helper/helper";
import Toaster from "../tasks/Toaster";
import { useDispatch, useSelector } from "react-redux";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../redux/slices/equipmentSlice";
import {
  Avatar,
  Button as MuiButton,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormLabel,
  Select,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Link,
  Typography,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormHelperText,
  Box,
  Autocomplete,
  Stack,
  ListItem as MuiListItem,
  ListItemText,
  ListItemButton,
  List as MuiList,
  FormControlLabel,
  FormGroup,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Menu,
  ListItemIcon,
} from "@mui/material";
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Error as ErrorIcon,
  Search as SearchIcon,
  Build as BuildIcon,
  Visibility as VisibilityIcon,
  Settings as SettingsIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { display, spacing } from "@mui/system";
import { param } from "jquery";
import CameraFeeding from "../../helper/camera-feeding";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 20px 0 15px ${(props) => props.theme.spacing(2)};
`;
const ListItem = styled(MuiListItem)`
  padding: 0px;
`;
const List = styled(MuiList)`
  margin-top: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  background: #7aa8ff0d;
  border-radius: 5px;
`;
const nameSuggestion = [
  { title: "Test one" },
  { title: "Test two" },
  { title: "Test three" },
  { title: "Test four" },
];
const equipSuggestion = [
  { title: "Equipment one" },
  { title: "Equipment two" },
  { title: "Equipment three" },
  { title: "Equipment four" },
];
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
const StyledTooltip = ({ title, children, ...props }) => (
  <Tooltip
    {...props}
    title={title}
    placement="top-end"
    /* arrow */
    componentsProps={{
      tooltip: {
        sx: {
          lineHeight: "1.6",
          fontSize: "10px",
          marginRight: "-15px",
          maxWidth: "330px",
        },
      },
    }}
  >
    {children}
  </Tooltip>
);
const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
function AddUserForm() {
  const [values, setValues] = React.useState({
    showPassword: false,
  });
  const [uploadedImage, setUploadedImage] = useState(null);
  const [showWebCam, setShowWebCam] = React.useState(false);
  const uploadedFileRef = useRef();
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [positionselect, setPosition] = React.useState("");
  const selectPositionlevel = (event) => {
    setPosition(event.target.value);
  };
  const [userselectDepartment, setDepartment] = React.useState("");
  const selectDepartment = (event) => {
    setDepartment(event.target.value);
  };
  const [securitylevel, setSecurityLevel] = React.useState("");
  const selectSecurityLevel = (event) => {
    setSecurityLevel(event.target.value);
  };
  const [open, setOpen] = React.useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("sm");

  const [otherinfo, setOtherInfo] = React.useState("");
  const selectOtherInfo = (event) => {
    setOtherInfo(event.target.value);
  };

  const dispatch = useDispatch();
  const snackbarStatus = useSelector((state) => state.equipment.snackStatus);
  const snackbarMsg = useSelector((state) => state.equipment.msg);
  const snackbarAlert = useSelector((state) => state.equipment.snackAlert);

  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(getSnackClose());
  };
  //Search Modal
  const [searchmodal, setSearchModal] = React.useState(false);
  const searchModalOpen = () => {
    setSearchModal(true);
  };
  const searchModalClose = () => {
    setSearchModal(false);
  };

  //Equipment check in Modal
  const [checkinequip, setEquipCheckIn] = React.useState(false);
  const equiCheckInOpen = () => {
    setEquipCheckIn(true);
  };
  const equiCheckInClose = () => {
    setEquipCheckIn(false);
    setIsShow(false);
  };
  const [processEquip, setProcessEquip] = useState({});
  const [userId, setUserId] = useState(null);
  const [check, setCheck] = useState([]);
  const [show, setShow] = useState(false);
  const [userNote, setUserNote] = useState("");
  const processEquipment = () => {
    ViewProcessEquipment(userId)
      .then((res) => {
        if (res.statusCode === 200) {
          setProcessEquip(res.data);
          setCheck(res.data.data);
          setUploadedImage(res.data.image);
          setShow(true);
          setUserNote(res.data.note);
        } else {
          setProcessEquip({});
          dispatch(getSnackOpen());
          dispatch(getMsg("User ID not found or this employee is inactive"));
          dispatch(getSnackAlert("error"));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [checkout, setCheckout] = useState({});
  const [employeeId, setEmployeeId] = useState(null);
  const [userChecked, setUserChecked] = useState([]);
  const checkEquipment = () => {
    let payload = {
      eid: employeeId,
      uid: userId,
      user_type: "Employee",
      user_id_scan: "YES",
    };
    CheckEquipments(payload)
      .then((res) => {
        if (res.status == "alreadycheckoutuser") {
          setCheckout(res.data);
          handleClickOpen();
          return;
        }
        if (res.statusCode === 200) {
          setUserChecked([res.data]);
          dispatch(getSnackOpen());
          dispatch(getMsg("Equipment Checked Out Successfully"));
          dispatch(getSnackAlert("success"));
        } else {
          dispatch(getSnackOpen());
          dispatch(getMsg(res.message));
          dispatch(getSnackAlert("error"));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [userName, setUserName] = useState([]);
  const [name, setName] = useState({});
  const searchUser = () => {
    SearchEquipmentUser()
      .then((res) => {
        if (res.statusCode === 200) {
          setUserName(res.data);
        } else {
          setUserName([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const assignedEquipment = () => {
    DeleteAssignedEquipemnt(id)
      .then((res) => {
        if (res.statusCode == 200) {
          dispatch(getSnackOpen());
          dispatch(getMsg("Equipment Checked In Succesfully"));
          dispatch(getSnackAlert("success"));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateEquipUser = () => {
    let payload = {
      emp_id: employeeId,
      equipment_id: checkout.equipment_id,
      id: checkout.assign_id,
    };
    UpdateEquipUser(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          console.log(res.data);
          handleClose();
          setUserChecked(res.data);
          dispatch(getSnackOpen());
          dispatch(getMsg("checkout successfully"));
          dispatch(getSnackAlert("success"));
        } else {
          dispatch(getSnackOpen());
          dispatch(getMsg(res.message));
          dispatch(getSnackAlert("error"));
        }
      })
      .catch((err) => {
        dispatch(getSnackOpen());
        dispatch(getMsg("Something went wrong"));
        dispatch(getSnackAlert("error"));
        console.log(err);
      });
  };

  const [checkIn, setCheckIn] = useState({});
  const [isShow, setIsShow] = useState(false);
  const equipmentCheckin = (id) => {
    EquipmentCheckin({ equipment_id: id })
      .then((res) => {
        if (res.statusCode === 200) {
          setCheckIn(res.data);
          setIsShow(true);
        } else {
          dispatch(getSnackOpen());
          dispatch(getMsg(res.message));
          dispatch(getSnackAlert("error"));
          setIsShow(false);
        }
      })
      .catch((err) => {
        dispatch(getSnackOpen());
        dispatch(getMsg("Something went wrong"));
        dispatch(getSnackAlert("error"));
        setIsShow(false);
      });
  };

  const bulkCheckIn = () => {
    BulkCheckIn(checkIn.id)
      .then((res) => {
        if (res.statusCode === 200) {
          console.log(res.data);
          dispatch(getSnackOpen());
          dispatch(getMsg("All equipment checked in"));
          dispatch(getSnackAlert("success"));
        } else {
          dispatch(getSnackOpen());
          dispatch(getMsg("Something went wrong"));
          dispatch(getSnackAlert("error"));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(getSnackOpen());
        dispatch(getMsg("Something went wrong"));
        dispatch(getSnackAlert("error"));
      });
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [id, setId] = useState(null);
  const handleActionClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setId(id);
    console.log(id);
  };

  const handleActionClose = () => {
    setAnchorEl(null);
  };

  const [equipmentCheckIn, setEquipmentCheckIn] = React.useState(false);
  const equipmentCheckInOpen = () => {
    setEquipmentCheckIn(true);
  };
  const equipmentCheckInClose = () => {
    setEquipmentCheckIn(false);
  };

  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleShowWebCam = () => {
    let changeSetShowWebCam = !showWebCam;
    setShowWebCam(changeSetShowWebCam);
  };
  const handleFileChange = (evt) => {
    let file = evt.target.files[0];
    getFileBase64(file, (result) => {
      setUploadedImage(result);
    });
  };

  const clearSelectedImage = () => {
    setUploadedImage(null);
    uploadedFileRef.current.value = "";
  };

  function isUrl(str) {
    const pattern =
      /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
    return pattern.test(str);
  }

  const handleUpdateUserImage = () => {
    UpdateUserImage({
      user_id: userId,
      equipmentUserNote: userNote,
      image: isUrl(uploadedImage) ? null : uploadedImage,
    })
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(getSnackOpen());
          dispatch(getMsg("Record updated successfully"));
          dispatch(getSnackAlert("success"));
        } else {
          dispatch(getSnackOpen());
          dispatch(getMsg("Something went wrong"));
          dispatch(getSnackAlert("error"));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Card mb={6}>
        {snackbarStatus && (
          <Toaster
            openSnack={snackbarStatus}
            handleCloseSnack={handleCloseSnack}
            severity={snackbarAlert}
            message={snackbarMsg}
          />
        )}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <Typography>
              Equipment is already is checkout to{" "}
              <b>{checkout.already_checkout_user}</b>. Would you like to check
              the equipment out to <b>{checkout.current_scan_user}</b>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>No</Button>
            <Button onClick={updateEquipUser} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12} md={12}>
              <Box sx={{ display: "flex", justifyContent: "end", gap: "10px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  onClick={() => {
                    searchModalOpen();
                    searchUser();
                  }}
                >
                  <SearchIcon /> Search name
                </Button>
                <Button
                  variant="outlined"
                  color="warning"
                  component="span"
                  onClick={equiCheckInOpen}
                >
                  <BuildIcon /> Bulk check-in
                </Button>
              </Box>
              {searchmodal && (
                <Dialog
                  sx={{
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "600px",
                      },
                    },
                  }}
                  open={searchmodal}
                  onClose={searchModalClose}
                  aria-labelledby="search-modal"
                  aria-describedby="search-modal"
                >
                  <DialogTitle
                    id="alert-dialog-title"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingBottom: "0px",
                    }}
                  >
                    <Typography variant="h4" component="div">
                      Search By Name
                    </Typography>
                    <DialogActions sx={{ padding: "0px" }}>
                      <IconButton
                        sx={{ marginRight: "-10px" }}
                        form
                        onClick={() => setSearchModal(false)}
                      >
                        <CancelIcon />
                      </IconButton>
                    </DialogActions>
                  </DialogTitle>
                  <DialogContent sx={{ padding: "0px" }}>
                    <DialogContentText
                      id="search-modal"
                      sx={{ padding: "20px 25px" }}
                    >
                      <Box
                        mt={3}
                        sx={{
                          display: "flex",
                          flexWrap: "nowrap",
                        }}
                      >
                        <Item sx={{ flexGrow: 1, width: "100%" }}>
                          <Stack>
                            <FormControl>
                              <Autocomplete
                                freeSolo
                                id="search-tours"
                                disableClearable
                                onChange={(e, v) => {
                                  setName(v);
                                }}
                                options={userName}
                                getOptionLabel={(option) =>
                                  option.fname + " " + option.lname
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Enter to see suggestion"
                                    InputProps={{
                                      ...params.InputProps,
                                      type: "search",
                                    }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                          </Stack>
                        </Item>
                        <Item>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              ViewProcessEquipment(name.employee_id)
                                .then((res) => {
                                  if (res.statusCode === 200) {
                                    setUserId(res.data.employee_id);
                                    setProcessEquip(res.data);
                                    setUserNote(res.data.note);
                                    setShow(true);
                                  } else {
                                    setProcessEquip({});
                                    dispatch(getSnackOpen());
                                    dispatch(
                                      getMsg(
                                        "User ID not found or this employee is inactive"
                                      )
                                    );
                                    dispatch(getSnackAlert("error"));
                                  }
                                })
                                .catch((err) => {
                                  console.log(err);
                                });
                              searchModalClose();
                            }}
                            sx={{
                              borderTopLeftRadius: "0px",
                              borderBottomLeftRadius: "0px",
                              height: "100%",
                              marginLeft: "-3px",
                            }}
                          >
                            <SearchIcon />
                          </Button>
                        </Item>
                      </Box>
                    </DialogContentText>
                  </DialogContent>
                </Dialog>
              )}
              {checkinequip && (
                <Dialog
                  sx={{
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "600px",
                      },
                    },
                  }}
                  open={checkinequip}
                  onClose={equiCheckInClose}
                  aria-labelledby="search-modal"
                  aria-describedby="search-modal"
                >
                  <DialogTitle
                    id="alert-dialog-title"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingBottom: "0px",
                    }}
                  >
                    <Typography variant="h4" component="div">
                      Equipment Check In
                    </Typography>
                    <DialogActions sx={{ padding: "0px" }}>
                      <IconButton
                        sx={{ marginRight: "-10px" }}
                        onClick={() => {
                          setEquipCheckIn(false);
                          setIsShow(false);
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                    </DialogActions>
                  </DialogTitle>
                  <DialogContent>
                    <FormControl>
                      <TextField
                        id="outlined-number"
                        label="Scan equipment*"
                        type="text"
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            equipmentCheckin(e.target.value);
                          }
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{ borderBottom: 0, marginTop: "15px" }}
                      />
                    </FormControl>
                    {isShow && (
                      <Card
                        variant="outlined"
                        sx={{ borderBottom: 0, marginTop: "15px" }}
                      >
                        <TableContainer className="tableContainer">
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Equipment Name</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Assigned To</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow hover>
                                <TableCell>{checkIn.equipment_name}</TableCell>
                                <TableCell>{checkIn.assign_date}</TableCell>
                                <TableCell>
                                  {checkIn.fname + " " + checkIn.lname}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Card>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() => {
                        equiCheckInClose();
                        setIsShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      sx={{ marginRight: "10px" }}
                      onClick={() => {
                        bulkCheckIn();
                        equiCheckInClose();
                      }}
                    >
                      Check in all
                    </Button>
                  </DialogActions>
                </Dialog>
              )}
            </Grid>
            <Grid item xs={12} md={12}>
              <form>
                <Grid container spacing={6}>
                  <Grid item xs={12} lg={6}>
                    <FormControl mt={3}>
                      <TextField
                        fullWidth
                        id="user-id"
                        label="User ID"
                        value={userId}
                        onChange={(e) => setUserId(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            processEquipment();
                          }
                        }}
                        placeholder="Type or scan barcode ID for person requesting"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <TextField
                        fullWidth
                        id="equip-id"
                        label="Equipment ID"
                        value={employeeId}
                        onChange={(e) => setEmployeeId(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            checkEquipment();
                          }
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Enter user ID first"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <TextField
                        fullWidth
                        InputProps={{
                          readOnly: true,
                        }}
                        value={processEquip.fname}
                        id="fname"
                        label="First name"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="No ID entered"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <TextField
                        fullWidth
                        InputProps={{
                          readOnly: true,
                        }}
                        id="lname"
                        value={processEquip.lname}
                        label="Last name"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="No ID entered"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <TextField
                        fullWidth
                        InputProps={{
                          readOnly: true,
                        }}
                        id="user-type"
                        label="User type"
                        value={processEquip.user_type}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="No ID entered"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <TextField
                        fullWidth
                        InputProps={{
                          readOnly: true,
                        }}
                        id="user-dept"
                        label="User department"
                        value={processEquip.department_name}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="No ID entered"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Grid container spacing={6}>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{ visibility: show ? "visible" : "hidden" }}
                      >
                        <FormControl>
                          <FormLabel>User Stats</FormLabel>
                          <Box sx={{ margin: "15px 0" }}>
                            <Typography>
                              Items checked out in last 30 days:{" "}
                              <b>{processEquip.retcount}</b>
                            </Typography>
                            <Typography>
                              Last checkout date:{" "}
                              <b>{processEquip.assigned_date}</b>
                            </Typography>
                            <Typography>
                              Count of equipment permission:{" "}
                              <b>{processEquip.count_of_equipment_per}</b>
                            </Typography>
                          </Box>
                          <TextField
                            fullWidth
                            id="user-notes"
                            label="Notes on user"
                            value={userNote}
                            onChange={(e) => setUserNote(e.target.value)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder="Notes added here save to user profile"
                          />
                          <Button
                            variant="outlined"
                            color="primary"
                            component="span"
                            sx={{ margin: "15px 0" }}
                            onClick={handleUpdateUserImage}
                          >
                            Update Images/Notes
                          </Button>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl>
                          {uploadedImage ? (
                            <BigAvatar alt="image name" src={uploadedImage} />
                          ) : (
                            <BigAvatar
                              alt="image name"
                              src="/static/img/avatars/profile-upload.png"
                            />
                          )}

                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              JustifyContent: "space-between",
                              margin: "10px 0 15px",
                              gap: "15px",
                            }}
                          >
                            <Button
                              variant="outlined"
                              color="primary"
                              component="span"
                              onClick={(e) => clearSelectedImage(e)}
                            >
                              Clear
                            </Button>
                            <Button
                              variant="outlined"
                              color="warning"
                              component="span"
                              onClick={() => handleShowWebCam()}
                            >
                              {showWebCam ? "Close WebCam" : "WebCam"}
                            </Button>
                          </Box>
                          <input
                            accept="image/*"
                            style={{ display: "none" }}
                            id="raised-button-file"
                            multiple
                            type="file"
                            ref={uploadedFileRef}
                            onChange={(e) => handleFileChange(e)}
                          />
                          <label htmlFor="raised-button-file">
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                            >
                              Choose image
                            </Button>
                          </label>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Typography
                          variant="subtitle2"
                          component="div"
                          sx={{
                            visibility: show ? "visible" : "hidden",
                          }}
                        >
                          Equipment currently checked out to this user
                        </Typography>
                        <Card
                          variant="outlined"
                          sx={{
                            borderBottom: 0,
                            visibility: show ? "visible" : "hidden",
                          }}
                        >
                          <TableContainer className="tableContainer">
                            <Table stickyHeader aria-label="sticky table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Name</TableCell>
                                  <TableCell>Type</TableCell>
                                  <TableCell>Date</TableCell>
                                  <TableCell>Action</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {userChecked.map((data) => {
                                  return (
                                    <TableRow hover>
                                      <TableCell>
                                        {data?.equipment?.equipment_name}
                                      </TableCell>
                                      <TableCell>
                                        {data?.equipment?.equipment_type
                                          ? data?.equipment?.equipment_type.name
                                          : null}
                                      </TableCell>
                                      <TableCell>
                                        {data?.assigned_date}
                                      </TableCell>
                                      <TableCell align="left">
                                        <IconButton
                                          aria-label="Settings"
                                          size="large"
                                          aria-owns={
                                            anchorEl ? "icon-menu" : undefined
                                          }
                                          aria-haspopup="true"
                                          onClick={(e) =>
                                            handleActionClick(e, data.id)
                                          }
                                          style={{ color: "#1374C5" }}
                                        >
                                          <SettingsIcon />
                                        </IconButton>
                                        <StyledMenu
                                          id="icon-menu"
                                          anchorEl={anchorEl}
                                          open={Boolean(anchorEl)}
                                          onClose={handleActionClose}
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "right",
                                          }}
                                          transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                          }}
                                        >
                                          <MenuItem
                                            onClick={equipmentCheckInOpen}
                                          >
                                            <ListItemIcon>
                                              <VisibilityIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                              primary="View"
                                              pl={1}
                                            />
                                          </MenuItem>
                                          <MenuItem onClick={assignedEquipment}>
                                            <ListItemIcon>
                                              <DeleteIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                              primary="Check-in"
                                              pl={1}
                                            />
                                          </MenuItem>
                                        </StyledMenu>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                              <TableBody>
                                {check.map((data) => {
                                  return (
                                    <TableRow hover>
                                      <TableCell>{data?.name}</TableCell>
                                      <TableCell>{data?.type}</TableCell>
                                      <TableCell>{data?.date}</TableCell>
                                      <TableCell align="left">
                                        <IconButton
                                          aria-label="Settings"
                                          size="large"
                                          aria-owns={
                                            anchorEl ? "icon-menu" : undefined
                                          }
                                          aria-haspopup="true"
                                          onClick={(e) =>
                                            handleActionClick(e, data.id)
                                          }
                                          style={{ color: "#1374C5" }}
                                        >
                                          <SettingsIcon />
                                        </IconButton>
                                        <StyledMenu
                                          id="icon-menu"
                                          anchorEl={anchorEl}
                                          open={Boolean(anchorEl)}
                                          onClose={handleActionClose}
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "right",
                                          }}
                                          transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                          }}
                                        >
                                          <MenuItem
                                            onClick={equipmentCheckInOpen}
                                          >
                                            <ListItemIcon>
                                              <VisibilityIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                              primary="View"
                                              pl={1}
                                            />
                                          </MenuItem>
                                          <MenuItem onClick={assignedEquipment}>
                                            <ListItemIcon>
                                              <DeleteIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                              primary="Check-in"
                                              pl={1}
                                            />
                                          </MenuItem>
                                        </StyledMenu>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
          {equipmentCheckIn && (
            <EquipmentCheckIn
              equipmentCheckIn={equipmentCheckIn}
              setEquipCheckIn={setEquipCheckIn}
              equipmentCheckInOpen={equipmentCheckInOpen}
              equipmentCheckInClose={equipmentCheckInClose}
              id={id}
            />
          )}
        </CardContent>
      </Card>
      {showWebCam && (
        <CameraFeeding
          showWebCam={showWebCam}
          closeWabCam={() => setShowWebCam(false)}
          // value={uploadedImage}
          // onChange={handleFileChange}
          setUploadedImage={setUploadedImage}
          uploadedImage={uploadedImage}
        />
      )}
    </>
  );
}

function ProcessEquipment() {
  return (
    <React.Fragment>
      <Helmet title="Process Equipment" />
      <Typography variant="h3" gutterBottom display="inline">
        Process Equipment
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/equipment/equipment-list">
          Equipment List
        </Link>
        <Typography>Process Equipment</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <AddUserForm />
    </React.Fragment>
  );
}

export default ProcessEquipment;
