import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import * as Yup from "yup";
import { Formik, Field } from "formik";
import { useDispatch } from "react-redux";
import { getFileBase64, handleApiResonseErrors } from "../../helper/helper";
import CameraFeeding from "../../helper/camera-feeding";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
} from "../../redux/slices/equipmentSlice";
import {
  AddEquipUser,
  GetAllDepartment,
  PositionByDepartment,
  GetSecurityLevel,
} from "../../api";
import {
  Avatar,
  Button as MuiButton,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormLabel,
  Select,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Link,
  Typography,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormHelperText,
  Box,
  Menu,
  Autocomplete,
  Checkbox,
  ListItemButton,
  ListItem,
  List,
  ListItemText,
  FormGroup,
  FormControlLabel,
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Paper,
} from "@mui/material";
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Error as ErrorIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  ExpandMore as ExpandMoreIcon,
  ContentPasteOffSharp,
} from "@mui/icons-material";
import { spacing, typography } from "@mui/system";
import CustomSelect from "../../components/form-components/CustomSelect";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 20px 0 15px ${(props) => props.theme.spacing(2)};
`;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const Accordion = styled(MuiAccordion)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
// Classes Styling
const useStyles = makeStyles((theme) => ({
  accordion: {
    marginTop: 16,
    borderTop: 0,
    "& .MuiAccordion-root": {
      "& .MuiAccordionSummary-root": {
        padding: "0 12px",
        opacity: ".5",
      },
      "& .MuiAccordionDetails-root": {
        padding: "8px 12px 12px",
      },
    },
    "& .MuiAccordion-root::before": {
      display: "none",
    },
    "& .Mui-expanded": {
      minHeight: "auto",
    },
  },
  textAreaControl: {
    position: "relative",
    "& .MuiFormLabel-root": {
      position: "absolute",
      top: "-24px",
      padding: "0 5px",
      zIndex: "1",
      left: "-5px",
      fontSize: "10px",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const Image = styled.img`
  max-width: initial;
  margin: 20px auto 0;
  width: 100%;
`;
const StyledTooltip = ({ title, children, ...props }) => (
  <Tooltip
    {...props}
    title={title}
    placement="top-end"
    /* arrow */
    componentsProps={{
      tooltip: {
        sx: {
          lineHeight: "1.6",
          fontSize: "10px",
          marginRight: "-15px",
          maxWidth: "330px",
        },
      },
    }}
  >
    {children}
  </Tooltip>
);
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    maxWidth: "460px",
    width: "100%",
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      "& .MuiMenuItem-root": {
        padding: "10px",
        display: "block",
        "& .MuiList-root": {
          paddingTop: "0px",
          marginTop: "15px",
          paddingBottom: "0px",
          "& .MuiListItem-root": {
            padding: "0px",
            background: "#7aa8ff0d",
            "& .MuiListItemButton-root": {
              padding: "3px 5px",
            },
          },
        },
      },
      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));

const selectSites = [
  { title: "None selected" },
  { title: "One" },
  { title: "Two" },
  { title: "Three" },
  { title: "Fouor" },
  { title: "Five" },
  { title: "Six" },
];

const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
function AddUserForm() {
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [showWebCam, setShowWebCam] = React.useState(false);
  const [uploadedImage, setUploadedImage] = React.useState(null);
  const uploadedFileRef = useRef();
  const classes = useStyles();
  const [values, setValues] = React.useState({
    showPassword: false,
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [positionselect, setPosition] = React.useState("");
  const selectPositionlevel = (event) => {
    setPosition(event.target.value);
  };
  const [userselectDepartment, setDepartment] = React.useState("");
  const selectDepartment = (event) => {
    setDepartment(event.target.value);
  };
  const [securitylevel, setSecurityLevel] = React.useState("");
  const selectSecurityLevel = (event) => {
    setSecurityLevel(event.target.value);
  };
  const [open, setOpen] = React.useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("sm");

  const [otherinfo, setOtherInfo] = React.useState("");
  const selectOtherInfo = (event) => {
    setOtherInfo(event.target.value);
  };

  // Site Dropdown
  const [siteDropdown, setSiteDropdown] = React.useState(null);
  const siteDropdownOpen = (event) => {
    setSiteDropdown(event.currentTarget);
  };
  const siteDropdownClose = () => {
    setSiteDropdown(null);
  };
  const handleShowWebCam = () => {
    let changeSetShowWebCam = !showWebCam;
    setShowWebCam(changeSetShowWebCam);
  };
  const handleFileChange = (evt) => {
    let file = evt.target.files[0];
    getFileBase64(file, (result) => {
      setUploadedImage(result);
    });
  };

  const clearSelectedImage = () => {
    setUploadedImage(null);
    uploadedFileRef.current.value = "";
  };
  // department list
  const department = [
    { id: 1, value: "departemnt 1" },
    { id: 2, value: "departemnt 2" },
    { id: 3, value: "departemnt 3" },
  ];
  // User other infor expansion
  const [userotherinfoexpand, setUserOtherInfoExpand] = React.useState(false);
  const handleUserOtherInfoExpand = (panel) => (event, newExpanded) => {
    setUserOtherInfoExpand(newExpanded ? panel : false);
  };

  const [departmentList, setDepartmentList] = useState([]);
  const handleDepartment = () => {
    GetAllDepartment()
      .then((res) => {
        setDepartmentList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [departmentData, setDepartmentData] = useState("");
  const [positionList, setPositionList] = useState([]);
  const [positionData, setPositionData] = useState("");
  const [sLevelData, setSLevelData] = useState("");
  const [sLevel, setSLevel] = useState({});
  const [equipmentCheck, setEquipmentCheck] = useState([]);
  const [errMsg, setErrMsg] = useState("");
  const handleChangeDepartment = (value) => {
    // setDepartmentData(e.target.value);
    // setPositionData("");
    setSLevelData("");
    PositionByDepartment(value)
      .then((res) => {
        if (res.statusCode === 200) {
          setPositionList(res.data);
        } else {
          setPositionList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangeSecurity = (value) => {
    GetSecurityLevel(value)
      .then((res) => {
        if (res.statusCode === 200) {
          setSLevel(res.data);
          setSLevelData(res.data.security_level);
          setEquipmentCheck(res.equipment_list);
        } else {
          setEquipmentCheck([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //get all task checkbox
  const [modulearray, setmodulearray] = useState([]);
  const handleChecked = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setmodulearray([...modulearray, value]);
    } else {
      setmodulearray(modulearray.filter((e) => e !== value));
    }
  };

  useEffect(() => {
    handleDepartment();
  }, []);

  const initialValues = {
    barcode_id: "",
    employee_id: "",
    first_name: "",
    last_name: "",
    department: "",
    position: "",
    security_level: "",
    email: "",
    address_one: "",
    address_two: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    user_note: "",
    equipment: [],
  };
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validationSchema = Yup.object().shape({
    barcode_id: Yup.number()
      .required("Please enter barcode ID")
      .positive("Please enter a valid number.")
      .typeError("Please enter a valid number."),
    first_name: Yup.string().required("Please enter first name"),
    last_name: Yup.string().required("Please enter last name"),
    department: Yup.string().required("Please select department"),
    position: Yup.string().required("Please select position"),
    email: Yup.string().nullable().email("Please enter valid email"),
    equipment: Yup.array().min(1, "Please select one of these equipment"),
    phone: Yup.string()
      .nullable()
      .matches(phoneRegExp, "Phone number is not valid")
      // .min(10, "Phone must be at least 10 characters")
      .max(15, "Phone must be at most 15 characters"),
  });

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    setLoading(true);
    try {
      let formData = {
        employee_id: values.barcode_id,
        other_employee: values.employee_id,
        fname: values.first_name,
        lname: values.last_name,
        department: values.department,
        position: values.position,
        security_level: sLevelData,
        email: values.email,
        address1: values.address_one,
        address2: values.address_two,
        city: values.city,
        state: values.state,
        zip: values.zip,
        phone: values.phone,
        note: values.user_note,
        equipment_type: values.equipment,
        image: uploadedImage,
      };
      AddEquipUser(formData)
        .then((res) => {
          if (res.statusCode === 200) {
            setLoading(false);
            dispatch(getSnackOpen());
            dispatch(getMsg("Equipment user has been submitted successfully"));
            navigate({
              pathname: "/equipment/equipment-user-list",
            });
          } else {
            dispatch(getSnackClose());
            setLoading(false);
          }
        })
        .catch((err) => {
          // console.log(err.data.errors);
          setLoading(false);
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            setErrMsg(error);
          }
          dispatch(getSnackClose());
        });

      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
          values,
          status,
        }) => (
          <Card mb={6}>
            <CardContent>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <TextField
                        fullWidth
                        id="barcode_id"
                        name="barcode_id"
                        label="Barcode ID*"
                        value={values.barcode_id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.barcode_id && errors.barcode_id)}
                        helperText={touched.barcode_id && errors.barcode_id}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Type or scan existing barcode"
                      />
                    </FormControl>
                    <FormHelperText
                      sx={{ color: "#d32f2f", marginLeft: "14px" }}
                    >
                      {errMsg}
                    </FormHelperText>
                    <FormControl mt={6}>
                      <TextField
                        fullWidth
                        id="emp-id"
                        label="Employee ID"
                        name="employee_id"
                        value={values.employee_id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Corporate/Global ID"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <TextField
                        fullWidth
                        id="fname"
                        label="First name*"
                        name="first_name"
                        value={values.first_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.first_name && errors.first_name)}
                        helperText={touched.first_name && errors.first_name}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="John"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <TextField
                        fullWidth
                        id="lname"
                        label="Last name*"
                        name="last_name"
                        value={values.last_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.last_name && errors.last_name)}
                        helperText={touched.last_name && errors.last_name}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Smith"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <InputLabel
                        shrink
                        id="department"
                        sx={{
                          paddingLeft: "6px",
                          paddingRight: "6px",
                          marginLeft: "-4px",
                        }}
                      >
                        Department*
                      </InputLabel>
                      <Select
                        labelId="department"
                        id="department-select"
                        name="department"
                        label="Department*"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={values.department}
                        onChange={(e) => {
                          setFieldValue("department", e.target.value);
                          handleChangeDepartment(e.target.value);
                        }}
                        onBlur={handleBlur}
                        error={Boolean(touched.department && errors.department)}
                        helperText={touched.department && errors.department}
                        fullWidth
                        displayEmpty
                      >
                        <MenuItem disabled value="">
                          Select department
                        </MenuItem>
                        {departmentList.map((data) => {
                          return (
                            <MenuItem value={data.id} key={data.id}>
                              {data.department_name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText sx={{ color: "#d32f2f" }}>
                        {touched.department && errors.department}
                      </FormHelperText>
                    </FormControl>

                    <FormControl mt={6}>
                      <InputLabel
                        shrink
                        id="position"
                        sx={{
                          paddingLeft: "6px",
                          paddingRight: "6px",
                          marginLeft: "-4px",
                        }}
                      >
                        Position*
                      </InputLabel>
                      <Select
                        labelId="position"
                        id="position-select"
                        name="position"
                        label="position*"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={values.position}
                        onChange={(e) => {
                          setFieldValue("position", e.target.value);
                          handleChangeSecurity(e.target.value);
                        }}
                        onBlur={handleBlur}
                        error={Boolean(touched.position && errors.position)}
                        // helperText={touched.position && errors.position}
                        fullWidth
                        displayEmpty
                      >
                        <MenuItem disabled value="">
                          Select position
                        </MenuItem>
                        {positionList.map((data) => {
                          return (
                            <MenuItem value={data.id} key={data.id}>
                              {data.position_name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText sx={{ color: "#d32f2f" }}>
                        {touched.position && errors.position}
                      </FormHelperText>
                    </FormControl>
                    <FormControl mt={6}>
                      <InputLabel
                        shrink
                        id="position"
                        sx={{
                          paddingLeft: "6px",
                          paddingRight: "6px",
                          marginLeft: "-4px",
                        }}
                      >
                        Security level (cannot edit)
                      </InputLabel>
                      <Select
                        labelId="position"
                        id="position-select"
                        name="security_level"
                        label="position*"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={sLevelData}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.security_level && errors.security_level
                        )}
                        helperText={
                          touched.security_level && errors.security_level
                        }
                        fullWidth
                        displayEmpty
                        disabled
                      >
                        <MenuItem value="">Position security level</MenuItem>
                        <MenuItem value={sLevel.security_level}>
                          {sLevel.security_level}
                        </MenuItem>
                      </Select>
                      <FormHelperText sx={{ color: "#d32f2f" }}>
                        {touched.security_level && errors.security_level}
                      </FormHelperText>
                    </FormControl>
                    <Accordion
                      mt={5}
                      expanded={
                        userotherinfoexpand === "user-other-info-expand"
                      }
                      onChange={handleUserOtherInfoExpand(
                        "user-other-info-expand"
                      )}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="user-other-info"
                        id="user-other-info"
                      >
                        <Typography>User other information</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={6}>
                          <Grid item xs={12} lg={12}>
                            <FormControl>
                              <TextField
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={Boolean(touched.email && errors.email)}
                                helperText={touched.email && errors.email}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="me@you.com"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} lg={12}>
                            <FormControl mt={3}>
                              <TextField
                                fullWidth
                                id="address1"
                                label="Address1"
                                name="address_one"
                                value={values.address_one}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="123 First Ave"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} lg={12}>
                            <FormControl mt={3}>
                              <TextField
                                fullWidth
                                id="address2"
                                label="Address2"
                                name="address_two"
                                value={values.address_two}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="Ste 333"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} lg={12}>
                            <FormControl mt={3}>
                              <TextField
                                fullWidth
                                id="city"
                                label="City"
                                name="city"
                                value={values.city}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="Miami"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} lg={12}>
                            <FormControl mt={3}>
                              <TextField
                                fullWidth
                                id="state"
                                label="State"
                                name="state"
                                value={values.state}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="FL"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} lg={12}>
                            <FormControl mt={3}>
                              <TextField
                                fullWidth
                                id="zip"
                                label="Zip"
                                name="zip"
                                value={values.zip}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="xxxxx"
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {/* <FormControl>
                      <BigAvatar
                        alt="Remy Sharp"
                        src="/static/img/avatars/profile-upload.png"
                      />
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          JustifyContent: "space-between",
                          margin: "10px 0 15px",
                          gap: "15px",
                        }}
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          component="span"
                        >
                          Clear
                        </Button>
                        <Button
                          variant="outlined"
                          color="warning"
                          component="span"
                        >
                          WebCam
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          component="span"
                        >
                          Capture
                        </Button>
                      </Box>
                      <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id="raised-button-file"
                        multiple
                        type="file"
                      />
                      <label htmlFor="raised-button-file">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                        >
                          Choose image
                        </Button>
                      </label>
                    </FormControl> */}
                    <FormControl>
                      {uploadedImage ? (
                        <Image alt="image name" src={uploadedImage} />
                      ) : (
                        <Image
                          alt="image name"
                          src="/static/img/lost-found/lost_and_found_pic.jpg"
                        />
                      )}

                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          JustifyContent: "space-between",
                          margin: "10px 0 15px",
                          gap: "15px",
                        }}
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          component="span"
                          onClick={(e) => clearSelectedImage(e)}
                        >
                          Clear
                        </Button>
                        <Button
                          variant="outlined"
                          color="warning"
                          component="span"
                          onClick={() => handleShowWebCam()}
                        >
                          {showWebCam ? "Close WebCam" : "WebCam"}
                        </Button>
                        {/* {showWebCam && (
                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      component="span"
                                      onClick={() => takeImageToWebCam()}
                                    >
                                      Capture
                                    </Button>
                                  )} */}
                      </Box>
                      <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id="raised-button-file"
                        multiple
                        type="file"
                        ref={uploadedFileRef}
                        onChange={(e) => handleFileChange(e)}
                      />
                      <label htmlFor="raised-button-file">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                        >
                          Choose image
                        </Button>
                      </label>
                    </FormControl>
                    <FormControl mt={6}>
                      <TextField
                        fullWidth
                        id="phone"
                        label="Phone"
                        name="phone"
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="XXX-XXX-XXXX"
                        error={Boolean(touched.phone && errors.phone)}
                        helperText={touched.phone && errors.phone}
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <TextField
                        fullWidth
                        id="user-note"
                        label="User notes"
                        name="user_note"
                        value={values.user_note}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder=""
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <FormLabel sx={{ fontSize: "11px" }}>
                        Select equipment*
                      </FormLabel>
                      {equipmentCheck.map((d) => {
                        return (
                          <label>
                            <Field
                              type="checkbox"
                              name="equipment"
                              value={d.id.toString()}
                            />
                            {d.name}
                          </label>
                        );
                      })}
                      <FormHelperText sx={{ color: "#d32f2f" }}>
                        {touched.equipment && errors.equipment}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      mt={6}
                      sx={{ width: "175px" }}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        )}
      </Formik>
      {showWebCam && (
        // <CameraFeed
        //   sendFile={uploadImage}
        //   takePhoto={takePhoto}
        // />

        <CameraFeeding
          showWebCam={showWebCam}
          closeWabCam={() => setShowWebCam(false)}
          // value={uploadedImage}
          // onChange={handleFileChange}
          setUploadedImage={setUploadedImage}
          uploadedImage={uploadedImage}
        />
      )}
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
}

function AddEquipmentUser() {
  return (
    <React.Fragment>
      <Helmet title="Add Equipment User" />
      <Typography variant="h3" gutterBottom display="inline">
        Add Equipment User
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/equipment/equipment-user-list">
          List Equipment User
        </Link>
        <Typography>Add Equipment User</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <AddUserForm />
    </React.Fragment>
  );
}

export default AddEquipmentUser;
