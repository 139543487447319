import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Formik } from "formik";
import CustomTextField from "../../../components/form-components/CustomTextField";
import { GetAddKeyTypeList, DeleteKeyType, AddNewKeyType } from "../../../api";
import { handleApiResonseErrors } from "../../../helper/helper";
import * as Yup from "yup";
import EditKeyType from "./EditKeyType";
import { useDispatch } from "react-redux";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../../redux/slices/equipmentSlice";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Menu,
  Typography,
  FormControl as MuiFormControl,
  Button,
  IconButton,
  Paper,
  Grid,
  Table,
  TableContainer,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CircularProgress,
} from "@mui/material";
import {
  CancelRounded as CancelRoundedIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));

const AddKeyType = ({ addKeyType, addKeyTypeClose, childToParent }) => {
  // Action Dropdown
  const [addKeyTypeAction, setAddKeyTypeAction] = React.useState(null);
  const [keyTypeList, setKeyTypeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [typeId, setTypeId] = useState(null);
  const [close, setClose] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [singleData, setSingleData] = useState({});
  const dispatch = useDispatch();
  const addKeyTypeActionClick = (event, id, data) => {
    setAddKeyTypeAction(event.currentTarget);
    setSingleData(data);
    setTypeId(id);
  };
  const addKeyTypeActionClose = () => {
    setAddKeyTypeAction(null);
  };

  /* The above code is creating a state variable called editKeyCabinet and setting it to false. It is
  also creating a function called editKeyCabinetOpen that sets the state variable to true. */
  const [editKeyType, setEditKeyType] = useState(false);
  const editKeyTypeOpen = () => {
    setEditKeyType(true);
  };

  /**
   * This function closes the edit key Type modal
   */
  const editKeyTypeClose = () => {
    setEditKeyType(false);
  };
  //method for open the delete dialog
  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  /**
   * It closes the delete dialog and resets the anchor element and id
   */
  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  /**
   * It closes the dialog box.
   * @param e - The event object
   */
  const closeDialogHandle = (e) => {
    setClose(e.target.value);
  };

  /**
   * This function is used to get the list of key cabinets from the database
   */
  const getAddKeyTypeList = () => {
    setLoading(true);
    GetAddKeyTypeList()
      .then((res) => {
        if (res.statusCode === 200) {
          setKeyTypeList(res.data);
          childToParent(res.data, "key_type");
          setLoading(false);
        } else {
          setKeyTypeList([]);
          childToParent(res.data, "key_type");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  /**
   * This function is used to delete a key cabinet
   */
  const deleteKeyType = () => {
    handleDeleteClose();
    DeleteKeyType(typeId)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(getSnackOpen());
          dispatch(getMsg("Record has been deleted successfully"));
          dispatch(getSnackAlert("success"));
          getAddKeyTypeList();
        } else {
          dispatch(getSnackOpen());
          dispatch(getMsg("Something went wrong"));
          dispatch(getSnackAlert("error"));
        }
      })
      .catch((err) => {
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        if (error) {
          setErrMsg(error);
        }
        dispatch(getSnackClose());
      });
  };

  /* The above code is using the useEffect hook to call the addKeyCabinetList function when the component
mounts. */
  useEffect(() => {
    getAddKeyTypeList();
  }, []);

  const validationSchema = Yup.object().shape({
    type_name: Yup.string().required("Please enter key type name"),
    type_code: Yup.string().required("Please enter key type code"),
  });

  /* Setting the initial values for the form. */
  let initialValues = {
    type_name: "",
    type_code: "",
  };

  /**
   * A function that is called when the form is submitted.
   * @param values - An object containing all values of the form.
   */
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      let formData = {
        type_name: values.type_name,
        type_code: values.type_code,
      };
      AddNewKeyType(formData)
        .then((res) => {
          if (res.statusCode === 200) {
            dispatch(getSnackOpen());
            dispatch(getMsg("Key type has been submitted successfully"));
            dispatch(getSnackAlert("success"));
            if (close === "close") {
              addKeyTypeActionClose();
            }
            getAddKeyTypeList();
          } else {
            dispatch(getSnackClose());
          }
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            setErrMsg(error);
          }
          dispatch(getSnackClose());
        });
      resetForm();
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  return (
    <>
      <Dialog
        open={addKeyType}
        onClocse={addKeyTypeClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "700px",
            },
          },
        }}
        aria-labelledby="add-key-type-title"
        aria-describedby="add-key-type-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="add-key-type-title"
        >
          <Typography variant="h4">Add Key type</Typography>
          <DialogActions>
            <IconButton sx={{ marginRight: "-10px" }} onClick={addKeyTypeClose}>
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              values,
              status,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <CustomTextField
                        name="type_name"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Type name"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <CustomTextField
                        name="type_code"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Type code"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Button color="primary" variant="outlined" type="submit">
                      Save & add more
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      sx={{ marginLeft: "10px" }}
                      type="submit"
                      value={"close"}
                      onClick={closeDialogHandle}
                    >
                      Save & done
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
          <Paper sx={{ marginTop: "30px" }}>
            <Dialog
              open={openDelete}
              onClose={handleDeleteClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Are you sure you want to delete key type?"}
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleDeleteClose} variant="contained">
                  Cancel
                </Button>
                <Button onClick={deleteKeyType} autoFocus variant="contained">
                  OK
                </Button>
              </DialogActions>
            </Dialog>
            <Typography variant="h4" component="div" gutterBottom>
              Existing Key Type
            </Typography>
            <TableContainer className="tableContainer">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow hover>
                    <TableCell>Name</TableCell>
                    <TableCell>Code</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                {loading ? (
                  <TableCell colSpan={13} align="center" padding="none">
                    <CircularProgress size={40} />
                  </TableCell>
                ) : (
                  <TableBody>
                    {keyTypeList.map((data) => {
                      return (
                        <TableRow hover>
                          <TableCell>{data.type_name}</TableCell>
                          <TableCell>{data.type_code}</TableCell>
                          <TableCell padding="none">
                            <IconButton
                              aria-label="Settings"
                              size="large"
                              aria-owns={
                                addKeyTypeAction ? "key-type-action" : undefined
                              }
                              aria-haspopup="true"
                              onClick={(e) =>
                                addKeyTypeActionClick(e, data.id, data)
                              }
                              style={{ color: "#1374C5" }}
                            >
                              <SettingsIcon />
                            </IconButton>
                            <StyledMenu
                              id="key-type-action"
                              anchorEl={addKeyTypeAction}
                              open={Boolean(addKeyTypeAction)}
                              onClose={addKeyTypeActionClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <MenuItem
                                onClick={() => {
                                  editKeyTypeOpen();
                                  addKeyTypeActionClose();
                                }}
                              >
                                <ListItemIcon>
                                  <EditIcon />
                                </ListItemIcon>
                                <ListItemText primary="Edit" pl={1} />
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  handleDeleteOpen();
                                  addKeyTypeActionClose();
                                }}
                              >
                                <ListItemIcon>
                                  <DeleteIcon />
                                </ListItemIcon>
                                <ListItemText primary="Delete" pl={1} />
                              </MenuItem>
                            </StyledMenu>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {keyTypeList.length === 0 && (
                      <TableRow>
                        <TableCell align="center" colSpan={10}>
                          No records found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={addKeyTypeClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {editKeyType && (
        <EditKeyType
          editKeyType={editKeyType}
          editKeyTypeClose={editKeyTypeClose}
          setEditKeyType={setEditKeyType}
          editKeyTypeOpen={editKeyTypeOpen}
          singleData={singleData}
          getAddKeyTypeList={getAddKeyTypeList}
          typeId={typeId}
        />
      )}
    </>
  );
};

export default AddKeyType;
