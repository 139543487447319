import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { format } from "date-fns";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import CustomDateRange from "../../components/form-components/CustomDateRange";
import {
  Checkbox,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  /* FormGroup, */
  TableContainer,
  InputAdornment,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  Radio,
  CardContent,
  FormLabel,
  RadioGroup,
  Card,
  Box,
  Button,
  /* List,
  ListItem, */
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  Chip,
  List,
  ListItem,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Dialog,
  Tooltip,
  CircularProgress,
  Pagination,
} from "@mui/material";
import { DatePicker } from "@mui/lab";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  AddBox as AddBoxIcon,
  Error as ErrorIcon,
  Visibility as VisibilityIcon,
  Settings as SettingsIcon,
  Search as SearchIcon,
  ExpandMore as ExpandMoreIcon,
  Map as MapIcon,
  Cancel as CancelIcon,
  PictureAsPdf as PictureAsPdfIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import {
  NonTourTaskList,
  NonTourTaskColumns,
  NonTourUserList,
} from "../../api";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { CSVLink } from "react-csv";
import autoTable from "jspdf-autotable";
import jsPDF from "jspdf";
import {
  nonTourTaskList,
  getNonTourTaskChecked,
} from "../../redux/slices/reportSlice";
import { useDispatch, useSelector } from "react-redux";
import NTourTaskD1 from "./inner-components/NTourTaskD1";
import NTourTaskD2 from "./inner-components/NTourTaskD2";
import { subDays } from "date-fns";

/* const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing); */

// Classes Styling
const useStyles = makeStyles((theme) => ({
  // searchFilter: {
  //   position: "relative",
  // },
  // clearSearch: {
  //   position: "absolute",
  //   right: "45px",
  // },
  dateRangeDropdown: {
    position: "absolute",
    left: 0,
    top: "100%",
    width: "100%",
    zIndex: 2,
    marginLeft: "0 !important",
    maxHeight: "200px",
    overflowY: "auto",

    "& .MuiBox-root": {
      padding: "15px",
      "& .MuiButton-contained": {
        marginLeft: "15px",
      },
    },
    "& .MuiList-root": {
      padding: 0,
      "& .MuiListItem-root:hover": {
        backgroundColor: "#376fd012",
      },
    },
  },
  DateList: {
    border: "1px solid #0000001c",
    marginTop: "22px",
    padding: 0,
    "& .MuiListItem-root": {
      borderBottom: "1px solid #0000001c",
    },
    "& .MuiListItem-root:last-child": {
      borderBottom: 0,
    },
    "& .MuiListItem-root:hover": {
      backgroundColor: "#376fd012",
    },
  },
  ErrorImage: {
    width: "250px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "15px",
    marginBottom: "15px",
  },
}));

const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  /* min-width: 148px; */
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
// Image Wrapper
const ImageWrapper = styled.div`
  margin-top: 10px;
`;
// const Image = styled.img`
//   width: 100%;
// `;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      "& a": {
        color:
          theme.palette.mode === "light" ? "#374151" : theme.palette.grey[300],
        textDecoration: "none",
      },
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledForm = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    marginLeft: "75px",
    marginTop: "8px",
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      minWidth: "435px",
      "& form": {
        padding: "15px 20px",
      },
      "& .MuiMenuItem-root": {
        padding: "0",
        display: "block",
        "& .MuiFormControl-root": {
          display: "block",
          width: "100%",
          marginBottom: "20px",
        },
      },
      "& .MuiMenuItem-root:hover": {
        background: "transparent",
      },
      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "140px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
function createData(
  nontourtask,
  counttask,
  countuser,
  avgmins,
  highmins,
  lowmins,
  totaltimemins,
  lastcompleted
  //action
) {
  return {
    nontourtask,
    counttask,
    countuser,
    avgmins,
    highmins,
    lowmins,
    totaltimemins,
    lastcompleted,
    // action,
  };
}
const rows = [createData("Code 1", "15", "4", "5.22", "6.80", "", "78.27", 0)];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    colArray,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const [headColumn, setHeadColumn] = useState([]); //My header...
  const checkedValue = useSelector((state) => state.report.nonTourTaskChecked);

  //  Function for preselected options in site.
  const headResult = () => {
    let result = [];
    checkedValue.forEach((obj) => {
      colArray.forEach((result3) => {
        if (result3.key == obj) {
          result.push(result3);
        }
      });
    });
    result.sort((a, b) => {
      return a.sort_id - b.sort_id;
    });
    setHeadColumn(result);
  };

  useEffect(() => {
    headResult();
  }, [checkedValue, colArray]);

  return (
    <TableHead>
      <TableRow>
        <TableCell>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all data" }}
          />
        </TableCell>
        {headColumn.map((headCell) => (
          <TableCell
            key={headCell.sort_id}
            align="left"
            sx={{ whiteSpace: "nowrap" }}
            // padding={headCell.key === "task_name" ? "none" : "normal"}
            // sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.key}
              direction={orderBy === headCell.key ? order : "asc"}
              onClick={createSortHandler(headCell.key)}
              // hideSortIcon={headCell.key === "task_name" ? false : true}
            >
              <Tooltip title={headCell.tooltipMsg}>
                <div>{headCell.value}</div>
              </Tooltip>
              {headCell.value === "Non Tour Task" ? (
                <Tooltip
                  title="Click task name to show a log of recent activities"
                  placement="top"
                  color="primary"
                  sx={{ marginLeft: "5px" }}
                >
                  <ErrorIcon fontSize="small" />
                </Tooltip>
              ) : null}
              {/* {headCell.icon} */}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const {
    numSelected,
    totalRecord,
    dataToFilter,
    filterRecord,
    tourTaskList,
    setDataToFilter,
    startDateToFilter,
    setStartDateToFilter,
    endDateToFilter,
    setEndDateToFilter,
    setSearchInput,
    colArray,
    getColumnList,
    getColumnChecked,
    CSVData,
    handleFirstPage,
    setCurrentPage,
  } = props;
  const [actions, setActions] = React.useState("");
  const [searchTaskname, setSearchTaskname] = useState("");
  const checkedValue = useSelector((state) => state.report.nonTourTaskChecked);
  const [datePDF, setDatePDF] = useState(new Date());
  const LoggedInUserInfo = useSelector((state) => state.login.user);
  const [CSVRecord, setCSVRecord] = useState([]);
  const dispatch = useDispatch();
  const selectedLocation = useSelector((state) => state.login.Location);

  const [isFilter, setIsFilter] = useState(false);
  const handleChange = (event) => {
    setActions(event.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    if (CSVData.length > 0) {
      getNonTourTask();
    }
  };

  const getNonTourTask = () => {
    let payload = {
      task_name: CSVData.toString(),
    };
    NonTourUserList(payload)
      .then((res) => {
        setCSVRecord(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // Search Dropdown
  const [anchorForm, setAnchorForm] = React.useState(null);
  const handleClickDropdown = (event) => {
    setAnchorForm(event.currentTarget);
  };

  const handleCloseForm = () => {
    setAnchorForm(null);
  };
  const [value, setValue] = React.useState(null);
  // Select Result
  const [result, setResult] = React.useState("");
  const selectResult = (event) => {
    setResult(event.target.value);
  };

  // Columns Dropdown
  const [anchorColumn, setAnchorColumn] = React.useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };
  //Scan Visitor ID modal
  const [open, visitorModal] = React.useState(false);
  // const scanVisitorModalOpen = () => {
  //   visitorModal(true);
  // };
  // const scanVisitorModalClose = () => {
  //   visitorModal(false);
  // };
  const [fadebox, setFadedBox] = React.useState(false);
  const classes = useStyles();

  const generatePdf = () => {
    const newRange =
      moment(startDateToFilter).format("MM/DD/YYYY") +
      " to " +
      moment(endDateToFilter).format("MM/DD/YYYY");
    // const newDatePDF = moment(datePDF).format("MM-DD-YYYY");
    const newDatePDF = moment(new Date()).format("MM/DD/YYYY");
    const newTimePDF = moment(new Date()).format("HH:mm:ss");

    const doc = new jsPDF();
    var img = new Image();
    img.src = "/static/img/brands/logo.png";
    doc.addImage(img, "png", 10, 10, 45, 20);

    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text(100, 15, "Non Tour Task Report", "center");

    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text(100, 25, `${selectedLocation.LocationName}`, "center");

    doc.setFontSize(8);
    doc.setFont("helvetica", "bold");
    doc.text("Created By : ", 15, 45);
    // doc.text(`Tour Name :        ${reportData.tour_name}`, 15, 50);

    doc.setFontSize(8);
    doc.setFont("helvetica", "normal");
    doc.text(LoggedInUserInfo.alias_name, 33, 45);

    doc.setFontSize(8);
    doc.setFont("helvetica", "bold");
    doc.text("Created Date : ", 15, 50);
    // doc.text(`Tour By :             ${reportData.user_name}`, 15, 55);

    doc.setFontSize(8);
    doc.setFont("helvetica", "normal");
    doc.text(newDatePDF, 36, 50);

    doc.setFontSize(8);
    doc.setFont("helvetica", "bold");
    doc.text("Created Time : ", 15, 55);
    // doc.text(`Tour Status :        ${reportData.report_status}`, 15, 60);

    doc.setFontSize(8);
    doc.setFont("helvetica", "normal");
    doc.text(newTimePDF, 36, 55);

    doc.setFontSize(8);
    doc.setFont("helvetica", "bold");
    doc.text("Report Date : ", 15, 60);
    // doc.text(`Tour Start :          ${reportData.start_time}`, 15, 65);

    doc.setFontSize(8);
    doc.setFont("helvetica", "normal");
    doc.text(newRange, 34, 60);

    // autoTable(doc, { html: "#my-table" });
    const expPdfData = tourTaskList.map((t) => {
      return [
        t.task_name,
        t.count_task,
        t.count_user,
        t.avg_time,
        t.highest_elapsed_time,
        t.lowestlapsedTime,
        t.total_time,
        t.last_completed,
      ];
    });
    autoTable(doc, {
      margin: { top: 70 },
      head: [
        [
          "Task Name",
          "Count Tasks",
          "Count Users",
          "Avg. (mins)",
          "High (mins)",
          "Low (mins)",
          "Total Time (mins)",
          "Last Completed",
        ],
      ],
      body: expPdfData,
      showFoot: "everyPage",
      // showHead: "firstPage",
      // Footer
      didDrawPage: function (data) {
        // Footer
        var str = "Page " + doc.internal.getNumberOfPages();

        doc.setFontSize(10);
        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        doc.text(str, 110, pageHeight - 3);
        doc.setLineWidth(0.1);
        doc.line(10, pageHeight - 8, 200, pageHeight - 8, "F");
      },
    });
    let fileName =
      "SOVA-Non-Tour-Task-" + moment(new Date()).format("YYYY-MM-DD") + ".pdf";
    doc.save(fileName);
  };

  const applySearch = () => {
    const payload = {
      // startTemp: startDateToFilter,
      // endTemp: endDateToFilter,
      // start_date: startDateToFilter
      //   ? moment(startDateToFilter).format("YYYY-MM-DD")
      //   : "",
      // end_date: endDateToFilter
      //   ? moment(endDateToFilter).format("YYYY-MM-DD")
      //   : "",
      task_name: searchTaskname,
    };
    filterRecord(payload);
  };

  //function for reseting the filter
  const resetFilter = () => {
    // setStartDateToFilter("");
    // setEndDateToFilter("");
    setStartDateToFilter(subDays(new Date(), 3));
    setEndDateToFilter(new Date());
    setSearchTaskname("");
    filterRecord({});
  };

  let exportCSVheaders = [
    { label: "Date Time", key: "scan_time" },
    { label: "User Name", key: "user_name" },
    { label: "Location", key: "task_location" },
    { label: "Comment", key: "comment" },
  ];

  //Handler for checked unchecked
  const handleChecked = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      dispatch(getNonTourTaskChecked([...checkedValue, value]));
    } else {
      dispatch(getNonTourTaskChecked(checkedValue.filter((e) => e !== value)));
    }
  };
  return (
    <Toolbar>
      {/* <div>
        <Paper mt={3}>
          <FormControl mr={2} sx={{ minWidth: 120 }}>
            <InputLabel id="demo-simple-select-label">Actions</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={actions}
              label="Actions"
              MenuProps={{
                PaperProps: {
                  sx: {
                    "& .MuiMenu-list": {
                      boxShadow:
                        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 10%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
                      margin: "0px 3px 5px 3px",
                      padding: "0",
                    },
                  },
                },
              }}
              onChange={handleChange}
            >
              <MenuItem value={"Delete"}>Delete</MenuItem>
            </Select>
          </FormControl>
        </Paper>
      </div> */}
      <div>
        {totalRecord > 0 ? (
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content" }}
          >
            {totalRecord} records found
          </Typography>
        ) : (
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content" }}
          >
            {totalRecord} record found
          </Typography>
        )}
      </div>
      <Spacer />
      <div style={{ width: "100%" }}>
        <Paper
          mt={3}
          sx={{
            minWidth: { lg: "600px", md: "500px" },

            "& .MuiFormControl-root": {
              width: "100%",
            },
            "& .MuiGrid-item": {
              paddingTop: "0px !important",
            },
          }}
        >
          <Grid container spacing={6} sx={{ marginTop: { md: 0, xs: "20px" } }}>
            <Grid item xs={12} sm={4}>
              <FormControl sx={{ mt: { md: 0, xs: "15px" } }}>
                <TextField
                  fullWidth
                  id="dateRange"
                  //label="Date Range"
                  value={
                    startDateToFilter && endDateToFilter
                      ? moment(startDateToFilter).format("MM/DD/YYYY") +
                        " - " +
                        moment(endDateToFilter).format("MM/DD/YYYY")
                      : ""
                  }
                  onClick={() => setFadedBox((prev) => !prev)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      applySearch();
                      setIsFilter(true);
                      // handleFirstPage();
                      setCurrentPage(1);
                    }
                  }}
                  // onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="Select date range"
                />
              </FormControl>
              {fadebox && (
                <CustomDateRange
                  handleShow={fadebox}
                  conformation={(status) => setFadedBox((prev) => !prev)}
                  setDates={(start, end) => {
                    setStartDateToFilter(start);
                    setEndDateToFilter(end);
                    setIsFilter(true);
                  }}
                  selectedStart={startDateToFilter}
                  selectedEnd={endDateToFilter}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={8}>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "nowrap",
                  mt: { md: 0, xs: "15px" },
                }}
              >
                <Item sx={{ flexGrow: 1, width: "100%" }}>
                  <FormControl>
                    <TextField
                      id="search"
                      className={classes.searchFilter}
                      type="text"
                      fullWidth
                      value={searchTaskname}
                      autoComplete="off"
                      onChange={(e) => {
                        setSearchTaskname(e.target.value);
                        setIsFilter(false);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          applySearch();
                          setSearchInput(searchTaskname);
                          setIsFilter(true);
                          // handleFirstPage();
                          setCurrentPage(1);
                        }
                      }}
                      placeholder="Search by task name"
                      // inputProps={{
                      //   sx: {
                      //     //minWidth: "280px",
                      //     paddingRight: "25px",
                      //   },
                      // }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {searchTaskname !== "" ? (
                              <IconButton
                                className={classes.clearSearch}
                                aria-label="toggle password visibility"
                                onClick={() => {
                                  setSearchTaskname("");
                                  setSearchInput("");
                                  setDataToFilter(null);
                                }}
                              >
                                <CancelIcon />
                              </IconButton>
                            ) : null}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Item>
                <Item>
                  {isFilter ? (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={(e) => {
                        resetFilter();
                        setDataToFilter(null);
                        setIsFilter(false);
                        setSearchInput("");
                      }}
                      sx={{
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        height: "100%",
                        marginLeft: "-3px",
                      }}
                    >
                      Clear
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        height: "100%",
                        marginLeft: "-3px",
                      }}
                      onClick={(e) => {
                        applySearch();
                        setSearchInput(searchTaskname);
                        setIsFilter(true);
                        // handleFirstPage();
                        setCurrentPage(1);
                        // if (searchTaskname !== "") {
                        //   applySearch({});
                        //   setSearchInput(searchTaskname);
                        // }
                      }}
                    >
                      {/* <SearchIcon /> */}
                      Search
                    </Button>
                  )}
                </Item>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </div>

      <Spacer />
      <div>
        <Paper mt={3}>
          <IconButton
            aria-owns={anchorEl ? "icon-menu" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            variant="contained"
            color="secondary"
          >
            <FilterListIcon />
          </IconButton>
          <StyledFilterMenu
            id="icon-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem>
              <ListItemIcon>
                <PictureAsPdfIcon />
              </ListItemIcon>
              <ListItemText
                size="large"
                primary="Download PDF"
                pl={0}
                onClick={() => generatePdf()}
              />
            </MenuItem>
            {CSVData.length > 0 && (
              <CSVLink
                data={CSVRecord}
                headers={exportCSVheaders}
                // onClick={handleClose}
                filename={`SOVA-Tour-Report-${format(
                  new Date(),
                  "MM-dd-yyyy"
                )}`}
              >
                <MenuItem>
                  <ListItemIcon>
                    <SummarizeIcon />
                  </ListItemIcon>
                  <ListItemText size="large" primary="Download CSV" pl={0} />
                </MenuItem>
              </CSVLink>
            )}

            <MenuItem
              aria-owns={anchorColumn ? "columns-dropdown" : undefined}
              aria-haspopup="true"
              onClick={handleClickColumn}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Columns" pl={0} />
            </MenuItem>
          </StyledFilterMenu>
          <StyledColumnDropdown
            id="columns-dropdown"
            anchorEl={anchorColumn}
            open={Boolean(anchorColumn)}
            onClose={handleCloseColumn}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem>
              <Card>
                <CardContent>
                  <Typography variant="h6" component="h2">
                    {checkedValue.length} out of {colArray.length} visible
                  </Typography>
                  <Box>
                    <FormGroup>
                      {colArray.map((col) => {
                        return (
                          <FormControlLabel
                            key={col.sort_id}
                            control={
                              <Checkbox
                                disableRipple
                                checked={checkedValue.includes(col.key)}
                                onChange={(e) => handleChecked(e)}
                                value={col.key}
                              />
                            }
                            label={col.value}
                          />
                        );
                      })}
                    </FormGroup>
                  </Box>
                  <Toolbar>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        getColumnList();
                        getColumnChecked();
                      }}
                    >
                      Reset
                    </Button>
                    <Spacer />
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleCloseColumn}
                    >
                      Cancel
                    </Button>
                  </Toolbar>
                </CardContent>
              </Card>
            </MenuItem>
          </StyledColumnDropdown>
        </Paper>
      </div>
    </Toolbar>
  );
};
function EnhancedTable(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("color");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  //states related to listing
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [loading, setLoading] = React.useState(false); //State for loader
  const [taskNameDialog, setTaskNameDialog] = useState(""); //State for dialog taskname D1
  // const [taskNameDetail, setTaskNameDetail] = useState("");
  const [dataToFilter, setDataToFilter] = useState(null);
  const [startDateToFilter, setStartDateToFilter] = useState(
    subDays(new Date(), 3)
  );
  const [endDateToFilter, setEndDateToFilter] = useState(new Date());
  const [searchInput, setSearchInput] = useState(""); //state for search in tableheadtoolbar
  const [CSVData, setCSVData] = useState([]);
  const dispatch = useDispatch();
  const tourTaskList = useSelector((state) => state.report.nonTourTaskData);
  const checkedValue = useSelector((state) => state.report.nonTourTaskChecked);
  const [colArray, setColArray] = useState([]); //State for list of colmn list
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = tourTaskList.map((n) => n.task_name);
      setSelected(newSelecteds);
      setCSVData(newSelecteds);
      return;
    }
    setCSVData([]);
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setCSVData(newSelected);
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    // setPage(newPage);
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setSelected([]);
    // setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // const emptyRows =
  //   rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleActionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();
  // User Name Missed Buttons Modal
  const [unmissedbuttons, setUserMissedButtons] = React.useState(false);
  const userMissedButtonsOpen = () => {
    setUserMissedButtons(true);
  };
  const userMissedButtonsClose = () => {
    setUserMissedButtons(false);
  };
  // Non Tour Task Modal
  const [nontourtask, setNonTourTask] = React.useState(false);
  const nonTourTaskOpen = (taskName) => {
    setTaskNameDialog(taskName);
    setNonTourTask(true);
  };
  const nonTourTaskClose = () => {
    setNonTourTask(false);
  };
  // Average Mins Modal
  const [avgmins, setAvgMins] = React.useState(false);
  const avgMinsOpen = (taskName) => {
    setTaskNameDialog(taskName);
    // setTaskNameDetail(taskNameD);
    setAvgMins(true);
  };
  const avgMinsClose = () => {
    setAvgMins(false);
  };

  //function for showing the Non-tour-task Listing page
  const showNonTourTaskList = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
      //sorting: {
      //  order:order,
      //  by: orderBy,
      // },
      start_date: startDateToFilter
        ? moment(startDateToFilter).format("YYYY-MM-DD")
        : "",
      end_date: endDateToFilter
        ? moment(endDateToFilter).format("YYYY-MM-DD")
        : "",
      ...dataToFilter,
    };
    NonTourTaskList(currentPage, payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setLoading(false);
          dispatch(nonTourTaskList(res.data));
          setCurrentPage(res.meta.current_page);
          setTotalRecord(res.meta.total);
        } else {
          dispatch(nonTourTaskList(res.data));
          setTotalRecord(0);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setTotalRecord(0);
        dispatch(nonTourTaskList([]));
      });
  };

  const filterRecord = (data) => {
    setDataToFilter(data);
  };

  //API call for showing list of column checkboxes Items
  const getColumnList = () => {
    NonTourTaskColumns()
      .then((res) => {
        setColArray(res.data.all_fields);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getColumnChecked = () => {
    NonTourTaskColumns()
      .then((res) => {
        dispatch(getNonTourTaskChecked(res.data.default_fields));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
    setSelected([]);
  };

  useEffect(() => {
    showNonTourTaskList();
    getColumnList();
  }, [
    rowsPerPage,
    currentPage,
    dataToFilter,
    searchInput,
    startDateToFilter,
    endDateToFilter,
  ]);

  //sideeffect for dispatching actions to redux store
  useEffect(() => {
    if (checkedValue.length == 0) {
      getColumnChecked();
    } else {
      console.log("Redux store array is empty");
    }
  }, []);

  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
    setSelected([]);
  };

  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
    setSelected([]);
  };

  return (
    <div>
      <Paper mt={3}>
        <EnhancedTableToolbar
          totalRecord={totalRecord}
          numSelected={selected.length}
          dataToFilter={dataToFilter}
          filterRecord={(data) => filterRecord(data)}
          tourTaskList={tourTaskList}
          setDataToFilter={setDataToFilter}
          startDateToFilter={startDateToFilter}
          setStartDateToFilter={setStartDateToFilter}
          endDateToFilter={endDateToFilter}
          setEndDateToFilter={setEndDateToFilter}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          colArray={colArray}
          getColumnList={getColumnList}
          getColumnChecked={getColumnChecked}
          CSVData={CSVData}
          handleFirstPage={handleFirstPage}
          setCurrentPage={setCurrentPage}
        />
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={10} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <>
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={tourTaskList.length}
                  colArray={colArray}
                />
                <TableBody>
                  {stableSort(tourTaskList, getComparator(order, orderBy))
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.task_name);
                      const labelId = `enhanced-table-checkbox-${row.id}`;

                      return (
                        <TableRow
                          hover
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <TableCell>
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                              onClick={(event) =>
                                handleClick(event, row.task_name)
                              }
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              selected={isItemSelected}
                            />
                          </TableCell>
                          {checkedValue.includes("task_name") && (
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              //padding="none"
                              onClick={() => {
                                nonTourTaskOpen(row.task_name);
                              }}
                              sx={{
                                cursor: "pointer",
                                lg: 'whiteSpace: "nowrap"',
                                color: "#0077B5",
                              }}
                            >
                              {row.task_name}
                            </TableCell>
                          )}
                          {checkedValue.includes("count_task") && (
                            <TableCell
                              align="left"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              {row.count_task}
                            </TableCell>
                          )}
                          {checkedValue.includes("count_user") && (
                            <TableCell
                              align="left"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              {row.count_user}
                            </TableCell>
                          )}
                          {checkedValue.includes("avg_time") && (
                            <TableCell
                              align="left"
                              sx={{
                                cursor: "pointer",
                                lg: 'whiteSpace: "nowrap"',
                                color: "#0077B5",
                              }}
                              onClick={() => {
                                avgMinsOpen(row.task_name);
                              }}
                            >
                              {row.avg_time}
                            </TableCell>
                          )}
                          {checkedValue.includes("highest_elapsed_time") && (
                            <TableCell align="left">
                              {row.highest_elapsed_time}
                            </TableCell>
                          )}
                          {checkedValue.includes("lowestlapsedTime") && (
                            <TableCell
                              align="left"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              {row.lowestlapsedTime}
                            </TableCell>
                          )}
                          {checkedValue.includes("total_time") && (
                            <TableCell
                              align="left"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              {row.total_time}
                            </TableCell>
                          )}
                          {checkedValue.includes("last_completed") && (
                            <TableCell
                              align="left"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              {row.last_completed}
                              {/* <Box>
                          <Typography variant="span" component="p">
                            08-03-2016
                          </Typography>
                          <Typography variant="span" component="p">
                            08:18:59
                          </Typography>
                        </Box> */}
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                  {tourTaskList.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={12} align="center">
                        No records found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          }}
        >
          <Pagination
            count={totalRecord !== 0 ? Math.ceil(totalRecord / rowsPerPage) : 1}
            page={currentPage}
            onChange={handleChangePagination}
            color="primary"
            boundaryCount={3}
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <Typography sx={{ marginRight: "10px" }}>
                Rows per page :
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                // label="Age"
                onChange={handleChangeLimit}
              >
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </Box>
          </FormControl>
        </Box>
        {/* <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={totalRecord}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      {/* Non Tour Task Modal */}
      {nontourtask && (
        <NTourTaskD1
          nontourtask={nontourtask}
          setNonTourTask={setNonTourTask}
          nonTourTaskClose={nonTourTaskClose}
          taskNameDialog={taskNameDialog}
        />
      )}

      {/* Avg mins Modal */}
      {avgmins && (
        <NTourTaskD2
          avgmins={avgmins}
          setAvgMins={setAvgMins}
          avgMinsClose={avgMinsClose}
          taskNameDialog={taskNameDialog}
          startDateToFilter={startDateToFilter}
          endDateToFilter={endDateToFilter}
        />
      )}
    </div>
  );
}

function NonTourTasks() {
  return (
    <React.Fragment>
      <Helmet title=" Non Tour Tasks" />
      <Typography variant="h3" gutterBottom display="inline">
        Non Tour Tasks
      </Typography>

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default NonTourTasks;
