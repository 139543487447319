import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import styled from "styled-components/macro";
import CustomTextField from "../../../components/form-components/CustomTextField";
import EditKeyCabinet from "./EditKeyCabinet";
import {
  GetAddKeyCabinetList,
  DeleteKeyCabinet,
  AddNewKeyCabinet,
} from "../../../api";
import { handleApiResonseErrors } from "../../../helper/helper";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../../redux/slices/equipmentSlice";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Menu,
  Typography,
  TextField,
  FormControl as MuiFormControl,
  Button,
  IconButton,
  Paper,
  Grid,
  Table,
  TableContainer,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import {
  CancelRounded as CancelRoundedIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const AddKeyCabinet = ({
  addKeyCabinet,
  addKeyCabinetClose,
  childToParent,
}) => {
  // Action Dropdown
  const [addKeyCabinetAction, setAddKeyCabinetAction] = React.useState(null);
  const [keyCabinetList, setKeyCabinetList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [cabinetId, setCabinetId] = useState(null);
  const [close, setClose] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [singleData, setSingleData] = useState({});
  const dispatch = useDispatch();

  /**
   * It sets the addKeyCabinetAction to the currentTarget and sets the cabinetId to the id.
   * @param event - The event that triggered the function.
   * @param id - The id of the cabinet you want to add a key to.
   */
  const addKeyCabinetActionClick = (event, id, data) => {
    setAddKeyCabinetAction(event.currentTarget);
    setCabinetId(id);
    setSingleData(data);
  };

  /**
   * It sets the state of the addKeyCabinetAction to null
   */
  const addKeyCabinetActionClose = () => {
    setAddKeyCabinetAction(null);
  };

  /* The above code is creating a state variable called editKeyCabinet and setting it to false. It is
  also creating a function called editKeyCabinetOpen that sets the state variable to true. */
  const [editKeyCabinet, setEditKeyCabinet] = useState(false);
  const editKeyCabinetOpen = () => {
    setEditKeyCabinet(true);
  };

  /**
   * This function closes the edit key cabinet modal
   */
  const editKeyCabinetClose = () => {
    setEditKeyCabinet(false);
  };

  //method for open the delete dialog
  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  /**
   * It closes the delete dialog and resets the anchor element and id
   */
  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  /**
   * It closes the dialog box.
   * @param e - The event object
   */
  const closeDialogHandle = (e) => {
    setClose(e.target.value);
  };

  /**
   * This function is used to get the list of key cabinets from the database
   */
  const addKeyCabinetList = () => {
    setLoading(true);
    GetAddKeyCabinetList()
      .then((res) => {
        if (res.statusCode === 200) {
          setKeyCabinetList(res.data);
          childToParent(res.data, "key_cabinet");
          setLoading(false);
        } else {
          setKeyCabinetList([]);
          childToParent(res.data, "key_cabinet");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  /**
   * This function is used to delete a key cabinet
   */
  const deleteKeyCabinet = () => {
    handleDeleteClose();
    DeleteKeyCabinet(cabinetId)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(getSnackOpen());
          dispatch(getMsg("Record has been deleted successfully"));
          dispatch(getSnackAlert("success"));
          addKeyCabinetList();
        } else {
          dispatch(getSnackOpen());
          dispatch(
            getMsg(
              "Cabinet has keys assigned. Remove or re-assign keys to a different cabinet then you can delete this cabinet"
            )
          );
          dispatch(getSnackAlert("error"));
        }
      })
      .catch((err) => {
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        if (error) {
          setErrMsg(error);
        }
        dispatch(getSnackClose());
      });
  };

  /* The above code is using the useEffect hook to call the addKeyCabinetList function when the component
mounts. */
  useEffect(() => {
    addKeyCabinetList();
  }, []);

  /* Creating a validation schema for the form. */
  const validationSchema = Yup.object().shape({
    cabinet_name: Yup.string().required("Please enter ring name"),
  });

  /* Setting the initial values for the form. */
  const initialValues = {
    cabinet_name: "",
    cabinet_trays: "",
    cabinet_columns: "",
    cabinet_rows: "",
    cabinet_notes: "",
  };

  /**
   * A function that is called when the form is submitted.
   * @param values - An object containing all values of the form.
   */
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      let formData = {
        name: values.cabinet_name,
        trays: values.cabinet_trays,
        columns: values.cabinet_columns,
        rows: values.cabinet_rows,
        notes: values.cabinet_notes,
      };
      AddNewKeyCabinet(formData)
        .then((res) => {
          if (res.statusCode === 200) {
            dispatch(getSnackOpen());
            dispatch(getMsg("Key cabinet has been submitted successfully"));
            dispatch(getSnackAlert("success"));
            if (close === "close") {
              addKeyCabinetClose();
            }
            addKeyCabinetList();
          } else {
            dispatch(getSnackClose());
          }
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            setErrMsg(error);
          }
          dispatch(getSnackClose());
        });
      resetForm();
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  return (
    <>
      <Dialog
        open={addKeyCabinet}
        onClocse={addKeyCabinetClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "700px",
            },
          },
        }}
        aria-labelledby="add-key-cabinet-title"
        aria-describedby="add-key-cabinet-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="add-key-cabinet-title"
        >
          <Typography variant="h4">Add Key Cabinet</Typography>
          <DialogActions>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={addKeyCabinetClose}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="add-key-cabinet-description">
            A key cabinet name is the name of your key box. You can have one key
            box or many. Enter a count of trays if you use a drawer system, or a
            count of columns and rows if you use a peg board system. If you do
            these categories, enter any value in these fields.
          </DialogContentText>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              touched,
              values,
              status,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={8}>
                      <CustomTextField
                        name="cabinet_name"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key cabinet name*"
                        autoComplete="off"
                        placeholder=""
                      />
                      <FormHelperText sx={{ color: "#d32f2f" }}>
                        {errMsg}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={8}>
                      <CustomTextField
                        name="cabinet_trays"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key cabinet tags"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="cabinet_columns"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key cabinet columns"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="cabinet_rows"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key cabinet rows"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="cabinet_notes"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Key cabinet notes"
                        autoComplete="off"
                        placeholder=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button color="primary" variant="outlined" type="submit">
                      Save & add more
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      sx={{ marginLeft: "10px" }}
                      type="submit"
                      value={"close"}
                      onClick={closeDialogHandle}
                    >
                      Save & done
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
          <Paper sx={{ marginTop: "30px" }}>
            <Dialog
              open={openDelete}
              onClose={handleDeleteClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Are you sure you want to delete  key cabinet?"}
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleDeleteClose} variant="contained">
                  Cancel
                </Button>
                <Button
                  onClick={deleteKeyCabinet}
                  autoFocus
                  variant="contained"
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>
            <Typography variant="h4" component="div" gutterBottom>
              Existing Key Cabinet
            </Typography>
            <TableContainer className="tableContainer">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Trays</TableCell>
                    <TableCell>Columns</TableCell>
                    <TableCell>Rows</TableCell>
                    <TableCell>Notes</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                {loading ? (
                  <TableCell colSpan={13} align="center">
                    <CircularProgress />
                  </TableCell>
                ) : (
                  <TableBody>
                    {keyCabinetList.map((data) => {
                      return (
                        <TableRow>
                          <TableCell>{data.name}</TableCell>
                          <TableCell>{data.trays}</TableCell>
                          <TableCell>{data.columns}</TableCell>
                          <TableCell>{data.rows}</TableCell>
                          <TableCell>{data.notes}</TableCell>
                          <TableCell>
                            <IconButton
                              aria-label="Settings"
                              size="large"
                              aria-owns={
                                addKeyCabinetAction
                                  ? "add-key-cabinet-action"
                                  : undefined
                              }
                              aria-haspopup="true"
                              onClick={(e) =>
                                addKeyCabinetActionClick(e, data.id, data)
                              }
                              style={{ color: "#1374C5" }}
                            >
                              <SettingsIcon />
                            </IconButton>
                            <StyledMenu
                              id="add-key-cabinet-action"
                              anchorEl={addKeyCabinetAction}
                              open={Boolean(addKeyCabinetAction)}
                              onClose={addKeyCabinetActionClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <MenuItem
                                onClick={() => {
                                  editKeyCabinetOpen();
                                }}
                              >
                                <ListItemIcon>
                                  <EditIcon />
                                </ListItemIcon>
                                <ListItemText primary="Edit" pl={1} />
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  handleDeleteOpen();
                                  addKeyCabinetActionClose();
                                }}
                              >
                                <ListItemIcon>
                                  <DeleteIcon />
                                </ListItemIcon>
                                <ListItemText primary="Delete" pl={1} />
                              </MenuItem>
                            </StyledMenu>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {keyCabinetList.length === 0 && (
                      <TableRow>
                        <TableCell align="center" colSpan={10}>
                          No records found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={addKeyCabinetClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {editKeyCabinet && (
        <EditKeyCabinet
          editKeyCabinet={editKeyCabinet}
          editKeyCabinetClose={editKeyCabinetClose}
          setEditKeyCabinet={setEditKeyCabinet}
          editKeyCabinetOpen={editKeyCabinetOpen}
          singleData={singleData}
          addKeyCabinetList={addKeyCabinetList}
          cabinetId={cabinetId}
        />
      )}
    </>
  );
};

export default AddKeyCabinet;
