import * as Yup from "yup";
import moment from "moment";
import { Formik } from "formik";
import { makeStyles } from "@mui/styles";
import { Helmet } from "react-helmet-async";
import styled from "styled-components/macro";
import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import CustomAlert from "../../components/form-components/CustomAlert";
import CustomSelect from "../../components/form-components/CustomSelect";
import { updateShowAlert, updateText } from "../../redux/slices/mainSlice";
import { getFileBase64, handleApiResonseErrors } from "../../helper/helper";
import CustomTextField from "../../components/form-components/CustomTextField";
import CustomDateTimePicker from "../../components/form-components/CustomDateTimePicker";
import MultipleAutoComplete from "../../components/form-components/MultipleAutoComplete";
import { AddOverdueKeyReport, DepartmentList } from "../../api";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

import {
  Divider as MuiDivider,
  Typography,
  FormControl as MuiFormControl,
  Breadcrumbs as MuiBreadcrumbs,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
  Link,
  Grid,
  Card,
  CardContent,
  TextField,
  Button,
  Tooltip,
  Box,
  FormHelperText,
  Paper,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import {
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
} from "@mui/icons-material";
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;

const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 200px;
  }
`;

const disposition = [
  { key: "Unknown", value: "Unknown" },
  { key: "Lost", value: "Lost" },
  { key: "Found", value: "Found" },
  { key: "Key Cancelled", value: "Key Cancelled" },
  { key: "Stolen", value: "Stolen" },
];
const investigation = [
  {
    key: "Lockers",
    value: "Lockers",
  },
  {
    key: "Uniforms",
    value: "Uniforms",
  },
  {
    key: "Took Home",
    value: "Took Badge",
  },
  {
    key: "Lost Key",
    value: "Lost Key",
  },
  {
    key: "Other",
    value: "Other",
  },
];

// Classes Styling
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
  borderedBox: {
    border: "1px solid",
    borderBottom: 0,
    borderColor:
      theme.palette.mode === "light" ? "#e0e0e0" : theme.palette.grey[700],
  },
}));

function AddReportForm(props) {
  const [departmentList, setDepartmentList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  // Alert Message
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");

  const showAlert = useSelector((state) => state.main.showAlert);
  const dispatch = useDispatch();
  const uploadedFileRef = useRef();
  const navigate = useNavigate();
  const classes = useStyles();
  const [narrative, setNarrative] = useState("");

  const [dateTimeStates, setDateTimeStates] = useState({
    missing: false,
    checkout: false,
    keyReturn: false,
    keyCancelled: false,
    newKey: false,
  });

  const setDateTimeState = (key, value) => {
    setDateTimeStates((prevStates) => ({
      ...prevStates,
      [key]: value,
    }));
  };

  const validationSchema = Yup.object().shape({
    missing_datetime: Yup.string().nullable(),
    datetime_checked_out: Yup.string().nullable(),
    key_return_datetime: Yup.string().nullable(),
    key_cancelled_datetime: Yup.string().nullable(),
    new_key_datetime: Yup.string().nullable(),
    key_number: Yup.string().required("Please enter key number"),
    checked_to: Yup.string().required("Please enter checked out"),
  });

  let initialValues = {
    key_number: "",
    checked_to: "",
    disposition: "",
    missing_datetime: null,
    department: "",
    datetime_checked_out: null,
    investigation: [],
    manager_notified: "No",
    manager_name_comment: "",
    key_return_when: "",
    action_taken: "",
    key_returned_by: "",
    key_return_datetime: null,
    key_return_lp: "",
    key_cancelled: "No",
    key_cancelled_datetime: null,
    key_cancelled_lp: "",
    new_key_issued: "No",
    new_key_datetime: null,
    new_key_lp: "",
    details: "",
  };

  const messageAlert = (msg, type) => {
    setLoading(false);
    setAlertMessage(msg);
    setAlertType(type);
    dispatch(updateShowAlert(true));
  };

  useEffect(() => {
    getDepartmentList();
  }, []);

  const getDepartmentList = () => {
    DepartmentList()
      .then((res) => {
        setDepartmentList(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoading(true);

      let formData = {
        key_number: values.key_number,
        checked_to: values.checked_to,
        disposition: values.disposition,
        missing_datetime: values.missing_datetime,
        department: values.department,
        datetime_checked_out: values.datetime_checked_out,
        manager_notified: values.manager_notified,
        manager_name_comment: values.manager_name_comment,
        key_return_when: values.key_return_when,
        action_taken: values.action_taken,
        key_returned_by: values.key_returned_by,
        key_return_datetime: values.key_return_datetime,
        key_return_lp: values.key_return_lp,
        key_cancelled: values.key_cancelled,
        key_cancelled_datetime: values.key_cancelled_datetime,
        key_cancelled_lp: values.key_cancelled_lp,
        new_key_issued: values.new_key_issued,
        new_key_datetime: values.new_key_datetime,
        new_key_lp: values.new_key_lp,
        details: narrative,
        image: uploadedImage,
        investigation:
          values.investigation.length > 0
            ? values.investigation.map((data) => data.value).join(",")
            : "",
      };
      AddOverdueKeyReport(formData)
        .then((res) => {
          if (res.statusCode === 200) {
            dispatch(updateShowAlert(true));
            dispatch(updateText("Record has been submitted successfully"));
            navigate({ pathname: "/logs/overdue-key-report" });
          } else {
            messageAlert("Sorry! something wrong please try again", "error");
          }
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            messageAlert(error, "error");
          }
        });
    } catch (error) {
      messageAlert("Sorry! something wrong please try again", "error");
    }
  };

  const handleFileChange = (evt) => {
    let file = evt.target.files[0];
    getFileBase64(file, (result) => {
      setUploadedImage(result);
    });
  };

  const handleOnPaste = (pasteEvent) => {
    var item = pasteEvent.clipboardData.items[0];
    if (item.type.indexOf("image") === 0) {
      var file = item.getAsFile();
      getFileBase64(file, (result) => {
        setUploadedImage(result);
      });
    }
  };

  return (
    <>
      <Card mt={6}>
        <CardContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, values, setFieldValue, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} lg={4} md={6}>
                    <FormControl mt={3}>
                      <CustomTextField
                        name="key_number"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        label="Key/Badge number"
                        autoComplete="off"
                        placeholder="Key or badge number"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={4} md={6}>
                    <FormControl mt={3}>
                      <CustomTextField
                        name="checked_to"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        label="Checked out to"
                        autoComplete="off"
                        placeholder="Name of person with key or badge"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={4} md={6}>
                    <FormControl mt={3}>
                      <CustomSelect
                        name="disposition"
                        fieldLabel="Disposition"
                        options={disposition}
                        displayEmpty
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6} md={6}>
                    <FormControl>
                      <CustomDateTimePicker
                        open={dateTimeStates.missing}
                        onOpen={() => setDateTimeState("missing", true)}
                        onClose={() => setDateTimeState("missing", false)}
                        handler={() => setDateTimeState("missing", true)}
                        name="missing_datetime"
                        label="Datetime missing"
                        disableOpenPicker="true"
                        inputFormat="yyyy-MM-dd HH:mm"
                        placeholder="yyyy-mm-dd hh:mm"
                        setValue={(value) => {
                          let date = value ? moment(value) : "";
                          let dateValue = date
                            ? date.format("YYYY-MM-DD HH:mm")
                            : "";
                          setFieldValue("missing_datetime", dateValue);
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6} md={6}>
                    <FormControl>
                      <CustomSelect
                        name="department"
                        fieldLabel="Department/site"
                        options={departmentList}
                        displayEmpty
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6} md={6}>
                    <FormControl>
                      <CustomDateTimePicker
                        open={dateTimeStates.checkout}
                        onOpen={() => setDateTimeState("checkout", true)}
                        onClose={() => setDateTimeState("checkout", false)}
                        handler={() => setDateTimeState("checkout", true)}
                        disableOpenPicker="true"
                        name="datetime_checked_out"
                        label="Datetime checked out"
                        inputFormat="yyyy-MM-dd HH:mm"
                        placeholder="yyyy-mm-dd hh:mm"
                        setValue={(value) => {
                          let date = value ? moment(value) : "";
                          let dateValue = date
                            ? date.format("YYYY-MM-DD HH:mm")
                            : "";
                          setFieldValue("datetime_checked_out", dateValue);
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6} md={6}>
                    <FormControl>
                      <MultipleAutoComplete
                        name="investigation"
                        InputLabelProps={{ shrink: true }}
                        label="Investigation: (check all that apply)"
                        options={investigation}
                        selOptions={["1", "3"]}
                        getChangedData={(evt) => {
                          setFieldValue("investigation", evt);
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} lg={12} md={12}>
                    <FormControl>
                      <FormLabel>Manager notified?</FormLabel>
                      <RadioGroup
                        aria-labelledby="has-manager_notified"
                        name="manager_notified"
                        value={values.manager_notified}
                        onChange={(e) =>
                          setFieldValue(
                            "manager_notified",
                            e.currentTarget.value
                          )
                        }
                        row
                      >
                        <FormControlLabel
                          control={<Radio />}
                          label="Yes"
                          value="Yes"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="No"
                          value="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={12}
                    md={12}
                    sx={{
                      display:
                        values.manager_notified === "Yes" ? "block" : "none",
                    }}
                  >
                    <FormControl>
                      <CustomTextField
                        name="manager_name_comment"
                        fullWidth
                        autoComplete="off"
                        placeholder="Manager names and any comments"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6} md={6}>
                    <FormControl mt={3}>
                      <CustomTextField
                        name="key_return_when"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        label="When will be the Key/Badge returned?"
                        autoComplete="off"
                        placeholder="Expected return time"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6} md={6}>
                    <FormControl mt={3}>
                      <CustomTextField
                        name="action_taken"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        label="What action was taken?"
                        autoComplete="off"
                        placeholder="Include details here"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={12} md={12}>
                    <Typography variant="h4" component="div" gutterBottom>
                      Disposition
                    </Typography>
                    <Divider my={6} />
                  </Grid>
                  <Grid item xs={12} lg={4} md={4}>
                    <FormControl>
                      <CustomTextField
                        name="key_returned_by"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        label="Key/Badge returned by"
                        autoComplete="off"
                        placeholder="Person name"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={4} md={4}>
                    <FormControl>
                      <CustomDateTimePicker
                        open={dateTimeStates.keyReturn}
                        onOpen={() => setDateTimeState("keyReturn", true)}
                        onClose={() => setDateTimeState("keyReturn", false)}
                        handler={() => setDateTimeState("keyReturn", true)}
                        disableOpenPicker="true"
                        name="key_return_datetime"
                        label="Datetime"
                        inputFormat="yyyy-MM-dd HH:mm"
                        placeholder="yyyy-mm-dd hh:mm"
                        setValue={(value) => {
                          let date = value ? moment(value) : "";
                          let dateValue = date
                            ? date.format("YYYY-MM-DD HH:mm")
                            : "";
                          setFieldValue("key_return_datetime", dateValue);
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={4} md={4}>
                    <FormControl>
                      <CustomTextField
                        name="key_return_lp"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        label="Loss prevention person"
                        autoComplete="off"
                        placeholder="Person key or badge was returned to"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={4} md={4}>
                    <FormControl>
                      <FormLabel>Key/Badge cancelled?</FormLabel>
                      <RadioGroup
                        aria-labelledby="has-key_cancelled"
                        name="key_cancelled"
                        value={values.key_cancelled}
                        onChange={(e) =>
                          setFieldValue("key_cancelled", e.currentTarget.value)
                        }
                        row
                      >
                        <FormControlLabel
                          control={<Radio />}
                          label="Yes"
                          value="Yes"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="No"
                          value="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={4} md={4}>
                    <FormControl>
                      <CustomDateTimePicker
                        open={dateTimeStates.keyCancelled}
                        onOpen={() => setDateTimeState("keyCancelled", true)}
                        onClose={() => setDateTimeState("keyCancelled", false)}
                        handler={() => setDateTimeState("keyCancelled", true)}
                        disableOpenPicker="true"
                        name="key_cancelled_datetime"
                        label="Datetime"
                        inputFormat="yyyy-MM-dd HH:mm"
                        placeholder="yyyy-mm-dd hh:mm"
                        setValue={(value) => {
                          let date = value ? moment(value) : "";
                          let dateValue = date
                            ? date.format("YYYY-MM-DD HH:mm")
                            : "";
                          setFieldValue("key_cancelled_datetime", dateValue);
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={4} md={4}>
                    <FormControl>
                      <CustomTextField
                        name="key_cancelled_lp"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        label="Loss prevention person"
                        autoComplete="off"
                        placeholder="Person who cancelled key or badge"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={4} md={4}>
                    <FormControl>
                      <FormLabel>New Key/Badge issued?</FormLabel>
                      <RadioGroup
                        aria-labelledby="has-new_key_issued"
                        name="new_key_issued"
                        value={values.new_key_issued}
                        onChange={(e) =>
                          setFieldValue("new_key_issued", e.currentTarget.value)
                        }
                        row
                      >
                        <FormControlLabel
                          control={<Radio />}
                          label="Yes"
                          value="Yes"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="No"
                          value="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={4} md={4}>
                    <FormControl>
                      <CustomDateTimePicker
                        open={dateTimeStates.newKey}
                        onOpen={() => setDateTimeState("newKey", true)}
                        onClose={() => setDateTimeState("newKey", false)}
                        handler={() => setDateTimeState("newKey", true)}
                        disableOpenPicker="true"
                        name="new_key_datetime"
                        label="Datetime"
                        inputFormat="yyyy-MM-dd HH:mm"
                        placeholder="yyyy-mm-dd hh:mm"
                        setValue={(value) => {
                          let date = value ? moment(value) : "";
                          let dateValue = date
                            ? date.format("YYYY-MM-DD HH:mm")
                            : "";
                          setFieldValue("new_key_datetime", dateValue);
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={4} md={4}>
                    <FormControl>
                      <CustomTextField
                        name="new_key_lp"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        label="Loss prevention person"
                        autoComplete="off"
                        placeholder="Person who created new key or badge"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={12} md={12}>
                    <FormLabel>Narrative:</FormLabel>
                    <QuillWrapper>
                      <ReactQuill
                        theme="snow"
                        value={narrative}
                        onChange={setNarrative}
                        placeholder="Type something.."
                      />
                    </QuillWrapper>
                  </Grid>
                  <Grid item xs={12} lg={6} md={6}>
                    <FormControl mt={6}>
                      <CustomTextField
                        name="image"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        label="Upload image (i.e. from access control system)"
                        autoComplete="off"
                        placeholder=""
                        type="file"
                        onChange={(e) => handleFileChange(e)}
                      />
                    </FormControl>
                    <FormControl mt={5}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <FormLabel id="ss">Paste screenshot</FormLabel>
                      </Box>
                      <TextField
                        InputProps={{
                          readOnly: true,
                        }}
                        sx={{ marginTop: "10px" }}
                        rows={3}
                        multiline={true}
                        placeholder="Press ctrl+v to share your screenshot"
                        onPasteCapture={(e) => handleOnPaste(e)}
                      />
                      <FormHelperText>
                        ctrl+v : For Window/Linux : "PrntScr" &gt; 'Ctrl+V', 2.
                        'Shift + Ctrl + Cmd + 4 &gt; "Cmd + V"
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6} md={6}>
                    <FormControl mt={6}>
                      {uploadedImage || values.image ? (
                        <Card variant="outlined" sx={{ height: "230px" }}>
                          {uploadedImage && (
                            <img
                              alt="image name"
                              src={uploadedImage}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                              }}
                            />
                          )}
                        </Card>
                      ) : (
                        <Card variant="outlined" sx={{ height: "230px" }}>
                          <img
                            alt="image name"
                            src="/static/img/lost-found/lost_and_found_pic.jpg"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                            }}
                          />
                        </Card>
                      )}
                      {uploadedImage && (
                        <Tooltip
                          title="Remove image"
                          placement="top-start"
                          sx={{
                            marginLeft: "5px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: "10px",
                          }}
                          onClick={() => setUploadedImage(null)}
                        >
                          <Box>
                            <IconButton>
                              <CancelIcon />
                            </IconButton>
                          </Box>
                        </Tooltip>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} lg={12} md={12}>
                    <Button color="primary" type="submit" variant="contained">
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
      {showAlert && (
        <CustomAlert
          message={alertMessage}
          severity={alertType}
          closeAlert={(show) => dispatch(updateShowAlert(show))}
          showAlert={showAlert}
        />
      )}
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
}
function AddReport() {
  return (
    <React.Fragment>
      <Helmet title="Add Report" />
      <Typography variant="h3" gutterBottom display="inline">
        Add Report
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/logs/overdue-key-report">
          Overdue Key Report
        </Link>
        <Typography>Add Report</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <AddReportForm />
    </React.Fragment>
  );
}

export default AddReport;
